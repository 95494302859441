<template>
  <mpro-video-viewer ref="viewer"
                     :video-source="videoSource" :loading-video="loadingVideo"
                     :show-video="showVideo"
                     :frame-time-stamps="frameTimeStamps"
                     :frames-per-second="movement != null && movement.framesPerSecond"
                     @render="render">
    <template #overlay>
      <!-- Measures -->
      <mpro-measures v-show="showMeasures && isSpecialist"
                     :assessment="assessment" :metadata="metadata"
                     class="p-absolute w-100pc h-100pc"
                     :class="canShowNarrative ? 'py-16' : 'py-4'"/>

      <!-- Layers -->
      <div class="p-absolute" style="right: 15px; bottom: 15px">
        <v-menu :close-on-content-click="false" :close-on-click="true"
                :offset-y="true" :left="true" :top="true"
                class="MPro-bg">
          <template v-slot:activator="{ on, attrs }" class="MPro-bg">
            <v-btn v-bind="attrs" v-on="on"
                   class="MProMainAlt" fab style="border-radius: 10px;">
              <v-icon>mdi-layers-outline</v-icon>
            </v-btn>
          </template>
          <div class="d-flex flex-column MPro-bg">
            <div class="ma-2">
              <v-btn-toggle dense multiple outlined color="primary" class="ml-1 flex-column d-block"
                            v-model="visibilityToggles">
                <v-btn block outlined value="video">{{ $t('scans.buttons.toggle-video') }}</v-btn>
                <v-btn block outlined value="tracking">{{ $t('scans.buttons.toggle-tracking-points') }}</v-btn>
                <v-btn block outlined value="trajectories">{{ $t('scans.buttons.toggle-trajectories') }}</v-btn>
                <v-btn v-if="isSpecialist" block outlined value="measures">{{ $t('scans.buttons.toggle-measures') }}</v-btn>
                <v-btn v-if="isSpecialist" block outlined value="plumbline">{{ $t('scans.buttons.toggle-plumbline') }}</v-btn>
              </v-btn-toggle>
            </div>
          </div>
        </v-menu>
      </div>

      <!-- Narrative -->
      <div v-if="canShowNarrative"
           class="p-absolute" style="left: 15px; top: 15px">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="toggleNarrative"
              v-bind="attrs"
              v-on="on"
              class="MProMainAlt"
              fab
              style="border-radius: 10px;">
              <v-icon>mdi-book-open-variant</v-icon>
            </v-btn>
          </template>
          <span>{{$t('scans.buttons.narrative')}}</span>
        </v-tooltip>
      </div>
    </template>
  </mpro-video-viewer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Vector2 } from 'three'
import Measures from './measures'
import VideoViewer from './video-viewer'
import { parseRawTrackingCsv } from './data-parsing'
import { AssessmentName, MovementKind } from '../../helpers/enums'
import ReferencePointCalculator from '../scans/reference-point-calculator'
import { augmentAssessments } from '@/logic/assessments'

const Toggles = {
  VIDEO: 'video',
  TRACKING: 'tracking',
  TRAJECTORIES: 'trajectories',
  MEASURES: 'measures',
  PLUMBLINE: 'plumbline'
}

const referencePointCalculator = new ReferencePointCalculator()

export default {
  components: {
    'mpro-video-viewer': VideoViewer,
    'mpro-measures': Measures
  },

  props: {
    movement: { type: Object, default: null }
  },

  data: () => ({
    loadingVideo: false,
    videoSource: null,
    visibilityToggles: [Toggles.VIDEO, Toggles.PLUMBLINE],
    trackingPointsRaw: [],
    trackingPoints: [],
    assessment: null,
    metadata: null,
    trajectories: [],
    floorNormal: null,
    plumblineBase: null
  }),

  computed: {
    frameTimeStamps: function () {
      return this.trackingPoints.length > 0
        ? this.trackingPoints.map(a => a.timeStamp)
        : this.trackingPointsRaw.map(a => a.timeStamp)
    },
    showVideo: function () {
      return this.visibilityToggles.includes(Toggles.VIDEO)
    },
    showTrackingPoints: function () {
      return this.visibilityToggles.includes(Toggles.TRACKING)
    },
    showTrajectories: function () {
      return this.visibilityToggles.includes(Toggles.TRAJECTORIES)
    },
    showMeasures: function () {
      return this.visibilityToggles.includes(Toggles.MEASURES)
    },
    showPlumbline: function () {
      return this.visibilityToggles.includes(Toggles.PLUMBLINE)
    },
    canShowNarrative: function () {
      return this.isSpecialist && this.canViewNarratives
    },

    metadataName: function () {
      if (this.movement == null) return undefined

      switch (this.movement.Kind) {
        case 'StandStillFaceView': return MovementKind.STAND_STILL
        case 'SideBend': return MovementKind.SIDE_BEND
        case 'Squat': return MovementKind.SQUAT
        case 'SquatRight': return MovementKind.SQUAT_RIGHT
        case 'SquatLeft': return MovementKind.SQUAT_LEFT
        default: return undefined
      }
    },
    assessmentName: function () {
      if (this.movement == null) return undefined

      switch (this.movement.Kind) {
        case 'StandStillFaceView': return AssessmentName.STAND_STILL
        case 'SideBend': return AssessmentName.SIDE_BEND
        case 'Squat': return AssessmentName.SQUAT_DOUBLE
        case 'SquatRight': return AssessmentName.SQUAT_RIGHT
        case 'SquatLeft': return AssessmentName.SQUAT_LEFT
        default: return undefined
      }
    },

    ...mapGetters('scans2d', ['viewerSettings']),
    ...mapGetters('measures', ['getMeasurePlacement']),
    ...mapGetters('user', ['getUserEmail', 'canViewNarratives']),
    ...mapGetters(['isSpecialist'])
  },

  watch: {
    renderMode: function () {
      this.resetLastRenderedFrame()
    },
    visibilityToggles: function () {
      this.saveViewerSettings()
      this.resetLastRenderedFrame()
    },
    movement: function () {
      this.onMovementChanged()
    }
  },

  methods: {
    resetLastRenderedFrame () {
      this.$refs.viewer.resetLastRenderedFrame()
    },

    async onMovementChanged () {
      this.videoSource = null
      this.trackingPoints = []
      this.trackingPointsRaw = []
      this.assessment = null
      this.metadata = null
      this.floorNormal = null
      this.plumblineBase = null
      if (this.movement == null) return

      this.loadingVideo = true

      this.videoSource = await this.getOrLoadMovementVideoUri(this.movement.Id)

      const assessments = (await this.loadMovementMeasures(this.movement.Id))?.Assessments
      if (assessments != null) {
        augmentAssessments(assessments)
        this.assessment = assessments[this.assessmentName]
      }
      this.metadata = this.getMeasurePlacement(this.metadataName)

      const data = await this.loadMovementTrackingPoints(this.movement.Id)
      if (data != null) {
        data.Frames.forEach(f => {
          f.timeStamp = f.TimeStampMs * 1e-3
          f.points = f.PointsPositions.filter(p => p != null)
        })
        this.trackingPoints = data.Frames

        const referencePoint = referencePointCalculator.calculate(data, this.assessment, this.metadata?.referencePoints)
        if (referencePoint != null) {
          this.plumblineBase = {
            x: referencePoint.X,
            y: referencePoint.Y
          }
        }
      }

      if (this.trackingPoints.length === 0) {
        const dataRaw = await this.loadMovementRawTracking(this.movement.Id)
        this.trackingPointsRaw = parseRawTrackingCsv(dataRaw)
      }

      this.trajectories = data.TrackingPoints.map((name, index) => ({
        name,
        points: data.Frames.map(frame => frame.PointsPositions[index]).filter(p => p != null)
      }))

      const generalInfo = await this.loadMovementGeneralInfo(this.movement.Id)
      const floorPlane = generalInfo && generalInfo.Geometry.FloorPlane
      if (floorPlane) this.floorNormal = { x: floorPlane.A, y: -floorPlane.B }

      this.loadingVideo = false

      this.resetLastRenderedFrame()
    },

    render (data) {
      if (this.showPlumbline) {
        this.renderPlumbline(data.context, data.videoWidth, data.videoHeight)
      }

      const pointRadius = this.pointRadius(data.videoWidth, data.videoHeight)

      if (this.showTrajectories) {
        this.trajectories.forEach(t => {
          const points = this.preparePointsForRender(t.points, data.videoWidth, data.videoHeight)
          this.renderTrajectory(data.context, points, pointRadius, '#C0C8')
        })
      }

      if (data.frameIndex >= 0) {
        const tp = this.trackingPoints[data.frameIndex]
        if (this.showTrackingPoints && tp != null) {
          const points = this.preparePointsForRender(tp.points, data.videoWidth, data.videoHeight)
          this.renderTrackingPoints(data.context, points, pointRadius, 'lime')
        }
      }
    },

    preparePointsForRender: function (points, videoWidth, videoHeight) {
      return points.map(p => ({ x: 0.5 * videoWidth + p.X, y: 0.5 * videoHeight - p.Y }))
    },

    renderTrackingPoints (ctx, points, radius, color) {
      ctx.fillStyle = color
      points.forEach(p => {
        ctx.beginPath()
        ctx.ellipse(
          p.x, p.y, radius, radius,
          0, 0, 2 * Math.PI)
        ctx.closePath()
        ctx.fill()
      })
    },

    renderTrajectory (ctx, points, radius, color) {
      ctx.fillStyle = color
      points.forEach((p, i) => {
        const dirPrev = i > 0 ? new Vector2(p.x - points[i - 1].x, p.y - points[i - 1].y) : new Vector2(0, 0)
        const dirNext = i < points.length - 1 ? new Vector2(points[i + 1].x - p.x, points[i + 1].y - p.y) : new Vector2(0, 0)
        let dir1 = dirPrev.add(dirNext)
        if (dir1.lengthSq() === 0) dir1 = new Vector2(0, 1)
        dir1.normalize().multiplyScalar(radius * 2 / 3)
        const dir2 = dir1.clone().rotateAround(new Vector2(0, 0), Math.PI * 4 / 3)
        const dir3 = dir1.clone().rotateAround(new Vector2(0, 0), -Math.PI * 4 / 3)

        ctx.beginPath()
        ctx.moveTo(p.x + dir1.x, p.y + dir1.y)
        ctx.lineTo(p.x + dir2.x, p.y + dir2.y)
        ctx.lineTo(p.x + dir3.x, p.y + dir3.y)
        ctx.closePath()
        ctx.fill()
      })
    },

    renderPlumbline (ctx, videoWidth, videoHeight) {
      if (this.floorNormal == null) return

      const lineLength = 2 * Math.max(videoWidth, videoHeight)
      const base = this.plumblineBase || { x: 0, y: 0 }
      const origin = {
        x: videoWidth / 2 + base.x,
        y: videoHeight / 2 - base.y
      }

      ctx.lineWidth = 2
      ctx.strokeStyle = 'red'
      ctx.beginPath()
      ctx.moveTo(origin.x - this.floorNormal.x * lineLength / 2,
        origin.y - this.floorNormal.y * lineLength / 2)
      ctx.lineTo(origin.x + this.floorNormal.x * lineLength / 2,
        origin.y + this.floorNormal.y * lineLength / 2)
      ctx.stroke()
    },

    pointRadius (videoWidth, videoHeight) {
      return Math.min(videoWidth, videoHeight) / 80
    },

    saveViewerSettings () {
      this.storeViewerSettings({
        visibilityToggles: this.visibilityToggles
      })
    },
    restoreViewerSettings () {
      if (this.viewerSettings != null) {
        this.visibilityToggles = this.viewerSettings.visibilityToggles
      }
    },

    toggleNarrative: function () {
      this.$emit('toggle-narrative')
    },

    ...mapActions('scans2d', ['getOrLoadMovementVideoUri', 'loadMovementRawTracking', 'loadMovementTrackingPoints', 'loadMovementGeneralInfo', 'loadMovementMeasures']),
    ...mapMutations('scans2d', ['storeViewerSettings'])
  },

  created () {
    if (this.viewerSettings == null) {
      this.saveViewerSettings()
    } else {
      this.restoreViewerSettings()
    }
  },

  mounted () {
    if (this.movement != null) {
      this.onMovementChanged()
    }
  }
}
</script>
