// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueAsyncComputed from 'vue-async-computed'
import Vuelidate from 'vuelidate'
import VueStatic from 'vue-static'
import App from './App.vue'
import { init as initAxios } from './config/axios'
import SessionMonitor from './helpers/session-monitor'
import router from './router'
import { store } from './store/store'
import vuetify from './plugins/vuetify'
import environment from './plugins/environment'
import errorMessage from './plugins/error-message'
import msal from './plugins/msal'
import i18n from './plugins/i18n'
import moment from './plugins/moment'
import units from './plugins/units'
import isoCountries from './plugins/iso-countries'
import isoLanguages from './plugins/iso-languages'
import linkify from './plugins/linkify'

initAxios(Vue, store)

Vue.use(msal)
Vue.use(VueAsyncComputed)
Vue.use(Vuelidate)
Vue.use(environment)
Vue.use(errorMessage)
Vue.use(moment)
Vue.use(units)
Vue.use(isoCountries)
Vue.use(isoLanguages)
Vue.use(linkify)
Vue.use(VueStatic)

// Enforce sign out when a user is idle
SessionMonitor.whenIdle(() => Vue.prototype.$msal.signOut())

// eslint-disable-next-line no-new
new Vue({
  el: '#app-container',
  components: { App },
  template: '<App/>',
  store: store,
  router: router,
  vuetify: vuetify,
  i18n: i18n
})
