









































































import Vue, { PropType } from 'vue'
import { mapActions, mapState } from 'vuex'
import Lo from 'lodash'
import LibraryListItem from './library-list-item.vue'
import { SharingSeeker } from '@/models/data-sharing'
import { Library } from '@/models/admin'
import { MessageKind } from '@/helpers/enums'

export default Vue.extend({
  components: {
    'mpro-library-item': LibraryListItem
  },

  props: {
    value: { type: Boolean, default: false },
    seeker: Object as PropType<SharingSeeker>,
    organization: String
  },

  data: () => ({
    saving: false,
    selectedLibraryIds: new Array<string>(0),
    showNewLibrary: false,
    newLibraryName: '',
    creatingNewLibrary: false,
    trackDropDownOpenHandler: undefined as number | undefined
  }),

  computed: {
    ...mapState({
      // eslint-disable-next-line
      allLibraries: (state: any): Library[] => state.admin.libraries
    }),
    libraryItems: function (): Library[] {
      return Lo(this.allLibraries)
        .filter(l => l.OwnerCode.toLowerCase() === this.organization?.toLowerCase())
        .sortBy(l => l.Name.toLocaleLowerCase())
        .value()
    },
    itemLibraryIds: function (): string[] {
      return this.seeker?.LibraryIds ?? []
    },
    toBeAdded: function (): string[] {
      return Lo.difference(this.selectedLibraryIds, this.itemLibraryIds)
    },
    toBeRemoved: function (): string[] {
      return Lo.difference(this.itemLibraryIds, this.selectedLibraryIds)
    },
    processing: function (): boolean {
      return this.saving || this.creatingNewLibrary
    }
  },

  watch: {
    value (newValue: boolean) {
      if (newValue) {
        this.ensureLibrariesLoaded()
      }
    },
    itemLibraryIds () {
      this.selectedLibraryIds = this.itemLibraryIds.slice()
    },
    showNewLibrary (newValue) {
      if (!newValue) {
        this.stopTrackingDropDown()
      }
    }
  },

  methods: {
    ...mapActions('admin', ['ensureLibrariesLoaded', 'addSeekerToLibrary', 'removeSeekerFromLibrary', 'createLibrary']),
    ...mapActions(['showGlobalMessage']),
    onInput (newValue: boolean) {
      this.$emit('input', newValue)
    },
    close () {
      this.onInput(false)
    },
    async saveChanges () {
      const totalCount = this.toBeAdded.length + this.toBeRemoved.length
      const errors = []
      this.saving = true

      for (const id of this.toBeRemoved) {
        try {
          await this.removeSeekerFromLibrary({
            libraryId: id,
            seekerId: this.seeker.SeekerId
          })
        } catch (e) {
          errors.push(e)
        }
      }

      for (const id of this.toBeAdded) {
        try {
          await this.addSeekerToLibrary({
            libraryId: id,
            seekerId: this.seeker.SeekerId
          })
        } catch (e) {
          errors.push(e)
        }
      }

      this.saving = false

      if (errors.length === 0) {
        this.showGlobalMessage({ kind: MessageKind.SUCCESS, text: this.$t('forms.save_changes_success') })
      } else if (errors.length === totalCount) {
        this.showGlobalMessage({ kind: MessageKind.ERROR, text: this.$errorMessage(errors[0]) })
        return
      } else if (errors.length > 0) {
        this.showGlobalMessage({ kind: MessageKind.WARNING, text: this.$t('forms.save_changes_partial') })
      }
      this.close()

      if (totalCount > 0) {
        this.$emit('update')
      }
    },
    async enterCreateNewLibrary () {
      this.showNewLibrary = true
      await this.$nextTick()
      const field = this.$refs['newLibraryField'] as Vue
      const input = field.$el.querySelector('input:not([type=hidden])') as HTMLElement
      if (input != null) {
        input.focus()
        this.trackDropDownOpenHandler = setInterval(() => {
          const visible = input.offsetParent != null
          if (!visible) this.exitCreateNewLibrary()
        }, 500, [])
      }
    },
    exitCreateNewLibrary () {
      this.showNewLibrary = false
      this.newLibraryName = ''
    },
    stopTrackingDropDown () {
      if (this.trackDropDownOpenHandler != null) {
        clearInterval(this.trackDropDownOpenHandler)
      }
    },
    async createNewLibrary () {
      this.creatingNewLibrary = true
      let newId: number | undefined
      try {
        const res = await this.createLibrary({
          name: this.newLibraryName,
          owner: this.organization
        })
        newId = res.id
      } catch (e) {
        const message = this.$errorMessage(e)
        this.showGlobalMessage({ kind: MessageKind.ERROR, text: message })
        return
      } finally {
        this.creatingNewLibrary = false
      }

      this.showGlobalMessage({ kind: MessageKind.SUCCESS, text: this.$t('admin.sharing-seekers.edit-libraries-dialog.created-library') })
      this.exitCreateNewLibrary()

      if (newId != null) {
        await this.ensureLibrariesLoaded({ force: true })
        this.selectedLibraryIds.push(String(newId))
      }
    }
  },

  beforeDestroy () {
    this.stopTrackingDropDown()
  }
})
