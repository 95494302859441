<template>
  <div class="d-flex flex-column align-start">
    <div class="d-flex flex-wrap justify-start ml-6 mt-6">
      <mpro-date-box :label="$t('admin.statistics.from')" dense
                     :value="fromDate" @input="onFromInput"
                     field-class="mr-6"/>
      <mpro-date-box :label="$t('admin.statistics.till')" dense
                     :value="tillDate" @input="onTillInput"
                     :error-messages="dateRangeErrors"
                     field-class="mr-6"/>
    </div>
    <div class="mx-6 align-self-stretch">
      <v-text-field :label="$t('admin.statistics.search')" v-model.trim="search"
                    clearable prepend-inner-icon="mdi-filter"
                    class="max-text-width"/>
    </div>
    <v-skeleton-loader type="table-thead,table-tbody" :loading="loading">
      <v-data-table :items="rows" item-key="Code"
                    :headers="headers" hide-default-footer disable-pagination
                    :no-data-text="$t('admin.no_records')"
                    :search="search" :no-results-text="$t('forms.nothing_found')">
        <template #item.Name="{item}">
          <div>{{item.Name}}</div>
          <div class="grey--text">{{item.Code}}</div>
        </template>
        <template #item.IsPaying="{value}">
          <div class="w100pc text-center">
            <v-icon v-if="value">mdi-check</v-icon>
          </div>
        </template>
        <template #item.RegisteredSpecialists="{value}">
          <div class="w100pc text-end">{{ value }}</div>
        </template>
        <template #item.ActiveSpecialists="{value}">
          <div class="w100pc text-end">{{ value }}</div>
        </template>
        <template #item.AcceptedSeekers="{value}">
          <div class="w100pc text-end">{{ value }}</div>
        </template>
        <template #item.WidgetClicks="{item}">
          <div class="w100pc text-end">{{ item.WidgetClicks }} / {{ item.ConnectionRequests }}</div>
        </template>
        <template #item.ScanCount="{item, value}">
          <div class="w100pc text-end">
            <router-link :to="getScanListRoute(item.Code)">{{value}}</router-link>
          </div>
        </template>
        <template #item.LastScanCapturedAt="{value}">
          <span v-if="value">{{$formatDateTime(value)}}</span>
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Utils from '@/helpers/utils'
import DateBox from '@/components/elements/date-box'

export default {
  components: {
    'mpro-date-box': DateBox
  },

  data: () => ({
    search: '',
    loading: true
  }),

  computed: {
    fromDate: function () {
      const moment = this.$moment(this.$route.query.from, 'YYYY-MM-DD', true)
      return moment.isValid() ? moment.toDate() : null
    },
    tillDate: function () {
      const moment = this.$moment(this.$route.query.till, 'YYYY-MM-DD', true)
      return moment.isValid() ? moment.toDate() : null
    },
    dateRangeErrors: function () {
      let result = []
      if (this.tillDate < this.fromDate) {
        result.push(this.$t('forms.validations.emptyDateRange'))
      }
      return result
    },
    rows: function () {
      if (!Array.isArray(this.rawData)) return []

      return this.rawData.map(item => ({
        ...item,
        Country: this.$isoCountries.getName(item.CountryCode, this.$i18n.locale)
      }))
    },
    headers: function () {
      const result = [
        {value: 'Name', text: this.$t('admin.statistics.organization')},
        {value: 'Country', text: this.$t('admin.statistics.country')},
        {value: 'Kind', text: this.$t('admin.statistics.kind')}
      ]
      if (this.isQinematicStaff) {
        result.push({value: 'IsPaying', text: this.$t('admin.statistics.paying'), filterable: false})
      }
      result.push(
        {value: 'RegisteredSpecialists', text: this.$t('admin.statistics.registered_specialists'), filterable: false},
        {value: 'ActiveSpecialists', text: this.$t('admin.statistics.active_specialists'), filterable: false},
        {value: 'AcceptedSeekers', text: this.$t('admin.statistics.accepted_seekers')},
        {value: 'WidgetClicks', text: this.$t('admin.statistics.widget_counts')},
        {value: 'ScanCount', text: this.$t('admin.statistics.scan_count'), filterable: false},
        {value: 'LastScanCapturedAt', text: this.$t('admin.statistics.last_scan'), filterable: false}
      )
      return result
    },
    ...mapGetters('user', ['isQinematicStaff'])
  },

  asyncComputed: {
    rawData: async function () {
      if (this.fromDate && this.tillDate && this.fromDate <= this.tillDate) {
        const from = Utils.toIsoDate(this.fromDate)
        const till = Utils.toIsoDate(this.tillDate)
        this.loading = true
        const result = await this.loadStatisticsOverview({from, till})
        this.loading = false
        return result
      } else {
        return null
      }
    }
  },

  methods: {
    ensureValidDateRange () {
      if (!this.fromDate || !this.tillDate) {
        // reset to default period last ~30 days
        const today = this.$moment()
        const from = Utils.toIsoDate(this.$moment(today).subtract(1, 'months'))
        const till = Utils.toIsoDate(today)
        const route = this.createRoute(from, till)
        this.$router.replace(route)
      }
    },
    onFromInput (value) {
      const newDate = Utils.toIsoDate(value)
      if (newDate === Utils.toIsoDate(this.fromDate)) return
      const route = this.createRoute(newDate, this.$route.query.till)
      this.$router.push(route)
    },
    onTillInput (value) {
      const newDate = Utils.toIsoDate(value)
      if (newDate === Utils.toIsoDate(this.tillDate)) return
      const route = this.createRoute(this.$route.query.from, newDate)
      this.$router.push(route)
    },
    createRoute (from, till) {
      return {
        name: 'admin:Statistics',
        query: { from, till }
      }
    },
    getScanListRoute (organizationCode) {
      return {
        name: 'admin:ScanList',
        query: {
          organization: organizationCode,
          from: this.$route.query.from,
          till: this.$route.query.till
        }
      }
    },
    ...mapActions(['setHeaderTitle']),
    ...mapActions('admin', ['loadStatisticsOverview'])
  },

  beforeRouteEnter (to, from, next) {
    next(vm => vm.ensureValidDateRange(to))
  },

  mounted () {
    this.setHeaderTitle(this.$t('admin.statistics_title'))
  }
}
</script>
