<template>
    <div>
      <mpro-editable-container class="min-card-width"
          :validate-callback="validate" :save-callback="save"
          @end-edit="onEndEdit">
        <template #title><h2>{{$t('account.settings.portal-title')}}</h2></template>
        <template #default="{readonly}">
          <v-form>
            <div v-if="!readonly" key="editing">
              <v-select :label="$t('account.settings.units')" v-model="portalSettings.metric"
                        :readonly="readonly" :hide-details="readonly"
                        :items="metricOptions" class="d-inline-block mr-4"
                        @input="$v.portalSettings.metric.$touch()"
                        @blur="$v.portalSettings.metric.$touch()"
                        :error-messages="$validationErrors($v.portalSettings.metric)"/>
              <mpro-language-select class="d-inline-block"
                                    v-model="portalSettings.activeLocale"
                                    :label="$t('person_profile.language')"
                                    :readonly="readonly" :hide-details="readonly"
                                    @input="$v.portalSettings.activeLocale.$touch()"
                                    @blur="$v.portalSettings.activeLocale.$touch()"
                                    :error-messages="$validationErrors($v.portalSettings.activeLocale)"/>
            </div>
            <div v-else>
              <span class="text-caption text--secondary">{{$t('account.settings.units')}}</span>
              <p>{{isMetric ? 'Metric' : 'Imperial'}}</p>
              <template>
                <span class="text-caption text--secondary">{{$t('person_profile.language')}}</span>
                <p>{{$isoLanguages.getName(portalSettings.activeLocale, portalSettings.activeLocale)}}</p>
              </template>
            </div>
          </v-form>
        </template>
      </mpro-editable-container>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import EditableContainer from '@/components/elements/editable-container'
import LanguageSelect from '@/components/elements/language-select'
import {MessageKind} from '@/helpers/enums'
import {required} from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      readonly: false,
      portalSettings: {
        metric: null,
        activeLocale: ''
      },
      metricOptions: [
        {text: 'Metric', value: true},
        {text: 'Imperial', value: false}
      ]
    }
  },
  validations: {
    portalSettings: {
      activeLocale: {required},
      metric: {required}
    }
  },
  components: {
    'mpro-editable-container': EditableContainer,
    'mpro-language-select': LanguageSelect
  },
  computed: {
    ...mapGetters('user', ['getSettings', 'getLastLogin', 'isMetric']),
    ...mapState('user', {
      settingsUpdated: state => state.userSettings.settingsLoadedAt
    })
  },
  methods: {
    ...mapActions(['setHeaderTitle', 'showGlobalMessage']),
    ...mapActions('user', ['ensureUserSettingsLoaded', 'updateUserSetting']),
    portalSettingsToData () {
      this.portalSettings.activeLocale = this.getSettings('locale')
      this.portalSettings.metric = this.getSettings('metric')
    },
    validate () {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    async save () {
      try {
        for (let setting in this.portalSettings) {
          await this.updateUserSetting({
            setting: `portal.${setting}`,
            value: this.portalSettings[setting],
            throwOnError: true
          })
        }
        // Prevent the success message because
        // if the language setting is changed
        // the message is displayed in the previous language.
        return {
          success: true,
          showSuccessMessage: false
        }
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e)
        })
        return false
      }
    },
    async onEndEdit (saved) {
      if (saved) {
        await this.ensureUserSettingsLoaded({force: true})
        // Display the success message "manually" after the UI locale changes
        this.showGlobalMessage({
          kind: MessageKind.SUCCESS,
          text: this.$t('forms.save_changes_success')
        })
      } else {
        await this.portalSettingsToData()
      }
    }
  },
  watch: {
    settingsUpdated: function () {
      this.portalSettingsToData()
      this.$root.$i18n.locale = this.portalSettings.activeLocale
    }
  },
  mounted () {
    // this.setHeaderTitle(this.$t('account.settings.title'))
    this.portalSettingsToData()
  },
  created () {
    this.ensureUserSettingsLoaded()
  }
}
</script>

<style scoped>

</style>
