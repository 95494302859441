<template>
  <v-app class="main" :class="[{ darkmode: getSettings.darkmode}, getRole]" id="app">
    <template v-if="getRole != null">
      <mpro-header/>
      <mpro-menu/>
      <v-main>
        <div class="d-flex flex-column" style="min-height: 100%">
          <router-view class="flex-grow-1"></router-view>
          <mpro-footer/>
        </div>

        <!-- Pending survey dialog -->
        <v-dialog persistent max-width="400"
                  v-model="pendingSurveyDialog">
          <v-card>
            <v-card-title class="MProGreen mb-4"/>
            <v-card-text class="d-flex flex-column">
              <span class="ws-pre-line">{{ $t('dashboard.pushed_surveys', { name: getFirstName }) }}</span>
              <span v-if="pendingSurveyNotes != null" class="ws-pre-line">{{ pendingSurveyNotes }}</span>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn class="MProGreen" @click="takePendingSurvey">
                {{ $t('surveys.buttons.take-survey') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-main>
      <mpro-chat/>
    </template>
    <transition-group tag="div" class="alert-container ma-4"
                      name="shift-alerts">
      <v-alert v-for="message in getGlobalMessage" :key="message.id"
              :type="message.kind" :color="kindToColor(message.kind)">
        {{message.text}}
      </v-alert>
    </transition-group>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Menu from '../shared/menu.vue'
import Chats from '../shared/chat-menu.vue'
import Header from '../shared/header.vue'
import Footer from '../shared/footer.vue'
import { MessageKind } from '@/helpers/enums'
import Styles from '@/helpers/styles'
import Utils from '@/helpers/utils'
import UITransitions from '@/logic/UITransitions'

export default {
  name: 'DefaultLayout',

  components: {
    'mpro-header': Header,
    'mpro-menu': Menu,
    'mpro-chat': Chats,
    'mpro-footer': Footer
  },

  data: () => ({
    pendingSurveyDialog: false,
    pendingSurveyNotes: null,
    pendingSurveyLocation: null
  }),

  computed: {
    ...mapGetters(['getRole', 'isSeeker', 'isSpecialist', 'isAdministrator', 'getGlobalMessage']),
    ...mapGetters('user', ['getSettings']),
    ...mapState(['appInitialized', 'justSignedIn']),
    ...mapGetters('seeker', ['pushedSurveyAssignment']),
    ...mapGetters('user', ['getFirstName'])
  },

  watch: {
    appInitialized: function (newValue) {
      if (newValue === true) {
        this.onAppInitialized()
      }
    },
    getRole: function () {
      this.setThemeFromRole()
    }
  },

  methods: {
    ...mapActions(['showGlobalMessage']),
    kindToColor (kind) {
      return Styles.getMessageColor(kind)
    },
    setThemeFromRole () {
      if (this.isSeeker) {
        this.$vuetify.theme.dark = true
      } else if (this.isSpecialist || this.isAdministrator) {
        this.$vuetify.theme.dark = false
      }
    },
    async onAppInitialized () {
      if (this.$msal.isAuthenticated() && this.getRole == null) {
        // A user has no roles => sign out
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$t('login.user-has-no-role')
        })
        await Utils.delay(3000)
        this.$msal.signOut()
      } else if (this.isSeeker && this.justSignedIn === true) {
        this.showPendingSurvey()
      }
    },
    async showPendingSurvey () {
      await Utils.delay(3000)
      if (this.isSeeker && this.$route.name === 'Dashboard') {
        const uit = new UITransitions(this.$store)
        this.pendingSurveyLocation = await uit.getPushedSurveyLocation()
        if (this.pendingSurveyLocation != null) {
          this.pendingSurveyNotes = this.pushedSurveyAssignment.Notes
          this.pendingSurveyDialog = true
        }
      }
    },
    takePendingSurvey () {
      this.pendingSurveyDialog = false
      if (this.pendingSurveyLocation != null) {
        this.$router.push(this.pendingSurveyLocation)
      }
    }
  },

  created () {
    if (this.appInitialized) {
      this.onAppInitialized()
    }
  },

  mounted () {
    this.setThemeFromRole()
  }
}
</script>

<style scoped>
/*
App bar height = 48px
App bar z-index = 5
*/

.alert-container {
  position: fixed;
  top: 96px;
  right: 0;
  z-index: 300;
}
.shift-alerts-item {
  transition: all 0.5s;
}
.shift-alerts-enter, .shift-alerts-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.shift-alerts-leave-active {
  position: absolute;
}
</style>
