import { TranslateResult } from 'vue-i18n'
import { Location } from 'vue-router'
import { ScanType } from '@/helpers/enums'
import { DataSharingStatus } from './data-sharing'
import { Scan } from './scans'

export interface HeaderTab {
  value: string,
  to: Location,
  title: string | TranslateResult,
  icon: string
}

export interface IconInfo {
  name?: string,
  color?: string
}

export function getScanIcon (scan: Scan): IconInfo {
  switch (scan.ScanType) {
    case ScanType.DEPTH: return { name: 'mdi-rotate-3d' }
    case ScanType.MOBILE: return { name: 'mdi-cellphone' }
    default: return {}
  }
}

export enum ScanView3D {
  Front = 'front',
  Back = 'back',
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
  Balance = 'balance'
}

export enum ScanMeasureArrow {
  Left = 'left',
  Right = 'right',
  Up = 'up',
  Down = 'down'
}

export enum ScanMeasureType {
  Angle = 'angle',
  AngleDirection = 'angle-direction',
  Displacement = 'displacement'
}

export function getDataSharingStatusIcon (status: DataSharingStatus): IconInfo {
  switch (status) {
    case DataSharingStatus.New: return { name: 'mdi-help-circle-outline', color: 'MProYellow' }
    case DataSharingStatus.Active: return { name: 'mdi-check-circle-outline', color: 'MProGreen' }
    default: return {}
  }
}
