import {sign, check} from 'sign'
import createActivityDetector from 'activity-detector'

const StorageKeys = {
  LAST_ACTIVITY: 'mpro:last-activity',
  SESSION_DURATION: 'mpro:session-duration'
}

// Tracks user activity based on input events
let activityDetector

// Function to be called when a user has been inactive
let idleCallback

function secretFromUserId (userId) {
  let secret = `B8NV3n1210${userId}q0bAOn9QiA`
  return secret.slice(15) + secret.slice(0, 15)
}

// setStorage/getStorage functions use value signing
// to prevent tampering by a simple edit of values in browser developer tools
// This doesn't prevent more serious attacks involving app code analysis

async function setStorageValue (key, value, userId) {
  try {
    const storedValue = await sign(value, secretFromUserId(userId))
    window.localStorage.setItem(key, storedValue)
  } catch {
    // Do not log anything to prevent disclosure of functions accessing the storage
  }
}

async function getStorageNumber (key, userId) {
  try {
    const storedValue = window.localStorage.getItem(key)
    const valid = await check(storedValue, secretFromUserId(userId))
    let value
    if (valid) {
      const index = storedValue.indexOf('.')
      value = storedValue.slice(0, index)
    }
    if (!Number.isNaN(value)) return value
  } catch {
    // Do not log anything to prevent disclosure of functions accessing the storage
  }
  return undefined
}

function resetActivityDetector (timeout) {
  if (activityDetector != null) {
    activityDetector.stop()
    activityDetector = null
  }
  if (timeout > 0) {
    activityDetector = createActivityDetector({
      timeToIdle: timeout,
      // Prevent from triggering going to idle state when browser tab is deactivated,
      // only consider reaching timeout
      inactivityEvents: []
    })
    activityDetector.on('idle', () => {
      if (typeof idleCallback === 'function') {
        idleCallback()
      }
    })
  }
}

export default {
  async onAuthenticated (userId, issuedAt, expiresAt) {
    resetActivityDetector(expiresAt - issuedAt)
    await setStorageValue(StorageKeys.SESSION_DURATION, expiresAt - issuedAt, userId)
    await setStorageValue(StorageKeys.LAST_ACTIVITY, new Date().valueOf(), userId)
  },

  async onTokenAcquired (userId, issuedAt, expiresAt, fromCache) {
    const duration = await getStorageNumber(StorageKeys.SESSION_DURATION, userId) || 0
    const lastActivity = await getStorageNumber(StorageKeys.LAST_ACTIVITY, userId) || issuedAt
    const now = new Date().valueOf()
    if (now - lastActivity <= duration) {
      if (activityDetector == null) {
        resetActivityDetector(expiresAt - issuedAt)
      }
      if (!fromCache) {
        await setStorageValue(StorageKeys.SESSION_DURATION, expiresAt - issuedAt, userId)
      }
      await setStorageValue(StorageKeys.LAST_ACTIVITY, now, userId)
      return true
    } else {
      return false
    }
  },

  clear () {
    window.localStorage.removeItem(StorageKeys.SESSION_DURATION)
    window.localStorage.removeItem(StorageKeys.LAST_ACTIVITY)
  },

  whenIdle (callback) {
    idleCallback = callback
  }
}
