<template>
  <div class="d-flex">
    <v-tabs-items :value="activeTab">
      <v-tab-item :value="tabNames[0]">
        <mpro-details-tab :specialist="specialist"
                          @update="forceRefresh(true)"/>
      </v-tab-item>

      <v-tab-item :value="tabNames[1]">
        <div class="d-flex">
          <mpro-grant-right :specialist-email="specialist && specialist.Login"
                            @grant-right="forceRefresh()"/>
        </div>
        <v-skeleton-loader type="table-thead, table-tbody" :loading="loading">
          <mpro-rights-table :rights="rights" hide-subject
                             @revoke-right="forceRefresh()"/>
        </v-skeleton-loader>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import DetailsTab from './details-tab'
import RightsTable from '../rights/table'
import GrantRight from '../rights/grant-right'

export default {
  components: {
    'mpro-details-tab': DetailsTab,
    'mpro-rights-table': RightsTable,
    'mpro-grant-right': GrantRight
  },

  data: () => ({
    specialist: null
  }),

  computed: {
    specialistLogin: function () {
      const uuid = this.$route.params.uuid
      if (uuid == null) return null

      // Reference specialists for auto-recomputing
      if (this.specialists.length === 0) return null

      return this.getSpecialistByUuid(uuid)?.Login
    },
    loading: function () {
      return this.specialist == null
    },
    tabNames: function () {
      return ['details', 'rights']
    },
    tabIcons: function () {
      return ['mdi-card-text-outline', 'mdi-security']
    },
    rights: function () {
      return this.specialist?.rights ?? []
    },
    ...mapState(['activeTab']),
    ...mapState('admin', ['specialists']),
    ...mapGetters('admin', ['getSpecialistByUuid'])
  },

  watch: {
    specialistLogin: function () {
      this.updateHeaderTitle()
      this.updateSpecialist()
    },
    specialist: function () {
      this.updateHeaderTitle()
    },
    '$route.params.activeTab': function () {
      this.updateActiveTab()
    }
  },

  methods: {
    updateHeaderTitle () {
      const name = this.specialist?.Login === this.specialistLogin
        ? (this.specialist?.DisplayName ?? this.specialist?.Login)
        : ''
      this.setHeaderTitle(this.$t('admin.specialist.title', {name}))
    },
    async updateSpecialist () {
      if (this.specialistLogin == null) {
        this.specialist = null
        return
      }

      if (this.specialist?.Login !== this.specialistLogin) {
        this.specialist = this.specialists.find(s => s.Login === this.specialistLogin)
      }
      this.specialist = await this.loadSpecialist(this.specialistLogin)
    },
    ensureTabRoute (route) {
      const isValidTabName = route.name === 'admin:SpecialistTab' &&
        this.tabNames.includes(route.params.activeTab)
      if (!isValidTabName) {
        this.$router.replace({
          name: 'admin:SpecialistTab',
          params: {
            uuid: this.$route.params.uuid,
            activeTab: this.tabNames[0]
          }
        })
      }
    },
    updateActiveTab () {
      this.setActiveTab(this.$route.params.activeTab)
    },
    forceRefresh (withList) {
      this.updateSpecialist()
      if (withList) {
        this.ensureSpecialistsLoaded({ force: true })
      }
    },
    ...mapActions(['setHeaderTitle', 'setHeaderTabs', 'setActiveTab']),
    ...mapActions('admin', ['loadSpecialist', 'ensureSpecialistsLoaded'])
  },

  async created () {
    await this.ensureSpecialistsLoaded()
    await this.updateSpecialist()
  },

  mounted () {
    this.updateHeaderTitle()

    const tabs = this.tabNames.map((tab, index) => ({
      value: tab,
      to: {
        name: 'admin:SpecialistTab',
        params: {
          uuid: this.$route.params.uuid,
          activeTab: tab
        }
      },
      title: this.$t(`admin.specialist.tabs.${tab}`),
      icon: this.tabIcons[index]
    }))
    this.setHeaderTabs({tabs})
    this.updateActiveTab()
  },
  beforeDestroy () {
    this.setHeaderTabs()
  },
  beforeRouteEnter (to, _, next) {
    next(vm => vm.ensureTabRoute(to))
  },
  beforeRouteUpdate (to, _, next) {
    next()
    this.ensureTabRoute(to)
  }
}
</script>
