































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { required, helpers } from 'vuelidate/lib/validators'
import { TranslateResult } from 'vue-i18n'
import { MessageKind } from '@/helpers/enums'

export default Vue.extend({
  props: {
    value: Boolean
  },

  data: () => ({
    processing: false,
    form: {
      email: ''
    }
  }),

  validations: {
    form: {
      email: {
        required,
        same: function (v: string): boolean {
          return !helpers.req(v) || v.toLocaleLowerCase() === this.getUserEmail.toLocaleLowerCase()
        }
      }
    }
  },

  computed: {
    ...mapGetters('user', ['getUserEmail']),
    validationMessages: function (): { [key: string]: TranslateResult } {
      return {
        same: this.$t('account.control-account.delete-account.should-match')
      }
    }
  },

  watch: {
    value: function (newValue) {
      if (!newValue) {
        this.form.email = ''
        this.$v.$reset()
      }
    }
  },

  methods: {
    ...mapActions(['showGlobalMessage']),
    ...mapActions('user', ['deleteSeekerAccount']),
    onInput (newValue: boolean) {
      this.$emit('input', newValue)
    },
    close () {
      this.onInput(false)
    },
    async deleteAccount () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.processing = true
      try {
        await this.deleteSeekerAccount()
      } catch (e) {
        const message = this.$errorMessage(e)
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: message
        })
        return
      } finally {
        this.processing = false
      }

      this.close()
      await this.$msal.signOut()
    }
  }
})
