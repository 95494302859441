/*
* Defines routes specifically designed for external references
*/
import { NavigationGuardNext, Route } from 'vue-router'
import MyAccount from '@/components/users/account/account.vue'
import { store } from '@/store/store'
import { Role } from '@/helpers/enums'
import Utils from '@/helpers/utils'
import { MoovmentRouteConfig } from './types'
import { RouteNames } from './names'
import locationBuilder from './location-builder'

const routes: MoovmentRouteConfig[] = [
  // Move a Seeker's data to another account
  {
    path: '/account/move-data',
    name: RouteNames.MOVE_SEEKER_DATA,
    component: MyAccount,
    meta: {
      roles: [Role.SEEKER]
    }
  },
  {
    path: '/account/connect-with-organization',
    name: RouteNames.Widget.CONNECT_WITH_ORGANIZATION,
    meta: {
      anonymous: true
    },
    beforeEnter: async (to: Route, _, next: NavigationGuardNext<Vue>) => {
      const code = Utils.singleQueryValue(to.query.code)

      // Do not await intentionally
      store.dispatch('activities/registerClickConnectionWidget', { organizationCode: code })

      // Check the organization first
      const org = await store.dispatch('loadPublicOrganization', code)
      if (code == null || org == null) {
        // Invalid organization code => redirect to the dashboard
        next(locationBuilder.withReplace().dashboard())
        return
      }

      const currentRole = store.getters.getRole
      if (currentRole == null) {
        // Redirect anonymous to "Login/signup with connect"
        next(locationBuilder.withReplace().loginWithConnect(code))
        return
      }

      if (currentRole !== Role.SEEKER) {
        // Try to switch to the Seeker role if available
        const hasSeekerRole = store.getters['user/getAllRoles'].includes(Role.SEEKER)
        if (hasSeekerRole) {
          await store.dispatch('user/changeRole', Role.SEEKER)
        }
      }

      // Redirect to "My account with connect action"
      next(locationBuilder.withReplace().myAccount('connect-with-organization', code))
    }
  }
]

export default routes
