var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.items,"item-key":"key","headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","no-data-text":_vm.$t('admin.no_records')},scopedSlots:_vm._u([{key:"item.SpecialistName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"d-flex flex-column",attrs:{"to":_vm.getLocationOfSpecialist(item.Specialist)}},[_c('span',[_vm._v(_vm._s(item.SpecialistName))]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.Specialist))])])]}},{key:"item.OrganizationName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"d-flex flex-column",attrs:{"to":{name: 'admin:Organization', params: {code: item.OrganizationCode}}}},[_c('span',[_vm._v(_vm._s(item.OrganizationName))]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.OrganizationCode))])])]}},{key:"item.LibraryName",fn:function(ref){
var item = ref.item;
return [(item.LibraryId)?_c('router-link',{attrs:{"to":{name: 'admin:Library', params: {id: item.LibraryId}}}},[_vm._v(" "+_vm._s(item.LibraryName)+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t('admin.all_libraries')))])]}},{key:"item.GrantedByName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"d-flex flex-column",attrs:{"to":_vm.getLocationOfSpecialist(item.GrantedBy)}},[(item.GrantedByName)?_c('span',[_vm._v(_vm._s(item.GrantedByName))]):_vm._e(),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.GrantedBy))])])]}},{key:"item.GrantedAt",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$formatDateTime(value))+" ")]}},{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","loading":item.revoking,"disabled":item.revoking},on:{"click":function($event){return _vm.onRevokeRightClick(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }