function AppError (key, originalError) {
  this.key = key
  this.message = `Application error: ${key}`
  this.originalError = originalError
  this.stack = (new Error()).stack
}
AppError.prototype.constructor = AppError

const AppErrorKey = {
  LOGIN_IN_USE: 'login_in_use',
  INVALID_VERIFICATION_CODE: 'invalid_verification_code',
  SERVER_NO_RESPONSE: 'server_no_response',
  SERVER_ERROR: 'server_error',
  AGE_UNDER_18: 'age_under_18',
  FORBIDDEN: 'forbidden'
}

function handleAxiosError (e) {
  if (e.response == null) {
    throw new AppError(AppErrorKey.SERVER_NO_RESPONSE, e)
  } else if (e.response.status === 403) {
    throw new AppError(AppErrorKey.FORBIDDEN, e)
  } else if (e.response.status >= 400 && e.response.status < 500 && e.response.status !== 404) {
    throw new AppError(e.response.data || e.response.statusText, e)
  } else {
    throw new AppError(AppErrorKey.SERVER_ERROR, e)
  }
}

export {
  AppError,
  AppErrorKey,
  handleAxiosError
}
