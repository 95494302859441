import Vue from 'vue'
import axios from 'axios'
import { handleAxiosError } from '@/helpers/errors'

function binaryKeyForRT (scanId, resolution) {
  return `${scanId}/reprojectionTable${resolution}`
}

function binaryKeyForMovementDepthMap (scanId, movement, resolution) {
  return `${scanId}/movement/${movement}${resolution}`
}
function binaryKeyForTrackingPoints (scanId, movement) {
  return `${scanId}/trackingpoints/${movement}`
}
function binaryKeyForMeasures (scanId, movement, resolution) {
  return `${scanId}/measures/${movement}${resolution}`
}
function binaryKeyForNarrative (scanId, movement) {
  return `${scanId}/narrative/${movement}`
}

// state
const state = {
  data: {},
  binaries: {},
  viewerSettings: null
}

// Auxiliary data not in state

// MRU array contains older items in the beginning and newer items in the end
let mruBinaryKeys = []

function updateUseOfBinaryKey (key) {
  // Remove key from the list if exists
  const mruIndex = mruBinaryKeys.findIndex(k => k === key)
  if (mruIndex >= 0) mruBinaryKeys.splice(mruIndex)

  // Now put it at the end as the newest
  mruBinaryKeys.push(key)
}

function evictOldBinaries () {
  const limit = process.env.SCAN_BINARIES_LIMIT
  while (mruBinaryKeys.length > limit) {
    const key = mruBinaryKeys.shift()
    state.binaries[key] = undefined
  }
}

function storeBinary (key, value) {
  Vue.set(state.binaries, key, value)
  updateUseOfBinaryKey(key)
  evictOldBinaries()
}

function getBinary (key) {
  const result = state.binaries[key]
  if (result) updateUseOfBinaryKey(key)
  return result
}

// getters
const getters = {
  getScanData: state => (scanId, resolution = '') => state.data[`${scanId}${resolution}`],
  getReprojectionTable: () => (scanId, resolution) => {
    const key = binaryKeyForRT(scanId, resolution)
    return getBinary(key)
  },
  getMovementDepthMap: () => ({scanId, movement, resolution}) => {
    const key = binaryKeyForMovementDepthMap(scanId, movement, resolution)
    return getBinary(key)
  },
  getMovementTrackingPoints: () => ({scanId, movement}) => {
    const key = binaryKeyForTrackingPoints(scanId, movement)
    return getBinary(key)
  },
  getMovementMeasures: () => ({scanId, movement, resolution}) => {
    const key = binaryKeyForMeasures(scanId, movement, resolution)
    return getBinary(key)
  },
  getMovementNarrative: () => ({scanId, movement}) => {
    const key = binaryKeyForNarrative(scanId, movement)
    return getBinary(key)
  },
  viewerSettings: state => state.viewerSettings
}

// actions
const actions = {
  downloadPdfReport: async (_, {scanId, reportType, isMetric}) => {
    let response = null
    try {
      response = await axios.get('/scans/report', {
        params: {
          scan_id: scanId,
          report_type: reportType,
          is_metric: isMetric
        },
        responseType: 'blob'
      })
      return response.data
    } catch (e) {
      console.log('downloadPdfReport:axios', e)
      return undefined
    }
  },
  async loadScanStatus (context, scanId) {
    let response
    try {
      response = await axios.get('/scans/status', {
        params: { scan_id: scanId }
      })
    } catch (e) {
      console.warn('loadScanStatus:axios', e)
      return undefined
    }
    return response.data
  },
  async getOrLoadScanData ({ getters, commit }, {scanId, resolution = ''}) {
    let result = getters.getScanData(scanId, resolution)
    if (result) return result
    let response = null
    if (scanId) {
      try {
        response = await axios.get('/scans/scan', {
          params: {
            scan_id: scanId,
            resolution: resolution
          }
        })
        result = response.data
      } catch (e) {
        if (e.response != null && e.response.status === 404 && resolution !== '') {
          console.log('Lower resolution not available, try loading default resolution', e)
          return 404
        }
        console.log('getOrLoadScanData:axios', e)
        return undefined
      }
      commit('storeScanData', {scanId, resolution, scanData: result})
    }
    return result
  },
  async getOrLoadReprojectionTable ({commit, getters}, {scanId, progressCallback, resolution = ''}) {
    let result = getters.getReprojectionTable(scanId, resolution)
    if (result) return result

    let response = null
    try {
      response = await axios.get('/scans/reprojection', {
        params: {
          scan_id: scanId,
          resolution: resolution
        },
        responseType: 'arraybuffer',
        onDownloadProgress: (progressEvent) => {
          if (progressCallback) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            progressCallback(percentCompleted)
          }
        }
      })
    } catch (e) {
      if (e.response != null && e.response.status === 404 && resolution !== '') {
        console.log('Lower resolution not available, try loading default resolution', e)
        return 404
      }

      console.log('getOrLoadReprojectionTable:axios', e)
      return undefined
    }

    try {
      // const buffer = await response.data.arrayBuffer()
      result = new Float32Array(response.data)
    } catch (e) {
      console.log('getOrLoadReprojectionTable:convert', e)
      return undefined
    }

    commit('storeReprojectionTable', {scanId, resolution, value: result})
    return result
  },

  async getOrLoadMovementDepthMap ({commit, getters}, {scanId, movement, progressCallback, resolution = ''}) {
    let result = getters.getMovementDepthMap({scanId, movement, resolution})
    if (result != null) return result

    let response = null
    try {
      response = await axios.get('/scans/movementdata', {
        params: {
          scan_id: scanId,
          data_type: 'BodyDepthsBin',
          movement: movement,
          resolution: resolution
        },
        responseType: 'arraybuffer',
        onDownloadProgress: (progressEvent) => {
          if (progressCallback) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            progressCallback(percentCompleted)
          }
        }
      })
    } catch (e) {
      if (e.response != null && e.response.status === 404 && resolution !== '') {
        console.log('Lower resolution not available, try loading default resolution', e)
        return 404
      }
      console.log('getOrLoadMovementDepthMap:axios', e)
      return undefined
    }

    try {
      result = new Uint16Array(response.data)
    } catch (e) {
      console.log('getOrLoadMovementDepthMap:convert', e)
      return undefined
    }

    commit('storeMovementDepthMap', {scanId, movement, resolution, value: result})
    return result
  },
  async getOrLoadMovementTrackingPoints ({commit, getters}, {scanId, movement, progressCallback}) {
    let result = getters.getMovementTrackingPoints({scanId, movement})
    if (result) return result

    let response = null
    try {
      response = await axios.get('/scans/movementdata', {
        params: {
          scan_id: scanId,
          data_type: 'V2TrackingPointsJson',
          movement: movement
        },
        // responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          if (progressCallback) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            progressCallback(percentCompleted)
          }
        }
      })
    } catch (e) {
      console.log('getOrLoadMovementTrackingPoints:axios', e)
      return undefined
    }
    commit('storeMovementTrackingPoints', {scanId, movement, value: response.data})
    return response.data
  },
  async getOrLoadMovementMeasures ({commit, getters}, {scanId, movement, progressCallback, resolution = ''}) {
    let result = getters.getMovementMeasures({scanId, movement, resolution})
    if (result) return result

    let response = null
    try {
      response = await axios.get('/scans/movementdata', {
        params: {
          scan_id: scanId,
          data_type: 'V2MeasuresJson',
          movement: movement
        },
        // responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          if (progressCallback) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            progressCallback(percentCompleted)
          }
        }
      })
    } catch (e) {
      if (e.response != null && e.response.status === 404 && resolution !== '') {
        console.log('Lower resolution not available, try loading default resolution', e)
        return 404
      }
      console.log('getOrLoadMovementMeasures:axios', e)
      return undefined
    }
    result = null
    result = response.data
    commit('storeMovementMeasures', {scanId, movement, resolution, value: result})
    return result
  },
  async getScanComments (context, {scanId}) {
    let response = null
    try {
      response = await axios.get('/scans/comments', {
        params: {
          scan_id: scanId
        }
      })
    } catch (e) {
      console.warn('getComments:axios', e)
      return undefined
    }
    return response.data
  },
  async postScanComment (_, {scanId, message}) {
    try {
      await axios.post('/scans/comment', {
        ScanId: scanId,
        Comment: message
      })
    } catch (e) {
      handleAxiosError(e)
    }
  },
  async getOrLoadMovementNarrative ({commit, getters}, {scanId, movement}) {
    let result = getters.getMovementNarrative({scanId, movement})
    if (result) return result

    let response = null
    try {
      response = await axios.get('/scans/movement_narrative', {
        params: {
          scan_id: scanId,
          movement: movement
        }
      })
    } catch (e) {
      console.log('getOrLoadMovementNarrative:axios', e)
      commit('storeMovementNarrative', {scanId, movement, value: 'error'})
      return 'error'
    }
    result = null
    result = response.data
    commit('storeMovementNarrative', {scanId, movement, value: result})
    return result
  }
}

// mutations
const mutations = {
  storeScanData (state, {scanId, resolution, scanData}) {
    Vue.set(state.data, scanId + resolution, scanData)
  },
  storeReprojectionTable (state, {scanId, resolution, value}) {
    const key = binaryKeyForRT(scanId, resolution)
    storeBinary(key, value)
  },
  storeMovementDepthMap (state, {scanId, movement, resolution, value}) {
    const key = binaryKeyForMovementDepthMap(scanId, movement, resolution)
    storeBinary(key, value)
  },
  storeMovementTrackingPoints (state, {scanId, movement, value}) {
    const key = binaryKeyForTrackingPoints(scanId, movement)
    storeBinary(key, value)
  },
  storeMovementMeasures (state, {scanId, movement, resolution, value}) {
    const key = binaryKeyForMeasures(scanId, movement, resolution)
    storeBinary(key, value)
  },
  storeMovementNarrative (state, {scanId, movement, value}) {
    const key = binaryKeyForNarrative(scanId, movement)
    storeBinary(key, value)
  },
  storeViewerSettings (state, value) {
    state.viewerSettings = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
