










import Vue from 'vue'
import { mapState } from 'vuex'
import Lo from 'lodash'
import { ListItem } from '@/models/vuetify'

export default Vue.extend({
  props: {
    value: String,
    label: String,
    required: String,
    errorMessages: Array,
    readonly: Boolean,
    hideDetails: Boolean,
    disabled: Boolean
  },
  computed: {
    items: function (): ListItem[] {
      return Lo(Object.keys(this.$isoLanguages.getAlpha2Codes()))
        .filter(code => this.$i18n.availableLocales.includes(code))
        .map(code => ({
          value: code,
          text: this.$isoLanguages.getName(code, code) ?? ''
        }))
        .sortBy(a => a.text)
        .value()
    },
    ...mapState(['validCountryCodes'])
  }
})
