<template>
  <v-form>
    <mpro-organization-select :label="$t('admin.library.owner')" v-model="data.owner"
                              :organizations="organizations"
                              readonly :hide-details="readonly"/>
    <v-text-field :label="$t('admin.library.name')" v-model.trim="data.name"
                  :readonly="readonly" :hide-details="readonly"
                  @input="$v.data.name.$touch()" @blur="$v.data.name.$touch()"
                  :error-messages="$validationErrors($v.data.name)"/>
    <v-textarea :label="$t('admin.library.description')" v-model.trim="data.description"
                :readonly="readonly" :hide-details="readonly"
                @input="$v.data.description.$touch()" @blur="$v.data.description.$touch()"
                :error-messages="$validationErrors($v.data.description)"/>
  </v-form>
</template>

<script>
import OrganizationSelect from '@/components/elements/organization-select'
import { mapState, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    'mpro-organization-select': OrganizationSelect
  },

  props: {
    ownerCode: {type: String, default: null},
    library: {type: Object, default: null},
    readonly: {type: Boolean, default: false}
  },

  data: () => ({
    data: {
      name: '',
      owner: '',
      description: ''
    }
  }),

  validations: {
    data: {
      name: {required},
      owner: {required},
      description: {}
    }
  },

  computed: {
    ...mapState('admin', ['organizations'])
  },

  watch: {
    library: function () {
      this.syncLibraryToData()
    },
    ownerCode: function () {
      this.syncLibraryToData()
    }
  },

  methods: {
    syncLibraryToData () {
      if (this.library) {
        this.data.name = this.library.Name
        this.data.owner = this.library.OwnerCode
        this.data.description = this.library.Description
      } else {
        for (const key in this.data) {
          this.data[key] = ''
        }
        if (this.ownerCode != null) {
          this.data.owner = this.ownerCode
        }
      }
    },
    validate () {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    async reset () {
      this.syncLibraryToData()
      await this.$nextTick()
      this.$v.$reset()
    },
    ...mapActions('admin', ['ensureOrganizationsLoaded'])
  },

  created () {
    this.syncLibraryToData()
    this.ensureOrganizationsLoaded()
  }
}
</script>
