// Adds $isoCountries prop containing a configured
// Ensure Vue.use for this plugin is invoked after vue-i18n

import countries from 'i18n-iso-countries'
import en from 'i18n-iso-countries/langs/en.json'
import de from 'i18n-iso-countries/langs/de.json'
import sv from 'i18n-iso-countries/langs/sv.json'

export default {
  install: function (Vue) {
    countries.registerLocale(en)
    countries.registerLocale(de)
    countries.registerLocale(sv)
    Vue.prototype.$isoCountries = countries
  }
}
