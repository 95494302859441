<template>
  <v-sheet class="pa-2 d-flex flex-column" color="white"
           style="overflow: hidden">
    <div class="d-flex justify-space-between pl-3 pt-3">
      <p class="title">{{$t('scans.single.narrative_title', { movement: $t('scans.movements.' + movementKind) })}}</p>
      <v-btn color="MProMainAlt" icon
             @click="$emit('close-narrative')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="flex-grow-1 p-relative">
      <v-skeleton-loader v-if="narrativeText == null"
                         type="heading, paragraph@3" class="pa-3"/>
      <div v-else-if="narrativeText === 'error'" class="pa-3">
        Nothing available
      </div>
      <v-container v-else
                   class="p-absolute overflow-scroll"
                   style="left: 0; top: 0; right: 0; bottom: 0">
<!-- -->
<!-- -->
<!-- MOVEMENT: STANDSTILL -->
        <v-row v-if="movement === 'StandStill'">
          <v-col cols="12" class="pt-0 mt-0">
            <p class="text-subtitle-1">Balance and positioning</p>
            <p>Balance was {{narrativeText[movement + '_2_String1']}} maintained.
<!-- Indicator "Improve" for StandStill_2_String1 -->
                <mpro-indicator v-if="narrativeText[movement + '_2_String1'] != ''"
                  kind="improve" title="Balance should be improved">
                  Balance is not considered optimal. Seek professional advice.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for StandStill_2_String1 -->
                <mpro-indicator v-else kind="maintain"
                  title="Balance should be maintained">
                  Balance is considered optimal. Maintain.<br><br>
                </mpro-indicator><br>
                The peak sway velocity of the Centre of Gravity was {{narrativeText[movement + '_2_String2']}}.
<!-- Indicator "Improve" for StandStill_2_String2 -->
                <mpro-indicator v-if="narrativeText[movement + '_2_String2'] != 'optimal'"
                    kind="improve" title="Sway velocity is too high">
                    Optimal peak sway velocity is less than 2.4 cm/sec for medio-lateral movement and 3.6 cm/sec for anterior-posterior movement. The sway velocity was too high at some time during the task. Seek professional advice.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for StandStill_2_String2 -->
                <mpro-indicator v-else kind="maintain"
                    title="Sway velocity is optimal">
                    The peak sway velocity was less than 2.4 cm/sec for medio-lateral movement and 3.6 cm/sec for anterior-posterior movement. Maintain.<br><br>
                </mpro-indicator>
                The average Centre of Gravity position was {{narrativeText[movement + '_3_String1']}} and {{narrativeText[(movement + '_3_String2')]}} relative to the midline.
<!-- Indicator "Improve" for StandStill_3_String1 & StandStill_3_String2 -->
                <mpro-indicator v-if="(narrativeText[movement + '_3_String1'] != 'centered') && (narrativeText[movement + '_3_String2'] != 'centered')"
                    kind="improve" title="Centre of Gravity position is not optimal">
                    The Centre of Gravity was not mainained in a central position relative to the midline. Seek professional advice.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for StandStill_3_String1 & StandStill_3_String2 -->
                <mpro-indicator v-else
                    kind="maintain" title="Centre of Gravity position is optimal">
                    The Centre of Gravity was mainained in a central position.<br><br>
                </mpro-indicator>
                The body weight was distributed {{Math.round(narrativeText.WeightDistributionPercentLeft)}}% on the left leg and {{Math.round(narrativeText.WeightDistributionPercentRight)}}% on the right leg.
<!-- Indicator "Improve" for StandStill_WeightDistributionPercentLeft & tandStill_WeightDistributionPercentRight -->
                <mpro-indicator v-if="(Math.round(parseFloat(narrativeText.WeightDistributionPercentLeft)) <= 45) || (Math.round(parseFloat(narrativeText.WeightDistributionPercentLeft)) >= 55)"
                    kind="improve" title="Weight distribution is not equal">
                    Optimal body weight distribution is within 45-55% (50/50 ± 5%) on each leg. Weight distribution was too much to one side. Seek professional advice.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for StandStill_WeightDistributionPercentLeft & tandStill_WeightDistributionPercentRight -->
                <mpro-indicator v-else kind="maintain"
                    title="Weight distribution is equal">
                    Optimal body weight distribution is within 45-55% (50/50 ± 5%) on each leg. Maintain<br><br>
                </mpro-indicator>
                <br>
             The distance between the feet was with {{$formatLength(narrativeText.DistanceBetweenFeetCm, 'cm', isMetric)}} {{narrativeText[movement + '_4_String1']}}.
<!-- Indicator "Improve" for StandStill_4_String1 -->
                <mpro-indicator v-if="narrativeText[movement + '_4_String1'] != 'as instructed'"
                    kind="improve" title="Not optimal stance width of the feet">
                    In order to standardise the test procedure and improve validity and reliability, it is necessary to stand with 15cm between the feet. These instructions were not followed, reducing the validity and reliability of the test.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for StandStill_4_String1 -->
                <mpro-indicator v-else kind="maintain"
                    title="Optimal stance width of the feet">
                    In order to standardise the test procedure and improve validity and reliability of the test, it is necessary to stand with 15 cm between the feet. These instructions were followed.<br><br>
                </mpro-indicator>
             The feet were {{narrativeText[movement + '_4_String2']}} facing forward.
<!-- Indicator "Improve" for StandStill_4_String2 -->
                <mpro-indicator v-if="narrativeText[movement + '_4_String2'] != ''"
                    kind="improve" title="Not optimal stance angle of the feet">
                    In order to standardise the test procedure and improve validity and reliability, it is necessary to stand  with the feet pointing forward. The inside border of the foot should be no more than 10 degrees outwards. These instructions were followed.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for StandStill_4_String2 -->
                <mpro-indicator v-else kind="maintain"
                    title="Optimal stance angle of the feet">
                    In order to standardise the test procedure and improve validity and reliability, it is necessary to stand  with the feet pointing forward. The inside border of the foot should be no more than 10 degrees outwards. These instructions were followed.<br><br>
                </mpro-indicator>
            </p>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
            <p class="text-subtitle-1">Frontal view postural alignment</p>
            <p> The pelvis was {{narrativeText[movement + '_7_String1']}} the midline.
<!-- Indicator "Improve" for StandStill_7_String1 -->
                <mpro-indicator v-if="narrativeText[movement + '_7_String1'] != 'on'"
                    kind="improve" title="Frontal alignment of the pelvis not optimal">
                    The optimal frontal plane  position of the pelvis is on the midline. The pelvis was not on the midline during this assessment. Seek professional advice.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for StandStill_7_String1 -->
                <mpro-indicator v-else kind="maintain"
                    title="Frontal alignment of the pelvis optimal">
                    In the frontal plane, the pelvis is on the midline. Maintain.<br><br>
                </mpro-indicator>
              <br>
            The upper spine was {{narrativeText[movement + '_8_String1']}} the midline and {{narrativeText[movement + '_8_String2']}} the pelvis.
<!-- Indicator "Improve" for StandStill_8_String1 & StandStill_8_String2-->
            <mpro-indicator v-if="(narrativeText[movement + '_8_String1'] != 'on') && (narrativeText[movement + '_8_String2'] == 'above')"
                kind="improve" title="Frontal alignment of the upper spine not optimal">
                In the frontal plane, the pelvis is on the midline. The trunk has deviated away from the midline. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for StandStill_8_String1 & StandStill_8_String2 -->
            <mpro-indicator v-else-if="(narrativeText[movement + '_8_String1'] == 'on') && (narrativeText[movement + '_8_String2'] != 'above')"
                kind="improve" title="Frontal alignment of the upper spine not optimal">
                In the frontal plane, the base of the neck is on the midline. The pelvis is not aligned with the midline. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for StandStill_8_String1 & StandStill_8_String2 -->
            <mpro-indicator v-else-if="(narrativeText[movement + '_8_String1'] != 'on') && (narrativeText[movement + '_8_String2'] != 'above')"
                kind="improve" title="Frontal alignment of the upper spine not optimal">
                In the frontal plane, both pelvis and trunk deviate from the midline. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" for StandStill_8_String1 & StandStill_8_String2 -->
            <mpro-indicator v-else kind="maintain"
                title="Frontal alignment of the upper spine optimal">
                In the frontal plane, both pelvis and trunk are near the midline. Maintain.<br><br>
            </mpro-indicator><br>
            The head was {{narrativeText[movement + '_9_String1']}} the midline and {{narrativeText[movement + '_9_String2']}} the shoulders.
<!-- Indicator "Improve" for StandStill_9_String1 & StandStill_9_String2-->
            <mpro-indicator v-if="(narrativeText[movement + '_9_String1'] != 'on') && (narrativeText[movement + '_9_String2'] == 'above')"
                kind="improve" title="Frontal alignment of the neck and head not optimal">
                In the frontal plane, the trunk is aligned with the midline. The head and neck deviate from the trunk and the midline. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for for StandStill_9_String1 & StandStill_9_String -->
            <mpro-indicator v-else-if="(narrativeText[movement + '_9_String1'] == 'on') && (narrativeText[movement + '_9_String2'] != 'above')"
                kind="improve" title="Frontal alignment of the upper spine not optimal">
                In the frontal plane, head and neck are on  the midline. The pelis deviates from the midline. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for for StandStill_9_String1 & StandStill_9_String -->
            <mpro-indicator v-else-if="(narrativeText[movement + '_9_String1'] != 'on') && (narrativeText[movement + '_9_String2'] != 'above')"
                kind="improve" title="Frontal alignment of the upper spine not optimal">
               In the frontal plane, the base of the neck is not aligned with the midline. The head and neck also deviate from  the midline. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" for StandStill_9_String1 & StandStill_9_String2 -->
            <mpro-indicator v-else kind="maintain"
                title="Frontal alignment of the upper spine optimal">
                In the frontal plane, the trunk is aligned with the midline. The head and neck are aligned with the trunk and close to the midline. Seek professional advice.<br><br>
            </mpro-indicator><br>
            </p>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
            <p class="text-subtitle-1">Side view postural alignment</p>
            <p>{{narrativeText[movement + '_5_String1'] === 'straight' && narrativeText[movement + '_5_String2'] === 'straight' ? 'Both left and right knee were straight' : narrativeText[movement + '_5_String1'] === 'flexed' && narrativeText[movement + '_5_String2'] === 'flexed' ? 'Both left and right knee were flexed' : 'The left knee was ' + narrativeText[movement + '_5_String1'] + ' and the right knee was ' + narrativeText[movement + '_5_String2']}}.
<!-- Indicator "Improve" for StandStill_5_String1 & StandStill_5_String2 -->
              <mpro-indicator v-if="(narrativeText[movement + '_5_String1'] != 'straight') || (narrativeText[movement + '_5_String2'] != 'straight')"
                  kind="improve" title="Legs were not straight">
                  One or both knees were not kept straight during this standing task. The instructions were not followed or there are significant physical limitations that may benefit from professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" for StandStill_5_String1 & StandStill_5_String2 -->
              <mpro-indicator v-else kind="maintain"
                  title="Legs were straight">
                  Both knees were straight during this standing task. These instructions were followed.<br><br>
              </mpro-indicator>
              <br>
            The pelvis was {{narrativeText[movement + '_11_String1']}} the midline.
<!-- Indicator "Improve" for StandStill_11_String1 -->
            <mpro-indicator v-if="narrativeText[movement + '_11_String1'] != 'centered over'"
                kind="improve" title="Sagittal alignment of the pelvis is not optimal">
                In the sagittal plane, the hips were not aligned with the midline. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" (all rest but not the ones above) for StandStill_11_String1 -->
            <mpro-indicator v-else kind="maintain"
                title="Sagittal alignment of the pelvis is optimal">
                The optimal alignment of the hips in the sagittal plane is on the midline. Maintain.<br><br>
            </mpro-indicator>
            <br>
            The upper spine was {{narrativeText[movement + '_12_String1']}} the midline and {{narrativeText[movement + '_12_String2']}} the pelvis.
<!-- Indicator "Improve" for StandStill_12_String1 & StandStill_12_String2-->
            <mpro-indicator v-if="(narrativeText[movement + '_12_String1'] != 'on') && (narrativeText[movement + '_12_String2'] == 'above')"
                kind="improve" title="Sagittal alignment of the upper spine is not optimal">
                In the sagittal plane, the base of the neck is not on the midline. The hips are on the midline. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for StandStill_12_String1 & StandStill_12_String2 -->
            <mpro-indicator v-else-if="(narrativeText[movement + '_12_String1'] == 'on') && (narrativeText[movement + '_12_String2'] != 'above')"
                kind="improve" title="Sagittal alignment of the upper spine is not optimal">
                In the sagittal plane, the base of the neck is on the midline. The hips are not on the midline.  Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for StandStill_12_String1 & StandStill_12_String2 -->
            <mpro-indicator v-else-if="(narrativeText[movement + '_12_String1'] != 'on') && (narrativeText[movement + '_12_String2'] != 'above')"
                kind="improve" title="Sagittal alignment of the upper spine is not optimal">
                In the sagittal plane, both hips and trunk deviate from the midline. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" for StandStill_12_String1 & StandStill_12_String2 -->
            <mpro-indicator v-else kind="maintain"
                title="Sagittal alignment of the upper spine is optimal">
                In the sagittal plane, both pelvis and upper spine are close to the midline. Maintain<br><br>
            </mpro-indicator><br>
            The head was {{narrativeText[movement + '_13_String1']}} the midline and {{narrativeText[movement + '_13_String2']}} the spine.
<!-- Indicator "Improve" for StandStill_13_String1 & StandStill_13_String2-->
            <mpro-indicator v-if="(narrativeText[movement + '_13_String1'] != 'on') && (narrativeText[movement + '_13_String2'] == 'above')"
                kind="improve" title="Sagittal alignment of the neck and head is not optimal">
                In the sagittal plane, the trunk is on the midline, however the head and neck deviate from the midline. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for for StandStill_13_String1 & StandStill_13_String2 -->
            <mpro-indicator v-else-if="(narrativeText[movement + '_13_String1'] == 'on') && (narrativeText[movement + '_13_String2'] != 'above')"
                kind="improve" title="Sagittal alignment of the upper spine is not optimal">
                In the sagittal plane, the head is on the midline, but not the base of the neck. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for for StandStill_13_String1 & StandStill_13_String2 -->
            <mpro-indicator v-else-if="(narrativeText[movement + '_13_String1'] != 'on') && (narrativeText[movement + '_13_String2'] != 'above')"
                kind="improve" title="Sagittal alignment of the upper spine is not optimal">
                In the sagittal plane, both head and base of neck deviate from the midline. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" for StandStill_13_String1 & StandStill_13_String2 -->
            <mpro-indicator v-else kind="maintain"
                title="Frontal alignment of the upper spine is optimal">
                In the sagittal plane, both head and base of the neck are close to the midline. Maintain.<br><br>
            </mpro-indicator></p>
          </v-col>
        </v-row>
<!-- -->
<!-- -->
<!-- MOVEMENT: SIDEBEND -->
        <v-row v-if="movement === 'SideBend'">
          <v-col cols="12" class="pt-0 mt-0">
          There was {{narrativeText[movement + '_1_String1']}} excessive lateral movement of either arm during bending.
<!-- Indicator "Improve" for SideBend_1_String1 -->
            <mpro-indicator v-if="narrativeText[movement + '_1_String1'] != 'no'"
                kind="improve" title="Excessive movement of the arms">
                Excessive sideways movement of the arms or bending the elbows can change the results for the distance reached by the hands. The arms should be straight and reaching directly down to the floor with thumbs facing out and not touching the legs.  One or both arms appears to have move excessively, and the assessment should be repeated or the error taken into consideration.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" for SideBend_1_String1 -->
            <mpro-indicator v-else
                kind="maintain" title="Arm movement minimal">
                The was no excessive lateral movement of the arms during the assessment.<br><br>
            </mpro-indicator>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
            <p class="text-subtitle-1">Fingertips and shoulder correspondance</p>
            The distance travelled by the shoulders {{narrativeText[movement + '_2_String1']}} correspond with the distance travelled by the hands.
<!-- Indicator "Improve" for SideBend_2_String1 -->
            <mpro-indicator v-if="narrativeText[movement + '_2_String1'] != 'did'"
                kind="improve" title="Missing correspondance between hands and shoulders">
                When executed as instructed the fingertip-to-floor-distance should correspond with the shoulder distance travelled. When comparing sides, a larger distance travelled by the finger tips on one side should also correspond with larger shoulder movement on the same side. There was a discrepency during this assessment. This may be due to flexion, extension or rotation of the trunk or hips, or execessive movement of the arms or legs. Repeat the test and/or seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" for SideBend_2_String1 -->
            <mpro-indicator v-else kind="maintain"
                title="Correspondance between fingertip-to-floor-distance reached and shoulder movement">
                When executed as instructed the fingertip-to-floor-distance should correspond with the shoulder distance travelled. When comparing sides, a larger distance travelled by the finger tips on one side should also correspond with larger shoulder movement on the same side. There was good correspondence during this assessment.<br><br>
            </mpro-indicator>
            The distance travelled by the left shoulder was {{narrativeText[movement + '_3_String1']}} the right shoulder (distance difference: {{$formatLength(narrativeText.AbsDistanceDifferenceShouldersCm, 'cm', isMetric)}}).
<!-- Indicator "Improve" for SideBend_3_String1 -->
            <mpro-indicator v-if="narrativeText[movement + '_3_String1'] != 'similar to'"
                kind="improve" title="Asymmetry in shoulder movement">
                Optimal movement is considered to show similar distances and movement strategies on both sides. Asymmetry was detected during this assessment.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" for SideBend_3_String1 -->
            <mpro-indicator v-else kind="maintain"
                title="Symmetry in shoulder movement">
                There was symmetry between vertical distance travelled by left and right shoulders.<br><br>
            </mpro-indicator>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
          <p class="text-subtitle-1">Fingertip to floor distances</p>
          The fingertip to floor distance of the left hand was {{narrativeText[movement + '_3_String2']}} the fingertip to floor distance of the right hand (distance difference: {{$formatLength(narrativeText.AbsDistanceDifferenceHandsCm, 'cm', isMetric)}}).
<!-- Indicator "Improve" for SideBend_3_String2 -->
            <mpro-indicator v-if="narrativeText[movement + '_3_String2'] !='similar to'"
                kind="improve" title="Asymmetry in fingertip-to-floor-distance reached">
                There was a significant difference in reach distance to the floor between left and right sides. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" for SideBend_3_String2 -->
            <mpro-indicator v-else kind="maintain"
                title="Symmetry in fingertip-to-floor-distance reached">
                There was similar reach distance to the floor between left and right sides. Maintain.<br><br>
            </mpro-indicator>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
          <p class="text-subtitle-1">Pelvis, Trunk, Centre of Gravity (Left bending)</p>
          During left side bend the pelvis {{narrativeText[movement + '_4_String1']}} and the upper body {{narrativeText[movement + '_4_String2']}}. The Centre of Gravity {{narrativeText[movement + '_4_String3']}} and {{narrativeText[movement + '_4_String4']}} the start position (midline).
<!-- Indicator "Improve" for SideBend_4_String1, SideBend_4_String2, SideBend_4_String3, SideBend_4_String4 -->
            <mpro-indicator v-if="(narrativeText[movement + '_4_String1'] == 'moved towards the bending side') || (narrativeText[movement + '_4_String2'] != 'did not move forward or backward') || (narrativeText[movement + '_4_String3'] == 'moved away from the bending side') || (narrativeText[movement + '_4_String4'] != 'was centered relative to')"
                kind="improve" title="Sidebending is not optimal">
                Optimal sidebend performance involves staying in plane so that the body moves sideways and not forwards or backwards, and that the hips stay relatively still or move in the opposite direction to the reach. Sidebend was not performed optimally. Repeat the assessment and/or seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" for SideBend_4_String1, SideBend_4_String2, SideBend_4_String3, SideBend_4_String4  -->
            <mpro-indicator v-else kind="maintain" title="Sidebending is optimal">
                Optimal sidebend performance involves staying in plane so that the body moves sideways and not forwards or backwards, and that the hips stay relatively still or move in the opposite direction to the reach. Sidebend was performed optimally.<br><br>
            </mpro-indicator>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
          <p class="text-subtitle-1">Pelvis, Trunk, Centre of Gravity (Right bending)</p>
          During right side bend the pelvis {{narrativeText[movement + '_5_String1']}} and the trunk {{narrativeText[movement + '_5_String2']}}. The Centre of Gravity {{narrativeText[movement + '_5_String3']}} and {{narrativeText[movement + '_5_String4']}} the start position (midline).
<!-- Indicator "Maintain" for SideBend_5_String1, SideBend_5_String2, SideBend_5_String3, SideBend_5_String4 -->
            <mpro-indicator v-if="(narrativeText[movement + '_5_String1'] == 'moved towards the bending side') || (narrativeText[movement + '_5_String2'] != 'did not move forward or backward') || (narrativeText[movement + '_5_String3'] == 'moved away from the bending side') || (narrativeText[movement + '_5_String4'] != 'was centered relative to')"
                kind="improve" title="Sidebending not optimal">
                Optimal sidebend performance involves staying in plane so that the body moves sideways and not forwards or backwards, and that the hips stay relatively still or move in the opposite direction to the reach. Sidebend was not performed optimally. Repeat the assessment and/or seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" for SideBend_5_String1, SideBend_5_String2, SideBend_5_String3, SideBend_5_String4  -->
            <mpro-indicator v-else kind="maintain" title="Sidebending optimal">
                Optimal sidebend performance involves staying in plane so that the body moves sideways and not forwards or backwards, and that the hips stay relatively still or move in the opposite direction to the reach. Sidebend was performed optimally.<br><br>
            </mpro-indicator>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
          <p class="text-subtitle-1">Pelvis, Trunk, Centre of Gravity (Symmetry)</p>
          {{(narrativeText[movement + '_4_String1'] === narrativeText[movement + '_5_String1']) && (narrativeText[movement + '_4_String2'] === narrativeText[movement + '_5_String2']) && (narrativeText[movement + '_4_String3'] === narrativeText[movement + '_5_String3']) && (narrativeText[movement + '_4_String4'] === narrativeText[movement + '_5_String4']) ? 'The left and right bending movement of the pelvis, the trunk and the Centre of Gravity where symmetric' : 'The left and right bending movement of the pelvis, the trunk and the Centre of Gravity where not symmetric'}}.
<!-- Indicator "Improve" for comparison between SideBend_4_String1 vs. SideBend_5_String1, SideBend_4_String2 vs. SideBend_5_String2, SideBend_4_String3 vs. SideBend_5_String3 and SideBend_4_String4 vs. SideBend_5_String4  -->
            <mpro-indicator v-if="(narrativeText[movement + '_4_String1'] != narrativeText[movement + '_5_String1']) || (narrativeText[movement + '_4_String2'] != narrativeText[movement + '_5_String2']) || (narrativeText[movement + '_4_String3'] != narrativeText[movement + '_5_String3']) || (narrativeText[movement + '_4_String4'] != narrativeText[movement + '_5_String4'])"
                kind="improve" title="Assymetry of left and right bend">
                Optimal sidebend performance involves staying in plane and demonstrating similar distances reached and symmetrical movement patterns. The left and right sides were asymmetrical. Repeat the assessment and/or seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" for comparison between SideBend_4_String1 vs. SideBend_5_String1, SideBend_4_String2 vs. SideBend_5_String2, SideBend_4_String3 vs. SideBend_5_String3 and SideBend_4_String4 vs. SideBend_5_String4  -->
            <mpro-indicator v-else kind="maintain" title="Symmetry of left and right bend">
                Optimal sidebend performance involves staying in plane and demonstrating similar distances reached and symmetrical movement patterns. The left and right sides were symmetrical.<br><br>
            </mpro-indicator>
          </v-col>
        </v-row>
<!-- -->
<!-- -->
<!-- MOVEMENT: SQUAT DOUBLE -->
        <v-row v-if="movement === 'SquatDouble'">
          <v-col cols="12" class="pt-0 mt-0">
            A {{narrativeText[movement + '_1_String1']}} was performed and recorded.
<!-- Indicator "Improve" for SquatDouble_1_String1 -->
              <mpro-indicator v-if="narrativeText[movement + '_1_String1'] != 'Non-Restricted-Squat (NRS)'"
                  kind="improve" title="Knees moved little during the squat">
                  The squat can be performed in many ways. During a Restricted Squat the knees tend not to move forward and down. When the shank is relatively still, there is less movement in the ankles. Some people can simply sit back to perform the Restricted Squat, others need to have their arms forward, or lean forward with the trunk in order to maintain balance. It is not recommended to use the frontal plane trajectory angle of the knee during the Restricted Squat. The size of the angle can be exaggerated due to the very small vertical component of the trajectory (eg. 1cm) and a relatively large horizontal component when the knees fall inwards or outwards (eg. 3 cm).<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_1_String1 -->
              <mpro-indicator v-else kind="maintain"
                  title="Knees moves forward during the squat">
                  The squat can be performed in many ways. During a Non-Restricted Squat the knees tend to move forward and down. When the shank moves forward, more movement in the ankles is necessary. The Centre of Gravity is maintained in a central position or moves forward over the base of support when the knees move forward. The frontal plane trajectory of the knee can be measured during the Non-Restricted Squat.<br><br>
              </mpro-indicator>
            The shoulders were {{narrativeText[movement + '_14_String1']}} the pelvis during the initial phase of the squat.
<!-- Indicator "Improve" for SquatDouble_14_String1 -->
              <mpro-indicator v-if="narrativeText[movement + '_14_String1'] != 'over'"
                  kind="improve" title="Trunk not above the pelvis">
                  It is preferable that the standing posture is upright before the squat movement is initiated. This did not occur case during the recording. Repeat the assessment and/or seek professional advice about any misalignment.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_14_String1 -->
              <mpro-indicator v-else kind="maintain"
                  title="Trunk above the pelvis">
                  It is preferable that the standing posture is upright before the squat movement is initiated. This did occur during the recording.<br><br>
            </mpro-indicator>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
            <p class="text-subtitle-1">Start position</p>
            <p>The distance between the feet was {{narrativeText[movement + '_3_String1']}} according to the instructions.
<!-- Indicator "Improve" for SquatDouble_3_String1 -->
                <mpro-indicator v-if="narrativeText[movement + '_3_String1'] == 'not'"
                    kind="improve" title="Stance width not following instructions">
                    To improve validity and reliability of the assessment there should be no more than 15 cm distance between the feet. The position of the feet during this movement was not as instructed. Please repeat the scan with the feet placed in the right position.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_3_String1 -->
                <mpro-indicator v-else kind="maintain"
                    title="Stance width following instructions">
                    To improve validity and reliability of the assessment there should be no more than 15 cm distance between the feet. The position of the feet during this movement was as instructed.<br><br>
                </mpro-indicator>
              {{narrativeText[movement + '_3_String3'] === 'centered' && narrativeText[movement + '_3_String4'] === 'centered' ? 'The Centre of Gravity was centered relative to the midline' : 'The Centre of Gravity was ' + narrativeText[movement + '_3_String3'] + ' and ' + narrativeText[movement + '_3_String4'] + ' relative to the midline'}}.
<!-- Indicator "Improve" for SquatDouble_3_String3 and SquatDouble_3_String4 -->
                <mpro-indicator v-if="(narrativeText[movement + '_3_String3'] != 'centered') || (narrativeText[movement + '_3_String4'] != 'centered')"
                    kind="improve" title="Centre of Gravity not centered">
                    The optimal position for the Centre of Gravity is centred above the Base of Support. The Centre of Gravity was not centred at the start of the movement. Repeat the assessment and/or seek professional advice about any misalignment.<br><br>
                </mpro-indicator>
  <!-- Indicator "Maintain" for SquatDouble_3_String3 and SquatDouble_3_String4 -->
                <mpro-indicator v-else
                kind="maintain" title="Centre of Gravity centered">
                    The optimal position for the Centre of Gravity is centred above the Base of Support. The Centre of Gravity was centred at the start of the movement.<br><br>
                </mpro-indicator><br>
            The body was {{narrativeText[movement + '_4_String1']}} aligned.
<!-- Indicator "Improve" for SquatDouble_4_String1 -->
              <mpro-indicator v-if="narrativeText[movement + '_4_String1'] != ''"
                  kind="improve" title="Not all body parts were aligned">
                  Equalibrium occurs when all body parts are in alignment and positioned on the midline. The knees may not be an equal distance from the midline, and the pelvis, trunk or shoulders may be off the midline. Repeat the assessment and/or seek professional advice about any misalignment.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_4_String1 -->
              <mpro-indicator v-else kind="maintain"
                  title="All body parts were aligned">
                  Equalibrium occurs when all body parts are in alignment and positioned on the midline.<br><br>
                </mpro-indicator>
            The right knee was {{$formatLength(narrativeText.KneeRightAnatomic_X_StartCm, 'cm', isMetric)}} from the midline while the left knee was {{$formatLength(narrativeText.KneeLeftAnatomic_X_StartCm, 'cm', isMetric)}} from the midline. {{narrativeText[movement + '_5_String1']}}
<!-- Indicator "Improve" for SquatDouble_5_String1 -->
              <mpro-indicator v-if="narrativeText[movement + '_5_String1'] != ''"
                  kind="improve" title="Knee distances not equal">
                 The optimal frontal plane position is with the knees straight and an equal distance from the midline. This was not the case during the recording. Repeat the assessment and/or seek professional advice about any misalignment.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_5_String1 -->
              <mpro-indicator v-else kind="maintain"
                  title="Knee distances equal">
                  The optimal frontal plane position is with the knees straight and an equal distance from the midline. The distance was equal, however check for valgus or varus knees that may benefit from professional advice. <br><br>
                </mpro-indicator>
            {{narrativeText[movement + '_6_String1'] === 'centered' ? 'The middle of the pelvis was centered relative to the midline.' : 'The middle of the pelvis was ' + narrativeText[movement + '_6_String1'] + ' by ' + $formatLength(narrativeText.HipsMiddleAnatomic_X_StartCm, 'cm', isMetric) + ' relative to the midline.'}}
<!-- Indicator "Improve" for SquatDouble_6_String1 -->
              <mpro-indicator v-if="narrativeText[movement + '_6_String1'] != 'centered'"
                  kind="improve" title="Pelvis not aligned">
                  The optimal frontal plane position of the pelvis during the squat is centred. This was not the case during the recording. Repeat the assessment and/or seek professional advice about any misalignment.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_6_String1 -->
              <mpro-indicator v-else kind="maintain"
                  title="Pelvis aligned">
                  The optimal frontal plane position of the pelvis during the squat is centred. This was the case during the recording.<br><br>
                </mpro-indicator>
            {{narrativeText[movement + '_7_String1'] === 'centered' ? 'The shoulders were centered relative to the midline.' : 'The shoulders were ' + narrativeText[movement + '_7_String1'] + ' by ' + $formatLength(narrativeText.ShouldersMiddleSurface_X_StartCm, 'cm', isMetric) + ' relative to the midline.'}}
<!-- Indicator "Improve" for SquatDouble_7_String1 -->
              <mpro-indicator v-if="narrativeText[movement + '_7_String1'] != 'centered'"
                  kind="improve" title="Shoulders not aligned">
                  The optimal frontal plane position of the middle of the shoulders during the squat is centred. This was not the case during the recording. Repeat the assessment and/or seek professional advice about any misalignment.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_7_String1 -->
              <mpro-indicator v-else kind="maintain"
                  title="Shoulders aligned">
                  The optimal frontal plane position of the middle of the shoulders during the squat is centred. This was the case during the recording. <br><br>
              </mpro-indicator></p>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
            <p class="text-subtitle-1">Down phase</p>
            <p>The Centre of Gravity moved {{narrativeText[movement + '_10_String1'] === 'on' && narrativeText[movement + '_10_String2'] === 'on' ? 'centered' : narrativeText[movement + '_10_String1'] + ' and ' + narrativeText[movement + '_10_String2']}} relative to the midline.
<!-- Indicator "Improve" for SquatDouble_10_String2 -->
              <mpro-indicator v-if="(narrativeText[movement + '_10_String2'] != 'on')"
                  kind="improve" title="Center of Gravity off the midline">
                  The optimal frontal plane path of the Centre of Gravity should remain centered. This was not the case during the recording, moving {{narrativeText[movement + '_10_String2']}}. Repeat the assessment and/or seek professional advice.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_10_String2 -->
              <mpro-indicator v-else kind="maintain"
                  title="Center of Gravity on the midline">
                  The optimal frontal plane path of the Centre of Gravity should remain centered in the frontal plane. This was the case during the recording.<br><br>
                </mpro-indicator>
            The shape of the frontal plane pathway of the pelvis {{narrativeText[movement + '_11_String3']}}. The pelvis movement was {{narrativeText[movement + '_11_String4']}} and finished {{narrativeText[movement + '_11_String1']}} and {{narrativeText[movement + '_11_String2']}}.
<!-- Indicator "Improve" for SquatDouble_11_String1, SquatDouble_11_String2 and SquatDouble_11_String4 -->
              <mpro-indicator v-if="(narrativeText[movement + '_11_String4'] != 'centered') && (narrativeText[movement + '_11_String1'] != 'forward') && (narrativeText[movement + '_11_String2'] != 'centered')"
                  kind="improve" title="Pathway of pelvis and bottom position was not optimal">
                  The optimal frontal plane pathway that the pelvis follows is centered. The pelvis should also be centred at the bottom of the squat. This was not the case during the recording. Repeat the assessment and/or seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for SquatDouble_11_String1, SquatDouble_11_String2 and SquatDouble_11_String4 -->
              <mpro-indicator v-else-if="(narrativeText[movement + '_11_String4'] != 'centered') && (narrativeText[movement + '_11_String1'] != 'forward') && (narrativeText[movement + '_11_String2'] == 'centered')"
                  kind="improve" title="Pathway of pelvis was not optimal">
                  The optimal frontal plane pathway of the pelvis is centered. This was not the case during the recording. However, the pelvis was centred at the bottom of the squat. Repeat the assessment and/or seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for SquatDouble_11_String1, SquatDouble_11_String2 and SquatDouble_11_String4 -->
              <mpro-indicator v-else-if="(narrativeText[movement + '_11_String4'] == 'centered') && (narrativeText[movement + '_11_String1'] != 'forward') && (narrativeText[movement + '_11_String2'] != 'centered')"
                  kind="improve" title="Bottom position of the pelvis not optimal">
                  The optimal frontal plane pathway that the pelvis follows is centered. This was not the case during the recording. Repeat the assessment and/or seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for SquatDouble_11_String1, SquatDouble_11_String2 and SquatDouble_11_String4 -->
              <mpro-indicator v-else-if="(narrativeText[movement + '_11_String1'] == 'forward')"
                  kind="improve" title="Pelvis movement in sagittal plane is unlikely">
                  During a normal mini-squat, it is not likely that the pelvis moves fowards. Repeat the assessment and/or seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_11_String1, SquatDouble_11_String2 and SquatDouble_11_String4 -->
              <mpro-indicator v-else kind="maintain"
                  title="Pelvis movement optimal">
                  The optimal frontal plane pathway that the pelvis follows is centered. This was the case during the recording.<br><br>
                </mpro-indicator>
            The middle of the shoulders moved {{narrativeText[movement + '_12_String2']}} and {{narrativeText[movement + '_12_String1']}}.
<!-- Indicator "Improve" for SquatDouble_12_String1 and SquatDouble_12_String2 -->
              <mpro-indicator v-if="(narrativeText[movement + '_12_String1'] != 'backward') && (narrativeText[movement + '_12_String2'] != 'centered')"
                  kind="improve" title="Shoulder movement not optimal">
                  The optimal frontal plane pathway that the mid-shoulder follows is centered. In the sagittal plane, optimal mid-shoulder movement is slightly forward or straight down. This was not the case during the recording. Repeat the assessment and/or seek professional advice.<br><br>
                </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for SquatDouble_12_String1 and SquatDouble_12_String2  -->
              <mpro-indicator v-else-if="(narrativeText[movement + '_12_String1'] == 'backward')"
                  kind="improve" title="Shoulder movement in sagittal plane is sub-optimal">
                  In the sagittal plane, optimal mid-shoulder movement is slightly forward or straight down. The shoulders moved backward during the recording. Repeat the assessment and/or seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_12_String1 and SquatDouble_12_String2  -->
              <mpro-indicator v-else
                  kind="maintain"
                  title="Shoulder movement optimal">
                  The optimal frontal plane pathway that the mid-shoulder follows is centered. In the sagittal plane, optimal mid-shoulder movement is slightly forward or straight down. This was the case during the recording.<br><br>
                </mpro-indicator>
            <br>
            There was {{narrativeText[movement + '_13_String1']}} shoulder rotation. {{narrativeText[movement + '_13_String1'] === 'some' ? 'The ' + narrativeText[movement + '_13_String2'].toLowerCase() + ' shoulder moved more than the ' + narrativeText[movement + '_13_String3'].toLowerCase() + ' shoulder.' : ''}}
<!-- Indicator "Improve" for SquatDouble_13_String1 -->
              <mpro-indicator v-if="narrativeText[movement + '_13_String1'] == 'some'"
                  kind="improve" title="Trunk/shoulder rotation">
                  Symmetrical movement is without rotation of the trunk and shoulders in the transverse plane. There was some rotation during the recording. Repeat the assessment and/or seek professional advice.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_13_String1 -->
              <mpro-indicator v-else kind="maintain"
                  title="Stable trunk - No rotation">
                  Symmetrical movement is without rotation of the trunk and shoulders in the transverse plane. There was no detectable rotation during the recording.<br><br>
                </mpro-indicator>
            <br>
            The right knee {{narrativeText[movement + '_15_String1']}} travel medial to the right foot during the down phase. The bottom position of the right knee was {{narrativeText[movement + '_15_String2']}} to the foot.
            The shape of the frontal plane pathway {{narrativeText[movement + '_15_String3'] == null ? 'could not be defined due to an unclear trajectory form of the right knee.' : narrativeText[movement + '_15_String3'] + (kneeShapesSquat.find(v => narrativeText[movement + '_15_String3'].includes(v)) ? '. Note that no Line of Best Fit trajectory angle is reported with a pathway like this.' : '. The pathway was at an angle of ' + $roundValue(narrativeText.KneeRightJoint_VertForthAngle, 1) + ' degrees using a Line of Best Fit.')}}
            The right knee moved sideways from the start position by {{$formatLength(narrativeText.KneeRightJoint_LatForthShiftCm, 'cm', isMetric)}} ({{$formatPercent(narrativeText.KneeRightJoint_LatForthShiftCm, bodyHeightCm)}} of body height) {{narrativeText[movement + '_16_String1']}}.
<!-- Indicator "Improve" for SquatDouble_15_String1 and SquatDouble_15_String2 -->
              <mpro-indicator v-if="(narrativeText[movement + '_15_String1'] != 'did not') && (narrativeText[movement + '_15_String2'] != 'not medial')"
                  kind="improve" title="Apparent dynamic valgus and knee medial to foot position">
                  The optimal frontal plane pathway of the knee (with feet facing forward) travels at less than 10 ± 4 degrees from the vertical, and preferrably in a relatively straight line. The knee should not travel medial of the foot. The right knee did travel medially by more than 10 ± 4 degrees. The knee did travel medial to the foot. Seek professional advice.<br><br>
                </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for SquatDouble_15_String1 and SquatDouble_15_String2 -->
              <mpro-indicator v-else-if="(narrativeText[movement + '_15_String1'] != 'did not') && (narrativeText[movement + '_15_String2'] == 'not medial')"
                  kind="improve" title="Knee medial to foot at bottom position">
                   The knee should not travel medial of the foot. Although the frontal plane pathway of the right knee was predominately lateral of the foot, it ended in a knee medial to foot position at the bottom of the squat. Seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for SquatDouble_15_String1 and SquatDouble_15_String2 -->
              <mpro-indicator v-else-if="(narrativeText[movement + '_15_String1'] == 'did not') && (narrativeText[movement + '_15_String2'] != 'not medial')"
                  kind="improve" title="Knee crossed medial to foot during down phase">
                   The knee should not travel medial of the foot. Although the frontal plane position of the right knee ended lateral to foot at the bottom of the squat, the knee did cross the foot position during the down phase. Seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_15_String1 and SquatDouble_15_String2 -->
              <mpro-indicator v-else kind="maintain"
                  title="Optimal knee control">
                  The optimal frontal plane pathway of the knee (with feet facing forward) travels at less than 10 ± 4 degrees from the vertical, and preferrably in a relatively straight line. The knee should not travel medial of the foot. The right knee did not travel medially or laterally by more than 10 ± 4 degrees. The right knee did not travel medial to the foot.<br><br>
              </mpro-indicator><br>
            The left knee {{narrativeText[movement + '_17_String1']}} travel medial to the left foot during the down phase.
            The bottom position of the left knee was {{narrativeText[movement + '_17_String2']}} to the foot.
            The shape of the frontal plane pathway {{narrativeText[movement + '_17_String3'] == null ? 'could not be defined due to an unclear trajectory form of the left knee.' : narrativeText[movement + '_17_String3'] + (kneeShapesSquat.find(v => narrativeText[movement + '_17_String3'].includes(v)) ? '. Note that no Line of Best Fit trajectory angle is reported with a pathway like this.' : '. The pathway was at an angle of ' + $roundValue(narrativeText.KneeLeftJoint_VertForthAngle, 1) + ' degrees using a Line of Best Fit.')}}
            The left knee moved sideways from the start position by {{$formatLength(narrativeText.KneeLeftJoint_LatForthShiftCm, 'cm', isMetric)}} ({{$formatPercent(narrativeText.KneeLeftJoint_LatForthShiftCm, bodyHeightCm)}} of body height) {{narrativeText[movement + '_18_String1']}}.
<!-- Indicator "Improve" for SquatDouble_17_String1 and SquatDouble_17_String2 -->
              <mpro-indicator v-if="(narrativeText[movement + '_17_String1'] != 'did not') && (narrativeText[movement + '_17_String2'] != 'not medial')"
                  kind="improve" title="Medial path and bottom position of the left knee">
                  Optimally the left knee is not travelling and finishing medial to the left foot. This happened during the squat which should be investigated by a qualified therapist.<br><br>
              </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for SquatDouble_17_String1 and SquatDouble_17_String2 -->
              <mpro-indicator v-else-if="(narrativeText[movement + '_17_String1'] != 'did not') && (narrativeText[movement + '_17_String2'] == 'not medial')"
                  kind="improve" title="Knee crossed medial to foot during down phase">
                   The knee should not travel medial of the foot. Although the frontal plane position of the left knee ended lateral to foot at the bottom of the squat, the knee did cross the foot position during the down phase. Seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for SquatDouble_17_String1 and SquatDouble_17_String2 -->
              <mpro-indicator v-else-if="(narrativeText[movement + '_17_String1'] == 'did not') && (narrativeText[movement + '_17_String2'] != 'not medial')"
                  kind="improve" title="Knee medial to foot at bottom position">
                   The knee should not travel medial of the foot. Although the frontal plane pathway of the left knee was predominately lateral of the foot, it ended in a knee medial to foot position at the bottom of the squat. Seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_17_String1 and SquatDouble_17_String2 -->
              <mpro-indicator v-else kind="maintain"
                  title="Optimal knee control">
                  The optimal frontal plane pathway of the knee (with feet facing forward) travels at less than 10 ± 4 degrees from the vertical, and preferrably in a relatively straight line. The knee should not travel medial of the foot. The left knee did not travel medially or laterally by more than 10 ± 4 degrees. The left knee did not travel medial to the foot.<br><br>
              </mpro-indicator>
            </p>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
            <p class="text-subtitle-1">Up phase</p>
            <p>The Centre of Gravity moved {{narrativeText[movement + '_21_String1'] === 'on' && narrativeText[movement + '_21_String2'] === 'on' ? 'centered' : narrativeText[movement + '_21_String1'] + ' and ' + narrativeText[movement + '_21_String2']}} relative to the midline.
<!-- Indicator "Improve" for SquatDouble_21_String2 -->
              <mpro-indicator v-if="(narrativeText[movement + '_21_String2'] != 'on')"
                  kind="improve" title="Center of Gravity away from the midline">
                  The optimal frontal plane path of the Centre of Gravity should remain centered. This was not the case during the recording, moving {{narrativeText[movement + '_21_String2']}}. Repeat the assessment and/or seek professional advice.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_21_String2 -->
              <mpro-indicator v-else kind="maintain"
                  title="Center of Gravity on the midline">
                  The optimal frontal plane path of the Centre of Gravity should remain centered in the frontal plane. This was the case during the recording.<br><br>
                </mpro-indicator>
            The pelvis moved {{narrativeText[movement + '_22_String2']}} in the frontal plane and {{narrativeText[movement + '_22_String1']}} in the sagittal plane. The shape of the frontal plane pathway {{narrativeText[movement + '_22_String3']}}.
<!-- Indicator "Improve" for SquatDouble_22_String1, SquatDouble_22_String2 -->
              <mpro-indicator v-if="(narrativeText[movement + '_22_String1'] != 'backward') && (narrativeText[movement + '_22_String2'] != 'centered')"
                  kind="improve" title="Path of the pelvis was not optimal">
                  The optimal frontal plane pathway of the pelvis is centered. This was not the case during the recording. Seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for SquatDouble_22_String1, SquatDouble_22_String2-->
              <mpro-indicator v-else-if="(narrativeText[movement + '_22_String1'] == 'backward')"
                  kind="improve" title="Pelvis movement in sagittal plane is unlikely">
                  During a normal mini-squat, it is not likely that the pelvis moves backwards when rising from the bottom position. Repeat the assessment and/or seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_22_String1, SquatDouble_22_String2-->
              <mpro-indicator v-else kind="maintain"
                  title="Optimal pelvis movement">
                  The optimal frontal plane pathway of the pelvis is centered. This was the case during the recording. The pelvis was centred at the bottom of the squat.<br><br>
                </mpro-indicator>
            The middle of the shoulders moved {{narrativeText[movement + '_23_String2']}} in the frontal plane and {{narrativeText[movement + '_23_String1']}} in the sagittal plane.
<!-- Indicator "Improve" for SquatDouble_23_String1 and SquatDouble_23_String2 -->
              <mpro-indicator v-if="(narrativeText[movement + '_23_String1'] != 'forward') && (narrativeText[movement + '_23_String2'] != 'centered')"
                  kind="improve" title="Path of the shoulders was not optimal">
                  The optimal frontal plane pathway that the mid-shoulder follows is centered. In the sagittal plane, optimal mid-shoulder movement is slightly backward or straight up. This was not the case during the recording. Seek professional advice.<br><br>
                </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for SquatDouble_23_String1 and SquatDouble_23_String2  -->
              <mpro-indicator v-else-if="(narrativeText[movement + '_23_String1'] == 'forward')"
                  kind="improve" title="Shoulder movement in sagittal plane is unlikely">
                 During a normal mini-squat, it is not likely that the shoulders moves forwards when rising from the bottom position. Repeat the assessment and/or seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_23_String1 and SquatDouble_23_String2  -->
              <mpro-indicator v-else
                  kind="maintain" title="Optimal pelvis movement">
                  The optimal frontal plane pathway of the shoulders is centered. This was the case during the recording. The shoulders was centred at the bottom of the squat.<br><br>
                </mpro-indicator>
            <br>
            The shape of the frontal plane pathway of the right knee {{narrativeText[movement + '_24_String1'] == null ? 'could not be defined due to an unclear trajectory form of the right knee.' : narrativeText[movement + '_24_String1'] + (kneeShapesSquat.find(v => narrativeText[movement + '_24_String1'].includes(v)) ? '. Note that no Line of Best Fit trajectory angle is reported with a pathway like this.' : '. The pathway was at an angle of ' + $roundValue(narrativeText.KneeRightJoint_VertBackAngle, 1) + ' degrees using a Line of Best Fit.')}}
            The right knee moved sideways from the bottom position by {{$formatLength(narrativeText.KneeRightJoint_LatBackShiftCm, 'cm', isMetric)}} ({{$formatPercent(narrativeText.KneeRightJoint_LatBackShiftCm, bodyHeightCm)}} of body height) {{narrativeText[movement + '_25_String1']}}.
            The shape of the frontal plane pathway of the left knee {{narrativeText[movement + '_26_String1'] == null ? 'could not be defined due to an unclear trajectory form of the left knee.' : narrativeText[movement + '_26_String1'] + (kneeShapesSquat.find(v => narrativeText[movement + '_26_String1'].includes(v)) ? '. Note that no Line of Best Fit trajectory angle is reported with a pathway like this.' : '. The pathway was at an angle of ' + $roundValue(narrativeText.KneeLeftJoint_VertBackAngle, 1) + ' degrees using a Line of Best Fit.')}}
            The left knee moved sideways from the bottom position by {{$formatLength(narrativeText.KneeLeftJoint_LatBackShiftCm, 'cm', isMetric)}} ({{$formatPercent(narrativeText.KneeLeftJoint_LatBackShiftCm, bodyHeightCm)}} of body height) {{narrativeText[movement + '_27_String1']}}.
            </p>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
            <p class="text-subtitle-1">Knee repositioning (start vs. end position)</p>
            <p>The repositioning of the right knee was within approximately {{$formatLength(Math.abs(narrativeText.KneeRightJoint_LatForthShiftCm-narrativeText.KneeRightJoint_LatBackShiftCm), 'cm', isMetric)}} ({{narrativeText[movement + '_29_String1']}}).
<!-- Indicator "Improve" for SquatDouble_29_String1 -->
              <mpro-indicator v-if="(narrativeText[movement + '_29_String1'] != 'consistent')"
                  kind="improve" title="Right knee repositioning error">
                  Optimal function enables returning to the same start position. At the end of this squat the right knee returned to approximately the same starting position. Seek professional advice.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_29_String1 -->
              <mpro-indicator v-else
                  kind="maintain" title="Adequate knee repositioning">
                  Optimal function enables returning to the same start position. At the end of this squat the right knee returned to approximately the same position.<br><br>
                </mpro-indicator>
              <br>
               The repositioning of the left knee was within approximately {{$formatLength(Math.abs(narrativeText.KneeLeftJoint_LatForthShiftCm-narrativeText.KneeLeftJoint_LatBackShiftCm), 'cm', isMetric)}} ({{narrativeText[movement + '_30_String1']}}).
<!-- Indicator "Improve" for SquatDouble_30_String1 -->
              <mpro-indicator v-if="(narrativeText[movement + '_30_String1'] != 'consistent')"
                  kind="improve" title="Knee repositioning error">
                  Optimal function enables returning to the same start position. At the end of this squat the left knee returned to approximately the same starting position. Seek professional advice.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for SquatDouble_30_String1 -->
              <mpro-indicator v-else
                  kind="maintain" title="Adequate knee repositioning">
                  Optimal function enables returning to the same start position. At the end of this squat the left knee returned to approximately the same position.<br><br>
                </mpro-indicator>
               </p>
          </v-col>
        </v-row>
<!-- -->
<!-- -->
<!-- MOVEMENT: SQUAT LEFT & RIGHT -->
        <v-row v-if="movement === 'SquatLeft' || movement === 'SquatRight'">
          <v-col cols="12" class="pt-0 mt-0">
            A {{narrativeText[movement + '_1_String1']}} was performed and recorded.
<!-- Indicator "Improve" for Squat{Side}_1_String1 -->
              <mpro-indicator v-if="narrativeText[movement + '_1_String1'] != 'Non-Restricted-Squat (NRS)'"
                  kind="improve" title="Knee moved little during the squat">
                  The squat can be performed in many ways. During a Restricted Squat the {{sideSingleSquat}} knee tended not to move forward and down. When the shank is relatively still, there is less movement in the {{sideSingleSquat}} ankle. Some people can simply sit back to perform the Restricted Squat, others need to have their arms forward, or lean forward with the trunk in order to maintain balance. It is not recommended to use the frontal plane trajectory angle of the knee during the Restricted Squat. The size of the angle can be exaggerated due to the very small vertical component of the trajectory (eg. 1 cm) and a relatively large horizontal component when the knees fall inwards or outwards (eg. 3 cm).<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" for Squat{Side}_1_String1 -->
              <mpro-indicator v-else kind="maintain"
                  title="Knees moved forward during the squat">
                  The squat can be performed in many ways. During a Non-Restricted Squat the {{sideSingleSquat}} knee tended to move forward and down. When the shank moves forward, more movement in the {{sideSingleSquat}} ankle is necessary. The Centre of Gravity is maintained in a central position or moves forward over the base of support when the knees move forward. The frontal plane trajectory of the knee can be measured during the Non-Restricted Squat.<br><br>
              </mpro-indicator>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
            <p class="text-subtitle-1">Start position</p>
            <p>The {{sideSingleSquat}} foot was {{narrativeText[movement + '_3_String1']}} facing forward.
<!-- Indicator "Improve" for Squat{Side}_3_String1 -->
                <mpro-indicator v-if="narrativeText[movement + '_3_String1'] == 'not'"
                    kind="improve" title="Stance width not following instructions">
                    To improve validity and reliability of the assessment there should be no more than 15 cm distance between the feet. The position of the feet during this movement was not as instructed. Please repeat the scan with the feet placed in the right position.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for Squat{Side}_3_String1 -->
                <mpro-indicator v-else kind="maintain"
                    title="Stance width following instructions">
                    To improve validity and reliability of the assessment there should be no more than 15 cm distance between the feet. The position of the feet during this movement was as instructed.<br><br>
                </mpro-indicator>
            {{narrativeText[movement + '_3_String2'] === 'centered' && narrativeText[movement + '_3_String3'] === 'centered' ? 'The Centre of Gravity was centered relative to the midline' : 'The Centre of Gravity was ' + narrativeText[movement + '_3_String2'] + ' and ' + narrativeText[movement + '_3_String3'] + ' relative to the midline'}}.
<!-- Indicator "Improve" for Squat{Side}_3_String2 and Squat{Side}_3_String3 -->
                <mpro-indicator v-if="(narrativeText[movement + '_3_String2'] !== 'centered') || (narrativeText[movement + '_3_String3'] !== 'centered')"
                    kind="improve" title="Centre of Gravity not centered">
                    The optimal position for the Centre of Gravity is centred above the Base of Support or the {{sideSingleSquat}} foot. The Centre of Gravity was not centred at the start of the movement. Repeat the assessment and/or seek professional advice about any misalignment.<br><br>
                </mpro-indicator>
  <!-- Indicator "Maintain" for Squat{Side}_3_String2 and Squat{Side}_3_String3 -->
                <mpro-indicator v-else
                  kind="maintain" title="Centre of Gravity centered">
                  The optimal position for the Centre of Gravity is centred above the Base of Support or the {{sideSingleSquat}} foot. The Centre of Gravity was centred at the start of the movement.<br><br>
                </mpro-indicator>
            The {{sideSingleSquat}} knee was {{narrativeText[movement + '_4_String1']}} the ankle by {{$formatLength(narrativeText[`Knee${sideSingleSquatCap}Anatomic_X_StartCm`], 'cm', isMetric)}} ({{$formatPercent(narrativeText[`Knee${sideSingleSquatCap}Anatomic_X_StartCm`], bodyHeightCm)}} of body height).
<!-- Indicator "Improve" for Squat{Side}_4_String1 -->
            <mpro-indicator v-if="narrativeText[movement + '_4_String1'] != 'lateral to'"
                kind="improve" title="Sub-optimal start position for the knee">
                The optimal starting position of the {{sideSingleSquat}} knee in the frontal plane during the single leg squat is above or lateral of the midline of the {{sideSingleSquat}} ankle. This was not the case during the recording. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" (all rest but not the ones above) for Squat{Side}_4_String1 -->
            <mpro-indicator v-else kind="maintain"
                title="Optimal start position for the knee">
                The optimal starting position of the {{sideSingleSquat}} knee in the frontal plane during the single leg squat is above or lateral of the midline of the {{sideSingleSquat}} ankle.<br><br>
            </mpro-indicator>
            The pelvis was {{narrativeText[movement + '_5_String1']}} the ankle.
<!-- Indicator "Improve" for Squat{Side}_5_String1 -->
            <mpro-indicator v-if="narrativeText[movement + '_5_String1'] == 'medial to'"
                kind="improve" title="Sub-optimal start position for the pelvis">
               The optimal starting position of the pelvis in the frontal plane during the {{sideSingleSquat}} leg squat is centered above or lateral to the midline of the {{sideSingleSquat}} ankle. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" (all rest but not the ones above) for Squat{Side}_5_String1 -->
            <mpro-indicator v-else kind="maintain"
                title="Optimal start position for the pelvis">
                The optimal starting position of the pelvis in the frontal plane during the {{sideSingleSquat}} leg squat is centered above or lateral to the midline of the {{sideSingleSquat}} ankle. This was the case during the recording.<br><br>
            </mpro-indicator>
            The middle of the shoulders was {{narrativeText[movement + '_6_String1']}} the pelvis.
<!-- Indicator "Improve" for Squat{Side}_6_String1 -->
            <mpro-indicator v-if="narrativeText[movement + '_6_String1'] == 'medial to'"
                kind="improve" title="Sub-optimal start position for the shoulders">
                The optimal starting position of the shoulders in the frontal plane during the {{sideSingleSquat}} leg squat is centered above or lateral to the midline of the {{sideSingleSquat}} ankle. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" (all rest but not the ones above) for Squat{Side}_6_String1 -->
            <mpro-indicator v-else kind="maintain"
                title="Optimal start position for the shoulders">
                The optimal starting position of the shoulders in the frontal plane during the {{sideSingleSquat}} leg squat is centered above or lateral to the midline of the {{sideSingleSquat}} ankle. This was the case during the recording.<br><br>
            </mpro-indicator>
            </p>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
            <p class="text-subtitle-1">Down phase</p>
            <p>The Centre of Gravity was {{narrativeText[movement + '_9_String1'] === 'centered' && narrativeText[movement + '_9_String2'] === 'centered' ? 'centered over' : narrativeText[movement + '_9_String1'] + ' and ' + narrativeText[movement + '_9_String2'] + ' relative to'}} the ankle of the {{sideSingleSquat}} leg at the bottom of the squat.
<!-- Indicator "Improve" for Squat{Side}_9_String1 and Squat{Side}_9_String2 -->
              <mpro-indicator v-if="(narrativeText[movement + '_9_String2'] != 'centered') && (narrativeText[movement + '_9_String1'] == 'backward')"
                  kind="improve" title="Sub-optimal Centre of Gravity position at the bottom of the squat">
                  The optimal position of the Centre of Gravity to maintain balance during single leg squat is centered above the midline of the {{sideSingleSquat}} ankle in the frontal plane and centered or in front of the {{sideSingleSquat}} ankle in the sagittal plane. During the down phase of the {{sideSingleSquat}} leg squat the Centre of Gravity was not maintained in an optimal position. Seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" (all rest but not the ones above) for Squat{Side}_9_String1 and Squat{Side}_9_String2 -->
              <mpro-indicator v-else kind="maintain"
                  title="Optimal Centre of Gravity position at the bottom of the squat">
                  The optimal position of the Centre of Gravity to maintain balance during single leg squat is centered above the midline of the {{sideSingleSquat}} ankle in the frontal plane and centered or in front of the {{sideSingleSquat}} ankle in the sagittal plane. During the down phase of the {{sideSingleSquat}} leg squat the Centre of Gravity was maintained in an optimal position.<br><br>
              </mpro-indicator>
            The pelvis moved {{narrativeText[movement + '_10_String2']}} in the frontal plane and {{narrativeText[movement + '_10_String1']}} in the sagittal plane and at the bottom of the squat it was {{narrativeText[movement + '_10_String3']}} the ankle. The shape of the frontal plane pathway of the pelvis {{narrativeText[movement + '_10_String4']}}.
<!-- Indicator "Improve" for Squat{Side}_10_String1, Squat{Side}_10_String2, Squat{Side}_10_String3 -->
            <mpro-indicator v-if="(narrativeText[movement + '_10_String1'] != 'forward') && (narrativeText[movement + '_10_String2'] != 'centered') && (narrativeText[movement + '_10_String3'] == 'medial to')"
                kind="improve" title="Sub-optimal movement and bottom position of the pelvis">
                The optimal pathway of the pelvis during the down phase of the squat should be centered in the frontal plane and straight down or backward in the sagittal plane. Additionally the optimal bottom position should be lateral or above the midline of the {{sideSingleSquat}} ankle in the frontal plane. During the {{sideSingleSquat}} leg squat the pelvis did not remain centered in the frontal plane. The bottom position of the pelvis was medial to the midline of the {{sideSingleSquat}} ankle. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for Squat{Side}_10_String1, Squat{Side}_10_String2, Squat{Side}_10_String3  -->
            <mpro-indicator v-else-if="(narrativeText[movement + '_10_String1'] != 'forward') && (narrativeText[movement + '_10_String2'] != 'centered') && (narrativeText[movement + '_10_String3'] != 'medial to')"
                kind="improve" title="Sub-optimal movement of the pelvis">
                The optimal pathway of the pelvis during the down phase of the squat should be centered in the frontal plane and straight down or backward in the sagittal plane. Additionally the optimal bottom position should be lateral or above the midline of the {{sideSingleSquat}} ankle in the frontal plane. During the {{sideSingleSquat}} leg squat the pelvis did not remain centered in the frontal plane, However the bottom position of the pelvis was optimal. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for Squat{Side}_10_String1, Squat{Side}_10_String2, Squat{Side}_10_String3  -->
            <mpro-indicator v-else-if="(narrativeText[movement + '_10_String1'] == 'forward')"
                kind="improve" title="Pelvis movement in sagittal plane is unlikely">
                During a normal mini-squat, it is not likely that the pelvis moves forward when squatting down. Repeat the assessment and/or seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" (all rest but not the ones above) for Squat{Side}_10_String1, Squat{Side}_10_String2, Squat{Side}_10_String3 -->
            <mpro-indicator v-else kind="maintain"
                title="Optimal movement and bottom position of the pelvis">
                The optimal pathway of the pelvis during the down phase of the squat should be centered in the frontal plane and straight down or backward in the sagittal plane. Additionally the optimal bottom position should be lateral or above the midline of the {{sideSingleSquat}} ankle in the frontal plane. This was the case during the recording.<br><br>
            </mpro-indicator>
            The middle of the shoulders moved {{narrativeText[movement + '_11_String2']}} in the frontal plane and {{narrativeText[movement + '_11_String1']}} in the sagittal plane and at the bottom of the squat it was {{narrativeText[movement + '_11_String3']}} the pelvis.
<!-- Indicator "Improve" for Squat{Side}_11_String1, Squat{Side}_11_String2 and Squat{Side}_11_String3 -->
            <mpro-indicator v-if="(narrativeText[movement + '_11_String1'] == 'forward') && (narrativeText[movement + '_11_String2'] != 'centered') && (narrativeText[movement + '_11_String3'] == 'medial')"
                kind="improve" title="Sub-optimal movement and bottom position of the shoulders">
                The optimal pathway of the shoulders during the down phase of the squat should be centered in the frontal plane and straight down or forward in the sagittal plane. Additionally the optimal bottom position should be lateral or above the midline of the {{sideSingleSquat}} ankle in the frontal plane. During the {{sideSingleSquat}} leg squat the shoulders did not remain centered in the frontal plane. The bottom position of the shoulders was medial to the midline of the {{sideSingleSquat}} ankle. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for Squat{Side}_11_String1 and Squat{Side}_11_String2  -->
            <mpro-indicator v-else-if="(narrativeText[movement + '_11_String1'] == 'forward') && (narrativeText[movement + '_11_String2'] != 'centered') && (narrativeText[movement + '_11_String3'] != 'medial')"
                kind="improve" title="Sub-optimal movement of the shoulders">
                The optimal pathway of the shoulders during the down phase of the squat should be centered in the frontal plane and straight down or forward in the sagittal plane. Additionally the optimal bottom position should be lateral or above the midline of the {{sideSingleSquat}} ankle in the frontal plane. During the {{sideSingleSquat}} leg squat the shoulders did not remain centered in the frontal plane, However the bottom position of the shoulders was optimal. Seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for Squat{Side}_11_String1 and Squat{Side}_11_String2  -->
            <mpro-indicator v-else-if="(narrativeText[movement + '_11_String1'] == 'backward')"
                kind="improve" title="Shoulders movement in sagittal plane is unlikely">
                During a normal mini-squat, it is not likely that the shoulders moves backward when squatting down. Repeat the assessment and/or seek professional advice.<br><br>
            </mpro-indicator>
<!-- Indicator "Maintain" (all rest but not the ones above) for Squat{Side}_11_String1 and Squat{Side}_11_String2 -->
            <mpro-indicator v-else kind="maintain"
                title="Optimal movement and bottom position of the shoulders">
                The optimal pathway of the shoulders during the down phase of the squat should be centered in the frontal plane and straight down or forward in the sagittal plane. Additionally the optimal bottom position should be lateral or above the midline of the {{sideSingleSquat}} ankle in the frontal plane. This was the case during the recording.<br><br>
            </mpro-indicator><br>
            <br>
            The {{sideSingleSquat}} knee {{narrativeText[movement + '_12_String1']}} travel medial to the foot during the down phase. The bottom position was {{narrativeText[movement + '_12_String2']}} to the foot.
            The shape of the frontal plane pathway of the {{sideSingleSquat}} knee {{narrativeText[movement + '_12_String3'] == null ? `could not be defined due to an unclear trajectory form of the ${sideSingleSquat} knee.` : narrativeText[movement + '_12_String3'] + (kneeShapesSquat.find(v => narrativeText[movement + '_12_String3'].includes(v)) ? '. Note that no Line of Best Fit trajectory angle is reported with a pathway like this.' : '. The pathway was at an angle of ' + $roundValue(narrativeText[`Knee${sideSingleSquatCap}Joint_VertForthAngle`], 1) + ' degrees using a Line of Best Fit.')}}
            The {{sideSingleSquat}} knee moved sideways by {{$formatLength(narrativeText[`Knee_LatForthShiftCm`], 'cm', isMetric)}} ({{$formatPercent(narrativeText[`Knee_LatForthShiftCm`], bodyHeightCm)}} of body height) {{narrativeText[movement + '_13_String1']}}.
<!-- Indicator "Improve" for Squat{Side}_12_String1 and Squat{Side}_12_String2 -->
              <mpro-indicator v-if="(narrativeText[movement + '_12_String1'] != 'did not') && (narrativeText[movement + '_12_String2'] != 'not medial')"
                  kind="improve" title="Apparent dynamic valgus and knee medial to foot position">
                  The optimal frontal plane pathway of the {{sideSingleSquat}} knee (with feet facing forward) travels at less than 10 ± 4 degrees from the vertical, and preferrably in a relatively straight line. The {{sideSingleSquat}} knee should not travel medial of the foot. The {{sideSingleSquat}} knee did travel medially by more than 10 ± 4 degrees. The {{sideSingleSquat}} knee did travel medial to the foot. Seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for Squat{Side}_12_String1 and Squat{Side}_12_String2 -->
              <mpro-indicator v-else-if="(narrativeText[movement + '_12_String1'] != 'did not') && (narrativeText[movement + '_12_String2'] == 'not medial')"
                  kind="improve" title="Knee crossed medial to foot during down phase">
                   The {{sideSingleSquat}} knee should not travel medial of the foot. Although the frontal plane position of the {{sideSingleSquat}} knee ended lateral to foot at the bottom of the squat, the {{sideSingleSquat}} knee did cross the foot position during the down phase. Seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for Squat{Side}_12_String1 and Squat{Side}_12_String2 -->
              <mpro-indicator v-else-if="(narrativeText[movement + '_12_String1'] == 'did not') && (narrativeText[movement + '_12_String2'] != 'not medial')"
                  kind="improve" title="Knee medial to foot at bottom position">
                  The {{sideSingleSquat}} knee should not travel medial of the foot. Although the frontal plane pathway of the {{sideSingleSquat}} knee was predominately lateral of the foot, it ended in a {{sideSingleSquat}} knee medial to foot position at the bottom of the squat. Seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" for Squat{Side}_12_String1 and Squat{Side}_12_String2 -->
              <mpro-indicator v-else kind="maintain"
                  title="Optimal knee control">
                  The optimal frontal plane pathway of the {{sideSingleSquat}} knee (with feet facing forward) travels at less than 10 ± 4 degrees from the vertical, and preferrably in a relatively straight line. The {{sideSingleSquat}} knee should not travel medial of the foot. The {{sideSingleSquat}} knee did not travel medially by more than 10 ± 4 degrees. The {{sideSingleSquat}} knee did not travel medial to the foot.<br><br>
              </mpro-indicator>
            </p>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
            <p class="text-subtitle-1">Up phase</p>
            <p>The Centre of Gravity moved {{narrativeText[movement + '_16_String1'] === 'centered' && narrativeText[movement + '_16_String2'] === 'centered' ? 'centered over' : narrativeText[movement + '_16_String1'] + ' and ' + narrativeText[movement + '_16_String2'] + ' relative to'}} the ankle of the {{sideSingleSquat}} leg at the end of the squat.
<!-- Indicator "Improve" for Squat{Side}_16_String1 and Squat{Side}_16_String2 -->
              <mpro-indicator v-if="(narrativeText[movement + '_16_String2'] != 'centered') && (narrativeText[movement + '_16_String1'] == 'backward')"
                  kind="improve" title="Sub-optimal Centre of Gravity position at the top of the squat">
                  The optimal position of the Centre of Gravity to maintain balance during single leg squat is centered above the midline of the {{sideSingleSquat}} ankle in the frontal plane and centered or in front of the {{sideSingleSquat}} ankle in the sagittal plane. During the up phase of the {{sideSingleSquat}} leg squat the Centre of Gravity was not maintained in an optimal position. Seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" (all rest but not the ones above) for Squat{Side}_16_String1 and Squat{Side}_16_String2 -->
              <mpro-indicator v-else kind="maintain"
                  title="Optimal Centre of Gravity position at the top of the squat">
                  The optimal position of the Centre of Gravity to maintain balance during single leg squat is centered above the midline of the {{sideSingleSquat}} ankle in the frontal plane and centered or in front of the {{sideSingleSquat}} ankle in the sagittal plane. During the up phase of the {{sideSingleSquat}} leg squat the Centre of Gravity was maintained in an optimal position.<br><br>
              </mpro-indicator>
            The pelvis moved {{narrativeText[movement + '_17_String2']}} in the frontal plane and {{narrativeText[movement + '_17_String1']}} in the sagittal plane.
            The shape of the frontal plane pathway of the pelvis {{narrativeText[movement + '_17_String3']}}.
<!-- Indicator "Improve" for Squat{Side}_17_String1, Squat{Side}_17_String2 -->
              <mpro-indicator v-if="(narrativeText[movement + '_17_String1'] != 'backward') && (narrativeText[movement + '_17_String2'] != 'centered')"
                  kind="improve" title="Path of the pelvis not optimal">
                  The optimal frontal plane pathway that the pelvis follows is centered around the midline of the ankle. The pelvis should also be relatively centred at the bottom of the squat. This was not the case during the recording. Repeat the assessment and/or seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for Squat{Side}_17_String1, Squat{Side}_17_String2-->
              <mpro-indicator v-else-if="(narrativeText[movement + '_17_String1'] == 'backward')"
                  kind="improve" title="Pelvis movement in sagittal plane is unlikely">
                  During a normal mini-squat, it is not likely that the pelvis moves backwards when rising from the bottom position. Repeat the assessment and/or seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" for Squat{Side}_17_String1, Squat{Side}_17_String2-->
              <mpro-indicator v-else kind="maintain"
                  title="Pelvis movement optimal">
                  The optimal frontal plane pathway that the pelvis follows is centered around the midline of the ankle. The pelvis should also be relatively centred at the bottom of the squat. This was the case during the recording.<br><br>
              </mpro-indicator>
            The middle of the shoulders moved {{narrativeText[movement + '_18_String2']}} in the frontal plane and {{narrativeText[movement + '_18_String1']}} in the sagittal plane.
<!-- Indicator "Improve" for Squat{Side}_18_String1 and Squat{Side}_18_String2 -->
              <mpro-indicator v-if="(narrativeText[movement + '_18_String1'] != 'forward') && (narrativeText[movement + '_18_String2'] != 'centered')"
                  kind="improve" title="Path of the shoulders not optimal">
                  The optimal frontal plane pathway that the shoulder follows is centered around the midline of the ankle. The shoulder should also be relatively centred at the bottom of the squat. This was not the case during the recording. Repeat the assessment and/or seek professional advice.<br><br>
                </mpro-indicator>
<!-- Indicator "Improve" additional if-condition for Squat{Side}_18_String1 and Squat{Side}_18_String2  -->
              <mpro-indicator v-else-if="(narrativeText[movement + '_18_String1'] == 'forward')"
                  kind="improve" title="Shoulder movement in sagittal plane is unlikely">
                  During a normal mini-squat, it is not likely that the shoulders moves forwards when rising from the bottom position. Repeat the assessment and/or seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" for Squat{Side}_18_String1 and Squat{Side}_18_String2  -->
              <mpro-indicator v-else
                  kind="maintain" title="Shoulder movement optimal">
                  The optimal frontal plane pathway that the shoulder follows is centered around the midline of the ankle. The shoulder should also be relatively centred at the bottom of the squat. This was the case during the recording.<br><br>
                </mpro-indicator></p>
            <p>
            The shape of the frontal plane pathway of the {{sideSingleSquat}} knee {{narrativeText[movement + '_19_String1'] == null ? `could not be defined due to an unclear trajectory form of the ${sideSingleSquat} knee.` : narrativeText[movement + '_19_String1'] + (kneeShapesSquat.find(v => narrativeText[movement + '_19_String1'].includes(v)) ? '. Note that no Line of Best Fit trajectory angle is reported with a pathway like this.' : '.  The pathway was at an angle of ' + $roundValue(narrativeText[`Knee${sideSingleSquatCap}Joint_VertBackAngle`], 1) + ' degrees using a Line of Best Fit.')}}
            The {{sideSingleSquat}} knee moved sideways by {{$formatLength(narrativeText[`Knee_LatBackShiftCm`], 'cm', isMetric)}} ({{$formatPercent(narrativeText[`Knee_LatBackShiftCm`], bodyHeightCm)}} of body height) {{narrativeText[movement + '_20_String1']}}.</p>
          </v-col>
          <v-col cols="12" class="pt-0 mt-0">
            <p class="text-subtitle-1">Knee repositioning (start vs. end position)</p>
            <p>The repositioning of the {{sideSingleSquat}} knee was approximately {{$formatLength(Math.abs(narrativeText[`Knee_LatForthShiftCm`]-narrativeText[`Knee_LatBackShiftCm`]), 'cm', isMetric)}} ({{narrativeText[movement + '_22_String1']}}).
<!-- Indicator "Improve" for Squat{Side}_22_String1 -->
              <mpro-indicator v-if="(narrativeText[movement + '_22_String1'] != 'consistent')"
                  kind="improve" title="Knee repositioning error">
                  Optimal function enables returning to the same start position. At the end of this squat the {{sideSingleSquat}} knee returned to approximately the same position.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" for Squat{Side}_22_String1 -->
              <mpro-indicator v-else
                  kind="maintain" title="Adequate knee repositioning">
                  Optimally the {{sideSingleSquat}} knee should return to the same point as the starting position. At the end of this squat the {{sideSingleSquat}} knee was returning to the starting position.<br><br>
              </mpro-indicator>
            </p>
          </v-col>
        </v-row>
<!-- -->
<!-- -->
<!-- MOVEMENT: BALANCE LEFT & RIGHT -->
        <v-row v-if="movement === 'BalanceLeft' || movement === 'BalanceRight'">
          <v-col cols="12"  class="pt-0 mt-0">
            <p>The {{sideSingleBalance}} foot was {{narrativeText[movement + '_4_String1']}} facing forward.
<!-- Indicator "Improve" for Balance{Side}_4_String1 -->
                <mpro-indicator v-if="narrativeText[movement + '_4_String1'] == 'not'"
                    kind="improve" title="Stance foot angle not according to instructions">
                    To improve validity and reliability of the assessment the {{sideSingleBalance}} foot should point forward. The instructed test position for the {{sideSingleBalance}} foot was facing forward with the inside border of the foot no more than 10 degrees outwards. The instructions were not followed during this recording.<br><br>
                </mpro-indicator>
<!-- Indicator "Maintain" for Balance{Side}_4_String1 -->
                <mpro-indicator v-else kind="maintain"
                    title="Stance foot angle according to instructions">
                    To improve validity and reliability of the assessment the {{sideSingleBalance}} foot should point forward. The instructed test position for the {{sideSingleBalance}} foot was facing forward with the inside border of the foot no more than 10 degrees outwards. The instructions were not followed during this recording.<br><br>
                </mpro-indicator></p>
            <p class="text-subtitle-1">General balance</p>
            <p>The Centre of Gravity was {{narrativeText[movement + '_3_String2']}} in the frontal plane and {{narrativeText[movement + '_3_String1']}} in the sagittal plane relative to the {{sideSingleBalance}} foot.
<!-- Indicator "Improve" for Balance{Side}_3_String1 and Balance{Side}_3_String2  -->
              <mpro-indicator v-if="(narrativeText[movement + '_3_String1'] == 'backward') || (narrativeText[movement + '_3_String2'] != 'centered')"
                  kind="improve" title="Sub-optimal Centre of Gravity position">
                  The optimal position of the Centre of Gravity to maintain balance during single leg stance is centered above the midline of the {{sideSingleBalance}} ankle in the frontal plane and centered or in front of the {{sideSingleBalance}} ankle in the sagittal plane. The Centre of Gravity was not maintained in an optimal position. Seek professional advice.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" (all rest but not the ones above) for Balance{Side}_3_String1 and Balance{Side}_3_String2 -->
              <mpro-indicator v-else kind="maintain"
                  title="Optimal Centre of Gravity position">
                  The optimal position of the Centre of Gravity to maintain balance during single leg stance is centered above the midline of the {{sideSingleBalance}} ankle in the frontal plane and centered or in front of the {{sideSingleBalance}} ankle in the sagittal plane. The Centre of Gravity was maintained in an optimal position.<br><br>
              </mpro-indicator>
            </p>
            <p>{{narrativeText[movement + '_2_String1'] === 'not' ? 'The balance was not maintained because the the peak sway velocity of the Centre of Gravity was '+  narrativeText[movement + '_2_String2'] : 'The balance was maintained due to ' +  narrativeText[movement + '_2_String2'] +' peak sway velocity of the Centre of Gravity.'}}
<!-- Indicator "Improve" for Balance{Side}_2_String1 -->
              <mpro-indicator v-if="narrativeText[movement + '_2_String1'] == 'not'"
                  kind="improve" title="Sub-optimal balance">
                  The optimal balance is controlled and has a low peak sway velocity (less than 3,6 cm/s for mediolateral movements and less than 5,2 cm/s in anterior-posterior movements). During this record the sway velocity was beyond this. A qualified therapist should investigate further.<br><br>
              </mpro-indicator>
<!-- Indicator "Maintain" (all rest but not the ones above) for Balance{Side}_2_String1 -->
              <mpro-indicator v-else kind="maintain"
                  title="Optimal balance">
                  The optimal balance is controlled and has a low peak sway velocity (less than 3,6 cm/s for mediolateral movements and less than 5,2 cm/s in anterior-posterior movements). This was the case during the record and Seeker should maintain it.<br><br>
              </mpro-indicator>

            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { movementKindToAssessmentName, KneeShapesSquat } from '../../helpers/enums'
import NarrativeIndicator from './narrative-indicator.vue'

// https://qinematic46.sharepoint.com/:x:/r/sites/TechnicalDevelopment/_layouts/15/Doc.aspx?sourcedoc=%7B315D2C22-1724-4C16-896B-6A82C196F7A7%7D&file=Posture_Assessment_Narrative.xlsx&action=edit&mobileredirect=true&wdPreviousSession=749f0b16-384d-4911-b9b3-9957e9e31c5e&wdOrigin=TEAMS-ELECTRON.teams.undefined

export default {
  components: {
    'mpro-indicator': NarrativeIndicator
  },

  props: {
    movementKind: {type: String, required: false, default: () => ''},
    bodyHeight: {type: Number, required: false, default: () => 0}
  },

  data: () => {
    return {
      narrativeText: null
    }
  },

  computed: {
    ...mapGetters('user', ['isMetric']),
    scanId: function () {
      return this.$route.name === 'Scan' ? this.$route.params.scanId : null
    },
    movement () {
      return movementKindToAssessmentName(this.movementKind)
    },
    bodyHeightCm: function () {
      return this.bodyHeight * 100
    },
    sideSingleSquat () {
      if (this.movement === 'SquatLeft') return 'left'
      if (this.movement === 'SquatRight') return 'right'
      return ''
    },
    sideSingleBalance () {
      if (this.movement === 'BalanceLeft') return 'left'
      if (this.movement === 'BalanceRight') return 'right'
      return ''
    },
    sideSingleSquatCap () {
      if (this.movement === 'SquatLeft') return 'Left'
      if (this.movement === 'SquatRight') return 'Right'
      return ''
    },
    kneeShapesSquat () {
      return KneeShapesSquat
    }
  },

  methods: {
    ...mapActions('scans', ['getOrLoadMovementNarrative'])
  },

  async mounted () {
    this.narrativeText = await this.getOrLoadMovementNarrative({scanId: this.scanId, movement: this.movement})
  }
}
</script>
