<template>
  <v-card-text>
    <v-form v-model="validProgram" ref="form">
      <v-container>
        <v-row>
          <v-col cols="12" lg="6" md="6" class="pb-0 mb-0">
            <mpro-date-box :label="$t('programs.edit.fields.start_date')"
                          v-model="program.StartDate"
                          class="mr-6"/>
            <v-text-field v-model="program.DurationWeeks"
                    type="number" :min="1"
                    :rules="numberRules"
                    :label="$t('programs.edit.fields.duration')" prepend-icon="mdi-clock-outline"
                    :hint="$t('programs.edit.fields.duration-hint')"/>
            <v-text-field v-model="program.FrequencyPerDay"
                    type="number"
                    :rules="numberRules"
                    :label="$t('programs.edit.fields.frequency')" prepend-icon="mdi-repeat"
                    :hint="$t('programs.edit.fields.frequency-hint')"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-textarea :label="$t('programs.edit.fields.notes')"
                    class="max-text-width"
                    v-model="program.Notes"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"  class="pt-0 mt-0">
            <v-radio-group v-model="program.SessionRepeat" row :rules="notEmptyRules" :label="$t('programs.edit.fields.repetition')" prepend-icon="mdi-swap-horizontal">
              <v-radio v-for="option in repeatProgram"  :label="$t(`programs.edit.fields.repeat-${option}`)" :value="option" :key="option" class="d-block"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="program.SessionRepeat == 'Custom'" :rules="notEmptyWeekdaySelect" multiple
                    class="" active-class="primary--text"
                    v-model="program.days">
          <v-col cols="6" lg="3" sx="4" class="ma-0 pa-0" v-for="(day, key) in DayOfTheWeek" :key="key">
            <v-checkbox
              class="ma-0 pa-0"
              :rules="notEmptyWeekdaySelect"
              v-model="program.days"
              :label="$t('general.weekdays.' + day)"
              :value="key"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
      <center>
        <v-btn text @click="$emit('resetProgram')" class="ma-2 ">{{ $t('forms.cancel') }}</v-btn>
        <v-btn  v-if="seeker" :disabled="!validProgram" @click="$emit('programDetails')" class="ma-2 MProCoral">{{ $t('programs.edit.buttons.select-exercises') }}</v-btn>
      </center>
    </v-form>
  </v-card-text>
</template>

<script>
import DateBox from '../elements/date-box'
import { DaysOfTheWeek } from '../../helpers/enums'

export default {
  components: {
    'mpro-date-box': DateBox
  },
  props: {
    'program': {},
    'seeker': {},
    'programDetails': {type: Boolean, required: false, default: () => true}
  },
  data: () => {
    return {
      repeatProgram: ['Daily', 'EveryOtherDay', 'Weekly', 'Custom'],
      assignExercises: [],
      validProgram: false
    }
  },
  computed: {
    notEmptyWeekdaySelect () {
      return [
        _ => this.program.days.length > 0 || this.$t('forms.validations.selectOneDay')
      ]
    },
    notEmptyRules () {
      return [
        v => !!v || this.$t('forms.validations.required')
      ]
    },
    numberRules () {
      return [
        v => !!v || this.$t('forms.validations.required'),
        v => /^[-+]?\d+$/.test(v) || this.$t('forms.validations.integer'),
        v => Number(v) >= 0 || this.$t('forms.validations.positiveNumber')
      ]
    },
    DayOfTheWeek: function () {
      return DaysOfTheWeek
    }
  },
  methods: {
    resetProgram () {
      this.$emit('resetProgram')
    }
  },
  watch: {
    program: function () {
      if (this.validProgram) this.$emit('program', this.program)
    }
  },
  mounted () {
    // this.$refs.form.reset()
  }
}
</script>
