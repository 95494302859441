<template>
  <div>
  <v-app-bar v-if="!presentMode" app dense :clipped-left="true" class="MProHeader" style="max-width: 100vw">
    <v-tooltip bottom class="d-inline-flex">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="menuToggle"
          style="margin-right: 48px"
        >
          <v-icon v-if="!menuOpen && !isSpecialist" >$menu</v-icon>
          <v-icon v-if="!menuOpen && isSpecialist" >$patients</v-icon>
        </v-btn>
      </template>
      <span v-if="!isSpecialist">{{ $t('menu.seeker-tooltip') }}</span>
      <span v-if="isSpecialist">{{ $t('menu.specialist-tooltip') }}</span>
    </v-tooltip>
    <v-spacer/>
    <mpro-logo class="color"/>
    <v-spacer class=""/>
    <div class="d-flex">
      <v-tooltip bottom class="d-inline-flex">
        <template #activator="{ on, attrs }">
          <v-btn icon link class="header-icon"
                 :to="{ name: 'Tutorials' }"
                 v-bind="attrs" v-on="on">
            <v-icon>mdi-school-outline</v-icon>
          </v-btn>
        </template>
        <span>{{$t('menu.tutorials')}}</span>
      </v-tooltip>
      <v-tooltip v-if="displayChats" bottom class="d-inline-flex">
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="chatToggle"
                 icon link
                 v-bind="attrs" v-on="on">
            <v-badge v-if="getUnreadCount > 0" class="ma-5"
                     color="MProCoral" overlap
                     :content="getUnreadCount">
              <v-icon>$chats</v-icon>
            </v-badge>
            <v-icon v-else>$chats</v-icon>
          </v-btn>
        </template>
        <span>{{$t('chats.general-title')}}</span>
      </v-tooltip>
      <v-menu offset-y
              v-model="accountMenuOpen">
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom class="d-inline-flex">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  icon
                  v-on="{ ...tooltip, ...menu }"
                  v-bind="attrs"
                >
                  <v-icon class="mr-n2">$user</v-icon>
                  <v-fade-transition leave-absolute>
                    <v-icon class="mr-n1 menu-chevron" v-if="!accountMenuOpen">mdi-chevron-down</v-icon>
                    <v-icon class="mr-n1 menu-chevron" v-else>mdi-chevron-up</v-icon>
                  </v-fade-transition>
                </v-btn>
                </template>
                <span>{{$t('account.button-tooltip')}}</span>
              </v-tooltip>
          </template>
          <v-list class="account-menu">
            <v-list-item :to="{name: 'MyAccount'}" :title="$t('account.title')">
              <v-list-item-avatar :color="avatarColor" class="lighten-2" size="40">
                <span>{{avatarText}}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-if="getDisplayName">{{getDisplayName}}</v-list-item-title>
                <v-list-item-subtitle>{{getUserEmail}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!-- Subordinate selector -->
            <v-list-group v-if="hasSubordinates" no-action sub-group @click.stop.prevent
                          v-model="subordinateMenuOpen" class="account-data-of">
              <template #activator>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ $t('account.view_data_of') }}</v-list-item-subtitle>
                    <v-list-item-title v-show="!subordinateMenuOpen">{{ viewDataOfName }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list-item class="account-data-of-item"
                           @click="onSubordinateSelected(null)">
                <v-list-item-content class="py-2">
                  <v-list-item-title>{{ $t('account.view_data_of_me') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="sub of subordinates" :key="sub.Id"
                           class="account-data-of-item"
                           @click="onSubordinateSelected(sub.Id)">
                <v-list-item-content class="py-2">
                  <v-list-item-title>{{ getSubordinateName(sub) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <!-- Role selector -->
            <v-list-group v-if="hasMultipleRoles" no-action sub-group @click.stop.prevent
                          v-model="roleMenuOpen">
              <template #activator>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{$t('account.view_as_role')}}</v-list-item-subtitle>
                    <v-list-item-title v-show="!roleMenuOpen" :class="getRoleItemColor(getRole)">
                      {{$t('account.view_as_' + getRole)}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list-item v-for="role in getAllRoles" :key="role"
                          @click="onSelectRole(role)"
                          style="min-height: 32px">
                <v-list-item-content class="py-2">
                  <v-list-item-title :class="getRoleItemColor(role)">{{$t('account.view_as_' + role)}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-divider class="my-3"></v-divider>
            <v-list-item link @click="signOut">
              <v-list-item-icon>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{$t('menu.signout')}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
        <template v-slot:extension v-if="isSpecialist || isAdministrator" >
          <div class="d-flex align-center w-100pc">
            <v-toolbar-title class="mr-4" style="flex: 1">
              <router-link v-if="getHeader.link != null" :to="getHeader.link" >{{
                getHeader.text
              }}</router-link>
              <span v-else>{{getHeader.text}}</span>
              <span v-if="getHeader.additionalText != null"
                    class="ml-2 d-none d-sm-inline" style="font-size: 80%">
                <router-link v-if="getHeader.additionalLink != null" :to="getHeader.additionalLink"
                              class="grey--text">{{
                  getHeader.additionalText
                }}</router-link>
                <span v-else class="grey--text">{{ getHeader.additionalText }}</span>
              </span>
            </v-toolbar-title>
            <v-menu v-if="getHeaderTabs != null" bottom offset-y>
              <template #activator="{ on, attrs }">
                <v-card v-on="on" v-bind="attrs"
                        flat class="ma-0 pa-0 transparent d-block text-centered flex-shrink-0">
                    <div class="ma-auto pa-0 d-flex justify-center">
                      <v-icon v-if="isAdministrator" class="mr-2">
                        {{ activeTabIcon }}
                      </v-icon>
                      <icon-base v-else :iconName="activeTab" width="32" height="32" class="d-inline-block">
                        <component :is="'icon-'+activeTab" />
                      </icon-base>
                      <v-card-title class="text-subtitle-1 ma-0 pa-0 d-inline-block" style="white-space: nowrap;">
                        {{ activeTabTitle }}
                      </v-card-title>
                      <v-icon>mdi-chevron-down</v-icon>
                    </div>
                </v-card>
              </template>
              <v-sheet class="middle-menu">
                <v-list v-if="isAdministrator">
                  <v-list-item v-for="tab in getHeaderTabs" :key="tab.value"
                                :to="tab.to"
                                class="my-3 mx-5">
                    <v-list-item-icon>
                      <v-icon>{{ tab.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{tab.title}}</v-list-item-title>
                  </v-list-item>
                </v-list>
                <div v-else class="py-2">
                  <v-card v-for="tab in getHeaderTabs" :key="`${tab.value}`"
                          :to="tab.to" link class="my-3 mx-5 pa-5 menu-item d-flex" hover>
                    <div>
                      <icon-base :iconName="tab.value" width="72" height="72"><component :is="'icon-'+tab.value" /></icon-base>
                    </div>
                    <div class="">
                      <v-card-title class="text-subtitle-1">{{ tab.title || $t('menu.' + tab.value) }}</v-card-title>
                      <v-card-subtitle class="text-subtitle-1">{{ tab.subtitle || $t('menu.specialist.sub' + tab.value, { name: getHeader.text }) }}</v-card-subtitle>
                    </div>
                  </v-card>
                </div>
              </v-sheet>
            </v-menu>
            <div class="ml-4 d-none d-md-block" style="flex: 1">
            </div>
          </div>
        </template>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import Logo from './logo'
import Utils from '@/helpers/utils'
import Styles from '@/helpers/styles'
import IconBase from './../icons/IconBase.vue'
import IconExercises from './../icons/Exercises.vue'
import IconScans from './../icons/Scans.vue'
import IconSurveys from './../icons/Surveys.vue'
import IconGoals from './../icons/Goals.vue'
import IconDashboard from './../icons/Dashboard.vue'
import LogoIcon from './../icons/LogoIcon.vue'

export default {
  components: {
    'icon-base': IconBase,
    'icon-exercises': IconExercises,
    'icon-scans': IconScans,
    'icon-surveys': IconSurveys,
    'icon-goals': IconGoals,
    'icon-profile': IconDashboard,
    'mpro-logo': Logo,
    'mpro-logo-icon': LogoIcon
  },
  data: () => {
    return {
      accountMenuOpen: false,
      subordinateMenuOpen: false,
      roleMenuOpen: false,
      presentMode: false
    }
  },
  computed: {
    menuOpen: {
      get: function () {
        return this.$store.state.user.userSettings.menuOpen
      },
      set: function (value) {
        this.$store.commit('user/setMenuOpen', value)
      }
    },
    activeTabTitle: function () {
      if (this.activeTab != null) {
        return this.activeTabItem?.title ??
          this.$t('menu.' + this.activeTab)
      } else {
        return null
      }
    },
    activeTabIcon: function () {
      return this.activeTab != null
        ? this.activeTabItem?.icon
        : null
    },

    hasMultipleRoles: function () {
      return this.getAllRoles?.length > 1
    },
    hasSubordinates: function () {
      return this.isSeeker
        ? this.getSeeker?.subordinates?.length > 0
        : false
    },
    subordinates: function () {
      const result = [...this.getSeeker.subordinates]
      result.sort((s1, s2) => {
        const name1 = this.getSubordinateName(s1)
        const name2 = this.getSubordinateName(s2)
        return name1 < name2 ? -1 : name1 > name2 ? +1 : 0
      })
      return result
    },
    avatarText: function () {
      let name = this.getDisplayName || this.getUserEmail || '?'
      return Utils.getUserInitials(name)
    },
    avatarColor: function () {
      return `${Styles.getRoleColor(this.getRole)} white--text`
    },
    viewDataOfName: function () {
      return this.activeSubordinate == null
        ? this.$t('account.view_data_of_me')
        : this.getSubordinateName(this.activeSubordinate)
    },
    displayChats: function () {
      // A Seeker has no access to the chat function
      // unless he already chatted with someone
      return !this.isSeeker || this.chatPartners.length > 0
    },

    ...mapGetters(['getRole', 'getHeader', 'isSeeker', 'isSpecialist', 'isAdministrator', 'getHeaderTabs', 'activeTab', 'activeTabItem']),
    ...mapGetters('user', ['getDisplayName', 'getUserEmail', 'getAllRoles', 'getSeeker']),
    ...mapGetters('chats', ['getUnreadCount']),
    ...mapState('chats', {
      chatPartners: state => state.partners
    }),
    ...mapGetters('seeker', ['activeSubordinate'])
  },

  watch: {
    accountMenuOpen: function (newValue) {
      if (!newValue) {
        this.subordinateMenuOpen = false
        this.roleMenuOpen = false
      }
    },
    subordinateMenuOpen: function (newValue) {
      if (newValue) this.roleMenuOpen = false
    },
    roleMenuOpen: function (newValue) {
      if (newValue) this.subordinateMenuOpen = false
    }
  },

  methods: {
    signOut () {
      this.$msal.signOut()
    },
    getRoleItemColor (role) {
      return Styles.getTextColor(Styles.getRoleColor(role))
    },
    async onSelectRole (role) {
      this.accountMenuOpen = false
      this.roleMenuOpen = false
      const prevRole = this.getRole
      await this.changeRole(role)
      if (this.getRole !== prevRole) this.gotoDashboard()
    },
    onSubordinateSelected (subId) {
      this.subordinateMenuOpen = false
      const prevSubId = this.activeSubordinate?.Id
      this.setActiveSubordinate(subId)
      subId = this.activeSubordinate?.Id
      if (subId !== prevSubId) this.gotoDashboard()
    },
    getSubordinateName (subordinate) {
      return `${subordinate.FirstName} ${subordinate.LastName}`
    },
    getHeaderTabText (tab) {
      if (typeof tab.text === 'string') {
        return tab.text
      } else if (typeof tab.text === 'function') {
        return tab.text(tab.value)
      } else {
        return tab.value
      }
    },
    gotoDashboard () {
      if (this.$route.name !== 'Dashboard') {
        this.$router.push({name: 'Dashboard'})
      }
    },
    ...mapActions('user', ['changeRole']),
    ...mapMutations('user', ['menuToggle', 'chatToggle']),
    ...mapMutations('seeker', ['setActiveSubordinate'])
  },
  mounted () {
    this.$root.$on('resizeElements', (presentMode) => {
      this.presentMode = presentMode
    })
  }
}
</script>

<style scoped>
.middle-menu {
  max-height: 80vh;
  overflow: auto;
}
.menu-chevron.theme--light {
  color: #242424 !important;
}
.menu-chevron.theme--dark {
  color: #F8F8F8 !important;
}
.header-icon.theme--light {
  color: rgba(0,0,0,0.87) !important;
}
.account-menu .account-data-of {
  max-height: 50vh;
  overflow-y: auto;
}
.account-menu .account-data-of-item {
  min-height: 32px;
}
</style>
