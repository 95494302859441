import axios from 'axios'
import { VueConstructor } from 'vue'
import { Store } from 'vuex'
import {} from 'dotenv'
import SessionMonitor from '../helpers/session-monitor'

export function init (Vue: VueConstructor<Vue>, store: Store<unknown>) {
  // The static Axios API will be used for requests to the backend
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

  // A request interceptor
  // * Sets a header with an auth token obtained from MSAL
  // * Sets a header with a user's timezone offset
  // * Sets a query parameter with the current locale
  // TODO: Do not update or track the last activity time instead (if needed)
  // * Updates a user's last login time
  // TODO: Consider SessionMonitor registering its own interecptors instead
  // * Updates SessionMonitor
  axios.interceptors.request.use(async config => {
    const result = await Vue.prototype.$msal.acquireToken()
    if (result) {
      const sessionIsValid = await SessionMonitor.onTokenAcquired(
        result.userId, result.issuedAt, result.expiresAt, result.fromCache)
      if (sessionIsValid) {
        if (config.headers == null) config.headers = {}
        config.headers['Authorization'] = `Bearer ${result.accessToken}`
        store.commit('user/storeLastLogin', result.authTime)
      }
    } else {
      SessionMonitor.clear()
    }

    // set client locale
    const locale = store.getters['user/getSettings']()?.locale
    if (locale) {
      if (config.params == null) config.params = {}
      config.params.locale = locale
    }

    // set client time zone offset
    if (config.headers == null) config.headers = {}
    config.headers['X-Mpro-Utc-Offset'] = -(new Date().getTimezoneOffset())

    return config
  })

  // A response interceptor
  // * (In development only) Displays a message about an erroneous response
  // * Signs out on 401 status code
  axios.interceptors.response.use(
    value => value,
    async function (err) {
      if (Vue.prototype.$nodeEnv.isDevelopment) {
        store.dispatch('showGlobalMessage', err.toString())
      }
      if (err.response != null && err.response.status === 401) {
        SessionMonitor.clear()
        await Vue.prototype.$msal.signOut()
      }
      return Promise.reject(err)
    })
}

// Use a custom instance for requests to the widgets
export const widgets = axios.create({
  baseURL: process.env.VUE_APP_WIDGETS_BASE_URL
})
