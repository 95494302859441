

































































































































import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { NavigationGuardNext, Route } from 'vue-router'
import { DataTableHeader } from 'vuetify'
import Lo from 'lodash'
import moment from 'moment'
import { TranslateResult } from 'vue-i18n'
import DateBox from '@/components/elements/date-box.vue'
import OrganizationFilter from './organization-filter.vue'
import RequestNoteDialog from './request-note-dialog.vue'
import EditLibrariesDialog from './edit-libraries-dialog.vue'
import SelectLibraryDialog from './select-library-dialog.vue'
import ConfirmationDialog from '@/components/elements/confirmation-dialog.vue'
import { SharingSeeker, DataSharingStatus } from '@/models/data-sharing'
import { IconInfo, getDataSharingStatusIcon } from '@/models/ui'
import { MessageKind } from '@/helpers/enums'
import TabsMixin from './tabs-mixin'
import { Library, Organization } from '@/models/admin'

interface Request extends SharingSeeker {
  key: string,
  statusIcon: IconInfo,
  seekerNameOrEmail: string,
  showNote: boolean,
  libraries: string
}

export default Vue.extend({
  components: {
    'mpro-datebox': DateBox,
    'mpro-organization-filter': OrganizationFilter,
    'mpro-request-note-dialog': RequestNoteDialog,
    'mpro-edit-libraries-dialog': EditLibrariesDialog,
    'mpro-select-library-dialog': SelectLibraryDialog,
    'mpro-confirmation-dialog': ConfirmationDialog
  },

  mixins: [TabsMixin],

  data: () => ({
    loadedRequests: new Array<SharingSeeker>(0),
    refreshRequests: 0,
    selectedRequests: new Array<Request>(0),
    acceptingSelected: false,
    rejectingSelected: false,
    rejectingAccepted: false,
    showingRequestNote: false,
    noteSeeker: undefined as SharingSeeker | undefined,
    editingSeekerLibraries: false,
    editedSeeker: undefined as SharingSeeker | undefined,
    search: '',
    filterDate: undefined as Date | undefined,
    filterStatus: '' as DataSharingStatus | string,
    tableSortBy: 'GrantedAt',
    tableSortDesc: true
  }),

  computed: {
    ...mapState('admin', {
      /* eslint-disable */
      organizations: (state: any): Organization[] => Lo.orderBy(state.organizations, o => o.Name.toLocaleLowerCase()),
      libraries: (state: any): Library[] => state.libraries
      /* eslint-enable */
    }),
    organizationCode: {
      get (): string {
        return this.$route.params.organizationCode
      },
      set (value: string) {
        this.$router.replace({
          name: 'admin:SharingSeekersOfOrganization',
          params: { organizationCode: value }
        })
      }
    },
    libraryNames: function (): { [id: string]: string } {
      return Lo(this.libraries).mapKeys(l => l.Id).mapValues(l => l.Name).value()
    },
    tableHeaders: function (): DataTableHeader[] {
      return [{
        text: this.$t('admin.sharing-seekers.status').toString(),
        value: 'Status',
        sortable: false,
        align: 'center'
      }, {
        text: this.$t('admin.sharing-seekers.name').toString(),
        value: 'seekerNameOrEmail'
      }, {
        text: this.$t('admin.sharing-seekers.request-date').toString(),
        value: 'GrantedAt',
        sort: (a: string, b: string) => moment(a).diff(b, 'seconds')
      }, {
        text: this.$t('admin.sharing-seekers.note').toString(),
        value: 'Notes',
        sortable: false
      }, {
        text: this.$t('admin.sharing-seekers.source').toString(),
        value: 'Source',
        sortable: false
      }, {
        text: this.$t('admin.sharing-seekers.scope').toString(),
        value: 'Scope',
        sortable: false
      }, {
        text: this.$t('admin.sharing-seekers.libraries').toString(),
        value: 'libraries',
        sortable: false
      }]
    },
    requests: function (): Request[] {
      let result = this.loadedRequests
        .map(r => ({
          ...r,
          key: `${r.SeekerId}\n${r.OrganizationCode}`,
          statusIcon: getDataSharingStatusIcon(r.Status),
          seekerNameOrEmail: r.SeekerName || r.SeekerEmail || '',
          showNote: false,
          libraries: r.LibraryIds.map(id => this.libraryNames[id] ?? id).join(', ')
        }))
      return result
    },
    filteredRequests: function (): Request[] {
      let result = this.requests.slice()
      if (this.filterDate != null) {
        const date = this.$moment(this.filterDate).utcOffset(0, true)
        result = Lo.filter(result, r => this.$moment(r.GrantedAt).utcOffset(0, true).isSame(date, 'day'))
      }
      if (this.filterStatus?.length > 0) {
        result = Lo.filter(result, r => r.Status === this.filterStatus)
      }
      if (this.search?.length > 0) {
        const search = this.search.toLocaleLowerCase()
        result = Lo.filter(result, r => (r.SeekerName || '').toLocaleLowerCase().includes(search) ||
          (r.SeekerEmail || '').toLocaleLowerCase().includes(search))
      }
      return result
    },
    filterStatusItems: function (): { text: TranslateResult, value: DataSharingStatus }[] {
      return [
        { text: this.$t('enums.DataSharingStatus.New'), value: DataSharingStatus.New },
        { text: this.$t('enums.DataSharingStatus.Active'), value: DataSharingStatus.Active }
      ]
    },
    resolvingSelected: function (): boolean {
      return this.acceptingSelected || this.rejectingSelected
    },
    disableAddRemoveLibrary: function (): boolean {
      return this.selectedRequests.length === 0 ||
        this.selectedRequests.some(r => r.Status !== DataSharingStatus.Active)
    }
  },

  asyncComputed: {
    loadedRequests: {
      async get (): Promise<SharingSeeker[]> {
        if (this.organizationCode == null) return []

        const result: SharingSeeker[] = await this.loadActiveSharings({
          organizationCode: this.organizationCode
        })
        return result
      },
      default: [],
      watch: ['refreshRequests']
    }
  },

  watch: {
    editingSeekerLibraries (newValue) {
      if (!newValue) {
        this.editedSeeker = undefined
      }
    }
  },

  methods: {
    ...mapActions(['setActiveTab', 'showGlobalMessage']),
    ...mapActions('admin', [
      'loadActiveSharings', 'resolveDataSharingRequest',
      'ensureOrganizationsLoaded', 'ensureLibrariesLoaded',
      'addSeekerToLibrary', 'removeSeekerFromLibrary'
    ]),
    async selectOrganization () {
      await this.ensureOrganizationsLoaded()
      if (this.organizationCode == null) {
        this.organizationCode = this.organizations[0].Code
      } else if (!this.organizations.some(o => o.Code === this.organizationCode)) {
        this.$router.push({ name: 'admin:SharingSeekers' })
      }
    },
    async resolveSelected (accepted: boolean, skipConfirmations = false): Promise<void> {
      if (this.selectedRequests.length === 0) return

      if (accepted) {
        this.acceptingSelected = true
      } else {
        if (this.selectedRequests.some(r => r.Status === DataSharingStatus.Active) && !skipConfirmations) {
          this.rejectingAccepted = true
          return
        }
        this.rejectingSelected = true
      }
      const requests = this.selectedRequests.slice()
      const errors: unknown[] = []
      for (let req of requests) {
        try {
          await this.resolveDataSharingRequest({
            seekerId: req.SeekerId,
            organizationCode: req.OrganizationCode,
            accepted
          })
        } catch (e) {
          errors.push(e)
          continue
        }
        const index = this.selectedRequests.indexOf(req)
        this.selectedRequests.splice(index, 1)
      }

      if (errors.length === 0) {
        const message = accepted
          ? this.$t('admin.sharing-seekers.accepted')
          : this.$t('admin.sharing-seekers.rejected')
        this.showGlobalMessage({ text: message, kind: MessageKind.SUCCESS })
      } else if (errors.length === requests.length) {
        this.showGlobalMessage({ text: this.$errorMessage(errors[0]), kind: MessageKind.ERROR })
      } else {
        const message = accepted
          ? this.$t('admin.sharing-seekers.accepted-not-all')
          : this.$t('admin.sharing-seekers.rejected-not-all')
        this.showGlobalMessage({ text: message, kind: MessageKind.WARNING })
      }

      this.refreshRequests++
      this.acceptingSelected = false
      this.rejectingSelected = false
    },
    async addSelectedToLibrary (libraryId: string): Promise<boolean> {
      const count = this.selectedRequests.length
      if (count === 0) return true

      let errors = []
      for (const item of this.selectedRequests.slice()) {
        try {
          await this.addSeekerToLibrary({ libraryId, seekerId: item.SeekerId })
        } catch (e) {
          errors.push(e)
          continue
        }
        const index = this.selectedRequests.indexOf(item)
        this.selectedRequests.splice(index, 1)
      }

      if (errors.length === count) {
        this.showGlobalMessage({
          text: this.$errorMessage(errors[0]),
          kind: MessageKind.ERROR
        })
      } else if (errors.length > 0) {
        this.showGlobalMessage({
          text: this.$t('admin.sharing-seekers.added-to-library-not-all'),
          kind: MessageKind.WARNING
        })
      } else {
        this.showGlobalMessage({
          text: this.$t('admin.sharing-seekers.added-to-library'),
          kind: MessageKind.SUCCESS
        })
      }

      const success = errors.length < count
      if (success) this.refreshRequests++
      return success
    },
    async removeSelectedFromLibrary (libraryId: string): Promise<boolean> {
      const count = this.selectedRequests.length
      if (count === 0) return true

      let errors = []
      for (const item of this.selectedRequests.slice()) {
        try {
          await this.removeSeekerFromLibrary({ libraryId, seekerId: item.SeekerId })
        } catch (e) {
          errors.push(e)
          continue
        }
        const index = this.selectedRequests.indexOf(item)
        this.selectedRequests.splice(index, 1)
      }

      if (errors.length === count) {
        this.showGlobalMessage({
          text: this.$errorMessage(errors[0]),
          kind: MessageKind.ERROR
        })
      } else if (errors.length > 0) {
        this.showGlobalMessage({
          text: this.$t('admin.sharing-seekers.removed-from-library-not-all'),
          kind: MessageKind.WARNING
        })
      } else {
        this.showGlobalMessage({
          text: this.$t('admin.sharing-seekers.removed-from-library'),
          kind: MessageKind.SUCCESS
        })
      }

      const success = errors.length < count
      if (success) this.refreshRequests++
      return success
    },
    showRequestNote (seeker: SharingSeeker) {
      this.noteSeeker = seeker
      this.showingRequestNote = true
    },
    editSeekerLibraries (seeker: SharingSeeker) {
      this.editedSeeker = seeker
      this.editingSeekerLibraries = true
    },
    toggleShowNote (item: Request) {
      if (item.showNote) {
        item.showNote = false
      } else {
        this.requests.forEach(r => { r.showNote = false })
        item.showNote = true
      }
    }
  },

  beforeRouteEnter (_: Route, __: Route, next: NavigationGuardNext<Vue>) {
    // eslint-disable-next-line
    next((vm: any) => vm.selectOrganization())
  },
  created () {
    this.ensureLibrariesLoaded()
  },
  mounted () {
    // Tab names are defined in tabs-mixin
    this.setActiveTab('seekers')
  }
})
