<template>
  <v-form>
    <v-text-field v-model.trim="data.code" :label="$t('admin.organization.code')"
                  :readonly="readonly || isExisting" :hide-details="readonly"
                  @input="$v.data.code.$touch()" @blur="$v.data.code.$touch()"
                  :error-messages="$validationErrors($v.data.code)"/>
    <v-text-field v-model.trim="data.name" :label="$t('admin.organization.name')"
                  :readonly="readonly" :hide-details="readonly"
                  @input="$v.data.name.$touch()" @blur="$v.data.name.$touch()"
                  :error-messages="$validationErrors($v.data.name)"/>
    <v-autocomplete v-model="data.kind" :label="$t('admin.organization.kind')"
                    :items="organizationKinds"
                    :readonly="readonly || isExisting && !isQinematicAdmin" :hide-details="readonly"
                    @input="$v.data.kind.$touch()" @blur="$v.data.kind.$touch()"
                    :error-messages="$validationErrors($v.data.kind)"/>
    <div v-if="isQinematicAdmin" class="d-flex flex-wrap">
      <v-checkbox class="mr-4"
                  v-model="data.paying" :label="$t('admin.organization.paying')"
                  :readonly="readonly" :hide-details="readonly" />
      <v-checkbox class="mr-4"
                  v-model="data.widgetAvailable" :label="$t('admin.organization.widgetAvailable')"
                  :readonly="readonly" :hide-details="readonly" />
      <v-checkbox class="mr-4"
                  v-model="data.offers3DService" :label="$t('admin.organization.offers3DService')"
                  :readonly="readonly" :hide-details="readonly" />
    </div>
    <mpro-organization-select v-model="data.master" :label="$t('admin.organization.master')"
                              :organizations="masterOrganizations"
                              :readonly="readonly" :hide-details="readonly"
                              @input="$v.data.master.$touch()" @blur="$v.data.master.$touch()"
                              :error-messages="$validationErrors($v.data.master)"/>
    <mpro-country-select v-model="data.country" :label="$t('admin.organization.country')"
                         :readonly="readonly" :hide-details="readonly"
                         @input="$v.data.country.$touch()" @blur="$v.data.country.$touch()"
                         :error-messages="$validationErrors($v.data.country)"/>
    <v-text-field v-model.trim="data.address" :label="$t('admin.organization.address')"
                  :readonly="readonly" :hide-details="readonly"
                  @input="$v.data.address.$touch()" @blur="$v.data.address.$touch()"
                  :error-messages="$validationErrors($v.data.address)"/>
    <v-text-field v-model.trim="data.email" :label="$t('admin.organization.email')"
                  :readonly="readonly" :hide-details="readonly" type="email"
                  @input="$v.data.email.$touch()" @blur="$v.data.email.$touch()"
                  :error-messages="$validationErrors($v.data.email)"/>
    <v-text-field v-model.trim="data.phone" :label="$t('admin.organization.phone')"
                  :readonly="readonly" :hide-details="readonly" type="phone"
                  @input="$v.data.phone.$touch()" @blur="$v.data.phone.$touch()"
                  :error-messages="$validationErrors($v.data.phone)"/>
    <v-text-field v-model.trim="data.timeZoneOffset" :label="$t('admin.organization.timeZoneOffset')"
                  :readonly="readonly" :hide-details="readonly" type="number" suffix="minutes"
                  @input="$v.data.timeZoneOffset.$touch()" @blur="$v.data.timeZoneOffset.$touch()"
                  :error-messages="$validationErrors($v.data.timeZoneOffset)"/>
    <v-textarea v-model.trim="data.description" :label="$t('admin.organization.description')"
                :readonly="readonly" :hide-details="readonly"
                @input="$v.data.description.$touch()" @blur="$v.data.description.$touch()"
                :error-messages="$validationErrors($v.data.description)"/>
  </v-form>
</template>

<script>
import OrganizationSelect from '@/components/elements/organization-select'
import CountrySelect from '@/components/elements/country-select'
import {required, integer, email} from 'vuelidate/lib/validators'
import { mapState, mapActions, mapGetters } from 'vuex'
import {OrganizationKind} from '@/helpers/enums'

export default {
  components: {
    'mpro-organization-select': OrganizationSelect,
    'mpro-country-select': CountrySelect
  },

  props: {
    organization: {type: Object, default: null},
    readonly: {type: Boolean, default: false}
  },

  data: () => ({
    data: {
      master: '',
      code: '',
      name: '',
      kind: '',
      paying: true,
      widgetAvailable: false,
      offers3DService: true,
      country: '',
      address: '',
      email: '',
      phone: '',
      timeZoneOffset: 0,
      description: ''
    }
  }),

  validations: {
    data: {
      code: {required},
      name: {required},
      kind: {required},
      master: {required},
      country: {required},
      address: {required},
      email: {required, email},
      phone: {required},
      timeZoneOffset: {required, integer},
      description: {},
      paying: {},
      widgetAvailable: {}
    }
  },

  computed: {
    isExisting: function () {
      return this.organization != null
    },
    organizationKinds: function () {
      const kinds = Object.values(OrganizationKind)
      kinds.sort()
      return kinds
    },
    masterOrganizations: function () {
      const result = (this.administratedOrganizations || [])
        .filter(o => o.Kind === OrganizationKind.MASTER)
      const containsCurrentMaster = this.organization == null ||
        result.some(o => o.Code === this.organization.MasteredByCode)
      if (!containsCurrentMaster) {
        result.push({
          Code: this.organization.MasteredByCode,
          Name: this.organization.MasteredByName
        })
      }
      return result
    },
    ...mapState('admin', {
      administratedOrganizations: 'organizations'
    }),
    ...mapGetters('user', ['isQinematicAdmin'])
  },

  watch: {
    organization: function () {
      this.syncOrganizationToData()
    }
  },

  methods: {
    syncOrganizationToData () {
      if (this.organization != null) {
        this.data.master = this.organization.MasteredByCode
        this.data.code = this.organization.Code
        this.data.name = this.organization.Name
        this.data.kind = this.organization.Kind
        this.data.description = this.organization.Description
        this.data.paying = this.organization.IsPaying
        this.data.widgetAvailable = this.organization.WidgetAvailable
        this.data.offers3DService = this.organization.Offers3DService
        this.data.country = this.organization.CountryCode
        this.data.address = this.organization.Address
        this.data.email = this.organization.Email
        this.data.phone = this.organization.Phone
        this.data.timeZoneOffset = this.organization.TimeZoneOffsetMinutes
      } else {
        for (const key in this.data) {
          this.data[key] = ''
        }
        this.data.paying = true
        this.data.widgetAvailable = false
        this.data.offers3DService = true
        this.data.timeZoneOffset = 0
      }
    },
    validate () {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    async reset () {
      this.syncOrganizationToData()
      await this.$nextTick()
      this.$v.$reset()
    },
    ...mapActions('admin', ['ensureOrganizationsLoaded'])
  },

  created () {
    this.syncOrganizationToData()
    this.ensureOrganizationsLoaded()
  }
}
</script>
