<template>
  <div v-if="assessment != null">
    <div class="d-flex justify-space-between">
      <!-- Put measures for the left body side to the right (closer to the left body side on a video) -->
      <div class="d-flex flex-column ml-4" style="width: 20%">
        <div v-for="(locationMeasures, name) in measuresMetadataOther" :key="name"
            class="my-4">
          <mpro-measure-card :location-name="name"
                            :location-measures="locationMeasures"
                            :assessment="assessment"/>
        </div>
      </div>
      <div class="d-flex flex-column mr-4" style="width: 20%">
        <div v-for="(locationMeasures, name) in measuresMetadataLeft" :key="name"
            class="my-4">
          <mpro-measure-card :location-name="name"
                            :location-measures="locationMeasures"
                            :assessment="assessment"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ScanView3D } from '@/models/ui'
import MeasureCard from './measure-card'

export default {
  components: {
    'mpro-measure-card': MeasureCard
  },

  props: {
    assessment: Object,
    metadata: Object
  },

  computed: {
    measuresMetadata: function () {
      if (this.metadata == null) return {}

      let measures = JSON.parse(JSON.stringify(this.metadata.measures))
      if (measures == null) return {}

      Object.keys(measures).forEach(loc => {
        const location = measures[loc]
        Object.keys(location).forEach(m => {
          // There are keys with general props of location
          // so check if references object is an actual measure before analyzing it
          const measure = location[m]
          if (measure.viewFrom != null) {
            if (!measure.viewFrom.includes(ScanView3D.Front)) {
              delete location[m]
            }
          }
        })
        if (Object.keys(location).length === 0) {
          delete measures[loc]
        }
      })
      return measures
    },
    measuresMetadataLeft: function () {
      const result = {}
      Object.keys(this.measuresMetadata).filter(name => name.startsWith('Left')).forEach(name => {
        result[name] = this.measuresMetadata[name]
      })
      return result
    },
    measuresMetadataOther: function () {
      const result = {}
      Object.keys(this.measuresMetadata).filter(name => !name.startsWith('Left')).forEach(name => {
        result[name] = this.measuresMetadata[name]
      })
      return result
    }
  }
}
</script>
