







































import Vue from 'vue'
import { DataTableHeader } from 'vuetify'
import { mapActions, mapState } from 'vuex'
import { Route, NavigationGuardNext } from 'vue-router'
import { TranslateResult } from 'vue-i18n'
import Lo from 'lodash'
import TabsMixin from './tabs-mixin'
import OrganizationFilter from './organization-filter.vue'
import DateBox from '@/components/elements/date-box.vue'
import { Organization } from '@/models/admin'
import { ListItem } from '@/models/vuetify'
import Utils from '@/helpers/utils'
import { SharedDataKind } from '@/models/data-sharing'
import moment from 'moment'

enum EventName {
  GrantDataSharing = 'GrantDataSharing',
  RevokeDataSharing = 'RevokeDataSharing',
  AcceptDataSharing = 'AcceptDataSharing',
  RejectDataSharing = 'RejectDataSharing',
  PostChatMessageToSeeker = 'PostChatMessageToSeeker',
  PostChatMessageFromSeeker = 'PostChatMessageFromSeeker',
  AssignExerciseProgram = 'AssignExerciseProgram',
  CreateSeekerGoal = 'CreateSeekerGoal',
  CreateSeekerGoalBySpecialist = 'CreateSeekerGoalBySpecialist',
  UpdateSeekerGoal = 'UpdateSeekerGoal',
  CompleteSeekerGoal = 'CompleteSeekerGoal',
  DeleteSeekerGoal = 'DeleteSeekerGoal',
  RegisterScan = 'RegisterScan',
  RegisterScan2D = 'RegisterScan2D',
  GenerateSeekerScanCode = 'GenerateSeekerScanCode',
  PostTextComment = 'PostTextComment',
  DownloadReport = 'DownloadReport',
  DownloadReportBySpecialist = 'DownloadReportBySpecialist',
  AssignSurvey = 'AssignSurvey',
  SaveCustomSurvey = 'SaveCustomSurvey',
  ViewSurvey = 'ViewSurvey',
  ViewSurveyBySpecialist = 'ViewSurveyBySpecialist',
  ViewScan = 'ViewScan',
  ViewScanBySpecialist = 'ViewScanBySpecialist',
  DownloadBackup = 'DownloadBackup'
}

interface EventItem {
  SeekerId: string,
  SeekerName?: string,
  SeekerEmail?: string,
  SeekerIsActive: boolean,
  seekerNameOrEmail?: string,
  Event: EventName,
  Date: string,
  ScanCapturedAt?: string,
  GrantScope?: string[],
  ReportKind?: string,
  QuestionnaireCode?: string,
  SurveyAnsweredAt?: string,
  DataKind?: SharedDataKind,
  Specialist?: string,
  SpecialistName?: string,
  SpecialistMoniker?: string,
  specialistNameOrEmail?: string
}

export default Vue.extend({
  components: {
    'mpro-organization-filter': OrganizationFilter,
    'mpro-datebox': DateBox
  },

  mixins: [TabsMixin],

  data: () => ({
    loadedItems: new Array<EventItem>(0),
    tableSortBy: 'Date',
    tableSortDesc: true
  }),

  computed: {
    ...mapState('admin', {
      // eslint-disable-next-line
      organizations: (state: any) => state.organizations as Organization[]
    }),
    organizationCode: {
      get (): string {
        return this.$route.params.organizationCode
      },
      set (value: string) {
        this.$router.replace({
          name: 'admin:SeekerEventsOfOrganization',
          params: { organizationCode: value },
          query: { ...this.$route.query, seeker: undefined }
        })
      }
    },
    filterFrom: function (): Date | null {
      const from = this.$route.query.from?.toString()
      return from === '' ? null
        : from == null ? this.$moment().startOf('day').add(-1, 'month').toDate()
          : Utils.parseIsoDate(from)
    },
    filterTill: function (): Date | null {
      const till = this.$route.query.till?.toString()
      return till === '' ? null
        : till == null ? this.$moment().startOf('day').toDate()
          : Utils.parseIsoDate(till)
    },
    filterSeekerId: function (): string {
      return this.$route.query.seeker?.toString() ?? ''
    },
    tableHeaders: function (): DataTableHeader[] {
      return [{
        text: this.$t('admin.seeker-events.seeker').toString(),
        value: 'seekerNameOrEmail'
      }, {
        text: this.$t('admin.seeker-events.date').toString(),
        value: 'Date',
        sort: (a: string, b: string) => moment(a).diff(b, 'seconds')
      }, {
        text: this.$t('admin.seeker-events.event').toString(),
        value: 'Event'
      }, {
        text: this.$t('admin.seeker-events.specialist').toString(),
        value: 'specialistNameOrEmail'
      }]
    }
  },

  asyncComputed: {
    loadedItems: {
      async get (): Promise<EventItem[]> {
        if (this.organizationCode == null) return []

        const result: EventItem[] = await this.loadSeekerEvents({
          organizationCode: this.organizationCode,
          from: this.filterFrom,
          till: this.filterTill,
          seekerId: this.filterSeekerId
        })
        result.forEach(r => {
          r.seekerNameOrEmail = r.SeekerName ?? r.SeekerEmail
          r.specialistNameOrEmail = r.SpecialistName ?? r.Specialist ??
            (r.SpecialistMoniker == null ? undefined : this.$t('specialist.anonymous').toString())
        })
        return result
      },
      default: []
    },
    filterSeekerItems: {
      async get (): Promise<ListItem[]> {
        if (this.organizationCode == null) return []

        const seekers = await this.loadSharingSeekers({
          organizationCode: this.organizationCode
        })
        return Lo(seekers)
          .map(s => ({
            value: s.Id,
            text: s.Name ?? s.Email
          }))
          .orderBy(s => (s.text ?? '').toLocaleLowerCase())
          .value()
      },
      default: []
    }
  },

  watch: {
    organizationCode () {
      if ((this.filterSeekerId ?? '').length > 0) {
        this.onFilterSeekerChanged('')
      }
    }
  },

  methods: {
    ...mapActions(['setActiveTab']),
    ...mapActions('admin', ['loadSeekerEvents', 'ensureOrganizationsLoaded', 'loadSharingSeekers']),
    async selectOrganization () {
      await this.ensureOrganizationsLoaded()
      if (this.organizationCode == null) {
        this.organizationCode = this.organizations[0].Code
      } else if (!this.organizations.some(o => o.Code === this.organizationCode)) {
        this.$router.replace({ name: 'admin:SeekerEvents' })
      }
    },
    onFilterSeekerChanged (newValue: string) {
      const trimmedValue = (newValue ?? '').trim()
      const seeker = trimmedValue === '' ? undefined : trimmedValue

      if (seeker === this.$route.query.seeker || trimmedValue === (this.filterSeekerId ?? '').trim()) return

      this.$router.replace({
        name: this.$route.name ?? '',
        params: this.$route.params,
        query: { ...this.$route.query, seeker }
      })
    },
    onFilterFromChanged (newValue: Date | null) {
      const from = newValue == null ? '' : Utils.toIsoDate(newValue)
      if (from === this.$route.query.from || newValue?.valueOf() === this.filterFrom?.valueOf()) return

      this.$router.replace({
        name: this.$route.name ?? '',
        params: this.$route.params,
        query: { ...this.$route.query, from }
      })
    },
    onFilterTillChanged (newValue: Date | null) {
      const till = newValue == null ? '' : Utils.toIsoDate(newValue)
      if (till === this.$route.query.till || newValue?.valueOf() === this.filterTill?.valueOf()) return

      this.$router.replace({
        name: this.$route.name ?? '',
        params: this.$route.params,
        query: { ...this.$route.query, till }
      })
    },
    getItemClass (item: EventItem): string {
      return item.SeekerIsActive ? '' : 'text--secondary'
    },
    getEventDescription (item: EventItem): TranslateResult {
      const scanDate = item.ScanCapturedAt != null ? this.$formatDateTime(item.ScanCapturedAt) : ''
      const surveyName = item.QuestionnaireCode
      switch (item.Event) {
        case EventName.GrantDataSharing:
          const scope = item.GrantScope?.map(v => this.$t('enums.SharedDataKind.' + v).toString())?.join(', ')
          return this.$t('admin.seeker-events.descriptions.GrantDataSharing', { scope })
        case EventName.PostTextComment:
        case EventName.ViewScan:
        case EventName.ViewScanBySpecialist:
          return this.$t('admin.seeker-events.descriptions.' + item.Event, { scanDate })
        case EventName.DownloadReport:
        case EventName.DownloadReportBySpecialist:
          const reportName = this.$te('admin.seeker-events.download-reports.' + item.ReportKind)
            ? this.$t('admin.seeker-events.download-reports.' + item.ReportKind)
            : this.$t('admin.seeker-events.download-reports.other')
          return this.$t('admin.seeker-events.descriptions.' + item.Event, { scanDate, reportName })
        case EventName.AssignSurvey:
        case EventName.SaveCustomSurvey:
          return this.$t('admin.seeker-events.descriptions.' + item.Event, { surveyName })
        case EventName.ViewSurvey:
        case EventName.ViewSurveyBySpecialist:
          const surveyDate = this.$formatDateTime(item.SurveyAnsweredAt)
          return this.$t('admin.seeker-events.descriptions.' + item.Event, { surveyName, surveyDate })
        case EventName.DownloadBackup:
          const dataKind = this.$t('enums.SharedDataKind.' + item.DataKind)
          return this.$t('admin.seeker-events.descriptions.DownloadBackup', { dataKind })
        default:
          return this.$t('admin.seeker-events.descriptions.' + item.Event)
      }
    }
  },

  beforeRouteEnter (_: Route, __: Route, next: NavigationGuardNext<Vue>) {
    // eslint-disable-next-line
    next((vm: any) => vm.selectOrganization())
  },
  mounted () {
    // Tab names are defined in tabs-mixin
    this.setActiveTab('events')
  }
})
