import { Role, MessageKind } from './enums'

function getRoleColor (role: string): string {
  switch (role) {
    case Role.ADMINISTRATOR: return 'pink'
    case Role.SPECIALIST: return 'teal'
    case Role.SEEKER: return 'indigo'
    default: return 'grey'
  }
}

function getTextColor (color: string): string {
  return `${color}--text`
}

function getMessageColor (kind: string): string {
  switch (kind) {
    case MessageKind.ERROR: return 'MProCoral'
    case MessageKind.SUCCESS: return 'MProGreen'
    case MessageKind.WARNING: return 'MProYellow'
    default: return 'MProBlue'
  }
}

export default {
  getRoleColor,
  getTextColor,
  getMessageColor
}
