import {} from 'dotenv'
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { widgets as axiosWidgets } from '../config/axios'
import goals from './modules/goals'
import exercises from './modules/exercises'
import surveys from './modules/surveys'
import scans from './modules/scans'
import scans2d from './modules/scans2d'
import user from './modules/user'
import seeker from './modules/seeker'
import specialist from './modules/specialist'
import measures from './modules/measures'
import admin from './modules/admin'
import chats from './modules/chats'
import activities from './modules/activities'
import {Role, MessageKind} from '../helpers/enums'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    appInitialized: false,
    adBlockerWhiteList: null,
    justSignedIn: false,
    brandName: process.env.VUE_APP_TITLE,
    headerTitle: {
      text: '',
      link: undefined,
      additionalText: undefined
    },
    globalMessage: [],
    headerTabs: undefined,
    activeTab: null,
    validCountryCodes: [],
    widgetLocales: []
  },
  getters: {
    getHeader: state => state.headerTitle,
    activeTab: state => {
      return state.activeTab
    },
    activeTabItem: state => {
      return state.activeTab == null || state.headerTabs == null
        ? null
        : state.headerTabs.find(t => t.value === state.activeTab)
    },
    getHeaderTabs: state => {
      return state.headerTabs
    },
    getGlobalMessage: state => {
      return state.globalMessage
    },
    getRole: state => {
      return state['user'].userSettings.viewAs
    },
    isSeeker: (_, getters) => getters.getRole === Role.SEEKER,
    isSpecialist: (_, getters) => getters.getRole === Role.SPECIALIST,
    isAdministrator: (_, getters) => getters.getRole === Role.ADMINISTRATOR
  },
  actions: {
    loadEnums: async context => {
      try {
        let res = await axios.get('/misc/country-codes')
        context.commit('setValidCountryCodes', res.data)
        res = await axiosWidgets.get('/metadata/locales')
        context.commit('setWidgetLocales', res.data)
      } catch (e) {
        console.error('Couldn\'t load some enums. Some app functions will work incorrectly.', e)
      }
    },
    loadPublicOrganization: async (_, code) => {
      try {
        let res = await axios.get(`/misc/public-organizations/${encodeURIComponent(code)}`)
        return res.data
      } catch (e) {
        if (e.response?.status !== 404) {
          console.warn('loadPublicOrganization:axios', e)
        }
        return null
      }
    },
    showGlobalMessage: ({state, commit}, message) => {
      if (message) {
        const timeout = Number(message.timeout) || 5000
        let m = {
          id: state.globalMessage.length > 0 ? state.globalMessage[state.globalMessage.length - 1].id + 1 : 1,
          kind: message.kind,
          text: message.text,
          expires: Date.now() + timeout
        }
        if (!m.kind) m.kind = MessageKind.INFO
        if (!m.text) m.text = message.toString()

        commit('addGlobalMessage', m)

        setTimeout(() => {
          commit('removeExpiredGlobalMessages')
        }, timeout)
      }
    },
    setHeaderTitle: ({ commit, state }, payload) => {
      let title
      switch (typeof payload) {
        case 'object':
          title = payload
          if (title.text == null) title.text = ''
          break
        case 'undefined':
          title = { text: '' }
          break
        default:
          title = { text: String(payload) }
          break
      }
      commit('commitHeaderTitle', title)

      document.title = title.text === '' ? state.brandName
        : title.text.includes(state.brandName) ? title.text
          : `${title.text} \u2014 ${state.brandName}`
    },
    setHeaderTabs: (context, payload) => {
      if (payload && Array.isArray(payload.tabs) && payload.tabs.length > 0) {
        let tabs = []
        payload.tabs.forEach(tab => {
          if (tab.value && tab.to) {
            tabs.push(tab)
          } else {
            let tabData = {
              text: 'tabs.' + tab,
              value: tab,
              to: null
            }
            if (tab === 'scans') tabData.to = {name: 'clientScans', params: {seekerId: payload.seekerId}}
            if (tab === 'surveys') tabData.to = {name: 'clientSurveys', params: {seekerId: payload.seekerId}}
            if (tab === 'profile') tabData.to = {name: 'ViewSeeker', params: {seekerId: payload.seekerId}}
            if (tab === 'goals') tabData.to = {name: 'clientGoals', params: {seekerId: payload.seekerId}}
            if (tab === 'exercises') tabData.to = {name: 'clientExercises', params: {seekerId: payload.seekerId}}
            tabs.push(tabData)
          }
        })
        context.commit('commitHeaderTabs', tabs)
      } else {
        context.commit('setActiveTab', null)
        context.commit('commitHeaderTabs', null)
      }
    },
    setActiveTab: (context, payload) => {
      context.commit('setActiveTab', payload)
    },
    onUserRoleChanged: ({commit}) => {
      commit('scans/storeViewerSettings', null)
    },
    setUILocale: ({ commit }, locale) => {
      // Set user locale
      commit('user/setLocale', locale)
      // And clear localized data
      commit('surveys/clearMetadata')
    }
  },
  mutations: {
    setAppInitialized: (state, payload) => {
      payload = payload || {}
      state.appInitialized = true
      state.adBlockerWhiteList = payload.adBlockerWhiteList
      state.justSignedIn = (payload.justSignedIn === true)
    },
    commitHeaderTitle: (state, payload) => {
      state.headerTitle = payload
    },
    commitHeaderTabs: (state, payload) => {
      state.headerTabs = payload
    },
    addGlobalMessage: (state, message) => {
      state.globalMessage.push(message)
    },
    removeExpiredGlobalMessages: (state) => {
      const now = Date.now()
      state.globalMessage = state.globalMessage.filter(m => m.expires > now)
    },
    setActiveTab: (state, tab) => {
      state.activeTab = tab
    },
    setValidCountryCodes: (state, countryCodes) => {
      state.validCountryCodes = countryCodes
    },
    setWidgetLocales: (state, locales) => {
      state.widgetLocales = locales
    }
  },
  modules: {
    goals,
    exercises,
    surveys,
    scans,
    scans2d,
    user,
    seeker,
    specialist,
    measures,
    admin,
    chats,
    activities
  }
})
