<template>
  <v-row class="my-6">
    <v-col cols="12" class="mx-6">
      <div class="d-flex">
        <mpro-manage-mfa ref="manageMfa" button-class="mr-4 mb-4"/>
      </div>
    </v-col>
    <v-col cols="12" lg="6">
      <mpro-editable-container class="mx-6"
                               :save-callback="saveDetails"
                               @end-edit="onDetailsEndEdit">
        <template #title><h2>{{$t('account.details_title')}}</h2></template>
        <template #default="{readonly}">
          <v-form>
            <v-row justify="center">
              <v-col cols="12" md="4">
                <v-avatar class="MProCoral ma-auto d-block" size="100" rounded large>
                  <v-icon color="MProWhite" large>mdi-account</v-icon>
                </v-avatar>
                <v-divider class="mt-4 mb-3"/>
                <transition name="fade" mode="out-in">
                  <v-textarea v-if="!readonly" :label="$t('person_profile.about')" v-model.trim="details.about"
                      :readonly="readonly" :hide-details="readonly"
                      @input="$v.details.about.$touch()" @blur="$v.details.about.$touch()"
                      :error-messages="$validationErrors($v.details.about)"/>
                  <div v-if="readonly">
                    <span class="text-caption text--secondary">{{$t('person_profile.about')}}</span>
                    <p>{{details.about}}</p>
                  </div>
                </transition>
              </v-col>
              <v-col cols="12" md="8" transition="fade-transition">
                <transition name="fade" mode="out-in" tag="v-container">
                  <v-row v-if="!readonly" key="editing">
                    <v-col cols="6">
                      <v-text-field :label="$t('person_profile.name')" v-model.trim="details.name"
                        :readonly="readonly" :hide-details="readonly"
                        @input="$v.details.name.$touch()" @blur="$v.details.name.$touch()"
                        :error-messages="$validationErrors($v.details.name)"/>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field :label="$t('person_profile.organization')" v-model="specialist.OrganizationName"
                        readonly :hide-details="readonly"
                        :hint="$t('person_profile.organization_hint')"/>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field :label="$t('person_profile.phone')" v-model.trim="details.phone"
                        :readonly="readonly" :hide-details="readonly"
                        @input="$v.details.phone.$touch()" @blur="$v.details.phone.$touch()"
                        :error-messages="$validationErrors($v.details.phone)"/>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field :label="$t('person_profile.department')" v-model.trim="details.department"
                        :readonly="readonly" :hide-details="readonly"
                        @input="$v.details.department.$touch()" @blur="$v.details.department.$touch()"
                        :error-messages="$validationErrors($v.details.department)"/>
                    </v-col>
                    <v-col cols="6">
                      </v-col>
                    <v-col cols="6">
                      <v-text-field :label="$t('person_profile.title')" v-model.trim="details.title"
                        :readonly="readonly" :hide-details="readonly"
                        @input="$v.details.title.$touch()" @blur="$v.details.title.$touch()"
                        :error-messages="$validationErrors($v.details.title)"/>
                    </v-col>
                  </v-row>
                  <v-row v-else key="notediting">
                    <v-col cols="6">
                      <span class="text-caption text--secondary">{{$t('person_profile.name')}}</span>
                      <p>{{details.name}}</p>
                    </v-col>
                    <v-col cols="6">
                      <span class="text-caption text--secondary">{{$t('person_profile.organization')}}</span>
                      <p>{{specialist.OrganizationName}}</p>
                    </v-col>
                    <v-col cols="6">
                      <span class="text-caption text--secondary">{{$t('person_profile.phone')}}</span>
                      <p>{{details.phone}}</p>
                    </v-col>
                    <v-col cols="6">
                      <span class="text-caption text--secondary">{{$t('person_profile.department')}}</span>
                      <p>{{details.department}}</p><br>
                    </v-col>
                    <v-col cols="6">

                    </v-col>
                    <v-col cols="6">
                      <span class="text-caption text--secondary">{{$t('person_profile.title')}}</span>
                      <p>{{details.title}}</p><br>
                    </v-col>
                  </v-row>
                </transition>
              </v-col>
            </v-row>
          </v-form>
        </template>
      </mpro-editable-container>
    </v-col>
    <v-col cols="12" lg="6">
      <mpro-settings class="mx-6"/>
    </v-col>
    <mpro-confirmation-dialog v-model="showConnectConfirmation"
                              :title="$t('account.data-sharing.connect-confirmation-specialist.title')"
                              :message="$t('account.data-sharing.connect-confirmation-specialist.message', { organization: connectOrganization && connectOrganization.Name })"
                              :confirmText="$t('forms.continue')" :cancelText="$t('forms.cancel')"
                              :action="confirmConnect"/>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import {required} from 'vuelidate/lib/validators'
import EditableContainer from '@/components/elements/editable-container'
import ConfirmationDialog from '@/components/elements/confirmation-dialog.vue'
import PortalSettings from './portal-settings.vue'
import ManageMfa from './manage-mfa.vue'
import {MessageKind} from '@/helpers/enums'
import Utils from '@/helpers/utils'
import locationBuilder from '@/router/location-builder'

export default {
  components: {
    'mpro-editable-container': EditableContainer,
    'mpro-confirmation-dialog': ConfirmationDialog,
    'mpro-settings': PortalSettings,
    'mpro-manage-mfa': ManageMfa
  },

  data: () => ({
    details: {
      name: '',
      department: '',
      title: '',
      phone: '',
      about: ''
    },
    connectOrganization: null,
    showConnectConfirmation: false
  }),

  validations: {
    details: {
      name: {required},
      department: {},
      title: {},
      phone: {},
      about: {}
    }
  },

  computed: {
    ...mapState('user', ['specialist'])
  },

  methods: {
    async saveDetails () {
      this.$v.$touch()
      if (this.$v.$invalid) return false

      try {
        await this.updateSpecialistProfile(this.details)
        return true
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.Error,
          text: this.$errorMessage(e)
        })
        return false
      }
    },
    async onDetailsEndEdit (saved) {
      if (saved) {
        await this.ensureUserDataLoaded({force: true})
      }

      this.resetDetails()
      this.$nextTick(function () {
        this.$v.$reset()
      })
    },
    resetDetails () {
      this.details.name = this.specialist.DisplayName
      this.details.department = this.specialist.Department
      this.details.title = this.specialist.Title
      this.details.phone = this.specialist.Phone
      this.details.about = this.specialist.About
    },
    confirmConnect () {
      const redirectUri = this.$router.resolve({ name: 'LoginWithConnect', query: { code: this.connectOrganization.Code } }).href
      this.$msal.signOut(redirectUri)
    },
    ...mapActions(['showGlobalMessage', 'loadPublicOrganization']),
    ...mapActions('user', ['ensureUserDataLoaded', 'updateSpecialistProfile'])
  },

  async mounted () {
    this.resetDetails()

    const action = Utils.singleQueryValue(this.$route.query.action)
    if (action != null) {
      switch (action) {
        case 'connect-with-organization':
          const code = Utils.singleQueryValue(this.$route.query.code)
          this.connectOrganization = await this.loadPublicOrganization(code)
          if (this.connectOrganization != null) {
            this.showConnectConfirmation = true
          }
          break
        case 'enable-totp':
          await this.$refs.manageMfa.handleEnableTotp()
          break
        default:
          return
      }

      // Redirect to an actionless route after handling the action
      this.$router.replace(locationBuilder.myAccount())
    }
  }
}
</script>
