<template>
  <v-dialog max-width="600"
            v-model="show"
            :persistent="granting" scrollable
            @keydown.esc="granting || (show = false)">
    <template #activator="{on}">
      <v-btn v-on="on"
             class="ma-6 MProPurple" small>
        <v-icon small>mdi-plus</v-icon>
        <small>{{ $t('admin.grant_right') }}</small>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{$t('admin.grant_right')}}</v-card-title>
      <v-card-text>
        <v-form :disabled="granting">
          <v-combobox v-model.trim="right.specialist" :label="$t('admin.rights.specialist_email')"
                      type="email" :menu-props="{maxHeight: '75vh'}"
                      :items="specialistLogins" :filter="filterSpecialist"
                      :readonly="fixedSubject"
                      @input="$v.right.specialist.$touch()" @blur="$v.right.specialist.$touch()"
                      :error-messages="$validationErrors($v.right.specialist)">
            <template #item="{item}">
              <span>{{item}}</span>
              <span v-if="getSpecialistName(item)" class="grey--text ml-3">{{getSpecialistName(item)}}</span>
            </template>
          </v-combobox>
          <mpro-organization-select v-model="right.organization" :label="$t('admin.rights.organization')"
                                    :organizations="organizations"
                                    :readonly="fixedObject"
                                    @input="$v.right.organization.$touch()" @blur="$v.right.organization.$touch()"
                                    :error-messages="$validationErrors($v.right.organization)"/>
          <v-select v-model="right.library" :items="libraries"
                    :label="$t('admin.rights.library')"
                    :readonly="fixedObject"
                    @input="$v.right.library.$touch()" @blur="$v.right.library.$touch()"
                    :error-messages="$validationErrors($v.right.library)"/>
          <v-select v-model="right.right" :label="$t('admin.rights.right')"
                    :items="rights" item-text="value"
                    :menu-props="{maxWidth: '600px', maxHeight: '75vh'}"
                    @input="$v.right.right.$touch()" @blur="$v.right.right.$touch()"
                    :error-messages="$validationErrors($v.right.right)">
            <template #item="{item}">
              <div class="d-flex flex-column">
                <span>{{item.value}}</span>
                <small class="grey--text ws-pre-line">{{item.description}}</small>
              </div>
            </template>
          </v-select>
          <v-textarea v-model.trim="right.description" :label="$t('admin.rights.description')"/>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn class="MProPurple" @click="onGrantClick"
               :loading="granting" :disabled="granting">
          {{$t('admin.grant_right')}}
        </v-btn>
        <v-btn text @click="show = false" :disabled="granting">{{$t('forms.cancel')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OrganizationSelect from '@/components/elements/organization-select'
import {MessageKind} from '@/helpers/enums'
import { mapState, mapActions } from 'vuex'
import {required, email} from 'vuelidate/lib/validators'

const Rights = {
  ADMIN: 'Admin',
  AUDIT: 'Audit',
  PORTAL: 'Portal',
  SCAN: 'Scan',
  LAB: 'Lab'
}

export default {
  components: {
    'mpro-organization-select': OrganizationSelect
  },

  props: {
    specialistEmail: String,
    organizationCode: String,
    libraryId: String
  },

  data: () => ({
    show: false,
    granting: false,
    libraries: [],
    right: {
      specialist: '',
      organization: '',
      library: '',
      right: '',
      description: ''
    }
  }),

  validations: {
    right: {
      specialist: {required, email},
      organization: {required},
      library: {},
      right: {required},
      description: {}
    }
  },

  computed: {
    rights: function () {
      const result = [Rights.PORTAL, Rights.SCAN, Rights.LAB]
      if (this.right.library === '') {
        result.unshift(Rights.ADMIN, Rights.AUDIT)
      }
      return result
        .map(r => {
          const disabled = !this.organizationOffers3DService &&
            [Rights.SCAN, Rights.LAB].includes(r)
          let description = this.$t(`admin.right_descriptions.${r}`).toString()
          if (disabled) {
            description += '\n' + this.$t('admin.right_descriptions.not-available-without-3d-service')
          }
          return { value: r, description, disabled }
        })
    },
    specialistLogins: function () {
      const result = (this.specialists || [])
        // Not using use object items to keep the value of combobox a simple string
        .map(s => s.Login)
      result.sort((s1, s2) => s1.localeCompare(s2))
      return result
    },
    organizationOffers3DService: function () {
      const org = this.organizations.find(o => o.Code === this.right.organization)
      return org?.Offers3DService !== false
    },
    fixedSubject: function () {
      return this.specialistEmail != null
    },
    fixedObject: function () {
      return this.organizationCode != null
    },
    allLibraries: function () {
      return { value: '', text: this.$t('admin.all_libraries') }
    },
    ...mapState('admin', ['organizations', 'specialists'])
  },

  watch: {
    organizationCode: function () {
      this.right.organization = this.organizationCode
    },
    libraryId: function () {
      this.right.library = this.libraryId
    },
    'right.organization': async function () {
      this.libraries = [this.allLibraries]
      if (this.right.organization) {
        const organization = await this.loadOrganization(this.right.organization)
        if (organization != null) {
          const libraries = organization.libraries
            .map(l => ({ value: l.Id, text: l.Name }))
          libraries.sort((l1, l2) => l1.text.localeCompare(l2.text))
          this.libraries = this.libraries.concat(libraries)
        }
      }
    },
    rights: function () {
      if (!this.rights.some(r => r.value === this.right.right)) {
        this.right.right = ''
      }
    },
    show: function () {
      this.reset()
    }
  },

  methods: {
    async reset () {
      this.right.specialist = this.specialistEmail
      this.right.organization = this.organizationCode
      this.right.library = this.libraryId || ''
      this.right.right = ''
      this.right.description = ''
      await this.$nextTick()
      this.$v.$reset()
    },
    async onGrantClick () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.granting = true
        try {
          await this.grantRight(this.right)
        } catch (e) {
          this.showGlobalMessage({
            kind: MessageKind.ERROR,
            text: this.$errorMessage(e)
          })
          return
        } finally {
          this.granting = false
        }

        this.$emit('grant-right')
        this.show = false
        this.showGlobalMessage({
          kind: MessageKind.SUCCESS,
          text: this.$t('admin.right_granted')
        })
      }
    },
    getSpecialistName (login) {
      const s = this.specialists.find(s => s.Login === login)
      return s && s.DisplayName
    },
    filterSpecialist (login, query) {
      if (query == null || query === '') return true
      if (login.toLocaleLowerCase().includes(query)) return true

      const name = this.getSpecialistName(login)
      return name && name.toLocaleLowerCase().includes(query)
    },
    ...mapActions(['showGlobalMessage']),
    ...mapActions('admin', ['ensureOrganizationsLoaded', 'loadOrganization', 'ensureSpecialistsLoaded', 'grantRight'])
  },

  created () {
    this.libraries = [this.allLibraries]
    this.reset()
    this.ensureOrganizationsLoaded()
    this.ensureSpecialistsLoaded()
  }
}
</script>
