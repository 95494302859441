<template>
  <div class="pa-5 pb-0 d-flex flex-column" style="max-height: 100%">
    <div id="scan-detail-container">
      <p class="pb-1">{{ $t('scans.details') }}</p>
      <p class="pb-1" v-if="scan">{{$formatDateTime(scan.CapturedAt)}}</p>
      <div v-if="!presentMode" class="scan-information-actions d-flex flex-row justify-space-between mb-4">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="MProMainAlt"
                :disabled="!summaryReportExists"
                fab
                v-bind="attrs"
                v-on="on"
                @click="presentSummaryReport"
                :loading="loadingSummaryReport">
              <v-icon>mdi-file-chart-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('scans.buttons.download-report') }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="isSpecialist">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="MProMainAlt"
                :disabled="!biomechReportExists"
                fab
                v-bind="attrs"
                v-on="on"
                @click="presentBiomechReport"
                :loading="loadingBiomechReport">
              <v-icon>mdi-chart-bell-curve-cumulative</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('scans.buttons.download-report-biomech') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="MProMainAlt"
                :disabled="!isDepthScan"
                fab
                v-bind="attrs"
                v-on="on"
                @click="showDetails = !showDetails">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('scans.buttons.show-scan-details') }}</span>
        </v-tooltip>
      </div>
      <v-dialog
        v-model="showDetails"
        width="1500"
        >
        <v-card>
          <v-card-title>
            <span>
              {{ $t('scans.scanDetails.scan-information') }}
            </span>
            <v-spacer/>
            <v-btn v-if="!presentMode" color="MProMainAlt" icon right @click="showDetails = !showDetails">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="scaninformation-actions d-flex flex-column justify-start">
            <div class="scaninformation-actions d-flex flex-row justify-start mb-4">
              <div class="flex-grow-1 pa-2 px-5">
                <p class="font-weight-bold h5">{{ $t('scans.scanDetails.general-information') }}</p>
                <div v-for="(value, detail) in generalDetails" :key="detail" class="d-flex flex-row">
                  <div style="flex-basis:50%; width: 100%">{{ $t(`scans.scanDetails.${detail}`) }}<span>: </span></div>
                  <div>{{ value }}</div>
                </div>
              </div>
              <div class="flex-grow-1 pa-2 px-5">
                <p class="font-weight-bold h5">{{ $t('scans.scanDetails.technical-information') }}</p>
                <div v-for="(value, Techdetail) in technicalDetails" :key="Techdetail" class="d-flex flex-row ">
                  <div style="flex-basis:50%; width: 100%">{{ $t(`scans.techDetails.${Techdetail}`) }}<span>: </span></div>
                  <div>{{ value }}</div>
                </div>
              </div>
            </div>
            <div class="scaninformation-actions d-flex flex-row justify-space-between flex-wrap">
              <div v-for="(movementInfo, movementKey) in movementDetails" :key="movementKey" style="flex-basis:25%;" class="flex-wrap flex-grow-1 flex-shrink-0 pa-2 px-5">
                <p class="font-weight-bold h5 mb-1">{{ $t(`scans.movements.${movementKey}`) }}</p>
                <div v-for="(value, detail) in movementInfo" :key="detail" class="d-flex flex-row">
                  <div style="flex-basis:50%; width: 100%">{{ $t(`scans.scanDetails.${detail}`) }}<span>: </span></div>
                  <div>{{ value }}</div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-divider class="pa-1"></v-divider>
    </div>
    <div id="comments" ref="comments" class="max-text-width flex-shrink-1">
      <v-skeleton-loader v-if="comments.length === 0 && !commentsLoadedAt" type="list-item-two-line@2"/>
      <div v-for="(comment, i) in comments" :key="comment.Id" :id="comment.Id">
          <b class="title" v-if="i === 0 || getCommentAuthorId(comments[i]) !== getCommentAuthorId(comments[i-1])">{{getCommentAuthorName(comment)}}</b>
          <p class="my-0 mt-2" v-if="i === 0 || ($formatDate(comments[i].CreatedAt, false) !== $formatDate(comments[i-1].CreatedAt, false)) || getCommentAuthorId(comments[i]) !== getCommentAuthorId(comments[i-1])"><span>{{$formatDate(comment.CreatedAt, false)}}</span></p>
          <p class="my-0"><mpro-read-more :text="comment.Text" :charCount="charCount" linkify /></p>
          <v-btn v-if="isSeeker && (!comments[i+1] || getCommentAuthorId(comments[i]) !== getCommentAuthorId(comments[i+1]))"
                 :to="getLocationToAskQuestion(comment)"
                 class="MProBlue my-3">
            {{ $t('general.buttons.ask-question') }}
          </v-btn>
      </div>
      <div v-if="comments.length === 0 && commentsLoadedAt">
          <v-alert
              dense
              text
              type="info"
            >
            <span>{{ $t('scans.no-comments') }}</span>
            </v-alert>
        </div>
    </div>
    <v-form v-if="isSpecialist" ref="postComment" class="mt-3">
      <v-textarea v-model="message" ref="commentArea" style="color: blue;"
                  id="comment-input"
                  :rules="messageRules"
                  @input="maxHeight()"
                  solo
                  :placeholder="$t('scans.comment-placeholder')"
                  append-outer-icon="mdi-send"
                  rows="1"
                  auto-grow
                  @click:append-outer="sureDialog=!sureDialog"/>
    </v-form>
    <v-divider class="pa-1"></v-divider>
    <v-dialog v-model="sureDialog" width="400"
              :persistent="postingComment">
      <v-card class="MPro-bg">
        <v-card-title class="flex-nowrap">
          <span>{{$t('scans.comment-sure')}}</span>
          <v-btn v-if="!postingComment" class="align-self-start" icon @click="sureDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ $t('scans.comment-sure-note')}}
        </v-card-text>
        <v-card-actions>
          <v-btn class="mr-3" @click="sureDialog = false"
                 :disabled="postingComment">
            {{$t('forms.no')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="mr-3 MProCoral" @click="postComment()"
                 :disabled="postingComment" :loading="postingComment">
            {{$t('forms.yes')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import readMore from './../elements/read-more'
import { ScanType, MovementKind, MessageKind } from '../../helpers/enums'

const ReportKinds = {
  SUMMARY: 'Summary',
  BIOMECH: 'Biomech'
}

export default {
  props: {
    presentMode: {type: Boolean, required: false, default: () => false}
  },
  components: {
    'mpro-read-more': readMore
  },
  data: () => {
    return {
      message: '',
      comments: [],
      commentsLoadedAt: null,
      messageRules: [
        m => m == null || m.trim() === '' ? 'Nothing to post' : true
      ],
      maxRows: 5,
      charCount: 40,
      showDetails: false,
      generalDetails: {},
      technicalDetails: {},
      movementDetails: {},
      sureDialog: false,
      postingComment: false,
      loadingSummaryReport: false,
      loadingBiomechReport: false
    }
  },
  methods: {
    ...mapActions('specialist', ['ensureSpecialistScansLoaded']),
    ...mapMutations('specialist', ['markScanViewed', 'updateLastCommentedScanAt']),
    ...mapActions('seeker', ['ensureScansLoaded']),
    ...mapActions('scans', ['getOrLoadScanData', 'getOrLoadMovementTrackingPoints']),
    ...mapActions('user', ['updateUserSetting', 'getUserSetting']),
    ...mapActions(['showGlobalMessage']),
    ...mapActions('scans2d', ['loadScanInfo']),
    ...mapActions({
      downloadPdfReport3D: 'scans/downloadPdfReport',
      downloadPdfReport2D: 'scans2d/downloadPdfReport',
      getScanComments3D: 'scans/getScanComments',
      getScanComments2D: 'scans2d/getScanComments',
      postScanComment3D: 'scans/postScanComment',
      postScanComment2D: 'scans2d/postScanComment'
    }),
    maxHeight () {
      let element = document.getElementById('comment-input')
      if (element) {
        element.style.maxHeight = `${this.maxRows * this.$refs.commentArea.rowHeight}px`
        element.scrollTop = element.scrollHeight
        element.style.overflowY = 'scroll' // for some reason the css doesn't work...
        // this.onResize()
      }
    },
    isOwnComment (comment) {
      return comment.Author === this.getUserEmail
    },
    getCommentAuthorName (comment) {
      return this.isOwnComment(comment) ? this.$t('scans.comment-own') : (comment.AuthorName || comment.Author || this.$t('specialist.anonymous'))
    },
    getCommentAuthorId (comment) {
      return comment.Author || comment.AuthorMoniker
    },
    getLocationToAskQuestion (comment) {
      return {
        name: 'Chat',
        params: { userUuid: this.getChatUuid(comment.Author) },
        query: { type: 'ScanComment', objectId: `${this.scanId}|${comment.Id}` }
      }
    },
    async onScanIdChanged () {
      if (this.scanId) {
        if (this.isSpecialist) {
          let res = await this.getUserSetting('specialist.last_viewed_scans')
          let value = null
          if (res.data.length <= 0) {
            this.updateUserSetting({setting: 'specialist.last_viewed_scans', value: this.scanId})
          } else {
            value = res.data[0].Value.split(';')
            const index = value.indexOf(this.scanId)
            if (index > -1) value.splice(index, 1)
            value.push(this.scanId)
            if (value.length > 7) value.splice(0, value.length - 7)
            value = value.join(';')
            this.updateUserSetting({setting: 'specialist.last_viewed_scans', value: value})
          }
          value = await this.getUserSetting('specialist.last_viewed_scans')
        }
        this.commentsLoadedAt = null
        this.getComments()
        if (this.scan && this.isDepthScan) this.updateScanDetails()
      }
    },
    async updateScanDetails () {
      let scanInformation = await this.getOrLoadScanData({scanId: this.scanId})
      this.generalDetails = {
        organisation: this.scan.OwnerName,
        library: this.scan.LibraryName,
        createdAt: this.$formatDateTime(this.scan.CapturedAt),
        scanVersion: this.scan.SoftwareVersion,
        discomfortAreas: scanInformation.person.discomfort_areas.length > 0 ? scanInformation.person.discomfort_areas.join(', ') : 'None',
        fallsReported: scanInformation.person.has_falls ? 'Yes' : 'No',
        bodyHeight: this.$formatLength(scanInformation.person.evaluated_height * 100, 'cm', this.isMetric)
      }
      let trackingData = await this.getOrLoadMovementTrackingPoints({
        scanId: this.scanId,
        movement: MovementKind.STAND_STILL
      })
      let midAnkleIndex = trackingData.TrackingPoints.indexOf('AnklesMiddleAnatomic')
      let leftAnkleIndex = trackingData.TrackingPoints.indexOf('LeftAnkleAnatomic')
      let rightAnkleIndex = trackingData.TrackingPoints.indexOf('RightAnkleAnatomic')
      let sensorAlignment = (trackingData.Frames[0].PointsPositions[leftAnkleIndex].X + trackingData.Frames[0].PointsPositions[rightAnkleIndex].X) / 2
      this.technicalDetails = {
        sensorPositionFloor: this.$formatLength(Math.abs(scanInformation.geometry.floor_plane.d) * 100, 'cm', this.isMetric),
        sensorRelativeBody: this.$formatPercent(Math.abs(scanInformation.geometry.floor_plane.d), scanInformation.person.evaluated_height),
        sensorDistanceBody: this.$formatLength(Math.abs(trackingData.Frames[0].PointsPositions[midAnkleIndex].Z * 100), 'cm', this.isMetric),
        sensorAlignmentBody: this.$t('scans.techDetails.sensorOffset.' + (sensorAlignment === 0 ? 'center' : sensorAlignment > 0 ? 'right' : 'left'), {
          offset: this.$formatLength(Math.abs(sensorAlignment) * 100, 'cm', this.isMetric)
        })
      }
      Object.keys(scanInformation.movements).forEach(key => {
        let movement = scanInformation.movements[key]
        this.movementDetails[key] = {
          actualFPS: this.$roundValue(movement.actual_fps, 1),
          modeFinalRecording: movement.mode,
          repeats: movement.repeat_count - 1,
          failureReasons: movement.failure_reasons.length > 0 ? movement.failure_reasons : 'None'
        }
      })
    },
    reportExists (kind) {
      const info = this.isDepthScan ? { existingReports: ['Summary', 'Biomech'] } : this.scanInfo || { existingReports: [] }
      return info.existingReports.some(k => k.toLowerCase() === kind.toLowerCase())
    },
    async presentSummaryReport () {
      this.loadingSummaryReport = true
      const fileNameResource = this.isDepthScan
        ? 'scans.summary-report-file-name'
        : 'scans2d.summary-report-file-name'
      try {
        await this.presentPdfReport(ReportKinds.SUMMARY, fileNameResource)
      } finally {
        this.loadingSummaryReport = false
      }
    },
    async presentBiomechReport () {
      this.loadingBiomechReport = true
      const fileNameResource = this.isDepthScan
        ? 'scans.biomech-report-file-name'
        : 'scans2d.biomech-report-file-name'
      try {
        await this.presentPdfReport(ReportKinds.BIOMECH, fileNameResource)
      } finally {
        this.loadingBiomechReport = false
      }
    },
    async presentPdfReport (kind, fileNameResource) {
      let pdfData = null
      if (this.isDepthScan) {
        pdfData = await this.downloadPdfReport3D({
          scanId: this.scanId,
          reportType: kind,
          isMetric: this.isMetric
        })
      } else {
        pdfData = await this.downloadPdfReport2D({
          scanId: this.scanId,
          kind,
          isMetric: this.isMetric
        })
      }
      if (pdfData != null) {
        const blob = new Blob([pdfData], { type: 'application/pdf' })
        const url = URL.createObjectURL(blob)
        var link = document.createElement('a')
        link.href = url
        const pdfDate = this.$moment.parseZone(this.scan.CapturedAt).format('YYYY-MM-DD')
        const seekerName = this.scan.SeekerName
        link.download = this.$t(fileNameResource, {name: seekerName, date: pdfDate})
        link.click()

        if (this.isSpecialist) {
          this.markScanViewed(this.scanId)
        }
      }
    },
    async postComment () {
      if (!this.$refs.postComment.validate()) return

      const payload = {
        scanId: this.scanId,
        message: this.message
      }

      this.postingComment = true
      try {
        if (this.isDepthScan) {
          await this.postScanComment3D(payload)
        } else {
          await this.postScanComment2D(payload)
        }
      } catch (e) {
        this.showGlobalMessage({
          text: this.$errorMessage(e),
          kind: MessageKind.ERROR
        })
        return
      } finally {
        this.postingComment = false
      }

      this.sureDialog = false

      this.$refs.postComment.reset()
      this.commentsLoadedAt = null
      this.getComments()
      this.showGlobalMessage({
        text: this.$t('scans.messages.comment-posted-success'),
        kind: 'success'
      })

      this.updateLastCommentedScanAt(this.scan.SeekerId)
    },
    async getComments () {
      if (this.commentsLoadedAt && (Date.now() - this.commentsLoadedAt < process.env.VUE_APP_COMMENTS_LIFETIME)) {
        return
      }
      let response = null
      if (this.isDepthScan) {
        response = await this.getScanComments3D({
          scanId: this.scanId
        })
      } else {
        response = await this.getScanComments2D({
          scanId: this.scanId
        })
      }
      this.comments = response
      this.commentsLoadedAt = Date.now()
      this.$nextTick(() => {
        this.onResize()
      })
    },
    onResize () {
      let element = document.getElementById('comments')
      if (element) {
        element.scrollTop = element.scrollHeight
      }
      this.$nextTick(() => {
        this.commentLength()
      })
    },
    commentLength: function () {
      if (document.getElementById('comments')) {
        let maxWidth = document.getElementById('comments').clientWidth
        if (maxWidth > 0) this.charCount = Math.round((maxWidth - 80) / 8)
      }
    }
  },
  computed: {
    ...mapGetters(['isSeeker', 'isSpecialist']),
    ...mapGetters({
      getSeekerScanById: 'seeker/getScanById',
      getSpecialistScanById: 'specialist/getScanById'
    }),
    ...mapGetters('user', ['isMetric', 'getUserEmail']),
    ...mapGetters('scans', ['getScanData']),
    ...mapGetters('chats', ['getChatUuid']),
    scanId: function () {
      return this.$route.params.scanId
    },
    scan: function () {
      if (this.isSeeker) {
        return this.getSeekerScanById(this.scanId)
      } else if (this.isSpecialist) {
        return this.getSpecialistScanById(this.scanId)
      } else {
        return null
      }
    },
    isDepthScan: function () {
      return this.scan == null ? undefined : this.scan.ScanType === ScanType.DEPTH
    },
    commentHeight: function () {
      return document.getElementById('scan-detail-container') ? document.getElementById('scan-detail-container').clientHeight : '115px'
    },
    summaryReportExists: function () {
      return this.reportExists(ReportKinds.SUMMARY)
    },
    biomechReportExists: function () {
      return this.reportExists(ReportKinds.BIOMECH)
    }
  },
  asyncComputed: {
    scanInfo: function () {
      return this.scan != null && !this.isDepthScan ? this.loadScanInfo(this.scan.Id) : null
    }
  },
  created () {
    if (this.isSeeker) {
      this.ensureScansLoaded()
    } else if (this.isSpecialist) {
      this.ensureSpecialistScansLoaded()
    }
  },
  mounted () {
    this.onScanIdChanged()
    window.addEventListener('resize', this.onResize)
    this.$nextTick(() => {
      this.onResize()
    })
  },
  watch: {
    scanId () {
      this.onScanIdChanged()
      this.onResize()
      this.message = ''
    }
    // showMovement () {
    //   this.loadMovementData()
    // }
  }
}
</script>

<style scoped>
  #comments {
    overflow-y: auto;
  }
</style>
