<template>
  <div>

    <div class="d-flex">
      <v-btn class="ma-6" small @click="deleting = true">
        <v-icon small>mdi-delete</v-icon>
        <small>{{ $t('admin.delete_library') }}</small>
      </v-btn>
    </div>

    <v-skeleton-loader type="card-heading, paragraph" :loading="loading"
                      class="ma-6 mt-0 min-card-width">
      <mpro-editable-card :save-callback="save"
                          @end-edit="onEndEdit">
        <template #title>
          {{$t('admin.details_card')}}
        </template>
        <template #default="{readonly}">
          <mpro-library-details ref="details" :library="library" :readonly="readonly"/>
        </template>
      </mpro-editable-card>
    </v-skeleton-loader>

    <mpro-delete-dialog v-model="deleting"
                        :title="$t('admin.delete_library')"
                        :message="deleteMessage"
                        @confirm="onDeleteConfirm" />

  </div>
</template>

<script>
import LibraryDetails from './details'
import EditableCard from '@/components/elements/editable-card'
import DeleteDialog from '@/components/elements/delete-dialog'
import {MessageKind} from '@/helpers/enums'
import { mapActions } from 'vuex'

export default {
  components: {
    'mpro-library-details': LibraryDetails,
    'mpro-editable-card': EditableCard,
    'mpro-delete-dialog': DeleteDialog
  },

  props: {
    library: {type: Object, default: null}
  },

  data: () => ({
    deleting: false
  }),

  computed: {
    loading: function () {
      return this.library == null
    },
    deleteMessage: function () {
      return this.library
        ? this.$t('admin.delete_library_warning', {name: this.library.Name})
        : null
    }
  },

  methods: {
    async save () {
      if (!this.$refs.details.validate()) return false

      const {owner: _, ...data} = this.$refs.details.data
      data.id = this.library.Id
      try {
        await this.updateLibrary(data)
        return true
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e)
        })
        return false
      }
    },
    onEndEdit (saved) {
      if (saved) {
        this.$emit('update')
      } else {
        this.$refs.details.reset()
      }
    },
    async onDeleteConfirm () {
      try {
        await this.deleteLibrary(this.library.Id)
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e)
        })
        return
      } finally {
        this.deleting = false
      }

      this.showGlobalMessage({
        kind: MessageKind.SUCCESS,
        text: this.$t('forms.save_changes_success')
      })
      this.$router.push({
        name: 'admin:OrganizationTab',
        params: {
          code: this.library.OwnerCode,
          activeTab: 'libraries'
        }})
    },
    ...mapActions(['showGlobalMessage']),
    ...mapActions('admin', ['updateLibrary', 'deleteLibrary'])
  }
}
</script>
