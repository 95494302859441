/** Adds $nodeEnv, $appFeatures properties to Vue instances */
export default {
  install: function (Vue) {
    Vue.prototype.$nodeEnv = {
      value: process.env.VUE_APP_NODE_ENV,
      isDevelopment: (process.env.VUE_APP_NODE_ENV ?? 'development') === 'development',
      isProduction: process.env.VUE_APP_NODE_ENV === 'production'
    }
    Vue.prototype.$appFeatures = (process.env.VUE_APP_FEATURES ?? '').split(',').map(s => s.trim()).filter(s => s.length > 0)
    Vue.prototype.$appFeatureEnabled = function (feature) {
      return Vue.prototype.$appFeatures.includes(feature)
    }
  }
}
