<template>
  <v-card>
    <v-card-title>{{$t('account.set_pin_code.title')}}</v-card-title>
    <v-card-text>
      <v-form>
        <p class="ws-pre-line">{{$t('account.set_pin_code.instruction')}}</p>
        <v-radio-group v-model="usePinCode">
          <v-radio :value="true" :label="$t('account.set_pin_code.use')"/>
          <v-text-field v-model="pinCode"
                        :type="pinCodeInputType"
                        :disabled="!usePinCode"
                        :label="$t('account.set_pin_code.pin_code')"
                        :hint="$t('account.set_pin_code.pin_code_hint')"
                        class="ml-8"
                        @input="$v.pinCode.$touch()" @blur="onPinCodeBlur"
                        :error-messages="$validationErrors($v.pinCode, {format: $t('account.set_pin_code.pin_code_hint')})"
                        :append-icon="showPinCode ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="showPinCode = !showPinCode"/>
          <v-radio :value="false" :label="$t('account.set_pin_code.dont_use')"/>
        </v-radio-group>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="processing"
             @click="close">
        {{$t('forms.cancel')}}
      </v-btn>
      <v-spacer/>
      <v-btn :disabled="processing" :loading="processing"
             class="MProBlue"
             @click="onOkClick">
        {{$t('forms.ok')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import {requiredIf, helpers} from 'vuelidate/lib/validators'
import {MessageKind} from '@/helpers/enums'

export default {
  data: () => ({
    usePinCode: true,
    showPinCode: false,
    pinCode: '',
    processing: false
  }),

  computed: {
    pinCodeInputType: function () {
      return this.pinCode && !this.showPinCode ? 'password' : 'text'
    },
    actualPinCode: function () {
      return this.usePinCode ? this.pinCode : null
    }
  },

  validations: {
    pinCode: {
      required: requiredIf('usePinCode'),
      format: (value, vm) => !vm.usePinCode || !helpers.req(value) || /^\d{4}$/.test(value)
    }
  },

  watch: {
    usePinCode: function () {
      if (!this.usePinCode) {
        this.showPinCode = false
      }
    }
  },

  methods: {
    async onOkClick () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.processing = true
        try {
          await this.setSeekerPinCode({pinCode: this.actualPinCode})
          this.close()
          const message = this.$t(this.actualPinCode ? 'account.set_pin_code.success_set' : 'account.set_pin_code.success_remove')
          this.showGlobalMessage({kind: MessageKind.SUCCESS, text: message})
        } catch (e) {
          const message = this.$errorMessage(e)
          this.showGlobalMessage({kind: MessageKind.ERROR, text: message})
        } finally {
          this.processing = false
        }
      }
    },
    close () {
      this.$emit('close')
    },
    onPinCodeBlur () {
      this.showPinCode = false
      this.$v.pinCode.$touch()
    },
    ...mapActions('user', ['setSeekerPinCode']),
    ...mapActions(['showGlobalMessage'])
  }
}
</script>
