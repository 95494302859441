<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8">
        <v-skeleton-loader v-if="survey == null" class="ma-6" type="article"/>
        <div v-else class="ma-6">
          <p>
            {{$t('surveys.view.answered_on', {date: $formatDateTime(survey.AnsweredAt)})}}
            <router-link v-if="survey.ScanId" class="float-right"
                        :to="{name: 'Scan', params: {scanId: survey.ScanId}}">
              {{$t('surveys.view.view_scan')}}
            </router-link>
          </p>
          <v-alert v-if="isSeeker && cautionNotes != null" dense text type="error">
            <span class="ws-pre-line">{{ cautionNotes }}</span>
          </v-alert>
          <mpro-sf36 v-if="survey.QuestionnaireCode.toUpperCase() === 'SF-36'"
                     :survey="survey"/>
          <mpro-survey-layout v-if="metadata" :metadata="metadata">
            <template #default="{question}">
              <mpro-answer :question="question"
                           :answer="getAnswerByCode(question.Code)"
                           :user-gender="seekerGender"/>
            </template>
            <template #grid="{questions}">
              <mpro-answer-grid :questions="questions"
                                :answers="questions.map(q => getAnswerByCode(q.Code))"
                                readonly/>
            </template>
          </mpro-survey-layout>
        </div>
        <center>
          <v-btn large :to="allSurveysLocation">
            {{$t('surveys.buttons.back-to-survey-list')}}
          </v-btn>
        </center>
        <mpro-header-handler tabName="surveys" :seeker="seeker"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { SurveyAssignmentNotesKind } from '@/helpers/enums'
import Lo from 'lodash'
import moment from 'moment'
import SurveyLayout from './survey-layout'
import Answer from './answer'
import AnswerGrid from './qa-grid'
import SF36Graph from './visuals/SF-36'
import HeaderHandler from '../specialist/header-handler'

export default {
  components: {
    'mpro-survey-layout': SurveyLayout,
    'mpro-answer': Answer,
    'mpro-answer-grid': AnswerGrid,
    'mpro-sf36': SF36Graph,
    'mpro-header-handler': HeaderHandler
  },

  data: () => ({
    returnToAssignedSurveys: false
  }),

  computed: {
    surveyId: function () {
      return this.$route.params.surveyId
    },
    cautionNotes: function () {
      const assignmentNotes = this.survey?.AssignmentNotes
      if (assignmentNotes == null) return null

      return Lo(assignmentNotes)
        .filter(notes => notes.Kind === SurveyAssignmentNotesKind.CAUTION)
        .orderBy(notes => moment(notes.AssignedAt).unix(), 'desc')
        .first()
        ?.Text
    },
    seeker: function () {
      if (this.isSpecialist) {
        return this.getSeekerById(this.survey?.SeekerId)
      } else if (this.isSeeker) {
        return this.activeSubordinate ?? this.seekerFromUser
      } else {
        return null
      }
    },
    seekerGender: function () {
      return this.seeker?.Gender
    },
    allSurveysLocation: function () {
      if (this.survey == null) return undefined

      if (this.isSeeker) {
        return { name: 'MySurveys' }
      } else if (this.returnToAssignedSurveys) {
        return { name: 'AssignedSurveys' }
      } else {
        return {
          name: 'clientSurveys',
          params: { seekerId: this.survey.SeekerId }
        }
      }
    },
    ...mapState('user', {
      seekerFromUser: state => state.seeker
    }),
    ...mapGetters(['isSpecialist', 'isSeeker']),
    ...mapGetters('specialist', ['getSeekerById']),
    ...mapGetters('seeker', ['activeSubordinate'])
  },

  asyncComputed: {
    survey: function () {
      return this.surveyId != null
        ? this.getOrLoadSurveyById(this.surveyId)
        : Promise.resolve(null)
    },
    metadata: function () {
      return this.survey && this.survey.QuestionnaireCode
        ? this.getOrLoadMetadata(this.survey.QuestionnaireCode)
        : Promise.resolve(null)
    }
  },

  methods: {
    getAnswerByCode (code) {
      if (this.survey) {
        const answer = this.survey.answers.find(a => a.QuestionCode.toLowerCase() === code.toLowerCase())
        if (answer) return answer.Answer
      }
      return null
    },
    ...mapActions('surveys', ['getOrLoadSurveyById', 'getOrLoadMetadata'])
  },

  beforeRouteEnter (_, from, next) {
    next(vm => { vm.returnToAssignedSurveys = from.name === 'AssignedSurveys' })
  }
}
</script>
