<template>
  <v-form>
    <p>{{$t('signup.verify_instruction')}}</p>
    <v-text-field v-if="canEnterCode"
                  solo :label="$t('signup.verification_code')"
                  v-model.trim="code"
                  required :error-messages="$validationErrors($v.code)"
                  :hint="codeHint"
                  :persistent-hint="showAttempts"
                  :readonly="processing">
      <template #append-outer>
        <v-btn text small color="MProYellow"
               @click="onSendNewCodeClick"
               :disabled="sendingCode" :loading="sendingCode">
          {{$t('signup.send_new_code')}}
        </v-btn>
      </template>
    </v-text-field>
    <div v-if="!canEnterCode" class="my-6 d-flex justify-center">
      <v-btn color="MProYellow"
              @click="onSendNewCodeClick"
              :disabled="sendingCode" :loading="sendingCode">
        {{$t('signup.send_new_code')}}
      </v-btn>
    </div>
    <p class="instruction">
      <span v-html="$t('signup.verify_bottom_instruction_spam_html')"/>
      <br/>
      <span v-html="$t('signup.verify_bottom_instruction_repeat')"/>
    </p>
  </v-form>
</template>

<script>
import {required} from 'vuelidate/lib/validators'

export default {
  props: {
    processing: {type: Boolean, default: false},
    sendingCode: {type: Boolean, default: false},
    attemptsLeft: {type: Number, default: 0},
    showAttempts: {type: Boolean, default: false}
  },

  data: () => ({
    code: ''
  }),

  validations: {
    code: {required}
  },

  computed: {
    codeHint: function () {
      return this.showAttempts
        ? this.$t('signup.verification_attempts_left', { attempts: this.attemptsLeft })
        : null
    },
    canEnterCode: function () {
      return this.attemptsLeft > 0
    }
  },

  methods: {
    getValidated () {
      this.$v.$touch()
      return this.$v.$invalid ? null : this.code
    },
    onSendNewCodeClick () {
      if (!this.sendingCode) {
        this.code = ''
        this.$v.$reset()
        this.$emit('send-new-code')
      }
    }
  }
}
</script>
