<template>
  <div>
    <v-btn
      v-if="updatingGoal"
      @click="dialog = true"
      class="mt-3 MProYellow"
      light>
        {{ $t('goals.edit-goal.buttons.edit-goal') }}
    </v-btn>
    <v-btn
      v-else
      @click="dialog = true"
      class="mt-3 MProYellow"
      light
      large>
        <v-icon>mdi-plus</v-icon>
        {{ $t('goals.edit-goal.buttons.add-goal') }}
    </v-btn>
    <v-dialog scrollable v-model="dialog" width="560" light content-class="theme--light"
              :persistent="processing">
        <v-card light class="theme--light">
          <v-card-title>
            <span v-if="updatingGoal">{{ $t('goals.edit-goal.edit-goal-title') }}</span>
            <span v-else>{{ $t('goals.edit-goal.new-goal-title') }}</span>
            <v-spacer/>
            <v-btn v-if="!processing" @click="dialog = false" icon>
              <v-icon>$close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-2">
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-text-field :label="$t('goals.edit-goal.fields.name')" :placeholder="$t('goals.edit-goal.fields.name-placeholder')"
                :rules="[v => !!v || $t('general.forms.required-field')]"
                type="text" v-model="goal.Goal" outlined
                class="mr-3 text-right"/>
              <v-textarea :label="$t('goals.edit-goal.fields.description')" :placeholder="$t('goals.edit-goal.fields.description-placeholder')"
                :rules="[v => !!v || $t('general.forms.required-field')]"
                type="text" v-model="goal.Notes" outlined
                class="mr-3 text-right"/>
              <div v-if="updatingGoal" class="d-flex flex-column flex-sm-row justify-left">
                <div class="order-sm-last text-right">
                  <v-btn v-if="!goal.EndDate" @click="completeGoal(goal.Id)"
                         :disabled="!valid || processing" :loading="completingGoal"
                         class="ma-2 MProYellow mr-0">
                    {{ $t('goals.edit-goal.buttons.complete-goal') }}
                  </v-btn>
                  <v-btn v-if="!goal.EndDate" @click="updateGoal()"
                         :disabled="!valid || processing" :loading="savingGoal"
                         class="ma-2 MProBlack text-right mr-0">
                    {{ $t('goals.edit-goal.buttons.save-goal') }}
                  </v-btn>
                </div>
                <v-btn @click="sureDialog = true"
                      :disabled="!valid || processing" :loading="deletingGoal"
                      text class="my-2 pl-0 font-weight-thin order-sm-first mr-sm-auto"
                      :class="goal.EndDate == null ? 'float-left' : ''">
                  {{ $t('goals.edit-goal.buttons.delete-goal') }}
                </v-btn>
              </div>
              <div v-else>
                <v-btn @click="setNewGoals" :disabled="!valid || processing" class="ma-2 MProYellow"
                       :loading="savingGoal">
                  {{ $t('goals.edit-goal.buttons.save-goal') }}
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
        <v-dialog v-model="sureDialog" width="400"  light content-class="theme--light">
        <v-card light class="theme--light MPro-bg">
          <v-card-title>
            {{$t('goals.discard-sure')}}
          </v-card-title>
          <v-card-text>
            {{ $t('goals.assign-sure-message')}}
          </v-card-text>
          <v-card-actions>
            <v-btn class="float-right mr-3" @click="deleteGoal(goal.Id)" >{{$t('forms.yes')}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn class="float-right mr-3 MProYellow" @click="sureDialog = false" >{{$t('forms.no')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { MessageKind } from '../../helpers/enums'

export default {
  props: {
    editGoal: Object
  },

  data: () => ({
    dialog: false,
    valid: true,
    updatingGoal: false,
    sureDialog: false,
    goal: {},
    savingGoal: false,
    deletingGoal: false,
    completingGoal: false
  }),

  computed: {
    ...mapState({
      surveys: state => state.seeker.surveys,
      userSeekerId: state => state.user.seeker.Id
    }),
    ...mapGetters(['isSpecialist', 'isSeeker']),
    ...mapGetters('seeker', ['activeSubordinate']),
    surveyPSFS: function () {
      const psfs = this.surveys.filter(s => s.QuestionnaireCode === 'PSFS')
      return psfs
    },
    seekerId: function () {
      return this.$route.params.seekerId
    },
    processing: function () {
      return this.savingGoal || this.deletingGoal || this.completingGoal
    }
  },

  methods: {
    ...mapActions('seeker', ['ensureSurveysLoaded', 'ensureGoalsLoaded']),
    ...mapActions('surveys', ['getOrLoadMetadata']),
    ...mapActions('goals', ['postGoal', 'putGoal', 'setGoalCompleted', 'setGoalDeleted', 'ensureSeekerGoalsLoaded']),
    ...mapActions(['showGlobalMessage']),
    async setNewGoals () {
      if (this.goal.CreatedByPSFS) {
        this.goal.Notes = 'Based on PSFS | ' + this.goal.Notes
      }

      if (this.isSpecialist) {
        this.goal.seekerId = this.seekerId
      } else if (this.isSeeker) {
        this.goal.seekerId = this.activeSubordinate != null ? this.activeSubordinate.Id : this.userSeekerId
      }

      this.savingGoal = true
      try {
        await this.postGoal(this.goal)
      } catch (e) {
        this.showGlobalMessage({
          text: this.$errorMessage(e),
          kind: MessageKind.ERROR
        })
        return
      } finally {
        this.savingGoal = false
      }

      this.showGlobalMessage({
        text: this.$t('goals.edit-goal.messages.success-save-goal'),
        kind: MessageKind.SUCCESS
      })
      this.dialog = false

      this.refreshGoals()
    },
    async updateGoal () {
      this.savingGoal = true
      try {
        await this.putGoal(this.goal)
      } catch (e) {
        this.showGlobalMessage({
          text: this.$errorMessage(e),
          kind: MessageKind.ERROR
        })
        return
      } finally {
        this.savingGoal = false
      }

      this.showGlobalMessage({
        text: this.$t('goals.edit-goal.messages.success-update-goal'),
        kind: MessageKind.SUCCESS
      })
      this.dialog = false

      this.refreshGoals()
    },
    async completeGoal () {
      this.completingGoal = true
      try {
        await this.setGoalCompleted(this.goal.Id)
      } catch (e) {
        this.showGlobalMessage({
          text: this.$errorMessage(e),
          kind: MessageKind.ERROR
        })
        return
      } finally {
        this.completingGoal = false
      }

      this.showGlobalMessage({
        text: this.$t('goals.edit-goal.messages.success-complete-goal'),
        kind: MessageKind.SUCCESS
      })
      this.dialog = false
      this.updatingGoal = false

      this.refreshGoals()
    },
    async deleteGoal () {
      this.sureDialog = false
      this.deletingGoal = true
      try {
        await this.setGoalDeleted(this.goal.Id)
      } catch (e) {
        this.showGlobalMessage({
          text: this.$errorMessage(e),
          kind: MessageKind.ERROR
        })
        return
      } finally {
        this.deletingGoal = false
      }

      this.showGlobalMessage({
        text: this.$t('goals.edit-goal.messages.success-delete-goal'),
        kind: MessageKind.SUCCESS
      })
      this.dialog = false
      this.updatingGoal = false

      this.refreshGoals()
    },
    refreshGoals () {
      if (this.isSpecialist) {
        this.ensureSeekerGoalsLoaded({ seekerId: this.seekerId, force: true })
      } else {
        this.ensureGoalsLoaded(true)
      }
    }
  },

  mounted () {
    this.ensureGoalsLoaded()
    if (this.editGoal) {
      this.goal = Object.assign({}, this.editGoal)
      this.updatingGoal = true
    } else {
      this.goal = {
        Goal: null,
        Notes: null,
        CreatedByPSFS: false
      }
    }
  }
}
</script>
