<template>
    <v-card-text>
      <v-container fluid class="pa-0 ma-0 pt-2">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" lg="9" md="8" sm="12">
            <v-expansion-panels flat v-model="panel" class="filter-panel">
              <v-expansion-panel>
                <v-expansion-panel-header v-if="$vuetify.breakpoint.name === 'xs'">
                  {{ $t('exercises.filters.title') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="pa-0 ma-0">
                    <v-col cols="12" sm="4">
                      <v-select
                            v-model="bodySitesSelected"
                            :items="bodySiteItems"
                            :label="$t('exercises.filters.body-site-filter')"
                            multiple
                            class="pa-0 ma-0"
                          ></v-select>
                          <v-select
                            v-model="scanMovementTypesSelected"
                            :items="scanMovementTypeItems"
                            :label="$t('exercises.filters.movement-type-filter')"
                            multiple
                            class="pa-0 ma-0"
                          ></v-select>
                    </v-col>
                    <v-col  cols="12" sm="4">
                      <v-select
                            v-model="directionSelected"
                            :items="directionItems"
                            :label="$t('exercises.filters.direction-filter')"
                            multiple
                            class="pa-0 ma-0"
                          ></v-select>
                          <v-text-field
                            v-model="exerciseFilter"
                            :label="$t('exercises.filters.search-exercise')"
                            class="pa-0 ma-0"
                          ></v-text-field>
                    </v-col>
                    <v-col  cols="12" sm="4">
                      <v-select
                            v-model="assigned"
                            :items="assignedItems"
                            :label="$t('exercises.filters.assigned-filter')"
                            class="pa-0 ma-0"
                          ></v-select>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="12">
              <v-btn class="float-right MProCoral" :disabled="assignedExercises.length <= 0" @click="saveAssignedExercises()">{{$t('forms.continue')}}</v-btn>
              <v-btn class="float-right" text @click="$emit('back')">{{ $t('forms.back') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid class="pa-0 ma-0 exercise-container">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" lg="2" md="3" sm="4" v-for="exercise in exercisesToShow" :key="exercise.Id">
            <div class="video-container pb-2 h-100pc text-center">
              <img :src="getExerciseThumbnail(exercise)" style="max-width:100%"/>
              <img v-if="getExerciseThumbnail(exercise) == null" src="./../../assets/img/point.png" style="min-height: 100px" />
              <div class="video-overlay">
                <p class="text-right ma-0">
                  <v-icon v-if="exercise.isAssigned" class="MProGreen-text">mdi-checkbox-marked-circle-outline</v-icon>
                  <v-icon v-else class="MProGreen-text"></v-icon>
                </p>
                <p v-if="exercise.isAssigned" class="text-center text-h2 add-exercise MProBlack-text">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon large class="mr-3 MProBlue" v-bind="attrs"
                          v-on="on" @click="addExercise(exercise.Id)"><v-icon>mdi-pencil-outline</v-icon></v-btn>
                    </template>
                    <span>{{$t('exercises.edit-exercise')}}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon large class="ml-3 MProCoral" v-bind="attrs"
                          v-on="on" @click="deleteExercise(exercise.Id)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                    </template>
                    <span>{{$t('exercises.remove-exercise')}}</span>
                  </v-tooltip>
                </p>
                <p v-else class="text-center text-h2 add-exercise MProBlack-text">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon large class="MProBlue add-exercise" v-bind="attrs"
                          v-on="on" @click="addExercise(exercise.Id)"><v-icon>mdi-plus</v-icon></v-btn>
                    </template>
                    <span>{{$t('exercises.add-exercise')}}</span>
                  </v-tooltip>
                </p>
                <p class="exercise-title text-center">{{exercise.Name}}</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="dialog" width="1200" scrollable :fullscreen="$vuetify.breakpoint.name === 'xs'">
        <v-card v-if="dialog" class="MPro-bg">
        <v-card-title>
          <div class="flex-grow-1 d-flex justify-space-between">
            <div>
              <span v-if="editing">
                {{ $t('exercises.edit.title', { exercise: editedExerciseInfo.Name }) }}
              </span>
            </div>
            <v-btn icon class="ml-2" @click="dialog = !dialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-form v-model="validExercise" ref="form">
            <v-container v-if="editing != null" class="pa-0 ma-0 ">
              <v-row class="align-stretch pa-0 ma-0 ">
                <v-col cols="12" md="5">
                  <v-row class="align-start pa-0 ma-0">
                    <v-col cols="12" class="pa-0 ma-0">
                      <v-subheader class="pa-0">{{$t('exercises.edit.fields.details')}}</v-subheader>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-0 ma-0">
                      <v-row>
                        <v-col cols="12" class="py-0 my-0">
                          <v-select v-model="editingMeta.Resistance" :full-width="true"
                              :rules="notEmptyRules"
                              :items="resistanceItems"
                              :label="$t('exercises.edit.fields.resistance')"
                              item-value="value"
                              item-text="text"
                              :placeholder="$t('exercises.edit.fields.resistance-placeholder')" persistent-placeholder
                              :dense="$vuetify.breakpoint.name === 'xs'"/>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0 my-0">
                          <v-text-field :label="$t('exercises.edit.fields.sets')" :rules="numberRulesNoZero"
                                        type="number" v-model="editingMeta.Sets" :min="0" required
                                        class="mr-3 text-right"/>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0 my-0">
                          <v-text-field :label="$t('exercises.edit.fields.repetitions')" :rules="numberRulesNoZero"
                                        type="number" v-model="editingMeta.Repetitions" :min="0"
                                        class="mr-3 text-right"/>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-0 my-0">
                          <label>{{$t('exercises.edit.fields.duration')}}</label>
                        </v-col>
                        <v-col cols="6" class="pt-0 mt-n4">
                          <v-text-field  :rules="numberRules"
                                        type="number" v-model="DurationMinutes"  :min="0"
                                        class="mr-0 text-right small-icon" append-icon="m"/>
                        </v-col>
                        <v-col cols="6" class="pt-0 mt-n4">
                          <v-text-field :rules="numberRules"
                                        type="number" v-model="DurationSeconds" :min="DurationMinutes > 0 ? -1 : 0" :max="60"
                                        class="mr-0 text-right small-icon" append-icon="s"/>
                        </v-col>
                        <v-col cols="12" class="pb-0 mb-0 pt-0 mt-n4">
                          <label>{{$t('exercises.edit.fields.rest')}}</label>
                        </v-col>
                        <v-col cols="6" class="pt-0 mt-n4">
                          <v-text-field :rules="numberRules"
                                        type="number" v-model="RestMinutes" :min="0"
                                        class="mr-0 text-right small-icon" append-icon="m"/>
                        </v-col>
                        <v-col cols="6" class="pt-0 mt-n4">
                          <v-text-field :rules="numberRules"
                                        type="number" v-model="RestSeconds" :min="RestMinutes > 0 ? -1 : 0" :max="60"
                                        class="mr-0 text-right small-icon" append-icon="s"/>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col col="6">
                      <v-textarea :label="$t('exercises.edit.fields.notes')"
                                  v-model="editingMeta.Notes"/>
                      <v-radio-group v-model="editingMeta.Alternation" :rules="notEmptyRules" :mandatory="false"  :label="$t('exercises.edit.fields.alternation')">
                        <v-radio v-for="option in alternation"  :label="$t(`exercises.edit.fields.alternation-${option}`)" :value="option" :key="option"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-slider :label="$t('exercises.edit.fields.exertion')" :min="1" :max="10"
                                    v-model="editingMeta.Exertion"
                                    class="mr-3"/>
                </v-col>
                <v-col cols="12" md="7" class="d-flex flex-column">
                  <v-subheader class="px-0">{{$t('exercises.description')}}</v-subheader>
                  <v-row class="flex-grow-1">
                    <v-col cols="12" sm="6">
                      <iframe :src="editedExerciseInfo.VideoPlayerUri || ''"
                              frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="width: 100%;">
                      </iframe>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <h3>{{ editedExerciseInfo.DetailName }}</h3>
                      <h4>{{ $t('exercises.goal') }}</h4>
                      <p>{{ editedExerciseInfo.Goal }}</p>
                      <h4>{{ $t('exercises.instruction') }}</h4>
                      <p>{{ editedExerciseInfo.Instruction }}</p>
                    </v-col>
                  </v-row>
                  <div class="d-flex justify-end">
                    <v-btn class="mr-3" text @click="dialog = !dialog">{{$t('forms.discard')}}</v-btn>
                    <v-btn class="MProCoral" :disabled="!validExercise" @click="saveExerciseMeta()">{{$t('programs.edit.buttons.save-exercise')}}</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-text>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Lo from 'lodash'

export default {
  props: {
    'assignedExercises': {type: Array, required: false, default: () => []}
  },
  data: () => {
    return {
      panel: null,
      validExercise: false,
      dialog: false,
      editing: null,
      assigned: 'both',
      resistance: ['none', 'light', 'moderate', 'heavy'],
      alternation: ['none', 'left-only', 'right-only', 'left-first', 'right-first'],
      bodySitesSelected: [],
      scanMovementTypesSelected: [],
      directionSelected: [],
      DurationMinutes: 0,
      DurationSeconds: 0,
      RestMinutes: 0,
      RestSeconds: 0,
      editingMeta: {},
      exerciseFilter: null
    }
  },
  computed: {
    ...mapGetters('exercises', ['getAllExercises']),
    ...mapGetters('specialist', ['getSeekerById']),
    notEmptyWeekdaySelect () {
      return [
        _ => this.WeekDaysMask === 0 || this.$t('forms.validations.required')
      ]
    },
    notEmptyRules () {
      return [
        v => !!v || this.$t('forms.validations.required')
      ]
    },
    numberRulesNoZero () {
      return [
        v => !!v || this.$t('forms.validations.required'),
        v => Number(v) >= 1 || this.$t('forms.validations.positiveNumber'),
        v => /^[-+]?\d+$/.test(v) || this.$t('forms.validations.integer')
      ]
    },
    numberRules () {
      return [
        // v => !!v || this.$t('forms.validations.required'),
        v => /^[-+]?\d+$/.test(v) || this.$t('forms.validations.integer'),
        v => Number(v) >= 0 || this.$t('forms.validations.positiveNumber')
      ]
    },
    seeker: function () {
      return this.getSeekerById(this.seekerId)
    },
    seekerId: function () {
      return this.$route.params.seekerId
    },
    bodySiteItems: function () {
      return Lo(this.getAllExercises)
        .flatMap(exercise => exercise.LabelsJson.BodySites)
        .uniq()
        .map(bodySite => ({ value: bodySite, text: this.$t('exercises.filters.body-site-' + bodySite) }))
        .sortBy(item => item.text)
        .value()
    },
    directionItems: function () {
      return Lo(this.getAllExercises)
        .flatMap(exercise => exercise.LabelsJson.Directions)
        .uniq()
        .map(direction => ({ value: direction, text: this.$t('exercises.filters.direction-' + direction) }))
        .sortBy(item => item.text)
        .value()
    },
    scanMovementTypeItems: function () {
      return Lo(this.getAllExercises)
        .flatMap(exercise => exercise.LabelsJson.ScanMovementTypes)
        .uniq()
        .map(movementType => ({ value: movementType, text: this.$t('exercises.filters.movement-type-' + movementType) }))
        .sortBy(item => item.text)
        .value()
    },
    exercisesToShow: function () {
      let exercises = []
      let seen = []
      this.getAllExercises.forEach(exer => {
        if (!seen.includes(exer.Id)) {
          exer.isAssigned = this.assignedExercises.find(aE => aE.ExerciseId === exer.Id) != null
          seen.push(exer.Id)
          exercises.push(exer)
        }
      })
      if ((this.exerciseFilter || '').trim() !== '') {
        const exerciseValue = this.exerciseFilter.toLocaleLowerCase()
        exercises = exercises.filter(e => {
          return e.Name.toLocaleLowerCase().includes(exerciseValue) ||
            e.Goal.toLocaleLowerCase().includes(exerciseValue) ||
            e.Instruction.toLocaleLowerCase().includes(exerciseValue)
        })
      }
      if (this.scanMovementTypesSelected.length > 0) exercises = exercises.filter(e => e.LabelsJson.ScanMovementTypes.some(r => this.scanMovementTypesSelected.indexOf(r) >= 0))
      if (this.bodySitesSelected.length > 0) exercises = exercises.filter(e => e.LabelsJson.BodySites.some(r => this.bodySitesSelected.indexOf(r) >= 0))
      if (this.directionSelected.length > 0) exercises = exercises.filter(e => e.LabelsJson.Directions.some(r => this.directionSelected.indexOf(r) >= 0))
      if (this.assigned !== this.$t('exercises.assigned-both')) exercises = exercises.filter(e => e.isAssigned === (this.assigned === this.$t('exercises.assigned')))
      exercises.sort((e1, e2) => e1.Name.toLocaleLowerCase() < e2.Name.toLocaleLowerCase() ? -1 : +1)
      return exercises
    },
    editedExerciseInfo: function () {
      return this.getAllExercises.find(e => e.Id === this.editing)
    },
    resistanceItems: function () {
      let items = []
      this.resistance.forEach(t => {
        items.push({
          'value': t,
          'text': this.$t(`exercises.edit.fields.resistance-${t}`)
        })
      })
      return items
    },
    assignedItems: function () {
      return [this.$t('exercises.assigned-both'), this.$t('exercises.assigned'), this.$t('exercises.not-assigned')]
    }
  },
  methods: {
    ...mapActions('exercises', ['ensureAllExercisesLoaded']),
    addExercise (exerciseId) {
      this.editing = exerciseId
      if (this.assignedExercises.find(e => e.ExerciseId === exerciseId)) {
        this.editingMeta = { ...this.assignedExercises.find(e => e.ExerciseId === exerciseId) }
        this.DurationMinutes = Math.floor(this.editingMeta.DurationSeconds / 60)
        this.DurationSeconds = this.editingMeta.DurationSeconds % 60
        this.RestMinutes = Math.floor(this.editingMeta.RestSeconds / 60)
        this.RestSeconds = this.editingMeta.RestSeconds % 60
      } else {
        this.editingMeta = {
          ExerciseId: exerciseId,
          Resistance: 'none',
          Exertion: null,
          Repetitions: null,
          DurationSeconds: null,
          Sets: null,
          RestSeconds: null,
          Alternation: 'none',
          Notes: ''
        }
        this.DurationMinutes = 0
        this.DurationSeconds = 0
        this.RestMinutes = 0
        this.RestSeconds = 0
      }
      this.dialog = true
    },
    deleteExercise: function (id) {
      let index = this.assignedExercises.findIndex(e => e.ExerciseId === id)
      if (index >= 0) {
        const newExercises = [...this.assignedExercises]
        newExercises.splice(index, 1)
        this.raiseUpdate(newExercises)
      }
    },
    saveExerciseMeta () {
      let meta = {...this.editingMeta}
      const newExercises = [...this.assignedExercises]
      const index = newExercises.findIndex(e => e.ExerciseId === meta.ExerciseId)
      if (index >= 0) {
        newExercises[index] = meta
      } else {
        newExercises.push(meta)
      }
      this.raiseUpdate(newExercises)
      this.dialog = false
    },
    getExerciseThumbnail (exercise) {
      const thumbnail = Lo.head(exercise.thumbnails)
      if (thumbnail != null) {
        const size = Lo(thumbnail.sizes).drop(2).head()
        if (size != null) {
          return size.link.replace('?r=pad', '')
        }
      }

      return null
    },
    saveAssignedExercises: function () {
      this.$emit('saveExercises')
    },
    updateTimes () {
      this.DurationMinutes = parseInt(this.DurationMinutes)
      this.DurationSeconds = parseInt(this.DurationSeconds)
      this.RestMinutes = parseInt(this.RestMinutes)
      this.RestSeconds = parseInt(this.RestSeconds)
      if (this.DurationSeconds >= 60) {
        this.DurationMinutes++
        this.DurationSeconds = 0
      }
      if (this.DurationSeconds < 0) {
        this.DurationMinutes > 0 ? this.DurationSeconds = 59 : this.DurationSeconds = 0
        this.DurationMinutes > 0 ? this.DurationMinutes-- : this.DurationMinutes = 0
      }
      this.editingMeta.DurationSeconds = (this.DurationMinutes * 60) + this.DurationSeconds
      if (this.RestSeconds >= 60) {
        this.RestMinutes++
        this.RestSeconds = 0
      }
      if (this.RestSeconds < 0) {
        this.RestMinutes > 0 ? this.RestSeconds = 59 : this.RestSeconds = 0
        this.RestMinutes > 0 ? this.RestMinutes-- : this.RestMinutes = 0
      }
      this.editingMeta.RestSeconds = this.RestMinutes * 60 + this.RestSeconds
    },
    onResize () {
      this.$vuetify.breakpoint.name !== 'xs' ? this.panel = 0 : this.panel = null
    },
    raiseUpdate (newExercises) {
      this.$emit('update', newExercises)
    }
  },
  watch: {
    DurationMinutes: function () {
      this.updateTimes()
    },
    DurationSeconds: function () {
      this.updateTimes()
    },
    RestMinutes: function () {
      this.updateTimes()
    },
    RestSeconds: function () {
      this.updateTimes()
    }
  },
  mounted () {
    this.ensureAllExercisesLoaded()
    window.addEventListener('resize', this.onResize)
    this.onResize()
    this.assigned = this.$t('exercises.assigned-both')
    // this.asignedExercises = this.alreadyAssignedExercises
  }
}
</script>

<style>
  .video-overlay{
    top: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    background: rgba(255, 255, 255, 0);
    transition: 0.3s;

  }

  .video-overlay:hover{
    background: rgba(255, 255, 255, 0.7);
  }

  .video-overlay .add-exercise{
    opacity: 0;
  }

  .video-overlay:hover .add-exercise{
    opacity: 1;
  }

  .video-container{
    position: relative
  }
  .exercise-title{
    position: absolute;
    padding-top: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    bottom: -27px;
    vertical-align: top;
  }

  .theme--light.v-expansion-panels.filter-panel .v-expansion-panel{
    background-color: transparent!important;
  }

  .v-toolbar {
    z-index: 1;
  }

  .small-icon i.v-icon {
    font-size: 17px;
  }

  .exercise-container {
    flex-grow: 1;
    overflow: auto;
    overflow-y: auto;
    max-height: 85%;
    padding: 10px;
    margin: 10px;
  }

</style>
