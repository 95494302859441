<template>
  <v-dialog :value="value" @input="onInput"
            max-width="600"
            :persistent="deleting">
    <v-card>
      <v-card-title class="MProCoral">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <div class="d-flex align-center">
          <v-icon large class="MProCoral-text ma-3">mdi-alert</v-icon>
          <span class="flex-grow-1">{{ message }}</span>
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn class="MProCoral" @click="onDeleteClick"
               :loading="deleting" :disabled="deleting">
          {{ $t('forms.delete') }}
        </v-btn>
        <v-btn text @click="onInput(false)"
               :disabled="deleting">
          {{ $t('forms.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    title: String,
    message: String
  },

  data: () => ({
    deleting: false
  }),

  watch: {
    value: function (newValue) {
      if (!newValue) this.deleting = false
    }
  },

  methods: {
    onInput (v) {
      this.$emit('input', v)
    },
    onDeleteClick () {
      this.deleting = true
      this.$emit('confirm')
    }
  }
}
</script>
