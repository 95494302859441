<template #default="{scan}">
  <div>
    <div v-if="scans.length === 0 && !loading" class="pa-6">
      <v-alert dense text type="info">
        <span v-if="isSpecialist && sharesScans3D === false && sharesScans2D === false">{{
          $t('scans.scans-not-shared-with-specialist') }}</span>
        <span v-else-if="isSpecialist && sharesScans3D === false">{{ $t('scans.scans3d-not-shared-with-specialist')
          }}</span>
        <span v-else-if="isSpecialist && sharesScans2D === false">{{ $t('scans.scans2d-not-shared-with-specialist')
          }}</span>
        <span v-else>{{ $t('scans.nothing-available') }}</span>
      </v-alert>
    </div>
    <div v-if="$vuetify.breakpoint.smAndDown" class="px-5 pt-2 pb-0">
      <v-select v-show="loading || scans.length > 0"
                :label="$t('scans.viewer.scans-select')" :placeholder="$t('scans.viewer.scans-select-first')"
                persistent-placeholder v-model="select"
                :items="scans" item-value="Id"
                :hint="selectHint" persistent-hint
                return-object @change="goToScan(select)"
                :loading="loading">
        <template slot='selection' slot-scope='{ item }'>
          {{ $t('scans.scan-title', {num: item.num}) }} - {{ $formatDate(item.CapturedAt) }}
        </template>
        <template #item="{ item }">
          <div class="d-flex align-center">
            <v-badge dot left color="MProCoral"
                     class="ma-2"
                     :value="item.ViewedBySpecialist === false">
              <v-icon>{{ getScanIcon(item).name }}</v-icon>
            </v-badge>
            <div class="d-flex flex-column flex-grow-1">
              <span>{{ $t('scans.scan-title', {num: item.num}) }} - {{ $formatDate(item.CapturedAt) }}</span>
              <span class="text-caption">{{ getScanAffiliation(item) }}</span>
            </div>
          </div>
        </template>
      </v-select>
    </div>
    <v-skeleton-loader v-else type="list-item-two-line@3"
                       class="pa-5" :class="[scans.length > 0 && 'fill-height']"
                       :loading="loading">
      <v-list>
        <v-btn v-if="isSpecialist && hasScans3D"
               :to="{name: 'ScanCompare', params: {seekerId: seekerId}, query: {scanIdL: $route.params.scanId, movementL: movementL}}"
               class="MProCoral mb-5" block large>
          {{ $t('scans.buttons.compare-scans') }}
        </v-btn>
        <v-list-item v-for="(item, index) in items" :key="item.id" flat class="my-2 fade"
                     :class="fab[index] ? 'faded' : ''" :to="{name: 'Scan', params: {scanId: item.id}}">
          <v-list-item-icon class="mr-4 align-self-center">
            <v-badge dot left color="MProCoral"
                    :value="item.scan.ViewedBySpecialist === false">
              <v-tooltip right>
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    {{ getScanIcon(item.scan).name }}
                  </v-icon>
                </template>
                <span v-if="item.scan.ScanType === '3D'">{{ $t('scans.3d-scan') }}</span>
                <span v-if="item.scan.ScanType === '2D'">{{ $t('scans2d.mobile-scan') }}</span>
              </v-tooltip>
            </v-badge>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>{{$formatDate(item.scan.CapturedAt)}}</v-list-item-subtitle>
            <v-list-item-title class="font-weight-bold">
              {{ $t('scans.scan-title', {num: item.num }) }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn v-if="isSpecialist && item.is3D"
                   :to="{name: 'ScanCompare', params: {seekerId: seekerId}, query: {scanIdL: $route.params.scanId, movementL: movementL, scanIdR: item.id}}"
                   class="MProCoral" icon small>
              <v-icon small>mdi-compare</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-pagination v-if="pageCount > 1" :length="pageCount" v-model="pageIndex" />
      </v-list>
    </v-skeleton-loader>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { ScanType } from '../../helpers/enums'
import { getScanIcon } from '@/models/ui'
import moment from 'moment'
import { SharedDataKind } from '@/models/data-sharing'

const SCANS_PER_PAGE = 10

export default {
  props: {
    movementL: {type: String, required: false, default: () => ''}
  },

  data: () => ({
    loading: true,
    pageIndex: 1,
    items: [],
    scans: [],
    select: undefined,
    scanCompare: false,
    fab: [],
    fabDir: Array(10)
  }),

  computed: {
    ...mapState({
      scansSeeker: state => state.seeker.scans,
      scansSpecialist: state => state.specialist.scans
    }),
    ...mapGetters(['isSpecialist', 'isSeeker']),
    scanId: function () {
      return this.$route.params.scanId
    },
    seekerId: function () {
      if (this.$route.name === 'clientScans') return this.$route.params.seekerId
      let scan = this.scansSpecialist.find(s => s.Id === this.scanId)
      return scan == null ? null : scan.SeekerId
    },
    hasScans3D: function () {
      return this.scans.filter(s => s.ScanType === ScanType.DEPTH).length > 0
    },
    scansToDisplay: function () {
      const startIndex = SCANS_PER_PAGE * (this.pageIndex - 1)
      const result = this.scans.slice(startIndex, startIndex + SCANS_PER_PAGE)
      return result
    },
    sharesScans3D: function () {
      return this.sharedDataKinds == null ? null : this.sharedDataKinds.includes(SharedDataKind.Scans)
    },
    sharesScans2D: function () {
      return this.sharedDataKinds == null ? null : this.sharedDataKinds.includes(SharedDataKind.Scans2D)
    },
    pageCount: function () {
      let result = Math.floor((this.scans.length + SCANS_PER_PAGE - 1) / SCANS_PER_PAGE)
      return result
    },
    selectHint: function () {
      return this.select == null ? null : this.getScanAffiliation(this.select)
    }
  },

  asyncComputed: {
    sharedDataKinds: async function () {
      if (!this.isSpecialist || this.seekerId == null) return Promise.resolve([])
      return this.getOrLoadSharedDataKinds(this.seekerId)
    }
  },

  methods: {
    ...mapActions('seeker', ['ensureScansLoaded']),
    ...mapActions('specialist', ['ensureSpecialistScansLoaded', 'ensureSpecialistSeekersLoaded', 'getOrLoadSharedDataKinds']),
    async refreshScans () {
      let scans = []
      if (this.isSpecialist) {
        await this.ensureSpecialistScansLoaded()
        await this.ensureSpecialistSeekersLoaded()
        scans = this.scansSpecialist.filter(s => s.SeekerId === this.seekerId)
      } else if (this.isSeeker) {
        await this.ensureScansLoaded()
        scans = this.scansSeeker
      }
      scans.sort((s1, s2) => moment(s2.CapturedAt).diff(moment(s1.CapturedAt)))
      scans.forEach((item, index) => {
        item.num = scans.length - index
      })
      this.scans = scans
      this.loading = false
    },
    updateItems () {
      this.items = this.scansToDisplay.map(s => ({
        id: s.Id,
        scan: s,
        num: s.num,
        is3D: s.ScanType === ScanType.DEPTH,
        is2D: s.ScanType === ScanType.MOBILE
      }))
    },
    updateSelect () {
      if (this.scanId != null) {
        this.select = this.scans.find(s => s.Id === this.scanId)
      }
    },
    getScanAffiliation (scan) {
      const library = scan.LibraryName
      return library == null ? scan.CapturedBy : `${library} - ${scan.OwnerName}`
    },
    getScanIcon (scan) {
      return getScanIcon(scan)
    },
    goToScan (scan) {
      this.$router.push({name: 'Scan', params: {scanId: scan.Id}})
    }
  },

  watch: {
    scanId: function () {
      this.updateSelect()
    },
    scansToDisplay: function () {
      this.updateItems()
    },
    select: function () {
      // Ensure the selected scan is visible
      if (this.select != null) {
        const index = this.scans.length - this.select.num
        this.pageIndex = 1 + Math.floor(index / SCANS_PER_PAGE)
      }
    }
  },

  async mounted () {
    await this.refreshScans()
    this.updateSelect()
  }
}
</script>

<style scoped>
  .fade .v-list-item__content{
    transition: .3s opacity;
  }
  .faded .v-list-item__content{
    opacity: .3;
  }
</style>
