import units from 'convert-units'

function roundValue (value, maxPrecision = 0) {
  // Based on this article
  // https://www.jacklmoore.com/notes/rounding-in-javascript/
  const rounded = Math.round(Number(`${value}e${maxPrecision}`))
  return Number(`${rounded}e-${maxPrecision}`)
}

function formatLength (value, originalUnit, metric, includeUnit = true, maxPrecision = 1) {
  let measure = metric || metric === 'true'
    ? {'val': value, 'unit': originalUnit}
    : units(units(value).from(originalUnit).to('in')).from('in').toBest({ exclude: ['mm', 'cm', 'm', 'km', 'ft-us'] })
  if ((measure.unit === 'yd' && measure.val < 3) || measure.unit === 'feet') return formatFeetInch(measure.val, measure.unit)
  if (measure.unit === 'in') return includeUnit ? `${inchRounding(measure.val, maxPrecision)}${measure.unit}` : `${inchRounding(measure.val, maxPrecision)}`
  const displayValue = roundValue(measure.val, maxPrecision)
  return includeUnit ? `${displayValue} ${measure.unit}` : String(displayValue)
}

function formatFeetInch (value, originalUnit) {
  let measure = units(value).from(originalUnit).to('in')
  let feet = units(measure - (measure % 12)).from('in').to('ft')
  let inch = inchRounding(measure % 12, 0)
  return `${feet}ft ${inch}in`
}

function inchRounding (value, decimals) {
  if (decimals === 0) {
    value = Math.round(value * 2) / 2
    if (value.toString().indexOf('.') !== -1) {
      value = (value === -0.5 || value === 0.5) ? value === -0.5 ? '-\u00BD' : '\u00BD' : value.toString().replace('.5', '\u00BD')
      return value
    }
    return value
  }
  return roundValue(value, decimals)
}

function formatMass (value, originalUnit, metric, includeUnit = true) {
  let measure = metric || metric === 'true'
    ? units(value).from(originalUnit).toBest({ exclude: ['mt', 'oz', 'lb', 't'] })
    : units(units(value).from(originalUnit).to('lb')).from('lb').toBest({ exclude: ['mcg', 'mg', 'g', 'kg', 'mt'] })
  return includeUnit ? `${roundValue(measure.val, 2)} ${measure.unit}` : `${roundValue(measure.val, 2)}`
}

function formatArea (value, originalUnit, metric, includeUnit = true) {
  const targetUnit = metric === true || metric === 'true' ? 'cm2' : 'in2'
  const converted = units(value).from(originalUnit).to(targetUnit)
  const rounded = roundValue(converted, 1)
  const roundedStr = rounded === 0 && converted > 0 ? `< ${0.1}` : rounded.toString()
  return includeUnit
    ? `${roundedStr} ${targetUnit.replace('2', '²')}`
    : roundedStr
}

function formatVolume (value, originalUnit, metric, includeUnit = true) {
  let measure = metric || metric === 'true'
    ? units(value).from(originalUnit).toBest({ exclude: ['mm3', 'cm3', 'm3', 'km3', 'krm', 'tsk', 'msk', 'kkp', 'glas', 'kanna', 'tsp', 'Tbs', 'in3', 'fl-oz', 'cup', 'pnt', 'qt', 'gal', 'ft3', 'yd3'] })
    : units(units(value).from(originalUnit).to('gal')).from('gal').toBest({ exclude: ['mm3', 'cm3', 'ml', 'cl', 'dl', 'l', 'kl', 'm3', 'km3', 'krm', 'tsk', 'msk', 'kkp', 'glas', 'kanna', 'tsp', 'Tbs', 'in3', 'ft3', 'yd3'] })
  return includeUnit ? `${roundValue(measure.val, 2)} ${measure.unit}` : `${roundValue(measure.val, 2)}`
}

function formatVolume3 (value, originalUnit, metric, includeUnit = true) {
  let measure = metric || metric === 'true'
    ? units(value).from(originalUnit).toBest({ exclude: ['ml', 'cl', 'dl', 'l', 'kl', 'krm', 'tsk', 'msk', 'kkp', 'glas', 'kanna', 'tsp', 'Tbs', 'in3', 'fl-oz', 'cup', 'pnt', 'qt', 'gal', 'ft3', 'yd3'] })
    : units(units(value).from(originalUnit).to('ft3')).from('ft3').toBest({ exclude: ['mm3', 'cm3', 'ml', 'cl', 'dl', 'l', 'kl', 'm3', 'km3', 'krm', 'tsk', 'msk', 'kkp', 'glas', 'kanna', 'tsp', 'Tbs', 'fl-oz', 'cup', 'pnt', 'qt', 'gal'] })
  return includeUnit ? `${roundValue(measure.val, 2)} ${measure.unit}` : `${roundValue(measure.val, 2)}`
}

function formatPercent (value, base) {
  const percent = Math.round(100 * value / base)
  return `${percent}%`
}

export default {
  install: function (Vue) {
    Vue.prototype.$units = units
    Vue.prototype.$roundValue = roundValue
    Vue.prototype.$formatLength = formatLength
    Vue.prototype.$formatMass = formatMass
    Vue.prototype.$formatArea = formatArea
    Vue.prototype.$formatVolume = formatVolume
    Vue.prototype.$formatVolume3 = formatVolume3
    Vue.prototype.$formatPercent = formatPercent
  }
}
