<template>
  <v-navigation-drawer app :right="true"
                        :clipped="true"
                        v-model="chatsOpen"
                        temporary>
    <div class="d-flex flex-column h-100pc">
      <mpro-new-chat-list class="ma-3"/>
      <mpro-chat-list class="ma-3"/>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NewChat from '../chats/new-chat'
import Utils from '@/helpers/utils'
import Styles from '@/helpers/styles'
import ChatList from '../chats/chat-list'

export default {
  components: {
    'mpro-new-chat-list': NewChat,
    'mpro-chat-list': ChatList
  },

  computed: {
    chatsOpen: {
      get: function () {
        return this.$store.state.user.userSettings.chatsOpen
      },
      set: function (value) {
        this.$store.commit('user/setChatsOpen', value)
      }
    },
    avatarText: function () {
      let name = this.getDisplayName || this.getUserEmail || '?'
      return Utils.getUserInitials(name)
    },
    avatarColor: function () {
      return `${Styles.getRoleColor(this.getRole)} white--text`
    },
    ...mapGetters(['getRole']),
    ...mapGetters('user', ['getDisplayName', 'getUserEmail'])
  },

  watch: {
    chatsOpen: function (newValue) {
      // Refresh the chat list on each open of the menu
      // But do not force: rely on the cache timeouts
      if (newValue === true) {
        this.ensurePartnersLoaded()
      }
    }
  },

  methods: {
    ...mapActions('chats', ['ensurePartnersLoaded'])
  }
}
</script>

<style scoped>
.main-navigation-seeker {
  /* Material Indigo */
  border-right: 2px #3F51B5 solid !important;
}
.main-navigation-specialist {
  /* Material Teal */
 border-right: 2px #009688 solid !important;
}
.main-navigation-administrator {
  /* Material Pink */
  border-right: 2px #e91e63 solid !important;
}
</style>
