import linkifyString from 'linkify-string'

function linkify (str) {
  return linkifyString(str, {
    validate: (_, type) => type === 'url',
    target: '_blank',
    className: 'linkified',
    attributes: {
      // For better security, as detected links are untrusted
      rel: 'noopener noreferrer'
    }
  })
}

export default {
  install (Vue) {
    Vue.prototype.$linkify = linkify

    Vue.directive('linkify', {
      bind (el, binding) {
        el.innerHTML = linkify(String(binding.value ?? ''))
      },
      componentUpdated (el, binding) {
        el.innerHTML = linkify(String(binding.value ?? ''))
      }
    })
  }
}
