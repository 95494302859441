<template>
  <div>
    <v-tabs-items :value="activeTab">
      <v-tab-item :value="tabNames[0]">
        <mpro-details-tab class="d-inline-block"
                          :library="library"
                          @update="forceRefresh++"/>
      </v-tab-item>

      <v-tab-item :value="tabNames[1]">
        <mpro-settings-table :settings="settings"
                             :organization-code="library != null ? library.OwnerCode : null"
                             :library-id="id"
                             @update="forceRefresh++"/>
      </v-tab-item>

      <v-tab-item :value="tabNames[2]">
        <div class="d-inline-block">
          <div class="d-flex">
            <mpro-grant-right :organization-code="library && library.OwnerCode"
                              :library-id="library && library.Id"
                              @grant-right="forceRefresh++"/>
          </div>

          <v-skeleton-loader type="table-thead, table-tbody" :loading="loading">
            <mpro-rights-table :rights="rights" hide-object
                              @revoke-right="forceRefresh++"/>
          </v-skeleton-loader>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import DetailsTab from './details-tab'
import SettingsTable from '../settings/table'
import RightsTable from '../rights/table'
import GrantRight from '../rights/grant-right'

export default {
  components: {
    'mpro-details-tab': DetailsTab,
    'mpro-settings-table': SettingsTable,
    'mpro-rights-table': RightsTable,
    'mpro-grant-right': GrantRight
  },

  data: () => ({
    forceRefresh: 0
  }),

  computed: {
    id: function () {
      return this.$route.params.id
    },
    loading: function () {
      return this.library == null
    },
    tabNames: function () {
      return ['details', 'settings', 'rights']
    },
    tabIcons: function () {
      return ['mdi-card-text-outline', 'mdi-cog-outline', 'mdi-security']
    },
    settings: function () {
      return this.library?.settings ?? []
    },
    rights: function () {
      return this.library?.rights ?? []
    },
    ...mapState(['activeTab'])
  },

  asyncComputed: {
    library: {
      get: function () {
        return this.id == null ? Promise.resolve(null) : this.loadLibrary(this.id)
      },
      watch: ['forceRefresh']
    }
  },

  watch: {
    library: function () {
      this.updateHeaderTitle()
    },
    '$route.params.activeTab': function () {
      this.updateActiveTab()
    }
  },

  methods: {
    updateHeaderTitle () {
      const name = this.library && this.library.Name
      this.setHeaderTitle({
        text: this.$t('admin.library.title', { name }),
        additionalText: this.library == null
          ? undefined
          : this.$t('admin.library.subtitle', { name: this.library.OwnerName }),
        additionalLink: {
          name: 'admin:OrganizationTab',
          params: { code: this.library?.OwnerCode, activeTab: 'libraries' }
        }
      })
    },
    ensureTabRoute (route) {
      const isValidTabName = route.name === 'admin:LibraryTab' &&
        this.tabNames.includes(route.params.activeTab)
      if (!isValidTabName) {
        this.$router.replace({
          name: 'admin:LibraryTab',
          params: {
            id: this.id,
            activeTab: this.tabNames[0]
          }
        })
      }
    },
    updateActiveTab () {
      this.setActiveTab(this.$route.params.activeTab)
    },
    ...mapActions(['setHeaderTitle', 'setHeaderTabs']),
    ...mapActions('admin', ['loadLibrary']),
    ...mapMutations(['setActiveTab'])
  },

  mounted () {
    this.updateHeaderTitle()
    let tabs = this.tabNames.map((tab, index) => ({
      value: tab,
      to: {
        name: 'admin:LibraryTab',
        params: {
          id: this.id,
          activeTab: tab
        }
      },
      title: this.$t(`admin.library.tabs.${tab}`),
      icon: this.tabIcons[index]
    }))
    this.setHeaderTabs({tabs})
    this.updateActiveTab()
  },
  beforeDestroy () {
    this.setHeaderTabs()
  },
  beforeRouteEnter (to, _, next) {
    next(vm => vm.ensureTabRoute(to))
  },
  beforeRouteUpdate (to, _, next) {
    next()
    this.ensureTabRoute(to)
  }
}
</script>
