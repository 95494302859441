





















































import Vue, { PropType } from 'vue'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { TranslateResult } from 'vue-i18n'
import Lo from 'lodash'
import { DataSharingStatus, SharingSeeker } from '@/models/data-sharing'
import { SpecialistBasicInfo } from '@/models/users'
import { MessageKind } from '@/helpers/enums'

export default Vue.extend({
  props: {
    value: Boolean,
    seeker: Object as PropType<SharingSeeker>,
    organizationCode: String
  },

  data: () => ({
    processing: false,
    specialist: null as SpecialistBasicInfo | null,
    specialists: [] as SpecialistBasicInfo[]
  }),

  validations: {
    specialist: { required }
  },

  computed: {
    canConnect: function (): boolean {
      return this.seeker?.Notes != null && !this.inConnection
    },
    inConnection: function (): boolean {
      return this.seeker?.NotesReceivedByLogin != null
    },
    seekerReady: function (): boolean {
      return this.seeker?.Status === DataSharingStatus.Active &&
        this.seeker?.LibraryIds?.length > 0
    },
    seekerNameOrEmail: function (): string {
      return this.seeker?.SeekerName ?? this.seeker?.SeekerEmail ?? ''
    },
    noSpecialistText: function (): TranslateResult {
      return this.seekerReady
        ? this.$t('admin.sharing-seekers.request-note-dialog.specialist-no-data')
        : this.$t('admin.sharing-seekers.request-note-dialog.connect-error-not-active')
    }
  },

  asyncComputed: {
    specialists: {
      async get (): Promise<SpecialistBasicInfo[]> {
        if (!this.canConnect) return []

        const result: SpecialistBasicInfo[] = await this.loadSpecialistsWithAccess({
          organizationCode: this.organizationCode,
          seekerId: this.seeker.SeekerId
        })
        return Lo.sortBy(result, s => s.DisplayName)
      },
      default: []
    }
  },

  methods: {
    ...mapActions(['showGlobalMessage']),
    ...mapActions('admin', ['loadSpecialistsWithAccess', 'sendDataSharingNotes']),
    onInput (v: boolean) {
      if (!v) {
        this.close()
      } else {
        this.$emit('input', v)
      }
    },
    close () {
      this.specialist = null
      this.$v.$reset()
      this.$emit('input', false)
    },
    async connect (): Promise<void> {
      this.$v.$touch()
      if (this.$v.$invalid || !this.seekerReady) return

      this.processing = true
      try {
        await this.sendDataSharingNotes({
          seekerId: this.seeker.SeekerId,
          organizationCode: this.organizationCode,
          specialist: this.specialist?.Login
        })
      } catch (e) {
        const message = this.$errorMessage(e)
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: message
        })
        return
      } finally {
        this.processing = false
      }

      this.$emit('connect')
      this.showGlobalMessage({
        kind: MessageKind.SUCCESS,
        text: this.$t('admin.sharing-seekers.request-note-dialog.connect-success', {
          seeker: this.seekerNameOrEmail,
          specialist: this.specialist?.DisplayName
        })
      })
      this.close()
    }
  }
})
