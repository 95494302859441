






































import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import Lo from 'lodash'
import TabsMixin from './tabs-mixin'
import OrganizationFilter from './organization-filter.vue'
import { Organization } from '@/models/admin'
import { NavigationGuardNext, Route } from 'vue-router'
import { SeekerBasicInfo } from '@/models/users'
import { SharedDataKind } from '@/models/data-sharing'
import { DataTableHeader } from 'vuetify'
import { MessageKind } from '@/helpers/enums'

// Organization kinds for which the data backup function is available
// (because they have a legal obligation to keep medical records)
const suitableOrganizationKinds: Array<string | undefined> = [
  'Academic', 'CareCentre', 'HealthCenter', 'HealthConsultant',
  'HomeService', 'Telehealth'
]

interface BackupSeeker extends SeekerBasicInfo {
  name: string,
  minutesLeftForBackup?: number,
  backupDataKinds: SharedDataKind[],
  gettingDownloadUrl: { [kind in SharedDataKind]: boolean }
}

export default Vue.extend({
  components: {
    'mpro-organization-filter': OrganizationFilter
  },

  mixins: [TabsMixin],

  data: () => ({
    tableSortBy: 'minutesLeftForBackup',
    tableSortDesc: false
  }),

  computed: {
    ...mapState('admin', {
      // eslint-disable-next-line
      organizations: (state: any): Organization[] => Lo.orderBy(state.organizations, o => o.Name.toLocaleLowerCase())
    }),
    organizationCode: {
      get (): string {
        return this.$route.params.organizationCode
      },
      set (value: string) {
        this.$router.replace({
          name: 'admin:BackupSeekerDataForOrganization',
          params: { organizationCode: value }
        })
      }
    },
    organization: function (): Organization | undefined {
      return this.organizationCode == null ? undefined : this.organizations.find(o => o.Code === this.organizationCode)
    },
    isSuitableOrganization: function (): boolean {
      return suitableOrganizationKinds.includes(this.organization?.Kind)
    },
    tableHeaders: function (): DataTableHeader[] {
      return [{
        text: this.$t('admin.backup-seeker-data.seeker').toString(),
        value: 'name',
        sort: (a: string, b: string): number => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
      }, {
        text: this.$t('admin.backup-seeker-data.time-left').toString(),
        value: 'minutesLeftForBackup',
        sort: (a?: number, b?: number): number =>
          a != null && b != null ? a - b : a != null ? -1 : b != null ? 1 : 0
      }, {
        text: this.$t('admin.backup-seeker-data.data-to-backup').toString(),
        value: 'backupDataKinds',
        sortable: false
      }]
    }
  },

  asyncComputed: {
    seekers: {
      async get (): Promise<BackupSeeker[]> {
        if (this.organizationCode == null || !this.isSuitableOrganization) return []

        const result: BackupSeeker[] = await this.loadBackupSeekers({ organizationCode: this.organizationCode })
        result.forEach(s => {
          s.name = s.DisplayName ?? s.Email ?? s.Id
          s.gettingDownloadUrl = { 'Scans': false, 'Scans2D': false, 'Surveys': false, 'GoalsAndExercises': false }
        })
        return Lo.sortBy(result, s => s.name)
      },
      default: []
    }
  },

  methods: {
    ...mapActions(['setActiveTab', 'showGlobalMessage']),
    ...mapActions('admin', ['ensureOrganizationsLoaded', 'loadBackupSeekers', 'loadBackupDownloadUrl']),
    async selectOrganization () {
      await this.ensureOrganizationsLoaded()
      if (this.organizationCode == null) {
        this.organizationCode = this.organizations[0].Code
      } else if (!this.organizations.some(o => o.Code === this.organizationCode)) {
        this.$router.push({ name: 'admin:BackupSeekerDataForOrganization' })
      }
    },
    formatTimeout (timeoutInMinutes: number): string {
      return this.$moment.duration(timeoutInMinutes, 'minutes').humanize()
    },
    async downloadBackup (seeker: BackupSeeker, kind: SharedDataKind) {
      seeker.gettingDownloadUrl[kind] = true
      const downloadUrl = await this.loadBackupDownloadUrl({
        organizationCode: this.organizationCode,
        seekerId: seeker.Id,
        dataKind: kind
      })
      seeker.gettingDownloadUrl[kind] = false

      if (downloadUrl == null) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$t('admin.backup-seeker-data.download-error')
        })
        return
      }

      const a: HTMLAnchorElement = document.createElement('a')
      a.href = downloadUrl
      a.download = ''
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
    }
  },

  beforeRouteEnter (_: Route, __: Route, next: NavigationGuardNext<Vue>) {
    // eslint-disable-next-line
    next((vm: any) => vm.selectOrganization())
  },
  mounted () {
    // Tab names are defined in tabs-mixin
    this.setActiveTab('backup')
  }
})
