<template>
  <v-container class="full-height p-relative" ref="chatContainer" :style="{ maxHeight: `${height}px` }">
    <v-row class="p-absolute pa-3" style="left: 0; top: 0; right: 0; bottom: 0">
      <v-col cols="12" md="3" class="d-none d-md-flex flex-column py-0" style="max-height: 100%">
        <mpro-new-chat-list/>
        <mpro-chat-list class="mt-3" style="flex: 1; overflow-y: auto"
                        :active-login="partner && partner.login"
                        :active-name="partner && partner.name" />
      </v-col>
      <v-col cols="12" md="9" class="pb-0" style="max-height: 100%;" >
        <mpro-single-chat :login="partner && partner.login"
                          :user-name="partner && partner.name"
                          @postmessage="onPostMessage" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ChatList from './chat-list.vue'
import NewChatList from './new-chat.vue'
import SingleChat from './single-chat.vue'

export default {
  components: {
    'mpro-chat-list': ChatList,
    'mpro-single-chat': SingleChat,
    'mpro-new-chat-list': NewChatList
  },

  data: () => ({
    windowHeight: window.innerHeight,
    refreshHandle: undefined
  }),

  computed: {
    ...mapGetters(['isSpecialist', 'isSeeker']),
    ...mapState('chats', ['partners']),
    ...mapGetters('chats', ['getPartnerByUuid', 'getChatUuid']),
    ...mapState('seeker', {
      specialistsForChat: state => state.specialists
    }),
    ...mapState('specialist', {
      seekersForChat: state => state.seekers
    }),
    ...mapGetters('specialist', ['getSeekerByEmail']),
    partner: function () {
      const uuid = this.$route.params.userUuid
      if (uuid == null) return null

      // Mentioning the "partners" property just for auto-recomputation of this property on changes in the partner list
      const partner = this.partners.length > 0
        ? this.getPartnerByUuid(uuid)
        : null
      if (partner != null) {
        return {
          login: partner.Login,
          name: this.isSeeker ? partner.SpecialistName : partner.SeekerName,
          unreadCount: partner.UnreadCount
        }
      }

      // Not an existing partner => search in specialists/seekers available for chat

      if (this.isSeeker) {
        const specialist = this.specialistsForChat.find(s => s.Login != null && this.getChatUuid(s.Login) === uuid)
        return specialist == null
          ? null
          : { login: specialist.Login, name: specialist.DisplayName }
      }

      const seeker = this.seekersForChat.find(s => s.Login != null && this.getChatUuid(s.Login) === uuid)
      return seeker == null
        ? null
        : { login: seeker.Login, name: seeker.DisplayName }
    },
    height: function () {
      let header = document.getElementsByTagName('header').length > 0 ? document.getElementsByTagName('header')[0].clientHeight : 96
      let footer = document.getElementsByTagName('footer').length > 0 ? document.getElementsByTagName('footer')[0].clientHeight : 48
      if (this.$vuetify.breakpoint.name === 'xs') footer = 0
      return this.windowHeight - header - footer
    },
    headerTitle: function () {
      const title = {
        text: this.partner?.name ?? this.$t('chats.general-title')
      }
      if (this.isSpecialist) {
        const seekerId = this.getSeekerByEmail(this.partner?.login)?.Id
        if (seekerId != null) {
          title.link = { name: 'ViewSeeker', params: { seekerId } }
        }
      }
      return title
    }
  },

  watch: {
    partner: function () {
      this.markReadIfNeeded()
    },
    headerTitle: function () {
      this.setHeaderTitle(this.headerTitle)
    }
  },

  methods: {
    ...mapActions(['setHeaderTitle']),
    ...mapMutations('user', ['chatsToggle']),
    ...mapActions('chats', ['ensurePartnersLoaded', 'markRead']),
    ...mapActions('seeker', ['ensureSeekerSpecialistsLoaded']),
    ...mapActions('specialist', ['ensureSpecialistSeekersLoaded']),
    onResize () {
      this.windowHeight = window.innerHeight
      if (this.$refs['chatContainer'] != null) {
        this.$refs['chatContainer'].style.height = this.height
      }
    },
    onPostMessage () {
      this.ensurePartnersLoaded({ force: true })
    },
    async markReadIfNeeded () {
      const partner = this.partner
      if (partner == null) return

      if (partner.unreadCount > 0) {
        await this.$nextTick()
        // Double check that we're still viewing the same chat and it has unread messages
        if (this.partner?.login === partner.login && this.partner.unreadCount > 0) {
          // Mark the chat read and force reloading partners to refresh the unread count
          await this.markRead(partner.login)
          this.ensurePartnersLoaded({ force: true })
        }
      }
    }
  },

  created () {
    if (this.isSeeker) {
      this.ensureSeekerSpecialistsLoaded()
    } else {
      this.ensureSpecialistSeekersLoaded()
    }
  },

  mounted () {
    this.setHeaderTitle(this.headerTitle)

    window.addEventListener('resize', this.onResize)
    this.$nextTick().then(() => this.onResize())

    this.markReadIfNeeded()

    // Refresh the chat list every 10 seconds while viewing the page.
    // Not forcing, so the actual refresh rate is determined
    // by timeouts of the store cache.
    this.refreshHandle = setInterval(() => this.ensurePartnersLoaded(), 10000)
  },

  beforeDestroy () {
    clearInterval(this.refreshHandle)
  }
}
</script>
