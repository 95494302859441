







import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import AccountSeeker from './seeker.vue'
import AccountSpecialist from './specialist.vue'

export default Vue.extend({
  components: {
    'mpro-seeker': AccountSeeker,
    'mpro-specialist': AccountSpecialist
  },

  computed: {
    title: function (): TranslateResult {
      return this.$t('account.title')
    },
    ...mapGetters(['isSeeker', 'isSpecialist', 'isAdministrator'])
  },

  watch: {
    // Title can change when the language setting is changed
    title: function () {
      this.updateTitle()
    }
  },

  methods: {
    updateTitle () {
      this.setHeaderTitle(this.title)
    },
    ...mapActions(['setHeaderTitle'])
  },

  mounted () {
    this.updateTitle()
  }
})
