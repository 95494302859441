var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-start"},[_c('div',{staticClass:"d-flex flex-wrap justify-start ml-6 mt-6"},[_c('mpro-date-box',{attrs:{"label":_vm.$t('admin.statistics.from'),"dense":"","value":_vm.fromDate,"field-class":"mr-6"},on:{"input":_vm.onFromInput}}),_c('mpro-date-box',{attrs:{"label":_vm.$t('admin.statistics.till'),"dense":"","value":_vm.tillDate,"error-messages":_vm.dateRangeErrors,"field-class":"mr-6"},on:{"input":_vm.onTillInput}})],1),_c('div',{staticClass:"mx-6 align-self-stretch"},[_c('v-text-field',{staticClass:"max-text-width",attrs:{"label":_vm.$t('admin.statistics.search'),"clearable":"","prepend-inner-icon":"mdi-filter"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-skeleton-loader',{attrs:{"type":"table-thead,table-tbody","loading":_vm.loading}},[_c('v-data-table',{attrs:{"items":_vm.rows,"item-key":"Code","headers":_vm.headers,"hide-default-footer":"","disable-pagination":"","no-data-text":_vm.$t('admin.no_records'),"search":_vm.search,"no-results-text":_vm.$t('forms.nothing_found')},scopedSlots:_vm._u([{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.Name))]),_c('div',{staticClass:"grey--text"},[_vm._v(_vm._s(item.Code))])]}},{key:"item.IsPaying",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"w100pc text-center"},[(value)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()],1)]}},{key:"item.RegisteredSpecialists",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"w100pc text-end"},[_vm._v(_vm._s(value))])]}},{key:"item.ActiveSpecialists",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"w100pc text-end"},[_vm._v(_vm._s(value))])]}},{key:"item.AcceptedSeekers",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"w100pc text-end"},[_vm._v(_vm._s(value))])]}},{key:"item.WidgetClicks",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w100pc text-end"},[_vm._v(_vm._s(item.WidgetClicks)+" / "+_vm._s(item.ConnectionRequests))])]}},{key:"item.ScanCount",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('div',{staticClass:"w100pc text-end"},[_c('router-link',{attrs:{"to":_vm.getScanListRoute(item.Code)}},[_vm._v(_vm._s(value))])],1)]}},{key:"item.LastScanCapturedAt",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(_vm._s(_vm.$formatDateTime(value)))]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }