import axios from 'axios'

const state = {
  seekerGoals: [],
  goalsLoadedAt: null
}

// getters
const getters = {
  getGoalsBySeekerId: state => seekerId => {
    return state.seekerGoals.filter(s => s.SeekerId === seekerId)
  }
}

// actions
const actions = {
  async postGoal (_, goal) {
    const response = await axios.post('/goals', goal)
    return response.data
  },
  putGoal (_, goal) {
    return axios.put(`/goals/${encodeURIComponent(goal.Id)}`, goal)
  },
  async ensureSeekerGoalsLoaded ({ state, commit }, payload) {
    const { seekerId, force } = payload
    if (state.seekerGoals.seekerId && state.goalsLoadedAt && (Date.now() - state.goalsLoadedAt < process.env.SCAN_LIFETIME) && !force) {
      return
    }

    let response = null
    try {
      response = await axios.get('/goals', {
        params: {
          'seeker-id': seekerId
        }
      })
    } catch (e) {
      console.warn('ensureSeekerGoalsLoaded:axios', e)
      return
    }

    if (Array.isArray(response.data)) {
      commit('storeSeekerGoals', response.data)
    }
  },
  setGoalDeleted (context, goalId) {
    return axios.delete(`/goals/${encodeURIComponent(goalId)}`)
  },
  setGoalCompleted (context, goalId) {
    return axios.put(`/goals/${encodeURIComponent(goalId)}/completed`)
  }
}

// mutations
const mutations = {
  storeSeekerGoals: (state, goals) => {
    goals.forEach((goal) => {
      const exist = state.seekerGoals.findIndex(s => s.Id === goal.Id)
      if (exist === -1) state.seekerGoals.push(goal)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
