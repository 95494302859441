<template>
  <div>
    <v-btn @click="openDialog()" class="MProCoral mb-10" large>{{ $t('programs.edit.buttons.new-program') }}</v-btn>
    <v-dialog v-model="dialog" :width="programDetails ? '800' : '1500'" :fullscreen="$vuetify.breakpoint.name === 'xs'">
       <v-card class="MPro-bg">
        <v-card-title>
          <div class="flex-grow-1 d-flex justify-space-between">
            <div>
              <span v-if="seeker">
                {{ $t('programs.assign-program', {name: (seeker.DisplayName || seeker.Username)}) }}
              </span>
            </div>
            <v-btn icon @click="dialog = !dialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <mpro-program-details  v-if="programDetails"
                                :program="program"
                                @program="program"
                                @resetProgram="resetProgram()"
                                :programDetails="programDetails"
                                @programDetails="programDetails = !programDetails, selectExercises = !selectExercises"
                                :seeker="seeker">
        </mpro-program-details>
        <mpro-exercise-selector v-if="selectExercises"
                                :assignedExercises="assignedExercises"
                                @update="updateExercises"
                                @saveExercises="checkProgram()"
                                @back="programDetails = true, selectExercises = false">
        </mpro-exercise-selector>
        <v-card-text v-if="checkingProgram">
          <v-container fluid class="pa-0 ma-0">
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="4">
                <h3>{{ $t('programs.program-details') }} <v-btn icon class="mr-3" @click="programDetails = true, selectExercises = false, checkingProgram = false"><v-icon>mdi-pencil-outline</v-icon></v-btn></h3>
                <p>{{ $formatDate(program.StartDate) }} - {{ $formatDate(programEndDate) }}<br>
                {{ $tc('programs.num-of-exercises', assignedExercises.length, {num: assignedExercises.length}) }}<br>
                {{ $t('programs.times-per-day', {num: program.FrequencyPerDay}) }}<br>
                <span v-if="program.SessionRepeat !== 'Custom'">{{ $t('programs.edit.fields.repeat') }} {{ $t('programs.edit.fields.repeat-' + program.SessionRepeat) }}</span><br>
                <span v-if="program.SessionRepeat === 'Custom'">{{ $t('programs.edit.fields.repeat') }} </span><span v-for="(day) in program.days" :key="day">{{$t('general.weekdays.' + day.toLowerCase())}}</span></p>
                <p>{{ $t('programs.edit.fields.notes') }}</p>
                <mpro-read-more v-if="program.Notes" :text="program.Notes" :charCount="100"></mpro-read-more>
                <p v-else>-</p>
              </v-col>
              <v-col cols="12" md="8" style="max-height: 50%;">
                <v-container class="pa-0 ma-0">
                  <h2>{{ $tc('programs.num-of-exercises-selected', assignedExercises.length, {num: assignedExercises.length}) }}<v-btn icon class="mr-3" @click="programDetails = false, selectExercises = true, checkingProgram = false"><v-icon>mdi-pencil-outline</v-icon></v-btn></h2>
                  <v-row class="pa-0 ma-0 align-center" v-for="(exercise) in assignedExercises" :key="exercise.ExerciseId">
                    <v-col cols="12" md="4">
                        <img :src="getExerciseThumbnail(exercise)" style="max-width:100%; width:100%"/>
                        <p class="text-center text-lg-center pa-0 ma-0">{{getExerciseMetadata(exercise).Name}}</p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <p>
                        <v-icon>mdi-sync</v-icon>{{$t('exercises.edit.fields.sets')}}: {{ exercise.Sets }}x<br>
                        <v-icon>mdi-repeat</v-icon>{{$t('exercises.edit.fields.repetitions')}}: {{ exercise.Repetitions }}x<br>
                        <v-icon>mdi-clock-outline</v-icon>{{$t('exercises.edit.fields.duration')}}: {{ Math.floor(exercise.DurationSeconds / 60 ) }}m {{ exercise.DurationSeconds % 60 }}s<br>
                        <v-icon>mdi-pause-circle-outline</v-icon>{{$t('exercises.edit.fields.rest')}}: {{ Math.floor(exercise.RestSeconds / 60 ) }}m {{ exercise.RestSeconds % 60 }}s<br>
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <p>
                        {{$t('exercises.edit.fields.resistance')}}: {{ exercise.Resistance }}<br>
                        {{$t('exercises.edit.fields.exertion')}}: {{ exercise.Exertion }}<br>
                        {{$t('exercises.edit.fields.alternation')}}: {{ exercise.Alternation ? $t(`exercises.edit.fields.alternation-${exercise.Alternation}`) : '-'}}<br>
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-btn class="float-right MProCoral" @click="sureDialog=!sureDialog, saveDialog=!saveDialog"><v-icon>mdi-save</v-icon>{{$t('programs.edit.buttons.assign')}}</v-btn>
            <v-btn class="float-right mr-3" @click="sureDialog=!sureDialog">{{$t('forms.discard')}}</v-btn>
          </v-container>
        </v-card-text>
      </v-card>
      <v-dialog v-model="sureDialog" width="400">
        <v-card class="MPro-bg">
          <v-card-title>
            {{saveDialog ? $t('programs.assign-sure-title') : $t('programs.discard-sure')}}
          </v-card-title>
          <v-card-text v-if="saveDialog">
            {{ $t('programs.assign-sure-message')}}
          </v-card-text>
          <v-card-actions class="float-right">
            <v-btn v-if="saveDialog" class="float-right mr-3" @click="sureDialog=!sureDialog, saveDialog=!saveDialog">{{$t('forms.no')}}</v-btn>
            <v-btn v-else class="float-right mr-3" @click="resetProgram()" :loading="loading">{{$t('forms.yes')}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="saveDialog" class="float-right mr-3  MProCoral" @click="assignProgram()"  :loading="loading">{{$t('forms.yes')}}</v-btn>
            <v-btn v-else class="float-right mr-3  MProCoral" @click="sureDialog=!sureDialog">{{$t('forms.no')}}</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
// import DateBox from '../elements/date-box'
import programDetail from './program-details'
import exerciseSelector from './exercise-selection'
import Utils from '@/helpers/utils'
import { DaysOfTheWeek, MessageKind } from '../../helpers/enums'
import readMore from './../elements/read-more'
import ProgramAnalyzer from './program-analyzer'

export default {
  components: {
    // 'mpro-date-box': DateBox,
    'mpro-program-details': programDetail,
    'mpro-exercise-selector': exerciseSelector,
    'mpro-read-more': readMore
  },

  props: ['seekerId'],

  data: () => {
    return {
      dialog: false,
      sureDialog: false,
      saveDialog: false,
      assigningProgram: false,
      checkingProgram: false,
      alternation: ['left-only', 'right-only', 'left-first', 'right-first'],
      repeatProgram: ['Daily', 'EveryOtherDay', 'Weekly', 'Custom'],
      menu: false,
      panel: [0],
      programDetails: false,
      selectExercises: false,
      assignedExercises: [],
      loading: false,
      program: null
    }
  },

  computed: {
    ...mapGetters('exercises', ['getAllExercises', 'getAssignedPrograms']),
    ...mapGetters('specialist', ['getSeekerById']),
    ...mapGetters({
      'exerciseChar': 'exercises/getSingleAssignedExercise'
    }),
    seeker: function () {
      return this.getSeekerById(this.seekerId)
    },
    resistance: function () {
      return [{'val': 'none'}, {'val': 'light'}, {'val': 'moderate'}, {'val': 'heavy'}]
    },
    WeekDaysMask: function () {
      let mask = 0
      if (this.program.SessionRepeat === 'Custom') {
        Object.keys(DaysOfTheWeek).forEach((day, index) => {
          console.log(index, day)
          if (this.program.days.includes(DaysOfTheWeek[day])) {
            console.log('included')
            mask += (1 << index)
          }
        })
        return mask
      } else if (this.program.SessionRepeat === 'Weekly') {
        return 1 << this.$moment(this.program.StartDate).day()
      } else if (this.program.SessionRepeat === 'EveryOtherDay') {
        return 85
      } else if (this.program.SessionRepeat === 'Daily') {
        return 127
      }
      return mask
    },
    programEndDate: function () {
      return ProgramAnalyzer.getEndDate(this.program)
    }
  },

  watch: {
    dialog: function () {
      if (this.dialog) {
        this.ensureAllExercisesLoaded()
        this.ensureSeekerProgramsLoaded({ seekerId: this.seekerId })

        this.programDetails = true
        this.selectExercises = false
        this.checkingProgram = false
      } else {
        this.programDetails = false
      }
    },
    sureDialog: function () {
      if (!this.sureDialog) this.saveDialog = false
    }
  },

  methods: {
    ...mapActions('exercises', ['ensureAllExercisesLoaded', 'ensureSeekerProgramsLoaded', 'apiAssignProgram']),
    ...mapActions('specialist', ['ensureAssignedProgramsLoaded']),
    ...mapMutations('specialist', ['updateLastAssignedProgramAt']),
    ...mapActions(['showGlobalMessage']),
    openDialog () {
      this.resetProgram(false)
      this.dialog = true
    },
    updateExercises (exercises) {
      this.assignedExercises = exercises.map((e, index) => ({ ...e, Num: index + 1 }))
    },
    resistanceText (item) {
      return this.$t(`exercises.edit.fields.resistance-${item.val}`)
    },
    getExerciseMetadata (exercise) {
      return this.getAllExercises.find(e => e.Id === exercise.ExerciseId)
    },
    getExerciseThumbnail (exercise) {
      const thumbnails = this.getExerciseMetadata(exercise).thumbnails
      return thumbnails == null
        ? null
        : thumbnails[0].sizes[3].link.replace('?r=pad', '')
    },
    getExerciseTitle (exercise) {
      const metadata = this.getExerciseMetadata(exercise)
      return metadata ? metadata.Name : this.$t('exercises.assignment.exercise.default-title')
    },
    checkProgram () {
      this.programDetails = false
      this.selectExercises = false
      this.checkingProgram = true
    },
    async assignProgram () {
      this.loading = true
      if (this.assignedExercises[this.assignedExercises.length - 1].ExerciseId === 0) this.assignedExercises.pop()
      let sendProgram = {
        SeekerId: this.seekerId,
        Notes: this.program.Notes,
        FrequencyPerDay: this.program.FrequencyPerDay,
        StartDate: Utils.toIsoDate(this.program.StartDate),
        DurationWeeks: this.program.DurationWeeks,
        WeekDaysMask: this.WeekDaysMask,
        Exercises: this.assignedExercises,
        SessionRepeat: this.program.SessionRepeat
      }
      try {
        await this.apiAssignProgram(sendProgram)
      } catch (e) {
        this.showGlobalMessage({
          text: this.$errorMessage(e),
          kind: MessageKind.ERROR
        })
        return
      } finally {
        this.loading = false
      }

      this.resetProgram()
      this.showGlobalMessage({
        text: this.$t('programs.edit.messages.message-success'),
        kind: MessageKind.SUCCESS
      })

      this.ensureAssignedProgramsLoaded({ force: true })
      this.updateLastAssignedProgramAt(this.seekerId)

      this.$emit('update')
    },
    async resetProgram (reload = true) {
      this.assignedExercises = []
      this.programDetails = false
      this.selectExercises = false
      this.dialog = false
      this.program = {
        StartDate: new Date(),
        DurationWeeks: null,
        FrequencyPerDay: null,
        SessionRepeat: 'Daily',
        days: [],
        Notes: null
      }

      if (reload) {
        this.loading = true
        await this.ensureSeekerProgramsLoaded({ seekerId: this.seekerId, force: true })
        this.loading = false
      }
    }
  },

  mounted () {
    this.program = {
      StartDate: new Date(),
      DurationWeeks: null,
      FrequencyPerDay: null,
      SessionRepeat: 'Daily',
      days: [],
      Notes: null
    }
  }
}
</script>

<style scoped>
iframe {
  width: 100%;
  height: 100%;
  min-height: 200px;
  max-width: 100%;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.exercise-container {
  flex-grow: 1;
  overflow: auto;
}
.tezt{
  z-index: 99999
}
</style>
