<template>
  <div>
    <v-tabs-items :value="activeTab">
      <v-tab-item :value="tabNames[0]">
        <mpro-details-tab class="d-inline-block"
                          :organization="organization"
                          :is-visible="activeTab === tabNames[0]"
                          @update="refreshOrganization"/>
      </v-tab-item>

      <v-tab-item :value="tabNames[1]">
        <mpro-settings-table :settings="settings"
                             :organization-code="code"
                             @update="refreshOrganization"/>
      </v-tab-item>

      <v-tab-item :value="tabNames[2]">
        <div class="d-flex">
          <v-skeleton-loader type="table-thead, table-tbody" :loading="loading">
            <mpro-organizations-table :organizations="slaves"/>
          </v-skeleton-loader>
        </div>
      </v-tab-item>

      <v-tab-item :value="tabNames[3]">
        <mpro-libraries-tab :organization-code="code"
                            :libraries="libraries"/>
      </v-tab-item>

      <v-tab-item :value="tabNames[4]">
        <mpro-specialists-tab :organization-code="code"
                              :specialists="specialists"/>
      </v-tab-item>

      <v-tab-item :value="tabNames[5]">
        <mpro-rights-tab :organization-code="code"
                         :rights="rights"
                         @update="refreshOrganization"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import DetailsTab from './details-tab'
import SettingsTable from '../settings/table'
import OrganizationsTable from './table'
import LibrariesTab from './libraries-tab'
import SpecialistsTab from './specialists-tab'
import RightsTab from './rights-tab'

export default {
  components: {
    'mpro-details-tab': DetailsTab,
    'mpro-settings-table': SettingsTable,
    'mpro-organizations-table': OrganizationsTable,
    'mpro-libraries-tab': LibrariesTab,
    'mpro-specialists-tab': SpecialistsTab,
    'mpro-rights-tab': RightsTab
  },

  data: () => ({
    organization: null
  }),

  computed: {
    tabNames: function () {
      return ['details', 'settings', 'slaves', 'libraries', 'specialists', 'rights']
    },
    tabIcons: function () {
      return ['mdi-card-text-outline', 'mdi-cog-outline', 'mdi-file-tree', 'mdi-library-shelves', 'mdi-account-multiple-outline', 'mdi-security']
    },
    code: function () {
      return this.$route.params.code
    },
    loading: function () {
      return this.organization == null
    },
    settings: function () {
      return this.organization?.settings ?? []
    },
    slaves: function () {
      return this.organization?.slaveOrganizations ?? []
    },
    libraries: function () {
      return this.organization?.libraries ?? []
    },
    specialists: function () {
      return this.organization?.specialists ?? []
    },
    rights: function () {
      return this.organization?.rights ?? []
    },
    ...mapState(['activeTab'])
  },

  watch: {
    code: function () {
      this.onCodeChanged()
    },
    '$route.params.activeTab': function () {
      this.updateActiveTab()
    }
  },

  methods: {
    async onCodeChanged () {
      this.organization = null
      this.updateHeaderTitle()
      await this.refreshOrganization()
      if (this.organization == null) {
        this.$router.push({name: 'admin:Organizations'})
      }
    },
    async refreshOrganization () {
      if (this.code != null) {
        this.organization = await this.loadOrganization(this.code)
        if (this.organization?.Kind != null) {
          this.updateHeaderTitle()
        } else {
          // User isn't an admin of an organization
          this.$router.back()
        }
      }
    },
    updateHeaderTitle () {
      const name = this.organization?.Name ?? this.code
      this.setHeaderTitle(this.$t('admin.organization.title', {name}))
    },
    ensureTabRoute (route) {
      const isValidTabName = route.name === 'admin:OrganizationTab' &&
        this.tabNames.includes(route.params.activeTab)
      if (!isValidTabName) {
        this.$router.replace({
          name: 'admin:OrganizationTab',
          params: {
            code: this.code,
            activeTab: this.tabNames[0]
          }
        })
      }
    },
    updateActiveTab () {
      this.setActiveTab(this.$route.params.activeTab)
    },
    ...mapActions(['setHeaderTitle', 'setHeaderTabs']),
    ...mapMutations(['setActiveTab']),
    ...mapActions('admin', ['loadOrganization'])
  },

  created () {
    this.onCodeChanged()
  },
  mounted () {
    let tabs = this.tabNames.map((t, index) => ({
      value: t,
      to: {
        name: 'admin:OrganizationTab',
        params: {
          code: this.code,
          activeTab: t
        }
      },
      title: this.$t(`admin.organization.tabs.${t}`),
      icon: this.tabIcons[index]
    }))
    this.setHeaderTabs({tabs})
    this.updateActiveTab()
  },
  beforeDestroy () {
    this.setHeaderTabs()
  },
  beforeRouteEnter (to, __, next) {
    next(vm => vm.ensureTabRoute(to))
  },
  beforeRouteUpdate (to, _, next) {
    next()
    this.ensureTabRoute(to)
  }
}
</script>
