<template>
  <div class="v-label mt-1 label-scale "
       :class="active ? 'primary--text' : null">{{
    value
  }}</div>
</template>

<script>
export default {
  props: {
    value: String,
    active: { type: Boolean, default: false }
  }
}
</script>

<style scoped>
.label-scale {
  transform: scale(0.75);
  transform-origin: left;
  margin-right: -33%;
}
</style>
