<template>
  <table>
    <tr v-if="questionType">
      <th class="bullet"/>
      <th class="question"/>
      <th v-for="v in questionType.values" :key="v.Value">
        <div v-if="questionType.Show.includes('Value')" class="value">{{v.Value}}</div>
        <div v-if="questionType.Show.includes('Name')" class="name">{{v.Name}}</div>
      </th>
    </tr>
    <tr v-for="(q, i) in questions" :key="q.Code"
        :class="errors[i] ? 'error' : null">
      <th class="bullet">{{String.fromCharCode('a'.charCodeAt(0) + i)}}.</th>
      <th class="question">{{q.Text}}</th>
      <td v-for="v in questionType.values" :key="v.Value"
          class="answer"
          :class="{selected: isActualAnswer(v.Value, i), clickable: !readonly}"
          @click="onAnswerClick(v.Value, i)">
        <span v-if="isActualAnswer(v.Value, i)">&#x2713;</span>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    questions: {type: Array, required: true, default: () => []},
    answers: {type: Array, required: true, default: () => []},
    errors: {type: Array, default: () => []},
    readonly: {type: Boolean, default: () => false}
  },

  model: {
    prop: 'answers'
  },

  computed: {
    questionType: function () {
      return this.questions.length > 0 ? this.questions[0] : null
    }
  },

  methods: {
    isActualAnswer (value, index) {
      return this.answers.length > index && this.answers[index] === value
    },
    onAnswerClick (value, index) {
      if (this.readonly) return

      let values = this.answers.slice()
      while (values.length <= index) values.push(undefined)
      values[index] = value
      this.$emit('input', values)
    }
  }
}
</script>

<style scoped>
table {
  border-collapse: collapse;
}
tr {
  border-bottom: 1px gray solid;
}
tr.error {
  background: none !important;
  border-color: gray !important;
}
tr.error th.bullet {
  border-left: 2px red solid;
}
td, th {
  padding: 0.25em 0.5em;
  border-right: 1px gray solid;
  vertical-align: baseline;
}
th.bullet {
  padding-right: 0.5ex;
  border-right: none;
  text-align: right;
}
th.question {
  padding-left: 0;
}
td.answer {
  text-align: center;
  vertical-align: middle;
}
td.answer.clickable:hover {
  border: 2px #00E693 dashed;
}
td.selected {
  color: #00E693;
  border: 2px #00E693 solid;
}
</style>
