<template>
  <div class="d-flex flex-column align-start">
    <div class="d-flex flex-wrap justify-start ml-6 mt-6">
      <mpro-date-box :label="$t('admin.statistics.from')"
                     :value="fromDate" @input="onFromInput"
                     field-class="mr-6 mb-6"/>
      <mpro-date-box :label="$t('admin.statistics.till')"
                     :value="tillDate" @input="onTillInput"
                     :error-messages="dateRangeErrors"
                     field-class="mr-6 mb-6"/>
    </div>

    <v-skeleton-loader type="table-thead, table-tbody"
                       :loading="loading">
      <v-data-table :items="items" :headers="headers"
                    hide-default-footer disable-pagination
                    :no-data-text="$t('forms.nothing_found')">
        <template #item.CapturedAt="{value}">
          {{$formatDateTime(value)}}
        </template>
        <template #item.SeekerUsername="{value, item}">
          <div>{{value}}</div>
          <div v-if="item.SeekerName" class="grey--text">{{item.SeekerName}}</div>
        </template>
        <template #item.SpecialistLogin="{value, item}">
          <div>{{value}}</div>
          <div v-if="item.SpecialistName" class="grey--text">{{item.SpecialistName}}</div>
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </div>
</template>

<script>
import DateBox from '@/components/elements/date-box'
import Utils from '@/helpers/utils'
import { mapActions } from 'vuex'

export default {
  components: {
    'mpro-date-box': DateBox
  },

  data: () => ({
    loading: false
  }),

  computed: {
    fromDate: function () {
      const moment = this.$moment(this.$route.query.from, 'YYYY-MM-DD', true)
      return moment.isValid() ? moment.toDate() : null
    },
    tillDate: function () {
      const moment = this.$moment(this.$route.query.till, 'YYYY-MM-DD', true)
      return moment.isValid() ? moment.toDate() : null
    },
    dateRangeErrors: function () {
      let result = []
      if (this.tillDate < this.fromDate) {
        result.push(this.$t('forms.validations.emptyDateRange'))
      }
      return result
    },
    organizationCode: function () {
      return this.$route.query.organization
    },
    headers: function () {
      return [
        { value: 'CapturedAt', text: this.$t('admin.scan_list.captured_at') },
        { value: 'LibraryName', text: this.$t('admin.scan_list.library') },
        { value: 'SeekerUsername', text: this.$t('admin.scan_list.seeker') },
        { value: 'SpecialistLogin', text: this.$t('admin.scan_list.specialist') },
        { value: 'SoftwareVersion', text: this.$t('admin.scan_list.software_version') }
      ]
    },
    items: function () {
      return this.scans || []
    }
  },

  asyncComputed: {
    organizationName: async function () {
      if (!this.organizationCode) return null
      const organization = await this.loadOrganization(this.organizationCode)
      return organization && organization.Name
    },
    scans: async function () {
      if (!this.organizationCode || !this.fromDate || !this.tillDate) return []

      this.loading = true
      try {
        return await this.loadScanList({
          organizationCode: this.organizationCode,
          from: Utils.toIsoDate(this.fromDate),
          till: Utils.toIsoDate(this.tillDate)
        })
      } finally {
        this.loading = false
      }
    }
  },

  watch: {
    organizationName: function () {
      this.updateHeaderTitle()
    }
  },

  methods: {
    ensureValidRouteQuery () {
      if (this.organizationCode == null) {
        this.$router.back()
      } else if (!this.fromDate || !this.tillDate) {
        // reset to default period last ~30 days
        const today = this.$moment()
        const from = Utils.toIsoDate(this.$moment(today).subtract(1, 'months'))
        const till = Utils.toIsoDate(today)
        const route = this.createRoute(from, till, this.organizationCode)
        this.$router.replace(route)
      }
    },
    onFromInput (value) {
      const newDate = Utils.toIsoDate(value)
      if (newDate === Utils.toIsoDate(this.fromDate)) return
      const route = this.createRoute(newDate, this.$route.query.till, this.organizationCode)
      this.$router.push(route)
    },
    onTillInput (value) {
      const newDate = Utils.toIsoDate(value)
      if (newDate === Utils.toIsoDate(this.tillDate)) return
      const route = this.createRoute(this.$route.query.from, newDate, this.organizationCode)
      this.$router.push(route)
    },
    createRoute (from, till, organization) {
      return {
        name: 'admin:ScanList',
        query: { from, till, organization }
      }
    },
    updateHeaderTitle () {
      const title = this.$t('admin.scan_list_title', {name: this.organizationName || this.organizationCode})
      this.setHeaderTitle(title)
    },
    ...mapActions(['setHeaderTitle']),
    ...mapActions('admin', ['loadOrganization', 'loadScanList'])
  },

  beforeRouteEnter (to, from, next) {
    next(vm => vm.ensureValidRouteQuery())
  },

  mounted () {
    this.updateHeaderTitle()
  }
}
</script>
