import axios from 'axios'
import moment from 'moment'
import Cache from 'ttl-cache'
import { ScanType } from '../../helpers/enums'
import Utils from '@/helpers/utils'

const requestCache = new Cache({
  ttl: process.env.VUE_APP_DEFAULT_API_RESPONSE_LIFETIME / 1000,
  interval: 0
})

// initial state
const state = {
  libraries: [],
  scans: [],
  seekers: [],
  sharedDataKinds: {},
  surveyAssignments: [],
  assignedPrograms: [],
  seekerList: {
    search: '',
    libraries: [],
    status: ''
  }
}

// getters
const getters = {
  getScanById: state => scanId =>
    state.scans.find(s => s.Id === scanId),
  getSeekerById: state => seekerId =>
    state.seekers.find(s => s.Id === seekerId),
  getSeekerByEmail: state => seekerEmail =>
    state.seekers.find(s => s.Email === seekerEmail),
  getSeekerScansById: state => seekerId =>
    state.scans.filter(s => s.SeekerId === seekerId)
}

async function ensureArrayLoaded ({ actionName, requestUrl, force, commit, mutationName }) {
  if (requestCache.get(requestUrl) != null && !force) return

  let response
  try {
    response = await axios.get(requestUrl)
  } catch (e) {
    console.warn(`${actionName}:axios`, e)
    return
  }

  if (Array.isArray(response.data)) {
    commit(mutationName, response.data)
  }

  requestCache.set(requestUrl, true)
}

// actions
const actions = {
  ensureSpecialistLibrariesLoaded: async ({ commit }) => {
    ensureArrayLoaded({
      actionName: 'ensureSpecialistLibrariesLoaded',
      requestUrl: '/scans/libraries',
      force: false,
      commit,
      mutationName: 'storeLibraries'
    })
  },

  ensureSpecialistSeekersLoaded: async ({ commit }, force = false) => {
    ensureArrayLoaded({
      actionName: 'ensureSpecialistSeekersLoaded',
      requestUrl: '/seekers',
      force,
      commit,
      mutationName: 'storeSeekers'
    })
  },

  ensureSpecialistScansLoaded: async ({ commit }, force = false) => {
    const request2D = '/scans2d/accessible-to-specialist'
    const request3D = '/scans/library_scans'
    const cacheKey = `${request2D}\n${request3D}`
    if (requestCache.get(cacheKey) != null && !force) return

    let result = []
    let response2D
    try {
      response2D = await axios.get(request2D)
    } catch (e) {
      console.warn('ensureSpecialistScansLoaded:axios', e)
      return
    }

    if (Array.isArray(response2D.data)) {
      response2D.data.forEach(scan => {
        scan.ScanType = ScanType.MOBILE
        result.push(scan)
      })
    }

    let response3D = null
    try {
      response3D = await axios.get(request3D)
    } catch (e) {
      console.warn('ensureSpecialistScansLoaded:axios', e)
      return
    }

    if (Array.isArray(response3D.data)) {
      response3D.data.forEach(scan => {
        scan.ScanType = ScanType.DEPTH
        result.push(scan)
      })
    }

    result.sort((s1, s2) => moment.parseZone(s2.CapturedAt).diff(moment.parseZone(s1.CapturedAt)))
    commit('storeScans', result)

    requestCache.set(cacheKey, true)
  },

  loadRelatedSeekers: async (context, seekerId) => {
    let result
    try {
      result = await axios.get(`/seekers/${encodeURIComponent(seekerId)}/related-seekers`)
      return result.data
    } catch (e) {
      console.warn('loadRelatedSeeker:axios', e)
      return {}
    }
  },

  getOrLoadSharedDataKinds: async ({ state, commit }, seekerId) => {
    const requestUrl = `/seekers/${encodeURIComponent(seekerId)}/shared-data-kinds`
    let result = state.sharedDataKinds[seekerId]
    if (result == null || requestCache.get(requestUrl) == null) {
      let response
      try {
        response = await axios.get(requestUrl)
      } catch (e) {
        console.warn('getOrLoadSharedDataKinds:axios', e)
        return result ?? []
      }

      if (Array.isArray(response.data)) {
        result = response.data
        commit('updateSharedDataKinds', { seekerId, dataKinds: result })
      } else {
        result = []
      }

      requestCache.set(requestUrl, true)
    }
    return result
  },

  async ensureSurveyAssignmentsLoaded ({ commit }, options) {
    const force = options?.force ?? false
    ensureArrayLoaded({
      actionName: 'ensureSurveyAssignmentsLoaded',
      requestUrl: '/surveys/assigned-by-me',
      force,
      commit,
      mutationName: 'storeSurveyAssignments'
    })
  },

  async ensureAssignedProgramsLoaded ({ commit }, options) {
    const force = options?.force ?? false
    ensureArrayLoaded({
      actionName: 'ensureAssignedProgramsLoaded',
      requestUrl: '/exercises/programs-assigned-by-me',
      force,
      commit,
      mutationName: 'storeAssignedPrograms'
    })
  }
}

// mutations
const mutations = {
  storeScans (state, scans) {
    state.scans = scans
  },
  markScanViewed (state, scanId) {
    const scan = state.scans.find(s => s.Id === scanId)
    if (scan != null) scan.ViewedBySpecialist = true
  },
  storeSeekers (state, seekers) {
    state.seekers = seekers
    state.sharedDataKinds = {}
  },
  updateSharedDataKinds (state, { seekerId, dataKinds }) {
    state.sharedDataKinds[seekerId] = dataKinds
  },
  updateLastChattedToAt (state, seekerLogin) {
    const value = Utils.toIsoDateTimeOffset(moment())
    state.seekers.filter(s => (s.Login ?? s.SuperiorLogin) === seekerLogin)
      .forEach(s => {
        s.LastChattedToAt = value
      })
  },
  updateLastAssignedProgramAt (state, seekerId) {
    const seeker = state.seekers.find(s => s.Id === seekerId)
    if (seeker != null) {
      seeker.LastProgramAssignedAt = Utils.toIsoDateTimeOffset(moment())
    }
  },
  updateLastAssignedSurveyAt (state, seekerId) {
    const seeker = state.seekers.find(s => s.Id === seekerId)
    if (seeker != null) {
      seeker.LastAssignedSurveyAt = Utils.toIsoDateTimeOffset(moment())
    }
  },
  updateLastCommentedScanAt (state, seekerId) {
    const seeker = state.seekers.find(s => s.Id === seekerId)
    if (seeker != null) {
      seeker.LastCommentedScanAt = Utils.toIsoDateTimeOffset(moment())
    }
  },
  storeLibraries (state, libraries) {
    state.libraries = libraries
  },
  storeSurveyAssignments (state, assignments) {
    state.surveyAssignments = assignments
  },
  storeAssignedPrograms (state, programs) {
    state.assignedPrograms = programs
  },
  updateSeekerList (state, payload) {
    state.seekerList = {...state.seekerList, ...payload}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
