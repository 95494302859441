<template>
  <v-text-field :value="value" @input="onInput"
                :readonly="readonly" :label="label"
                :hint="hint" persistent-hint
                :rules="validationRules" :type="type" />
</template>

<script>
import {
  required as vRequired,
  minLength as vMinLength,
  maxLength as vMaxLength,
  email as vEmail
} from 'vuelidate/lib/validators'

export default {
  props: {
    value: String,
    required: { type: Boolean, default: false },
    minLength: Number,
    maxLength: Number,
    type: { type: String, default: 'text' },
    readonly: { type: Boolean, default: true },
    label: String,
    hint: String
  },

  computed: {
    validationRules: function () {
      const rules = []
      if (this.required) {
        rules.push(v => vRequired(v) ? true : this.$t('forms.validations.required'))
      }
      if (this.minLength > 0) {
        rules.push(v => vMinLength(this.minLength)(v) ? true : this.$t('forms.validations.minLength', { min: this.minLength }))
      }
      if (this.maxLength > 0) {
        rules.push(v => vMaxLength(this.maxLength)(v) ? true : this.$t('forms.validations.maxLength', { max: this.maxLength }))
      }
      if (this.type === 'email') {
        rules.push(v => vEmail(v) ? true : this.$t('forms.validations.email'))
      }
      return rules
    }
  },

  methods: {
    onInput (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
