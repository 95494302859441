<template>
  <mpro-video-viewer ref="viewer"
                     :video-source="videoSource" :loading-video="loadingVideo"
                     :frame-time-stamps="frameTimeStamps"
                     :show-video="showVideo"
                     @render="render">
    <template #overlay>
      <div v-if="isSpecialist" class="p-absolute" style="right: 15px; bottom: 15px">
        <v-menu :close-on-content-click="false" :close-on-click="true"
                :offset-y="true" :left="true" :top="true"
                class="MPro-bg">
          <template #activator="{ on, attrs }" class="MPro-bg">
            <v-btn v-bind="attrs" v-on="on"
                   class="MProMainAlt" fab style="border-radius: 10px;">
              <v-icon>mdi-layers-outline</v-icon>
            </v-btn>
          </template>
          <div class="d-flex flex-column MPro-bg">
            <div class="ma-2">
              <v-btn-toggle dense multiple outlined color="primary" class="ml-1 flex-column d-block"
                            v-model="visibilityToggles">
                <v-btn block outlined value="video">{{ $t('scans.buttons.toggle-video') }}</v-btn>
                <v-btn block outlined value="tracking">{{ $t('scans.buttons.toggle-tracking-points') }}</v-btn>
              </v-btn-toggle>
            </div>
          </div>
        </v-menu>
      </div>
    </template>
  </mpro-video-viewer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VideoViewer from './video-viewer'
import { parseRawTrackingCsv } from './data-parsing'

export default {
  components: {
    'mpro-video-viewer': VideoViewer
  },

  props: {
    recording: { type: Object, default: null }
  },

  data: () => ({
    loadingVideo: false,
    videoSource: null,
    trackingPoints: [],
    visibilityToggles: ['video']
  }),

  computed: {
    frameTimeStamps: function () {
      return this.trackingPoints.map(p => p.timeStamp)
    },
    showVideo: function () {
      return this.visibilityToggles.includes('video')
    },
    showTrackingPoints: function () {
      return this.visibilityToggles.includes('tracking')
    },
    ...mapGetters(['isSpecialist'])
  },

  watch: {
    recording: function () {
      this.onRecordingChanged()
    }
  },

  methods: {
    async onRecordingChanged () {
      this.videoSource = null
      this.trackingPoints = []

      this.loadingVideo = true

      this.videoSource = await this.getOrLoadRecordingVideoUri(this.recording.Id)

      const dataRaw = await this.loadRecordingRawTracking(this.recording.Id)
      this.trackingPoints = parseRawTrackingCsv(dataRaw)

      this.loadingVideo = false
    },
    render (data) {
      if (data.frameIndex >= 0 && this.showTrackingPoints) {
        const tp = this.trackingPoints[data.frameIndex]
        const pointRadius = this.pointRadius(data.videoWidth, data.videoHeight)
        this.renderTrackingPoints(data.context, tp.points, pointRadius, 'blue')
      }
    },
    renderTrackingPoints (ctx, points, radius, color) {
      ctx.fillStyle = color
      points.forEach(p => {
        ctx.beginPath()
        ctx.ellipse(
          p.x, p.y, radius, radius,
          0, 0, 2 * Math.PI)
        ctx.closePath()
        ctx.fill()
      })
    },
    pointRadius (videoWidth, videoHeight) {
      return Math.min(videoWidth, videoHeight) / 80
    },
    ...mapActions('scans2d', ['getOrLoadRecordingVideoUri', 'loadRecordingRawTracking'])
  },

  mounted () {
    if (this.recording != null) {
      this.onRecordingChanged()
    }
  }
}
</script>
