<template>
  <div>
    <v-dialog v-model="showDialog" width="90%" max-width="600">
      <template #activator="{on}">
        <div class="d-flex MProCoral pa-1 rounded-bl-lg text-caption pointer"
             v-on="on">
          <v-icon small class="white--text mr-1">mdi-information-outline</v-icon>
          <span>{{ $t('general.beta-version') }}</span>
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="flex-grow-1">{{ $t('general.beta-version') }}</span>
          <v-btn icon @click="toggleDialog">
            <v-icon>$close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-card-actions class="justify-end px-6 pb-6">
          <v-btn class="MProCoral" @click="toggleDialog">
            {{ $t('forms.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    autoPopupSettingName: String
  },

  data: () => ({
    showDialog: false
  }),

  computed: {
    ...mapGetters('user', ['getSettings'])
  },

  methods: {
    toggleDialog () {
      this.showDialog = !this.showDialog
    },
    async autoPopup () {
      const settingValue = await this.getCachedUserSetting(this.autoPopupSettingName)
      if (settingValue == null) {
        this.showDialog = true

        await this.updateUserSetting({
          setting: this.autoPopupSettingName,
          value: 1
        })
      }
    },
    ...mapActions('user', ['getCachedUserSetting', 'updateUserSetting'])
  },

  mounted () {
    if (this.autoPopupSettingName != null) {
      this.autoPopup()
    }
  }
}
</script>
