<template>
  <v-footer class="MProHeader d-flex flex-nowrap justify-space-between align-baseline" v-if="!presentMode">
    <div class="mr-4 flex-shrink-1 d-flex align-baseline">
      <mpro-logo class="mr-4" />
      <span class="d-flex align-baseline ws-nowrap">
        <span>&#169;&nbsp;</span>
        <span class="d-none d-sm-inline">{{copyrightYear}}&nbsp;</span>
        <a href="https://qinematic.com" target="_blank" style="color: inherit !important">
          Qinematic AB
        </a>
        <span class="d-none d-md-inline">&nbsp;| {{ $t('footer.rights') }}</span>
      </span>
    </div>
    <div class="d-flex align-baseline">
      <mpro-download-apps v-if="isSpecialist || isAdministrator" />
      <mpro-language-switch />
    </div>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './logo'
import LanguageSwitch from '@/components/elements/language-switch.vue'
import DownloadApps from './download-apps.vue'

export default {
  components: {
    'mpro-language-switch': LanguageSwitch,
    'mpro-logo': Logo,
    'mpro-download-apps': DownloadApps
  },
  data () {
    return {
      presentMode: false
    }
  },
  computed: {
    ...mapGetters(['isSeeker', 'isSpecialist', 'isAdministrator']),
    copyrightYear: function () {
      const year = new Date().getFullYear()
      return year > 2019 ? `2019 - ${year}` : '2019'
    }
  },
  mounted () {
    this.$root.$on('resizeElements', (presentMode) => {
      this.presentMode = presentMode
    })
  }
}
</script>
