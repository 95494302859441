<template>
  <div>
    <v-sheet v-if="adBlocked" id="ad-blocked" class="MProCoral d-flex justify-center pa-3">
      <span v-html="$t('login.ad-blocked-html', { whiteList: adBlockerWhiteList })"
            class="text-center"/>
    </v-sheet>
    <v-sheet v-if="!isGoodBrowser" id="chrome-notice" class="MProCoral d-flex justify-center pa-3">
      {{ $t('login.recommend-browser')}}
      <v-btn href="https://www.google.com/chrome/" class="ml-3" target="_blank" outlined>
        {{ $t('login.get-chrome')}}
      </v-btn>
    </v-sheet>
    <v-card class="px-4 pa-2 main-card"
            :style="{ marginTop: `${topMargin}px`, marginBottom: `${bottomMargin}px` }">
      <v-container>
        <v-row class="my-0" justify="center">
          <v-col cols="12" class="py-0">
            <h1 class="mb-4 d-flex flex-column align-center">
              <span class="text-center">{{ $t('login.title-main') }}</span>
              <mpro-icon-base iconName="Logo" class="color my-2"
                              :vb-width="1628" :vb-height="160" width="100%" height="1em">
                <mpro-logo/>
              </mpro-icon-base>
            </h1>
            <p class="text-justify ws-pre-line">{{ mainText }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn color="MProBlue" block large
                  @click="signIn">
              {{ $t('login.login') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-4" >
          <v-col cols="12" class="py-0">
            <p class="text-center">{{ $t('login.title-registration') }}</p>
            <p>
              <v-btn outlined color="MProBlue" class="MProWhite-text" block large
                    :to="signupLocation">
                <span class="MProWhite-text">{{ $t('login.seeker-register') }}</span>
              </v-btn>
            </p>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <div class="d-flex justify-space-between align-center">
              <a href="https://moovment.pro" target="_blank" class="MProWhite-text">
                {{ $t('login.information') }}
              </a>
              <mpro-language-switch/>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LanguageSwitch from '@/components/elements/language-switch'
import IconBase from './../icons/IconBase.vue'
import Logo from './../icons/Logo.vue'
import {MessageKind} from '../../helpers/enums'
import Utils from '@/helpers/utils'

function isGoodBrowser () {
  let sBrowser
  let sUsrAg = navigator.userAgent
  // The order matters here, and this may report false positives for unlisted browsers.
  if (sUsrAg.indexOf('Firefox') > -1 || sUsrAg.indexOf('FxiOS') > -1) {
    // Firefox
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    sBrowser = false
  } else if (sUsrAg.indexOf('YaBrowser') > -1) {
    // Yandex browser
    sBrowser = false
  } else if (sUsrAg.indexOf('Vivaldi') > -1) {
    // Vivaldi
    sBrowser = false
  } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
    // Samsung browser
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    sBrowser = false
  } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
    // Opera
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    sBrowser = false
  } else if (sUsrAg.indexOf('Trident') > -1 || sUsrAg.indexOf('MSIE') > -1) {
    // Internet Explorer
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    sBrowser = false
  } else if (sUsrAg.indexOf('Edge') > -1 || sUsrAg.indexOf('EdgiOS') > -1 || sUsrAg.indexOf('EdgA') > -1) {
    // Edge OLD (EdgeHTML engine)
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    sBrowser = false
  } else if (sUsrAg.indexOf('Edg') > -1) {
    // Edge NEW (Chromium engine)
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/86.0.4240.198 Safari/537.36 Edg/86.0.622.69"
    sBrowser = true
  } else if (sUsrAg.indexOf('Chrome') > -1 || sUsrAg.indexOf('CriOS') > -1) {
    // Chrome
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    sBrowser = true
  } else if (sUsrAg.indexOf('Safari') > -1) {
    // Safari
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    sBrowser = false
  } else {
    // Other
    sBrowser = false
  }
  return sBrowser
}

export default {
  components: {
    'mpro-language-switch': LanguageSwitch,
    'mpro-icon-base': IconBase,
    'mpro-logo': Logo
  },

  data: () => {
    return {
      topMargin: 0,
      bottomMargin: 0
    }
  },

  computed: {
    isConnecting: function () {
      return this.$route.name === 'LoginWithConnect'
    },
    organizationCode: function () {
      return this.isConnecting ? Utils.singleQueryValue(this.$route.query.code) : null
    },
    mainText: function () {
      return this.isConnecting
        ? this.$t('login.text-connect', { organization: this.organizationName })
        : this.$t('login.text-main')
    },
    signupLocation: function () {
      const result = { name: 'SignUp' }
      if (this.isConnecting) result.query = { organization: this.organizationCode }
      return result
    },
    adBlocked: function () {
      return this.adBlockerWhiteList != null
    },
    isGoodBrowser: function () {
      return isGoodBrowser()
    },
    ...mapState(['adBlockerWhiteList'])
  },

  asyncComputed: {
    organizationName: async function () {
      if (!this.isConnecting) return null

      const org = await this.loadPublicOrganization(this.organizationCode)
      return org?.Name
    }
  },

  watch: {
    isGoodBrowser: async function () {
      await this.$nextTick()
      this.updateMargins()
    },
    adBlocked: async function () {
      await this.$nextTick()
      this.updateMargins()
    },
    organizationName: function (newValue) {
      if (this.isConnecting && newValue == null) {
        this.$router.replace({ name: 'Login' })
      }
    }
  },

  methods: {
    ...mapActions(['loadPublicOrganization']),
    signIn () {
      this.$msal.signIn(true, this.$i18n.locale)
    },
    onRegisterProviderClick () {
      this.showGlobalMessage({kind: MessageKind.WARNING, text: 'Will be available soon...\nStay tuned!'})
    },
    ...mapActions(['showGlobalMessage', 'setHeaderTitle']),
    updateMargins () {
      this.topMargin = document.getElementById('ad-blocked')?.clientHeight ?? 0
      this.bottomMargin = document.getElementById('chrome-notice')?.clientHeight ?? 0
    }
  },

  created () {
    this.setHeaderTitle()
  },
  mounted () {
    window.addEventListener('resize', this.updateMargins)
    this.updateMargins()
  }
}
</script>

<style scoped>
.main-card {
  width: 400px;
}

#chrome-notice, #ad-blocked {
  max-width: 100vw;
  position: fixed;
  left: 0;
  right: 0;
  align-items: center;
}

#chrome-notice {
  bottom: 0;
}

#ad-blocked {
  top: 0;
}

</style>
