<template>
  <v-simple-table>
    <thead>
      <tr>
        <th>{{$t('admin.organizations.name')}}</th>
        <th>{{$t('admin.organizations.kind')}}</th>
        <th v-if="isQinematicAdmin">{{$t('admin.organizations.paying')}}</th>
        <th>{{$t('admin.organizations.mastered_by')}}</th>
        <th>{{$t('admin.organizations.address')}}</th>
        <th>{{$t('admin.organizations.contacts')}}</th>
        <th>{{$t('admin.organizations.time_zone_offset')}}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="noRecords">
        <td colspan="7" class="text-center">{{$t('admin.no_records')}}</td>
      </tr>
      <tr v-else v-for="o in organizations" :key="o.Code">
        <td>
          <div class="d-flex flex-column">
            <router-link :to="getOrganizationRoute(o.Code)">
              {{o.Name}}
            </router-link>
            <span class="grey--text">{{o.Code}}</span>
          </div>
        </td>
        <td>{{o.Kind}}</td>
        <td v-if="isQinematicAdmin"><v-icon v-if="o.IsPaying">mdi-check</v-icon></td>
        <td>
          <div class="d-flex flex-column">
            <router-link v-if="getOrganizationRoute(o.MasteredByCode)"
                        :to="getOrganizationRoute(o.MasteredByCode)">
              {{o.MasteredByName}}
            </router-link>
            <span v-else>{{o.MasteredByName}}</span>
            <span class="grey--text">{{o.MasteredByCode}}</span>
          </div>
        </td>
        <td>
          {{$isoCountries.getName(o.CountryCode, $i18n.locale)}}
          <br/>
          {{o.Address}}
        </td>
        <td>
          <div v-if="o.Email">{{o.Email}}</div>
          <div v-if="o.Phone">{{o.Phone}}</div>
        </td>
        <td>
          {{formatTimeZoneOffset(o.TimeZoneOffsetMinutes)}}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  props: {
    organizations: {type: Array, default: () => []}
  },

  computed: {
    noRecords: function () {
      return this.organizations == null || this.organizations.length === 0
    },
    ...mapState('admin', {
      administratedOrganizations: 'organizations'
    }),
    ...mapGetters('user', ['isQinematicAdmin'])
  },

  methods: {
    getOrganizationRoute (code) {
      if (!this.administratedOrganizations) return ''

      if (this.administratedOrganizations.some(o => o.Code === code)) {
        return {
          name: 'admin:Organization',
          params: { code }
        }
      } else {
        return ''
      }
    },
    formatTimeZoneOffset (minutes) {
      const d = this.$moment.duration(minutes, 'minutes')
      return d.asMilliseconds() >= 0
        ? this.$moment.utc(d.asMilliseconds()).format('H:mm')
        : '-' + this.$moment.utc(-d.asMilliseconds()).format('H:mm')
    },
    ...mapActions('admin', ['ensureOrganizationsLoaded'])
  },

  created () {
    this.ensureOrganizationsLoaded()
  }
}
</script>
