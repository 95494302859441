<template>
  <v-container>
    <v-row v-if="isSpecialist && sharesSurveys === false" justify="center">
      <v-col cols="12" md="8">
        <v-alert dense text type="info">
          {{ $t('surveys.not-shared-with-specialist') }}
        </v-alert>
      </v-col>
    </v-row>
    <template v-else>
      <v-row justify="center">
        <v-col cols="12" md="7">
          <h2 v-if="isSeeker">{{ seekerTitle }}</h2>
        </v-col>
        <v-col cols="12" md="2">
          <mpro-assign-survey v-if="isSpecialist" :seeker-id="seekerId"
                              @assign="refreshPendingItems++"/>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <h2>{{$t('surveys.title-pending')}}</h2>
          <v-skeleton-loader type="article"
                             :loading="pendingItems == null || (pendingItems.length === 0 && $asyncComputed.pendingItems.updating)">
            <div v-if="pendingItems && pendingItems.length > 0">
              <div v-for="survey in pendingItems" :key="survey.Id">
                <mpro-single-survey :survey="survey"></mpro-single-survey>
              </div>
            </div>
            <v-alert v-else dense text type="success">
              <span v-if="isSpecialist && seeker != null">{{ $t('surveys.no-pending-surveys-specialist', {name: (seeker.DisplayName || seeker.Username)}) }}</span>
              <span v-else>{{ $t('surveys.no-pending-surveys-seeker') }}</span>
            </v-alert>
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <h2>{{$t('surveys.title-finished')}}</h2>
          <v-skeleton-loader type="article@2"
                             :loading="items == null || (items.length === 0 && $asyncComputed.items.updating)">
            <div>
              <div v-for="survey in items" :key="survey.Id">
                <mpro-single-survey :survey="survey"></mpro-single-survey>
              </div>
            </div>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </template>
    <mpro-header-handler tabName="surveys" :seeker="seeker"/>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Lo from 'lodash'
import SingleSurvey from './single-survey'
import AssignSurvey from './assign-survey'
import HeaderHandler from '../specialist/header-handler'
import { SharedDataKind } from '@/models/data-sharing'

export default {
  components: {
    'mpro-single-survey': SingleSurvey,
    'mpro-assign-survey': AssignSurvey,
    'mpro-header-handler': HeaderHandler
  },

  data: () => ({
    refreshPendingItems: 0
  }),

  computed: {
    ...mapGetters('specialist', ['getSeekerById']),
    ...mapGetters(['isSpecialist', 'isSeeker']),
    ...mapState('seeker', ['surveyAssignments', 'surveys']),
    ...mapGetters('seeker', ['activeSubordinate']),
    seekerId: function () {
      return this.$route.params.seekerId
    },
    seeker: function () {
      return this.getSeekerById(this.seekerId)
    },
    activeTab: {
      get: function () {
        return this.$store.state.activeTab
      },
      set: function (value) {
        this.$store.commit('setActiveTab', value)
      }
    },
    seekerTitle: function () {
      return this.activeSubordinate == null
        ? this.$t('surveys.title-seeker')
        : this.$t('surveys.title-subordinate', { name: this.activeSubordinate.FirstName })
    }
  },

  asyncComputed: {
    async items () {
      let result = []
      let surveys
      if (this.isSpecialist) {
        surveys = this.seekerSurveys
        if (surveys != null) surveys = surveys.filter(s => s.AnsweredAt != null)
      } else {
        surveys = this.surveys
      }
      if (surveys != null) {
        for (const s of surveys) {
          const metadata = await this.getOrLoadMetadata(s.QuestionnaireCode)
          result.push({metadata, ...s})
        }
      }
      return result
    },
    pendingItems: async function () {
      let surveys
      if (this.isSpecialist) {
        surveys = this.seekerSurveys
        if (surveys != null) surveys = surveys.filter(s => s.AnsweredAt == null)
      } else {
        surveys = this.surveyAssignments
      }
      if (surveys == null) return []

      const groupedByCode = surveys.reduce((acc, sa) => {
        let item = acc[sa.QuestionnaireCode] || { QuestionnaireCode: sa.QuestionnaireCode, values: [] }
        item.values.push(sa)
        acc[sa.QuestionnaireCode] = item
        return acc
      }, {})

      let result = Object.values(groupedByCode)
      result.forEach(item => {
        item.DeadlineDate = Lo.min(item.values.map(sa => sa.DeadlineDate))
      })
      const awaited = result.map(async item => {
        item.metadata = await this.getOrLoadMetadata(item.QuestionnaireCode)
      })
      await Promise.all(awaited)
      result = Lo.orderBy(result, item => item.DeadlineDate)
      return result
    },
    seekerSurveys: function () {
      if (!this.isSpecialist || this.seekerId == null || !this.sharesSurveys) return Promise.resolve([])

      return this.getOrLoadSurveysBySeekerId({
        seekerId: this.seekerId,
        force: this.refreshPendingItems > 0
      })
    },
    sharesSurveys: async function () {
      if (!this.isSpecialist || this.seekerId == null) return false
      const kinds = await this.getOrLoadSharedDataKinds(this.seekerId)
      return kinds.includes(SharedDataKind.Surveys)
    }
  },

  watch: {
    seekerTitle: function () {
      if (this.isSeeker) {
        this.setHeaderTitle(this.seekerTitle)
      }
    }
  },

  methods: {
    ...mapActions(['setHeaderTitle']),
    ...mapActions('seeker', ['ensureSurveysLoaded', 'ensureSurveyAssignmentsLoaded']),
    ...mapActions('surveys', ['getOrLoadSurveysBySeekerId', 'getOrLoadMetadata']),
    ...mapActions('specialist', ['getOrLoadSharedDataKinds']),
    getItemLink (item) {
      return item.CapturedAt
        ? {name: 'Scan', params: {scanId: item.Id}}
        : item.AnsweredAt
          ? {name: 'ViewSurvey', params: {surveyId: item.Id}}
          : null
    }
  },

  created () {
    if (!this.isSpecialist) {
      this.ensureSurveysLoaded()
      this.ensureSurveyAssignmentsLoaded()
    }
  },

  mounted () {
    if (this.isSeeker) {
      this.setHeaderTitle(this.seekerTitle)
    }
  }
}
</script>

<style scoped>
ul {
  margin-top: 2em;
}
li {
  margin-bottom: 1em;
}
</style>
