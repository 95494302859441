import Vue from 'vue'
import axios from 'axios'
import Cache from 'ttl-cache'
import Utils from '../../helpers/utils'

const URL_METADATA = '/surveys/metadata'
const URL_SURVEY_BY_ID = '/surveys/single'
const URL_SURVEYS_BY_SEEKER_ID = '/surveys/of/seeker'

// Augments questionnaire metadata with props used to display surveys
function augmentMetadata (metadata) {
  metadata.canNavigateToSource = Utils.isHttpUrl(metadata.Source)

  if (Array.isArray(metadata.questions)) {
    for (let q of metadata.questions) {
      if (Array.isArray(q.questions)) {
        // This is a section
        q.isQuestionGrid = q.questions.length > 1 && Utils.allSame(q.questions.map(item => item.TypeCode.toLowerCase()))
      }
    }

    // Flat list of questions
    metadata.flatQuestions = metadata.questions.flatMap(q => Array.isArray(q.questions) ? q.questions : [q])

    for (let q of metadata.flatQuestions) {
      // Extract groups
      q.groups = []
      if (Array.isArray(q.values)) {
        for (const v of q.values) {
          if (v.GroupName != null && !q.groups.includes(v.GroupName)) q.groups.push(v.GroupName)
        }
      }
      q.groups.sort()
    }
  }
}

let requestCache = new Cache({
  ttl: process.env.VUE_APP_DEFAULT_API_RESPONSE_LIFETIME / 1000,
  interval: 0
})

const state = {
  // Questionnaire metadata keyed with questionnaire code
  metadata: {},
  // Surveys data keyed by survey ID
  surveys: {},
  // Surveys available for assignment
  availableSurveys: []
}

const getters = {
  getUnansweredSurveyCount: state => {
    let unanswered = 0
    for (let key in state.surveys) {
      if (state.surveys[key].AnsweredAt === null) unanswered++
    }
    return unanswered
  }
}

const actions = {
  async getOrLoadMetadata ({state, commit}, questionnaireCode) {
    let result = state.metadata[questionnaireCode]

    const requestKey = `${URL_METADATA}:${questionnaireCode}`
    if (requestCache.get(requestKey) === undefined) {
      let res
      try {
        res = await axios.get(URL_METADATA, {
          params: {
            questionnaire_code: questionnaireCode
          }
        })
      } catch (e) {
        console.warn('getOrLoadMetadata:axios', e)
      }

      if (res) {
        result = res.data && String(res.data.Code).toLowerCase() === questionnaireCode.toLowerCase() ? res.data : null
        if (result != null) {
          augmentMetadata(result)
          commit('setMetadata', result)
        } else {
          commit('setMissingMetadata', questionnaireCode)
        }
      }
    }

    return result
  },

  async getOrLoadSurveyById ({state, commit}, surveyId) {
    let result = state.surveys[surveyId]

    const requestKey = `${URL_SURVEY_BY_ID}:${surveyId}`
    if (requestCache.get(requestKey) === undefined) {
      let response
      try {
        response = await axios.get(URL_SURVEY_BY_ID, {
          params: {
            survey_id: surveyId
          }
        })
      } catch (e) {
        console.warn('getOrLoadSurveyById:axios', e)
      }

      if (response != null) {
        result = response.data
        if (result != null) {
          commit('setSurvey', result)
        } else {
          commit('setMissingSurvey', surveyId)
        }
      }
    }

    return result
  },

  async getOrLoadSurveysBySeekerId (_, payload) {
    const { seekerId, force } = payload
    const requestKey = `${URL_SURVEYS_BY_SEEKER_ID}:${seekerId}`

    if (force) requestCache.del(requestKey)

    let result = requestCache.get(requestKey)
    if (result == null) {
      let response
      try {
        response = await axios.get(URL_SURVEYS_BY_SEEKER_ID, {
          params: {
            seeker_id: seekerId
          }
        })
      } catch (e) {
        console.warn('getOrLoadSurveysBySeekerId:axios', e)
      }

      if (response != null && Array.isArray(response.data)) {
        requestCache.set(requestKey, response.data)
        return response.data
      } else {
        return []
      }
    }

    return result
  },

  async getOrLoadAvailableSurveys ({state, commit}) {
    let res
    if (state.availableSurveys.length === 0) {
      try {
        res = await axios.get('surveys/all', {
        })
      } catch (e) {
        console.warn('getOrLoadAvailableSurveys:axios', e)
      }
      commit('setAvailableSurveys', res.data)
    }
    return res
  },

  async assignSurveyToSeeker (context, survey) {
    try {
      await axios.post('/surveys/assign', {
        survey: survey
      })
      return true
    } catch (e) {
      console.warn('assignSurveyToSeeker:axios', e)
      return false
    }
  },

  async postCompletedSurvey (context, {questionnaireCode, answers, subordinateId}) {
    try {
      await axios.post('/surveys', {
        questionnaireCode,
        answers
      }, {
        params: {
          sub: subordinateId
        }
      })
      return true
    } catch (e) {
      console.warn('postCompletedSurvey:axios', e)
      return false
    }
  }
}

const mutations = {
  setMetadata (state, metadata) {
    Vue.set(state.metadata, metadata.Code, metadata)
    const requestKey = `${URL_METADATA}:${metadata.Code}`
    requestCache.set(requestKey, 1)
  },
  setMissingMetadata (state, code) {
    Vue.delete(state.metadata, code)
    const requestKey = `${URL_METADATA}:${code}`
    requestCache.set(requestKey, 0)
  },
  clearMetadata (state) {
    const loadedCodes = Object.keys(state.metadata)
    state.metadata = {}
    loadedCodes.forEach(code => {
      const requestKey = `${URL_METADATA}:${code}`
      requestCache.del(requestKey)
    })
  },

  setSurvey (state, survey) {
    Vue.set(state.surveys, survey.Id, survey)
    const requestKey = `${URL_SURVEY_BY_ID}:${survey.Id}`
    requestCache.set(requestKey, 1)
  },

  setAvailableSurveys (state, surveys) {
    state.availableSurveys = surveys
  },

  setMissingSurvey (state, surveyId) {
    Vue.delete(state.surveys, surveyId)
    const requestKey = `${URL_SURVEY_BY_ID}:${surveyId}`
    requestCache.set(requestKey, 0)
  }}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
