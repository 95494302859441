<template>
  <v-autocomplete :items="items" :label="label"
                  :value="value" v-on="$listeners"
                  :required="required" :error-messages="errorMessages"
                  :readonly="readonly" :disabled="disabled" :hide-details="hideDetails"
                  :menu-props="{maxHeight: '75vh'}"/>
</template>

<script>
export default {
  props: {
    organizations: Array,
    value: String,
    label: String,
    readonly: Boolean,
    disabled: Boolean,
    hideDetails: Boolean,
    required: Boolean,
    errorMessages: Array
  },

  computed: {
    items: function () {
      const result = (this.organizations || []).map(o => ({
        value: o.Code,
        text: o.Name
      }))
      result.sort((o1, o2) => o1.text.localeCompare(o2.text))
      return result
    }
  }
}
</script>
