<template>
  <div>
    <v-select :label="$t('scans.viewer.movement-select')"
              :placeholder="$t('scans.viewer.movement-select-first')" persistent-placeholder
              :items="items"
              :value="value" @input="onInputValue"
              :disabled="!hasMovements || progress != null"
              class="max-text-width"/>
    <v-progress-linear v-if="progress != null"
                       :value="progress" :indeterminate="progress < 0"/>
    <div v-else style="height:4px"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Object],
    items: Array,
    progress: Number
  },

  computed: {
    hasMovements: function () {
      return this.items?.length > 0
    }
  },

  methods: {
    onInputValue (v) {
      this.$emit('input', v)
    }
  }
}
</script>
