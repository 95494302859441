<template>
  <div>
    <div class="d-flex justify-space-between">
      <span><slot name="title"/></span>
      <span>
        <v-btn v-show="!editing && !saving"
              icon :title="$t('forms.edit')"
              @click="onEditClick">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn v-show="editing || saving" :disabled="saving" :loading="saving"
              icon :title="$t('forms.save_changes')" color="primary"
              @click="onSaveClick">
          <v-icon>mdi-check-bold</v-icon>
        </v-btn>
        <v-btn v-show="editing || saving" :disabled="saving"
              icon :title="$t('forms.discard_changes')"
              @click="onDiscardClick">
          <v-icon>mdi-pencil-off</v-icon>
        </v-btn>
      </span>
    </div>
    <div>
      <slot :readonly="!editing || saving"/>
    </div>
  </div>
</template>

<script>
import EditableContents from './editable-contents'

export default {
  mixins: [EditableContents]
}
</script>
