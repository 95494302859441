import moment from 'moment'
import momentLocaleBritish from 'moment/locale/en-gb'
import momentLocaleGerman from 'moment/locale/de'
import momentLocaleNederlands from 'moment/locale/nl'
import momentDurationFormatSetup from 'moment-duration-format'
import VueMoment from 'vue-moment'

moment.updateLocale('en-gb', momentLocaleBritish)
moment.updateLocale('de', momentLocaleGerman)
moment.updateLocale('nl', momentLocaleNederlands)

// Set default locale
moment.locale('en-gb')

// Set up formatting of durations
momentDurationFormatSetup(moment)

function format (value, format, originalLocalTime) {
  const m = originalLocalTime ? moment.parseZone(value) : moment(value)
  return m.format(format)
}

function formatDate (value, originalLocalTime = true) {
  return format(value, 'll', originalLocalTime)
}

function formatDateTime (value, originalLocalTime = true) {
  return format(value, 'lll', originalLocalTime)
}

function formatTime (value, originalLocalTime = true) {
  return format(value, 'LT', originalLocalTime)
}

function formatAgeFromBirthDate (birthDate) {
  const now = moment()
  const bd = moment(birthDate)
  const years = now.diff(bd, 'years')
  if (years < 0) return undefined
  if (years > 0) return moment.duration(years, 'years').humanize()
  const months = now.diff(bd, 'months')
  if (months > 0) return moment.duration(months, 'months').humanize()
  const days = now.diff(bd, 'days')
  return moment.duration(days, 'days').humanize()
}

function formatDurationSeconds (seconds) {
  return moment.duration(seconds, 'seconds').format('m _ s _', { trim: 'both' })
}

export default {
  install: function (Vue) {
    Vue.use(VueMoment, {moment})

    Vue.prototype.$formatDate = formatDate
    Vue.prototype.$formatDateTime = formatDateTime
    Vue.prototype.$formatTime = formatTime
    Vue.prototype.$formatAgeFromBirthDate = formatAgeFromBirthDate
    Vue.prototype.$formatDurationSeconds = formatDurationSeconds
  }
}
