













































import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Lo from 'lodash'
import LibraryListItem from './library-list-item.vue'
import { Library } from '@/models/admin'

export default Vue.extend({
  components: {
    'mpro-library-item': LibraryListItem
  },

  props: {
    organization: String,
    title: String,
    buttonText: String,
    buttonColor: String,
    itemCount: Number,
    libraryIds: { type: Array, default: () => [] },
    processCallback: Function
  },

  data: () => ({
    dialogOpen: false,
    processing: false,
    loadingLibraries: false,
    fields: {
      libraryId: ''
    }
  }),

  validations: {
    fields: {
      libraryId: { required }
    }
  },

  computed: {
    ...mapState({
      // eslint-disable-next-line
      allLibraries: (state: any): Library[] => state.admin.libraries as Library[]
    }),
    libraryItems: function (): Library[] {
      let result = Lo(this.allLibraries)
        .filter(l => l.OwnerCode.toLowerCase() === this.organization?.toLowerCase())
      if (this.libraryIds?.length > 0) {
        result = result.filter(l => this.libraryIds.includes(l.Id))
      }
      return result.sortBy(l => l.Name.toLocaleLowerCase()).value()
    }
  },

  watch: {
    async dialogOpen (newValue) {
      this.reset()
      if (newValue === true && this.allLibraries.length === 0) {
        this.loadingLibraries = true
        await this.ensureLibrariesLoaded()
        this.loadingLibraries = false
      }
    }
  },

  methods: {
    ...mapActions('admin', ['ensureLibrariesLoaded']),
    close () {
      this.dialogOpen = false
    },
    async reset () {
      this.fields.libraryId = ''
      await this.$nextTick()
      this.$v.$reset()
    },
    async process () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      let success
      this.processing = true
      try {
        success = await this.processCallback(this.fields.libraryId)
      } finally {
        this.processing = false
      }

      if (success) {
        this.close()
      }
    }
  }
})
