<template>
  <div class="d-flex flex-column align-start">
    <div class="d-flex">
      <v-dialog v-model="creatingNew"
                max-width="600" scrollable
                @keydown.esc="creatingNew = false">
        <template #activator="{on}">
          <v-btn class="MProPurple ma-6" small
                 v-on="on">
            <v-icon small>mdi-plus</v-icon>
            <small>{{ $t('admin.new_organization') }}</small>
          </v-btn>
        </template>
        <mpro-editable-card ref="createNewCard"
                            :save-callback="saveNewOrganization"
                            @end-edit="onCreateNewEndEdit">
          <template #title>{{ $t('admin.new_organization') }}</template>
          <mpro-organization-details ref="createNewDetails"/>
        </mpro-editable-card>
      </v-dialog>
    </div>
    <v-skeleton-loader type="table-head, table-body" :loading="loading">
      <mpro-organizations-table :organizations="organizations"/>
    </v-skeleton-loader>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import OrganizationsTable from './table'
import OrganizationDetails from './details'
import EditableCard from '@/components/elements/editable-card'
import {MessageKind} from '@/helpers/enums'

export default {
  components: {
    'mpro-organizations-table': OrganizationsTable,
    'mpro-organization-details': OrganizationDetails,
    'mpro-editable-card': EditableCard
  },

  data: () => ({
    loading: true,
    creatingNew: false
  }),

  computed: {
    ...mapState('admin', ['organizations'])
  },

  watch: {
    creatingNew: async function (newValue) {
      if (newValue) {
        await this.$nextTick()
        this.$refs.createNewCard.beginEdit()
      } else if (this.$refs.createNewDetails) {
        this.$refs.createNewDetails.syncOrganizationToData()
        await this.$nextTick()
        this.$refs.createNewDetails.$v.$reset()
      }
    }
  },

  methods: {
    async saveNewOrganization () {
      if (!this.$refs.createNewDetails.validate()) return false

      try {
        await this.createOrganization(this.$refs.createNewDetails.data)
        return true
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e)
        })
        return false
      }
    },
    async onCreateNewEndEdit (saved) {
      const code = this.$refs.createNewDetails.data.code
      this.creatingNew = false
      if (saved) {
        this.ensureOrganizationsLoaded({force: true})
        this.$router.push({name: 'admin:Organization', params: { code }})
      }
    },
    ...mapActions(['setHeaderTitle', 'showGlobalMessage']),
    ...mapActions('admin', ['ensureOrganizationsLoaded', 'createOrganization'])
  },

  async created () {
    this.loading = true
    await this.ensureOrganizationsLoaded()
    if (!this.organizations || !this.organizations.length) {
      this.$router.push({name: 'Dashboard'})
    }
    this.loading = false
  },
  mounted () {
    this.setHeaderTitle(this.$t('admin.organizations_title'))
  }
}
</script>
