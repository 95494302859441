














































import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import CountdownDialog from '@/components/elements/countdown-dialog.vue'
import { UserMfaMode } from '@/models/users'
import { MessageKind } from '@/helpers/enums'
import locationBuilder from '@/router/location-builder'

export interface ManageMfaRef {
  handleEnableTotp: () => Promise<void>
}

export default Vue.extend({
  components: {
    'mpro-countdown': CountdownDialog
  },

  props: {
    buttonClass: { type: [String, Object] }
  },

  data: () => ({
    dialogOpen: false,
    mfaMode: UserMfaMode.None,
    processing: false,
    showCountdown: false,
    coarsePointer: false
  }),

  computed: {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    ...mapState('user', {
      userMfaMode: (state: any): UserMfaMode | null => state.mfaMode
    }),
    ...mapState({
      justSignedIn: (state: any): boolean => state.justSignedIn
    }),
    /* eslint-enable @typescript-eslint/no-explicit-any */
    isMobile: function (): boolean {
      return this.$vuetify.breakpoint.smAndDown || this.coarsePointer
    },
    instructionHtml: function (): TranslateResult {
      return this.isMobile
        ? this.$t('account.manage-mfa.instruction-mobile-html')
        : this.$t('account.manage-mfa.instruction-html')
    },
    canProceed: function (): boolean {
      if (this.processing) return false
      else if (this.isMobile && this.mfaMode === UserMfaMode.Totp) return false
      else return true
    }
  },

  watch: {
    dialogOpen: function (newValue: boolean) {
      if (newValue) this.mfaMode = this.userMfaMode ?? UserMfaMode.None
    }
  },

  methods: {
    ...mapActions('user', ['setUserMfa', 'ensureUserDataLoaded']),
    ...mapActions(['showGlobalMessage']),
    close () {
      this.dialogOpen = false
    },
    async save () {
      if (!this.canProceed) return

      if (this.mfaMode === this.userMfaMode) {
        this.close()
        this.showGlobalMessage({ text: this.$t('forms.no_changes'), kind: MessageKind.INFO })
        return
      }

      if (this.mfaMode === UserMfaMode.None) {
        // Disable MFA immediately
        this.processing = true
        try {
          await this.setUserMfa({ mode: UserMfaMode.None })
        } catch (e) {
          const message = this.$errorMessage(e)
          this.showGlobalMessage({ text: message, kind: MessageKind.ERROR })
          return
        } finally {
          this.processing = false
        }

        this.close()
        this.ensureUserDataLoaded({ force: true })
        this.showGlobalMessage({
          text: this.$t('account.manage-mfa.success-disabled'),
          kind: MessageKind.SUCCESS
        })
      } else if (this.mfaMode === UserMfaMode.Totp) {
        this.close()
        this.showCountdown = true
      }
    },
    onCountdownElapsed () {
      // Enabling TOTP requires binding to an authenticator app.
      // So, first let a user sign in with MFA policy.
      // When finished, will update its MFA mode (see 'handleEnableTotp').
      const returnUrl = this.$router.resolve(locationBuilder.myAccount('enable-totp')).href
      this.$msal.signInMfa(returnUrl, this.$i18n.locale)
    },
    async handleEnableTotp () {
      if (this.justSignedIn) {
        if (this.$msal.isAuthenticatedWithMfa()) {
          try {
            await this.setUserMfa({ mode: UserMfaMode.Totp })
          } catch (e) {
            console.error('Could not enable TOTP', e)
            this.showGlobalMessage({
              text: this.$t('account.manage-mfa.error-enabling-totp'),
              kind: MessageKind.ERROR
            })
            return
          }

          this.ensureUserDataLoaded({ force: true })
          this.showGlobalMessage({
            text: this.$t('account.manage-mfa.success-enabled-totp'),
            kind: MessageKind.SUCCESS
          })
        }
      }
    },
    onCoarsePointerChanged (ev: MediaQueryListEvent) {
      this.coarsePointer = ev.matches
    }
  },

  created () {
    const mediaQuery = window.matchMedia('(pointer:coarse)')
    mediaQuery.addEventListener('change', this.onCoarsePointerChanged)
    this.coarsePointer = mediaQuery.matches
  }
})
