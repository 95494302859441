var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-start"},[_c('div',{staticClass:"d-flex flex-wrap justify-start ml-6 mt-6"},[_c('mpro-date-box',{attrs:{"label":_vm.$t('admin.statistics.from'),"value":_vm.fromDate,"field-class":"mr-6 mb-6"},on:{"input":_vm.onFromInput}}),_c('mpro-date-box',{attrs:{"label":_vm.$t('admin.statistics.till'),"value":_vm.tillDate,"error-messages":_vm.dateRangeErrors,"field-class":"mr-6 mb-6"},on:{"input":_vm.onTillInput}})],1),_c('v-skeleton-loader',{attrs:{"type":"table-thead, table-tbody","loading":_vm.loading}},[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"hide-default-footer":"","disable-pagination":"","no-data-text":_vm.$t('forms.nothing_found')},scopedSlots:_vm._u([{key:"item.CapturedAt",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$formatDateTime(value))+" ")]}},{key:"item.SeekerUsername",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(value))]),(item.SeekerName)?_c('div',{staticClass:"grey--text"},[_vm._v(_vm._s(item.SeekerName))]):_vm._e()]}},{key:"item.SpecialistLogin",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(value))]),(item.SpecialistName)?_c('div',{staticClass:"grey--text"},[_vm._v(_vm._s(item.SpecialistName))]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }