<template>
  <v-navigation-drawer id="app-menu-left" app
                       :clipped="true"
                       v-model="menuOpen"
                       color="MProBlack2"
                       elevation="24"
                       temporary
                       class="MPro-bg">
    <div class="d-flex flex-column h-100pc">
      <v-list nav v-if="!isSpecialist">
        <p>
          <v-btn
            icon
            @click="menuToggle"
          >
            <v-icon>$close</v-icon>
          </v-btn>
          <span class="ml-8 text-center">{{ $t('menu.title') }}</span>
        </p>
        <v-card v-for="item in menuItems" :key="item.to.name || item.to"
                :to="item.to" link class="my-3 mx-5 pa-5 menu-item d-flex align-center" hover>
          <div>
            <v-icon v-if="item.icon.startsWith('mdi-')" class="">{{ item.icon }}</v-icon>
            <icon-base v-else :iconName="item.icon" width="72" height="72"><component :is="'icon-'+item.icon" /></icon-base>
          </div>
          <div>
            <v-card-title class="text-subtitle-1">{{item.title}}</v-card-title>
            <v-card-subtitle>{{item.subtitle}}</v-card-subtitle>
          </div>
        </v-card>
      </v-list>
      <v-list v-else nav>
        <div>
          <v-btn
            icon
            @click="menuToggle"
          >
            <v-icon>$close</v-icon>
          </v-btn>
          <span class="ml-8 text-center">{{ $t('menu.title') }}</span>
        </div>
        <mpro-seeker-list />
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import IconBase from './../icons/IconBase.vue'
import IconExercises from './../icons/Exercises.vue'
import IconScans from './../icons/Scans.vue'
import IconSurveys from './../icons/Surveys.vue'
import IconGoals from './../icons/Goals.vue'
import IconDashboard from './../icons/Dashboard.vue'
import SeekerList from './../specialist/seeker-list'

export default {
  components: {
    'icon-base': IconBase,
    'icon-exercises': IconExercises,
    'icon-scans': IconScans,
    'icon-surveys': IconSurveys,
    'icon-goals': IconGoals,
    'icon-dashboard': IconDashboard,
    'mpro-seeker-list': SeekerList
  },

  data: () => ({
    roleMenuOpen: false
  }),

  computed: {
    menuItems: function () {
      let items = []
      if (!this.isAdministrator) {
        items.push({to: {name: 'Dashboard'}, title: this.$t('menu.dashboard'), subtitle: this.$t('menu.subdashboard'), icon: 'dashboard'})
      }
      if (this.isSeeker) {
        items.push({to: {name: 'MyExercises'}, title: this.$t('menu.exercises'), subtitle: this.$t('menu.subexercises'), icon: 'exercises'})
        items.push({to: {name: 'MyGoals'}, title: this.$t('menu.goals'), subtitle: this.$t('menu.subgoals'), icon: 'goals'})
        items.push({to: {name: 'MyScans'}, title: this.$t('menu.scans'), subtitle: this.$t('menu.subscans'), icon: 'scans'})
        items.push({to: {name: 'MySurveys'}, title: this.$t('menu.surveys'), subtitle: this.$t('menu.subsurveys'), icon: 'surveys'})
      }
      if (this.isAdministrator) {
        items.push({to: {name: 'admin:Statistics'}, title: this.$t('admin.statistics_title'), subtitle: this.$t('admin.statistics_subtitle'), icon: 'mdi-poll'})
        if (this.hasAdministratedOrganizations) {
          items.splice(items.length - 1, 0, {
            to: { name: 'admin:Seekers' },
            title: this.$t('admin.seekers-title'),
            subtitle: this.$t('admin.seekers-subtitle'),
            icon: 'mdi-account-multiple'
          })
          items.push({
            to: { name: 'admin:Organizations' },
            title: this.$t('admin.organizations_title'),
            subtitle: this.$t('admin.organizations_subtitle'),
            icon: 'mdi-office-building'
          })
        }
      }
      return items
    },
    menuOpen: {
      get: function () {
        return this.$store.state.user.userSettings.menuOpen
      },
      set: function (value) {
        this.$store.commit('user/setMenuOpen', value)
      }
    },
    ...mapGetters(['isSeeker', 'isSpecialist', 'isAdministrator']),
    ...mapState('admin', {
      adminOrganizations: 'organizations'
    })
  },

  asyncComputed: {
    hasAdministratedOrganizations: async function () {
      if (!this.isAdministrator) return false

      await this.ensureOrganizationsLoaded()
      return this.adminOrganizations && this.adminOrganizations.length > 0
    }
  },

  methods: {
    ...mapMutations('user', ['menuToggle']),
    ...mapActions('admin', ['ensureOrganizationsLoaded'])
  }
}
</script>

<style>
.seeker #app-menu-left, .administrator #app-menu-left {
  max-width: 100%;
  width: 400px !important;
}
.specialist #app-menu-left {
  min-width: 450px;
  max-width: 600px;
  width: 40vw !important;
}

@media screen and (max-width: 450px) {
  .specialist #app-menu-left {
    min-width: 0;
    width: 100% !important;
  }
}
</style>
