<template>
  <div>
    <!-- Text answer -->
    <span v-if="['Dropdown', 'TextField'].includes(userInterface)  && answerItem != null"
          class="MProGreen-text">
      {{showValue ? answerItem.Value : ''}} {{showName ? answerItem.Name : ''}}
    </span>

    <!-- Slider -->
    <v-slider v-else-if="userInterface === 'Slider'"
              :min="0" :max="question.values.length - 1"
              :value="answerIndex"
              readonly
              color="MProGreen" hide-details class="mb-8"
              ticks="always" tick-size="5"
              :tick-labels="question.values.map(getTickLabel)" />

    <!-- Vertical list -->
    <v-item-group v-else-if="userInterface === 'VerticalTicks'"
                  mandatory
                  :value="(answerItem || {}).Value">
      <v-item v-for="item in question.values" :key="item.Value"
              :value="item.Value"
              #default="{active, toggle}">
        <div class="ma-2">
          <v-chip :input-value="active" @click="toggle"
                  :disabled="!active" class="vertical-chip py-1"
                  active-class=""
                  :color="active ? 'MProGreen' : ''">
            <span v-if="showValue" class="value">{{item.Value}}</span>
            <span v-if="showValue && showName && item.Name" class="mx-1">:</span>
            <span v-if="showName" class="name">{{item.Name}}</span>
          </v-chip>
        </div>
      </v-item>
    </v-item-group>

    <!-- Horizontal list -->
    <v-chip-group v-else-if="userInterface === 'HorizontalTicks' || userInterface === 'Grid'"
                  active-class="primary--text" column mandatory
                  :value="(answerItem || {}).Value">
      <v-chip v-for="item in question.values" :key="item.Value"
              :value="item.Value" :disabled="item.Value !== (answerItem || {}).Value">
        <span v-if="showValue" class="value">{{item.Value}}</span>
        <span v-if="showValue && showName && item.Name" class="mx-1">:</span>
        <span v-if="showName" class="name">{{item.Name}}</span>
      </v-chip>
    </v-chip-group>

    <!-- Discomfort areas -->
    <mpro-discomfort-areas v-else-if="userInterface === 'DiscomfortAreas'"
                           :question="question"
                           :value="answer" readonly
                           :gender="userGender"/>
  </div>
</template>

<script>
import DiscomfortAreas from './qa-discomfort-areas'
import {Gender} from '../../helpers/enums'

export default {
  components: {
    'mpro-discomfort-areas': DiscomfortAreas
  },
  props: {
    question: {type: Object, required: true},
    answer: String,
    userGender: {type: String, default: Gender.UNKNOWN}
  },
  computed: {
    userInterface: function () {
      return this.question.UserInterface
    },
    showName: function () {
      return this.question.Show.includes('Name')
    },
    showValue: function () {
      return this.question.Show.includes('Value')
    },
    answerItem: function () {
      if (!Array.isArray(this.question.values)) return null

      return this.question.values.find(item => item.Value === this.answer) ?? { Value: this.answer }
    },
    answerIndex: function () {
      return this.question.values.findIndex(i => i.Value === this.answer)
    }
  },
  methods: {
    getAnswerClass: function (item) {
      return {
        selected: item === this.answerItem
      }
    },
    getTickLabel (item) {
      let label
      if (this.showValue && item.Value !== null && item.Value !== undefined) label = item.Value
      if (label !== undefined) label += '\n'
      if (this.showName && item.Name !== null && item.Name !== undefined) label += item.Name
      return label
    }
  }
}
</script>

<style scoped>
.vertical-chip {
  height: auto !important;
  min-height: 32px;
  white-space: normal;
}

table {
  border-collapse: collapse;
}

.value {
  text-align: center;
}
</style>
