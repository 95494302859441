































import Vue from 'vue'
import { mapGetters } from 'vuex'
import RequestChangeEmailAddress from './request-change-emal-address.vue'
import ConfirmMoveSeekerData from './confirm-move-seeker-data.vue'
import ConfirmDeleteAccount from './confirm-delete-account.vue'
import ConfirmationDialog from '@/components/elements/confirmation-dialog.vue'

export default Vue.extend({
  components: {
    'mpro-request-change-email-address': RequestChangeEmailAddress,
    'mpro-confirm-move-seeker-data': ConfirmMoveSeekerData,
    'mpro-confirm-delete-account': ConfirmDeleteAccount,
    'mpro-confirmation-dialog': ConfirmationDialog
  },

  data: () => ({
    changingEmail: false,
    deletingAccount: false,
    requireSignIn: false
  }),

  computed: {
    ...mapGetters('user', ['getLastLogin'])
  },

  methods: {
    ensureRecentSignIn (): boolean {
      const minutesFromSignIn = this.$moment().diff(this.$moment(this.getLastLogin as number), 'minutes', true)
      if (minutesFromSignIn <= 2) return true

      this.requireSignIn = true
      return false
    },
    async onSignIn (): Promise<void> {
      await this.$msal.signOut()
    },
    onChangeEmail () {
      if (this.ensureRecentSignIn()) {
        this.changingEmail = true
      }
    },
    onDeleteAccount () {
      if (this.ensureRecentSignIn()) {
        this.deletingAccount = true
      }
    }
  }
})
