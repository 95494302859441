





































import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  computed: {
    downloadLinkScan: function (): string | undefined {
      return this.canDownloadDesktopApps
        ? this.formatDownloadLink('Scan')
        : undefined
    },
    downloadLinkLab: function (): string | undefined {
      return this.canDownloadDesktopApps
        ? this.formatDownloadLink('Lab')
        : undefined
    },
    ...mapGetters('user', ['canDownloadDesktopApps'])
  },

  methods: {
    formatDownloadLink (product: string): string {
      const suffix = process.env['VUE_APP_DOWNLOAD_APPS_SUFFIX'] || ''
      const suffixLower = suffix.toLowerCase()
      const productLower = product.toLowerCase()
      return `https://download.qinematic.com/moovment.${productLower}${suffixLower}/Moovment.${product}${suffix}.exe`
    }
  }
})
