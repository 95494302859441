<template>
  <div class="playback">
    <div class="d-flex flex-wrap justify-center">
      <div>
        <v-tooltip bottom class="d-inline-flex">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small class="py-5 px-0 ml-2 "
              :disabled="currentFrame <= 0 || playbackDisabled"
              v-bind="attrs"
              v-on="on"
              @click="raisePrevFrame">
              <v-icon>mdi-step-backward</v-icon>
            </v-btn>
          </template>
          <span>{{$t('scans.buttons.previous-frame')}}</span>
        </v-tooltip>
        <v-tooltip bottom class="d-inline-flex">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small class="py-5 px-0 ml-2"
              v-bind="attrs"
              v-on="on"
              :disabled="playbackDisabled"
              @click="togglePlaying">
              <v-icon>{{playing ? 'mdi-pause' : 'mdi-play'}}</v-icon>
            </v-btn>
          </template>
          <span>{{$t('scans.buttons.' + (playing ? 'pause' : 'play'))}}</span>
        </v-tooltip>
        <v-tooltip bottom class="d-inline-flex">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small class="py-5 px-0"
              v-bind="attrs"
              v-on="on"
              :disabled="currentFrame >= frameCount - 1 || playbackDisabled"
              @click="raiseNextFrame">
              <v-icon>mdi-step-forward</v-icon>
            </v-btn>
          </template>
          <span>{{$t('scans.buttons.next-frame')}}</span>
        </v-tooltip>
      </div>
      <div class="flex-grow-1 align-self-stretch d-flex align-center playbar">
        <v-slider id="timeline" dense hide-details
                  class="mx-2" color="MProCoral" style="min-width: 100px"
                  min="0" :max="maxFrame"
                  :value="currentFrame" @input="scrollToFrame"
                  :readonly="playbackDisabled"/>
        <div class="text-right" :style="{ minWidth: `${timeAndFrameStatusWidth}ex`}">
          <span>{{ displayedCurrentTime.toFixed(2) }}</span>
          /
          <span>{{ (maxFrame / fps).toFixed(2) }}</span>
          <br/>
          <span>{{ displayedCurrentFrame }}</span>
          /
          <span>{{ maxFrame }}</span>
        </div>
        <v-tooltip :bottom="!fullscreen" :top="fullscreen">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="toggleFullscreen" icon v-bind="attrs" v-on="on" class="py-5 px-0 mx-2">
              <v-icon v-show="!fullscreen">mdi-fullscreen</v-icon>
              <v-icon v-show="fullscreen">mdi-fullscreen-exit</v-icon>
            </v-btn>
          </template>
          <span v-show="!fullscreen">{{$t('scans.buttons.full-screen')}}</span>
          <span v-show="fullscreen">{{$t('scans.buttons.exit-full-screen')}}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    frameCount: { type: Number, default: 0 },
    fps: { type: Number, default: 30 },
    currentFrame: { type: Number, default: 0 },
    currentTime: Number,
    playing: { type: Boolean, default: false },
    fullscreen: { type: Boolean, default: false },
    playbackDisabled: { type: Boolean, default: false }
  },

  computed: {
    displayedCurrentFrame: function () {
      return Math.max(0, Math.min(this.maxFrame, this.currentFrame))
    },
    displayedCurrentTime: function () {
      return this.currentTime == null
        ? this.displayedCurrentFrame / this.fps
        : Math.max(0, Math.min(this.maxTime, this.currentTime))
    },
    maxFrame: function () {
      return Math.max(0, this.frameCount - 1)
    },
    maxTime: function () {
      return this.maxFrame / this.fps
    },
    // Determines number of characters needed to display time/frame status
    // This is used to set min-width of an element to avoid shakiness
    // of status and slider during playback
    timeAndFrameStatusWidth: function () {
      const timeLength = this.maxTime.toFixed(2).length * 2 + 3
      const frameLength = this.maxFrame.toString().length * 2 + 3
      return Math.max(timeLength, frameLength) + 1
    }
  },

  methods: {
    togglePlaying () {
      this.$emit('toggle-playing')
    },
    raisePrevFrame () {
      this.$emit('prev-frame')
    },
    raiseNextFrame () {
      this.$emit('next-frame')
    },
    scrollToFrame (frame) {
      this.$emit('set-frame', frame)
    },
    toggleFullscreen () {
      this.$emit('toggle-fullscreen')
    }
  }
}
</script>

<style>
.playback button{
  min-width: 40px!important;
}
</style>
