import {mapActions} from 'vuex'
import {MessageKind} from '@/helpers/enums'

export default {
  props: {
    validateCallback: Function,
    saveCallback: {type: Function, required: true},
    beginEditWhenCreated: {type: Boolean, default: () => false}
  },

  data: () => ({
    editing: false,
    saving: false
  }),

  methods: {
    beginEdit () {
      if (!this.editing) {
        this.editing = true
        this.$emit('begin-edit')
      }
    },
    onEditClick () {
      this.beginEdit()
    },
    async onSaveClick () {
      const valid = !this.validateCallback || this.validateCallback()
      if (valid) {
        this.saving = true
        let result
        try {
          result = await this.saveCallback()
        } finally {
          this.saving = false
        }

        let success
        let showSuccessMessage = true
        switch (typeof result) {
          case 'boolean':
            success = result
            break
          case 'object':
            success = result.success
            showSuccessMessage = result.showSuccessMessage !== false
            break
          default:
            success = false
            break
        }

        if (success) {
          this.editing = false
          this.$emit('end-edit', true)

          if (showSuccessMessage) {
            this.showGlobalMessage({
              kind: MessageKind.SUCCESS,
              text: this.$t('forms.save_changes_success')
            })
          }
        } else {
          // Save failed => stay in edit mode
        }
      }
    },
    onDiscardClick () {
      this.editing = false
      this.$emit('end-edit', false)
    },
    ...mapActions(['showGlobalMessage'])
  },

  created () {
    if (this.beginEditWhenCreated) {
      this.beginEdit()
    }
  }
}
