import axios from 'axios'
import Cache from 'ttl-cache'
import getUuid from 'uuid-by-string'
import { handleAxiosError } from '@/helpers/errors'
import Utils from '@/helpers/utils'

function getSpecialistUuid (specialistLogin) {
  const salt = '6eW7oriByR6Ge8cuZWvM'
  return getUuid(salt + specialistLogin, 5)
}

const requestCache = new Cache({
  ttl: process.env.VUE_APP_DEFAULT_API_RESPONSE_LIFETIME / 1000,
  interval: 0
})

const state = {
  organizations: [],
  libraries: [],
  specialists: [],
  settingsMetadata: null
}

const getters = {
  getSpecialistUuid: _ => getSpecialistUuid,
  getSpecialistByUuid: state => uuid => state.specialists.find(s => getSpecialistUuid(s.Login) === uuid)
}

const RequestUrls = {
  ORGANIZATIONS: '/admin/organizations',
  organization (code) {
    return `${RequestUrls.ORGANIZATIONS}/${encodeURIComponent(code)}`
  },
  LIBRARIES: '/admin/libraries',
  library (id) {
    return `${RequestUrls.LIBRARIES}/${encodeURIComponent(id)}`
  },
  SPECIALISTS: '/admin/specialists',
  SPECIALISTS_WITH_ACCESS: '/admin/specialists-with-access',
  specialist (login) {
    return `${RequestUrls.SPECIALISTS}/${encodeURIComponent(login)}`
  },
  right (specialist, right, organizationCode, libraryId) {
    return libraryId == null || libraryId === ''
      ? `${RequestUrls.organization(organizationCode)}/rights/${encodeURIComponent(specialist)}/${encodeURIComponent(right)}`
      : `${RequestUrls.library(libraryId)}/rights/${encodeURIComponent(specialist)}/${encodeURIComponent(right)}`
  },
  SETTINGS_METADATA: '/admin/settings-metadata',
  organizationSetting (organizationCode, settingCode) {
    return `${RequestUrls.organization(organizationCode)}/settings/${encodeURIComponent(settingCode)}`
  },
  organizationBinarySetting (organizationCode, settingCode) {
    return RequestUrls.organizationSetting(organizationCode, settingCode) + '/binary'
  },
  librarySetting (libraryId, settingCode) {
    return `${RequestUrls.library(libraryId)}/settings/${encodeURIComponent(settingCode)}`
  },
  libraryBinarySetting (libraryId, settingCode) {
    return RequestUrls.librarySetting(libraryId, settingCode) + '/binary'
  },
  STATISTICS_OVERVIEW: '/admin/statistics',
  SCAN_LIST: 'admin/statistics/scans',
  activeSharings: (organizationCode) => `${RequestUrls.organization(organizationCode)}/sharings`,
  sharingNotes: (organizationCode) => `${RequestUrls.activeSharings(organizationCode)}/notes`,
  seekerEvents: (organizationCode) => `${RequestUrls.organization(organizationCode)}/seeker-events`,
  sharingSeekers: (organizationCode) => `${RequestUrls.organization(organizationCode)}/seekers`,
  backupSeekers: (organizationCode) => `${RequestUrls.organization(organizationCode)}/backup-seekers`,
  backupDownloadUrl: (organizationCode, seekerId) => `${RequestUrls.backupSeekers(organizationCode)}/${encodeURIComponent(seekerId)}/download-url`,
  libraryMembership: (libraryId, seekerId) => `${RequestUrls.library(libraryId)}/seekers/${encodeURIComponent(seekerId)}`
}

const mutations = {
  setOrganizations (state, newValue) {
    state.organizations = newValue
    requestCache.set(RequestUrls.ORGANIZATIONS, true)
  },
  clearOrganizations (state) {
    state.organizations = []
    requestCache.del(RequestUrls.ORGANIZATIONS)
  },
  setLibraries (state, newValue) {
    state.libraries = newValue
    requestCache.set(RequestUrls.LIBRARIES, true)
  },
  clearLibraries (state) {
    state.libraries = []
    requestCache.del(RequestUrls.LIBRARIES)
  },
  setSpecialists (state, newValue) {
    state.specialists = newValue
    requestCache.set(RequestUrls.SPECIALISTS, true)
  },
  clearSpecialists (state) {
    state.specialists = []
    requestCache.del(RequestUrls.SPECIALISTS)
  },
  setSettingsMetadata (state, newValue) {
    state.settingsMetadata = newValue
  }
}

const actions = {
  //
  // ORGANIZATIONS
  //

  async ensureOrganizationsLoaded ({commit}, options) {
    const force = options && options.force
    if (requestCache.get(RequestUrls.ORGANIZATIONS) == null || force) {
      let res
      try {
        res = await axios.get(RequestUrls.ORGANIZATIONS)
      } catch (e) {
        console.warn('ensureOrganizationsLoaded:axios', e)
      }
      if (res) {
        const newValue = Array.isArray(res.data) ? res.data : []
        commit('setOrganizations', newValue)
      }
    }
  },
  async loadOrganization (context, code) {
    try {
      let res = await axios.get(RequestUrls.organization(code))
      return res.data
    } catch (e) {
      console.warn('loadOrganization:axios', e)
    }
  },
  async createOrganization (context, data) {
    const isQinematicAdmin = context.rootGetters['user/isQinematicAdmin']
    if (!isQinematicAdmin) {
      delete data.paying
      delete data.widgetAvailable
      delete data.offers3DService
    }
    try {
      await axios.post(RequestUrls.ORGANIZATIONS, data)
    } catch (e) {
      handleAxiosError(e)
    }

    context.commit('clearOrganizations')
  },
  async updateOrganization (context, data) {
    const {code, ...dataWithoutCode} = data
    data = dataWithoutCode

    const isQinematicAdmin = context.rootGetters['user/isQinematicAdmin']
    if (!isQinematicAdmin) {
      delete data.paying
      delete data.kind
      delete data.widgetAvailable
      delete data.offers3DService
    }

    try {
      await axios.put(RequestUrls.organization(code), data)
    } catch (e) {
      handleAxiosError(e)
    }

    context.commit('clearOrganizations')
  },
  async deleteOrganization (context, code) {
    try {
      await axios.delete(RequestUrls.organization(code))
    } catch (e) {
      handleAxiosError(e)
    }

    context.commit('clearOrganizations')
  },

  //
  // LIBRARIES
  //

  async ensureLibrariesLoaded ({ commit }, options) {
    const force = options && options.force
    if (requestCache.get(RequestUrls.LIBRARIES) == null || force === true) {
      let response
      try {
        response = await axios.get(RequestUrls.LIBRARIES)
      } catch (e) {
        console.warn('ensureLibrariesLoaded:axios', e)
        return
      }

      if (Array.isArray(response.data)) {
        commit('setLibraries', response.data)
      }
    }
  },
  async loadLibrary (context, id) {
    try {
      let res = await axios.get(RequestUrls.library(id))
      return res.data
    } catch (e) {
      console.warn('loadLibrary:axios', e)
    }
  },
  async createLibrary ({ commit }, data) {
    let response
    try {
      response = await axios.post(RequestUrls.LIBRARIES, data)
    } catch (e) {
      handleAxiosError(e)
    }
    commit('clearLibraries')
    return response.data
  },
  async updateLibrary ({ commit }, data) {
    const {id, ...dataWithoutId} = data
    try {
      await axios.put(RequestUrls.library(id), dataWithoutId)
    } catch (e) {
      handleAxiosError(e)
    }
    commit('clearLibraries')
  },
  async deleteLibrary ({ commit }, id) {
    try {
      await axios.delete(RequestUrls.library(id))
    } catch (e) {
      handleAxiosError(e)
    }
    commit('clearLibraries')
  },

  //
  // SPECIALISTS
  //

  async loadSpecialist (context, login) {
    try {
      let res = await axios.get(RequestUrls.specialist(login))
      return res.data
    } catch (e) {
      console.warn('loadSpecialist:axios', e)
    }
  },
  async ensureSpecialistsLoaded ({ commit }, options) {
    const force = options?.force === true
    if (requestCache.get(RequestUrls.SPECIALISTS) == null || force) {
      let response
      try {
        response = await axios.get(RequestUrls.SPECIALISTS)
      } catch (e) {
        console.warn('ensureSpecialistsLoaded:axios', e)
        return
      }

      if (Array.isArray(response.data)) {
        commit('setSpecialists', response.data)
      }
    }
  },
  async createSpecialist (context, data) {
    try {
      await axios.post(RequestUrls.SPECIALISTS, data)
    } catch (e) {
      handleAxiosError(e)
    }
  },
  async updateSpecialist (context, data) {
    const {login, ...dataWithoutLogin} = data
    try {
      await axios.put(RequestUrls.specialist(login), dataWithoutLogin)
    } catch (e) {
      handleAxiosError(e)
    }
  },
  async deleteSpecialist (context, login) {
    try {
      await axios.delete(RequestUrls.specialist(login))
    } catch (e) {
      handleAxiosError(e)
    }
  },
  async loadSpecialistsWithAccess (_, filter) {
    const { organizationCode, seekerId } = filter || {}
    try {
      const response = await axios.get(RequestUrls.SPECIALISTS_WITH_ACCESS, {
        params: { organizationCode, seekerId }
      })
      if (Array.isArray(response.data)) {
        return response.data
      }
    } catch (e) {
      console.warn('loadSpecialistsWithAccess:axios', e)
    }
    return []
  },

  //
  // SPECIALIST RIGHTS
  //

  async grantRight (context, data) {
    try {
      await axios.put(RequestUrls.right(data.specialist, data.right, data.organization, data.library), {
        description: data.description
      })
    } catch (e) {
      handleAxiosError(e)
    }
  },
  async revokeRight (context, data) {
    try {
      await axios.delete(RequestUrls.right(data.Specialist, data.Right, data.OrganizationCode, data.LibraryId))
    } catch (e) {
      handleAxiosError(e)
    }
  },

  //
  // ORGANIZATION/LIBRARY SETTINGS
  //

  async ensureSettingsMetadataLoaded ({ state, commit }) {
    if (state.settingsMetadata != null) return

    let response
    try {
      response = await axios.get(RequestUrls.SETTINGS_METADATA)
    } catch (e) {
      console.warn('ensureSettingsMetadataLoaded:axios', e)
      return
    }

    if (Array.isArray(response.data)) {
      commit('setSettingsMetadata', response.data)
    }
  },
  async loadOrganizationBinarySetting (context, { organizationCode, settingCode }) {
    let response
    try {
      response = await axios.get(
        RequestUrls.organizationBinarySetting(organizationCode, settingCode), {
          responseType: 'blob'
        })
    } catch (e) {
      console.warn('loadOrganizationBinarySetting:axios', e)
      return null
    }
    return response.data
  },
  async loadLibraryBinarySetting (context, { libraryId, settingCode }) {
    let response
    try {
      response = await axios.get(
        RequestUrls.libraryBinarySetting(libraryId, settingCode), {
          responseType: 'blob'
        })
    } catch (e) {
      console.warn('loadLibraryBinarySetting:axios', e)
      return null
    }
    return response.data
  },
  async changeOrganizationSetting (context, { organizationCode, settingCode, value }) {
    try {
      if (value == null || (typeof value === 'string' && value.trim() === '')) {
        await axios.delete(RequestUrls.organizationSetting(organizationCode, settingCode))
      } else if (value instanceof File) {
        await axios.put(RequestUrls.organizationBinarySetting(organizationCode, settingCode),
          value, {
            headers: { 'Content-Type': value.type || 'application/octet-stream' },
            params: { filename: value.name }
          })
      } else {
        await axios.put(RequestUrls.organizationSetting(organizationCode, settingCode),
          { value })
      }
    } catch (e) {
      console.warn('changeOrganizationSetting:axios', e)
      handleAxiosError(e)
    }
  },
  async changeLibrarySetting (context, { libraryId, settingCode, value }) {
    try {
      if (value == null || (typeof value === 'string' && value.trim() === '')) {
        await axios.delete(RequestUrls.librarySetting(libraryId, settingCode))
      } else if (value instanceof File) {
        await axios.put(RequestUrls.libraryBinarySetting(libraryId, settingCode),
          value, {
            headers: { 'Content-Type': value.type || 'application/octet-stream' },
            params: { filename: value.name }
          })
      } else {
        await axios.put(RequestUrls.librarySetting(libraryId, settingCode),
          { value })
      }
    } catch (e) {
      console.warn('changeLibrarySetting:axios', e)
      handleAxiosError(e)
    }
  },

  //
  // STATISTICS
  //

  async loadStatisticsOverview (context, {from, till}) {
    try {
      let res = await axios.get(RequestUrls.STATISTICS_OVERVIEW, {
        params: {from, till}
      })
      return res.data
    } catch (e) {
      console.warn('loadStatisticsOverview:axios', e)
      handleAxiosError(e)
    }
  },
  async loadScanList (context, {organizationCode, from, till}) {
    try {
      const res = await axios.get(RequestUrls.SCAN_LIST, {
        params: {organization: organizationCode, from, till}
      })
      return res.data
    } catch (e) {
      console.warn('loadScanList:axios', e)
      handleAxiosError(e)
    }
  },

  //
  // DATA SHARING
  //

  async loadActiveSharings (_, { organizationCode }) {
    let response
    try {
      response = await axios.get(RequestUrls.activeSharings(organizationCode))
    } catch (e) {
      console.warn('loadActiveSharings', e)
      return []
    }

    return Array.isArray(response.data) ? response.data : []
  },
  async resolveDataSharingRequest (_, data) {
    const { organizationCode, ...body } = data

    try {
      await axios.post(RequestUrls.activeSharings(organizationCode), body)
    } catch (e) {
      console.warn('resolveDataSharingRequest:axios', e)
      handleAxiosError(e)
    }
  },
  async sendDataSharingNotes (_, data) {
    const { organizationCode, ...body } = data

    try {
      await axios.post(RequestUrls.sharingNotes(organizationCode), body)
    } catch (e) {
      handleAxiosError(e)
    }
  },
  async loadSeekerEvents (_, filter) {
    let { organizationCode, from, till, seekerId } = filter

    let result
    try {
      result = await axios.get(RequestUrls.seekerEvents(organizationCode), {
        params: {
          from: from == null ? undefined : Utils.toIsoDate(from),
          till: till == null ? undefined : Utils.toIsoDate(till),
          seekerId: (seekerId || '').length === 0 ? undefined : seekerId
        }
      })
    } catch (e) {
      console.warn('loadSeekerEvents:axios', e)
      return []
    }

    return Array.isArray(result.data) ? result.data : []
  },
  async loadSharingSeekers (_, payload) {
    const { organizationCode } = payload
    const url = RequestUrls.sharingSeekers(organizationCode)

    let response
    try {
      response = await axios.get(url)
    } catch (e) {
      console.warn('loadSharingSeekers:axios', e)
    }

    return Array.isArray(response?.data) ? response.data : []
  },
  async addSeekerToLibrary (_, { libraryId, seekerId }) {
    const url = RequestUrls.libraryMembership(libraryId, seekerId)
    try {
      await axios.put(url, null)
    } catch (e) {
      handleAxiosError(e)
    }
  },
  async removeSeekerFromLibrary (_, { libraryId, seekerId }) {
    const url = RequestUrls.libraryMembership(libraryId, seekerId)
    try {
      await axios.delete(url)
    } catch (e) {
      handleAxiosError(e)
    }
  },
  async loadBackupSeekers (_, payload) {
    const { organizationCode } = payload
    const url = RequestUrls.backupSeekers(organizationCode)

    let response
    try {
      response = await axios.get(url)
    } catch (e) {
      console.warn('loadBackupSeekers:axios', e)
    }

    return Array.isArray(response?.data) ? response.data : []
  },
  async loadBackupDownloadUrl (_, payload) {
    const { organizationCode, seekerId, dataKind } = payload
    const url = RequestUrls.backupDownloadUrl(organizationCode, seekerId)

    let response
    try {
      response = await axios.get(url, {
        params: { dataKind }
      })
    } catch (e) {
      console.warn('loadBackupDownloadUrl:axios', e)
      return undefined
    }

    return String(response.data)
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
