import moment, { MomentInput } from 'moment'

//
// String utils
//

function isHttpUrl (value: unknown): boolean {
  if (typeof value === 'string') {
    const valueLow = value.toLowerCase()
    return valueLow.startsWith('http://') || valueLow.startsWith('https://')
  } else {
    return false
  }
}

function getUserInitials (nameOrEmail: string): string {
  const atIndex = nameOrEmail.indexOf('@')
  const name = atIndex > 0 ? nameOrEmail.substring(0, atIndex) : nameOrEmail
  const parts = name.split(/(?:\s|\.|-|,)+/)
  return parts.slice(0, 2).map(p => p[0]).join('').toUpperCase()
}

function singleQueryValue (queryValue: string | (string | null)[]): string | null {
  return Array.isArray(queryValue) ? queryValue[0] : queryValue
}

//
// Date utils
//

function parseIsoDate (s: MomentInput): Date {
  return moment(s, 'YYYY-MM-DD', true).toDate()
}

function toIsoDate (date: MomentInput): string {
  return moment(date).format('YYYY-MM-DD')
}

function toIsoDateTimeOffset (date: MomentInput): string {
  return moment(date).toISOString(true)
}

//
// Array utils
//

function allSame (array: unknown[]): boolean {
  if (array.length === 0) return true

  const first = array[0]
  return array.every(item => Object.is(item, first))
}

// Common utils

function delay (msTimeout: number): Promise<void> {
  return new Promise<void>(resolve => setTimeout(() => resolve(), msTimeout))
}

export default {
  isHttpUrl,
  getUserInitials,
  singleQueryValue,
  parseIsoDate,
  toIsoDate,
  toIsoDateTimeOffset,
  allSame,
  delay
}
