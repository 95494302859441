





import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Role } from '@/helpers/enums'

export default Vue.extend({
  computed: {
    tutorialsUrl: function (): string {
      let pagePath = ''
      switch (this.getRole) {
        case Role.SEEKER:
          pagePath = '/client-tutorials/'
          break
        case Role.SPECIALIST:
          pagePath = '/specialist-tutorials/'
          break
        case Role.ADMINISTRATOR:
          pagePath = '/admin-tutorials/'
          break
        default:
          return ''
      }

      const baseUrl: string = process.env.VUE_APP_TUTORIALS_BASE_URL ?? ''
      return baseUrl + pagePath
    },

    ...mapGetters(['getRole'])
  }
})
