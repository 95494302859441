<template>
  <v-simple-table>
    <thead>
      <tr>
        <th>{{$t('admin.libraries.name')}}</th>
        <th>{{$t('admin.libraries.description')}}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="noRecords">
        <td colspan="2" class="text-center">{{$t('admin.no_records')}}</td>
      </tr>
      <tr v-else v-for="l in libraries" :key="l.Id">
        <td>
          <router-link :to="{name: 'admin:Library', params: {id: l.Id}}">
            {{l.Name}}
          </router-link>
        </td>
        <td>{{l.Description}}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    libraries: {type: Array, default: () => []}
  },

  computed: {
    noRecords: function () {
      return this.libraries == null || this.libraries.length === 0
    }
  }
}
</script>
