<template>
  <div class="d-flex flex-column align-stretch">
    <div class="MProMain2 flex-grow-1 p-relative d-flex align-center justify-center">
      <!-- Video and canvas -->
      <!-- Adding mute and playsinline attributes for proper playback on iOS devices. See CS-28 for details.  -->
      <video ref="video"
             v-show="canDisplayData && showVideo"
             :src="videoSource" autoplay loop muted playsinline
             @loadedmetadata="onVideoLoadedMetadata" @loadeddata="onVideoLoadedData"
             @play="onVideoTogglePlaying" @pause="onVideoTogglePlaying" @seeked="render"/>
      <canvas ref="canvas" v-show="canDisplayData" class="click-through"/>
      <!-- Overlay -->
      <slot name="overlay" v-if="canDisplayData"/>
      <!-- Loading indicator -->
      <div v-if="!canDisplayData" class="d-flex flex-column align-center">
        <span>{{ $t('scans2d.loading-video') }}</span>
        <v-progress-circular class="mt-2" indeterminate/>
      </div>
    </div>
    <mpro-playback-controls v-show="canDisplayData"
                            :fps="fps"
                            :frame-count="frameCount"
                            :current-frame="lastRenderedFrameIndex"
                            :current-time="lastRenderedTimeStamp"
                            :playing="playing"
                            @toggle-playing="togglePlaying"
                            @set-frame="setCurrentFrame"
                            @next-frame="stepFrame(+1)"
                            @prev-frame="stepFrame(-1)"
                            :fullscreen="presentMode"
                            @toggle-fullscreen="presentMode = !presentMode"/>
  </div>
</template>

<script>
import binarySearch from 'binary-search'
import PlaybackControls from '@/components/elements/playback-controls'

export default {
  components: {
    'mpro-playback-controls': PlaybackControls
  },

  props: {
    loadingVideo: { type: Boolean, default: false },
    videoSource: String,
    frameTimeStamps: { type: Array, default: () => [] },
    framesPerSecond: Number,
    showVideo: { type: Boolean, default: true }
  },

  data: () => ({
    ctx: null,
    videoWidth: 0,
    videoHeight: 0,
    videoDuration: 0,
    videoMetadataLoaded: false,
    playing: false,
    presentMode: false,
    lastRenderedFrameIndex: -1,
    lastRenderedTimeStamp: undefined
  }),

  computed: {
    frameCount: function () {
      return this.frameTimeStamps?.length ?? 0
    },
    fps: function () {
      return this.videoDuration > 0
        ? this.frameCount / this.videoDuration
        : this.framesPerSecond
    },
    canDisplayData: function () {
      return this.videoSource != null && !this.loadingVideo && this.videoMetadataLoaded
    },
    videoEl: function () {
      return this.$refs.video
    }
  },

  watch: {
    videoSource: function () {
      this.resetVideoMetadata()
      this.resetLastRenderedFrame()
    },
    presentMode: function () {
      this.$nextTick(() => {
        this.$root.$emit('resizeElements', this.presentMode)
      })
    }
  },

  methods: {
    resetVideoMetadata () {
      this.videoWidth = 0
      this.videoHeight = 0
      this.videoDuration = 0
      this.videoMetadataLoaded = false
    },
    resetLastRenderedFrame () {
      this.lastRenderedFrameIndex = -1
      this.lastRenderedTimeStamp = undefined
      this.render()
    },
    onVideoLoadedMetadata () {
      const c = this.$refs.canvas
      this.videoWidth = c.width = this.videoEl.videoWidth
      this.videoHeight = c.height = this.videoEl.videoHeight
      this.videoDuration = this.videoEl.duration
      this.videoMetadataLoaded = true
    },
    onVideoLoadedData () {
      this.render()
    },
    onVideoTogglePlaying () {
      this.playing = this.videoEl?.paused === false
      this.render()
    },
    togglePlaying () {
      if (this.videoEl != null) {
        this.playing ? this.videoEl.pause() : this.videoEl.play()
      }
    },
    setCurrentFrame (frame) {
      if (this.videoEl == null || this.frameCount === 0) return

      frame = Math.max(0, Math.min(this.frameCount - 1, frame))
      const timeStamp = this.frameTimeStamps[frame]
      this.videoEl.currentTime = timeStamp
    },
    stepFrame (step) {
      if (this.videoEl == null) return

      const frame = Math.max(0, Math.min(this.frameCount - 1, this.lastRenderedFrameIndex + step))
      this.setCurrentFrame(frame)
    },

    findCurrentFrameIndex (timeStamp) {
      let frameIndex = binarySearch(this.frameTimeStamps ?? [], timeStamp,
        (fts, needle) => fts - needle)

      if (frameIndex < 0) {
        // There is no exact time in the array (not surprising),
        // so find the nearest one
        // (binarySearch returns -(indexToInsert + 1) when value not found)
        frameIndex = -frameIndex - 1
        if (frameIndex >= this.frameCount) {
          frameIndex = this.frameCount - 1 // will be negative for an empty array
        } else if (frameIndex > 0) {
          const prevIsCloser = this.frameTimeStamps[frameIndex] - timeStamp >
                               timeStamp - this.frameTimeStamps[frameIndex - 1]
          if (prevIsCloser) frameIndex--
        }
      }

      return frameIndex
    },

    render () {
      if (this.videoEl != null) {
        const timeStamp = this.videoEl.currentTime
        const frameIndex = this.findCurrentFrameIndex(timeStamp)
        if (frameIndex !== this.lastRenderedFrameIndex) {
          this.ctx.save()
          this.ctx.clearRect(0, 0, this.videoWidth, this.videoHeight)
          this.$emit('render', {
            context: this.ctx,
            timeStamp,
            frameIndex,
            videoWidth: this.videoWidth,
            videoHeight: this.videoHeight
          })
          this.ctx.restore()

          this.lastRenderedFrameIndex = frameIndex
          this.lastRenderedTimeStamp = timeStamp
        }
      }

      if (this.playing) {
        window.requestAnimationFrame(this.render)
      }
    }
  },

  mounted () {
    const c = this.$refs.canvas
    this.ctx = c.getContext('2d')

    this.$root.$on('resizeElements', pm => {
      this.presentMode = pm
    })
  },

  beforeDestroy () {
    this.$emit('resizeElements', false)
  }
}
</script>

<style scoped>
video, canvas {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);
}
video {
  outline: 0;
}
</style>
