<template>
  <div v-if="question != null" class="question pb-4 mb-10">
    <v-text-field v-if="userInterface === 'TextField'"
                  :value="value" @change="setValue" @blur="$forceUpdate()"
                  outlined dense hide-details />

    <!-- Text box with suggestions dropdown -->
    <div v-if="isAutosuggest">
      <v-autosuggest
                    v-model="selected"
                   :suggestions="autosuggestItems"
                   :sectionConfigs="autosuggestSections"
                   :input-props="{id: `survey-question:${question.Code}`, placeholder: $t('forms.autosuggest_placeholder'), class: 'autosuggest__input'}"
                   :component-attr-id-autosuggest="`autosuggest:survey-question:${question.Code}`"
                   @input="onAutosuggestInput"
                   @selected="onAutosuggestSelected"
                   class="d-flex flex-wrap autosuggest">
        <template slot-scope="{suggestion}" class="flex-grow-1">
          <span v-if="showValue">{{suggestion.item.Value}}</span>
          <span v-if="showName">{{suggestion.item.Name}}</span>
        </template>
        <template slot="after-input" @click="onAutosuggestSelected">
          <v-btn icon class="text-align-left"
                 @click="onAutosuggestSelected(null)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-autosuggest>
    </div>
    <!-- Simple dropdown -->
    <select v-else-if="userInterface === 'Dropdown'">
      <option value="" selected>{{$t('forms.nothing_selected')}}</option>
      <option v-for="item in question.values" :key="item.Value"
              :value="item.Value">
        <span v-if="showValue">{{item.Value}}</span>
        <span v-if="showName">{{item.Name}}</span>
      </option>
    </select>

    <!-- Horizontal slider with labeled ticks -->
    <v-slider v-else-if="userInterface === 'Slider'"
              :min="0" :max="question.values.length - 1"
              :value="valueIndex"
              @input="setValueFromIndex"
              color="MProGreen" hide-details class="mb-8"
              ticks="always" tick-size="5"
              :tick-labels="question.values.map(getTickLabel)" />

    <!-- Vertical list -->
    <v-item-group v-else-if="userInterface === 'VerticalTicks'"
                  :value="value" @change="setValue">
      <v-item v-for="item in question.values" :key="item.Value"
              :value="item.Value"
              #default="{active, toggle}">
        <div class="ma-2">
          <v-chip :input-value="active" @click="toggle"
                  :color="active ? 'MProGreen' : ''"
                  class="vertical-chip py-1"
                  active-class="">
            <span v-if="showValue" class="value">{{item.Value}}</span>
            <span v-if="showValue && showName && item.Name" class="mx-1">:</span>
            <span v-if="showName" class="name">{{item.Name}}</span>
          </v-chip>
        </div>
      </v-item>
    </v-item-group>

    <!-- Horizontal list -->
    <v-chip-group v-else-if="userInterface === 'HorizontalTicks' || userInterface === 'Grid'"
                  active-class="primary--text" column
                  :value="value" @change="setValue">
      <v-chip v-for="item in question.values" :key="item.Value"
              :value="item.Value">
        <span v-if="showValue" class="value">{{item.Value}}</span>
        <span v-if="showValue && showName" class="mx-1">:</span>
        <span v-if="showName" class="name">{{item.Name}}</span>
      </v-chip>
    </v-chip-group>

    <!-- Discomfort areas -->
    <mpro-discomfort-areas v-else-if="userInterface === 'DiscomfortAreas'"
                           :question="question"
                           :value="value" @input="setValue"
                           :gender="userGender"/>
  </div>
</template>

<script>
import {VueAutosuggest} from 'vue-autosuggest'
import DiscomfortAreas from './qa-discomfort-areas'

const MAX_SELECT_ITEMS = 20
const MAX_SELECT_ITEM_LENGTH = 50

export default {
  components: {
    'v-autosuggest': VueAutosuggest,
    'mpro-discomfort-areas': DiscomfortAreas
  },

  props: {
    value: {type: String, default: null},
    question: {type: Object, required: true},
    userGender: String
  },

  data: () => ({
    autosuggestItems: [],
    selected: null
  }),

  computed: {
    userInterface: function () {
      return this.question.UserInterface
    },
    isAutosuggest: function () {
      if (this.userInterface !== 'Dropdown') return false
      // Use autosuggest when:
      if (Array.isArray(this.question.values)) {
        // * there are too many items
        if (this.question.values.length > MAX_SELECT_ITEMS) return true
        // * there are too lengthy items (as <option> cannot be styled to wrap text)
        if (this.showName && this.question.values.some(v => (v.Name || '').length > MAX_SELECT_ITEM_LENGTH)) return true
        if (this.showValue && this.question.values.some(v => v.Value.length > MAX_SELECT_ITEM_LENGTH)) return true
      }
      // * groups are defined (as <option> cannot be styled for grouping)
      if (this.question.groups.length > 0) return true
      return false
    },
    showName: function () {
      return this.question.Show ? this.question.Show.includes('Name') : false
    },
    showValue: function () {
      return this.question.Show ? this.question.Show.includes('Value') : false
    },
    autosuggestSections: function () {
      let sections = {
        default: {}
      }
      for (const g of this.question.groups) {
        sections[g] = {label: g}
      }
      return sections
    },
    valueIndex: function () {
      return this.question.values.findIndex(i => i.Value === this.value)
    }
  },

  methods: {
    setValue (newValue) {
      if (this.userInterface === 'TextField') newValue = newValue?.trim()
      this.$emit('input', newValue)
    },
    setValueFromIndex (newIndex) {
      this.setValue(this.question.values[newIndex]?.Value)
    },
    getItemClass (item) {
      return {
        selected: item.Value === this.value
      }
    },
    getTickLabel (item) {
      let label
      if (this.showValue && item.Value !== null && item.Value !== undefined) label = item.Value
      if (label !== undefined) label += '\n'
      if (this.showName && item.Name !== null && item.Name !== undefined) label += item.Name
      return label
    },
    getAutosuggestValue (suggestion) {
      return this.showName ? suggestion.item.Name : suggestion.item.Value
    },
    onAutosuggestInput (text) {
      this.setValue(null)

      if (!Array.isArray(this.question.values)) {
        this.autosuggestItems = []
        return
      }

      let values = this.question.values.filter(v => {
        return text === null || text === '' ||
          (this.showName && (v.Name || '').toLocaleLowerCase().includes(text.toLocaleLowerCase())) ||
          (this.showValue && v.Value.toLowerCase().includes(text.toLowerCase()))
      })
      values.sort((a, b) => this.getAutosuggestValue({item: a}).localeCompare(this.getAutosuggestValue({item: b})))
      if (this.question.groups.length > 0) {
        this.autosuggestItems = this.question.groups.map(g => ({
          name: g,
          data: values.filter(v => v.GroupName === g),
          onSelected: this.onAutosuggestSelected
        })).filter(item => item.data.length > 0)
      } else {
        this.autosuggestItems = [{data: values}]
      }
    },
    onAutosuggestSelected (suggestion) {
      if (suggestion !== null) {
        this.selected = this.getAutosuggestValue(suggestion)
        this.onAutosuggestInput(this.getAutosuggestValue(suggestion))
        this.setValue(suggestion.item.Value)
      } else {
        this.selected = null
        this.onAutosuggestInput('')
        this.setValue(null)
      }
    }
  },

  mounted () {
    if (this.isAutosuggest) this.onAutosuggestInput('')
  }
}
</script>

<style scoped>
.question {
  border-bottom: 1px solid white;
}
.vertical-chip {
  height: auto !important;
  min-height: 32px;
  white-space: normal;
}

select {
  max-width: 100%;
}
select option {
  margin-left: 5ex;
}

table {
  border-collapse: collapse;
}

.value {
  text-align: center;
}
</style>

<style>
/* Styling of VueAutosuggest */
.autosuggest__results-container {
  position: relative;
  width: 100%;
  flex-basis: 100%;
}
.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  color: black;
  padding: 0px;
  overflow-y: scroll;
  max-height: 30vh;
}
.autosuggest__results ul {
  list-style-type: none;
}
.autosuggest__results li {
  padding: 0.25em 1ex;
  cursor: default;
}
.autosuggest__results li:hover,
.autosuggest__results li:focus,
.autosuggest__results li:active,
.autosuggest__results li.autosuggest__results-item--highlighted {
  background: aliceblue;
}
.autosuggest__results li.autosuggest__results-before {
  text-transform: uppercase;
  font-size: smaller;
  opacity: 0.85;
  padding-left: 0.5ex;
  background: inherit;
  border: none;
  margin: 0.25em 0 0 0;
}
.autosuggest__results .autosuggest__results-after {
  height: 2px;
  padding: 0;
  margin: 0;
  background: steelblue;
}
.autosuggest__input {
  color: white;
}
.autosuggest > div[role="combobox"] {
  flex-grow: 1;
}
</style>
