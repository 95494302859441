import * as Msal from '@azure/msal-browser'
import config from '@/config/auth'
import Lo from 'lodash'

const Claims = {
  POLICY: 'tfp',
  POLICY_ALTERNATIVE: 'acr',
  AUTH_TIME: 'auth_time',
  EXPIRES_AT: 'exp',
  ISSUED_AT: 'iat',
  USER_ID: 'oid',
  USER_ID_ALTERNATIVE: 'sub',
  MFA_REQUIRED: 'extension_MfaRequired'
}

const instance = new Msal.PublicClientApplication({
  auth: {
    clientId: config.clientId,
    authority: getAuthority(config.tenantName, config.policies.signIn),
    knownAuthorities: [getKnownAuthority(config.tenantName)],
    redirectUri: config.redirectUri,
    postLogoutRedirectUri: config.postLogoutRedirectUri,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
})

const request = {
  scopes: config.scopes.slice()
}

function getAccount () {
  const accounts = instance.getAllAccounts()
  return Lo(accounts).orderBy(a => a.idTokenClaims?.iat, 'desc').first()
}

async function signIn (returnUrl, locale, forceLogin, policyName) {
  const r = {...request}

  let relativeHref
  if (returnUrl === true) {
    // Get relative return URL
    relativeHref = window.location.href.substring(window.origin.length)
  } else if (typeof returnUrl === 'string') {
    relativeHref = returnUrl
  }
  if (relativeHref != null) {
    addReturnUrlToRequest(r, relativeHref)
  }

  r.loginHint = getAccount()?.username

  if (locale != null) addLocaleToRequest(r, locale)
  if (forceLogin === true) r.prompt = 'login'
  if (policyName != null) r.authority = getAuthority(config.tenantName, policyName)

  try {
    await instance.loginRedirect(r)
  } catch (e) {
    if (e instanceof Msal.BrowserAuthError && e.errorCode === 'interaction_in_progress') {
      await instance.handleRedirectPromise()
      await signOut(relativeHref)
    } else {
      throw e
    }
  }
}

async function acquireToken () {
  const account = getAccount()
  if (account == null) return null

  try {
    const response = await instance.acquireTokenSilent({account, ...request})
    return {
      fromCache: response.fromCache,
      accessToken: response.accessToken,
      userId: getUserIdFromIdToken(response),
      ...getIdTokenTimes(response)
    }
  } catch (error) {
    if (error instanceof Msal.InteractionRequiredAuthError) {
      // Do no make a redirect
      // The app should require a user to sign in
      return null
    }
  }
}

async function signOut (redirectUri = undefined) {
  try {
    await instance.logoutRedirect({ postLogoutRedirectUri: redirectUri })
  } catch (e) {
    if (e instanceof Msal.BrowserAuthError && e.errorCode === 'interaction_in_progress') {
      await instance.handleRedirectPromise()
      await instance.logoutRedirect({ postLogoutRedirectUri: redirectUri })
    } else {
      throw e
    }
  }
}

async function handleRedirect () {
  const hash = window.location.hash

  let response
  try {
    response = await instance.handleRedirectPromise()
  } catch (error) {
    return onHandleRedirectError(error, hash)
  }

  return response?.account != null
    ? onHandleRedirectAuthenticated(response)
    : null
}

async function onHandleRedirectError (error, hash) {
  const encodedState = getEncodedStateFromHash(hash)
  const state = decodeState(encodedState)

  const r = {...request, state: encodedState}
  if (state.locale != null) addLocaleToRequest(r, state.locale)

  if (error.errorMessage.indexOf('AADB2C90047') >= 0) {
    // Probably blocked by Ad blocker
    return {
      adBlocked: true,
      whiteList: getKnownAuthority(config.tenantName),
      locale: state.locale
    }
  } else {
    console.warn('AADB2C authentication error', error)
  }
  return null
}

async function onHandleRedirectAuthenticated (response) {
  const policy = response.idTokenClaims[Claims.POLICY] ??
    response.idTokenClaims[Claims.POLICY_ALTERNATIVE]
  const state = decodeState(response.state)

  const isSignInMfa = policy.toLowerCase() === config.policies.signInMfa.toLowerCase()
  if (getMfaRequiredFromIdToken(response) && !isSignInMfa) {
    // Authenticated via the "sign in" flow, but MFA is required =>
    // reauthenticate with the "sign in with MFA" flow
    const r = {
      ...request,
      authority: getAuthority(config.tenantName, config.policies.signInMfa),
      loginHint: response.account.username,
      state: response.state
    }
    if (state.locale != null) addLocaleToRequest(r, state.locale)
    await instance.loginRedirect(r)
    return null
  } else {
    // Good authentication
    const relativeHref = state.returnUrl
    let url = new URL(relativeHref, window.location.origin)
    if (url.origin !== window.location.origin) {
      url = window.location.origin
    }
    return {
      returnPath: url.href.substring(url.origin.length),
      locale: state.locale,
      userId: getUserIdFromIdToken(response),
      ...getIdTokenTimes(response)
    }
  }
}

function getAuthority (tenantName, policyName) {
  return `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policyName}`
}

function getKnownAuthority (tenantName) {
  return `${tenantName}.b2clogin.com`
}

function getIdTokenTimes (response) {
  // Convert epoch time to milliseconds to match JS Date value
  return {
    authTime: response.idTokenClaims[Claims.AUTH_TIME] * 1000,
    expiresAt: response.idTokenClaims[Claims.EXPIRES_AT] * 1000,
    issuedAt: response.idTokenClaims[Claims.ISSUED_AT] * 1000
  }
}

function getUserIdFromIdToken (response) {
  return response.idTokenClaims[Claims.USER_ID] ||
    response.idTokenClaims[Claims.USER_ID_ALTERNATIVE]
}

function getMfaRequiredFromIdToken (response) {
  const value = response.idTokenClaims[Claims.MFA_REQUIRED]
  return value === true || value === 1 || String(value).toLowerCase() === 'true'
}

function addReturnUrlToRequest (request, returnUrl) {
  mergeStateIntoRequest(request, { returnUrl })
}

function addLocaleToRequest (request, locale) {
  mergeStateIntoRequest(request, { locale })
  // Specify locale for AD B2C
  if (request.extraQueryParameters == null) request.extraQueryParameters = {}
  request.extraQueryParameters.ui_locales = locale
}

function mergeStateIntoRequest (request, newState) {
  let oldState = decodeState(request.state)
  const state = {...oldState, ...newState}
  request.state = encodeState(state)
}

function decodeState (encodedState) {
  if (encodedState == null) return {}
  try {
    return JSON.parse(atob(encodedState)) || {}
  } catch {
    return {}
  }
}

function encodeState (state) {
  return btoa(JSON.stringify(state || {}))
}

function getEncodedStateFromHash (hash) {
  // Remove leading '#' character if any
  if (hash == null) hash = ''
  if (hash.startsWith('#')) hash = hash.substring(1)
  // There should be a query string containing 'state' value
  const query = new URLSearchParams(hash)
  const state = query.get('state')
  // MSAL state puts its own state plus user-defined state,
  // concatenating them with '|' => try to split
  return state == null ? state : state.split('|')[1]
}

/** Adds $msal property to Vue instances */
export default {
  install (Vue) {
    Vue.prototype.$msal = {
      isAuthenticated () {
        return getAccount() != null
      },
      isAuthenticatedWithMfa () {
        const claims = getAccount()?.idTokenClaims
        if (claims == null) return false
        const policyName = claims[Claims.POLICY] ?? claims[Claims.POLICY_ALTERNATIVE]
        return policyName?.toLowerCase() === config.policies.signInMfa.toLowerCase()
      },
      signIn (saveCurrentUrl = true, locale = undefined) {
        return signIn(saveCurrentUrl, locale, false, undefined)
      },
      signInMfa (returnUrl = undefined, locale = undefined) {
        return signIn(returnUrl ?? true, locale, true, config.policies.signInMfa)
      },
      acquireToken,
      signOut,
      handleRedirect
    }
  }
}
