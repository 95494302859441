<template>
  <div>
    <mpro-editable-container class="min-card-width"
                             :validate-callback="validate" :save-callback="save"
                             @end-edit="onEndEdit">
      <template #title><h2>{{$t('account.details_title')}}</h2></template>
      <template #default="{readonly}">
        <v-form>
          <v-row justify="center">
            <v-col cols="12" md="4">
              <v-avatar class="MProCoral ma-auto d-block" size="100" rounded large>
                <v-icon color="MProWhite" large>mdi-account</v-icon>
              </v-avatar>
              <v-divider class="mt-4 mb-3"/>
              <transition name="fade" mode="out-in">
                <v-textarea v-if="!readonly" :label="$t('person_profile.about')" :hint="$t('person_profile.about_hint')"
                            v-model.trim="seekerData.about" :readonly="readonly" :hide-details="readonly"
                            @input="$v.seekerData.about.$touch()" @blur="$v.seekerData.about.$touch()"
                            :error-messages="$validationErrors($v.seekerData.about)"/>
                <div v-if="readonly">
                  <span class="text-caption text--secondary">{{$t('person_profile.about')}}</span>
                  <p>{{seekerData.about}}</p>
                </div>
              </transition>
            </v-col>
            <v-col cols="12" md="8" transition="fade-transition">
              <transition name="fade" mode="out-in" tag="v-container">
                <v-row v-if="!readonly" key="editing">
                  <v-col cols="6">
                    <v-text-field :label="$t('person_profile.first_name')" v-model.trim="seekerData.firstName"
                                  :readonly="readonly" :hide-details="readonly" class="d-inline-block"
                                  @input="$v.seekerData.firstName.$touch()" @blur="$v.seekerData.firstName.$touch()"
                                  :error-messages="$validationErrors($v.seekerData.firstName)"/>
                    <v-text-field :label="$t('person_profile.last_name')" class="d-inline-block ml-1" v-model.trim="seekerData.lastName"
                                  :readonly="readonly" :hide-details="readonly"
                                  @input="$v.seekerData.lastName.$touch()" @blur="$v.seekerData.lastName.$touch()"
                                  :error-messages="$validationErrors($v.seekerData.lastName)"/>
                  </v-col>
                  <v-col cols="6">
                    <mpro-date-box :label="$t('person_profile.birth_date')" v-model="seekerData.birthDate"
                                   :readonly="readonly" :hide-details="readonly"
                                   :birthdayPicker="true" :max="new Date()"
                                   @input="$v.seekerData.birthDate.$touch()" @blur="$v.seekerData.birthDate.$touch()"
                                   :error-messages="$validationErrors($v.seekerData.birthDate, birthDateValidationStrings)"/>
                  </v-col>
                  <v-col cols="6">
                    <v-select :label="$t('person_profile.gender')" v-model="seekerData.gender"
                              :readonly="readonly" :hide-details="readonly"
                              :items="genderItems" class="d-inline-block"
                              @input="$v.seekerData.gender.$touch()" @blur="$v.seekerData.gender.$touch()"
                              :error-messages="$validationErrors($v.seekerData.gender)"/>
                  </v-col>
                  <v-col cols="6">
                    <mpro-country-select :label="$t('person_profile.country_of_residence')" v-model="seekerData.country"
                                         readonly :hide-details="readonly"
                                         :hint="$t('person_profile.country_of_residence_hint')"/>
                  </v-col>
                  <v-col v-if="!isSubordinate" cols="6">
                    <v-text-field :label="$t('person_profile.phone')" :hint="$t('person_profile.phone_hint')"
                                  type="tel" v-model.trim="seekerData.phone"
                                  :readonly="readonly" :hide-details="readonly"
                                  @input="$v.seekerData.phone.$touch()" @blur="$v.seekerData.phone.$touch()"
                                  :error-messages="$validationErrors($v.seekerData.phone)"/>
                  </v-col>
                </v-row>
                <v-row v-else key="notediting">
                  <v-col cols="6">
                    <span class="text-caption text--secondary">{{$t('person_profile.name')}}</span>
                    <p>{{seekerData.firstName}} {{seekerData.lastName}}</p>
                  </v-col>
                  <v-col cols="6">
                    <span class="text-caption text--secondary">{{$t('person_profile.age')}}</span>
                    <p>{{seekerAge}}</p>
                  </v-col>
                  <v-col cols="6">
                    <span class="text-caption text--secondary">{{$t('person_profile.gender')}}</span>
                    <p>{{seekerData.gender}}</p>
                  </v-col>
                  <v-col cols="6">
                    <span class="text-caption text--secondary">{{$t('person_profile.country_of_residence')}}</span>
                    <p>{{$isoCountries.getName(seekerData.country, $i18n.locale)}}</p>
                  </v-col>
                  <v-col v-if="!isSubordinate" cols="6">
                    <span class="text-caption text--secondary">{{$t('person_profile.phone')}}</span>
                    <p>{{seekerData.phone}}</p>
                  </v-col>
                </v-row>
              </transition>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </mpro-editable-container>
  </div>
</template>

<script>
import EditableContainer from '@/components/elements/editable-container'
import DateBox from '@/components/elements/date-box'
import CountrySelect from '@/components/elements/country-select'
import {required, maxValue} from 'vuelidate/lib/validators'
import {Gender, MessageKind} from '@/helpers/enums'
import Utils from '@/helpers/utils'
import {mapState, mapActions} from 'vuex'
import moment from 'moment'

export default {
  components: {
    'mpro-editable-container': EditableContainer,
    'mpro-date-box': DateBox,
    'mpro-country-select': CountrySelect
  },

  props: {
    subordinateId: String
  },

  data: () => ({
    seekerData: {
      firstName: '',
      lastName: '',
      gender: '',
      birthDate: null,
      country: '',
      phone: '',
      about: ''
    },
    editing: false,
    metric: false,
    readonly: false
  }),

  validations () {
    const birthDate = { required }
    if (!this.isSubordinate) {
      birthDate.ageOver18 = maxValue(moment().endOf('date').subtract(18, 'years'))
    }
    return {
      seekerData: {
        firstName: { required },
        lastName: { required },
        gender: { required },
        birthDate,
        country: { required },
        phone: {},
        about: {}
      }
    }
  },

  computed: {
    genderItems: function () {
      return Object.values(Gender).map(g => ({value: g, text: this.$t('enums.Gender.' + g)}))
    },
    birthDateValidationStrings: function () {
      return {
        ageOver18: this.$t('person_profile.validations.ageOver18')
      }
    },
    weight: function () {
      let test = Math.round(this.$units(80).from('kg').to((this.metric ? 'kg' : 'lb')) * 10) / 10
      return test + (this.metric ? 'kg' : 'lb')
    },
    seekerAge: function () {
      return this.seekerData?.birthDate == null ? '' : this.$formatAgeFromBirthDate(this.seekerData.birthDate)
    },
    isSubordinate: function () {
      return this.subordinateId != null
    },
    seekerProfile: function () {
      return this.isSubordinate
        ? this.seeker.subordinates.find(sub => sub.Id === this.subordinateId)
        : this.seeker
    },
    ...mapState('user', {
      seeker: state => state.seeker
    })
  },

  watch: {
    seekerProfile: function () {
      this.seekerProfileToData()
    }
  },

  methods: {
    seekerProfileToData () {
      this.seekerData.firstName = this.seekerProfile.FirstName
      this.seekerData.lastName = this.seekerProfile.LastName
      this.seekerData.gender = this.seekerProfile.Gender
      this.seekerData.birthDate = this.seekerProfile.BirthDate ? Utils.parseIsoDate(this.seekerProfile.BirthDate) : null
      this.seekerData.country = this.seekerProfile.CountryCode
      this.seekerData.phone = this.seekerProfile.Phone
      this.seekerData.about = this.seekerProfile.About
    },
    validate () {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    async save () {
      const profile = {
        ...this.seekerData,
        birthDate: Utils.toIsoDate(this.seekerData.birthDate),
        // Cannot edit the country
        country: undefined
      }
      try {
        if (this.isSubordinate) {
          await this.updateSubordinateSeeker({ subordinateId: this.subordinateId, profile })
        } else {
          await this.updateSeekerProfile({ profile })
        }
        return true
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e)
        })
        return false
      }
    },
    onEndEdit (saved) {
      if (saved) {
        this.ensureUserDataLoaded({force: true})
      } else {
        this.seekerProfileToData()
      }
    },
    ...mapActions(['showGlobalMessage']),
    ...mapActions('user', ['ensureUserDataLoaded', 'updateSeekerProfile', 'updateSubordinateSeeker'])
  },

  created () {
    this.seekerProfileToData()
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
