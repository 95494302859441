<template>
  <div class="w-100pc d-flex flex-column align-stretch">
    <mpro-seeker v-if="isSeeker"/>
    <mpro-specialist v-if="isSpecialist"/>
    <mpro-administrator v-if="isAdministrator"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Seeker from './dashboard-seeker'
import Specialist from './dashboard-specialist'
import Administrator from './dashboard-administrator'

export default {
  methods: {
  },
  computed: {
    ...mapGetters(['isSeeker', 'isSpecialist', 'isAdministrator'])
  },
  components: {
    'mpro-seeker': Seeker,
    'mpro-specialist': Specialist,
    'mpro-administrator': Administrator
  }
}
</script>
