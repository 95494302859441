











import Vue, { PropType } from 'vue'
import { Library } from '@/models/admin'

export default Vue.extend({
  props: {
    library: Object as PropType<Library>
  }
})
