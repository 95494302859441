<template>
  <div>
    <div class="d-flex align-center flex-wrap">
      <mpro-problem-areas :gender="gender" :areas="areas" class="ma-auto"
                          :clickable-areas="!readonly" @click-area="onClickArea"
                          style="width: 100%"/>
    </div>
    <div class="d-flex align-center flex-wrap">
      <v-chip-group column class="max-text-width">
        <v-chip v-for="a in areas" :key="a" close :close-icon="!readonly ? 'mdi-close' : ''" color="MProGreen"
        @click:close="onClickArea(a)">
          {{getAreaName(a)}}
        </v-chip>
      </v-chip-group>
    </div>
  </div>
</template>

<script>
import ProblemAreas from '../scans/problem-areas'
import {Gender} from '../../helpers/enums'

export default {
  components: {
    'mpro-problem-areas': ProblemAreas
  },

  props: {
    value: String,
    question: {type: Object, required: true},
    gender: {type: String, default: () => Gender.UNKNOWN},
    readonly: {type: Boolean, default: false}
  },

  computed: {
    areas: function () {
      return this.value ? this.value.split(/\s*,\s*/) : []
    }
  },

  methods: {
    getAreaName (area) {
      let name
      if (this.question.Show.includes('Name')) {
        const qv = this.question.values.find(v => v.Value === area)
        if (qv) name = qv.Name
      }

      if (this.question.Show.includes('Name') && this.question.Show.includes('Value') && name) {
        return `${area}: ${name}`
      } else if (this.question.Show.includes('Name') && name) {
        return name
      } else {
        return area
      }
    },
    onClickArea (area) {
      const index = this.areas.indexOf(area)
      let newAreas = this.areas.slice()
      if (index >= 0) {
        newAreas.splice(index, 1)
      } else {
        newAreas.push(area)
      }
      this.$emit('input', newAreas.join(','))
    }
  }
}
</script>

<style>
.problem-areas img{
  max-height: 450px !important;
}
.problem-areas-overlay {
  max-width: 450px !important;
}
</style>
