import Vue from 'vue'
import VueI18n from 'vue-i18n'
import detectNearestLocale from 'detect-nearest-locale'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const messages = loadLocaleMessages()

const fallbackLocale = 'en'
const availableLocales = Object.keys(messages)
if (!availableLocales.includes(fallbackLocale)) {
  console.error(`The fallback locale '${fallbackLocale}' is not available`)
}

// Get preferred locales from the browser settings
let preferredLocales = navigator.languages ?? [navigator.language]
// As we do not provide regional variations yet, keep only languages (first two letters)
// Otherwise, general locales will take precedence over regional locales even when set with a lower priority
preferredLocales = preferredLocales.map(language => language.substring(0, 2))
// If not present, add the fallback locale to the end, so it is selected
// if there's no match for available locales in browser languages
if (!preferredLocales.includes(fallbackLocale)) {
  preferredLocales.push(fallbackLocale)
}
const locale = detectNearestLocale(availableLocales, preferredLocales)

export default new VueI18n({
  locale,
  fallbackLocale,
  messages
})
