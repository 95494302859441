









































































import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import PersonalDetails from './personal-details.vue'
import SetPinCode from './set-pin-code.vue'
import PortalSettings from './portal-settings.vue'
import DataSharing from './data-sharing.vue'
import SubordinateDetails from './subordinate-details.vue'
import ControlAccount from './control-account.vue'
import ManageMfa, { ManageMfaRef } from './manage-mfa.vue'
import { SeekerInfo, SubordinateInfo } from '@/models/users'
import Utils from '@/helpers/utils'
import locationBuilder from '@/router/location-builder'

interface Data {
  activeTab: string,
  addingSubordinate: boolean,
  settingPinCode: boolean,
  addDataSharing: boolean,
  connectWithOrganization: string | null
}

export default Vue.extend({
  components: {
    'mpro-personal-details': PersonalDetails,
    'mpro-set-pin-code': SetPinCode,
    'mpro-portal-settings': PortalSettings,
    'mpro-data-sharing': DataSharing,
    'mpro-subordinate-details': SubordinateDetails,
    'mpro-control-account': ControlAccount,
    'mpro-manage-mfa': ManageMfa
  },

  data: (): Data => ({
    activeTab: '',
    addingSubordinate: false,
    settingPinCode: false,
    addDataSharing: false,
    connectWithOrganization: null
  }),

  computed: {
    ...mapState('user', {
      // eslint-disable-next-line
      seeker: (state: any): SeekerInfo => state.seeker
    }),
    ...mapGetters('seeker', ['activeSubordinate']),
    ...mapGetters(['getRole']),
    isSuperior: function (): boolean {
      return this.activeTab === this.seeker.Id
    },
    isSubordinate: function (): boolean {
      return this.subordinates.some(sub => sub.Id === this.activeTab) === true
    },
    subordinates: function (): SubordinateInfo[] {
      return this.seeker?.subordinates ?? []
    },
    manageMfaRef: function (): ManageMfaRef {
      return this.$refs.manageMfa as unknown as ManageMfaRef
    }
  },

  methods: {
    onAddSubordinateClick (): void {
      this.addingSubordinate = true
    },
    onNewSubordinateCancel (): void {
      this.addingSubordinate = false
    },
    onNewSubordinateSave (subordinateId: string): void {
      this.addingSubordinate = false
      if (this.subordinates.some(sub => sub.Id === subordinateId)) {
        this.activeTab = subordinateId
      }
    },
    async scrollToDataSharing () {
      // Give some time to render the contents
      await Utils.delay(100)
      const ds = this.$refs.dataSharing as Vue
      const el = ds.$el as HTMLElement
      el.scrollIntoView()
    },
    onDataSharingStartInteraction () {
      this.addDataSharing = false
      this.connectWithOrganization = null
    }
  },

  async mounted () {
    const action = Utils.singleQueryValue(this.$route.query.action)
    const seekerId = action != null
      ? (this.activeSubordinate?.Id ?? this.seeker.Id)
      : this.seeker.Id
    this.activeTab = seekerId

    if (action != null) {
      switch (action) {
        case 'view-data-sharings':
          await this.scrollToDataSharing()
          break
        case 'add-data-sharing':
          await this.scrollToDataSharing()
          this.addDataSharing = true
          break
        case 'connect-with-organization':
          await this.scrollToDataSharing()
          const v = this.$route.query.code
          const code = Array.isArray(v) ? v[0] : v
          this.connectWithOrganization = code
          break
        case 'enable-totp':
          await this.manageMfaRef.handleEnableTotp()
          break
        default:
          return
      }

      // Switch to an actionless route to prevent executing
      // the action when switching between subordinates
      this.$router.replace(locationBuilder.myAccount())
    }
  }
})
