<template>
  <div class="d-flex flex-column fill-height">
    <div v-if="isSeeker" class="d-block d-md-none">
      <p class="text-h6 text-center">{{ userName }}</p>
    </div>
    <div class="pa-6 pb-0 mb-auto flex-shrink-1 overflow-scroll" id="chat-window">
      <v-skeleton-loader v-if="messages == null" type="sentences@2" />
      <p class="d-flex justify-center flex-sm-shrink-0 mt-2" v-else-if="messages.length === 0">
        <v-chip v-if="login != null">{{$t('chats.no-messages') }}</v-chip>
      </p>
      <template v-else>
        <div v-for="(message, i) in messages" :key="message.Id">
          <p class="d-flex justify-center flex-sm-shrink-0 mt-2"
             v-if="messages[i-1] === undefined">
            <v-chip>{{ $formatDate(messages[i].CreatedAt, false) }}</v-chip>
          </p>
          <div class="d-flex"
               :class="isOwnComment(message) ? 'reciever flex-row-reverse' : 'sender flex-row'">
            <v-card class="my-1 MainGradient pa-0 chat-comment"
                    :class="isOwnComment(message) ? 'ml-10 right MProPurple sender' : 'mr-10 MProCoral reciever'"
                    outlined>
              <v-card-text class="ws-pre-line pt-2 px-2 pb-0 d-inline-block">
                <span v-linkify="message.Text" />
                <p v-if="!isOwnComment(message)" class="overline ml-2 mt-0 mb-0 text--secondary timestamp d-inline-block float-right" :class="isSpecialist ? 'MProBlack-text' : ''">
                  {{ $formatTime(message.CreatedAt, false) }}
                </p>
                <p v-if="isOwnComment(message)" class="overline ml-2 mt-0 mb-0 text--secondary timestamp d-inline-block float-right" :class="isSpecialist ? 'MProBlack-text' : ''">
                  {{ $formatTime(message.CreatedAt, false) }}
                  <v-icon v-if="message.ReadFlag" class="MProBlue-text">mdi-check-all</v-icon>
                  <v-icon v-else>mdi-check</v-icon>
                </p>
                <p v-for="link in JSON.parse(message.MessageLinks)" :key="link.Num" @click="getLinkedItemRoute(link.Kind, link.ObjectId)" class="chat-links text-right mt-2">{{getLinkText(link.Kind)}}</p>
              </v-card-text>
            </v-card>
          </div>
          <p class="d-flex justify-center flex-shrink-0 mt-2"
             v-if="messages[i+1] !== undefined && !$moment(messages[i].CreatedAt).isSame($moment(messages[i+1].CreatedAt), 'day')">
            <v-chip>{{ $formatDate(messages[i+1].CreatedAt, false) }}</v-chip>
          </p>
        </div>
      </template>
    </div>
    <div class="justify-end" id="message-area">
      <v-form v-if="canPost" ref="postMessage">
        <div v-if=" getLinkedItem" class="chat-linking-from"><p><v-icon @click="getLinkedItemRoute(postMessageLink.type, postMessageLink.objectId)">mdi-reply-outline</v-icon>{{getLinkedItem}}<v-icon class="float-right" @click="skipLink = true">mdi-close</v-icon></p></div>
        <v-textarea v-model="message" ref="messageArea"
                    id="chat-input"
                    @input="maxHeight()"
                    :rules="messageRules"
                    solo :shaped="getLinkedItem ? false : true"
                    :placeholder="$t('chats.type-message-placeholder')"
                    append-outer-icon="mdi-send"
                    rows="1"
                    auto-grow
                    @click:append-outer="postMessage"
                    :loading="isPosting" :disabled="isPosting"/>
      </v-form>
      <v-alert v-else-if="isSeeker" dense text type="info">
        {{ $t('chats.cannot-chat-with-specialist') }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import Lo from 'lodash'
import { MessageKind } from '@/helpers/enums'

export default {
  props: {
    login: String,
    userName: String
  },

  data: () => ({
    message: '',
    messageRules: [
      m => m == null || m.trim() === '' ? 'Nothing to post' : true
    ],
    maxRows: 4,
    isPosting: false,
    skipLink: false,
    messages: null,
    updatingMessages: [],
    canPost: false
  }),

  computed: {
    ...mapGetters(['isSeeker', 'isSpecialist']),
    ...mapGetters('user', ['getUserEmail']),
    ...mapGetters('specialist', ['getSeekerByEmail']),
    ...mapGetters('chats', ['getMessagesFor', 'canChatWith']),
    ...mapState('chats', ['partners']),
    postMessageLink: function () {
      if (!this.$route.query || !this.$route.query.type || !this.$route.query.objectId || this.skipLink) return null
      return {type: this.$route.query.type, objectId: this.$route.query.objectId}
    },
    getLinkedItem () {
      if (!this.postMessageLink) return null
      let str = ''
      switch (this.postMessageLink.type) {
        case 'ExerciseProgram':
          str = this.$t('chats.linked-to-exercise-program')
          break
        case 'ScanComment':
          str = this.$t('chats.linked-to-scan-comment')
          break
        case 'Goal':
          str = this.$t('chats.linked-to-goal')
          break
        default:
          break
      }
      return str
    },
    lastMessageCreatedAt: function () {
      return this.partners.find(p => p.Login === this.login)?.LastMessageTime
    }
  },

  watch: {
    login: function () {
      this.updateMessages()
      this.updateCanPost()
    },
    messages: function () {
      this.updateWindow()
    },
    lastMessageCreatedAt: function (newValue) {
      if (this.updatingMessages.length === 0) {
        const differs = newValue != null && this.messages != null &&
          newValue !== Lo.last(this.messages)?.CreatedAt
        if (differs) {
          this.updateMessages()
        }
      }
    }
  },

  methods: {
    ...mapActions('chats', ['ensureMessagesLoaded', 'postChatMessage', 'updateCanChatWith']),
    ...mapActions(['showGlobalMessage']),
    ...mapMutations('specialist', ['updateLastChattedToAt']),
    async updateMessages (forceReload) {
      const login = this.login
      if (login == null) {
        this.messages = []
        return
      }

      // Initialize to cached messages, so they are displayed immediately
      // after a selected chat changes. And then forcefully get fresh
      // messages from the backend.
      // Use stack for updatingMessages to handle parallel updates
      // correctly if they occur.
      this.updatingMessages.push(1)
      this.messages = this.getMessagesFor(login)
      await this.ensureMessagesLoaded({ partnerLogin: login, force: forceReload })
      // Ensure we're still viewing the same chat before updating messages
      if (this.login === login) {
        this.messages = this.getMessagesFor(login)
      }
      this.updatingMessages.pop()
    },
    async updateCanPost () {
      const login = this.login
      if (login == null) {
        this.canPost = false
        return
      }

      this.canPost = this.canChatWith(login)

      // Ensure we do not rely on expired value and refresh asynchronously
      await this.updateCanChatWith({ partnerLogin: login })
      if (this.login === login) {
        this.canPost = this.canChatWith(login)
      }
    },
    isOwnComment (message) {
      if (message === undefined) return
      return message.Author === this.getUserEmail
    },
    getLinkedItemRoute (kind, id) {
      var routeObject = {name: '', params: {}, query: {}}
      switch (kind) {
        case 'ExerciseProgram':
          routeObject.name = this.isSpecialist ? 'clientExercises' : 'MyExercises'
          routeObject.params['seekerId'] = this.isSpecialist ? this.getSeekerByEmail(this.login).Id : ''
          routeObject.query['open'] = id
          break
        case 'ScanComment':
          id = id.split('|')
          routeObject.name = 'Scan'
          routeObject.params['scanId'] = id[0]
          routeObject.query['open'] = id[1]
          break
        case 'Goal':
          routeObject.name = this.isSpecialist ? 'clientGoals' : 'MyGoals'
          routeObject.params['seekerId'] = this.isSpecialist ? this.getSeekerByEmail(this.login).Id : ''
          routeObject.query['open'] = id
          break
        default:
          break
      }
      this.$router.push(routeObject)
    },
    getLinkText (kind) {
      let str = ''
      switch (kind) {
        case 'ExerciseProgram':
          str = this.$t('chats.send-from-exercise-program')
          break
        case 'ScanComment':
          str = this.$t('chats.send-from-scan-comment')
          break
        case 'Goal':
          str = this.$t('chats.send-from-goal')
          break
        default:
          break
      }
      return str
    },
    async postMessage () {
      if (!this.$refs.postMessage.validate()) return
      this.isPosting = true
      try {
        await this.postChatMessage({
          recipient: this.login,
          message: this.message,
          messageLink: this.postMessageLink
        })
      } catch (e) {
        console.log('errorMessage', e)
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e)
        })
        return
      } finally {
        this.isPosting = false
      }

      this.$refs.postMessage.reset()
      if (!Lo.isEmpty(this.$route.query)) {
        this.$router.replace({ query: null })
      }
      this.updateMessages(true)

      if (this.isSpecialist) {
        this.updateLastChattedToAt(this.login)
      }

      this.$emit('postmessage')
    },
    async updateWindow () {
      if (this.login != null) {
        await this.$nextTick()
        this.onResize()
      }
    },
    onResize () {
      let element = document.getElementById('chat-window')
      if (element) {
        element.scrollTop = element.scrollHeight
      }
    },
    maxHeight () {
      let element = document.getElementById('chat-input')
      if (element) {
        element.style.maxHeight = `${this.maxRows * this.$refs.messageArea.rowHeight}px`
        element.scrollTop = element.scrollHeight
        element.style.overflowY = 'scroll' // for some reason the css doesn't work...
      }
      this.onResize()
    }
  },

  created () {
    this.updateMessages()
    this.updateCanPost()
  },
  mounted () {
    this.updateWindow()
    window.addEventListener('resize', this.onResize)
    this.$nextTick(() => {
      this.onResize()
      this.maxHeight()
    })
  }
}
</script>

<style>
#message-area .v-text-field.v-text-field--solo .v-input__control {
  overflow-y: auto;
}

#message-area .v-textarea .v-input__append-outer{
  align-self: flex-end;
  margin-bottom: 30px;
}

.v-application .overline.timestamp{
  font-size: .65rem!important;
  display: inline-block;
  line-height: 1.5rem;
}
.v-application .overline.timestamp .v-icon{
  font-size: .95rem;
}

.sender .v-card{
  border-radius: 50px 24px 24px 4px;

}
.reciever .v-card{
  border-radius: 24px 50px 4px 24px;
}

.overflow-scroll{
  overflow-y: auto;
}

.reciever .v-card__title{
  justify-content: flex-end;
}

.chat-links{
 cursor: pointer;
 clear: both;
}
.chat-linking-from {
    background: #ffffff7d;
    padding: 5px 10px 3px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
</style>

<style scoped>

.v-card.chat-comment {
  max-width: 75%;
  border-width: 2px;
}

</style>
