function getMiddlePosition (getPositionByPointIndex, pointIndices) {
  const points = pointIndices.map(pi => getPositionByPointIndex(pi))
  const pointsSum = points.reduce((sum, p) => sum == null || p == null ? null : { X: sum.X + p.X, Y: sum.Y + p.Y, Z: sum.Z + p.Z }, { X: 0, Y: 0, Z: 0 })
  return pointsSum == null
    ? null
    : { X: pointsSum.X / pointIndices.length,
      Y: pointsSum.Y / pointIndices.length,
      Z: pointsSum.Z / pointIndices.length
    }
}

function getAveragePositionOverRoi (frames, fromInclusive, toExclusive, getPositionByFrame) {
  let i = 0
  while (i < frames.length && frames[i].FrameIndex < fromInclusive) i++
  let sum = { X: 0, Y: 0, Z: 0 }
  let count = 0
  while (i < frames.length && frames[i].FrameIndex < toExclusive) {
    const p = getPositionByFrame(i)
    if (p != null) {
      sum.X += p.X
      sum.Y += p.Y
      sum.Z += p.Z
      count++
    }
    i++
  }
  return count === 0 ? undefined : { X: sum.X / count, Y: sum.Y / count, Z: sum.Z / count }
}

export default class ReferencePointCalculator {
  calculate (trackingPointsData, assessment, referencePointNames) {
    const frames = trackingPointsData.Frames
    const lastFrameIndex = frames.length > 0 ? frames[frames.length - 1].FrameIndex : 0
    const roi = { fromInclusive: 0, toExclusive: lastFrameIndex + 1 }
    if (assessment != null) {
      if (assessment.SelectROI != null) {
        roi.fromInclusive = assessment.SelectROI.FromFrameIndexInclusive
        roi.toExclusive = assessment.SelectROI.ToFrameIndexExclusive
      } else if (assessment.SelectROILeft != null && assessment.SelectROIRight != null) {
        roi.fromInclusive = Math.min(assessment.SelectROILeft.FromFrameIndexInclusive, assessment.SelectROIRight.FromFrameIndexInclusive)
        roi.toExclusive = Math.max(assessment.SelectROILeft.ToFrameIndexExclusive, assessment.SelectROIRight.ToFrameIndexExclusive)
      }
    }

    if (referencePointNames != null) {
      const indices = referencePointNames.map(rp => trackingPointsData.TrackingPoints.indexOf(rp))
      if (indices.every(i => i >= 0)) {
        return getAveragePositionOverRoi(
          trackingPointsData.Frames, roi.fromInclusive, roi.toExclusive,
          frameIndex => getMiddlePosition(
            pointIndex => {
              return trackingPointsData.Frames[frameIndex].PointsPositions[pointIndex]
            },
            indices))
      }
    }

    return undefined
  }
}
