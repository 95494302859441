<template>
  <v-list nav>
    <v-list-item v-for="item in items" :key="item.Login"
                  :to="getLocationOfChat(item)">
      <v-list-item-content>
        <v-list-item-title>
          {{ getPartnerName(item) }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.LastMessageText }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-show="item.UnreadCount > 0">
          <v-badge color="MProCoral"
                   :content="item.UnreadCount.toString()" />
      </v-list-item-action>
    </v-list-item>
  </v-list >
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Lo from 'lodash'

export default {
  props: {
    activeLogin: String,
    activeName: String
  },

  computed: {
    ...mapGetters(['isSeeker']),
    ...mapState('chats', {
      chatPartners: state => state.partners
    }),
    ...mapGetters('chats', ['getChatUuid']),

    items: function () {
      const result = Lo(this.chatPartners)
        .filter(cp => this.isSeeker ? cp.IsSpecialist : cp.IsSeeker)
        .orderBy(cp => this.$moment(cp.LastMessageTime).valueOf(), 'desc')
        .value()

      // Add missing partner (in case of a new chat)
      if (this.activeLogin != null && !result.some(cp => cp.Login === this.activeLogin)) {
        result.unshift({
          Login: this.activeLogin,
          IsSeeker: !this.isSeeker,
          SeekerName: !this.isSeeker
            ? (this.activeName ?? this.activeLogin)
            : null,
          IsSpecialist: this.isSeeker,
          SpecialistName: this.isSeeker
            ? (this.activeName ?? this.activeLogin)
            : null,
          LastMessageText: null,
          LastMessageTime: null,
          UnreadCount: 0
        })
      }

      return result
    }
  },

  methods: {
    ...mapActions('chats', ['ensurePartnersLoaded']),

    getPartnerName (partner) {
      return (this.isSeeker ? partner.SpecialistName : partner.SeekerName) ?? partner.Login
    },
    getLocationOfChat (partner) {
      return {
        name: 'Chat',
        params: { userUuid: this.getChatUuid(partner.Login) }
      }
    }
  },

  created () {
    this.ensurePartnersLoaded()
  }
}
</script>
