<template>
  <v-menu offset-y bottom transition="slide-y-transition" min-width="200"
          :disable-keys="readonly" :open-on-click="!readonly"
          :close-on-content-click="false"
          v-model="popupOpen">
    <template #activator="{on}">
      <v-text-field v-model.trim="dateLocale"
                    :required="required" :rules="rules" :error-messages="errorMessages"
                    :label="label" :placeholder="placeholder" :persistent-placeholder="persistentPlaceholder"
                    :class="fieldClass" :hide-details="hideDetails"
                    :readonly="readonly" :clearable="clearable" clear-icon="mdi-eraser"
                    append-icon="mdi-calendar" :outlined="outlined" :dense="dense"
                    v-on="on" @blur="onFieldBlur" @click:append="if (!readonly) popupOpen = !popupOpen"
                    @click:clear="dateISO = undefined"/>
    </template>
    <v-date-picker v-model="dateISO" :min="dateMinISO" :max="dateMaxISO"
                   no-title @input="popupOpen = false"
                   :active-picker="activePicker"
                   :first-day-of-week="$moment.localeData().firstDayOfWeek()"/>
  </v-menu>
</template>

<script>
import Utils from '@/helpers/utils'

export default {
  props: {
    value: Date,
    min: Date,
    max: Date,
    required: Boolean,
    rules: Array,
    errorMessages: Array,
    readonly: Boolean,
    clearable: Boolean,
    hideDetails: Boolean,
    dense: { type: Boolean, default: false },
    label: String,
    fieldClass: String,
    birthdayPicker: Boolean,
    placeholder: String,
    persistentPlaceholder: { type: Boolean, default: false },
    outlined: Boolean
  },
  data: () => ({
    dateISO: undefined,
    dateLocale: undefined,
    popupOpen: false,
    activePicker: ''
  }),
  computed: {
    dateMinISO: function () {
      return this.min ? this.toISODateString(this.min) : undefined
    },
    dateMaxISO: function () {
      return this.max ? this.toISODateString(this.max) : undefined
    }
  },
  watch: {
    popupOpen () {
      this.birthdayPicker && !this.value && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    value: function () {
      this.onValueChanged()
    },
    min: function () {
      this.onValueChanged()
    },
    max: function () {
      this.onValueChanged()
    },
    dateISO: function () {
      this.syncLocaleWithISO()
      this.$emit('input', this.dateISO ? Utils.parseIsoDate(this.dateISO) : undefined)
    },
    readonly: function (newValue) {
      if (newValue) {
        this.popupOpen = false
      }
    }
  },

  methods: {
    onValueChanged () {
      let date = this.value
      if (!date) {
        this.dateISO = undefined
      } else {
        if (this.min && date < this.min) {
          date = this.min
        } else if (this.max && date > this.max) {
          date = this.max
        }
        this.dateISO = this.toISODateString(date)
      }
    },
    onFieldBlur (event) {
      if ((this.dateLocale || '') === '') {
        this.dateISO = undefined
      } else {
        const asDate = this.$moment(this.dateLocale, this.$moment.localeData().longDateFormat('L'))
        if (asDate.isValid()) {
          this.dateISO = this.toISODateString(asDate)
        } else {
          this.syncLocaleWithISO()
        }
      }

      this.$emit('blur', event)
    },
    syncLocaleWithISO () {
      this.dateLocale = this.dateISO ? this.$moment(this.dateISO).format('L') : undefined
    },
    toISODateString (date) {
      return date ? Utils.toIsoDate(date) : null
    }
  },

  created () {
    this.onValueChanged()
  }
}
</script>
