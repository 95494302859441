<template>
  <v-container v-if="survey != null" class="my-7 py-0">
    <v-card class="py-4 px-4 px-sm-7 px-md-14" hover>
      <v-card-title>
        {{ (survey.metadata && survey.metadata.Title) || survey.QuestionnaireCode }}
      </v-card-title>
      <v-card-subtitle>
        <v-container>
          <v-row>
            <v-col cols="12" class="d-flex flex-column">
              <span v-if="survey.AnsweredAt == null && survey.DeadlineDate != null">{{ $t('surveys.complete-by', {date: $formatDate(survey.DeadlineDate)}) }}</span>
              <span v-if="survey.AnsweredAt != null">{{ $t('surveys.completed-on', {date: $formatDateTime(survey.AnsweredAt)}) }}</span>
              <span v-else-if="survey.values && survey.values.length > 0" class="ws-pre-line">
                {{ survey.values[0].Notes }}
              </span>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn v-if="survey.AnsweredAt == null && isSeeker"
                     :to="{name: 'TakeSurvey', params: {questionnaireCode: survey.QuestionnaireCode}}"
                     class="MProGreen">
                {{ $t('surveys.buttons.take-survey') }}
              </v-btn>
              <v-btn v-if="survey.AnsweredAt != null"
                     :to="{name: 'ViewSurvey', params: {surveyId: survey.Id}}"
                     class="MProGreen">
                {{ $t('surveys.buttons.view-survey-results') }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" class="text-right">
              <span v-if="survey.AnsweredAt == null">{{ $t('surveys.survey-duration', {mins: 4}) }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-subtitle>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['survey'],
  computed: {
    ...mapGetters(['isSeeker'])
  },
  methods: {
  }
}
</script>
