var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-6"},[_c('div',{staticClass:"d-flex flex-wrap mb-6"},[_c('mpro-organization-filter',{staticClass:"flex-grow-0 mr-4",model:{value:(_vm.organizationCode),callback:function ($$v) {_vm.organizationCode=$$v},expression:"organizationCode"}}),_c('v-autocomplete',{staticClass:"flex-grow-0 mr-4",attrs:{"value":_vm.filterSeekerId,"spellcheck":false,"items":_vm.filterSeekerItems,"label":_vm.$t('admin.seeker-events.seeker'),"loading":_vm.$asyncComputed.filterSeekerItems.updating,"no-data-text":_vm.$t('forms.nothing_found'),"clearable":"","clear-icon":"mdi-eraser"},on:{"input":_vm.onFilterSeekerChanged}}),_c('mpro-datebox',{attrs:{"value":_vm.filterFrom,"label":_vm.$t('admin.seeker-events.from'),"clearable":"","field-class":"flex-grow-0 mr-4"},on:{"input":_vm.onFilterFromChanged}}),_c('mpro-datebox',{attrs:{"value":_vm.filterTill,"label":_vm.$t('admin.seeker-events.till'),"clearable":"","field-class":"flex-grow-0 mr-4"},on:{"input":_vm.onFilterTillChanged}})],1),_c('div',{staticClass:"d-flex"},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.loadedItems,"sort-by":_vm.tableSortBy,"sort-desc":_vm.tableSortDesc,"footer-props":{ itemsPerPageOptions: [15], showFirstLastPage: true },"no-data-text":_vm.$t('admin.no_records'),"loading":_vm.$asyncComputed.loadedItems.updating,"item-class":_vm.getItemClass},on:{"update:sortBy":function($event){_vm.tableSortBy=$event},"update:sort-by":function($event){_vm.tableSortBy=$event},"update:sortDesc":function($event){_vm.tableSortDesc=$event},"update:sort-desc":function($event){_vm.tableSortDesc=$event}},scopedSlots:_vm._u([{key:"item.Date",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$formatDateTime(value, false))+" ")]}},{key:"item.Event",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEventDescription(item))+" ")]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s((pageStart + " - " + pageStop + " / " + itemsLength))+" ")]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }