<template>
  <div>
    <v-skeleton-loader v-if="exerciseAttrs == null || exerciseInfo == null" type="card-avatar, date-picker"/>
    <v-card v-else class="exercise-card mx-auto pb-0 my-10 MProMain5" width="80%">
      <div class="video-container">
        <iframe :src="exerciseVideoUri"
                frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="max-width: 100%">
        </iframe>
      </div>
      <v-card-title>
        {{exerciseInfo.Name}}
      </v-card-title>
      <v-card-subtitle>
        <div>
          <h4 class="d-inline mr-2">{{ $t('exercises.goal') }}</h4>
          {{ exerciseInfo.Goal }}
        </div>
        <div>
          <h4>{{ $t('exercises.instruction') }}</h4>
          <mpro-read-more :text="exerciseInfo.Instruction" :charCount="100"></mpro-read-more>
        </div>
      </v-card-subtitle>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6" lg="4" class="px-0">
              <v-card-title class="text-center d-block">
                {{ exerciseAttrs.DurationSeconds == null ? '-' : $formatDurationSeconds(exerciseAttrs.DurationSeconds) }}
              </v-card-title>
              <v-card-subtitle class="text-center">
                {{$t('exercises.edit.fields.duration')}}
              </v-card-subtitle>
            </v-col>
            <v-col cols="6" lg="4" class="px-0">
              <v-divider vertical class="float-left"></v-divider>
              <v-card-title class="text-center d-block">
                {{ exerciseAttrs.Sets }}
              </v-card-title>
              <v-card-subtitle class="text-center">
                {{$t('exercises.edit.fields.sets')}}
              </v-card-subtitle>
            </v-col>
            <v-col cols="6" lg="4" class="px-0">
              <v-divider vertical class="float-left d-none d-lg-block"></v-divider>
              <v-card-title class="text-center d-block">
                {{ exerciseAttrs.Repetitions }}
              </v-card-title>
              <v-card-subtitle class="text-center">
                {{$t('exercises.edit.fields.repetitions')}}
              </v-card-subtitle>
            </v-col>
            <v-col cols="6" lg="4" class="px-0">
              <v-divider vertical class="float-left d-block d-lg-none"></v-divider>
              <v-card-title class="text-center d-block">
                {{ exerciseAttrs.Resistance || '-' }}
              </v-card-title>
              <v-card-subtitle class="text-center">
                {{$t('exercises.edit.fields.resistance')}}
              </v-card-subtitle>
            </v-col>
            <v-col cols="6" lg="4" class="px-0">
              <v-divider vertical class="float-left d-none d-lg-block"></v-divider>
              <v-card-title class="text-center d-block">
                {{ exerciseAttrs.Exertion == null ? '-' : `${exerciseAttrs.Exertion} / 10` }}
              </v-card-title>
              <v-card-subtitle class="text-center">
                {{$t('exercises.edit.fields.exertion')}}
              </v-card-subtitle>
            </v-col>
            <v-col cols="6" lg="4" class="px-0">
              <v-divider vertical class="float-left"></v-divider>
              <v-card-title class="text-center d-block">
                {{ exerciseAttrs.RestSeconds == null ? '-' : $formatDurationSeconds(exerciseAttrs.RestSeconds) }}
              </v-card-title>
              <v-card-subtitle class="text-center">
                {{$t('exercises.edit.fields.rest')}}
              </v-card-subtitle>
            </v-col>
          </v-row>
          </v-container>
      </v-card-text>
      <v-card-text v-if="exerciseAttrs.Notes" class="MProCoral">
        <mpro-read-more class="MProCoral" :text="exerciseAttrs.Notes" linkify />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import readMore from './../elements/read-more'

export default {
  props: {
    programId: String,
    exerciseId: String
  },

  components: {
    'mpro-read-more': readMore
  },

  computed: {
    ...mapGetters('exercises', ['getSingleExerciseMeta', 'getSingleAssignedExercise']),
    exerciseAttrs: function () {
      return this.getSingleAssignedExercise(this.programId, this.exerciseId)
    },
    exerciseInfo: function () {
      return this.getSingleExerciseMeta(this.exerciseId)
    },
    exerciseVideoUri: function () {
      return this.exerciseInfo?.VideoPlayerUri ?? ''
    }
  }
}
</script>

<style scoped>
.video-container {
  position:relative;
  padding-bottom:56.25%;
  padding-top:30px;
  height:0;
  overflow:hidden;
  border-top-left-radius:14px;
  border-top-right-radius:14px;
}

.video-container iframe, .video-container object, .video-container embed {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}
</style>
