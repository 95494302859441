import * as THREE from 'three'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader'
import imageDouble from '../../assets/img/feet_anc.svg'
import imageLeft from '../../assets/img/foot_left_anc.svg'
import imageRight from '../../assets/img/foot_right_anc.svg'

const TYPICAL_PERSON_HEIGHT = 1.8
const TYPICAL_HEIGHT_TO_FOOT_SCALE = 6.5
const TYPICAL_DISTANCE_BETWEEN_ANKLES = 0.24

function loadSvg (filePath) {
  // Check that paths in SVG do not contain numbers in the exponential format (like "7.9999972e-4").
  // Convert such numbers to the simple decimal format if any.
  // It seems that SVGLoader doesn't support this format properly.
  return new Promise(resolve => {
    const loader = new SVGLoader()
    loader.load(filePath,
      data => resolve(data),
      undefined,
      error => {
        console.warn('Error loading SVG from ', filePath, error)
        resolve()
      })
  })
}

function makeMesh (svgPath, color) {
  const material = new THREE.MeshBasicMaterial({
    color: color || 0x000000,
    side: THREE.DoubleSide,
    depthWrite: false
  })
  const shapes = svgPath.toShapes(true)
  const group = new THREE.Object3D()
  for (let j = 0; j < shapes.length; j++) {
    const shape = shapes[j]
    const geometry = new THREE.ShapeGeometry(shape)
    const mesh = new THREE.Mesh(geometry, material)
    mesh.name = svgPath.userData.node.id
    mesh.matrixAutoUpdate = true
    group.add(mesh)
  }
  return group
}

function computeScale (svgPaths, personHeight) {
  const distanceSample = svgPaths.find(p => p.userData.node.id === 'distance-sample')
  const footLengthSvg = distanceSample.userData.node.height.baseVal.value
  const footLength = personHeight / TYPICAL_HEIGHT_TO_FOOT_SCALE
  return footLength / footLengthSvg
}

function computeCenter (svgPaths) {
  const origin = makeMesh(svgPaths.find(p => p.userData.node.id === 'origin'))
  let originBox = new THREE.Box3().setFromObject(origin)
  const center = new THREE.Vector3()
  originBox.getCenter(center)
  return center
}

function translateCenter (object, center, scale) {
  object.rotateX(0.5 * Math.PI)
  object.rotateY(2 * Math.PI)
  object.translateX(-center.x * scale)
  object.translateY(-center.y * scale)
  object.translateZ(-center.z * scale)
  object.scale.set(scale, scale, scale)
}

export default class FeetBuilder {
  constructor () {
    this.svgData = {
      double: null,
      left: null,
      right: null
    }
    this.color = 0x8B8B8B
  }

  async ensureSvgLoaded () {
    if (this.svgData.double == null) this.svgData.double = await loadSvg(imageDouble)
    if (this.svgData.left == null) this.svgData.left = await loadSvg(imageLeft)
    if (this.svgData.right == null) this.svgData.right = await loadSvg(imageRight)
  }

  buildDouble (personHeight, feetDistance) {
    if (this.svgData.double == null) {
      return new THREE.Group()
    }

    if (personHeight == null) personHeight = TYPICAL_PERSON_HEIGHT
    if (feetDistance == null) feetDistance = TYPICAL_DISTANCE_BETWEEN_ANKLES
    const paths = this.svgData.double.paths

    const scale = computeScale(paths, personHeight)
    const center = computeCenter(paths)

    const leftFoot = makeMesh(paths.find(p => p.userData.node.id === 'left-foot-obj'), this.color)
    const rightFoot = makeMesh(paths.find(p => p.userData.node.id === 'right-foot-obj'), this.color)
    translateCenter(leftFoot, center, scale)
    translateCenter(rightFoot, center, scale)

    // Adjust distance between the feet
    const defaultFeetDistanceSvg = paths.find(p => p.userData.node.id === 'feet-distance').userData.node.width.baseVal.value * scale
    const feetSpaceSvg = paths.find(p => p.userData.node.id === 'feet-space').userData.node.width.baseVal.value * scale
    let footShiftSvg = (0.5 * (feetDistance - defaultFeetDistanceSvg))
    if (2 * footShiftSvg < -feetSpaceSvg) {
      footShiftSvg = -(0.5 * feetSpaceSvg)
    }
    leftFoot.translateX(footShiftSvg)
    rightFoot.translateX(-footShiftSvg)

    const result = new THREE.Group()
    result.add(leftFoot, rightFoot)
    return result
  }

  buildLeft (personHeight) {
    if (this.svgData.left == null) {
      return new THREE.Group()
    }

    if (personHeight == null) personHeight = TYPICAL_PERSON_HEIGHT
    const paths = this.svgData.left.paths

    const scale = computeScale(paths, personHeight)
    const center = computeCenter(paths)

    const leftFoot = makeMesh(paths.find(p => p.userData.node.id === 'left-foot-obj'), this.color)
    translateCenter(leftFoot, center, scale)

    const result = new THREE.Group()
    result.add(leftFoot)
    return result
  }

  buildRight (personHeight) {
    if (this.svgData.right == null) {
      return new THREE.Group()
    }

    if (personHeight == null) personHeight = TYPICAL_PERSON_HEIGHT
    const paths = this.svgData.right.paths

    const scale = computeScale(paths, personHeight)
    const center = computeCenter(paths)

    const rightFoot = makeMesh(paths.find(p => p.userData.node.id === 'right-foot-obj'), this.color)
    translateCenter(rightFoot, center, scale)

    const result = new THREE.Group()
    result.add(rightFoot)
    return result
  }
}
