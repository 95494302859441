








import Vue from 'vue'
import { mapState } from 'vuex'
import Lo from 'lodash'
import { Organization } from '@/models/admin'

export default Vue.extend({
  props: {
    value: String
  },

  computed: {
    ...mapState('admin', {
      /* eslint-disable */
      multipleOrganizations: (state: any): boolean => state.organizations.length > 1,
      organizations: (state: any): Organization[] => Lo.orderBy(state.organizations, o => o.Name.toLocaleLowerCase()),
      /* eslint-enable */
    })
  },

  methods: {
    onInput (newValue?: string) {
      this.$emit('input', newValue)
    }
  }
})
