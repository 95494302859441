













import Vue from 'vue'

export default Vue.extend({
  props: {
    text: String,
    charCount: {
      type: Number,
      default: 50
    },
    linkify: { type: Boolean, default: false }
  },

  data: () => ({
    readMore: false
  }),

  computed: {
    textLength: function (): number {
      return this.text?.length ?? 0
    },
    showFullText: function (): boolean {
      return this.readMore || this.textLength <= this.charCount
    },
    showHtml: function (): boolean {
      return this.showFullText && this.linkify
    },
    textToDisplay: function (): string | null {
      if (this.showHtml) return null
      return this.showFullText
        ? this.text
        // Cut the text and append an ellipsis
        : this.text.substring(0, this.charCount) + '\u2026'
    },
    htmlToDisplay: function (): string | null {
      return this.showHtml ? this.$linkify(this.text) : null
    }
  },

  methods: {
    onToggleClick (e: Event) {
      e.preventDefault()
      this.readMore = !this.readMore
    }
  }
})
