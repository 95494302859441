<template>
  <div id="app-container">
    <transition name="component-fade" mode="out-in">
      <component v-if="appInitialized" :is="layout"/>
    </transition>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'
import DefaultLayout from './components/layouts/default'

export default {
  computed: {
    layout: function () {
      return (this.$route.meta && this.$route.meta.layout) || DefaultLayout
    },
    ...mapGetters('user', ['getSettings']),
    ...mapState(['appInitialized'])
  },

  methods: {
    onAppInitialized () {
      // Remove the splash screen
      // For some reason, in release builds a simple call of remove()
      // also removes the app-container. So remove all child nodes instead.
      const splash = document.getElementById('splash-container')
      if (splash != null) {
        while (splash.hasChildNodes()) splash.removeChild(splash.childNodes[0])
      }

      // Sync with the initial locale
      const locale = this.getSettings('locale')
      if (locale != null && locale !== this.$i18n.locale) {
        this.$i18n.locale = locale
      } else {
        this.onLocaleChanged()
      }
    },
    onLocaleChanged () {
      const locale = this.$i18n.locale
      this.setUILocale(locale)

      // Default English locale for dates to European
      const momentLocale = locale === 'en' ? 'en-gb' : locale
      this.$moment.locale(momentLocale)
    },
    ...mapActions(['loadEnums', 'setUILocale'])
  },

  watch: {
    appInitialized: function (newValue) {
      if (newValue === true) {
        this.onAppInitialized()
      }
    },
    '$i18n.locale': function () {
      this.onLocaleChanged()
    }
  },

  created () {
    if (this.appInitialized) {
      this.onAppInitialized()
    }
    this.loadEnums()
  }
}
</script>

<style>
/*
Below styles fix a layout issue caused by
changing .row margins in Vuetify v2.4.
https://github.com/vuetifyjs/vuetify/releases/tag/v2.4.0
https://github.com/vuetifyjs/vuetify/issues/12848
It would be better to accommodate the new reality instead
and apply appropriate changes to the layout where needed.
*/

main .row {
  margin-top: 0;
  margin-bottom: 0;
}

main .row + .row {
  margin-top: 0;
}
</style>
