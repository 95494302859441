function getContainerId (scanId, isCompare, isSecondary) {
  return `${isCompare ? 'compare-' : ''}${scanId}${isSecondary ? '-secondary' : ''}`
}

export default {
  getContainerId,
  getViewerId: (scanId, isCompare, isSecondary) =>
    `scan_viewer-${getContainerId(scanId, isCompare, isSecondary)}`,
  getPlaybackId: (scanId) => `playback-${scanId}`
}
