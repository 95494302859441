<template>
  <div>
    <mpro-text-editor v-if="isText"
                      :value="value" @input="onInput"
                      :readonly="readonly" :label="label" :hint="hint"
                      :required="required"
                      :max-length="metadata.type.maxLength"
                      :max-line-count="metadata.type.maxLineCount" />

    <mpro-boolean-editor v-else-if="isBool"
                         :value="value" @input="onInput"
                         :readonly="readonly" :label="label" :hint="hint"
                         :required="required" />

    <mpro-image-editor v-else-if="isImage"
                       :value="value" @input="onInput"
                       :readonly="readonly" :label="label" :hint="hint" />

    <mpro-enum-editor v-else-if="isEnum"
                      :value="value" @input="onInput"
                      :readonly="readonly" :label="label" :hint="hint"
                      :enum-values="enumValues" :enum-texts="enumTexts" :sort-by-text="sortEnumByText"
                      :multiple="multiple"
                      :required="required" />

    <mpro-string-editor v-else
                        :value="value" @input="onInput"
                        :readonly="readonly" :label="label" :hint="hint"
                        :required="required" :type="stringType"
                        :min-length="metadata.type.minLength"
                        :max-length="metadata.type.maxLength" />
  </div>
</template>

<script>
import TextEditor from './text-editor'
import BooleanEditor from './boolean-editor'
import ImageEditor from './image-editor'
import EnumEditor from './enum-editor'
import StringEditor from './string-editor'

const TypeNames = {
  TEXT: 'Text',
  BOOLEAN: 'Boolean',
  IMAGE: 'Image',
  ENUMERATION: 'Enumeration',
  ENUMERATION_SET: 'EnumerationSet'
}

const EnumNames = {
  LANGUAGE: 'Language',
  LANGUAGE_CODE: 'LanguageCode'
}

export default {
  components: {
    'mpro-text-editor': TextEditor,
    'mpro-boolean-editor': BooleanEditor,
    'mpro-image-editor': ImageEditor,
    'mpro-enum-editor': EnumEditor,
    'mpro-string-editor': StringEditor
  },

  props: {
    value: [String, Boolean, Number, File],
    readonly: { type: Boolean, default: true },
    metadata: Object,
    sourceHint: String
  },

  computed: {
    typeName: function () {
      return this.metadata?.type?.typeName
    },
    isText: function () {
      return this.typeName === TypeNames.TEXT
    },
    isBool: function () {
      return this.typeName === TypeNames.BOOLEAN
    },
    isImage: function () {
      return this.typeName === TypeNames.IMAGE
    },
    isEnum: function () {
      return [TypeNames.ENUMERATION, TypeNames.ENUMERATION_SET].includes(this.typeName)
    },
    label: function () {
      const code = this.metadata?.code
      const translationKey = 'admin.settings.codes.' + code?.replaceAll('.', ':')
      return this.$te(translationKey) ? this.$t(translationKey) : code
    },
    hint: function () {
      return this.readonly ? null : this.sourceHint
    },
    required: function () {
      return !(this.metadata?.defaultValue == null)
    },
    multiple: function () {
      return this.typeName === TypeNames.ENUMERATION_SET
    },
    enumValues: function () {
      return this.metadata?.type?.values ?? []
    },
    enumTexts: function () {
      const enumName = this.metadata?.type?.enumName
      switch (enumName) {
        case undefined:
        case null:
        case '':
          return null
        case EnumNames.LANGUAGE_CODE:
          return this.enumValues.map(v => this.$isoLanguages.getName(v, this.$i18n.locale))
        default:
          return this.enumValues.map(v => {
            const translationKey = `admin.settings.enumerations.${enumName}.${v}`
            return this.$te(translationKey) ? this.$t(translationKey) : null
          })
      }
    },
    sortEnumByText: function () {
      return [EnumNames.LANGUAGE, EnumNames.LANGUAGE_CODE].includes(this.metadata?.type?.enumName)
    },
    stringType: function () {
      switch (this.typeName) {
        case 'EmailAddress': return 'email'
        default: return 'text'
      }
    }
  },

  methods: {
    onInput (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
