<template>
  <div class="d-flex flex-column">
    <v-overlay :value="message">
      <v-label dark>{{ $t(message) }}</v-label>
      <v-progress-circular indeterminate size="48" class="d-block mx-auto mt-2"/>
    </v-overlay>
    <!-- Scene -->
    <div class="p-relative" style="flex: 1;">
      <div :id="containerElementId" class="fill-height">
        <canvas ref="canvas" class="p-absolute" style="left: 0; top: 0; right: 0; bottom: 0"/>
        <svg v-if="showMeasures && measuresReady" class="annotation_lines click-through" id="annotations_line_svg">
          <polyline v-for="(measuresPlace, name) in measurePlacement.measures"
                    :id="(secondaryScan ? 'secondary-' : '') + scanId + name + '-line'"
                    :key="name"
                    :class="[viewPoint, measurePlacement.measures[name].orientation, (Object.keys(measuresPlace).length > 0 ? 'd-block' : 'd-none')]"
                    class="single_annotation_line"
                    :points="'0,0 0,0'">
          </polyline>
        </svg>
        <div v-if="showMeasures && measuresReady" class="annotations"
             :class="canShowNarrative ? 'py-16' : null">
          <div v-for="(measuresPlace, name) in measurePlacement.measures" :id="scanId + name" :key="name"  class="single_annotation">
            <v-card v-if="Object.keys(measuresPlace).length > 0" class="d-inline-block">
              <v-card-title class="py-0 pt-0 px-2 d-block text-center text-subtitle-2">{{ $t(`measures.${name}`) }}</v-card-title>
              <v-card-text class="ma-auto d-flex py-0 pt-0 px-1">
                <div v-for="(measurePlace, name) in measuresPlace" :key="name + '2'" class="d-flex single-measure ma-auto" style="flex-basis: 50%">
                  <v-tooltip bottom>
                    <template #default>
                      <span v-if="measureTip(name) != null">
                        {{ measureTip(name) }}
                      </span>
                    </template>
                    <template #activator="{on}">
                      <span v-if="measurePlace.arrow != null" v-on="on"
                            class="d-block ma-auto">
                        <span v-if="measurePlace.type === 'angle'">
                          {{Math.abs(measureVal(name, measurePlace.type))}}°
                          <v-icon :style="{ transform: `rotate(${measureVal(name, measurePlace.type)}deg)`, color: getMeasureColor(measurePlace, name) }">
                            {{ getArrow(measurePlace, name) }}
                          </v-icon>
                        </span>
                        <span v-else-if="measurePlace.type === 'angle-direction'">
                          {{Math.abs(measureVal(name, measurePlace.type))}}°
                          <v-icon v-if="measureVal(name, measurePlace.type) !== 0"
                                  :style="{ color: getMeasureColor(measurePlace, name) }">
                            {{ getArrow(measurePlace, name) }}
                          </v-icon>
                          <v-icon v-else small :style="{ color: getMeasureColor(measurePlace, name) }">
                            mdi-circle
                          </v-icon>
                        </span>
                        <span v-else-if="measurePlace.type === 'displacement'">
                          {{$formatLength(Math.abs(measureVal(name, measurePlace.type)), 'cm', isMetric)}}
                          <v-icon v-if="measureVal(name, measurePlace.type) !== 0"
                                  :style="{ color: getMeasureColor(measurePlace, name) }">
                            {{ getArrow(measurePlace, name) }}
                          </v-icon>
                          <v-icon v-else small :style="{ color: getMeasureColor(measurePlace, name) }">
                            mdi-circle
                          </v-icon>
                        </span>
                      </span>
                    </template>
                  </v-tooltip>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
      <div v-show="isBalanceView"
           class="float-left top-left" :class="canShowNarrative ? 'mt-16' : null"
           :style="{ width: `${0.25 * canvasWidth}px`, height: `${0.25 * canvasWidth}px` }">
        <mpro-sway-area :assessment="balanceAssessment"
                        :movement-kind="movement"
                        :show-trajectory="showTrajectories"
                        :show-position="showTracking"
                        :time-stamp="playTimeStamp != null ? playTimeStamp - trackingPoints.Frames[0].TimeStampMs : undefined" />
      </div>
      <div v-if="canShowNarrative" class="d-flex" :class="secondaryScan ? 'float-right top-right' : 'float-left top-left'" style="height:0">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="toggleNarrative"
              v-bind="attrs"
              v-on="on"
              class="MProMainAlt"
              fab
              style="border-radius: 10px;">
              <v-icon>mdi-book-open-variant</v-icon>
            </v-btn>
          </template>
          <span>{{$t('scans.buttons.narrative')}}</span>
        </v-tooltip>
      </div>
      <div v-if="showControl" class="d-flex float-right top-right" :class="showControl && syncView ? 'move-controls' : 'boe'" style="height:0">
        <v-menu
          v-model="menuView"
          :close-on-content-click="true"
          :close-on-click="true"
          :offset-y="true"
          :left="true"
          :bottom="true"
          class="MPro-bg"
        >
          <template v-slot:activator="{ on, attrs }" class="MPro-bg">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="MProMainAlt"
              fab
              style="border-radius: 10px;"
            >
              <v-tooltip bottom nudge-bottom="20">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    >
                      <v-icon>mdi-eye</v-icon>
                  </span>
                </template>
                <span>{{$t('scans.buttons.view-from')}}</span>
              </v-tooltip>
            </v-btn>
          </template>
          <div class="ma-2 MPro-bg">
            <div class="flex-column">
              <div class="flex-row ma-0">
                <v-btn id="left" @click="view('right')" small class="mb-1 mr-1">{{ $t('scans.buttons.left-view') }}</v-btn>
                <v-btn id="right" @click="view('left')" small  class="mb-1">{{ $t('scans.buttons.right-view') }}</v-btn>
              </div>
              <div class="flex-column ma-0">
                <v-btn id="front" @click="view('front')" small class="mt-1 mb-2 d-block" width="100%">{{ $t('scans.buttons.front-view') }}</v-btn>
                <v-btn id="top" @click="view('top')" small class="my-2 d-block" width="100%">{{ $t('scans.buttons.top-view') }}</v-btn>
                <v-btn id="bottom" @click="view('bottom')" small class="my-2 d-block" width="100%">{{ $t('scans.buttons.bottom-view') }}</v-btn>
                <v-btn v-if="!compare" id="bottom" @click="balanceMe()" small class="my-2 d-block" width="100%">Balance</v-btn>
              </div>
            </div>
          </div>
        </v-menu>
      </div>
       <div v-if="showControl" class="d-inline-block float-right bottom-right" :class="showControl && syncView ? 'move-controls' : 'boe'" style="height:0">
        <v-menu
          v-model="menuMeasure"
          :close-on-content-click="false"
          :close-on-click="true"
          :offset-y="true"
          :left="true"
          :top="true"
          class="MPro-bg"
        >
          <template v-slot:activator="{ on, attrs }" class="MPro-bg">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="MProMainAlt"
              fab
              style="border-radius: 10px;"
            >
              <v-tooltip bottom nudge-bottom="20">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon>mdi-layers-outline</v-icon>
                  </span>
                </template>
                <span>{{$t('scans.buttons.display-toggles')}}</span>
              </v-tooltip>
            </v-btn>
          </template>
          <div class="d-flex flex-column MPro-bg">
            <div v-show="!isBalanceView" class="ma-2">
              <v-btn-toggle dense multiple outlined color="primary" class="ml-1 d-block"
                            v-model="visibilityToggles">
                <v-btn id="body" block outlined value="body">{{ $t('scans.buttons.toggle-body') }}</v-btn>
                <v-btn id="tracking" block outlined value="tracking">{{ $t('scans.buttons.toggle-tracking-points') }}</v-btn>
                <v-btn id="trajectories" block outlined value="trajectories">{{ $t('scans.buttons.toggle-trajectories') }}</v-btn>
                <v-btn v-if="isSpecialist && !compare && !isBalanceView" id="measures" block outlined value="measures">{{ $t('scans.buttons.toggle-measures') }}</v-btn>
                <v-expand-transition>
                  <div v-show="showMeasures"
                       height="36"
                       class="mx-auto">
                    <v-btn inline-block dense outlined @click="toggleAngles()" width="50%">
                      <v-icon>{{measureAngles ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank'}}</v-icon>
                      <v-icon>mdi-angle-acute</v-icon>
                    </v-btn>
                    <v-btn inline-block dense outlined @click="toggleDisplacement()"  width="50%">
                      <v-icon>{{measureDisplacement ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank'}}</v-icon>
                      <v-icon>mdi-ruler</v-icon>
                    </v-btn>
                  </div>
                </v-expand-transition>
              </v-btn-toggle>
            </div>
            <div v-if="isSpecialist" class="ma-2">
              <v-btn-toggle dense multiple color="primary" class="ml-1 flex-column d-block"
                            v-model="guideToggles">
                <v-btn id="floor-target" block value="floorTarget">{{ $t('scans.buttons.toggle-floor-target') }}</v-btn>
                <v-btn id="plumb-line" v-show="!isBalanceView" block value="plumbLine">{{ $t('scans.buttons.toggle-plumbline') }}</v-btn>
                <v-btn id="pom-plane-x" v-show="!isBalanceView" block value="pomX">{{ $t('scans.buttons.toggle-x-plane') }}</v-btn>
                <v-btn id="pom-plane-y" v-show="!isBalanceView" block value="pomY">{{ $t('scans.buttons.toggle-y-plane') }}</v-btn>
                <v-btn id="pom-plane-z" v-show="!isBalanceView" block value="pomZ">{{ $t('scans.buttons.toggle-z-plane') }}</v-btn>
              </v-btn-toggle>
            </div>
          </div>
        </v-menu>
       </div>
      <div class="orientation-helper">
        <div class="mb-2">
          <span v-if="!showMeasures || !measuresReady" >{{left}}</span>
          <span class="float-right">{{right}}</span>
        </div>
      </div>
    </div>
    <mpro-playback-controls :id="playbackElementId"
                            :frame-count="frames.length"
                            :fps="fps"
                            :playing="play"
                            @toggle-playing="play = !play"
                            :current-frame="playFrame"
                            @prev-frame="setCurrentFrame(playFrame - nextFrame)"
                            @next-frame="setCurrentFrame(playFrame + nextFrame)"
                            @set-frame="setCurrentFrame"
                            :fullscreen="presentMode"
                            @toggle-fullscreen="presentMode = !presentMode"
                            :playback-disabled="playbackDisabled"/>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
// TODO: Updating the three package post v0.124.0 requires
// to abandon using Geometry
import * as THREE from 'three'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js'
import { Line2 } from 'three/examples/jsm/lines/Line2.js'
import orbitControlsFactory from 'three-orbit-controls'
import pointMaterial from '../../assets/img/point.png'
import sceneDark from '../../assets/img/sceneDark.jpg'
import sceneLight from '../../assets/img/sceneLight.jpg'
import * as TWEEN from '../../assets/js/tween.min.js'
import Worker from '../../workers/fpsmesh.worker.js'
import Font3D from '../../../public/CircularBold_Regular.typeface.json'
import PlaybackControls from '@/components/elements/playback-controls'
import SwayArea from './sway-area.vue'
import ElementIds from './element-ids'
import FeetBuilder from './feet-builder'
import ReferencePointCalculator from './reference-point-calculator'
import { adjustForBankElevation } from '../../helpers/math'
import { AssessmentName, MovementKind, movementKindToAssessmentName } from '../../helpers/enums'
import { ScanMeasureArrow, ScanMeasureType, ScanView3D } from '@/models/ui'

const OrbitControls = orbitControlsFactory(THREE)

const TP_CENTER_OF_MASS = 'CenterofMass'
const TP_LEFT_SHOULDER_SURFACE = 'LeftShoulderSurface'
const TP_RIGHT_SHOULDER_SURFACE = 'RightShoulderSurface'
const TP_MID_SHOULDER = 'MidShoulder'
const TOGGLE_BODY = 'body'
const TOGGLE_TRACKING = 'tracking'
const OBJECT_TRACKING_FLOOR = `${TOGGLE_TRACKING}-floor`
const TOGGLE_TRAJECTORIES = 'trajectories'
const OBJECT_TRAJECTORIES_FLOOR = `${TOGGLE_TRAJECTORIES}-floor`
const TOGGLE_MEASURES = 'measures'
const TOGGLE_FLOOR_TARGET = 'floorTarget'
const TOGGLE_PLUMB_LINE = 'plumbLine'
const TOGGLE_POM_X = 'pomX'
const TOGGLE_POM_Y = 'pomY'
const TOGGLE_POM_Z = 'pomZ'
const OBJECT_BALANCE = 'balance'
const OBJECT_REACH_DISTANCE = 'reach'
const RENDER_OFFSET_BASE = 1

const feetBuilder = new FeetBuilder()
const referencePointCalculator = new ReferencePointCalculator()

export default {
  components: {
    'mpro-playback-controls': PlaybackControls,
    'mpro-sway-area': SwayArea
  },

  props: {
    scanId: {type: String, required: false, default: () => ''},
    scanData: {type: Object, required: false, default: () => ({})},
    movement: {type: String, required: false, default: () => ''},
    reprojectionTable: {type: Float32Array, required: false, default: () => new Float32Array()},
    depthMap: {type: Uint16Array, required: false, default: () => new Uint16Array()},
    trackingPoints: {type: Object, required: false, default: () => ({})},
    measures: {type: Object, required: false, default: () => ({})},
    cameraExternal: {type: Object, required: false, default: () => ({})},
    compare: {type: Boolean, required: false, default: () => false},
    secondaryScan: {type: Boolean, required: false, default: () => false},
    syncView: {type: Boolean, required: false, default: () => false},
    syncTime: {type: String, required: false, default: () => 'none'},
    emitTime: {type: Boolean, required: false, default: () => false},
    mirror: {type: Boolean, required: false, default: () => false}
  },
  static () {
    return {
      last: null,
      currentFrame: 0,
      mesh: null,
      offset: 1.15,
      currentRender: null,
      currentPositionInBuffer: 0,
      smooth: false,
      trackingPointColors: {
        'HeadTopAnatomic': 0x8631e4,
        'HipsMiddleAnatomic': 0x8631e4,
        'CenterofMass': 0xff0000,
        'AnklesMiddleAnatomic': 0x8631e4,
        // 'ShouldersMiddleAnatomic': 0x8631e4,
        'MidShoulder': 0x8631e4,
        'RightHipAnatomic': 0x8631e4,
        'SpineT1': 0x8631e4,
        'LeftHipAnatomic': 0x8631e4,
        'RightShoulderSurface': 0x8631e4,
        'LeftShoulderSurface': 0x8631e4,
        'LeftKneeAnatomic': 0x8631e4,
        'KneesMiddleAnatomic': 0x8631e4,
        'RightKneeAnatomic': 0x8631e4,
        'LeftAnkleAnatomic': 0x8631e4,
        'RightAnkleAnatomic': 0x8631e4,
        // 'RightShoulderAnatomic': 0x8631e4,
        // 'LeftShoulderAnatomic': 0x8631e4,
        'SpineShoulder': 0x8631e4,
        'LeftHandTipSurface': 0x8631e4,
        'RightHandTipSurface': 0x8631e4
      },
      camera: null,
      scene: null,
      renderer: null,
      container: null,
      frames: [],
      trackingPointFrames: [],
      pointTexture: null,
      bBox: null,
      center: null
    }
  },
  data () {
    return {
      viewPoint: ScanView3D.Front,
      isBalanceView: false,
      menuMeasure: false,
      menuView: false,
      measureDisplacement: true,
      measureAngles: false,
      measuresReady: false,
      message: null,
      visibilityToggles: [TOGGLE_BODY],
      guideToggles: [TOGGLE_PLUMB_LINE, TOGGLE_FLOOR_TARGET],
      playFrame: 0,
      left: 'Left',
      right: 'Right',
      autoPlay: false,
      play: false,
      bank: Math.atan2(this.scanData.geometry.floor_plane.a, this.scanData.geometry.floor_plane.b),
      elevation: Math.asin(this.scanData.geometry.floor_plane.c),
      loading: true,
      worker: undefined,
      translateToCenter: null,
      updateFrames: false,
      updateFrameNum: 0,
      renderOffset: RENDER_OFFSET_BASE,
      floorTarget: null,
      floorPlane: null,
      plumbLine: null,
      pomX: null,
      pomY: null,
      pomZ: null,
      axesHelper: null,
      trajectories: null,
      'trajectories-floor': null,
      annotation: null,
      annotations: null,
      balance: null,
      controls: null,
      renderLimit: 1,
      renderIteration: 0,
      presentMode: false,
      movementSections: false,
      reach: null,
      canvasWidth: 1,
      canvasHeight: 1
    }
  },
  computed: {
    ...mapGetters('measures', ['getMeasurePlacement']),
    ...mapGetters('scans', ['viewerSettings']),
    ...mapGetters(['getRole', 'isSeeker', 'isSpecialist']),
    ...mapGetters('user', ['isMetric', 'getUserEmail', 'canViewNarratives']),
    depthCameraGeometry: function () {
      return this.scanData.sensor.depth_camera_geometry
    },
    fps: function () {
      let fps = this.scanData && this.movement
        ? 1000 / this.scanData.movements[this.movement].actual_fps
        : 1000 / 30
      fps = fps * this.nextFrame
      return fps
    },
    nextFrame: function () {
      if (this.renderIteration <= 2) {
        return 4
      } else if (this.renderIteration === 3) {
        return 2
      } else {
        return 1
      }
    },
    playTimeStamp: function () {
      return this.trackingPoints != null
        ? this.trackingPoints.Frames.find(f => f.FrameIndex === this.playFrame)?.TimeStampMs
        : undefined
    },
    playbackDisabled: function () {
      return (this.syncTime === 'right' && !this.secondaryScan) || (this.syncTime === 'left' && this.secondaryScan)
    },
    showTracking: function () {
      return this.visibilityToggles.includes(TOGGLE_TRACKING) || this.isBalanceView
    },
    showTrajectories: function () {
      return this.visibilityToggles.includes(TOGGLE_TRAJECTORIES) || this.isBalanceView
    },
    showBody: function () {
      return this.visibilityToggles.includes(TOGGLE_BODY) && !this.isBalanceView
    },
    showMeasures: function () {
      return this.visibilityToggles.includes(TOGGLE_MEASURES) && !this.isBalanceView && !this.compare
    },
    assessmentName: function () {
      return movementKindToAssessmentName(this.movement)
    },
    assessment: function () {
      return this.measures != null && this.measures.Assessments != null
        ? this.measures.Assessments[this.assessmentName]
        : undefined
    },
    balanceAssessment: function () {
      if (this.measures == null || this.measures.Assessments == null) return undefined
      return this.measures.Assessments[AssessmentName.BALANCE_TWO_LEGS] ??
        this.measures.Assessments[AssessmentName.BALANCE_RIGHT] ??
        this.measures.Assessments[AssessmentName.BALANCE_LEFT]
    },
    viewMeasure: function (views) {
      if (views.find(this.viewPoint) !== -1) return true
      return false
    },
    showControl: function () {
      if (!this.secondaryScan) return true
      if (this.syncView) return false
      return true
    },
    canShowNarrative: function () {
      return !this.compare && this.isSpecialist && this.canViewNarratives
    },
    measurePlacement: function () {
      var measures = JSON.parse(JSON.stringify(this.getMeasurePlacement(this.movement)))
      if (this.showMeasures) {
        Object.keys(measures.measures).forEach(loc => {
          const location = measures.measures[loc]
          Object.keys(measures.measures[loc]).forEach(m => {
            // There are keys with general props of location
            // so check if references object is an actual measure before analyzing it
            const measure = location[m]
            if (measure.viewFrom) {
              if (!measure.viewFrom.includes(this.viewPoint)) {
                delete location[m]
                return
              }
            }
            if (measure.type) {
              if (this.measureAngles && ![ScanMeasureType.Angle, ScanMeasureType.AngleDirection].includes(measure.type)) {
                delete location[m]
              } else if (this.measureDisplacement && measure.type !== ScanMeasureType.Displacement) {
                delete location[m]
              }
            }
          })
        })
      } else {
        Object.keys(measures.measures).forEach(loc => {
          Object.keys(measures.measures[loc]).forEach(m => {
            delete measures.measures[loc][m]
          })
        })
      }
      return measures
    },
    containerElementId: function () {
      return ElementIds.getContainerId(this.scanId, this.compare, this.secondaryScan)
    },
    playbackElementId: function () {
      return ElementIds.getPlaybackId(this.scanId)
    },
    currentSection: function () {
      if (this.movementSections) {
        for (var i = 0; i < this.movementSections.start.length; i++) {
          if (this.playFrame >= this.movementSections.start[i] && this.playFrame < this.movementSections.end[i]) return i + 1
        }
      }
      return 0
    }
  },
  methods: {
    ...mapActions(['showGlobalMessage']),
    ...mapMutations('scans', ['storeViewerSettings']),
    calcCanvasSize: function () {
      const style = window.getComputedStyle(this.container)
      this.canvasWidth = parseFloat(style.getPropertyValue('width'))
      this.canvasHeight = parseFloat(style.getPropertyValue('height'))
    },
    // ////////////////////////////////////// //
    //         Initiate ThreeJS               //
    // ////////////////////////////////////// //
    init: function () { // Initiate ThreeJS
      if (!this.scanData) return
      // Get the render container to set render area width
      this.container = document.getElementById(this.containerElementId)
      if (this.container == null) return
      this.calcCanvasSize()
      // Create Scene
      this.scene = new THREE.Scene()
      this.isSpecialist ? this.sceneTexture = new THREE.TextureLoader().load(sceneLight) : this.sceneTexture = new THREE.TextureLoader().load(sceneDark)
      this.scene.background = this.sceneTexture
      // Create Camera to view scene
      this.camera = new THREE.PerspectiveCamera(59, this.canvasWidth / this.canvasHeight, 0.1, 15000)
      this.camera.position.z = 2
      this.camera.enablePan = false
      this.camera.enableDamping = true
      this.camera.dampingFactor = 1.5
      this.camera.updateProjectionMatrix() // Update to use set values
      // Create scene rederer
      this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true, canvas: this.$refs.canvas })
      this.renderer.setClearColor(0xffffff, 0)
      this.renderer.setSize(this.canvasWidth, this.canvasHeight, false)
      // Create Controls
      this.controls = new OrbitControls(this.camera, this.container) // Initiate Movement controls
      this.controls.enableKeys = false
      this.controls.addEventListener('change', this.onPositionChange)
      this.pointTexture = new THREE.TextureLoader().load(pointMaterial)
    },
    measureVal: function (name, type) {
      try {
        let rawValue
        let isForth = false
        if (name.includes('.')) {
          let nameArray = name.split('.')
          rawValue = this.assessment[nameArray[0]][nameArray[1]]
          isForth = nameArray[1].includes('Forth')
        } else {
          rawValue = this.assessment[name]
        }
        switch (type) {
          case ScanMeasureType.Displacement:
            // Convert from meters to centimeters
            return Math.round(rawValue * 100)
          case ScanMeasureType.Angle:
            // Convert from radians to degrees
            let value = Math.round(rawValue * 180 / Math.PI)
            if (isForth) {
              value = value + 180
            }
            return value
          case ScanMeasureType.AngleDirection:
            // Convert from radians to degrees
            return Math.round(rawValue * 180 / Math.PI)
          default:
            return undefined
        }
      } catch (e) {
        console.warn('measureVal failed', name, type)
      }
    },
    getTrajectoryColor: function (value, type, trackingpoint, extension, hex = false) {
      if (value === undefined) return hex ? '#8631e4' : 0x8631e4
      let testVal = value
      if (type === ScanMeasureType.Displacement && !hex) {
        testVal = Math.round(value * 100)
      } else if (type === ScanMeasureType.Angle && !hex) {
        testVal = Math.round(value * 180 / Math.PI)
        if (extension.includes('Forth')) {
          testVal = testVal + 180
        }
      }
      if ([MovementKind.SQUAT, MovementKind.SQUAT_LEFT, MovementKind.SQUAT_RIGHT].includes(this.movement)) {
        if (trackingpoint.includes('Shoulder') || trackingpoint.includes('Knee')) {
          if (Math.abs(testVal) <= 5) {
            return hex ? '#8631e4' : 0x8631e4
          } else if (Math.abs(testVal) > 5 && Math.abs(testVal) < 11) {
            return hex ? '#ff9500' : 0xff9500
          } else {
            return hex ? '#ff0000' : 0xff0000
          }
        } else if (trackingpoint.includes('Hips')) {
          if (Math.abs(testVal) <= 5) {
            return hex ? '#8631e4' : 0x8631e4
          } else {
            return hex ? '#ff0000' : 0xff0000
          }
        }
      }
      return hex ? '#0000ff' : 0x0000ff
    },
    getMeasureColor (measurePlace, name) {
      return measurePlace.enableOn && !measurePlace.enableOn.includes(this.currentSection)
        ? '#ccc'
        : this.getTrajectoryColor(this.measureVal(name, measurePlace.type), measurePlace.type, name, '', true)
    },
    measureTip (name) {
      const key = `measures.${name}`
      return this.$te(key) ? this.$t(key) : undefined
    },
    forceRebuild: function () {
      this.$root.$emit('rendered', false, this.scanId)
      this.message = 'scans.messages.processing-3d'
      if (this.worker !== undefined) {
        this.worker.terminate()
        this.worker = undefined
      }
      if (typeof (Worker) !== 'undefined') {
        if (typeof (this.worker) === 'undefined') {
          this.worker = new Worker()
        }
        this.autoPlay = true
        this.worker.onmessage = ({ data }) => {
          this.processWorkerData(data)
        }
        this.worker.onerror = () => {
          this.showGlobalMessage('scans.messages.processing-3d')
        }
      }
      this.renderOffset = RENDER_OFFSET_BASE
      this.updateFrames = false
      this.updateFrameNum = 0
      this.renderIteration = 0
      this.frames = []
      this.measuresReady = false
      setTimeout(() => {
        while (this.scene.children.length !== 0) {
          this.scene.remove(this.scene.children[0])
        }
        this.currentPositionInBuffer = 0
        this.setCurrentFrame(0)
        this.processDepthMap()
      }, 80)
    },
    // ////////////////////////////////////// //
    //            Build Models                //
    // ////////////////////////////////////// //
    processDepthMap: function () { // Process the DepthMap data and prepare for rendering frames
      if (!this.depthMap) return
      // Send the data to the worker
      this.worker.postMessage({
        'bodyDepthMapsArray': this.depthMap,
        'reprojectionTable': this.reprojectionTable,
        'depthCameraGeometry': this.depthCameraGeometry,
        'smooth': this.smooth,
        'bank': this.bank,
        'elevation': this.elevation,
        'offset': this.renderOffset,
        'iteration': this.renderIteration
      })
    },
    processWorkerData: function (data) {
      let temp = this.syncView
      if (this.autoPlay && !this.play && this.frames.length >= 20) {
        this.play = true
        this.autoPlay = false
      }
      if (data === 'new') {
        this.loading = true
      } else if (data && data !== 'done') {
        let loader = new THREE.ObjectLoader()
        let frameJSON = JSON.parse(data)
        if (frameJSON.body) {
          let frame = loader.parse(frameJSON.body)
          frame.name = TOGGLE_BODY
          if (this.playFrame === this.updateFrameNum) {
            if (this.frames[this.updateFrameNum] && this.frames[this.updateFrameNum].body) this.scene.remove(this.frames[this.updateFrameNum].body)
          }
          this.frames[this.updateFrameNum] = {}
          this.frames[this.updateFrameNum][TOGGLE_BODY] = frame
          if (this.updateFrameNum === 0) {
            this.getCenter()
            const isBalanceView = this.isBalanceView
            this.controls.target = this.center
            this.controls.update()
            if (isBalanceView) {
              this.balanceMe()
            } else {
              this.camera.position.y = this.center.y
              this.camera.lookAt(this.center)
            }
            this.buildLighting()
          }
          this.message = ''
          this.frames[this.updateFrameNum][TOGGLE_BODY].geometry.translate(this.translateToCenter.x, this.translateToCenter.y, this.translateToCenter.z)
          if (this.renderIteration === 0) {
            this.updateFrameNum++
            this.frames[this.updateFrameNum] = {'body': null}
            this.updateFrameNum++
            this.frames[this.updateFrameNum] = {'body': null}
            this.updateFrameNum++
            this.frames[this.updateFrameNum] = {'body': null}
            this.updateFrameNum++
            this.frames[this.updateFrameNum] = {'body': null}
          } else {
            this.updateFrameNum += 4
          }
        }
      } else if (data === 'done') {
        this.loading = false
        if (this.frames[this.frames.length - 1]) { // test for Tino's infinite loading issues
          while (this.frames[this.frames.length - 1].body === null) {
            this.frames.pop()
          }
        }
        this.$root.$emit('maxFrames', this.frames.length, this.scanId)
        if (this.renderIteration <= 2) {
          this.renderIteration++
          if (this.renderIteration === 1) {
            this.updateFrameNum = 2
          } else if (this.renderIteration === 2) {
            this.updateFrameNum = 1
          } else {
            this.updateFrameNum = this.renderIteration
          }
          this.processDepthMap()
        } else {
          this.renderIteration++
          while (this.frames[this.frames.length - 1].body === null) {
            this.frames.pop()
          }
          this.$root.$emit('maxFrames', this.frames.length, this.scanId)
          this.$root.$emit('rendered', true, this.scanId)
        }
        this.toggleSceneObject(TOGGLE_FLOOR_TARGET, false)
        this.toggleSceneObject(TOGGLE_PLUMB_LINE, false)
        this.toggleSceneObject(TOGGLE_POM_X, false)
        this.toggleSceneObject(TOGGLE_POM_Y, false)
        this.toggleSceneObject(TOGGLE_POM_Z, false)
        this.toggleSceneObject(TOGGLE_TRAJECTORIES, false)
        this.toggleSceneObject(OBJECT_TRAJECTORIES_FLOOR, false)
        this.toggleSceneObject(OBJECT_BALANCE, false)
        this.toggleSceneObject(OBJECT_REACH_DISTANCE, false)
        this.toggleFrameObject(TOGGLE_BODY, false)
        this.toggleFrameObject(TOGGLE_TRACKING, false)
        this.toggleFrameObject(OBJECT_TRACKING_FLOOR, false)
        this.postProcessing()
        this.syncSceneWithToggles()
      }
      this.syncView = temp
    },
    postProcessing: function () {
      // Order matters, as built objects are added to the scene in this order
      // and then are rendered in the same order
      this.buildTrajectories()
      this.buildTrackingPoints()
      this.buildRestOfScene()
      this.translateCenter()
      this.measuresReady = true
    },
    buildTrackingPoints: function () {
      if (!this.trackingPoints) return
      const trackingMaterial = new THREE.PointsMaterial({ vertexColors: THREE.VertexColors, map: this.pointTexture, transparent: true, depthTest: false, depthWrite: false, size: 0.08 }) // Declare material/color for points
      this.trackingPoints.Frames.forEach(frame => {
        let trackingCloud = new THREE.Geometry()
        frame.PointsPositions.forEach((pointCor, j) => {
          let added = false
          if (pointCor !== null) {
            const p = adjustForBankElevation(pointCor, this.bank, this.elevation)
            const point = new THREE.Vector3(p.X, p.Y, p.Z)
            const color = this.trackingPointColors[this.trackingPoints.TrackingPoints[j]]
            if (color != null) {
              trackingCloud.colors.push(new THREE.Color(color))
              trackingCloud.vertices.push(point)
              added = true
            }
          }
          if (!added) {
            // Just add a stub point into the invisible area to maintain point indexes
            trackingCloud.colors.push(new THREE.Color())
            trackingCloud.vertices.push(new THREE.Vector3(400, 400, 400))
          }
        })
        if (this.movement === MovementKind.STAND_STILL) {
          // This is a "MidShoulder" point which is only needed for positioning of measures
          const index1 = this.trackingPoints.TrackingPoints.indexOf(TP_LEFT_SHOULDER_SURFACE)
          const index2 = this.trackingPoints.TrackingPoints.indexOf(TP_RIGHT_SHOULDER_SURFACE)
          if (index1 >= 0 && index2 >= 0) {
            const points = []
            for (const index of [index1, index2]) {
              const pointCor = frame.PointsPositions[index]
              const p = adjustForBankElevation(pointCor, this.bank, this.elevation)
              points.push(new THREE.Vector3(p.X, p.Y, p.Z))
            }
            let point = new THREE.Vector3()
            point.x = (points[0].x + points[1].x) / 2
            point.y = (points[0].y + points[1].y) / 2
            point.z = (points[0].z + points[1].z) / 2
            this.frames[frame.FrameIndex].midShoulderIndex = trackingCloud.vertices.length
            trackingCloud.colors.push(new THREE.Color(this.trackingPointColors[TP_MID_SHOULDER]))
            trackingCloud.vertices.push(point)
          }
        }
        const indexCom = this.trackingPoints.TrackingPoints.indexOf(TP_CENTER_OF_MASS)
        if (indexCom >= 0) {
          // Add CoM projection to the floor
          const trackingCloudFloor = new THREE.Geometry()
          const pointCor = frame.PointsPositions[indexCom]
          const p = adjustForBankElevation(pointCor, this.bank, this.elevation)
          const point = new THREE.Vector3(p.X, p.Y, p.Z)
          const trackingColor = new THREE.Color(this.trackingPointColors[TP_CENTER_OF_MASS])
          const trackingMaterialCoM = new THREE.PointsMaterial({ vertexColors: THREE.VertexColors, map: this.pointTexture, transparent: true, opacity: 0.6, depthTest: false, depthWrite: false, size: 0.04 }) // Declare material/color for points
          trackingCloudFloor.colors.push(trackingColor)
          trackingCloudFloor.vertices.push(point)
          const pointsFloor = new THREE.Points(trackingCloudFloor, trackingMaterialCoM)
          pointsFloor.name = OBJECT_TRACKING_FLOOR
          this.frames[frame.FrameIndex][OBJECT_TRACKING_FLOOR] = pointsFloor
        }
        const points = new THREE.Points(trackingCloud, trackingMaterial)
        points.name = TOGGLE_TRACKING
        this.frames[frame.FrameIndex][TOGGLE_TRACKING] = points
        trackingCloud.dispose()
        trackingMaterial.dispose()
      })
    },
    buildTrajectories: function () {
      if (this.trackingPoints == null || this.assessment == null) return

      this[TOGGLE_TRAJECTORIES] = new THREE.Group()
      this[TOGGLE_TRAJECTORIES].name = TOGGLE_TRAJECTORIES
      this[OBJECT_TRAJECTORIES_FLOOR] = new THREE.Group()
      this[OBJECT_TRAJECTORIES_FLOOR].name = OBJECT_TRAJECTORIES_FLOOR

      for (const trackingpoint in this.measurePlacement.trajectories) {
        let frameRanges = []
        if ([MovementKind.SQUAT, MovementKind.SQUAT_LEFT, MovementKind.SQUAT_RIGHT].includes(this.movement)) {
          frameRanges.push(
            [this.assessment.SelectROI.FromFrameIndexInclusive, this.assessment.SquatMiddleFrame],
            [this.assessment.SquatMiddleFrame, this.assessment.SelectROI.ToFrameIndexExclusive])
        } else if (this.movement === MovementKind.SIDE_BEND) {
          frameRanges.push(
            [this.assessment.SelectROILeft.FromFrameIndexInclusive, this.assessment.SelectROILeft.ToFrameIndexExclusive],
            [this.assessment.SelectROIRight.FromFrameIndexInclusive, this.assessment.SelectROIRight.ToFrameIndexExclusive])
        } else {
          frameRanges.push([this.assessment.SelectROI.FromFrameIndexInclusive, this.assessment.SelectROI.ToFrameIndexExclusive])
        }

        let TrackingIndex = this.trackingPoints.TrackingPoints.indexOf(trackingpoint)
        if (TrackingIndex < 0) continue

        for (let i = 0; i < frameRanges.length; i++) {
          const startIndex = this.trackingPoints.Frames.findIndex(f => f.FrameIndex === frameRanges[i][0])
          if (startIndex < 0) continue

          let points = []
          for (let j = startIndex; j < this.trackingPoints.Frames.length; j++) {
            const frame = this.trackingPoints.Frames[j]
            if (frame.FrameIndex >= frameRanges[i][1]) break
            const pointCor = frame.PointsPositions[TrackingIndex]
            const p = adjustForBankElevation(pointCor, this.bank, this.elevation)
            const point = new THREE.Vector3(p.X, p.Y, p.Z)
            points.push(point)
          }
          let positions = []
          var spline = new THREE.CatmullRomCurve3(points)
          var divisions = Math.round(12 * points.length)
          var point = new THREE.Vector3()
          for (var k = 0, l = divisions; k < l; k++) {
            var t = k / l
            spline.getPoint(t, point)
            positions.push(point.x, point.y, point.z)
          }
          const trajectory = this.measurePlacement.trajectories[trackingpoint]
          const assessmentDefined = trajectory.value &&
            this.assessment[trajectory.value.id] &&
            trajectory.value.extension[i]
          const color = assessmentDefined
            ? this.getTrajectoryColor(this.assessment[trajectory.value.id][trajectory.value.extension[i]], trajectory.type, trackingpoint, trajectory.value.extension[i])
            : 0x8631e4
          let material = new LineMaterial({
            color,
            linewidth: 0.004,
            // resolution: // to be set by renderer, eventually
            transparent: true,
            depthTest: false,
            depthWrite: false,
            dashed: false
          })
          if (trackingpoint === TP_CENTER_OF_MASS) {
            // Set CoM trajectory on the floor from the snail trail measure
            // when available, otherwise from the tracking point
            let comSnailTrail = this.balanceAssessment?.CenterOfMassSnailTrailPathTopView
            let geometryFloor = new LineGeometry()
            if (comSnailTrail != null) {
              const trailPositions = []
              comSnailTrail.forEach(p => trailPositions.push(p.Value.X, 1e-3, p.Value.Y))
              geometryFloor.setPositions(trailPositions)
            } else {
              geometryFloor.setPositions(positions)
            }
            const lineFloor = new Line2(geometryFloor, material)
            lineFloor.userData.isTransformed = comSnailTrail != null
            this[OBJECT_TRAJECTORIES_FLOOR].add(lineFloor)
            geometryFloor.dispose()
          }
          let geometry = new LineGeometry()
          geometry.setPositions(positions)
          let line = new Line2(geometry, material)
          this.trajectories.add(line)
          geometry.dispose()
          material.dispose()
        }
      }
    },

    getCenter: function () {
      this.bBox = new THREE.Box3()
      this.frames[0].body.geometry.computeBoundingBox()
      this.frames[0].body.geometry.computeBoundingSphere()

      this.bBox.copy(this.frames[0].body.geometry.boundingBox).applyMatrix4(this.frames[0].body.matrixWorld)
      var size = new THREE.Vector3()
      this.bBox.getSize(size)
      this.adjustCamera(size)

      const referencePoint = referencePointCalculator.calculate(
        this.trackingPoints, this.assessment, this.measurePlacement?.referencePoints)

      // The X axis is red. The Y axis is green. The Z axis is blue.
      let x, y, z
      if (referencePoint != null) {
        const p = adjustForBankElevation(referencePoint, this.bank, this.elevation)
        x = p.X
        z = p.Z
      } else {
        x = (this.bBox.max.x + this.bBox.min.x) / 2
        z = (this.bBox.max.z + this.bBox.min.z) / 2
      }
      y = this.bBox.min.y
      this.translateToCenter = new THREE.Vector3(-x, -y, -z)
      this.bBox.translate(this.translateToCenter)
      this.center = new THREE.Vector3()
      this.bBox.getCenter(this.center)
      this.center.x = this.center.z = 0
    },

    translateCenter: function () {
      this[TOGGLE_TRAJECTORIES].children.forEach((trajectory) => {
        trajectory.geometry.translate(this.translateToCenter.x, this.translateToCenter.y, this.translateToCenter.z)
      })
      this[OBJECT_TRAJECTORIES_FLOOR].children.forEach(t => {
        if (t.userData.isTransformed !== true) {
          t.geometry.translate(this.translateToCenter.x, this.translateToCenter.y, this.translateToCenter.z)
          // Put just above the floor
          t.geometry.scale(1, 0, 1)
          t.geometry.translate(0, 1e-3, 0)
        }
      })
      for (let k = 0; k < this.frames.length; k++) {
        if (this.frames[k] == null) continue
        for (const key of [TOGGLE_TRACKING, OBJECT_TRACKING_FLOOR]) {
          const o = this.frames[k][key]
          if (o == null) continue
          o.geometry.translate(this.translateToCenter.x, this.translateToCenter.y, this.translateToCenter.z)
          if (key === OBJECT_TRACKING_FLOOR) {
            // Put just above the floor
            // Per vertex instead of using geometry.scale + translate
            // because scaling to zero causes an error in this case
            // > Matrix3.getInverse(): can't invert matrix, determinant is 0
            for (const v of o.geometry.vertices) {
              v.y = 1e-3
            }
          }
        }
      }
    },
    // ////////////////////////////////////// //
    //               Add Measures             //
    // ////////////////////////////////////// //
    buildAnnotation: function () {
      this.message = 'scans.messages.processing_measures'
      if (this.measurePlacement) {
        for (let i = 0; i < Object.keys(this.measurePlacement.measures).length; i++) {
          let spriteMaterial = new THREE.SpriteMaterial({
            alphaTest: 0.5,
            transparent: true,
            depthTest: false,
            depthWrite: false
          })

          let sprite = new THREE.Sprite(spriteMaterial)
          sprite.position.set(250, 250, 250)
          sprite.scale.set(35, 35, 1)
          sprite.name = document.getElementById(this.scanId + Object.keys(this.measurePlacement.measures)[i])
          this.scene.add(sprite)
          spriteMaterial.dispose()
        }
      }
    },
    updateScreenPosition: function () {
      const frame = this.frames[this.playFrame]
      const tracking = frame && frame[TOGGLE_TRACKING]
      if (this.showMeasures && this.measurePlacement.measures && tracking != null) {
        let svg = document.getElementById('annotations_line_svg')
        let vector = new THREE.Vector3()
        for (const trackingPoint of Object.keys(this.measurePlacement.measures)) {
          let index = this.trackingPoints.TrackingPoints.indexOf(trackingPoint)
          if (index < 0 && trackingPoint === TP_MID_SHOULDER) {
            index = frame.midShoulderIndex
          }
          if (index >= 0) {
            vector.copy(tracking.geometry.vertices[index])
            vector.project(this.camera)
            let polyline = document.getElementById((this.secondaryScan ? 'secondary-' : '') + this.scanId + trackingPoint + '-line')
            let annotation = document.getElementById((this.secondaryScan ? 'secondary-' : '') + this.scanId + trackingPoint)
            let endpoint = svg.createSVGPoint()
            endpoint.x = Math.round((0.5 + vector.x / 2) * (this.container.clientWidth))
            endpoint.y = Math.round((0.5 - vector.y / 2) * (this.container.clientHeight))
            let startpoint = svg.createSVGPoint()
            startpoint.y = annotation.offsetTop + (annotation.clientHeight / 2)
            startpoint.x = annotation.offsetLeft + annotation.childNodes[0].clientWidth
            polyline.points[0] = startpoint
            polyline.points[1] = endpoint
          }
        }
      }
    },
    // ////////////////////////////////////// //
    //        Build Rest of Scene             //
    // ////////////////////////////////////// //
    buildRestOfScene: function () {
      this.message = 'scans.messages.processing-scene'
      this.axesHelper = new THREE.AxesHelper(5)
      this.axesHelper.name = 'axesHelper'
      // this.scene.add(this.axesHelper)
      // The X axis is red. The Y axis is green. The Z axis is blue.
      this.buildPlumbLine()
      this.buildFloorTarget()
      if (this.isSpecialist) {
        // this.buildFloor()
        this.buildPlanesOfMotion()
      }
      this.balanceView()
      if (this.movement === 'side_bend') this.reachDistance()
      this.syncSceneWithToggles()
      this.colorObject()
      this.message = ''
    },
    balanceView: function () {
      this.balance = new THREE.Group()
      this.balance.name = OBJECT_BALANCE
      const standStill = this.measures.Assessments[AssessmentName.STAND_STILL]
      const personHeight = (standStill && standStill['HeadHeightOverFloor']) ||
        (this.scanData.person && this.scanData.person.evaluated_height)
      switch (this.movement) {
        case MovementKind.STAND_STILL:
        case MovementKind.SQUAT:
        case MovementKind.SIDE_BEND:
          let requiredFeetDistanceSvg
          for (const assessmentName of [AssessmentName.BALANCE_TWO_LEGS, AssessmentName.SQUAT_DOUBLE, AssessmentName.SIDE_BEND]) {
            const assessment = this.measures.Assessments[assessmentName]
            if (assessment != null) {
              requiredFeetDistanceSvg = assessment['DistanceBetweenAnkles']
              if (requiredFeetDistanceSvg != null) break
            }
          }
          const feet = feetBuilder.buildDouble(personHeight, requiredFeetDistanceSvg)
          this.balance.add(feet)
          break
        case MovementKind.BALANCE_LEFT:
        case MovementKind.SQUAT_LEFT:
          const leftFoot = feetBuilder.buildLeft(personHeight)
          this.balance.add(leftFoot)
          break
        case MovementKind.BALANCE_RIGHT:
        case MovementKind.SQUAT_RIGHT:
          const rightFoot = feetBuilder.buildRight(personHeight)
          this.balance.add(rightFoot)
          break
      }
      const loader = new THREE.FontLoader()
      const font = loader.parse(Font3D)
      const assessmentName = this.assessmentName === AssessmentName.STAND_STILL ? AssessmentName.BALANCE_TWO_LEGS : this.assessmentName
      if (assessmentName === AssessmentName.BALANCE_TWO_LEGS) {
        const measure = 'WeightDistributionPercentLeft'
        const amount = this.measures.Assessments[assessmentName][measure]
        for (var x = 0; x < 2; x++) {
          const percent = x === 0 ? Math.round(amount) : 100 - Math.round(amount)
          const message = `${percent}%`
          const shapes = font.generateShapes(message, (percent * 0.0005) + 0.05)
          const geometry = new THREE.ShapeGeometry(shapes)
          const color = percent <= 40 || percent >= 60 ? 0xff0000 : 0x000000
          const matLite = new THREE.MeshBasicMaterial({
            color: color,
            transparent: true,
            opacity: 1,
            side: THREE.FrontSide
          })
          geometry.rotateX(-0.5 * Math.PI)
          geometry.rotateZ(1 * Math.PI)
          geometry.rotateY(1 * Math.PI)
          geometry.computeBoundingBox()
          const xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x)
          geometry.translate(xMid, 0, 0)
          let balanceBottom = new THREE.Mesh(geometry, matLite)
          let balanceTop = new THREE.Mesh(geometry, matLite)
          balanceBottom.position.x = x === 0 ? 0.3 : -0.3
          balanceBottom.position.y = -0.01
          balanceTop.position.x = x === 0 ? 0.3 : -0.3
          balanceTop.position.y = +0.01
          balanceTop.rotateX(1 * Math.PI)
          this.balance.add(balanceTop)
          this.balance.add(balanceBottom)
        }
      }
    },
    reachDistance: function () {
      if (this.assessment == null) return

      this.reach = new THREE.Group()
      this.reach.name = OBJECT_REACH_DISTANCE
      const distances = ['HandLeftMinDistanceToFloor', 'HandRightMinDistanceToFloor']
      const loader = new THREE.FontLoader()
      const font = loader.parse(Font3D)
      const bodyHeight = this.scanData.person.evaluated_height
      for (var i = 0; i < distances.length; i++) {
        let size = this.assessment[distances[i]]
        let material = new THREE.LineDashedMaterial({ color: 0x0000FF, linewidth: 1, dashSize: 0.02, gapSize: 0.01 })
        let geometry = new THREE.Geometry()
        let sign = i === 0 ? 1 : -1
        let side = 0.55
        geometry.vertices.push(new THREE.Vector3(sign * side, 0, 0))
        geometry.vertices.push(new THREE.Vector3(sign * side, size, 0))
        let line = new THREE.Line(geometry, material)
        line.computeLineDistances()
        line.name = i === 0 ? 'leftLine' : 'rightLine'
        this.reach.add(line)
        material = new THREE.LineBasicMaterial({ color: 0xcccccc })
        geometry = new THREE.Geometry()
        geometry.vertices.push(new THREE.Vector3(sign * side, 0, 0))
        geometry.vertices.push(new THREE.Vector3(sign * (side - 0.2), 0, 0))
        line = new THREE.Line(geometry, material)
        this.reach.add(line)
        geometry = new THREE.Geometry()
        geometry.vertices.push(new THREE.Vector3(sign * side, size, 0))
        geometry.vertices.push(new THREE.Vector3(sign * (side - 0.2), size, 0))
        line = new THREE.Line(geometry, material)
        this.reach.add(line)
        const dir = new THREE.Vector3(0, -1, 0)
        // normalize the direction vector (convert to vector of length 1)
        dir.normalize()
        const origin = new THREE.Vector3(sign * side, 0.05, 0)
        const length = 0.05
        const hex = 0x0000FF
        const headWidth = 0.02
        const headLength = 0.05
        const arrowHelper = new THREE.ArrowHelper(dir, origin, length, hex, headLength, headWidth)
        arrowHelper.name = i === 0 ? 'leftArrow' : 'rightArrow'
        this.reach.add(arrowHelper)
        // Generate text
        const text = [this.$formatPercent(size, bodyHeight), this.$formatLength(size * 100, 'cm', this.isMetric)]
        const fontSizes = [0.05, 0.035]
        for (var j = 0; j < text.length; j++) {
          const color = 0x0000FF
          const matLite = new THREE.MeshBasicMaterial({
            color: color,
            transparent: true,
            opacity: 1,
            side: THREE.FrontSide
          })
          const shapes = font.generateShapes(text[j], fontSizes[j])
          geometry = new THREE.ShapeGeometry(shapes)
          geometry.computeBoundingBox()
          const xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x)
          geometry.translate(xMid, 0.08 / (j + 1), 0)
          let textFront = new THREE.Mesh(geometry, matLite)
          let textBack = new THREE.Mesh(geometry, matLite)
          textFront.name = i === 0 ? j === 0 ? 'leftPercent' : 'leftAbsolute' : j === 0 ? 'rightPercent' : 'rightAbsolute'
          textFront.position.x = sign * (side + 0.1)
          textFront.position.z = -0.01
          textBack.position.x = sign * (side + 0.1)
          textBack.position.z = +0.01
          textBack.rotateY(1 * Math.PI)
          this.reach.add(textFront)
          this.reach.add(textBack)
          geometry.dispose()
        }
      }
    },
    buildPlumbLine: function () {
      // let material = new THREE.LineBasicMaterial({ color: 0xFF0000, linewidth: 5 })
      let material = new THREE.LineBasicMaterial({ color: 0xFF0000, transparent: true, depthTest: false, depthWrite: false, linewidth: 1 })
      let geometry = new THREE.Geometry()
      geometry.vertices.push(new THREE.Vector3(0, 0, 0))
      geometry.vertices.push(new THREE.Vector3(0, this.bBox.max.y, 0))
      this.plumbLine = new THREE.Line(geometry, material)
      this.plumbLine.name = 'plumbLine'
      geometry.dispose()
      material.dispose()
      // this.scene.add(this.plumbLine)
    },
    buildFloor: function () {
      this.floorPlaneGrid = new THREE.GridHelper(5, 20, 0x0000ff, 0xdddddd)
      this.floorPlaneGrid.position.y = 0
      this.floorPlaneGrid.position.x = 0
      this.floorPlaneGrid.position.z = 0
      this.floorPlaneGrid.name = 'floorPlaneGrid'
      this.scene.add(this.floorPlaneGrid)
      let geometry = new THREE.PlaneGeometry(20, 20, 1)
      geometry.rotateX(-0.5 * Math.PI)
      geometry.rotateY(0.5 * Math.PI)
      let material = new THREE.MeshBasicMaterial({color: 0xffffff})
      this.floorPlane = new THREE.Mesh(geometry, material)
      this.floorPlane.position.y = 0.0000001
      this.floorPlane.position.x = 0
      this.floorPlane.position.z = 0
      this.floorPlane.name = 'floorPlane'
      this.scene.add(this.floorPlane)
    },
    buildFloorTarget: function () {
      this.floorTarget = new THREE.Group()
      this.floorTarget.name = TOGGLE_FLOOR_TARGET

      let size = 0.25
      let material = new THREE.LineBasicMaterial({ color: 0xd8d8d8, linewidth: 3 })
      let geometry = new THREE.Geometry()
      geometry.vertices.push(new THREE.Vector3(-size, 0, 0))
      geometry.vertices.push(new THREE.Vector3(size, 0, 0))
      let line = new THREE.Line(geometry, material)
      this.floorTarget.add(line)
      geometry.dispose()
      material.dispose()

      geometry = new THREE.Geometry()
      geometry.vertices.push(new THREE.Vector3(0, 0, -size))
      geometry.vertices.push(new THREE.Vector3(0, 0, size))
      line = new THREE.Line(geometry, material)
      this.floorTarget.add(line)
      geometry.dispose()
      material.dispose()

      let curve = new THREE.EllipseCurve(0, 0, size, size)
      let points = curve.getPoints(50)
      geometry = new THREE.Geometry().setFromPoints(points)
      let circle = new THREE.Line(geometry, material)
      circle.rotation.x = Math.PI / 2
      this.floorTarget.add(circle)
      geometry.dispose()
      material.dispose()

      curve = new THREE.EllipseCurve(0, 0, size / 2, size / 2)
      points = curve.getPoints(50)
      geometry = new THREE.Geometry().setFromPoints(points)
      circle = new THREE.Line(geometry, material)
      circle.rotation.x = Math.PI / 2
      this.floorTarget.add(circle)
      // Put just above the floor
      this.floorTarget.translateY(1e-3)
      geometry.dispose()
      material.dispose()
    },
    buildPlanesOfMotion: function () {
      let opacity = 0.5
      let transparent = true
      let depthWrite = false

      // Along X-plane
      let color = 0x0066FF
      let material = new THREE.MeshBasicMaterial({color: color, opacity: opacity, transparent: transparent, side: THREE.DoubleSide, depthWrite: depthWrite})
      // let material = new THREE.MeshBasicMaterial({color: color, side: THREE.DoubleSide})
      let planeWidth = (Math.abs(this.bBox.min.x) + Math.abs(this.bBox.max.x)) * 2
      let planeHeight = Math.abs(this.bBox.min.y) + Math.abs(this.bBox.max.y) + 0.1
      let geometry = new THREE.PlaneGeometry(planeWidth, planeHeight, 1)
      geometry.translate(0, this.center.y, 0)
      this.pomX = new THREE.Mesh(geometry, material)
      this.pomX.name = 'pomX'
      geometry.dispose()
      material.dispose()

      // Along Y-plane
      color = 0xFF0000
      material = new THREE.MeshBasicMaterial({color: color, opacity: opacity, transparent: transparent, side: THREE.DoubleSide, depthWrite: depthWrite})
      planeWidth = (Math.abs(this.bBox.min.z) + Math.abs(this.bBox.max.z)) * 2
      planeHeight = Math.abs(this.bBox.min.y) + Math.abs(this.bBox.max.y) + 0.1
      geometry = new THREE.PlaneGeometry(planeWidth, planeHeight, 1)
      geometry.rotateY(0.5 * Math.PI)
      geometry.translate(0, this.center.y, 0)
      this.pomY = new THREE.Mesh(geometry, material)
      this.pomY.name = 'pomY'
      geometry.dispose()
      material.dispose()

      // Along Z-plane
      color = 0x33CC66
      material = new THREE.MeshBasicMaterial({color: color, opacity: opacity, transparent: transparent, side: THREE.DoubleSide, depthWrite: depthWrite})
      planeWidth = (Math.abs(this.bBox.min.z) + Math.abs(this.bBox.max.z)) * 2
      planeHeight = (Math.abs(this.bBox.min.x) + Math.abs(this.bBox.max.x)) * 2
      geometry = new THREE.PlaneGeometry(planeWidth, planeHeight, 1)
      geometry.rotateX(0.5 * Math.PI)
      geometry.rotateY(0.5 * Math.PI)
      geometry.translate(0, this.center.y, 0)
      // material = new THREE.MeshBasicMaterial( {color: 0x00ff00, opacity: opacity, transparent: true, side: THREE.DoubleSide} );
      this.pomZ = new THREE.Mesh(geometry, material)
      this.pomZ.name = 'pomZ'
      geometry.dispose()
      material.dispose()
    },
    buildLighting: function () {
      var ambientLight = new THREE.AmbientLight(0x404040)
      this.scene.add(ambientLight)

      var lightFront = new THREE.PointLight(0xffffff, 1, 5, 2)
      lightFront.position.set(0, this.center.y, 2)
      this.scene.add(lightFront)
    },
    // ////////////////////////////////////// //
    //          Run/Update Scene              //
    // ////////////////////////////////////// //
    adjustCamera: function (size) {
      const maxDim = Math.max(size.x, size.y, size.z)
      const fov = this.camera.fov * (Math.PI / 180)
      let cameraZ = Math.abs(maxDim / 4 * Math.tan(fov * 2))
      cameraZ *= 1.3 + 0.5
      const minZ = this.bBox.min.z
      const cameraToFarEdge = (minZ < 0) ? -minZ + cameraZ : cameraZ - minZ
      this.camera.far = cameraToFarEdge * 11
      this.controls.maxDistance = cameraToFarEdge * 8
      this.camera.updateProjectionMatrix()
    },
    animate: function () { // Scene render loop
      // TODO: Do not render when there were no changes since the previous render
      //       I.e. paused, no animation, layers not changed
      this.currentRender = requestAnimationFrame(this.animate) // Loop
      // Limit model to recording FPS
      let now = Date.now()
      let delta = now - this.last
      if ((!this.last || delta >= this.fps) && this.frames.length > 0) {
        if (this.play === true) {
          this.advanceFrame()
        }
        this.last = now - (delta % this.fps)
      }
      // Update rest as fast as possible
      if (this.measurePlacement) {
        this.updateScreenPosition()
      }
      TWEEN.update()
      this.controls.update()
      this.camera.updateProjectionMatrix()
      this.renderer.render(this.scene, this.camera)
    },
    setCurrentFrame: function (newFrame) {
      // Ensure we are in range
      let f = Math.max(0, Math.min(this.frames.length - 1, Math.round(newFrame)))
      // Ensure we only go to loaded frames
      f -= f % this.nextFrame

      const removeAllWithName = name => {
        let o
        while ((o = this.scene.getObjectByName(name)) != null) {
          this.scene.remove(o)
        }
      }

      if (f !== this.playFrame) {
        // Update scene contents
        removeAllWithName(TOGGLE_BODY)
        removeAllWithName(TOGGLE_TRACKING)
        removeAllWithName(OBJECT_TRACKING_FLOOR)

        this.playFrame = f

        if (this.showBody && this.frames[this.playFrame]) {
          const body = this.frames[this.playFrame][TOGGLE_BODY]
          if (body != null) this.scene.add(body)
        }
        if (this.showTracking && this.frames[this.playFrame]) {
          if (!this.isBalanceView) {
            const tracking = this.frames[this.playFrame][TOGGLE_TRACKING]
            if (tracking != null) this.scene.add(tracking)
          }
          const trackingFloor = this.frames[this.playFrame][OBJECT_TRACKING_FLOOR]
          if (trackingFloor != null) this.scene.add(trackingFloor)
        }
      }

      if (this.emitTime) {
        this.$root.$emit('syncFrames', this.playFrame)
      }
    },
    advanceFrame: function () {
      let f = this.playFrame + this.nextFrame
      // Loop playback
      if (f >= this.frames.length) f = 0

      this.setCurrentFrame(f)
    },
    onWindowResize: function () { // Handles window resizing
      this.$nextTick(() => {
        if (this.container == null) return
        this.calcCanvasSize()
        if (this.camera != null) {
          this.camera.aspect = this.canvasWidth / this.canvasHeight // Adjust aspect ratio to new values
          this.camera.updateProjectionMatrix() // Actually use the values
          this.renderer.setSize(this.canvasWidth, this.canvasHeight, false) // Adjust render area to new size
        }
      })
    },
    onPositionChange: function () {
      if (this.controls.enabled === true) {
        if (this.controls.target.y !== 0 || this.controls.getPolarAngle() > 1e-2) {
          // View differs significantly from the balance - exit this mode
          this.isBalanceView = false
        }
      }
      let degrees = Math.atan(this.camera.position.x / Math.abs(this.camera.position.z)) * (180 / Math.PI)
      if (this.camera.position.z < 0) {
        degrees = 180 - degrees
      } else if (this.camera.position.x < 0) {
        degrees = 360 + degrees
      }
      if (degrees < 45 || degrees >= 315) {
        this.left = this.$t('scans.viewer.orientation.right')
        this.right = this.$t('scans.viewer.orientation.left')
        this.viewPoint = ScanView3D.Front
      } else if (degrees >= 45 && degrees < 135) {
        this.left = this.$t('scans.viewer.orientation.anterior')
        this.right = this.$t('scans.viewer.orientation.posterior')
        this.viewPoint = ScanView3D.Left
      } else if (degrees >= 135 && degrees < 225) {
        this.left = this.$t('scans.viewer.orientation.left')
        this.right = this.$t('scans.viewer.orientation.right')
        this.viewPoint = ScanView3D.Back
      } else if (degrees >= 225 && degrees < 315) {
        this.left = this.$t('scans.viewer.orientation.posterior')
        this.right = this.$t('scans.viewer.orientation.anterior')
        this.viewPoint = ScanView3D.Right
      }
    },
    // ////////////////////////////////////// //
    //              Controls                  //
    // ////////////////////////////////////// //
    view: function (viewFrom) {
      let endPos = null
      let distance = (this.center.y * this.offset) / Math.tan((this.camera.fov / 2 * Math.PI / 180))
      switch (viewFrom) {
        case ScanView3D.Left:
          endPos = new THREE.Vector3(-distance, this.center.y, 0)
          break
        case ScanView3D.Front:
          endPos = new THREE.Vector3(0, this.center.y, distance)
          break
        case ScanView3D.Right:
          endPos = new THREE.Vector3(distance, this.center.y, 0)
          break
        case ScanView3D.Top:
          endPos = new THREE.Vector3(0, this.bBox.max.y + (this.center.y / 2), distance)
          break
        case ScanView3D.Bottom:
          endPos = new THREE.Vector3(0, (-this.center.y / 2), distance)
          this.camera.rotateX(0)
          this.camera.rotateY(0)
          this.camera.rotateZ(0)
          break
        case ScanView3D.Balance:
          endPos = new THREE.Vector3(0, this.center.y, 0)
          break
      }

      var duration = 2000
      let ease = TWEEN.Easing.Quartic.InOut
      this.controls.enabled = false
      var position = new THREE.Vector3().copy(this.camera.position)

      let cameraCenter = viewFrom !== ScanView3D.Balance ? new THREE.Vector3(0, this.bBox.max.y / 2, 0) : new THREE.Vector3(0, 0, 0)
      new TWEEN.Tween(position)
        .to(endPos, duration)
        .easing(ease)
        .onUpdate(() => {
          this.camera.position.copy(position)
          this.camera.lookAt(cameraCenter)
          this.controls.target = cameraCenter
        })
        .onComplete(() => {
          this.camera.position.copy(endPos)
          this.camera.lookAt(this.center)
          this.controls.target = cameraCenter
          this.controls.enabled = true
        })
        .start()

      this.isBalanceView = viewFrom === ScanView3D.Balance
    },
    balanceMe: function () {
      this.view(ScanView3D.Balance)
      this.guideToggles = [TOGGLE_FLOOR_TARGET]
    },
    toggleSceneObject: function (name, visible) {
      if (visible == null) visible = this.scene.getObjectByName(name) == null

      if (visible && this.scene.getObjectByName(name) == null) {
        const o = this[name]
        if (o) this.scene.add(o)
      } else if (!visible) {
        let o
        while ((o = this.scene.getObjectByName(name)) != null) {
          this.scene.remove(o)
        }
      }
    },
    toggleFrameObject: function (name, visible) {
      if (this.playFrame < 0 || this.playFrame >= this.frames.length) return

      const o = this.frames[this.playFrame][name]
      if (!o) return

      if (visible == null) visible = !this.scene.children.includes(o)

      if (visible && !this.scene.children.includes(o)) {
        this.scene.add(o)
      } else if (!visible) {
        this.scene.remove(o)
      }
    },
    toggleAngles: function () {
      this.measureAngles = !this.measureAngles
      this.measureDisplacement = !this.measureAngles
    },
    toggleDisplacement: function () {
      this.measureDisplacement = !this.measureDisplacement
      this.measureAngles = !this.measureDisplacement
    },
    toggleNarrative: function () {
      this.$emit('toggle-narrative')
    },
    syncSceneWithToggles: function () {
      if (!this.play) {
        this.toggleFrameObject(TOGGLE_BODY, this.showBody)
        this.toggleFrameObject(TOGGLE_TRACKING, this.showTracking && !this.isBalanceView)
        this.toggleFrameObject(OBJECT_TRACKING_FLOOR, this.showTracking)
      }
      this.toggleSceneObject(TOGGLE_TRAJECTORIES, !this.isBalanceView && this.showTrajectories)
      this.toggleSceneObject(OBJECT_TRAJECTORIES_FLOOR, this.showTrajectories)
      this.toggleSceneObject(OBJECT_BALANCE, this.isBalanceView || this.showMeasures)
      this.toggleSceneObject(TOGGLE_FLOOR_TARGET, this.guideToggles.includes(TOGGLE_FLOOR_TARGET))
      this.toggleSceneObject(TOGGLE_PLUMB_LINE, this.guideToggles.includes(TOGGLE_PLUMB_LINE) && !this.isBalanceView)
      this.toggleSceneObject(TOGGLE_POM_X, this.guideToggles.includes(TOGGLE_POM_X) && !this.isBalanceView)
      this.toggleSceneObject(TOGGLE_POM_Y, this.guideToggles.includes(TOGGLE_POM_Y) && !this.isBalanceView)
      this.toggleSceneObject(TOGGLE_POM_Z, this.guideToggles.includes(TOGGLE_POM_Z) && !this.isBalanceView)
      if (this.movement === 'side_bend') this.toggleSceneObject(OBJECT_REACH_DISTANCE, this.showMeasures)
    },
    comparingScans: function () {
      // Adding listners and sending data
      this.controls.addEventListener('change', () => {
        if (this.syncView && !this.loading) this.$root.$emit('movingCam', this.camera, this.controls, this.scanId, this.secondaryScan)
      })

      // Recieving data and updating view
      this.$root.$on('movingCam', (camera, controls) => {
        if (this.syncView && this.camera && !this.loading) {
          this.camera.copy(camera)
          this.controls.target = controls.target
        }
      })
      this.$root.$on('playToggle', (play) => {
        if (this.syncView) this.play = play
      })
      this.$root.$on('layerChange', (guideToggles) => {
        if (this.syncView) this.guideToggles = guideToggles
      })
      this.$root.$on('visibilityChange', (visibilityToggles) => {
        if (this.syncView) this.visibilityToggles = visibilityToggles
      })
      this.$root.$on('syncedFrame', (frame) => {
        if (!this.emitTime) this.setCurrentFrame(frame)
      })
    },
    getArrow: function (measurePlace, name) {
      let arrow = measurePlace.arrow
      const value = this.measureVal(name, measurePlace.type)
      let reverseH = false
      if ([ScanMeasureType.AngleDirection, ScanMeasureType.Displacement].includes(measurePlace.type)) {
        reverseH = ([ScanView3D.Front, ScanView3D.Left].includes(this.viewPoint) && value < 0) ||
          ([ScanView3D.Back, ScanView3D.Right].includes(this.viewPoint) && value > 0)
      }
      if (reverseH) {
        switch (arrow) {
          case ScanMeasureArrow.Left:
            arrow = ScanMeasureArrow.Right
            break
          case ScanMeasureArrow.Right:
            arrow = ScanMeasureArrow.Left
            break
        }
      }
      return `mdi-arrow-${arrow}-bold`
    },
    saveViewerSettings () {
      this.storeViewerSettings({
        visibilityToggles: this.visibilityToggles,
        guideToggles: this.guideToggles,
        measureDisplacement: this.measureDisplacement,
        measureAngles: this.measureAngles
      })
    },
    restoreViewerSettings () {
      if (this.viewerSettings != null) {
        this.visibilityToggles = this.viewerSettings.visibilityToggles
        this.guideToggles = this.viewerSettings.guideToggles
        this.measureDisplacement = this.viewerSettings.measureDisplacement
        this.measureAngles = this.viewerSettings.measureAngles
      }
    },
    setMovementSections: function () {
      let start = []
      let end = []
      if (this.assessment != null) {
        switch (this.movement) {
          case MovementKind.SIDE_BEND:
            start.push(this.assessment.SelectROILeft.FromFrameIndexInclusive)
            end.push(this.assessment.SelectROILeft.ToFrameIndexExclusive)
            start.push(this.assessment.SelectROIRight.FromFrameIndexInclusive)
            end.push(this.assessment.SelectROIRight.ToFrameIndexExclusive)
            break
          case MovementKind.SQUAT:
          case MovementKind.SQUAT_LEFT:
          case MovementKind.SQUAT_RIGHT:
            start.push(this.assessment.SelectROI.FromFrameIndexInclusive)
            end.push(this.assessment.SquatMiddleFrame)
            start.push(this.assessment.SquatMiddleFrame)
            end.push(this.assessment.SelectROI.ToFrameIndexExclusive)
            break
        }
      }
      this.movementSections = {start: start, end: end}
    },
    colorObject: function () {
      if (this.movement === 'side_bend') {
        let obj = this.scene.getObjectByName('reach')
        if (obj) {
          let blue = []
          let grey = []
          if (this.currentSection === 1) {
            grey = obj.children.filter(c => c.name.includes('right'))
            blue = obj.children.filter(c => c.name.includes('left'))
          } else if (this.currentSection === 2) {
            grey = obj.children.filter(c => c.name.includes('left'))
            blue = obj.children.filter(c => c.name.includes('right'))
          } else {
            blue = [...obj.children.filter(c => c.name.includes('right')), ...obj.children.filter(c => c.name.includes('left'))]
          }
          blue.forEach(elem => {
            if (elem.name.includes('Arrow')) {
              elem.children.forEach(c => {
                c.material.color.setHex(0x0000FF)
              })
            } else {
              elem.material.color.setHex(0x0000FF)
            }
          })
          grey.forEach(elem => {
            if (elem.name.includes('Arrow')) {
              elem.children.forEach(c => {
                c.material.color.setHex(0xcccccc)
              })
            } else {
              elem.material.color.setHex(0xcccccc)
            }
          })
        }
      }
    }
  },
  watch: {
    depthMap: function () {
      this.forceRebuild()
      this.setMovementSections()
    },
    visibilityToggles: function () {
      this.syncSceneWithToggles()
      if (this.syncView) this.$root.$emit('visibilityChange', this.visibilityToggles, this.scanId)
      this.saveViewerSettings()
    },
    guideToggles: function () {
      this.syncSceneWithToggles()
      if (this.syncView) this.$root.$emit('layerChange', this.guideToggles, this.scanId)
      this.saveViewerSettings()
    },
    isBalanceView: function () {
      this.syncSceneWithToggles()
    },
    play: function () {
      if (this.syncView) this.$root.$emit('playToggle', this.play)
    },
    presentMode: function () {
      this.$nextTick(() => {
        this.$root.$emit('resizeElements', this.presentMode)
        this.onWindowResize()
      })
    },
    // It would be better to handle the resize of this.container
    // instead of window + breakpoint.
    // Check ResizeObserver API
    // https://developer.mozilla.org/docs/Web/API/Resize_Observer_API
    '$vuetify.breakpoint.name': function () {
      this.onWindowResize()
    },
    cameraExternal: function () {
      this.camera.copy(this.cameraExternal)
    },
    mirror: function () {
      let scale = new THREE.Vector3(-1, 1, 1)
      this.frames.forEach(frame => {
        frame[TOGGLE_BODY].scale.multiply(scale)
        if (frame[TOGGLE_TRACKING]) frame[TOGGLE_TRACKING].scale.multiply(scale)
        if (frame[OBJECT_TRACKING_FLOOR]) frame[OBJECT_TRACKING_FLOOR].scale.multiply(scale)
      })
      this.trajectories.children.forEach((trajectory) => {
        trajectory.geometry.scale.multiply(scale)
      })
      this[OBJECT_TRAJECTORIES_FLOOR].children.forEach((trajectory) => {
        trajectory.geometry.scale.multiply(scale)
      })
    },
    measureAngles: function () {
      this.saveViewerSettings()
    },
    measureDisplacement: function () {
      this.saveViewerSettings()
    },
    currentSection: function () {
      this.colorObject()
    }
  },

  created () {
    feetBuilder.ensureSvgLoaded()
    if (this.viewerSettings == null) {
      this.saveViewerSettings()
    } else {
      this.restoreViewerSettings()
    }
  },

  mounted () {
    this.init()
    this.animate()
    window.addEventListener('resize', this.onWindowResize, { passive: true })
    if (this.compare) {
      this.renderOffset = 2
      this.renderLimit = 2
      this.comparingScans()
    }
    this.$root.$on('resizeElements', (presentMode) => {
      this.presentMode = presentMode
      this.onWindowResize()
    })
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.renderOffset = 4
      this.renderLimit = 4
    }
    this.onWindowResize()
  },
  beforeDestroy () {
    if (this.worker !== undefined) {
      this.worker.terminate()
      this.worker = undefined
    }
    cancelAnimationFrame(this.currentRender)
    this.camera = null
    this.scene = null
    this.renderer = null
    this.controls = null
    this.container = null
    this.frames = []
    this.trackingPointFrames = []
    this.pointTexture = null
    this.bBox = null
    this.center = null
    this.floorTarget = null
    this.plumbLine = null
    this.pomX = null
    this.pomY = null
    this.pomZ = null
    this.axesHelper = null
    this.trajectories = null
    this[OBJECT_TRAJECTORIES_FLOOR] = null
    this.presentMode = false
    window.removeEventListener('resize', this.onWindowResize, { passive: true })
    this.$root.$emit('resizeElements', false)
  }
}
</script>

<style scoped>
.top-right{
  position: absolute;
  top: 15px;
  right: 15px;
}

.top-left{
  position: absolute;
  top: 15px;
  left: 15px;
}

.bottom-right{
  position: absolute;
  bottom: 80px;
  right: 15px;
}

.move-controls{
  transform: translateX(98%);
  z-index: 2
}

.annotation {
  position: absolute;
  padding: 1em;
  top: 0;
  margin-top: 15px;
  font-size: 12px;
  line-height: 1.2;
  z-index: 99;
  background: rgba(0, 0, 0, 0.8);
  border-radius: .1em
}

.annotation.right{
  right: 0;
  margin-right: 35px;
}

.annotation.left{
  left: 0;
  margin-left: 35px;
}
.annotation strong {
  color: #fff;
}

.annotation span{
  padding: .1em 0;
  color: #fff;
  transition: opacity .5s;
  display: block;
}

.orientation-helper {
    position: absolute;
    width: 100%;
    top: 75%;
    padding: 0 15px;
}

.component-fade-enter-active, .component-fade-leave-active{
  transition: .2s ease;
}

.component-fade-enter, .component-fade-leave-to{
  opacity: 0;
}

.view-container,
.view-container *{
  display: block;
  margin: auto;
}

.annotation_lines,
.annotations {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.annotations{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 15px;
}

.single_annotation {
    margin: 0!important;
}

.annotations .v-card{
  width: 20%;
}

.single-measure:nth-child(odd) div {
  border-right: 1px solid #000
}

.single-measure .v-card__title {
    font-size: .9rem;
}

polyline {
    fill: none;
    stroke-width: 3;
    stroke-linecap: round;
    stroke: rgba(0,0,0,0.5);
}
</style>
