var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"playback"},[_c('div',{staticClass:"d-flex flex-wrap justify-center"},[_c('div',[_c('v-tooltip',{staticClass:"d-inline-flex",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-5 px-0 ml-2 ",attrs:{"icon":"","small":"","disabled":_vm.currentFrame <= 0 || _vm.playbackDisabled},on:{"click":_vm.raisePrevFrame}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-step-backward")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('scans.buttons.previous-frame')))])]),_c('v-tooltip',{staticClass:"d-inline-flex",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-5 px-0 ml-2",attrs:{"icon":"","small":"","disabled":_vm.playbackDisabled},on:{"click":_vm.togglePlaying}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.playing ? 'mdi-pause' : 'mdi-play'))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('scans.buttons.' + (_vm.playing ? 'pause' : 'play'))))])]),_c('v-tooltip',{staticClass:"d-inline-flex",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-5 px-0",attrs:{"icon":"","small":"","disabled":_vm.currentFrame >= _vm.frameCount - 1 || _vm.playbackDisabled},on:{"click":_vm.raiseNextFrame}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-step-forward")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('scans.buttons.next-frame')))])])],1),_c('div',{staticClass:"flex-grow-1 align-self-stretch d-flex align-center playbar"},[_c('v-slider',{staticClass:"mx-2",staticStyle:{"min-width":"100px"},attrs:{"id":"timeline","dense":"","hide-details":"","color":"MProCoral","min":"0","max":_vm.maxFrame,"value":_vm.currentFrame,"readonly":_vm.playbackDisabled},on:{"input":_vm.scrollToFrame}}),_c('div',{staticClass:"text-right",style:({ minWidth: (_vm.timeAndFrameStatusWidth + "ex")})},[_c('span',[_vm._v(_vm._s(_vm.displayedCurrentTime.toFixed(2)))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s((_vm.maxFrame / _vm.fps).toFixed(2)))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.displayedCurrentFrame))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(_vm.maxFrame))])]),_c('v-tooltip',{attrs:{"bottom":!_vm.fullscreen,"top":_vm.fullscreen},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-5 px-0 mx-2",attrs:{"icon":""},on:{"click":_vm.toggleFullscreen}},'v-btn',attrs,false),on),[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fullscreen),expression:"!fullscreen"}]},[_vm._v("mdi-fullscreen")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.fullscreen),expression:"fullscreen"}]},[_vm._v("mdi-fullscreen-exit")])],1)]}}])},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fullscreen),expression:"!fullscreen"}]},[_vm._v(_vm._s(_vm.$t('scans.buttons.full-screen')))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.fullscreen),expression:"fullscreen"}]},[_vm._v(_vm._s(_vm.$t('scans.buttons.exit-full-screen')))])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }