<template>
  <div class="d-inline-block">
    <div class="d-flex">
      <v-dialog v-model="creatingNew"
                max-width="600" scrollable
                @keydown.esc="creatingNew = false">
        <template #activator="{on}">
          <v-btn class="MProPurple ma-6" small
                 v-on="on">
            <v-icon small>mdi-plus</v-icon>
            <small>{{ $t('admin.new_library') }}</small>
          </v-btn>
        </template>
        <mpro-editable-card ref="createNewCard"
                            :save-callback="saveNew"
                            @end-edit="onCreateNewEndEdit">
          <template #title>{{ $t('admin.new_library') }}</template>
          <mpro-library-details ref="createNewDetails"
                                :owner-code="organizationCode"/>
        </mpro-editable-card>
      </v-dialog>
    </div>
    <v-skeleton-loader type="table-thead, table-tbody" :loading="loading">
      <mpro-libraries-table :libraries="libraries"/>
    </v-skeleton-loader>
  </div>
</template>

<script>
import LibrariesTable from '../libraries/table'
import LibraryDetails from '../libraries/details'
import EditableCard from '@/components/elements/editable-card'
import {MessageKind} from '@/helpers/enums'
import { mapActions } from 'vuex'

export default {
  components: {
    'mpro-libraries-table': LibrariesTable,
    'mpro-library-details': LibraryDetails,
    'mpro-editable-card': EditableCard
  },

  props: {
    organizationCode: String,
    libraries: { type: Array, default: () => [] }
  },

  data: () => ({
    creatingNew: false
  }),

  computed: {
    loading: function () {
      return this.organizationCode == null
    }
  },

  watch: {
    creatingNew: async function (newValue) {
      if (newValue) {
        await this.$nextTick()
        this.$refs.createNewCard.beginEdit()
      } else if (this.$refs.createNewDetails) {
        this.$refs.createNewDetails.syncLibraryToData()
        await this.$nextTick()
        this.$refs.createNewDetails.$v.$reset()
      }
    }
  },

  methods: {
    async saveNew () {
      if (!this.$refs.createNewDetails.validate()) return false

      let libraryId
      try {
        const res = await this.createLibrary(this.$refs.createNewDetails.data)
        libraryId = res.id
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e)
        })
        return false
      }

      this.$router.push({name: 'admin:Library', params: {id: libraryId}})
      return true
    },
    onCreateNewEndEdit () {
      this.creatingNew = false
    },
    ...mapActions(['showGlobalMessage']),
    ...mapActions('admin', ['createLibrary'])
  }
}
</script>
