<template>
  <div :class="embedded ? 'click-through' : 'my-7 py-0'">
    <v-card @click="onCardClick"
            :class="cardClass" :hover="!embedded"
            :flat="embedded" :color="embedded ? 'transparent' : null">
      <v-card-title class="d-flex flex-wrap">
        <span>{{ name }}</span>
        <span class="flex-grow-1 ml-4 text-right">
          {{ $t('programs.times-per-day', {num: program.FrequencyPerDay}) }}
        </span>
      </v-card-title>
      <v-card-subtitle class="d-flex">
        <div>
          {{ $t('programs.duration-program', {num: program.DurationWeeks}) }}
        </div>
        <div v-if="isScheduledForToday" class="flex-grow-1 text-right">
          {{ program.FrequencyPerDay === 2 ? $t('programs.twice-per-day'): $t('programs.once-per-day') }}
        </div>
      </v-card-subtitle>
      <v-card-text>
        <v-progress-linear :value="program.progress"
                           rounded height="10" opacity="1"
                           :class="embedded ? 'my-2' : null"/>
        <div class="d-flex">
          <span>
            {{ $tc('programs.num-of-exercises', program.ExerciseIds.length, {num: program.ExerciseIds.length}) }}
          </span>
          <span class="flex-grow-1 text-right">{{ status }}</span>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="value" @input="onInput"
              scrollable
              width="1000" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card>
        <v-card-title primary-title>
          <div class="flex-grow-1 d-flex justify-space-between align-start">
            <div>
              {{ $t('programs.header_title', {num: program.DurationWeeks}) }}
            </div>
            <v-btn icon @click="closeDetails">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-container class="ma-0 py-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" sm="6" class="ma-0 pa-0">
                {{ $formatDate(program.StartDate) }} - {{ $formatDate(programEndDate) }}
              </v-col>
              <v-col cols="6" class="text-right ma-0 pa-0">
                {{ $t('programs.times-per-day', {num: program.FrequencyPerDay}) }}
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col cols="12" sm="6" class="ma-0 pa-0">
                {{ $t('programs.duration-program', {num: program.DurationWeeks}) }}
              </v-col>
              <v-col v-if="isScheduledForToday" cols="12" sm="6" class="text-right ma-0 pa-0">
                {{ program.FrequencyPerDay === 2 ? $t('programs.twice-per-day'): $t('programs.once-per-day') }}
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-progress-linear rounded :value="program.progress" height="10" opacity="1"></v-progress-linear>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                {{ name }}
              </v-col>
              <v-col v-if="canAskQuestion" cols="12" sm="6">
                <v-btn :to="locationToAskQuestion" class="MProBlue">{{ $t('general.buttons.ask-question') }}</v-btn>
              </v-col>
              <v-col v-if="program.Notes" cols="12">
                <mpro-read-more :text="program.Notes" :charCount="100" linkify />
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <mpro-single-exercise v-for="exerciseId in program.ExerciseIds" :key="exerciseId"
                                :exercise-id="exerciseId" :program-id="program.Id" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import exerciseSingle from './exercise_single'
import readMore from './../elements/read-more'
import Styles from '@/helpers/styles'
import ProgramAnalyzer from './program-analyzer'
import { UserLogin } from '@/models/users'

export default {
  components: {
    'mpro-single-exercise': exerciseSingle,
    'mpro-read-more': readMore
  },

  props: {
    program: Object,
    embedded: Boolean,
    displayName: { type: String, default: 'Author' },
    value: Boolean
  },

  computed: {
    ...mapGetters(['getHeader', 'getRole', 'isSpecialist', 'isSeeker']),
    ...mapState({
      exercises: state => state.exercises[this.program.Id].assignedExercises
    }),
    ...mapGetters('chats', ['getChatUuid']),
    ...mapGetters('user', ['getDisplayName']),
    ...mapGetters('specialist', ['getSeekerById']),
    roleBasedColor: function () {
      return `${Styles.getRoleColor(this.getRole)} white--text`
    },
    isActive: function () {
      return ProgramAnalyzer.isActive(this.program)
    },
    isFinished: function () {
      return ProgramAnalyzer.isFinished(this.program)
    },
    cardClass: function () {
      let result = ''
      if (this.embedded) {
        result += ' embedded'
      } else {
        result += ' py-4 px-1 px-md-7 px-lg-14'
      }
      if (this.isFinished) {
        result += ' v-card--look-disabled'
      }
      return result
    },
    status: function () {
      if (ProgramAnalyzer.isPending(this.program)) {
        return this.$t('programs.starting-date', {date: this.$formatDate(this.program.StartDate)})
      } else if (ProgramAnalyzer.isActive(this.program)) {
        return ''
      } else if (ProgramAnalyzer.isFinished(this.program)) {
        return this.$t('programs.finished-date', {days: ProgramAnalyzer.daysSinceFinished(this.program)})
      }
      return ''
    },
    name: function () {
      switch (this.displayName) {
        case 'Author':
          return this.$t('programs.assigned-by', {
            author: this.program.AuthorName || this.program.Author || this.$t('specialist.anonymous')
          })
        case 'Seeker':
          return this.isSeeker
            ? this.getDisplayName()
            : this.getSeekerById(this.program.SeekerId)?.DisplayName
        default:
          return ''
      }
    },
    canAskQuestion: function () {
      return this.isSeeker &&
        this.program.Author != null &&
        this.program.Author !== UserLogin.System
    },
    locationToAskQuestion: function () {
      return {
        name: 'Chat',
        params: { userUuid: this.getChatUuid(this.program.Author) },
        query: { type: 'ExerciseProgram', objectId: this.program.Id }
      }
    },
    isScheduledForToday: function () {
      return ProgramAnalyzer.isScheduledForToday(this.program)
    },
    programEndDate: function () {
      return ProgramAnalyzer.getEndDate(this.program)
    },
    seekerId: function () {
      return this.$route.params.seekerId
    },
    seeker: function () {
      return this.getSeekerById(this.seekerId)
    }
  },

  watch: {
    value: function (newValue) {
      if (newValue) {
        this.loadProgramExercises()
      }
    }
  },

  methods: {
    ...mapActions('exercises', ['ensureAssignedExercisesLoaded']),
    onCardClick () {
      if (!this.embedded) {
        this.onInput(true)
      }
    },
    onInput (newValue) {
      this.$emit('input', newValue)
    },
    closeDetails () {
      this.onInput(false)
    },
    loadProgramExercises () {
      this.ensureAssignedExercisesLoaded(this.program.Id)
    },
    getRoleItemColor (role) {
      return Styles.getTextColor(Styles.getRoleColor(role))
    }
  },

  created () {
    if (this.value) {
      this.loadProgramExercises()
    }
  }
}
</script>

<style lang="scss">
.embedded {
  .v-card__title, .v-card__subtitle, .v-card__text {
    padding: 0;
    margin-top: 0 !important;
    margin: 0 0 4px 0;
    line-height: normal;
  }
  .v-card__title {
    font-size: 1rem;
    margin-bottom: 8px;
  }
}
</style>
