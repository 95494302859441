import Organizations from '../components/admin/organizations/organizations.vue'
import Organization from '../components/admin/organizations/organization.vue'
import Library from '../components/admin/libraries/library.vue'
import Specialist from '../components/admin/specialists/specialist.vue'
import StatisticsOverview from '../components/admin/statistics/overview.vue'
import ScanList from '../components/admin/statistics/scan-list.vue'
import SharingSeekers from '@/components/admin/seekers/sharing-seekers.vue'
import SeekerEvents from '@/components/admin/seekers/seeker-events.vue'
import BackupSeekerData from '@/components/admin/seekers/backup-seeker-data.vue'
import { Role } from '@/helpers/enums'
import { ParamNames, RouteNames } from './names'
import locationBuilder from './location-builder'

const routes = [
  {
    path: '/admin/organizations',
    name: RouteNames.Admin.ORGANIZATIONS,
    component: Organizations
  },
  {
    path: '/admin/organizations/:code',
    name: RouteNames.Admin.SINGLE_ORGANIZATION,
    component: Organization
  },
  {
    path: '/admin/organizations/:code/:activeTab',
    name: RouteNames.Admin.ORGANIZATION_TAB,
    component: Organization
  },
  {
    path: '/admin/libraries/:id',
    name: RouteNames.Admin.SINGLE_LIBRARY,
    component: Library
  },
  {
    path: '/admin/libraries/:id/:activeTab',
    name: RouteNames.Admin.LIBRARY_TAB,
    component: Library
  },
  {
    path: '/admin/specialists/:uuid',
    name: RouteNames.Admin.SINGLE_SPECIALIST,
    component: Specialist
  },
  {
    path: '/admin/specialists/:uuid/:activeTab',
    name: RouteNames.Admin.SPECIALIST_TAB,
    component: Specialist
  },
  {
    path: '/admin/statistics',
    name: RouteNames.Admin.STATISTICS,
    component: StatisticsOverview
  },
  {
    path: '/admin/statistics/scans',
    name: RouteNames.Admin.SCAN_LIST,
    component: ScanList
  },
  {
    path: '/admin/seekers',
    name: RouteNames.Admin.SEEKERS,
    redirect: locationBuilder.sharingSeekers()
  },
  {
    path: '/admin/seekers/sharing',
    name: RouteNames.Admin.SHARING_SEEKERS,
    component: SharingSeekers
  },
  {
    path: `/admin/seekers/sharing/:${ParamNames.ORGANIZATION_CODE}`,
    name: RouteNames.Admin.SHARING_SEEKERS_OF_ORGANIZATION,
    component: SharingSeekers
  },
  {
    path: '/admin/seekers/events',
    name: RouteNames.Admin.SEEKER_EVENTS,
    component: SeekerEvents
  },
  {
    path: `/admin/seekers/events/:${ParamNames.ORGANIZATION_CODE}`,
    name: RouteNames.Admin.SEEKER_EVENTS_OF_ORGANIZATION,
    component: SeekerEvents
  },
  {
    path: '/admin/seekers/backup',
    name: RouteNames.Admin.BACKUP_SEEKER_DATA,
    component: BackupSeekerData
  },
  {
    path: '/admin/seekers/backup/:organizationCode',
    name: RouteNames.Admin.BACKUP_SEEKER_DATA_FOR_ORGANIZATION,
    component: BackupSeekerData
  }
]

routes.forEach(r => {
  r.meta = r.meta ?? {}
  r.meta.roles = [Role.ADMINISTRATOR]
})

export default routes
