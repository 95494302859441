<template>
  <v-app id="app">
    <div class="main-background"/>
    <v-main>
      <div class="main-container d-flex justify-center align-center">
        <router-view/>
      </div>
    </v-main>
    <v-fade-transition mode="out-in">
      <div v-if="messageToDisplay"
           class="message-container d-flex justify-center align-center pa-4"
           @click="hideMessage">
          <v-alert max-width="80vw"
                  :type="messageToDisplay.kind"
                  :color="messageColor">
            <span class="ws-pre-line">{{messageToDisplay.text}}</span>
          </v-alert>
      </div>
    </v-fade-transition>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Styles from '@/helpers/styles'

export default {
  name: 'CenteredLayout',
  data: () => ({
    hiddenMessageId: null
  }),
  computed: {
    messageToDisplay: function () {
      const message = this.globalMessage.length > 0 ? this.globalMessage[this.globalMessage.length - 1] : null
      return message && message.id !== this.hiddenMessageId ? message : null
    },
    messageColor: function () {
      return this.messageToDisplay == null ? null : Styles.getMessageColor(this.messageToDisplay.kind)
    },
    ...mapState(['globalMessage'])
  },
  methods: {
    hideMessage () {
      if (this.messageToDisplay) {
        this.hiddenMessageId = this.messageToDisplay.id
      }
    }
  },
  watch: {
    globalMessage: function () {
      if (!this.globalMessage.some(m => m.id === this.hiddenMessageId)) {
        this.hiddenMessageId = null
      }
    }
  }
}
</script>

<style>
  html {
    overflow-y: auto !important;
  }
</style>
<style scoped>
  .main-background {
    width: 100%;
    height: 100vh;
    background: url('../../assets/img/loginbg-pc.jpeg') right center  no-repeat;
    background-size: cover;
    position: fixed;
  }
  .main-container {
    width: 100%;
    min-height: 100%;
  }
  .message-container {
    width: 100%;
    height: 100vh;
    position: fixed;
  }
</style>
