<template>
  <v-form>
    <v-text-field :label="$t('admin.specialist.fields.login')" v-model.trim="data.login"
                  :readonly="readonly || fixedLogin" :hide-details="readonly" type="email"
                  @input="$v.data.login.$touch()" @blur="$v.data.login.$touch()"
                  :error-messages="$validationErrors($v.data.login)"/>
    <v-text-field :label="$t('admin.specialist.fields.name')" v-model.trim="data.name"
                  :readonly="readonly" :hide-details="readonly"
                  @input="$v.data.name.$touch()" @blur="$v.data.name.$touch()"
                  :error-messages="$validationErrors($v.data.name)"/>
    <mpro-organization-select :label="$t('admin.specialist.fields.organization')" v-model="data.organization"
                              :organizations="organizations"
                              :readonly="readonly || fixedOrganizationCode" :hide-details="readonly"
                              @input="$v.data.organization.$touch()" @blur="$v.data.organization.$touch()"
                              :error-messages="$validationErrors($v.data.organization)"/>
    <v-text-field :label="$t('admin.specialist.fields.department')" v-model.trim="data.department"
                  :readonly="readonly" :hide-details="readonly"
                  @input="$v.data.department.$touch()" @blur="$v.data.department.$touch()"
                  :error-messages="$validationErrors($v.data.department)"/>
    <v-text-field :label="$t('admin.specialist.fields.title')" v-model.trim="data.title"
                  :readonly="readonly" :hide-details="readonly"
                  @input="$v.data.title.$touch()" @blur="$v.data.title.$touch()"
                  :error-messages="$validationErrors($v.data.title)"/>
    <v-text-field :label="$t('admin.specialist.fields.phone')" v-model.trim="data.phone"
                  :readonly="readonly" :hide-details="readonly" type="phone"
                  @input="$v.data.phone.$touch()" @blur="$v.data.phone.$touch()"
                  :error-messages="$validationErrors($v.data.phone)"/>
    <v-textarea :label="$t('admin.specialist.fields.about')" v-model.trim="data.about"
                :readonly="readonly" :hide-details="readonly"
                @input="$v.data.about.$touch()" @blur="$v.data.about.$touch()"
                :error-messages="$validationErrors($v.data.about)"/>
  </v-form>
</template>

<script>
import OrganizationSelect from '@/components/elements/organization-select'
import { mapState, mapActions } from 'vuex'
import required from 'vuelidate/lib/validators/required'

export default {
  components: {
    'mpro-organization-select': OrganizationSelect
  },

  props: {
    organizationCode: String,
    specialist: {type: Object, default: null},
    readonly: {type: Boolean, default: false}
  },

  data: () => ({
    data: {
      login: '',
      name: '',
      organization: '',
      title: '',
      department: '',
      phone: '',
      about: ''
    }
  }),

  validations: {
    data: {
      login: {required},
      name: {required},
      organization: {},
      title: {},
      department: {},
      phone: {},
      about: {}
    }
  },

  computed: {
    fixedOrganizationCode: function () {
      return this.specialist == null && this.organizationCode != null
    },
    fixedLogin: function () {
      return this.specialist != null
    },
    ...mapState('admin', ['organizations'])
  },

  watch: {
    specialist: function () {
      this.syncSpecialistToData()
    },
    organizationCode: function () {
      this.syncSpecialistToData()
    }
  },

  methods: {
    syncSpecialistToData () {
      if (this.specialist) {
        this.data.login = this.specialist.Login
        this.data.name = this.specialist.DisplayName
        this.data.organization = this.specialist.OrganizationCode
        this.data.title = this.specialist.Title
        this.data.department = this.specialist.Department
        this.data.phone = this.specialist.Phone
        this.data.about = this.specialist.About
      } else {
        for (const key in this.data) {
          this.data[key] = ''
        }
        if (this.organizationCode != null) {
          this.data.organization = this.organizationCode
        }
      }
    },
    validate () {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    async reset () {
      this.syncSpecialistToData()
      await this.$nextTick()
      this.$v.$reset()
    },
    ...mapActions('admin', ['ensureOrganizationsLoaded'])
  },

  created () {
    this.syncSpecialistToData()
    this.ensureOrganizationsLoaded()
  }
}
</script>
