<template>
  <div>
    <v-text-field v-if="loadingImage && readonly"
                  :value="fileName" disabled
                  :label="label" :hint="hint" persistent-hint />
    <v-file-input v-else
                  :value="fileInputValue" @change="onChange"
                  accept=".png, .jpeg, .jpg, .bmp"
                  :disabled="readonly" :label="label"
                  :hint="hint" persistent-hint
                  :truncate-length="100"
                  :rules="validationRules" />
    <v-skeleton-loader v-if="value != null"
                       type="image" :loading="loadingImage">
      <v-img :alt="fileName" :src="imageUrl"
              max-height="20vh" contain class="mt-1 mb-4" />
    </v-skeleton-loader>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, File],
    readonly: { type: Boolean, default: true },
    label: String,
    hint: String
  },

  data: () => ({
    imageUrl: null
  }),

  computed: {
    valueIsString: function () {
      return typeof this.value === 'string'
    },
    valueIsFile: function () {
      return this.value instanceof File
    },
    loadingImage: function () {
      return this.fileName != null && this.imageUrl == null
    },
    fileInputValue: function () {
      return this.valueIsFile ? [this.value] : []
    },
    fileName: function () {
      return this.valueIsString ? this.value
        : this.valueIsFile ? this.value.name
          : null
    },
    validationRules: function () {
      return [v => {
        const file = v instanceof File ? v
          : v instanceof Array && v[0] instanceof File ? v[0]
            : null
        return file?.size > 4096 * 1024
          ? this.$t('forms.validations.maxFileSize', { max: '4 MB' })
          : true
      }]
    }
  },

  watch: {
    value: function () {
      this.imageUrl = this.valueIsFile
        ? URL.createObjectURL(this.value)
        : null
    }
  },

  methods: {
    onChange (newValue) {
      const value = newValue?.length > 0 ? newValue[0]
        : newValue instanceof File ? newValue
          : null
      this.$emit('input', value)
    }
  }
}
</script>
