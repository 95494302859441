import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/components/users/login.vue'
import MyAccount from '@/components/users/account/account.vue'
import SignUp from '@/components/users/signup/signup.vue'
import Dashboard from '@/components/dashboard.vue'
import ScanCompare from '@/components/scans/compare.vue'
import Scans from '@/components/scans/scans.vue'
import Goals from '@/components/goals/goals.vue'
import Exercises from '@/components/exercises/exercises.vue'
import Surveys from '@/components/surveys/surveys.vue'
import ViewSurvey from '@/components/surveys/view-survey.vue'
import TakeSurvey from '@/components/surveys/take-survey.vue'
import ViewClient from '@/components/specialist/view-client.vue'
import AssignedSurveys from '@/components/specialist/assigned-surveys.vue'
import Tutorials from '@/components/tutorials.vue'
import Reflex from '@/components/integrations/reflex.vue'
import Chats from '@/components/chats/chats.vue'

import CenteredLayout from '@/components/layouts/centered.vue'

import {store} from '@/store/store'
import {Role} from '@/helpers/enums'
import SessionMonitor from '@/helpers/session-monitor'
import landingRoutes from './landing'
import adminRoutes from './admin'
import locationBuilder from './location-builder'
import { RouteNames } from './names'

Vue.use(Router)

function getMsal () {
  return Vue.prototype.$msal
}

const routes = [
  {
    path: '/login',
    name: RouteNames.LOGIN,
    component: Login,
    meta: {
      layout: CenteredLayout,
      anonymous: true
    },
    beforeEnter: (to, _, next) => {
      if (getMsal().isAuthenticated()) {
        // Already authenticated => continue to return URL or the dashbord
        const returnUrl = Array.isArray(to.query.return) ? to.query.return[0] : to.query.return
        const location = { replace: true }
        if (returnUrl != null) {
          location.path = returnUrl
        } else {
          location.name = RouteNames.DASHBOARD
        }
        next(location)
      } else {
        next()
      }
    }
  },
  {
    path: '/login/connect-with-organization',
    name: RouteNames.LOGIN_WITH_CONNECT,
    component: Login,
    meta: {
      layout: CenteredLayout,
      anonymous: true
    },
    beforeEnter: (to, _, next) => {
      if (getMsal().isAuthenticated()) {
        // Already authenticated => redirect to the widget landing URL
        next(locationBuilder.connectWithOrganization(to.query.code))
      } else {
        next()
      }
    }
  },
  {
    path: '/auth',
    meta: {
      anonymous: true,
      initApp: false
    },
    beforeEnter: async (_, __, next) => {
      const result = await getMsal().handleRedirect()

      if (result == null) {
        // Authentication cancelled/failed
        next(locationBuilder.withReplace().login())
      } else if (result.adBlocked) {
        // Authentication is likely interferred by an AD blocker
        if (result.locale != null) {
          store.dispatch('setUILocale', result.locale)
        }
        store.commit('setAppInitialized', {
          adBlockerWhiteList: result.whiteList
        })
        next(locationBuilder.withReplace().login())
      } else {
        // Successful authentication
        await SessionMonitor.onAuthenticated(result.userId, result.issuedAt, result.expiresAt)
        await store.dispatch('user/updateLoginTime', {loginTime: result.authTime})
        await store.dispatch('user/ensureUserDataLoaded', {force: true})
        await store.dispatch('user/ensureUserSettingsLoaded')
        if (result.locale != null) {
          // Set locale from sign-in if there is no setting yet
          const activeLocale = store.getters['user/getSettings']('locale')
          if (activeLocale == null) {
            store.dispatch('setUILocale', result.locale)
            await store.dispatch('user/updateUserSetting', {
              setting: 'portal.activeLocale',
              value: result.locale
            })
          }
        }
        store.commit('setAppInitialized', {
          justSignedIn: true
        })

        const nextLocation = {
          path: result.returnPath,
          replace: true
        }
        next(nextLocation)
      }
    }
  },

  // Landing routes have the top priority
  ...landingRoutes,

  {
    path: '/account',
    name: RouteNames.MY_ACCOUNT,
    component: MyAccount
  },
  {
    path: '/account/signup',
    name: RouteNames.SIGN_UP,
    component: SignUp,
    meta: {
      layout: CenteredLayout,
      anonymous: true,
      authenticated: false
    }
  },
  {
    path: '/dashboard',
    name: RouteNames.DASHBOARD,
    component: Dashboard
  },
  {
    path: '/scan/:scanId',
    name: RouteNames.SINGLE_SCAN,
    component: Scans,
    meta: {
      roles: [Role.SEEKER, Role.SPECIALIST]
    }
  },
  {
    path: '/compare/:seekerId',
    name: RouteNames.COMPARE_SCANS,
    component: ScanCompare,
    meta: {
      roles: [Role.SPECIALIST]
    }
  },
  {
    path: '/scans/my',
    name: RouteNames.MY_SCANS,
    component: Scans,
    meta: {
      roles: [Role.SEEKER]
    }
  },
  {
    path: '/scans/:seekerId',
    name: RouteNames.SEEKER_SCANS,
    component: Scans,
    meta: {
      roles: [Role.SPECIALIST]
    }
  },
  {
    path: '/client/:seekerId',
    name: RouteNames.VIEW_SEEKER,
    component: ViewClient,
    meta: {
      roles: [Role.SPECIALIST]
    }
  },
  {
    path: '/goals',
    name: RouteNames.MY_GOALS,
    component: Goals,
    meta: {
      roles: [Role.SEEKER]
    }
  },
  {
    path: '/goals/:seekerId',
    name: RouteNames.SEEKER_GOALS,
    component: Goals,
    meta: {
      roles: [Role.SPECIALIST]
    }
  },
  {
    path: '/exercises',
    name: RouteNames.MY_EXERCISES,
    component: Exercises,
    meta: {
      roles: [Role.SEEKER]
    }
  },
  {
    path: '/exercises/:seekerId',
    name: RouteNames.SEEKER_EXERCISES,
    component: Exercises,
    meta: {
      roles: [Role.SPECIALIST]
    }
  },
  {
    path: '/surveys',
    name: RouteNames.MY_SURVEYS,
    component: Surveys,
    meta: {
      roles: [Role.SEEKER]
    }
  },
  {
    path: '/surveys/assigned',
    name: RouteNames.ASSIGNED_SURVEYS,
    component: AssignedSurveys,
    meta: {
      roles: [Role.SPECIALIST]
    }
  },
  {
    path: '/tutorials',
    name: RouteNames.TUTORIALS,
    component: Tutorials
  },
  {
    path: '/surveys/:seekerId',
    name: RouteNames.SEEKER_SURVEYS,
    component: Surveys,
    meta: {
      roles: [Role.SPECIALIST]
    }
  },
  {
    path: '/surveys/take/:questionnaireCode',
    name: RouteNames.TAKE_SURVEY,
    component: TakeSurvey,
    meta: {
      roles: [Role.SEEKER],
      scrollToTop: true
    }
  },
  {
    path: '/survey/:surveyId',
    name: RouteNames.VIEW_SURVEY,
    component: ViewSurvey,
    meta: {
      roles: [Role.SEEKER, Role.SPECIALIST],
      scrollToTop: true
    }
  },
  {
    path: '/integration/reflex',
    component: Reflex
  },
  {
    path: '/chats',
    name: RouteNames.CHATS,
    component: Chats
  },
  {
    path: '/chats/:userUuid',
    name: RouteNames.SINGLE_CHAT,
    component: Chats
  },

  ...adminRoutes,

  {
    path: '*',
    redirect: locationBuilder.dashboard()
  }
]

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior: function (to, _, savedPosition) {
    if (savedPosition != null) {
      return { ...savedPosition }
    } else if ((to.hash ?? '').trim().length > 0) {
      return { selector: to.hash }
    } else if (to.meta?.scrollToTop === true) {
      return { y: 0 }
    }
  }
})

router.beforeEach(async (to, _, next) => {
  const auth = getMsal().isAuthenticated()

  // Init meta defaults
  const meta = to.meta || {}
  const initApp = meta.initApp ?? true
  const allowAnonymous = meta.anonymous ?? false
  const allowAuthenticated = meta.authenticated ?? true
  const allowedRoles = meta.roles ?? []

  if (initApp && !store.state.appInitialized) {
    if (auth) {
      await store.dispatch('user/ensureUserDataLoaded', {force: true})
      await store.dispatch('user/ensureUserSettingsLoaded')
      const loginTime = store.getters['user/getLastLogin']
      if (loginTime != null) {
        await store.dispatch('user/updateLoginTime', {loginTime})
      }
    }
    // App should be initialized even if anonymous
    store.commit('setAppInitialized')
  }

  if (!auth && !allowAnonymous) {
    // Route doesn't allow anonymous access => redirect to Login
    next(locationBuilder.login(to.fullPath))
    return
  }

  if (auth && !allowAuthenticated) {
    // Route doesn't allow authenticated access => redirect to Dashboard
    next(locationBuilder.dashboard())
    return
  }

  if (allowedRoles.length > 0) {
    // Route is restricted to some role(s)
    // Need to ensure a user has one of allowed roles
    if (!allowedRoles.includes(store.getters['getRole'])) {
      // Current role is not allowed
      // If a user has one of allowed roles => switch to it
      // Otherwise, redirect a user to the dashboard
      const userRoles = store.getters['user/getAllRoles']
      const targetRole = userRoles.find(r => allowedRoles.includes(r))
      if (targetRole != null) {
        await store.dispatch('user/changeRole', targetRole)
      } else {
        next(locationBuilder.dashboard())
        return
      }
    }
  }

  next()
})

export default router
