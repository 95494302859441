<template>
  <div class="d-flex flex-column align-center">
    <v-alert type="success" color="MProGreen">
      <span class="ws-pre-line">{{$t('signup.registration_success', {timeout: countdown})}}</span>
    </v-alert>
    <v-btn color="MProBlue" @click="signIn">
      {{$t('login.login')}}
    </v-btn>
  </div>
</template>

<script>
export default {
  data: () => ({
    interval: null,
    countdown: 10
  }),

  methods: {
    signIn () {
      this.stopInterval()
      this.$msal.signIn(false, this.$i18n.locale)
    },
    onInterval () {
      if (this.countdown === 1) {
        this.signIn()
      }
      this.countdown--
    },
    stopInterval () {
      clearInterval(this.interval)
    }
  },

  mounted () {
    this.interval = setInterval(this.onInterval, 1000)
  },
  beforeDestroy () {
    this.stopInterval()
  }
}
</script>
