



















import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import { MessageKind } from '@/helpers/enums'
import Utils from '@/helpers/utils'

export default Vue.extend({
  data: () => ({
    showDialog: false,
    oldEmailAddress: undefined as string | undefined,
    processing: false
  }),

  computed: {
    ...mapGetters('user', ['getFirstName', 'getUserEmail']),
    token: function (): string | null | undefined {
      return this.$route.name === 'MoveSeekerData' ? this.$route.query['token']?.toString() : undefined
    },
    prompt: function (): TranslateResult {
      return this.$t('account.control-account.change-email-address.confirm-prompt-html', {
        name: this.getFirstName,
        newEmail: this.getUserEmail,
        oldEmail: this.oldEmailAddress
      })
    }
  },

  watch: {
    token: function () {
      this.parseToken()
    }
  },

  methods: {
    ...mapActions(['showGlobalMessage']),
    ...mapActions('seeker', ['getOldEmailAddressFromMoveDataToken', 'moveDataFromAnotherAccount']),
    async parseToken () {
      if (this.token == null) return

      this.oldEmailAddress = await this.getOldEmailAddressFromMoveDataToken(this.token)
      if (this.oldEmailAddress == null) {
        this.showGlobalMessage({
          kind: MessageKind.WARNING,
          text: this.$t('account.control-account.change-email-address.invalid-link')
        })
        this.close()
      } else if (this.oldEmailAddress.toLocaleLowerCase() === this.getUserEmail.toLocaleLowerCase()) {
        this.showGlobalMessage({
          kind: MessageKind.WARNING,
          text: this.$t('account.control-account.change-email-address.sign-in-to-target')
        })
        this.close()
      } else {
        this.showDialog = true
      }
    },
    async proceed () {
      this.processing = true
      try {
        await this.moveDataFromAnotherAccount(this.token)
      } catch (e) {
        const message = this.$errorMessage(e)
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: message
        })
        return
      } finally {
        this.processing = false
      }

      this.showGlobalMessage({
        kind: MessageKind.SUCCESS,
        text: this.$t('account.control-account.change-email-address.moved-data', {
          oldEmail: this.oldEmailAddress
        })
      })
      this.close()

      // Force total refresh
      await Utils.delay(3000)
      window.location.reload()
    },
    close () {
      this.showDialog = false
      this.$router.push({ name: 'MyAccount' })
    }
  },

  mounted () {
    this.parseToken()
  }
})
