export default {
  // Tenant name of AD B2C instance
  tenantName: process.env.VUE_APP_TENANT_NAME,

  // Client ID of AD B2C app registration
  clientId: process.env.VUE_APP_CLIENT_ID,

  // AD B2C policies (user flows)
  policies: {
    signIn: process.env.VUE_APP_POLICY_SIGN_IN,
    signInMfa: process.env.VUE_APP_POLICY_SIGN_IN_MFA
  },

  // All redirects from AD B2C pages go here
  // Need to add this to redirect URI list in AD B2C app settings
  redirectUri: process.env.VUE_APP_BASE_URL + '/auth',

  // Explicit URL to redirect to after sign-out
  // Thus prevent saving previous app URL in 'return' query parameter
  postLogoutRedirectUri: process.env.VUE_APP_BASE_URL + '/login',

  // The scope to access the backend API
  // Simple "client ID" works because it's shared between the app and the backend
  scopes: [process.env.VUE_APP_CLIENT_ID]
}
