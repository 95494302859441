




























































































import Vue from 'vue'
import { mapActions } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import Lo from 'lodash'
import EditDataSharing from './edit-data-sharing.vue'
import DataSharingCheckbox from './data-sharing-checkbox.vue'
import BackupNote from './backup-note.vue'
import ConfirmationDialog from '@/components/elements/confirmation-dialog.vue'
import { ChangeDataSharingResult, DataSharing, DataSharingStatus } from '@/models/data-sharing'
import { MessageKind } from '@/helpers/enums'
import { getDataSharingStatusIcon } from '@/models/ui'
import locationBuilder from '@/router/location-builder'

export default Vue.extend({
  components: {
    'mpro-edit-data-sharing': EditDataSharing,
    'mpro-data-sharing-checkbox': DataSharingCheckbox,
    'mpro-backup-note': BackupNote,
    'mpro-confirmation': ConfirmationDialog
  },

  props: {
    subordinateId: { type: String, default: undefined },
    addOrganization: { type: Boolean, default: false },
    connectWithOrganization: { type: String, default: null }
  },

  data: () => ({
    refreshDataSharings: 0,
    dataSharings: new Array<DataSharing>(0),
    activeItem: undefined as DataSharing | undefined,
    organizationCodeFromWidget: undefined as string | undefined,
    editing: false,
    redirectToDashboardOnUpdate: false,
    revoking: false,
    showBackupNote: false,
    backupNoteRevoked: false,
    backupPeriodDays: 0
  }),

  computed: {
    revokeConfirmationMessage: function (): TranslateResult {
      return this.$t('account.data-sharing.revoke-confirmation-message', {
        organization: this.activeItem?.OrganizationName
      })
    },
    notAcceptedIcon: function () {
      return getDataSharingStatusIcon(DataSharingStatus.New)
    }
  },

  asyncComputed: {
    dataSharings: {
      async get (): Promise<DataSharing[]> {
        const result: DataSharing[] = await this.loadDataSharings({ subordinateId: this.subordinateId })
        return Lo.sortBy(result, ds => ds.OrganizationName.toLocaleLowerCase())
      },
      default: [],
      watch: ['refreshDataSharings']
    }
  },

  watch: {
    addOrganization: function (newValue: boolean) {
      if (newValue) {
        this.onAddOrganization()
        this.raiseStartInteraction()
      }
    },
    connectWithOrganization: function (newValue: string | null) {
      if (newValue != null) {
        this.organizationCodeFromWidget = this.connectWithOrganization
        if (!this.$asyncComputed.dataSharings.updating) {
          this.replaceEditOrganizationWithExistingItem()
          this.editing = true
          this.raiseStartInteraction()
        }
      }
    },
    dataSharings: function () {
      if (this.organizationCodeFromWidget != null) {
        this.replaceEditOrganizationWithExistingItem()
        this.editing = true
        this.raiseStartInteraction()
      }
    },
    editing: function (newValue) {
      if (this.organizationCodeFromWidget != null) {
        if (newValue) {
          // Redirect to the dashboard once connected via the widget
          this.redirectToDashboardOnUpdate = true
        } else {
          this.organizationCodeFromWidget = undefined
        }
      } else {
        this.redirectToDashboardOnUpdate = false
      }
    }
  },

  methods: {
    ...mapActions(['showGlobalMessage']),
    ...mapActions('seeker', ['loadDataSharings', 'revokeDataSharing']),
    raiseStartInteraction () {
      this.$emit('startInteraction')
    },
    onAddOrganization (): void {
      this.activeItem = undefined
      this.editing = true
    },
    onEditItem (item: DataSharing): void {
      this.activeItem = item
      this.editing = true
    },
    replaceEditOrganizationWithExistingItem (): void {
      if (this.organizationCodeFromWidget != null) {
        this.activeItem = this.dataSharings.find(
          ds => ds.OrganizationCode === this.organizationCodeFromWidget)
        if (this.activeItem != null) {
          this.organizationCodeFromWidget = undefined
        }
      }
    },
    onRevokeItem (item: DataSharing): void {
      this.activeItem = item
      this.revoking = true
    },
    async revokeActiveItem (): Promise<void> {
      if (this.activeItem == null) throw new Error()

      let result: ChangeDataSharingResult
      try {
        result = await this.revokeDataSharing({
          subordinateId: this.subordinateId,
          organizationCode: this.activeItem.OrganizationCode
        })
      } catch (e) {
        const message = this.$errorMessage(e)
        this.showGlobalMessage({ kind: MessageKind.ERROR, text: message })
        return
      }

      this.refreshDataSharings++
      this.showGlobalMessage({ kind: MessageKind.SUCCESS, text: this.$t('account.data-sharing.revoked') })
      this.showBackupNoteWhenNeeded(result)
    },
    onDataSharingUpdated (result: ChangeDataSharingResult): void {
      this.refreshDataSharings++
      this.showBackupNoteWhenNeeded(result)

      if (this.redirectToDashboardOnUpdate) {
        this.redirectToDashboardOnUpdate = false
        this.$router.push(locationBuilder.dashboard())
      }
    },
    showBackupNoteWhenNeeded (result: ChangeDataSharingResult) {
      if (result.showBackupNotice) {
        this.backupNoteRevoked = result.wasRevoked === true
        this.backupPeriodDays = result.backupPeriodDays
        this.showBackupNote = true
      }
    },
    itemIsNotAccepted (item: DataSharing): boolean {
      return item.Status === DataSharingStatus.New
    }
  }
})
