<template>
  <div :style="{ display: 'grid', gridTemplateColumns: layout.columns, gridTemplateRows: layout.rows }"
       class="ma-0 pa-0 p-relative"
       :class="$vuetify.breakpoint.mdAndUp ? ['fill-height'] : null">
    <div v-if="!presentMode"
          ref="scanlist"
          @scroll="$root.$emit('scanListScroll')"
          class="d-flex flex-column overflow-scroll"
          :class="isSpecialist ? 'MPro-bg' : 'MPro-bg-second'"
          :style="{ gridColumn: layout.listColumn, gridRow: layout.listRow }">
      <h2 v-if="isSeeker" class="mt-3 mx-5">{{ seekerTitle }}</h2>
      <mpro-scan-list/>
      <mpro-movement-selector v-if="layout.movementInList && canSelectMovement"
                              class="px-5 pt-0 align-end"
                              v-model="selectedMovement"
                              :items="movementItems"
                              :progress="progress"/>
    </div>
    <div v-if="scanId != null"
          ref="scaninformation"
          class="MPro-bg-gradient d-flex flex-column"
          :style="{ gridColumn: layout.infoColumn, gridRow: layout.infoRow }">
      <div ref="scandetails"
           class="flex-grow-1"
           style="overflow-y: auto">
        <div v-if="showScanStatusAlert"
             class="pa-5 pb-0 d-flex flex-column"
             style="max-height: 90%">
          <v-alert dense text :type="scanStatusAlertType">
            <p class="pb-1">{{ $t(`scans.status.title-${scanStatus}`) }}</p>
            <span>{{ scanStatusMessage }}</span>
          </v-alert>
        </div>
        <mpro-scan-details class="mb-auto" v-if="showScanDetails"
                           :presentMode="presentMode"/>
      </div>
      <v-spacer/>
      <mpro-movement-selector v-if="!layout.movementInList && canSelectMovement"
                              id="movement-select"
                              class="px-5 py-0 align-end"
                              v-model="selectedMovement"
                              :items="movementItems"
                              :progress="progress"/>
    </div>
    <div v-if="selectedMovement != null"
          :id="viewerElementId"
          class="MPro-bg"
          :style="{ gridColumn: layout.viewerColumn, gridRow: layout.viewerRow }">
      <mpro-scan-viewer v-if="isDepthScan"
                        :scanId="scanId"
                        :scanData="scanData"
                        :movement="selectedMovement"
                        :reprojectionTable="reprojectionTable"
                        :depthMap="movementDepthMap"
                        :trackingPoints="movementTrackingPoints"
                        :measures="movementMeasures"
                        @toggle-narrative="toggleNarrative"
                        class="pa-5 pt-0 fill-height">
      </mpro-scan-viewer>
      <mpro-movement-viewer v-else-if="isMobileScan && scanMovements2D != null"
                            class="fill-height"
                            :movement="selectedMovement"
                            @toggle-narrative="toggleNarrative"/>
      <mpro-recording-viewer v-else-if="isMobileScan && scanRecordings2D != null"
                             class="fill-height"
                             :recording="selectedMovement"/>
    </div>
    <mpro-scan-narrative v-if="scanNarrative && !isMobileScan"
                         @close-narrative="toggleNarrative"
                         :movement-kind="selectedMovement"
                         :body-height="scanData.person.evaluated_height"
                         :style="{ zIndex: 1, gridColumn: layout.narrativeColumn, gridRow: layout.narrativeRow }"/>
    <mpro-scan-narrative-2d v-if="scanNarrative && isMobileScan"
                            @close-narrative="toggleNarrative"
                            :scan-id="scanId"
                            :movement-id="selectedMovement && selectedMovement.Id"
                            :movement-kind="selectedMovement && selectedMovement.Kind"
                            :body-height-cm="scan && scan.HeightCm"
                            :style="{ zIndex: 1, gridColumn: layout.narrativeColumn, gridRow: layout.narrativeRow }"/>
    <mpro-beta-indicator v-if="isMobileScan"
                         class="p-absolute"
                         style="top: 0; right: 0"
                         auto-popup-setting-name="portal.beta-scans2d">
      <div v-html="$t('scans2d.beta-note-html', { name: getFirstName || $t('general.default-appeal') })"/>
    </mpro-beta-indicator>
    <mpro-header-handler tabName="scans" :seeker="seeker"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import ScanList from './scan-list'
import ScanDetails from './scan-details'
import MovementViewer from '../scans2d/movement-viewer'
import RecordingViewer from '../scans2d/recording-viewer'
import ScanViewer from './scan-viewer'
import HeaderHandler from '../specialist/header-handler'
import BetaIndicator from '../elements/beta-indicator'
import { ScanType } from '../../helpers/enums'
import ElementIds from './element-ids'
import ScanNarrative from './scan-narrative'
import ScanNarrative2D from '../scans2d/scan-narrative-2d.vue'
import MovementSelector from './movement-selector.vue'
import { augmentAssessments } from '@/logic/assessments'

const MovementKinds2D = {
  'StandStillFaceView': 1,
  'SideBend': 2,
  'Squat': 3,
  'SquatRight': 4,
  'SquatLeft': 5
}

const ScanStatus = {
  NOT_EXIST: 'NotExist',
  UPLOADING: 'Uploading',
  CALCULATING: 'Calculating',
  BAD_DATA: 'BadData',
  READY: 'Ready'
}

export default {
  components: {
    'mpro-scan-list': ScanList,
    'mpro-scan-details': ScanDetails,
    'mpro-scan-viewer': ScanViewer,
    'mpro-movement-viewer': MovementViewer,
    'mpro-recording-viewer': RecordingViewer,
    'mpro-header-handler': HeaderHandler,
    'mpro-beta-indicator': BetaIndicator,
    'mpro-scan-narrative': ScanNarrative,
    'mpro-scan-narrative-2d': ScanNarrative2D,
    'mpro-movement-selector': MovementSelector
  },
  data: () => {
    return {
      reprojectionTable: null,
      movementDepthMap: null,
      movementMeasures: null,
      movementTrackingPoints: null,
      selectedMovement: null,
      progress: null,
      windowHeight: window.innerHeight,
      show: true,
      resolution: '',
      presentMode: false,
      scanStatus: null,
      scanNarrative: false
    }
  },
  computed: {
    ...mapState({
      scansSpecialist: state => state.specialist.scans,
      scansSeeker: state => state.seeker.scans
    }),
    ...mapGetters(['isSpecialist', 'isSeeker', 'getRole']),
    ...mapGetters('specialist', ['getSeekerById']),
    ...mapGetters('user', ['getFirstName']),
    ...mapGetters('seeker', ['activeSubordinate']),
    height: function () {
      if (this.presentMode) {
        return this.windowHeight
      } else {
        const header = document.getElementsByTagName('header').length > 0
          ? document.getElementsByTagName('header')[0].clientHeight
          : 96
        const footer = document.getElementsByTagName('footer').length > 0
          ? document.getElementsByTagName('footer')[0].clientHeight
          : 48
        return this.windowHeight - header - footer
      }
    },
    layout: function () {
      const result = {
        columns: '',
        rows: '',
        listColumn: '',
        listRow: '',
        infoColumn: '',
        infoRow: '',
        viewerColumn: '',
        viewerRow: '',
        narrativeColumn: '',
        narrativeRow: '',
        movementInList: false
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        result.columns = this.presentMode ? '1fr 3fr' : '1fr 1fr 2fr'
        result.rows = `${this.height}px`
        result.listRow = result.infoRow = result.viewerRow = result.narrativeRow = '1 / 2'
        result.listColumn = '1 / 2'
        result.infoColumn = this.presentMode ? '1 / 2' : '2 / 3'
        result.viewerColumn = this.presentMode ? '2 / 3' : '3 / 4'
        result.narrativeColumn = this.presentMode ? '1 / 2' : '1 / 3'
      } else if (this.$vuetify.breakpoint.md) {
        result.columns = '1fr 1fr'
        result.rows = `repeat(2, ${this.height}px)`
        result.listRow = result.infoRow = '1 / 2'
        result.listColumn = '1 / 2'
        result.infoColumn = this.presentMode ? '1 / 3' : '2 / 3'
        result.viewerRow = result.narrativeRow = '2 / 3'
        result.viewerColumn = result.narrativeColumn = '1 / 3'
      } else {
        result.columns = '1fr'
        result.rows = this.presentMode ? `repeat(2, ${this.height}px)` : `auto repeat(2, ${this.height}px)`
        result.listColumn = result.infoColumn = result.viewerColumn = result.narrativeColumn = '1 / 2'
        result.listRow = '1 / 2'
        result.viewerRow = result.narrativeRow = this.presentMode ? '1 / 2' : '2 / 3'
        result.infoRow = this.presentMode ? '2 / 3' : '3 / 4'
        result.movementInList = true
      }
      return result
    },
    scanListElement: function () {
      return this.$refs.scanlist
    },
    scanId: function () {
      return this.$route.name === 'Scan' ? this.$route.params.scanId : null
    },
    scanType: function () {
      return this.scan?.ScanType
    },
    isDepthScan: function () {
      return this.scanType === ScanType.DEPTH
    },
    isMobileScan: function () {
      return this.scanType === ScanType.MOBILE
    },
    showScanStatusAlert: function () {
      return this.scanStatus !== ScanStatus.READY && this.scanStatus != null
    },
    scanStatusAlertType: function () {
      return this.scanStatus === ScanStatus.NOT_EXIST || this.scanStatus === ScanStatus.BAD_DATA
        ? 'error'
        : 'info'
    },
    scanStatusMessage: function () {
      switch (this.scanStatus) {
        case ScanStatus.BAD_DATA: return this.$t(`scans.status.message-${this.scanStatus}-${this.getRole}`)
        case ScanStatus.NOT_EXIST:
        case ScanStatus.UPLOADING:
        case ScanStatus.CALCULATING: return this.$t(`scans.status.message-${this.scanStatus}`)
        default: return ''
      }
    },
    showScanDetails: function () {
      return this.scanStatus === ScanStatus.READY
    },
    seeker: function () {
      return this.getSeekerById(this.seekerId)
    },
    seekerTitle: function () {
      return this.activeSubordinate == null
        ? this.$t('menu.scans')
        : this.$t('scans.title-subordinate', { name: this.activeSubordinate.FirstName })
    },
    canSelectMovement: function () {
      return this.scanStatus === ScanStatus.READY ||
        (this.isMobileScan && this.scanStatus === ScanStatus.BAD_DATA)
    },
    movementItems: function () {
      if (this.isDepthScan && this.scanData != null) {
        return Object.keys(this.scanData.movements).map(key => ({
          value: key,
          text: this.movementTextString(key)
        }))
      } else if (this.isMobileScan && this.scanMovements2D != null) {
        const result = this.scanMovements2D.slice()
        result.sort((a, b) =>
          a.Kind !== b.Kind
            ? MovementKinds2D[a.Kind] - MovementKinds2D[b.Kind]
            : a.Repetition - b.Repetition)
        return result.map(m => ({
          value: m,
          text: `${this.$t('scans2d.movements.' + m.Kind)}: ${m.Repetition}`
        }))
      } else if (this.isMobileScan && this.scanRecordings2D != null) {
        const result = this.scanRecordings2D.slice()
        result.sort((a, b) => MovementKinds2D[a.Kind] - MovementKinds2D[b.Kind])
        return result.map(r => ({
          value: r,
          text: `${this.$t('scans2d.movements.' + r.Kind)}: ${this.$t('scans2d.repetitionCount', { count: r.RepetitionCount })}`
        }))
      } else {
        return []
      }
    },
    activeTab: {
      get: function () {
        return this.$store.state.activeTab
      },
      set: function (value) {
        this.$store.commit('setActiveTab', value)
      }
    },
    seekerId: function () {
      if (this.$route.name === 'clientScans') return this.$route.params.seekerId
      let scan = this.scansSpecialist.find(s => s.Id === this.scanId)
      return scan == null ? null : scan.SeekerId
    },
    scan: function () {
      if (this.scanId == null) return undefined

      if (this.isSeeker) {
        return this.scansSeeker.find(s => s.Id === this.scanId)
      } else if (this.isSpecialist) {
        return this.scansSpecialist.find(s => s.Id === this.scanId)
      } else {
        return undefined
      }
    },
    viewerElementId: function () {
      return ElementIds.getViewerId(this.scanId, false, false)
    }
  },

  asyncComputed: {
    scanData: function () {
      if (this.scanId != null && this.isDepthScan) {
        return this.getOrLoadScanData({
          scanId: this.scanId,
          resolution: this.resolution
        })
      } else {
        return null
      }
    },
    scanMovements2D: function () {
      if (this.isMobileScan && this.scanStatus === ScanStatus.READY) {
        return this.loadScanMovements(this.scanId)
      } else {
        return null
      }
    },
    scanRecordings2D: function () {
      if (this.isMobileScan && this.scanStatus === ScanStatus.BAD_DATA) {
        return this.loadScanRecordings(this.scanId)
      } else {
        return null
      }
    }
  },

  methods: {
    ...mapActions('specialist', ['ensureSpecialistSeekersLoaded', 'ensureSpecialistScansLoaded']),
    ...mapMutations('specialist', ['markScanViewed']),
    ...mapActions('surveys', ['getOrLoadMetadata']),
    ...mapActions('scans', [
      'getOrLoadScanData', 'loadScanStatus',
      'getOrLoadReprojectionTable', 'getOrLoadMovementDepthMap',
      'apiPostComment', 'getOrLoadMovementTrackingPoints', 'getOrLoadMovementMeasures']),
    ...mapActions('scans2d', ['loadScanMovements', 'loadScanRecordings']),
    ...mapActions({'loadScanStatus2D': 'scans2d/loadScanStatus'}),
    ...mapActions('seeker', ['ensureScansLoaded']),
    ...mapActions(['setHeaderTitle']),
    movementTextString: function (key) {
      if (this.scanData == null) return ''

      let movementData = this.scanData.movements[key]
      let str = `${this.$t('scans.movements.' + key)}`
      if (movementData.repeat_count > 1 && movementData.mode !== 'Normal') {
        str += ` (${this.$t('general.count.' + movementData.repeat_count)}, ${this.$t('scans.modes.' + movementData.mode)})`
      } else if (movementData.repeat_count > 1) {
        str += ` (${this.$t('general.count.' + movementData.repeat_count)})`
      } else if (movementData.mode !== 'Normal') {
        str += ` (${movementData.mode})`
      }
      return str
    },
    async checkStatus () {
      if (this.isDepthScan) this.scanStatus = await this.loadScanStatus(this.scanId)
      if (this.isMobileScan) this.scanStatus = await this.loadScanStatus2D(this.scanId)
      this.$nextTick(() => {
        this.onResize()
      })
    },
    async loadReprojectionTable () {
      if (this.scanId) {
        this.task = 'scans.single.downloading_reprojection'
        this.progress = 0
        this.reprojectionTable = await this.getOrLoadReprojectionTable({
          scanId: this.scanId,
          progressCallback: p => { this.progress = p },
          resolution: this.resolution
        })
        this.progress = null
      }
    },
    onResize () {
      this.windowHeight = window.innerHeight
    },
    loadMovementData () {
      if (this.scanId && this.selectedMovement) {
        this.task = 'scans.single.downloading_movement'
        this.progress = 0

        let p1 = 0
        let p2 = 0
        let p3 = 0

        const updateProgress = () => {
          // Weighted average in accordance with common size ratio
          // Negative values mean "indeterminate"
          this.progress = p1 < 0 || p2 < 0 || p3 < 0
            ? -1
            : (50 * p1 + p2 + 0.3 * p3) / 51.3
        }

        const promise1 = this.getOrLoadMovementDepthMap({
          scanId: this.scanId,
          movement: this.selectedMovement,
          progressCallback: p => { p1 = p; updateProgress() },
          resolution: this.resolution
        }).then(result => {
          this.movementDepthMap = result
        })

        const promise2 = this.getOrLoadMovementTrackingPoints({
          scanId: this.scanId,
          movement: this.selectedMovement,
          progressCallback: p => { p2 = p; updateProgress() },
          resolution: this.resolution
        }).then(result => {
          this.movementTrackingPoints = result
        })

        const promise3 = this.getOrLoadMovementMeasures({
          scanId: this.scanId,
          movement: this.selectedMovement,
          progressCallback: p => { p3 = p; updateProgress() },
          resolution: this.resolution
        }).then(result => {
          augmentAssessments(result.Assessments)
          this.movementMeasures = result
        })

        Promise.all([promise1, promise2, promise3]).then(() => {
          this.progress = null
        })
      }
    },
    getItemLink (item) {
      return item.CapturedAt
        ? {name: 'Scan', params: {scanId: item.Id}}
        : item.AnsweredAt
          ? {name: 'ViewSurvey', params: {surveyId: item.Id}}
          : null
    },
    toggleNarrative () {
      this.scanNarrative = !this.scanNarrative
    }
  },
  watch: {
    selectedMovement () {
      this.scanNarrative = false
      if (this.isDepthScan) {
        this.loadReprojectionTable()
        this.loadMovementData()
      }
      if (this.isSpecialist && this.selectedMovement != null) {
        this.markScanViewed(this.scanId)
      }
    },
    scanId () {
      this.checkStatus()
      this.selectedMovement = null
      this.$nextTick(() => {
        this.onResize()
      })
    },
    scanListElement () {
      this.onResize()
    },
    seekerTitle (newValue) {
      if (this.isSeeker) {
        this.setHeaderTitle(newValue)
      }
    }
  },
  async created () {
    if (this.isSeeker) {
      await this.ensureScansLoaded()
    }
    if (this.isSpecialist) {
      await this.ensureSpecialistSeekersLoaded()
      await this.ensureSpecialistScansLoaded()
    }
    await this.checkStatus()
  },
  mounted () {
    if (this.isSeeker) {
      this.setHeaderTitle(this.seekerTitle)
    }
    window.addEventListener('resize', this.onResize)
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.resolution = '_Low'
    }
    this.$nextTick(() => {
      this.onResize()
    })
    this.$root.$on('resizeElements', (presentMode) => {
      this.presentMode = presentMode
      this.$nextTick(() => {
        this.onResize()
      })
    })
  }
}
</script>

<style scoped>
.overflow-scroll{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
}

.bottom{
  bottom: 0
}
</style>
