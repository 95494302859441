const ADMIN_PREFIX = 'admin'
const WIDGET_PREFIX = 'widget'

export const RouteNames = {
  DASHBOARD: 'Dashboard',
  LOGIN: 'Login',
  LOGIN_WITH_CONNECT: 'LoginWithConnect',
  MY_ACCOUNT: 'MyAccount',
  SIGN_UP: 'SignUp',

  SINGLE_SCAN: 'Scan',
  COMPARE_SCANS: 'ScanCompare',
  MY_SCANS: 'MyScans',
  SEEKER_SCANS: 'clientScans',

  MY_GOALS: 'MyGoals',
  SEEKER_GOALS: 'clientGoals',

  MY_EXERCISES: 'MyExercises',
  SEEKER_EXERCISES: 'clientExercises',

  MY_SURVEYS: 'MySurveys',
  TAKE_SURVEY: 'TakeSurvey',
  VIEW_SURVEY: 'ViewSurvey',
  ASSIGNED_SURVEYS: 'AssignedSurveys',
  SEEKER_SURVEYS: 'clientSurveys',

  VIEW_SEEKER: 'ViewSeeker',

  CHATS: 'Chats',
  SINGLE_CHAT: 'Chat',

  TUTORIALS: 'Tutorials',

  Admin: {
    ORGANIZATIONS: `${ADMIN_PREFIX}:Organizations`,
    SINGLE_ORGANIZATION: `${ADMIN_PREFIX}:Organization`,
    ORGANIZATION_TAB: `${ADMIN_PREFIX}:OrganizationTab`,
    SINGLE_LIBRARY: `${ADMIN_PREFIX}:Library`,
    LIBRARY_TAB: `${ADMIN_PREFIX}:LibraryTab`,
    SINGLE_SPECIALIST: `${ADMIN_PREFIX}:Specialist`,
    SPECIALIST_TAB: `${ADMIN_PREFIX}:SpecialistTab`,

    STATISTICS: `${ADMIN_PREFIX}:Statistics`,
    SCAN_LIST: `${ADMIN_PREFIX}:ScanList`,

    SEEKERS: `${ADMIN_PREFIX}:Seekers`,
    SHARING_SEEKERS: `${ADMIN_PREFIX}:SharingSeekers`,
    SHARING_SEEKERS_OF_ORGANIZATION: `${ADMIN_PREFIX}:SharingSeekersOfOrganization`,
    SEEKER_EVENTS: `${ADMIN_PREFIX}:SeekerEvents`,
    SEEKER_EVENTS_OF_ORGANIZATION: `${ADMIN_PREFIX}:SeekerEventsOfOrganization`,
    BACKUP_SEEKER_DATA: `${ADMIN_PREFIX}:BackupSeekerData`,
    BACKUP_SEEKER_DATA_FOR_ORGANIZATION: `${ADMIN_PREFIX}:BackupSeekerDataForOrganization`
  },

  MOVE_SEEKER_DATA: 'MoveSeekerData',

  Widget: {
    CONNECT_WITH_ORGANIZATION: `${WIDGET_PREFIX}:ConnectWithOrganization`
  }
}

export const ParamNames = {
  CODE: 'code',
  RETURN: 'return',
  ORGANIZATION_CODE: 'organizationCode',
  ACTION: 'action'
}
