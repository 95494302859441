/** Transforms point coordinates to account for bank and elevation angles of a camera.
 * @param point A point object having X, Y, Z properties.
 * @returns A transformed point object.
*/
export function adjustForBankElevation (point, bank, elevation) {
  const ty = point.Y * Math.cos(-elevation) - point.Z * Math.sin(-elevation)
  const z = point.Y * Math.sin(-elevation) + point.Z * Math.cos(-elevation)
  const x = point.X * Math.cos(bank) - ty * Math.sin(bank)
  const y = point.X * Math.sin(bank) + ty * Math.cos(bank)
  return { X: x, Y: y, Z: z }
}
