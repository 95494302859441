function parseRawTrackingCsvLine (line) {
  const cells = line.split(',')
  const result = { frame: Number.NaN, timeStamp: Number.NaN, score: 0, points: [] }
  if (cells.length >= 2) {
    result.frame = Number(cells[0])
    result.timeStamp = Number(cells[1]) * 1e-3 // from milliseconds to seconds
    if (cells.length >= 3) {
      result.score = Number(cells[2])
      let j = 1
      while (cells.length >= (j + 1) * 3) {
        result.points.push({
          x: Number(cells[j * 3]),
          y: Number(cells[j * 3 + 1]),
          score: Number(cells[j * 3 + 2])
        })
        j++
      }
    }
  }
  return result
}

export function parseRawTrackingCsv (data) {
  return data.split('\n')
    .map(parseRawTrackingCsvLine)
    .filter(item => !Number.isNaN(item.frame))
}
