import axios from 'axios'
import Cache from 'ttl-cache'
import { handleAxiosError } from '@/helpers/errors'

const cache = new Cache({
  ttl: process.env.VUE_APP_DEFAULT_API_RESPONSE_LIFETIME / 1000,
  interval: 0
})

const CacheKeys = {
  programs: (seekerId) => `programs:${seekerId}`
}

// initial state
const state = {
  assignedExercises: [],
  allExercises: [],
  exercisesLoadedAt: null,
  allExercisesLoadedAt: null,
  programs: []
}

// getters
const getters = {
  getSingleAssignedExercise: state => (programId, exerciseId) => {
    let programExercises = state.assignedExercises.filter(s => s.ProgramId === programId)
    return programExercises.find(s => s.ExerciseId === exerciseId)
  },
  getSingleExerciseMeta: state => exerciseId => {
    return state.allExercises.find(s => s.Id === exerciseId)
  },
  getAllExercises: state => {
    return state.allExercises
  },
  getAssignedPrograms: state => seekerId => {
    return state.programs.filter(p => p.SeekerId === seekerId)
  }
}

const RequestUrls = {
  EXERCISES: '/exercises',
  exerciseThumbnails: (exerciseId) => `/exercises/${encodeURIComponent(exerciseId)}/thumbnails`,
  EXERCISE_PROGRAMS: '/exercises/programs',
  programExercises: (programId) => `/exercises/programs/${encodeURIComponent(programId)}/exercises`
}

// actions
const actions = {
  // Load exercise data, such as
  ensureAllExercisesLoaded: async ({ state, commit, dispatch }) => {
    if (state.allExercisesLoadedAt && (Date.now() - state.allExercisesLoadedAt < process.env.VUE_APP_SCAN_LIFETIME)) {
      return
    }

    let response = null
    try {
      response = await axios.get(RequestUrls.EXERCISES)
    } catch (e) {
      console.log('ensureAllExercisesLoaded:axios', e)
      return
    }

    if (Array.isArray(response.data)) {
      response.data.forEach(e => {
        e.LabelsJson = JSON.parse(e.LabelsJson)
        e.LabelsJson.BodySites = e.LabelsJson.BodySites.map(bodySite => bodySite.replaceAll(' ', '-').toLowerCase())
        e.LabelsJson.Directions = e.LabelsJson.Directions.map(direction => direction.replaceAll(' ', '-').toLowerCase())
        e.LabelsJson.ScanMovementTypes = e.LabelsJson.ScanMovementTypes.map(movementType => movementType.replaceAll(' ', '-').toLowerCase())
      })
      commit('storeAllExercises', response.data)
      response.data.forEach(e => {
        dispatch('ensureThumbnailsLoaded', e.Id)
      })
    }
  },
  ensureThumbnailsLoaded: async ({ commit }, exerciseId) => {
    const url = RequestUrls.exerciseThumbnails(exerciseId)
    let response
    try {
      response = await axios.get(url)
    } catch (e) {
      console.log('ensureThumbnailsLoaded:axios', e)
      return
    }
    commit('storeThumbnails', { thumbnails: response.data, exerciseId })
  },
  ensureSeekerProgramsLoaded: async ({ commit }, payload) => {
    const { seekerId, force } = payload
    if (cache.get(CacheKeys.programs(seekerId)) && !force) {
      return
    }
    let response = null
    try {
      response = await axios.get(RequestUrls.EXERCISE_PROGRAMS, {
        params: {
          'seeker-id': seekerId
        }
      })
    } catch (e) {
      console.log('ensureSeekerProgramsLoaded:axios', e)
      return
    }

    if (Array.isArray(response.data)) {
      commit('storeExercisePrograms', { exercisePrograms: response.data, seekerId })
    }
  },
  apiAssignProgram: async (_, program) => {
    try {
      return await axios.post(RequestUrls.EXERCISE_PROGRAMS, program)
    } catch (e) {
      handleAxiosError(e)
    }
  },
  ensureAssignedExercisesLoaded: async ({ commit }, programId) => {
    const url = RequestUrls.programExercises(programId)
    let response = null
    try {
      response = await axios.get(url)
    } catch (e) {
      console.log('ensureAssignedExercisesLoaded:axios', e)
      return
    }

    if (Array.isArray(response.data)) {
      commit('storeAssignedExercises', response.data)
    }
  }
}

// mutations
const mutations = {
  storeAssignedExercises: (state, exercises) => {
    exercises.forEach((exercise) => {
      state.assignedExercises.push(exercise)
    })
  },
  storeAllExercises (state, exercises) {
    state.allExercises = exercises
    state.allExercisesLoadedAt = Date.now()
  },
  storeThumbnails (state, {thumbnails, exerciseId}) {
    let exercise = state.allExercises.find(e => e.Id === exerciseId)
    exercise.thumbnails = thumbnails
  },
  storeExercisePrograms: (state, { exercisePrograms, seekerId }) => {
    let index
    while ((index = state.programs.findIndex(p => p.SeekerId === seekerId)) >= 0) {
      state.programs.splice(index, 1)
    }
    state.programs.push(...exercisePrograms)
    cache.set(CacheKeys.programs(seekerId), true)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
