<template>
  <v-form @submit.prevent>
    <p>{{$t('signup.credentials_instruction')}}</p>
    <v-text-field :label="$t('signup.email')"
                  v-model.trim="email" :error-messages="$validationErrors($v.email)"
                  @input="$v.email.$touch()" @blur="$v.email.$touch()"
                  type="email" required
                  :readonly="processing"/>
    <v-text-field :label="$t('signup.password')"
                  v-model="password" :error-messages="$validationErrors($v.password, passwordValidationStrings)"
                  @input="$v.password.$touch()" @blur="onPasswordBlur"
                  :type="passwordInputType" required
                  :hint="$t('signup.password_hint')"
                  :readonly="processing"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="showPassword = !showPassword"/>
    <v-text-field v-if="organizationName != null"
                  :label="$t('signup.organization')"
                  :value="organizationName" readonly />
    <v-checkbox
      v-model="privacy"
      name="privacyConsent"
      @change="$v.privacyV.$touch()"
      :error-messages="$validationErrors($v.privacyV)">
      <template v-slot:label>
        <i18n path="signup.privacy-notice" tag="label" for="privacyConsent">
          <a :href="privacyLink"
            target="_blank"
            @click.stop>
            {{ $t('signup.privacy-policy') }}
          </a>
        </i18n>
      </template>
    </v-checkbox>
  </v-form>
</template>

<script>
import {required, email, minLength, maxLength, helpers} from 'vuelidate/lib/validators'

// About password restrictions in Azure AD
// https://docs.microsoft.com/en-us/azure/active-directory/authentication/concept-sspr-policy#password-policies-that-apply-only-to-cloud-user-accounts
function allowedPasswordCharacters (value) {
  const allowedRegex = /^([A-Za-z0-9@#$%^&*\-_!+=[\]{}|\\:',.?/`~"(); ])+$/
  return !helpers.req(value) || allowedRegex.test(value)
}

function passwordCharacterGroups (value) {
  if (!helpers.req(value)) return true

  const upperRegex = /[A-Z]/
  const lowerRegex = /[a-z]/
  const digitRegex = /[0-9]/
  const symbolRegex = /[@#$%^&*\-_!+=[\]{}|\\:',.?/`~"();]/
  let groupCount = 0
  if (upperRegex.test(value)) groupCount++
  if (lowerRegex.test(value)) groupCount++
  if (digitRegex.test(value)) groupCount++
  if (symbolRegex.test(value)) groupCount++

  return groupCount >= 3
}
function pConsent () {
  return this.privacy
}

export default {
  props: {
    processing: {type: Boolean, default: () => false},
    organizationName: String
  },

  data: () => ({
    email: '',
    password: '',
    privacy: false,
    showPassword: false,
    privacyLink: 'https://www.qinematic.com/privacy-policy'
  }),

  validations: {
    email: {required, email},
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(256),
      allowedPasswordCharacters,
      passwordCharacterGroups
    },
    privacyV: {
      pConsent
    }
  },

  computed: {
    passwordInputType: function () {
      return this.password && !this.showPassword ? 'password' : 'text'
    },
    passwordValidationStrings: function () {
      return {
        allowedPasswordCharacters: this.$t('signup.validations.allowedPasswordCharacters'),
        passwordCharacterGroups: this.$t('signup.validations.passwordCharacterGroups')
      }
    }
  },

  methods: {
    onPasswordBlur () {
      this.showPassword = false
      this.$v.password.$touch()
    },
    getValidated () {
      this.$v.$touch()
      return this.$v.$invalid
        ? null
        : {email: this.email, password: this.password, privacy: this.privacy}
    }
  }
}
</script>
