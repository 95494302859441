<template>
  <v-card color="rgb(255,255,255,0.8)" width="600" class="ma-0 ma-sm-4">
    <v-card-title class="d-block text-center">
      <mpro-icon-base icon-name="LogoIcon" :vb-width="1628" :vb-height="160"
                      height="1.5em" width="100%" class="color my-2">
        <mpro-logo/>
      </mpro-icon-base>
    </v-card-title>
    <v-card-subtitle class="text-center">
      <span style="font-size: larger">{{ $t('signup.title') }}</span>
    </v-card-subtitle>
    <v-card-text>
      <v-stepper v-if="!isSuccessStep" v-model="step"
                 class="mb-4">
        <v-stepper-header>
          <template v-for="s in stepCount">
            <v-divider v-if="s > 1" :key="'d' + s"/>
            <v-stepper-step :key="s" :step="s" :complete="step > s"
                            color="MProBlue"/>
          </template>
        </v-stepper-header>
      </v-stepper>
      <mpro-signup-credentials ref="credentials" v-show="isCredentialsStep"
                               :processing="processing"
                               :organizationName="organizationName"/>
      <mpro-signup-seeker ref="seeker" v-show="isSeekerStep"
                          :processing="processing"/>
      <mpro-signup-verify ref="verify" v-if="isVerifyStep"
                          :processing="processing" :sendingCode="sendingNewCode"
                          :attempts-left="attemptsLeft"
                          :show-attempts="attemptsLeft < maxAttempts"
                          @send-new-code="onVerifySendNewCode"/>
      <mpro-signup-success v-if="isSuccessStep"/>

      <div v-if="!isSuccessStep" class="d-flex justify-space-between">
        <v-btn @click="onBackClick"
              :disabled="processing">
          {{$t(isInitialStep ? 'forms.cancel' : 'forms.back')}}
        </v-btn>
        <v-btn color="MProBlue" @click="onContinueClick"
              :disabled="processing || isFinalStep && attemptsLeft <= 0" :loading="processing">
          {{$t(isFinalStep ? 'forms.finish' : 'forms.continue')}}
        </v-btn>
      </div>
    </v-card-text>

    <mpro-confirmation-dialog v-model="showConnectNote"
                              :title="$t('signup.connect-note.title')"
                              :message="connectNoteText"
                              :confirm-text="$t('forms.ok')"
                              cancel-class="d-none" />
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import SignupCredentials from './credentials'
import SignupSeeker from './seeker'
import SignupVerify from './verify'
import SignupSuccess from './success'
import ConfirmationDialog from '@/components/elements/confirmation-dialog.vue'
import IconBase from '@/components/icons/IconBase.vue'
import Logo from '@/components/icons/Logo.vue'
import { MessageKind } from '@/helpers/enums'
import Utils from '@/helpers/utils'
import { AppErrorKey } from '../../../helpers/errors'

export default {
  components: {
    'mpro-signup-credentials': SignupCredentials,
    'mpro-signup-seeker': SignupSeeker,
    'mpro-signup-verify': SignupVerify,
    'mpro-signup-success': SignupSuccess,
    'mpro-confirmation-dialog': ConfirmationDialog,
    'mpro-icon-base': IconBase,
    'mpro-logo': Logo
  },

  data: () => ({
    step: 1,
    credentials: null,
    verificationId: null,
    maxAttempts: 0,
    attemptsLeft: 0,
    userData: null,
    processing: false,
    sendingNewCode: false,
    showConnectNote: false
  }),

  computed: {
    organizationCode: function () {
      return Utils.singleQueryValue(this.$route.query.organization)
    },
    isConnecting: function () {
      return this.organizationCode != null
    },
    connectNoteText: function () {
      return this.$t('signup.connect-note.text', { organization: this.organizationName })
    },
    stepCount: () => 3,
    isCredentialsStep: function () { return this.step === 1 },
    isSeekerStep: function () { return this.step === 2 },
    isVerifyStep: function () { return this.step === 3 },
    isInitialStep: function () { return this.step === 1 },
    isFinalStep: function () { return this.step === this.stepCount },
    isSuccessStep: function () { return this.step > this.stepCount },
    titleParts: function () {
      const title = this.$t('signup.title')
      return title.split('%{icon}')
    }
  },

  asyncComputed: {
    organizationName: async function () {
      if (!this.isConnecting) return null

      const org = await this.loadPublicOrganization(this.organizationCode)
      return org?.Name
    }
  },

  watch: {
    organizationName: function (newValue) {
      if (this.isConnecting) {
        if (newValue == null) {
          this.$router.replace({ name: 'SignUp' })
        } else {
          this.showConnectNote = true
        }
      }
    }
  },

  methods: {
    onBackClick () {
      if (this.isInitialStep) {
        this.$router.push({name: 'Login'})
      } else {
        this.step--
      }
    },

    async onContinueClick () {
      if (this.processing) return

      let success
      let toReset
      if (this.isCredentialsStep) {
        success = await this.submitCredentials()
        toReset = this.$refs.seeker
      } else if (this.isSeekerStep) {
        success = await this.submitSeeker()
        toReset = this.$refs.verify
      } else if (this.isVerifyStep) {
        success = await this.submitVerify()
      }

      if (success) {
        if (toReset) toReset.$v.$reset()
        this.step++
      }
    },

    async submitCredentials () {
      const credentials = this.$refs.credentials.getValidated()
      if (credentials) {
        this.processing = true
        try {
          const started = await this.startRegistrationAndHandle(credentials.email, null, true)
          if (started) this.credentials = credentials
          return started
        } finally {
          this.processing = false
        }
      }
    },

    async submitSeeker () {
      const userData = this.$refs.seeker.getValidated()
      if (userData) {
        this.processing = true
        try {
          const started = await this.startRegistrationAndHandle(this.credentials.email, userData.firstName)
          if (started) this.userData = userData
          return started
        } finally {
          this.processing = false
        }
      }
    },

    async submitVerify () {
      const code = this.$refs.verify.getValidated()
      if (code) {
        this.processing = true
        try {
          await this.registerUser({
            credentials: this.credentials,
            verification: {id: this.verificationId, code},
            profile: {
              ...this.userData,
              birthDate: Utils.toIsoDate(this.userData.birthDate)
            },
            connectWithOrganization: this.organizationCode
          })
          return true
        } catch (e) {
          this.showGlobalMessage({
            kind: MessageKind.ERROR,
            text: this.$errorMessage(e, 'signup')
          })
          if (e.key === AppErrorKey.INVALID_VERIFICATION_CODE) {
            this.attemptsLeft--
          }
          return false
        } finally {
          this.processing = false
        }
      }
    },

    async onVerifySendNewCode () {
      this.sendingNewCode = true
      try {
        const started = await this.startRegistrationAndHandle(this.credentials.email, this.userData.firstName)
        if (started) {
          this.showGlobalMessage({kind: 'success', text: this.$t('signup.sent_code')})
        }
      } finally {
        this.sendingNewCode = false
      }
    },

    async startRegistrationAndHandle (email, firstName, withoutCode = false) {
      try {
        const result = await this.startRegistration({email, firstName, withoutCode})
        if (result.verificationId != null) {
          this.verificationId = result.verificationId
          this.maxAttempts = result.maxAttempts ?? 1
          this.attemptsLeft = this.maxAttempts
        }
        return true
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e, 'signup')
        })
        return false
      }
    },

    ...mapActions(['setHeaderTitle', 'showGlobalMessage', 'loadPublicOrganization']),
    ...mapActions('user', ['startRegistration', 'registerUser'])
  },

  mounted () {
    this.setHeaderTitle(this.$t('signup.page-title'))
  }
}
</script>
