





























import Vue from 'vue'

export default Vue.extend({
  props: {
    value: { type: Boolean, default: false },
    title: String,
    message: String,
    seconds: { type: Number, default: 10 },
    showContinue: { type: Boolean, default: false },
    continueText: String
  },

  data: () => ({
    count: 0,
    interval: undefined as number | undefined
  }),

  computed: {
    progress: function (): number {
      return 100 * this.count / this.seconds
    },
    actualContinueText: function (): string {
      return this.continueText ?? this.$t('forms.continue')
    }
  },

  watch: {
    value: function (newValue: boolean) {
      if (newValue) {
        this.count = this.seconds
        this.interval = setInterval(() => this.onTick(), 1000, [])
      } else if (this.interval != null) {
        clearInterval(this.interval)
        this.interval = undefined
      }
    }
  },

  methods: {
    onInput (v: boolean) {
      this.$emit('input', v)
    },
    onTick () {
      this.count--
      if (this.count <= 0) {
        this.onContinue()
      }
    },
    onContinue () {
      this.onInput(false)
      this.$emit('elapsed')
    }
  }
})
