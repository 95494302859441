<template>
  <v-container>
    <v-row v-if="isSpecialist && sharesGoals === false" justify="center">
      <v-col cols="12" md="8">
        <v-alert dense text type="info">
          {{ $t('goals.not-shared-with-specialist') }}
        </v-alert>
      </v-col>
    </v-row>
    <template v-else>
      <v-row justify="center">
        <v-col cols="12" md="7">
          <h2 v-if="isSeeker">{{ seekerTitle }}</h2>
        </v-col>
        <v-col cols="12" md="1">
          <mpro-edit-goal></mpro-edit-goal>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <div v-if="openGoals.length > 0">
            <mpro-single-goal v-for="goal in openGoals" :key="goal.Id" :goal="goal" class="ma-2 ma-sm-6">
            </mpro-single-goal>
          </div>
          <div v-else>
            <v-alert dense text type="success">
              <span v-if="isSpecialist && seeker != null">{{ $t('goals.no-goals-title-specialist', {name: (seeker.DisplayName || seeker.Username)}) }}</span>
              <span v-else-if="isSeeker && activeSubordinate != null">{{ $t('goals.no-goals-title-subordinate', {name: activeSubordinate.FirstName}) }}</span>
              <span v-else>{{ $t('goals.no-goals-title-seeker') }}</span>
            </v-alert>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <h2>{{ $t('goals.completed') }}</h2>
          <div v-if="reachedGoals.length > 0" class="">
              <mpro-single-goal v-for="goal in reachedGoals" :key="goal.Id" :goal="goal" class="ma-2 ma-sm-6">
              </mpro-single-goal>
          </div>
        </v-col>
      </v-row>
    </template>
    <mpro-header-handler tabName="goals" :seeker="seeker"/>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import editGoal from './edit_goal'
import singleGoal from './single_goal'
import HeaderHandler from '../specialist/header-handler'
import { SharedDataKind } from '@/models/data-sharing'

export default {
  components: {
    'mpro-edit-goal': editGoal,
    'mpro-single-goal': singleGoal,
    'mpro-header-handler': HeaderHandler
  },

  computed: {
    ...mapState({
      myGoals: state => state.seeker.goals,
      exercises: state => state.exercises.all
    }),
    ...mapGetters(['isSpecialist', 'isSeeker']),
    ...mapGetters('specialist', ['getSeekerById']),
    ...mapGetters('goals', ['getGoalsBySeekerId']),
    ...mapGetters('seeker', ['activeSubordinate']),
    seekerId: function () {
      return this.$route.params.seekerId
    },
    seeker: function () {
      return this.getSeekerById(this.seekerId)
    },
    seekerTitle: function () {
      return this.activeSubordinate == null
        ? this.$t('goals.title-seeker')
        : this.$t('goals.title-subordinate', { name: this.activeSubordinate.FirstName })
    },
    goals () {
      if (this.isSpecialist) {
        return this.getGoalsBySeekerId(this.seekerId)
      } else {
        return this.myGoals
      }
    },
    openGoals () {
      return this.goals.filter(s => !s.EndDate)
    },
    reachedGoals () {
      return this.goals.filter(s => s.EndDate)
    }
  },

  asyncComputed: {
    sharesGoals: async function () {
      if (!this.isSpecialist || this.seekerId == null) return false
      const kinds = await this.getOrLoadSharedDataKinds(this.seekerId)
      return kinds.includes(SharedDataKind.GoalsAndExercises)
    }
  },

  watch: {
    seekerId: function () {
      this.updateSeekerGoals()
    },
    sharesGoals: function () {
      this.updateSeekerGoals()
    },
    seekerTitle: function (newValue) {
      if (this.isSeeker) {
        this.setHeaderTitle(newValue)
      }
    }
  },

  methods: {
    ...mapActions('seeker', ['ensureGoalsLoaded']),
    ...mapActions('specialist', ['ensureSpecialistSeekersLoaded', 'getOrLoadSharedDataKinds']),
    ...mapActions('goals', ['ensureSeekerGoalsLoaded']),
    ...mapActions(['setHeaderTitle']),
    updateSeekerGoals () {
      if (this.isSpecialist) {
        if (this.seekerId && this.sharesGoals === true) {
          this.ensureSeekerGoalsLoaded({ seekerId: this.seekerId })
        }
      } else {
        this.ensureGoalsLoaded()
      }
    }
  },

  created () {
    if (this.isSpecialist) {
      this.ensureSpecialistSeekersLoaded()
    }
    this.updateSeekerGoals()
  },
  mounted () {
    if (this.isSeeker) {
      this.setHeaderTitle(this.seekerTitle)
    }
  }
}
</script>
