export const Role = {
  SEEKER: 'seeker',
  SPECIALIST: 'specialist',
  ADMINISTRATOR: 'administrator'
}

export const MessageKind = {
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning'
}

export const Gender = {
  MALE: 'Male',
  FEMALE: 'Female',
  UNKNOWN: 'Unknown'
}

export const Handedness = {
  RIGHT: 'Right',
  LEFT: 'Left',
  MIXED: 'Mixed'
}

export const Ethnicity = {
  WHITE: 'White',
  GIPSY: 'Gipsy',
  INDIAN: 'Indian',
  PAKISTANI: 'Pakistani',
  BANGLADESHI: 'Bangladeshi',
  CHINESE: 'Chinese',
  OTHER_ASIAN: 'OtherAsian',
  AFRICAN: 'African',
  CARIBBEAN: 'Caribbean',
  OTHER_BLACK: 'OtherBlack',
  ARAB: 'Arab',
  WHITE_AND_BLACK: 'WhiteAndBlack',
  WHITE_AND_ASIAN: 'WhiteAndAsian',
  OTHER_MIXED: 'OtherMixed',
  OTHER: 'Other'
}
export const EthnicityGroup = {
  'White': [Ethnicity.WHITE, Ethnicity.GIPSY],
  'Black': [Ethnicity.AFRICAN, Ethnicity.CARIBBEAN, Ethnicity.OTHER_BLACK],
  'Asian': [Ethnicity.INDIAN, Ethnicity.BANGLADESHI, Ethnicity.PAKISTANI, Ethnicity.CHINESE, Ethnicity.OTHER_ASIAN],
  'Mixed': [Ethnicity.WHITE_AND_BLACK, Ethnicity.WHITE_AND_ASIAN, Ethnicity.OTHER_MIXED],
  'Arab': [Ethnicity.ARAB],
  'Other': [Ethnicity.OTHER]
}

export const OrganizationKind = {
  TEST: 'Test',
  MASTER: 'Master',
  HEALTH_CENTER: 'HealthCenter',
  FITNESS_CENTER: 'FitnessCenter',
  ACADEMIC: 'Academic',
  DEMO: 'Demo',
  HEALTH_CONSULTANT: 'HealthConsultant',
  CORPORATE: 'Corporate',
  CLUB: 'Club',
  RETAIL: 'Retail',
  CARE_CENTRE: 'CareCentre',
  HOME_SERVICE: 'HomeService',
  TELEHEALTH: 'Telehealth',
  INSURER: 'Insurer',
  ORGANISATION: 'Organisation'
}

export const DaysOfTheWeek = {
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday'
}

export const ScanType = {
  DEPTH: '3D',
  MOBILE: '2D'
}

// TODO: Get rid of movement kinds with underscores and use values from the DB instead

export const MovementKind = {
  STAND_STILL: 'stand_still',
  BALANCE_LEFT: 'balance_left_leg',
  BALANCE_RIGHT: 'balance_right_leg',
  SIDE_BEND: 'side_bend',
  SQUAT: 'squat',
  SQUAT_LEFT: 'squat_left',
  SQUAT_RIGHT: 'squat_right'
}

export const AssessmentName = {
  STAND_STILL: 'StandStill',
  BALANCE_TWO_LEGS: 'BalanceTwoLegs',
  BALANCE_LEFT: 'BalanceLeft',
  BALANCE_RIGHT: 'BalanceRight',
  SIDE_BEND: 'SideBend',
  SQUAT_DOUBLE: 'SquatDouble',
  SQUAT_LEFT: 'SquatLeft',
  SQUAT_RIGHT: 'SquatRight'
}

export function movementKindToAssessmentName (movementKind) {
  switch (movementKind) {
    case MovementKind.STAND_STILL: return 'StandStill'
    case MovementKind.BALANCE_LEFT: return 'BalanceLeft'
    case MovementKind.BALANCE_RIGHT: return 'BalanceRight'
    case MovementKind.SIDE_BEND: return 'SideBend'
    case MovementKind.SQUAT: return 'SquatDouble'
    case MovementKind.SQUAT_LEFT: return 'SquatLeft'
    case MovementKind.SQUAT_RIGHT: return 'SquatRight'
    default: return null
  }
}

export function assessmentNameToMovementKind (assessmentName) {
  switch (assessmentName) {
    case AssessmentName.STAND_STILL: return 'stand_still'
    case AssessmentName.BALANCE_TWO_LEGS: return 'stand_still'
    case AssessmentName.BALANCE_LEFT: return 'balance_left_leg'
    case AssessmentName.BALANCE_RIGHT: return 'balance_right_leg'
    case AssessmentName.SIDE_BEND: return 'side_bend'
    case AssessmentName.SQUAT_DOUBLE: return 'squat'
    case AssessmentName.SQUAT_LEFT: return 'squat_left'
    case AssessmentName.SQUAT_RIGHT: return 'squat_right'
    default: return null
  }
}

export const KneeShapesSquat = ['C', 'J', 'S']

export const SurveyAssignmentNotesKind = {
  INFO: 'Information',
  CAUTION: 'Caution'
}
