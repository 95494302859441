import { AssessmentBundle, StandStillAssessment } from '@/models/assessments'

function augmentStandStill (assessment: StandStillAssessment): void {
  if (assessment.KneeLeftOutwardFlexionAngle == null) {
    assessment.KneeLeftOutwardFlexionAngle = assessment.HipToKneeLeftAngleFaceView - assessment.KneeToFootLeftAngleFaceView
  }
  if (assessment.KneeRightOutwardFlexionAngle == null) {
    assessment.KneeRightOutwardFlexionAngle = assessment.KneeToFootRightAngleFaceView - assessment.HipToKneeRightAngleFaceView
  }
}

export function augmentAssessments (assessmentBundle: AssessmentBundle): void {
  if (assessmentBundle.StandStill != null) {
    augmentStandStill(assessmentBundle.StandStill)
  }
}
