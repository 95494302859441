<template>
  <div class="d-inline-block">

    <div class="d-flex">
      <v-dialog v-model="creatingNew" max-width="600" scrollable
                @keydown.esc="creatingNew = false">
        <template #activator="{on}">
          <v-btn class="ma-6 MProPurple" small
                 v-on="on">
            <v-icon small>mdi-plus</v-icon>
            <small>{{ $t('admin.new_specialist') }}</small>
          </v-btn>
        </template>

        <mpro-editable-card ref="createNewCard"
                            :save-callback="saveNew"
                            @end-edit="onCreateNewEndEdit">
          <template #title>{{ $t('admin.new_specialist') }}</template>
          <mpro-specialist-details ref="createNewDetails"
                                   :organization-code="organizationCode"/>
        </mpro-editable-card>
      </v-dialog>
    </div>

    <v-skeleton-loader type="table-head, table-body" :loading="loading">
      <mpro-specialists-table :specialists="specialists"/>
    </v-skeleton-loader>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SpecialistsTable from '../specialists/table'
import SpecialistDetails from '../specialists/details'
import EditableCard from '@/components/elements/editable-card'
import {MessageKind} from '@/helpers/enums'

export default {
  components: {
    'mpro-specialists-table': SpecialistsTable,
    'mpro-specialist-details': SpecialistDetails,
    'mpro-editable-card': EditableCard
  },

  props: {
    organizationCode: {type: String, default: null},
    specialists: {type: Array, default: () => []}
  },

  data: () => ({
    creatingNew: false
  }),

  computed: {
    ...mapGetters('admin', ['getSpecialistUuid']),
    loading: function () {
      return this.organizationCode == null
    }
  },

  watch: {
    creatingNew: async function (newValue) {
      if (newValue) {
        await this.$nextTick()
        this.$refs.createNewCard.beginEdit()
      } else if (this.$refs.createNewDetails) {
      }
    }
  },

  methods: {
    async saveNew () {
      if (!this.$refs.createNewDetails.validate()) return false

      try {
        await this.createSpecialist(this.$refs.createNewDetails.data)
        return true
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e)
        })
        return false
      }
    },
    onCreateNewEndEdit (saved) {
      const login = this.$refs.createNewDetails.data.login
      this.creatingNew = false
      if (saved) {
        this.$router.push({name: 'admin:Specialist', params: { uuid: this.getSpecialistUuid(login) }})
      }
    },
    ...mapActions(['showGlobalMessage']),
    ...mapActions('admin', ['createSpecialist'])
  }
}
</script>
