<template>
    <v-card class="py-4 px-12" :outlined="$route.query.open === goal.Id ? true : false" :class="goal.EndDate ? 'v-card--look-disabled' : ''">
      <v-card-title class="pt-0">
        <span>{{ goal.Goal }}</span>
      </v-card-title>
      <v-card-subtitle>
        <span v-if="goal.EndDate"> {{ $t('goals.completed-on', { date: $formatDate(goal.EndDate) }) }}</span>
        <span v-else>{{ $t('goals.started-on', { date: $formatDate(goal.StartDate) }) }}</span>
      </v-card-subtitle>
      <v-card-text>
        {{ goal.Notes }}
      </v-card-text>
      <v-card-actions>
        <mpro-edit-goal  v-if="isSeeker" :editGoal="goal"></mpro-edit-goal>
        <v-btn v-if="isSpecialist" :to="locationToAskQuestion" class="MProBlue">{{ $t('general.buttons.ask-question')}}</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import editGoal from './edit_goal'

export default {
  components: {
    'mpro-edit-goal': editGoal
  },

  props: {
    goal: Object
  },

  data: () => ({
    editGoal: false
  }),

  computed: {
    seeker: function () {
      return this.getSeekerById(this.goal.SeekerId)
    },
    locationToAskQuestion () {
      return {
        name: 'Chat',
        params: { userUuid: this.getChatUuid(this.seeker.Login ?? this.seeker.SuperiorLogin) },
        query: { type: 'Goal', objectId: this.goal.Id }
      }
    },
    ...mapGetters('specialist', ['getSeekerById']),
    ...mapGetters(['isSpecialist', 'isSeeker']),
    ...mapGetters('chats', ['getChatUuid'])
  },

  methods: {
    ...mapActions(['showGlobalMessage'])
  }
}
</script>
