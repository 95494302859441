import { Location } from 'vue-router'
import { RouteNames, ParamNames } from './names'

class LocationBuilder {
  static instance: LocationBuilder = new LocationBuilder()

  private replace: boolean | undefined

  private constructor (replace?: boolean) {
    this.replace = replace
  }

  withReplace (): LocationBuilder {
    return new LocationBuilder(true)
  }

  dashboard (): Location {
    return { name: RouteNames.DASHBOARD, replace: this.replace }
  }

  login (returnPath?: string): Location {
    const query: Record<string, string | undefined> = {}
    query[ParamNames.RETURN] = returnPath
    return { name: RouteNames.LOGIN, query, replace: this.replace }
  }

  loginWithConnect (organizationCode: string) {
    const query: Record<string, string> = {}
    query[ParamNames.CODE] = organizationCode
    return {
      name: RouteNames.LOGIN_WITH_CONNECT,
      query,
      replace: this.replace
    }
  }

  myAccount (action?: string, organizationCode?: string) {
    const query: Record<string, string | undefined> = {}
    query[ParamNames.ACTION] = action
    query[ParamNames.CODE] = organizationCode
    return {
      name: RouteNames.MY_ACCOUNT,
      query,
      replace: this.replace
    }
  }

  connectWithOrganization (organizationCode: string): Location {
    const query: Record<string, string> = {}
    query[ParamNames.CODE] = organizationCode
    return {
      name: RouteNames.Widget.CONNECT_WITH_ORGANIZATION,
      query,
      replace: this.replace
    }
  }

  sharingSeekers (): Location {
    return {
      name: RouteNames.Admin.SHARING_SEEKERS,
      replace: this.replace
    }
  }
}

export default LocationBuilder.instance
