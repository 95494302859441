<template>
  <v-select :value="internalValue" :items="items"
            :multiple="multiple" :readonly="readonly"
            :clearable="multiple && !readonly" clear-icon="mdi-eraser"
            :value-comparator="compareValues"
            :label="label" :hint="hint" persistent-hint
            @input="onInput" />
</template>

<script>
import Lo from 'lodash'

export default {
  props: {
    value: String,
    label: String,
    hint: String,
    enumValues: { type: Array, default: () => [] },
    enumTexts: { type: Array, default: () => [] },
    sortByText: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: true }
  },

  computed: {
    internalValue: function () {
      return !this.multiple ? this.value : this.value == null || this.value.trim() === '' ? [] : this.value.split(',')
    },
    items: function () {
      if (this.enumValues == null) return []

      const enumTexts = this.enumTexts ?? this.enumValues
      let result = this.enumValues.map((v, index) => ({
        value: v,
        text: enumTexts[index] ?? v
      }))

      if (!this.required && !this.multiple) {
        result.unshift({ value: null, text: '' })
      }

      if (this.sortByText) {
        result = Lo.sortBy(result, i => i.text)
      }

      return result
    }
  },

  methods: {
    onInput (newInternalValue) {
      const newValue = !this.multiple ? newInternalValue : newInternalValue === [] ? null : newInternalValue.join(',')
      this.$emit('input', newValue)
    },
    compareValues (a, b) {
      return Lo.isEqualWith(a, b,
        (v, o) => Lo.isEqual(
          typeof (v) === 'string' ? v.toLowerCase() : v,
          typeof (o) === 'string' ? o.toLowerCase() : o))
    }
  }
}
</script>
