




































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { required, email, helpers } from 'vuelidate/lib/validators'
import { TranslateResult } from 'vue-i18n'
import { MessageKind } from '@/helpers/enums'

export default Vue.extend({
  props: {
    value: Boolean
  },

  data: () => ({
    sending: false,
    form: {
      newEmail: ''
    }
  }),

  validations: {
    form: {
      newEmail: {
        required,
        email,
        different: function (v: string): boolean {
          return !helpers.req(v) || v.toLocaleLowerCase() !== this.getUserEmail.toLocaleLowerCase()
        }
      }
    }
  },

  computed: {
    ...mapGetters('user', ['getUserEmail']),
    validationMessages: function (): { [key: string]: TranslateResult } {
      return {
        different: this.$t('account.control-account.change-email-address.should-differ')
      }
    }
  },

  methods: {
    ...mapActions(['showGlobalMessage']),
    ...mapActions('seeker', ['requestChangeEmailAddress']),
    onInput (value: boolean) {
      this.$emit('input', value)
      if (!value) {
        this.form.newEmail = ''
        this.$v.$reset()
      }
    },
    close () {
      this.onInput(false)
    },
    async send (): Promise<void> {
      this.$v.form.$touch()
      if (this.$v.$invalid) return

      this.sending = true
      try {
        await this.requestChangeEmailAddress({ newEmail: this.form.newEmail })
      } catch (e) {
        const message = this.$errorMessage(e)
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: message
        })
        return
      } finally {
        this.sending = false
      }

      this.showGlobalMessage({
        kind: MessageKind.SUCCESS,
        text: this.$t('account.control-account.change-email-address.sent-instructions', { email: this.form.newEmail })
      })
      this.close()
    }
  }
})
