




























import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import Lo from 'lodash'
import { ListItem } from '@/models/vuetify'

export default Vue.extend({
  computed: {
    ...mapState(['validCountryCodes']),
    ...mapGetters(['getRole']),
    items: function (): ListItem[] {
      return Lo(Object.keys(this.$isoLanguages.getAlpha2Codes()))
        .filter(code => this.$i18n.availableLocales.includes(code))
        .map(code => ({
          value: code,
          text: this.$isoLanguages.getName(code, code) ?? ''
        }))
        .sortBy(a => a.text)
        .value()
    }
  },

  methods: {
    ...mapActions('user', ['updateUserSetting']),
    async onLanguageSet (): Promise<void> {
      if (this.getRole != null) {
        // Store selected language in user settings
        await this.updateUserSetting({
          setting: 'portal.activeLocale',
          value: this.$i18n.locale
        })
      }
    }
  }
})
