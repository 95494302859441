// Adds to Vue instances
// * $errorMessage function - gets localized messages for an error
// * $validationErrors - gets localized validation errors for vuelidate validation
import {AppError} from '../helpers/errors'

const errorMessage = function (e, scope = null) {
  if (!e) return null

  scope = String(scope)
  const key = e instanceof AppError ? e.key : 'generic'
  while (scope) {
    const name = `${scope}.errors.${key}`
    if (this.$te(name)) {
      return this.$t(name)
    } else {
      const dotIndex = scope.lastIndexOf('.')
      if (dotIndex >= 0) {
        scope = scope.substr(0, dotIndex)
      } else {
        scope = null
      }
    }
  }

  // Search message globally and fall back to key if not found
  const globalName = `errors.${key}`
  return this.$te(globalName) ? this.$t(globalName) : key
}

const validationErrors = function (validation, customMessages) {
  let errors = []
  if (validation.$dirty) {
    const validators = Object.keys(validation).filter(k => !k.startsWith('$'))
    validators.forEach(v => {
      if (!validation[v]) {
        let message = customMessages == null ? undefined : customMessages[v]
        if (message == null) {
          const key = `forms.validations.${v}`
          message = this.$t(key, validation.$params[v])
        } else if (typeof message === 'function') {
          message = message(validation.$params[v])
        }
        errors.push(message)
      }
    })
  }
  return errors
}

export default {
  install: function (Vue) {
    Vue.prototype.$errorMessage = errorMessage
    Vue.prototype.$validationErrors = validationErrors
  }
}
