<template>
    <div>
        <div class="tab-title">
            <h2>{{ $t('integrations.reflex.title') }}</h2>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions([
      'setHeaderTitle'
    ])
  },
  mounted () {
    this.setHeaderTitle(this.$t('menu.reflex'))
  }
}
</script>
