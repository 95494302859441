import Vue from 'vue'
import { HeaderTab } from '@/models/ui'
import { mapActions } from 'vuex'

export default Vue.extend({
  computed: {
    headerTabs: function (): HeaderTab[] {
      const organizationCode: string | undefined = this.$route.params.organizationCode
      return [{
        value: 'seekers',
        title: this.$t('admin.sharing-seekers.title'),
        icon: 'mdi-account-check',
        to: organizationCode == null
          ? { name: 'admin:SharingSeekers' }
          : { name: 'admin:SharingSeekersOfOrganization', params: { organizationCode } }
      }, {
        value: 'events',
        title: this.$t('admin.seeker-events.title'),
        icon: 'mdi-clipboard-text-outline',
        to: organizationCode == null
          ? { name: 'admin:SeekerEvents' }
          : { name: 'admin:SeekerEventsOfOrganization', params: { organizationCode } }
      }, {
        value: 'backup',
        title: this.$t('admin.backup-seeker-data.title'),
        icon: 'mdi-archive',
        to: organizationCode == null
          ? { name: 'admin:BackupSeekerData' }
          : { name: 'admin:BackupSeekerDataForOrganization', params: { organizationCode } }
      }]
    }
  },

  watch: {
    headerTabs () {
      this.setHeaderTabs({ tabs: this.headerTabs })
    }
  },

  methods: {
    ...mapActions(['setHeaderTabs', 'setHeaderTitle'])
  },

  mounted () {
    this.setHeaderTitle(this.$t('admin.seekers-title'))
    this.setHeaderTabs({ tabs: this.headerTabs })
  },
  beforeDestroy () {
    this.setHeaderTabs(null)
  }
})
