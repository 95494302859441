<template>
    <v-dialog v-model="dialogOpen"
              :persistent="processing" max-width="600"
              @keydown="onDialogKeyDown">
      <template #activator="{on}">
        <slot name="button" :on="on"/>
      </template>
      <v-card>
        <v-card-title class="d-flex justify-space-between align-start">
          <span>{{$t('account.create_scan_code.title')}}</span>
          <v-btn v-if="!processing" icon @click="cancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-tabs-items :value="tab">
          <v-tab-item value="request">
            <v-form>
              <v-card-text>
                <v-select v-if="!forSelf"
                          :label="$t('account.create_scan_code.who_label')"
                          v-model="subordinateId"
                          :items="whoItems"/>
              </v-card-text>
              <v-card-actions>
                <v-btn :disabled="processing"
                       @click="cancel">
                  {{$t('forms.cancel')}}
                </v-btn>
                <v-spacer/>
                <v-btn color="primary"
                      :disabled="processing" :loading="processing"
                      @click="onGetCodeClick">
                  {{$t('account.create_scan_code.ok')}}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-tab-item>

          <v-tab-item value="response">
            <v-card-text>
              <v-alert type="success">
                <span class="ws-pre-line">{{resultInstruction}}</span>
              </v-alert>
              <v-img :src="qrCodeImageSrc" :alt="$t('account.create_scan_code.qr_code_alt')"
                      class="mx-auto" width="300" height="300">
                <template #placeholder>
                  <v-sheet color="grey lighten-2" width="300" height="300"/>
                </template>
              </v-img>
              <p class="headline text-center mt-2">{{scanCode}}</p>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import {MessageKind} from '@/helpers/enums'

export default {
  data: () => ({
    dialogOpen: false,
    subordinateId: '',
    processing: false,
    tab: 'request',
    scanCode: '',
    expiresAt: ''
  }),

  computed: {
    forSelf: function () {
      return this.subordinates.length === 0
    },
    whoItems: function () {
      const result = this.subordinates.map(s => ({
        value: s.Id,
        text: `${s.FirstName} ${s.LastName}`
      }))
      result.unshift({
        value: '',
        text: this.$t('account.create_scan_code.who_self')
      })
      return result
    },
    subordinateName: function () {
      return this.subordinateId
        ? this.whoItems.find(s => s.value === this.subordinateId).text
        : null
    },
    qrCodeImageSrc: function () {
      if ((this.scanCode ?? '').length === 0) {
        return null
      }

      const urlTemplate = process.env.VUE_APP_QR_CODE_URL_TEMPLATE
      return urlTemplate
        .replaceAll('{CODE}', encodeURIComponent(this.scanCode))
        .replaceAll('{SIZE}', '300')
    },
    resultInstruction: function () {
      return this.$t('account.create_scan_code.result_instruction', {
        name: this.subordinateId ? ` ${this.subordinateName}` : '',
        expiration: this.$formatDateTime(this.expiresAt, false)
      })
    },
    ...mapState('user', {
      subordinates: state => (state.seeker && state.seeker.subordinates) || []
    }),
    ...mapGetters('seeker', ['activeSubordinate'])
  },

  methods: {
    async onGetCodeClick () {
      this.processing = true
      try {
        const result = await this.createScanCode({subordinateId: this.subordinateId})
        this.scanCode = result.code
        this.expiresAt = this.$moment.utc(result.expiresAtUtc).local()
        this.tab = 'response'
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e)
        })
      } finally {
        this.processing = false
      }
    },
    cancel () {
      this.dialogOpen = false
    },
    onDialogKeyDown (e) {
      if (e.key === 'Escape') {
        this.cancel()
      }
    },
    ...mapActions(['showGlobalMessage']),
    ...mapActions('user', ['createScanCode'])
  },

  watch: {
    dialogOpen: async function (newValue) {
      if (newValue !== true) {
        // reset state on hide
        this.subordinateId = ''
        this.processing = false
        this.tab = 'request'
        return
      }

      await this.$nextTick()
      if (this.dialogOpen !== true) return

      if (this.forSelf) {
        // Automatically get code if there is no choice
        this.onGetCodeClick()
      } else {
        // Automatically set who to the current "view data of" person
        this.subordinateId = this.activeSubordinate != null ? this.activeSubordinate.Id : ''
      }
    }
  }
}
</script>
