










































import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'
import Lo from 'lodash'
import { Distance } from 'convert-units'
import { BalanceAssessment, Point2 } from '@/models/assessments'
import { MovementKind } from '@/helpers/enums'

interface Rect {
  left: number,
  top: number,
  width: number,
  height: number
}

export default Vue.extend({
  props: {
    assessment: Object as PropType<BalanceAssessment>,
    movementKind: String,
    showTrajectory: Boolean,
    showPosition: Boolean,
    timeStamp: Number
  },

  computed: {
    ...mapGetters('user', ['isMetric']),
    validMovement: function (): boolean {
      return [MovementKind.STAND_STILL, MovementKind.BALANCE_LEFT, MovementKind.BALANCE_RIGHT].includes(this.movementKind)
    },
    swayArea: function (): string {
      if (this.assessment == null) return '-'

      const roundedToMm = this.$roundValue(this.assessment.SwayEnvelopeArea, 6)
      return this.$formatArea(roundedToMm, 'm2', this.isMetric)
    },
    viewRect: function (): Rect {
      const points = this.assessment?.SwayEnvelope ?? []

      const xx = points.map(p => p.X)
      const minX = Lo.min(xx) ?? 0
      const maxX = Lo.max(xx) ?? 1
      let width = maxX - minX
      let marginX = width === 0 ? 1 : width / 10
      width += 2 * marginX

      const yy = points.map(p => p.Y)
      const minY = Lo.min(yy) ?? 0
      const maxY = Lo.max(yy) ?? 1
      let height = maxY - minY
      let marginY = height === 0 ? 1 : height / 10
      height += 2 * marginY

      // Ensure there's enough horizontal room for a scale unit
      const minWidth = 1.2 * this.scaleUnitSizeMeters
      if (width < minWidth) {
        marginX += (minWidth - width) / 2
        width = minWidth
      }

      return {
        left: minX - marginX,
        width,
        top: minY - marginY,
        // Ensure there's enough space at the bottom for a scale unit
        height: 1.1 * height
      }
    },
    viewBox: function (): string {
      return [this.viewRect.left, this.viewRect.top,
        this.viewRect.width, this.viewRect.height].join(' ')
    },
    envelopePoints: function (): string {
      return (this.assessment?.SwayEnvelope ?? []).map(p => `${p.X},${p.Y}`).join(' ')
    },
    pathPoints: function (): string {
      return Lo.orderBy((this.assessment?.CenterOfMassSnailTrailPathTopView ?? []), p => p.TimeStampMs)
        .map(p => `${p.Value.X},${p.Value.Y}`).join(' ')
    },
    comPosition: function (): Point2 | undefined {
      if (this.timeStamp != null) {
        const path = this.assessment?.CenterOfMassSnailTrailPathTopView ?? []
        const closest = Lo.minBy(path, p => Math.abs(p.TimeStampMs - this.timeStamp))
        if (closest != null) {
          return closest.Value
        }
      }

      return undefined
    },
    lineThickness: function (): number {
      return Math.max(this.viewRect.width, this.viewRect.height) / 75
    },
    pointRadius: function (): number {
      return 3 * this.lineThickness
    },
    scaleUnit: function (): { val: number, unit: Distance } {
      // Stick to fixed units for now
      // Look for an algorithm computing a dynamic unit in the repo history
      return this.isMetric
        ? { val: 1, unit: 'cm' }
        : { val: 0.5, unit: 'in' }
    },
    scaleUnitSizeMeters: function (): number {
      return this.$units(this.scaleUnit.val).from(this.scaleUnit.unit).to('m')
    },
    scaleWidth: function (): string {
      const size = Math.max(this.viewRect.width, this.viewRect.height)
      return `${100 * this.scaleUnitSizeMeters / size}%`
    }
  }
})
