<template>
  <v-dialog v-model="dialogOpen" max-width="600">
    <template #activator="{on}">
      <button v-on="on" class="narrative-indicator" :class="colorClass">
        <span>{{ label }}</span>
      </button>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-space-between align-start">
        <span>{{ title }}</span>
        <v-btn icon @click="dialogOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slot/>
        <p class="text--disabled">
          Note: Please ensure that the clothing is suitable. Check the scan quality before accepting and interpreting results. Redo the scan if the quality is poor or the results seem inaccurate.
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
const IndicatorKind = {
  IMPROVE: 'improve',
  MAINTAIN: 'maintain'
}

export default {
  props: {
    kind: String,
    title: String
  },

  data: () => ({
    dialogOpen: false
  }),

  computed: {
    colorClass: function () {
      switch (this.kind?.toLowerCase()) {
        case IndicatorKind.IMPROVE: return 'MProCoral'
        case IndicatorKind.MAINTAIN: return 'MProGreen'
        default: return null
      }
    },
    label: function () {
      switch (this.kind?.toLowerCase()) {
        case IndicatorKind.IMPROVE: return this.$t('scans.single.narrative_indicator_improve')
        case IndicatorKind.MAINTAIN: return this.$t('scans.single.narrative_indicator_maintain')
        default: return null
      }
    }
  }
}
</script>

<style>
.narrative-indicator {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.2em;
  height: 1.2em;
  border-radius: 1em;
  vertical-align: text-top;
}
.narrative-indicator span {
  font-size: smaller;
}
</style>
