<template>
  <div v-if="!isSeeker">
    <v-btn @click="newChatDialog = !newChatDialog" class="mt-3 MProBlue" block>{{ $t('chats.new.new-chat-btn') }}</v-btn>
    <v-dialog v-model="newChatDialog" :max-width="`${ Math.max(40, maxUserTitleLength + 10) }ex`">
        <v-card>
          <v-card-title>{{ $t('chats.new.title') }}</v-card-title>
          <v-card-text>
            <v-text-field :placeholder="$t('chats.new.search-placeholder')"
                          prepend-icon="mdi-filter"
                          full-width
                          v-model.trim="userFilter"/>
            <v-list v-if="filteredUsers.length > 0"
                    style="overflow-y: auto; max-height: 60vh">
              <v-list-item v-for="user in filteredUsers" :key="user.Id"
                           @click="newChatDialog = false"
                           :to="getLocationOfChat(user)">
                <v-list-item-title>
                  {{ getUserTitle(user) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <span v-else>
              {{ isSeeker ? $t('chats.new.not-found-specialist') : $t('chats.new.not-found-seeker') }}
            </span>
          </v-card-text>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    newChatDialog: false,
    userFilter: ''
  }),
  computed: {
    availableUsers: function () {
      const users = this.isSpecialist ? this.seekers : this.specialists.filter(s => s.IsActive)
      return users.filter(u => {
        const login = u.Login ?? u.SuperiorLogin
        return login != null && login !== this.getUserEmail
      })
    },
    filteredUsers: function () {
      const users = this.availableUsers

      if (this.userFilter === '') {
        return users
      }

      const filterValue = this.userFilter.toLocaleLowerCase()
      return users.filter(u =>
        (u.DisplayName && u.DisplayName.toLocaleLowerCase().includes(filterValue)) ||
        (!u.SuperiorId && u.Username && u.Username.toLocaleLowerCase().includes(filterValue)) ||
        (u.Login && u.Login.toLocaleLowerCase().includes(filterValue)) ||
        (u.SuperiorName && u.SuperiorName.toLocaleLowerCase().includes(filterValue)) ||
        (u.SuperiorLogin && u.SuperiorLogin.toLocaleLowerCase().includes(filterValue))
      )
    },
    maxUserTitleLength: function () {
      return this.availableUsers.map(u => this.getUserTitle(u).length)
        .reduce((p, c) => Math.max(p, c), 0)
    },
    ...mapGetters('chats', ['getChatUuid']),
    ...mapState('specialist', {
      seekers: state => state.seekers
    }),
    ...mapState('seeker', {
      specialists: state => state.specialists
    }),
    ...mapGetters(['isSeeker', 'isSpecialist']),
    ...mapGetters('user', ['getUserEmail'])
  },

  watch: {
    newChatDialog: function () {
      if (this.newChatDialog) {
        if (this.isSpecialist) this.ensureSpecialistSeekersLoaded()
        if (this.isSeeker) this.ensureSeekerSpecialistsLoaded()
      } else {
        this.userFilter = ''
      }
    }
  },

  methods: {
    ...mapActions('specialist', ['ensureSpecialistSeekersLoaded']),
    ...mapActions('seeker', ['ensureSeekerSpecialistsLoaded']),
    getUserTitle (user) {
      if (this.isSpecialist && user.SuperiorId != null) {
        return `${user.SuperiorName || user.SuperiorLogin} (${this.$t('chats.new.representative-of', {subordinate: user.DisplayName})})`
      }
      if (this.isSpecialist) {
        return user.DisplayName || user.Username || user.Login
      }
      if (this.isSeeker) {
        return user.DisplayName || user.Login
      }
    },
    getLocationOfChat (user) {
      return {
        name: 'Chat',
        params: { userUuid: this.getChatUuid(user.SuperiorLogin ?? user.Login) }
      }
    }
  }
}
</script>
