<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8">
        <template v-if="isAllowed === false && !submitting">
          <v-alert v-if="$nodeEnv.isProduction" type="error">
            {{$t('surveys.take.not_allowed')}}
          </v-alert>
          <v-alert v-if="$nodeEnv.isDevelopment" type="warning" color="amber">
            This page is only available in development mode.
          </v-alert>
        </template>
        <template v-if="isAllowed === true || $nodeEnv.isDevelopment">
          <v-alert v-if="notes != null" dense text :type="notesType">
            <span class="ws-pre-line">{{ notes.text }}</span>
          </v-alert>
          <mpro-survey-layout v-if="metadata"
                              :metadata="metadata"
                              :errors="errors">
            <template #default="{question}">
              <mpro-question :question="question"
                             v-model="answers[question.Code]"
                             :user-gender="seekerGender"/>
            </template>
            <template #grid="{code, questions, errors}">
              <mpro-question-grid :questions="questions" :errors="errors"
                                  v-model="gridAnswers[code]"
                                  @input="syncAnswersWithGrids"/>
            </template>
          </mpro-survey-layout>
          <v-skeleton-loader v-else type="article, list-item-two-line@2"/>

          <center>
            <v-btn color="MProGreen" large
                  :disabled="!metadata || submitting"
                  :loading="submitting"
                  @click="onSubmit">
              {{$t('surveys.take.submit')}}
            </v-btn>
          </center>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Lo from 'lodash'
import SurveyLayout from './survey-layout'
import Question from './question'
import QuestionGrid from './qa-grid'
import { MessageKind, SurveyAssignmentNotesKind } from '../../helpers/enums'
import UITransitions from '@/logic/UITransitions'

// TODO: Safeguard for leaving the page while not finished
//       See "window.onbeforeupload"

function initialData () {
  return {
    showErrors: false,
    answers: {},
    gridAnswers: {},
    submitting: false
  }
}

export default {
  components: {
    'mpro-survey-layout': SurveyLayout,
    'mpro-question': Question,
    'mpro-question-grid': QuestionGrid
  },
  data: () => initialData(),
  computed: {
    questionnaireCode: function () {
      return this.$route.params.questionnaireCode
    },
    isAllowed: function () {
      return this.surveyAssignments.some(sa => sa.QuestionnaireCode === this.questionnaireCode)
    },
    title: function () {
      return this.metadata?.Title ?? this.$t('surveys.take.title')
    },
    notes: function () {
      return Lo(this.surveyAssignments)
        .filter(sa => sa.QuestionnaireCode === this.questionnaireCode)
        .orderBy([sa => sa.DeadlineDate, sa => sa.AssignedAt])
        .map(sa => ({ text: sa.Notes, kind: sa.NotesKind }))
        .filter(notes => (notes.text ?? '').trim().length > 0)
        .first()
    },
    notesType: function () {
      switch (this.notes?.kind) {
        case SurveyAssignmentNotesKind.CAUTION: return MessageKind.ERROR
        default: return MessageKind.INFO
      }
    },
    errors: function () {
      let result = {}
      if (this.showErrors) {
        for (const q of this.metadata.flatQuestions) {
          if ((this.answers[q.Code] ?? '').trim() === '') {
            result[q.Code] = this.$t('surveys.take.answer_required')
          }
        }
      }
      return result
    },
    seekerGender: function () {
      return this.activeSubordinate == null
        ? this.seeker?.Gender
        : this.activeSubordinate.Gender
    },
    ...mapState('user', ['seeker']),
    ...mapState('seeker', ['surveyAssignments']),
    ...mapGetters('seeker', ['activeSubordinate']),
    ...mapGetters('specialist', ['getSeekerById']),
    ...mapGetters(['isSeeker'])
  },

  asyncComputed: {
    metadata: function () {
      return this.questionnaireCode ? this.getOrLoadMetadata(this.questionnaireCode) : Promise.resolve(null)
    }
  },

  watch: {
    metadata: function () {
      if (this.metadata) {
        this.metadata.flatQuestions.forEach(q => {
          this.$set(this.answers, q.Code, null)
        })
        this.metadata.questions.filter(q => q.isQuestionGrid).forEach(q => {
          this.$set(this.gridAnswers, q.Code, q.questions.map(_ => null))
        })
      }
    },
    questionnaireCode: function () {
      Object.assign(this.$data, initialData())
    },
    title: function () {
      this.setHeaderTitle(this.title)
    }
  },

  methods: {
    syncAnswersWithGrids () {
      for (const code in this.gridAnswers) {
        const answers = this.gridAnswers[code]
        const questionGrid = this.metadata.questions.find(q => q.isQuestionGrid && q.Code === code)
        questionGrid.questions.forEach((q, i) => {
          this.answers[q.Code] = answers[i]
        })
      }
    },
    async onSubmit () {
      this.syncAnswersWithGrids()
      this.showErrors = true
      if (Object.values(this.errors).some(v => v !== null)) {
        this.showGlobalMessage({
          text: this.$t('surveys.take.not_all_answered'),
          kind: MessageKind.WARNING
        })
        return
      }

      this.showErrors = false
      this.submitting = true
      const nextLocation = await this.doSubmit()
      this.submitting = false

      if (nextLocation != null) {
        this.$router.push(nextLocation)
      }
    },
    async doSubmit () {
      const success = await this.postCompletedSurvey({
        questionnaireCode: this.questionnaireCode,
        answers: this.answers,
        subordinateId: this.activeSubordinate?.Id
      })

      if (!success) {
        this.showGlobalMessage({
          text: this.$t('surveys.take.submit_error'),
          kind: MessageKind.ERROR
        })
        return undefined
      }

      this.showGlobalMessage(this.$t('surveys.take.submit_success'))

      // TODO: Move this logic to the backend
      if (this.questionnaireCode === 'PSFS') {
        await this.setGoalsFromPSFS()
      }

      await this.ensureSurveyAssignmentsLoaded({force: true})
      // No need to wait for surveys to reload, so no await keyword
      this.ensureSurveysLoaded({force: true})

      const tran = new UITransitions(this.$store)
      return await tran.getPushedSurveyLocation() || { name: 'MySurveys' }
    },
    async setGoalsFromPSFS () {
      let setAnyGoal = false
      for (var i = 1; i <= 3; i++) {
        if ((this.answers[`PSFS:Activity${i}`] ?? '').trim() === '') continue
        if (Number(this.answers[`PSFS:Difficulty${i}`] ?? '10') >= 10) continue

        const goal = {
          Goal: this.answers[`PSFS:Activity${i}`],
          Notes: this.$t('goals.edit-goal.created-by-psfs'),
          CreatedByPSFS: true,
          SeekerId: this.activeSubordinate?.Id ?? this.seeker.Id
        }

        try {
          await this.$store.dispatch('goals/postGoal', goal)
          setAnyGoal = true
        } catch {
        }
      }

      if (setAnyGoal) {
        this.showGlobalMessage({
          text: this.$t('goals.edit-goal.messages.success-save-goal'),
          kind: MessageKind.SUCCESS
        })
        this.ensureGoalsLoaded(true)
      }
    },
    ...mapActions(['setHeaderTitle', 'showGlobalMessage']),
    ...mapActions('surveys', ['getOrLoadMetadata', 'postCompletedSurvey']),
    ...mapActions('seeker', ['ensureSurveysLoaded', 'ensureSurveyAssignmentsLoaded', 'ensureGoalsLoaded'])
  },

  created () {
    if (!this.isSeeker) {
      this.$router.push({ name: 'Dashboard' })
      return
    }

    this.ensureSurveyAssignmentsLoaded()
  },
  mounted () {
    this.setHeaderTitle(this.title)
  }
}
</script>
