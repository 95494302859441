import { MomentInput } from 'moment'

export interface OrganizationBase {
  Code: string,
  Name: string,
  Offers3DService: boolean
}

export interface Organization extends OrganizationBase {
  Description: string,
  CountryCode: string,
  ActiveSeekerCount: number,
  SpecialistCount: number,
  SupportedLanguages: string[]
}

export interface Library {
  Id: string,
  Name: string,
  OwnerName: string
}

export enum SharedDataKind {
  // Mobile scans & comments to them
  Scans2D = 'Scans2D',
  // Surveys and survey assignments
  Surveys = 'Surveys',
  // Seeker goals & exercise programs
  GoalsAndExercises = 'GoalsAndExercises',
  // 3D scans & comments to them
  Scans = 'Scans'
}

export enum DataSharingStatus {
  New = 'New',
  Active = 'Active'
}

export enum DataSharingSource {
  Self = 'Self',
  System = 'System',
  Widget = 'Widget',
  PostScan = 'PostScan'
}

export interface DataSharing {
  OrganizationCode: string,
  OrganizationName: string,
  OrganizationOffers3DService: boolean,
  Scope: SharedDataKind[],
  Status: DataSharingStatus
}

export interface ChangeDataSharingResult {
  showBackupNotice: boolean,
  backupPeriodDays: number,
  wasRevoked?: boolean
}

export interface SharingSeeker extends DataSharing {
  SeekerId: string,
  SeekerName?: string,
  SeekerEmail?: string,
  GrantedAt: MomentInput,
  Source: DataSharingSource,
  Notes?: string,
  NotesReceivedByLogin?: string,
  NotesReceivedByName?: string,
  LibraryIds: string[]
}
