



































































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { KneeShapesSquat, AssessmentName, MovementKind as MovementKind_, movementKindToAssessmentName } from '../../helpers/enums'
import NarrativeIndicator from '../scans/narrative-indicator.vue'

enum MovementKind {
  StandStillFaceView = 'StandStillFaceView',
  SideBend = 'SideBend',
  Squat = 'Squat',
  SquatRight = 'SquatRight',
  SquatLeft = 'SquatLeft'
}

export default Vue.extend({
  components: {
    'mpro-indicator': NarrativeIndicator
  },

  props: {
    scanId: String,
    movementId: String,
    movementKind: String as PropType<MovementKind>,
    bodyHeightCm: Number
  },

  data: () => {
    return {
      narrativeText: null
    }
  },

  computed: {
    ...mapGetters('user', ['isMetric']),
    movementKind_: function (): string | undefined {
      switch (this.movementKind) {
        case MovementKind.StandStillFaceView: return MovementKind_.STAND_STILL
        case MovementKind.SideBend: return MovementKind_.SIDE_BEND
        case MovementKind.Squat: return MovementKind_.SQUAT
        case MovementKind.SquatRight: return MovementKind_.SQUAT_RIGHT
        case MovementKind.SquatLeft: return MovementKind_.SQUAT_LEFT
        default: return undefined
      }
    },
    movement (): string | null {
      return movementKindToAssessmentName(this.movementKind_)
    },
    sideSingleSquat () {
      if (this.movement === AssessmentName.SQUAT_LEFT) return 'left'
      if (this.movement === AssessmentName.SQUAT_RIGHT) return 'right'
      return ''
    },
    sideSingleBalance () {
      if (this.movement === AssessmentName.BALANCE_LEFT) return 'left'
      if (this.movement === AssessmentName.BALANCE_RIGHT) return 'right'
      return ''
    },
    sideSingleSquatCap () {
      if (this.movement === AssessmentName.SQUAT_LEFT) return 'Left'
      if (this.movement === AssessmentName.SQUAT_RIGHT) return 'Right'
      return ''
    },
    kneeShapesSquat () {
      return KneeShapesSquat
    }
  },

  asyncComputed: {
    narrativeText: function () {
      return this.getOrLoadMovementNarrative({
        scanId: this.scanId,
        movementId: this.movementId
      })
    }
  },

  methods: {
    ...mapActions('scans2d', ['getOrLoadMovementNarrative'])
  }
})
