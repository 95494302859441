<template>
  <div>
      <mpro-label :value="label"/>
      <v-switch v-if="required" class="mt-0"
                :input-value="value" :readonly="readonly"
                :label="switchLabel" :hint="hint" persistent-hint
                @change="onChange" />
      <v-radio-group v-else row class="mt-0"
                     :value="radioValue" @change="onRadioChange"
                     :readonly="readonly" :hint="hint" persistent-hint>
        <v-radio :label="$t('forms.not_set')" :value="0" />
        <v-radio :label="$t('forms.no')" :value="1" />
        <v-radio :label="$t('forms.yes')" :value="2" />
      </v-radio-group>
  </div>
</template>

<script>
import Label from './label'

export default {
  components: {
    'mpro-label': Label
  },

  props: {
    value: Boolean,
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: true },
    label: String,
    hint: String
  },

  computed: {
    switchLabel: function () {
      return this.value === true ? this.$t('forms.yes')
        : this.value === false ? this.$t('forms.no')
          : ''
    },
    radioValue: function () {
      return this.value === true ? 2 : this.value === false ? 1 : 0
    }
  },

  methods: {
    onChange (newValue) {
      this.$emit('input', newValue)
    },
    onRadioChange (newValue) {
      this.$emit('input', newValue === 2 ? true : newValue === 1 ? false : null)
    }
  }
}
</script>
