import { Store } from 'vuex'
import { Location } from 'vue-router'
import { SurveyAssignment } from '@/models/surveys'

export default class UITransitions {
  private store: Store<unknown>

  public constructor (store: Store<unknown>) {
    this.store = store
  }

  public async getPushedSurveyLocation (): Promise<Location | undefined> {
    if (this.store.getters['isSeeker'] !== true) return undefined

    await this.store.dispatch('seeker/ensureSurveyAssignmentsLoaded', { force: true })
    const assignment: SurveyAssignment | undefined = this.store.getters['seeker/pushedSurveyAssignment']
    if (assignment == null) return undefined

    return {
      name: 'TakeSurvey',
      params: {
        questionnaireCode: assignment.QuestionnaireCode
      }
    }
  }
}
