import axios from 'axios'
import Lo from 'lodash'
import Cache from 'ttl-cache'
import moment from 'moment'
import getUuid from 'uuid-by-string'
import { handleAxiosError } from '@/helpers/errors'

function getChatUuid (partnerLogin) {
  const salt = 'sO3VOzjB9otKVa6NmjZI'
  return getUuid(salt + partnerLogin, 5)
}

// initial state
const state = {
  partners: [],
  messages: {},
  canChat: {}
}

const requestCache = new Cache({
  ttl: process.env.VUE_APP_COMMENTS_LIFETIME / 1000,
  interval: 0
})

// getters
const getters = {
  getUnreadCount: state => Lo.sumBy(state.partners, cp => cp.UnreadCount),
  getMessagesFor: state => partnerLogin => state.messages[partnerLogin],
  canChatWith: state => partnerLogin => state.canChat[partnerLogin],
  getChatUuid: _ => getChatUuid,
  getPartnerByUuid: state => uuid => state.partners.find(p => getChatUuid(p.Login) === uuid)
}

const RequestUrls = {
  CHATS: '/chats',
  chat: partnerLogin => `${RequestUrls.CHATS}/${encodeURIComponent(partnerLogin)}`,
  chatMessages: partnerLogin => `${RequestUrls.chat(partnerLogin)}/messages`,
  readChat: partnerLogin => `${RequestUrls.chat(partnerLogin)}/read`
}

// actions
const actions = {
  ensurePartnersLoaded: async ({ commit }, payload) => {
    const { force } = payload ?? {}
    if (requestCache.get(RequestUrls.CHATS) != null && force !== true) return

    let response
    try {
      response = await axios.get(RequestUrls.CHATS)
    } catch (e) {
      console.warn('ensurePartnersLoaded:axios', e)
      return
    }

    if (Array.isArray(response.data)) {
      commit('storePartners', response.data)
      requestCache.set(RequestUrls.CHATS, true)
    }
  },
  ensureMessagesLoaded: async ({ commit }, payload) => {
    const { partnerLogin, force } = payload
    const url = RequestUrls.chatMessages(partnerLogin)
    if (requestCache.get(url) != null && force !== true) return

    let response
    try {
      response = await axios.get(url)
    } catch (e) {
      console.warn('ensureMessagesLoaded:axios', e)
      return
    }

    if (Array.isArray(response.data)) {
      commit('storeMessages', {
        partnerLogin,
        messages: response.data
      })
      requestCache.set(url, true)
    }
  },
  markRead: async (_, author) => {
    try {
      await axios.post(RequestUrls.readChat(author), '')
    } catch (e) {
      console.warn('markRead:axios', e)
    }
  },
  postChatMessage: async ({ rootGetters }, { recipient, message, messageLink }) => {
    try {
      const body = {
        message,
        fromSeeker: rootGetters['isSeeker']
      }
      if (messageLink != null) {
        body.links = [{
          num: 1,
          kind: messageLink.type,
          objectId: messageLink.objectId
        }]
      }
      await axios.post(RequestUrls.chatMessages(recipient), body)
    } catch (e) {
      console.warn('postChatMessage:axios', e)
      handleAxiosError(e)
    }
  },
  updateCanChatWith: async ({ rootGetters, commit }, payload) => {
    const { partnerLogin, force } = payload
    const key = `canChat:${partnerLogin}`
    if (requestCache.get(key) === true && force !== true) return

    let result = null
    try {
      const body = {
        message: 'test',
        fromSeeker: rootGetters['isSeeker']
      }
      await axios.post(RequestUrls.chatMessages(partnerLogin), body, {
        params: { test: true }
      })
      result = true
    } catch (e) {
      if (e?.response?.status >= 400 && e?.response?.status < 500) {
        result = false
      } else {
        // assume a transient error
        return true
      }
    }

    commit('storeCanChat', { partnerLogin, canChat: result })
    requestCache.set(key, true)
  }
}

// mutations
const mutations = {
  storePartners: (state, value) => {
    state.partners = value
  },
  storeMessages: (state, payload) => {
    const { partnerLogin, messages } = payload
    state.messages[partnerLogin] = Lo.orderBy(messages, m => moment(m.CreatedAt).valueOf())
  },
  storeCanChat: (state, payload) => {
    const { partnerLogin, canChat } = payload
    state.canChat[partnerLogin] = canChat
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
