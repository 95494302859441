





import Vue, { PropType } from 'vue'
import { SharedDataKind } from '@/models/data-sharing'

export default Vue.extend({
  props: {
    scope: { type: Array as PropType<SharedDataKind[]>, default: [] },
    dataKind: String as PropType<SharedDataKind>
  },

  computed: {
    checked: function (): boolean {
      return this.scope.includes(this.dataKind)
    }
  },

  methods: {
    onEdit (): void {
      this.$emit('edit')
    }
  }
})
