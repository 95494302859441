<template>
  <v-container fluid class="px-6">
    <v-row>
      <v-col v-for="c in categories" :key="c"
             cols="12" sm="6" md="4" lg="3" xl="2">
        <mpro-editable-card :save-callback="() => saveCategorySettings(c)"
                            @end-edit="onEndCategoryEdit">
          <template #title>{{ categoryTitle(c) }}</template>
          <template #default="{ readonly }">
            <v-form :ref="c">
              <mpro-setting-editor v-for="s in itemsOfCategory(c)" :key="s.Setting"
                                   v-model="values[s.Setting]"
                                   :metadata="s.metadata" :readonly="readonly"
                                   :source-hint="getSourceHint(s)"
                                   :class="readonly ? null : 'mb-3'" />
            </v-form>
          </template>
        </mpro-editable-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Lo from 'lodash'
import SettingEditor from './editor'
import EditableCard from '../../elements/editable-card'
import { MessageKind } from '../../../helpers/enums'

const Categories = {
  INFORMATION: 'Information',
  PRIVACY_POLICY: 'PrivacyPolicy',
  REPORTING: 'Reporting',
  SCAN: 'Scan',
  VISUALIZATION: 'Visualization'
}

export default {
  components: {
    'mpro-setting-editor': SettingEditor,
    'mpro-editable-card': EditableCard
  },

  props: {
    settings: { type: Array, default: () => [] },
    organizationCode: String,
    libraryId: String
  },

  data: () => ({
    values: {},
    originalValues: {}
  }),

  computed: {
    items: function () {
      const settings = (this.settings || [])
      // Prefer order of settings defined by metadata
      let result = this.settingsMetadata
        .map(m => {
          var setting = settings.find(s => s.Setting === m.code)
          return setting == null ? {
            Setting: m.code,
            Value: m.defaultValue,
            HasBinaryData: false,
            Source: 'Default',
            metadata: m
          } : {
            ...setting,
            metadata: m
          }
        })
      // Add settings without metadata, ordered by name
      const unknown = Lo(settings)
        .filter(s => !result.some(i => i.Setting === s.Setting))
        .sortBy(s => s.Setting)
        .value()
      unknown.forEach(s => {
        result.push({
          ...s,
          metadata: {
            code: s.Setting,
            category: 'Unknown',
            defaultValue: null,
            type: { typeName: 'Text' }
          }
        })
      })

      // For libraries, remove settings of the Information category
      if (this.libraryId != null) {
        result = result.filter(s => s.metadata.category !== Categories.INFORMATION)
      }

      return result
    },
    categories: function () {
      const categoriesOrder = [
        Categories.INFORMATION, Categories.PRIVACY_POLICY, Categories.REPORTING,
        Categories.VISUALIZATION, Categories.SCAN
      ]
      const result = [...new Set(this.items.map(s => s.metadata.category))]
      return Lo.sortBy(result, c => (categoriesOrder.indexOf(c) + 1) ?? Number.MAX_VALUE)
    },
    noRecords: function () {
      return this.items.length === 0
    },
    ...mapState('admin', {
      settingsMetadata: state => state.settingsMetadata ?? []
    })
  },

  watch: {
    items: function () {
      this.updateValuesFromItems()
      this.fetchImageSettings()
    },
    organizationCode: function () {
      this.fetchImageSettings()
    },
    libraryId: function () {
      this.fetchImageSettings()
    }
  },

  methods: {
    categoryTitle (category) {
      const translationKey = 'admin.settings.categories.' + category
      return this.$te(translationKey) ? this.$t(translationKey) : category
    },
    itemsOfCategory (category) {
      return this.items.filter(s => s.metadata.category === category)
    },

    async fetchImageSettings () {
      const imageItems = this.items.filter(s => s.metadata.type.typeName === 'Image')
      const promises = imageItems.map(async s => {
        if (s.HasBinaryData) {
          let blob
          switch (s.Source) {
            case 'Organization':
              blob = this.organizationCode == null
                ? null
                : await this.loadOrganizationBinarySetting({
                  organizationCode: this.organizationCode,
                  settingCode: s.Setting
                })
              break
            case 'Library':
              blob = this.libraryId == null
                ? null
                : await this.loadLibraryBinarySetting({
                  libraryId: this.libraryId,
                  settingCode: s.Setting
                })
              break
          }
          const valueUnchanged = this.originalValues != null && this.values != null &&
            this.values[s.Setting] === this.originalValues[s.Setting]
          if (blob != null && s.Value != null) {
            const file = new File([blob], s.Value)
            this.$set(this.originalValues, s.Setting, file)
            if (valueUnchanged) this.$set(this.values, s.Setting, file)
          } else if (blob == null) {
            this.$set(this.originalValues, s.Setting, s.Value)
            if (valueUnchanged) this.$set(this.values, s.Setting, s.Value)
          }
        }
      })
      await Promise.all(promises)
    },

    async saveCategorySettings (category) {
      if (this.libraryId == null && this.organizationCode == null) return false

      const form = this.$refs[category][0]
      if (form?.validate() !== true) return false

      const updatedItems = this.itemsOfCategory(category)
        .filter(s => this.values[s.Setting] !== this.originalValues[s.Setting])
      if (updatedItems.length === 0) {
        // no changes were made
        this.showGlobalMessage({
          kind: MessageKind.INFO,
          text: this.$t('forms.no_changes')
        })
        return { success: true, showSuccessMessage: false }
      }

      let errors = {}
      for (const s of updatedItems) {
        try {
          if (this.libraryId != null) {
            await this.changeLibrarySetting({
              libraryId: this.libraryId,
              settingCode: s.Setting,
              value: this.values[s.Setting]
            })
          } else {
            await this.changeOrganizationSetting({
              organizationCode: this.organizationCode,
              settingCode: s.Setting,
              value: this.values[s.Setting]
            })
          }
        } catch (e) {
          errors[s.Setting] = e
        }
      }

      const failedUpdates = Object.keys(errors)
      if (failedUpdates.length === updatedItems.length) {
        // all updates failed
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(errors[failedUpdates[0]])
        })
        return false
      } else if (failedUpdates.length > 0) {
        // some updates failed
        this.showGlobalMessage({
          kind: MessageKind.WARNING,
          text: this.$t('admin.settings.partial_update', { settings: failedUpdates.join('\n') })
        })
        return { success: true, showSuccessMessage: false }
      } else {
        // all updates succeeded
        return true
      }
    },

    onEndCategoryEdit (saved) {
      if (saved) {
        this.$emit('update')
      } else {
        for (const s in this.originalValues) {
          this.$set(this.values, s, this.originalValues[s])
        }
      }
    },

    updateValuesFromItems () {
      this.items.forEach(s => {
        this.$set(this.values, s.Setting, this.getTypedValue(s))
      })
      this.originalValues = {...this.values}
    },

    getTypedValue (s) {
      const v = s.Value
      switch (s.metadata?.type?.typeName) {
        case 'Boolean':
          if (v === true || v === 1) return true
          if (v === false || v === 0) return false
          const sv = String(v).toLowerCase()
          if (sv === 'true' || sv === 'yes') return true
          if (sv === 'false' || sv === 'no') return false
          return null
        default: return v
      }
    },
    getSourceHint (s) {
      if (s.Value == null) return null

      switch (s.Source) {
        case 'None':
        case 'Default':
          return this.$t('admin.settings.default_value')
        case 'Organization':
          if (this.libraryId != null) return this.$t('admin.settings.inherited_from_organization')
          break
      }

      return null
    },
    ...mapActions(['showGlobalMessage']),
    ...mapActions('admin', [
      'ensureSettingsMetadataLoaded',
      'loadOrganizationBinarySetting', 'loadLibraryBinarySetting',
      'changeOrganizationSetting', 'changeLibrarySetting'])
  },

  created () {
    this.ensureSettingsMetadataLoaded()
    this.updateValuesFromItems()
    this.fetchImageSettings()
  }
}
</script>
