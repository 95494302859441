<template>
  <div>
    <v-btn @click="dialog=!dialog" class="MProGreen mb-10" large>{{ $t('surveys.assign.buttons.new-survey') }}</v-btn>
    <v-dialog v-model="dialog" scrollable width="1000">
      <v-card class="MPro-bg">
        <v-card-title>
          <v-container class="pa-0 ma-0">
            <v-row class="pa-0 ma-0">
              <v-col cols="11">
                <div>{{ $t('surveys.assign.title', {name: seekerName}) }}</div>
              </v-col>
              <v-col cols="1">
                <p class="text-right">
                  <v-btn
                    icon
                    @click="dialog=!dialog"
                  >
                    <v-icon>$close</v-icon>
                  </v-btn>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-autocomplete v-model="survey.QuestionnaireCode"
                      :items="questionnaires" item-text="Title" item-value="Code" outlined
                      :placeholder="$t('surveys.assign.fields.survey-name-placeholder')" persistent-placeholder
                      :label="$t('surveys.assign.fields.survey-name')"
                      :error-messages="$validationErrors($v.survey.QuestionnaireCode)"
                      @change="$v.survey.QuestionnaireCode.$touch()"
                      @blur="$v.survey.QuestionnaireCode.$touch()"/>
                </v-col>
                <v-col cols="12" sm="6">
                  <mpro-date-box
                    :label="$t('surveys.assign.fields.deadline')"
                    :placeholder="$t('surveys.assign.fields.deadline-placeholder')" persistent-placeholder
                    :outlined="true"
                    :min="new Date()"
                    :required="true"
                    v-model="survey.Deadline"
                    :error-messages="$validationErrors($v.survey.Deadline)"
                    @change="$v.survey.Deadline.$touch()"
                    @blur="$v.survey.Deadline.$touch()"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea :label="$t('surveys.assign.fields.notes')"
                              :placeholder="$t('surveys.assign.fields.notes-placeholder')" persistent-placeholder
                              type="text" v-model="survey.Notes" outlined/>
                </v-col>
              </v-row>
              <div class="text-right">
                  <v-btn @click="assignSurvey" class="ma-2 MProGreen">
                    {{ $t('surveys.assign.buttons.assign-to', {name: seekerName}) }}
                  </v-btn>
                </div>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Lo from 'lodash'
import DateBox from '../elements/date-box'
import Utils from '@/helpers/utils'

export default {
  components: {
    'mpro-date-box': DateBox
  },

  props: ['seekerId'],

  data: () => {
    return {
      dialog: false,
      survey: {
        Deadline: null,
        QuestionnaireCode: null,
        Notes: null
      }
    }
  },

  validations: {
    survey: {
      QuestionnaireCode: { required },
      Deadline: { required }
    }
  },

  computed: {
    ...mapState({
      availableSurveys: state => state.surveys.availableSurveys
    }),
    ...mapGetters('specialist', ['getSeekerById']),
    seeker: function () {
      return this.getSeekerById(this.seekerId)
    },
    seekerName: function () {
      return this.seeker && (this.seeker.DisplayName || this.seeker.Username)
    },
    questionnaires: function () {
      return Lo.orderBy(this.availableSurveys, q => q.Title)
    }
  },

  methods: {
    ...mapActions(['showGlobalMessage']),
    ...mapActions('surveys', ['getOrLoadAvailableSurveys', 'assignSurveyToSeeker']),
    ...mapActions('specialist', ['ensureSurveyAssignmentsLoaded']),
    ...mapMutations('specialist', ['updateLastAssignedSurveyAt']),
    async assignSurvey () {
      this.$v.survey.$touch()
      if (this.$v.survey.$invalid) return

      this.survey.SeekerId = this.seekerId
      const s = {SeekerId: this.seekerId, ...this.survey}
      s.Deadline = Utils.toIsoDate(s.Deadline)
      let res = await this.assignSurveyToSeeker(s)
      if (res) {
        this.showGlobalMessage({
          text: this.$t('surveys.assign.messages.succes'),
          kind: 'success'
        })
        this.survey = {
          Deadline: null,
          QuestionnaireCode: null,
          Notes: null
        }
        this.dialog = false

        this.ensureSurveyAssignmentsLoaded({ force: true })
        this.updateLastAssignedSurveyAt(this.seekerId)

        this.$emit('assign')
      } else {
        this.showGlobalMessage({
          text: this.$t('surveys.assign.messages.failed'),
          kind: 'error'
        })
      }
    }
  },

  created () {
    this.getOrLoadAvailableSurveys()
  }
}
</script>
