<template>
  <!-- This is a non-visual component.
       It just handles setting of header elements for
       specialist pages containing a seeker's data.  -->
  <div class="d-none"/>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    tabName: String,
    seeker: Object
  },

  computed: {
    ...mapGetters(['isSpecialist'])
  },

  watch: {
    seeker: function () {
      this.updateHeader()
    }
  },

  methods: {
    updateHeader () {
      if (this.isSpecialist && this.seeker != null) {
        this.setHeaderTabs({tabs: ['profile', 'exercises', 'goals', 'scans', 'surveys'], seekerId: this.seeker.Id})
        this.setActiveTab(this.tabName)
        const title = {
          text: this.seeker.DisplayName ?? this.seeker.Username
        }
        if (this.seeker.SuperiorName != null) {
          title.additionalText = this.$t('specialist.seekers.subordinate_of', { name: this.seeker.SuperiorName })
        }
        this.setHeaderTitle(title)
      }
    },
    ...mapActions(['setHeaderTitle', 'setHeaderTabs', 'setActiveTab'])
  },

  mounted () {
    this.updateHeader()
  },
  beforeDestroy () {
    this.setHeaderTabs()
    this.setHeaderTitle()
  }
}
</script>
