import moment from 'moment'

export interface SeekerBasicInfo {
  Id: string,
  FirstName: string,
  LastName: string
  SuperiorId?: string,
  DisplayName?: string,
  Email?: string
}

export enum SeekerToSpecialistStatus {
  WaitingForAdvice = 'WaitingForAdvice',
  ActiveContact = 'ActiveContact',
  RecentlyJoined = 'RecentlyJoined'
}

export interface SeekerToSpecialist extends SeekerBasicInfo {
  LibraryIds: string[],
  LastJoinedAt: string,
  LastScanCapturedAt?: string,
  LastWaitingForAdviceAt?: string,
  LastChattedToAt?: string,
  LastAssignedProgramAt?: string,
  LastAssignedSurveyAt?: string,
  LastCommentedScanAt?: string
}

function isActiveContact (seeker: SeekerToSpecialist): boolean {
  const actedOnSeeker = seeker.LastAssignedProgramAt != null ||
    seeker.LastAssignedSurveyAt != null ||
    seeker.LastCommentedScanAt != null
  if (actedOnSeeker) return true

  if (seeker.LastWaitingForAdviceAt != null && seeker.LastChattedToAt != null) {
    // Chatted after was requested an advice
    // (simple check on chat cannot distinguish a subordinate from superior seeker)
    return moment(seeker.LastChattedToAt).diff(seeker.LastWaitingForAdviceAt) > 0
  }

  return false
}

function isWaitingForAdvice (seeker: SeekerToSpecialist): boolean {
  return seeker.LastWaitingForAdviceAt != null &&
    (seeker.LastChattedToAt == null ||
      moment(seeker.LastWaitingForAdviceAt).diff(seeker.LastChattedToAt) > 0)
}

function isRecentlyJoined (seeker: SeekerToSpecialist): boolean {
  return moment().diff(seeker.LastJoinedAt, 'days', true) < 7
}

export function getSeekerToSpecialistStatus (seeker: SeekerToSpecialist): SeekerToSpecialistStatus | null {
  if (isWaitingForAdvice(seeker)) return SeekerToSpecialistStatus.WaitingForAdvice
  else if (isActiveContact(seeker)) return SeekerToSpecialistStatus.ActiveContact
  else if (isRecentlyJoined(seeker)) return SeekerToSpecialistStatus.RecentlyJoined
  else return null
}

export type SubordinateInfo = SeekerBasicInfo

export interface SeekerInfo extends SeekerBasicInfo {
  subordinates: SubordinateInfo[]
}

export class UserLogin {
  public static readonly System: string = 'system@moovment.pro'
}

export interface SpecialistBasicInfo {
  Login: string,
  DisplayName: string
}

export enum UserMfaMode {
  None = 'None',
  Totp = 'Totp'
}
