<template>
  <v-autocomplete :items="items"
                  :value="value" v-on="$listeners"
                  :required="required" :error-messages="errorMessages"
                  :label="label" :hint="hint"
                  :readonly="readonly" :hide-details="hideDetails" :disabled="disabled"
                  :menu-props="{maxHeight: '75vh'}"
                  :no-data-text="$t('forms.nothing_found')"/>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: String,
    label: String,
    hint: String,
    required: String,
    errorMessages: Array,
    readonly: Boolean,
    hideDetails: Boolean,
    disabled: Boolean
  },
  computed: {
    items: function () {
      let result = Object.keys(this.$isoCountries.getAlpha3Codes())
        .filter(code => this.validCountryCodes.includes(code))
        .map(code => ({
          value: code,
          text: this.$isoCountries.getName(code, this.$i18n.locale) ?? this.$isoCountries.getName(code, 'en')
        }))
      result.sort((a, b) => a.text.localeCompare(b.text))
      return result
    },
    ...mapState(['validCountryCodes'])
  }
}
</script>
