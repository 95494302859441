<template>
  <mpro-editable-card class="min-card-width"
                      :validate-callback="validate" :save-callback="save"
                      :begin-edit-when-created="isCreate"
                      @end-edit="onEndEdit">
    <template #title>{{title}}</template>
    <template #default="{readonly}">
      <v-form>
        <v-text-field :label="$t('person_profile.first_name')" v-model.trim="seekerData.firstName"
                      :readonly="readonly" :hide-details="readonly"
                      @input="$v.seekerData.firstName.$touch()" @blur="$v.seekerData.firstName.$touch()"
                      :error-messages="$validationErrors($v.seekerData.firstName)"/>
        <v-text-field :label="$t('person_profile.last_name')" v-model.trim="seekerData.lastName"
                      :readonly="readonly" :hide-details="readonly"
                      @input="$v.seekerData.lastName.$touch()" @blur="$v.seekerData.lastName.$touch()"
                      :error-messages="$validationErrors($v.seekerData.lastName)"/>
        <v-select :label="$t('person_profile.gender')" v-model="seekerData.gender"
                  :readonly="readonly" :hide-details="readonly"
                  :items="genderItems"
                  @input="$v.seekerData.gender.$touch()" @blur="$v.seekerData.gender.$touch()"
                  :error-messages="$validationErrors($v.seekerData.gender)"/>
        <mpro-date-box :label="$t('person_profile.birth_date')" v-model="seekerData.birthDate"
                       :readonly="readonly" :hide-details="readonly"
                       :birthdayPicker="true" :max="new Date()"
                       @input="$v.seekerData.birthDate.$touch()" @blur="$v.seekerData.birthDate.$touch()"
                       :error-messages="$validationErrors($v.seekerData.birthDate)"/>
        <v-textarea :label="$t('person_profile.about')" :hint="$t('person_profile.about_hint')"
                    v-model.trim="seekerData.about" :readonly="readonly" :hide-details="readonly"
                    @input="$v.seekerData.about.$touch()" @blur="$v.seekerData.about.$touch()"
                    :error-messages="$validationErrors($v.seekerData.about)"/>
      </v-form>
    </template>
  </mpro-editable-card>
</template>

<script>
import EditableCard from '@/components/elements/editable-card'
import DateBox from '@/components/elements/date-box'
import {required} from 'vuelidate/lib/validators'
import {Gender, MessageKind} from '@/helpers/enums'
import Utils from '@/helpers/utils'
import { mapActions } from 'vuex'

export default {
  components: {
    'mpro-editable-card': EditableCard,
    'mpro-date-box': DateBox
  },

  props: {
    subordinate: Object
  },

  data: () => ({
    seekerData: {
      firstName: '',
      lastName: '',
      gender: '',
      birthDate: null
    },
    createdId: null
  }),

  validations: {
    seekerData: {
      firstName: {required},
      lastName: {required},
      gender: {required},
      birthDate: {required},
      about: {}
    }
  },

  computed: {
    isCreate: function () {
      return this.subordinate == null
    },
    genderItems: function () {
      return Object.values(Gender).map(g => ({value: g, text: this.$t('enums.Gender.' + g)}))
    },
    title: function () {
      return this.isCreate ? this.$t('account.new_sub_title') : this.subordinate.FirstName
    }
  },

  watch: {
    subordinate: function () {
      this.subordinateToSeekerData()
    }
  },

  methods: {
    subordinateToSeekerData () {
      this.seekerData.firstName = this.subordinate && this.subordinate.FirstName
      this.seekerData.lastName = this.subordinate && this.subordinate.LastName
      this.seekerData.gender = this.subordinate && this.subordinate.Gender
      const birthDate = this.subordinate && this.subordinate.BirthDate
      this.seekerData.birthDate = birthDate ? Utils.parseIsoDate(birthDate) : null
      this.seekerData.about = this.subordinate && this.subordinate.About
    },
    validate () {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    async save () {
      try {
        const profile = {
          ...this.seekerData,
          birthDate: Utils.toIsoDate(this.seekerData.birthDate)
        }
        if (this.isCreate) {
          const result = await this.createSubordinateSeeker({profile})
          console.log('created', result)
          this.createdId = result.subordinateId
        } else {
          await this.updateSubordinateSeeker({subordinateId: this.subordinate.Id, profile})
        }
        return true
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e)
        })
        return false
      }
    },
    async onEndEdit (saved) {
      if (saved) {
        await this.ensureUserDataLoaded({force: true})
        this.$emit('save', this.createdId || (this.subordinate && this.subordinate.Id))
      } else {
        this.$emit('cancel')
      }
    },
    ...mapActions(['showGlobalMessage']),
    ...mapActions('user', ['createSubordinateSeeker', 'updateSubordinateSeeker', 'ensureUserDataLoaded'])
  },

  created () {
    if (this.subordinate) {
      this.subordinateToSeekerData()
    }
  }

}
</script>
