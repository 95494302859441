// Adds $isoCountries prop containing a configured
// Ensure Vue.use for this plugin is invoked after vue-i18n

import languages from '@cospired/i18n-iso-languages'
import en from '@cospired/i18n-iso-languages/langs/en.json'
import de from '@cospired/i18n-iso-languages/langs/de.json'
import sv from '@cospired/i18n-iso-languages/langs/sv.json'

export default {
  install: function (Vue) {
    languages.registerLocale(en)
    languages.registerLocale(de)
    languages.registerLocale(sv)
    Vue.prototype.$isoLanguages = languages
  }
}
