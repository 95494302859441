




















































import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import EditableCard from '@/components/elements/editable-card.vue'
import DeleteDialog from '@/components/elements/delete-dialog.vue'
import OrganizationDetails from './details.vue'
import {MessageKind} from '@/helpers/enums'
import { TranslateResult } from 'vue-i18n'
import { ListItem } from '@/models/vuetify'

const WidgetPosition = {
  LEFT: 'left',
  RIGHT: 'right'
}

const WidgetTheme = {
  DARK: 'dark',
  LIGHT: 'light'
}

interface OrganizationDetailsRef {
  validate: () => boolean,
  reset: () => void,
  data: unknown
}

export default Vue.extend({
  components: {
    'mpro-editable-card': EditableCard,
    'mpro-delete-dialog': DeleteDialog,
    'mpro-organization-details': OrganizationDetails
  },

  props: {
    organization: Object,
    isVisible: Boolean
  },

  data: () => ({
    deleting: false,
    previewWidget: true,
    refreshingWidget: false,
    widgetPosition: WidgetPosition.LEFT,
    widgetTheme: WidgetTheme.DARK,
    widgetLocale: null as string | null
  }),

  computed: {
    ...mapState({
      // eslint-disable-next-line
      widgetLocales: (state: any): string[] => state.widgetLocales
    }),
    loading: function (): boolean {
      return this.organization == null
    },
    detailsRef: function (): OrganizationDetailsRef {
      return this.$refs.details as unknown as OrganizationDetailsRef
    },
    deleteMessage: function (): TranslateResult | null {
      return this.loading
        ? null
        : this.$t('admin.delete_organization_warning', {name: this.organization.Name})
    },
    widgetAvailable: function (): boolean {
      return this.organization?.WidgetAvailable === true
    },
    widgetScriptUrl: function (): string | undefined {
      if (!this.widgetAvailable) return undefined

      let result = `${process.env.VUE_APP_WIDGETS_BASE_URL}/connect-with-organization` +
        `?embed=script&code=${encodeURIComponent(this.organization.Code)}` +
        `&position=${encodeURIComponent(this.widgetPosition)}` +
        `&theme=${encodeURIComponent(this.widgetTheme)}`
      if (this.widgetLocale != null) {
        result += `&locale=${encodeURIComponent(this.widgetLocale)}`
      }
      return result
    },
    widgetScriptTag: function (): string {
      return this.widgetAvailable
        // If the angle bracket is put directly into the string
        // then we've got an error "Unterminated template literal".
        // So we have to cheat a bit to work the issue around.
        ? `<script src="${this.widgetScriptUrl}" async>${'<'}/script>`
        : ''
    },
    widgetInstructionHtml: function (): TranslateResult {
      return this.widgetAvailable
        ? this.$t('admin.organization.connection-widget.instruction-copy-html')
        : this.$t('admin.organization.connection-widget.instruction-request-html')
    },
    shouldDisplayWidget: function (): boolean {
      return this.widgetScriptUrl != null && this.isVisible && this.previewWidget
    },
    widgetPositionItems: function (): ListItem[] {
      return Object.values(WidgetPosition).map(k => ({
        value: k,
        text: this.$t(`admin.organization.connection-widget.option-position-items.${k}`)
      }))
    },
    widgetThemeItems: function (): ListItem[] {
      return Object.values(WidgetTheme).map(k => ({
        value: k,
        text: this.$t(`admin.organization.connection-widget.option-theme-items.${k}`)
      }))
    },
    widgetLocaleItems: function (): ListItem[] {
      const result = this.widgetLocales.map(l => ({
        value: l,
        text: this.$isoLanguages.getName(l, this.$i18n.locale) ?? l
      } as ListItem))
      result.unshift({ value: null, text: this.$t('admin.organization.connection-widget.option-locale-items.auto') })
      return result
    }
  },

  watch: {
    shouldDisplayWidget: function () {
      this.refreshWidgetSample()
    },
    widgetScriptUrl: function () {
      this.refreshWidgetSample()
    }
  },

  methods: {
    async save () {
      if (!this.detailsRef.validate()) return false

      try {
        await this.updateOrganization(this.detailsRef.data)
        return true
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e)
        })
        return false
      }
    },
    async onEndEdit (saved: boolean) {
      if (saved) {
        this.ensureOrganizationsLoaded({force: true})
        this.$emit('update')
      } else {
        this.detailsRef.reset()
      }
    },
    async onDeleteConfirm () {
      try {
        await this.deleteOrganization(this.organization.Code)
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e)
        })
        return
      } finally {
        this.deleting = false
      }

      this.showGlobalMessage({
        kind: MessageKind.SUCCESS,
        text: this.$t('forms.save_changes_success')
      })
      this.ensureOrganizationsLoaded({force: true})
      this.$router.push({name: 'admin:Organizations'})
    },
    removeWidgetSample () {
      // Remove all elements with that id
      // There may be occasional duplicates
      let sample
      while ((sample = document.getElementById('organization-widget-sample')) != null) {
        sample.remove()
      }
    },
    async refreshWidgetSample () {
      if (this.refreshingWidget) return

      this.refreshingWidget = true
      this.removeWidgetSample()
      await this.$nextTick()
      if (this.shouldDisplayWidget) {
        const node = document.createElement('script')
        const parent = document.getElementById('organization-widget-area')
        if (parent != null) parent.appendChild(node)
        node.src = this.widgetScriptUrl + '&id=organization-widget-sample'
      }
      this.refreshingWidget = false
    },
    ...mapActions(['showGlobalMessage']),
    ...mapActions('admin', ['loadOrganization', 'updateOrganization', 'deleteOrganization', 'ensureOrganizationsLoaded'])
  },

  beforeDestroy () {
    this.removeWidgetSample()
  }
})
