import { render, staticRenderFns } from "./centered.vue?vue&type=template&id=327bacd6&scoped=true&"
import script from "./centered.vue?vue&type=script&lang=js&"
export * from "./centered.vue?vue&type=script&lang=js&"
import style0 from "./centered.vue?vue&type=style&index=0&lang=css&"
import style1 from "./centered.vue?vue&type=style&index=1&id=327bacd6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327bacd6",
  null
  
)

export default component.exports