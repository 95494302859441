<template>
  <v-textarea :value="value" @input="onInput"
              :readonly="readonly" :label="label"
              :hint="hint" persistent-hint
              :rules="validationRules" />
</template>

<script>
import { required as vRequired, maxLength as vMaxLength } from 'vuelidate/lib/validators'

export default {
  props: {
    value: String,
    required: { type: Boolean, default: false },
    maxLength: Number,
    maxLineCount: Number,
    readonly: { type: Boolean, default: true },
    label: String,
    hint: String
  },

  computed: {
    validationRules: function () {
      const rules = []
      if (this.required) {
        rules.push(v => vRequired(v) ? true : this.$t('forms.validations.required'))
      }
      if (this.maxLength > 0) {
        rules.push(v => vMaxLength(this.maxLength)(v) ? true : this.$t('forms.validations.maxLength', { max: this.maxLength }))
      }
      if (this.maxLineCount > 0) {
        rules.push(v => {
          const lineCount = (v ?? '').split('\n').length
          return lineCount <= this.maxLineCount ? true : this.$t('forms.validations.maxLineCount', { max: this.maxLineCount })
        })
      }
      return rules
    }
  },

  methods: {
    onInput (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
