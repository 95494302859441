var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.scans.length === 0 && !_vm.loading)?_c('div',{staticClass:"pa-6"},[_c('v-alert',{attrs:{"dense":"","text":"","type":"info"}},[(_vm.isSpecialist && _vm.sharesScans3D === false && _vm.sharesScans2D === false)?_c('span',[_vm._v(_vm._s(_vm.$t('scans.scans-not-shared-with-specialist')))]):(_vm.isSpecialist && _vm.sharesScans3D === false)?_c('span',[_vm._v(_vm._s(_vm.$t('scans.scans3d-not-shared-with-specialist')))]):(_vm.isSpecialist && _vm.sharesScans2D === false)?_c('span',[_vm._v(_vm._s(_vm.$t('scans.scans2d-not-shared-with-specialist')))]):_c('span',[_vm._v(_vm._s(_vm.$t('scans.nothing-available')))])])],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"px-5 pt-2 pb-0"},[_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading || _vm.scans.length > 0),expression:"loading || scans.length > 0"}],attrs:{"label":_vm.$t('scans.viewer.scans-select'),"placeholder":_vm.$t('scans.viewer.scans-select-first'),"persistent-placeholder":"","items":_vm.scans,"item-value":"Id","hint":_vm.selectHint,"persistent-hint":"","return-object":"","loading":_vm.loading},on:{"change":function($event){return _vm.goToScan(_vm.select)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('scans.scan-title', {num: item.num}))+" - "+_vm._s(_vm.$formatDate(item.CapturedAt))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-badge',{staticClass:"ma-2",attrs:{"dot":"","left":"","color":"MProCoral","value":item.ViewedBySpecialist === false}},[_c('v-icon',[_vm._v(_vm._s(_vm.getScanIcon(item).name))])],1),_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('span',[_vm._v(_vm._s(_vm.$t('scans.scan-title', {num: item.num}))+" - "+_vm._s(_vm.$formatDate(item.CapturedAt)))]),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.getScanAffiliation(item)))])])],1)]}}],null,false,4277918847),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1):_c('v-skeleton-loader',{staticClass:"pa-5",class:[_vm.scans.length > 0 && 'fill-height'],attrs:{"type":"list-item-two-line@3","loading":_vm.loading}},[_c('v-list',[(_vm.isSpecialist && _vm.hasScans3D)?_c('v-btn',{staticClass:"MProCoral mb-5",attrs:{"to":{name: 'ScanCompare', params: {seekerId: _vm.seekerId}, query: {scanIdL: _vm.$route.params.scanId, movementL: _vm.movementL}},"block":"","large":""}},[_vm._v(" "+_vm._s(_vm.$t('scans.buttons.compare-scans'))+" ")]):_vm._e(),_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:item.id,staticClass:"my-2 fade",class:_vm.fab[index] ? 'faded' : '',attrs:{"flat":"","to":{name: 'Scan', params: {scanId: item.id}}}},[_c('v-list-item-icon',{staticClass:"mr-4 align-self-center"},[_c('v-badge',{attrs:{"dot":"","left":"","color":"MProCoral","value":item.scan.ViewedBySpecialist === false}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getScanIcon(item.scan).name)+" ")])]}}],null,true)},[(item.scan.ScanType === '3D')?_c('span',[_vm._v(_vm._s(_vm.$t('scans.3d-scan')))]):_vm._e(),(item.scan.ScanType === '2D')?_c('span',[_vm._v(_vm._s(_vm.$t('scans2d.mobile-scan')))]):_vm._e()])],1)],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$formatDate(item.scan.CapturedAt)))]),_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('scans.scan-title', {num: item.num }))+" ")])],1),_c('v-list-item-action',[(_vm.isSpecialist && item.is3D)?_c('v-btn',{staticClass:"MProCoral",attrs:{"to":{name: 'ScanCompare', params: {seekerId: _vm.seekerId}, query: {scanIdL: _vm.$route.params.scanId, movementL: _vm.movementL, scanIdR: item.id}},"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-compare")])],1):_vm._e()],1)],1)}),(_vm.pageCount > 1)?_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.pageIndex),callback:function ($$v) {_vm.pageIndex=$$v},expression:"pageIndex"}}):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }