import { ScanView3D, ScanMeasureArrow, ScanMeasureType } from '@/models/ui'

const state = {
  'stand_still': {
    'measures': {
      'HeadTopAnatomic': {
        'HeadLateralDisplacementFromMidline': {
          'viewFrom': [ScanView3D.Front],
          'type': ScanMeasureType.Displacement,
          'arrow': ScanMeasureArrow.Left
        },
        'NeckAngleOfTiltFaceView': {
          'viewFrom': [ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.AngleDirection,
          'arrow': ScanMeasureArrow.Left
        },
        'NeckForwardAngle': {
          'viewFrom': [ScanView3D.Left, ScanView3D.Right],
          'type': ScanMeasureType.AngleDirection,
          'arrow': ScanMeasureArrow.Left
        },
        'HeadForwardDisplacementFromMidline': {
          'viewFrom': [ScanView3D.Left, ScanView3D.Right],
          'type': ScanMeasureType.Displacement,
          'arrow': ScanMeasureArrow.Left
        }
      },
      'MidShoulder': {
        'ShouldersMiddleLateralDisplacementFromMidline': {
          'viewFrom': [ScanView3D.Front],
          'type': ScanMeasureType.Displacement,
          'arrow': ScanMeasureArrow.Left
        }
      },
      'SpineT1': {
        'T1ForwardDisplacementFromMidline': {
          'viewFrom': [ScanView3D.Left, ScanView3D.Right],
          'type': ScanMeasureType.Displacement,
          'arrow': ScanMeasureArrow.Left
        }
      },
      'HipsMiddleAnatomic': {
        'HipsMiddleLateralDisplacementFromMidline': {
          'viewFrom': [ScanView3D.Front],
          'type': ScanMeasureType.Displacement,
          'arrow': ScanMeasureArrow.Left
        },
        'HipsMiddleForwardDisplacementFromMidline': {
          'viewFrom': [ScanView3D.Left, ScanView3D.Right],
          'type': ScanMeasureType.Displacement,
          'arrow': ScanMeasureArrow.Left
        }
      },
      'RightKneeAnatomic': {
        'KneeRightOutwardFlexionAngle': {
          'viewFrom': [ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.AngleDirection,
          'arrow': ScanMeasureArrow.Left
        }
      },
      'LeftKneeAnatomic': {
        'KneeLeftOutwardFlexionAngle': {
          'viewFrom': [ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.AngleDirection,
          'arrow': ScanMeasureArrow.Right
        }
      }
    },
    'trajectories': {
      'CenterofMass': {}
    },
    'lines': {},
    'referencePoints': ['AnteriorAnklesMiddle']
  },
  'balance_left_leg': {
    'measures': {},
    'trajectories': {
      'CenterofMass': {}
    },
    'lines': {},
    'referencePoints': ['LeftAnteriorAnkle']
  },
  'balance_right_leg': {
    'measures': {},
    'trajectories': {
      'CenterofMass': {}
    },
    'lines': {},
    'referencePoints': ['RightAnteriorAnkle']
  },
  'side_bend': {
    'measures': {
      'RightShoulderSurface': {
        'ShoulderRightPath': {
          'viewFrom': [ScanView3D.Front],
          'type': ScanMeasureType.Displacement,
          'arrow': ScanMeasureArrow.Down,
          'enableOn': [0, 2]
        }
      },
      'LeftShoulderSurface': {
        'ShoulderLeftPath': {
          'viewFrom': [ScanView3D.Front],
          'type': ScanMeasureType.Displacement,
          'arrow': ScanMeasureArrow.Down,
          'enableOn': [0, 1]
        }
      },
      'HipsMiddleAnatomic': {
        'HipsMiddleLeftMaxLateralDispacement': {
          'viewFrom': [ScanView3D.Front],
          'type': ScanMeasureType.Displacement,
          'arrow': ScanMeasureArrow.Right,
          'enableOn': [0, 1]
        },
        'HipsMiddleRightMaxLateralDispacement': {
          'viewFrom': [ScanView3D.Front],
          'type': ScanMeasureType.Displacement,
          'arrow': ScanMeasureArrow.Right,
          'enableOn': [0, 2]
        }
      },
      '': {}
    },
    'trajectories': {
      'RightShoulderSurface': {
        'value': {
          'id': 'ShoulderRightMaxLateralDispacement',
          'extension': []
        },
        'type': ScanMeasureType.Displacement
      },
      'LeftShoulderSurface': {
        'value': {
          'id': 'ShoulderLeftMaxLateralDispacement',
          'extension': []
        },
        'type': ScanMeasureType.Displacement
      },
      'HipsMiddleAnatomic': {
        'value': {
          'id': 'HipsMiddleRightMaxLateralDispacement',
          'extension': []
        },
        'type': ScanMeasureType.Displacement
      },
      'CenterofMass': {
        'type': ScanMeasureType.Angle
      }
    },
    'lines': {},
    'referencePoints': ['AnteriorAnklesMiddle']
  },
  'squat': {
    'measures': {
      'LeftShoulderSurface': {
        'ShoulderLeftSurfaceTrajectoryAngles.VerticalForthAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Down,
          'threshold': 0,
          'enableOn': [0, 1]
        },
        'ShoulderLeftSurfaceTrajectoryAngles.VerticalBackAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Up,
          'threshold': 0,
          'enableOn': [0, 2]
        }
      },
      'RightShoulderSurface': {
        'ShoulderRightSurfaceTrajectoryAngles.VerticalForthAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Down,
          'threshold': 0,
          'enableOn': [0, 1]
        },
        'ShoulderRightSurfaceTrajectoryAngles.VerticalBackAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Up,
          'threshold': 0,
          'enableOn': [0, 2]
        }
      },
      'HipsMiddleAnatomic': {
        'HipsMiddleTrajectoryAngles.VerticalForthAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Down,
          'threshold': 0,
          'enableOn': [0, 1]
        },
        'HipsMiddleTrajectoryAngles.VerticalBackAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Up,
          'threshold': 0,
          'enableOn': [0, 2]
        }
      },
      'RightKneeAnatomic': {
        'KneeRightJointTrajectoryAngles.VerticalForthAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Down,
          'threshold': 15,
          'enableOn': [0, 1]
        },
        'KneeRightJointTrajectoryAngles.VerticalBackAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Up,
          'threshold': 15,
          'enableOn': [0, 2]
        }
      },
      'LeftKneeAnatomic': {
        'KneeLeftJointTrajectoryAngles.VerticalForthAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Down,
          'threshold': 15,
          'enableOn': [0, 1]
        },
        'KneeLeftJointTrajectoryAngles.VerticalBackAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Up,
          'threshold': 15,
          'enableOn': [0, 2]
        }
      }
    },
    'trajectories': {
      'RightShoulderSurface': {
        'value': {
          'id': 'ShoulderRightSurfaceTrajectoryAngles',
          'extension': ['VerticalForthAngle', 'VerticalBackAngle']
        },
        'type': ScanMeasureType.Angle
      },
      'LeftShoulderSurface': {
        'value': {
          'id': 'ShoulderLeftSurfaceTrajectoryAngles',
          'extension': ['VerticalForthAngle', 'VerticalBackAngle']
        },
        'type': ScanMeasureType.Angle
      },
      'HipsMiddleAnatomic': {
        'value': {
          'id': 'HipsMiddleTrajectoryAngles',
          'extension': ['VerticalForthAngle', 'VerticalBackAngle']
        },
        'type': ScanMeasureType.Angle
      },
      'RightKneeAnatomic': {
        'value': {
          'id': 'KneeRightJointTrajectoryAngles',
          'extension': ['VerticalForthAngle', 'VerticalBackAngle']
        },
        'type': ScanMeasureType.Angle
      },
      'LeftKneeAnatomic': {
        'value': {
          'id': 'KneeLeftJointTrajectoryAngles',
          'extension': ['VerticalForthAngle', 'VerticalBackAngle']
        },
        'type': ScanMeasureType.Angle
      }
    },
    'lines': {},
    'referencePoints': ['LeftAnteriorAnkle', 'RightAnteriorAnkle']
  },
  'squat_left': {
    'measures': {
      'LeftShoulderSurface': {
        'ShoulderLeftSurfaceTrajectoryAngles.VerticalForthAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Down,
          'enableOn': [0, 1]
        },
        'ShoulderLeftSurfaceTrajectoryAngles.VerticalBackAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Up,
          'enableOn': [0, 2]
        }
      },
      'RightShoulderSurface': {
        'ShoulderRightSurfaceTrajectoryAngles.VerticalForthAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Down,
          'enableOn': [0, 1]
        },
        'ShoulderRightSurfaceTrajectoryAngles.VerticalBackAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Up,
          'enableOn': [0, 2]
        }
      },
      'HipsMiddleAnatomic': {
        'HipsMiddleTrajectoryAngles.VerticalForthAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Down,
          'enableOn': [0, 1]
        },
        'HipsMiddleTrajectoryAngles.VerticalBackAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Up,
          'enableOn': [0, 2]
        }
      },
      'LeftKneeAnatomic': {
        'KneeLeftJointTrajectoryAngles.VerticalForthAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Down,
          'enableOn': [0, 1]
        },
        'KneeLeftJointTrajectoryAngles.VerticalBackAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Up,
          'enableOn': [0, 2]
        }
      }
    },
    'trajectories': {
      'RightShoulderSurface': {
        'value': {
          'id': 'ShoulderRightSurfaceTrajectoryAngles',
          'extension': ['VerticalForthAngle', 'VerticalBackAngle']
        },
        'type': ScanMeasureType.Angle
      },
      'LeftShoulderSurface': {
        'value': {
          'id': 'ShoulderLeftSurfaceTrajectoryAngles',
          'extension': ['VerticalForthAngle', 'VerticalBackAngle']
        },
        'type': ScanMeasureType.Angle
      },
      'HipsMiddleAnatomic': {
        'value': {
          'id': 'HipsMiddleTrajectoryAngles',
          'extension': ['VerticalForthAngle', 'VerticalBackAngle']
        },
        'type': ScanMeasureType.Angle
      },
      'LeftKneeAnatomic': {
        'value': {
          'id': 'KneeLeftJointTrajectoryAngles',
          'extension': ['VerticalForthAngle', 'VerticalBackAngle']
        },
        'type': ScanMeasureType.Angle
      }
    },
    'lines': {},
    'referencePoints': ['LeftAnteriorAnkle']
  },
  'squat_right': {
    'measures': {
      'LeftShoulderSurface': {
        'ShoulderLeftSurfaceTrajectoryAngles.VerticalForthAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Down,
          'enableOn': [0, 1]
        },
        'ShoulderLeftSurfaceTrajectoryAngles.VerticalBackAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Up,
          'enableOn': [0, 2]
        }
      },
      'RightShoulderSurface': {
        'ShoulderRightSurfaceTrajectoryAngles.VerticalForthAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Down,
          'enableOn': [0, 1]
        },
        'ShoulderRightSurfaceTrajectoryAngles.VerticalBackAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Up,
          'enableOn': [0, 2]
        }
      },
      'HipsMiddleAnatomic': {
        'HipsMiddleTrajectoryAngles.VerticalForthAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Down,
          'enableOn': [0, 1]
        },
        'HipsMiddleTrajectoryAngles.VerticalBackAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Up,
          'enableOn': [0, 2]
        }
      },
      'RightKneeAnatomic': {
        'KneeRightJointTrajectoryAngles.VerticalForthAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Down,
          'enableOn': [0, 1]
        },
        'KneeRightJointTrajectoryAngles.VerticalBackAngle': {
          'viewFrom': [ScanView3D.Right, ScanView3D.Left, ScanView3D.Front, ScanView3D.Back],
          'type': ScanMeasureType.Angle,
          'arrow': ScanMeasureArrow.Up,
          'enableOn': [0, 2]
        }
      }
    },
    'trajectories': {
      'RightShoulderSurface': {
        'value': {
          'id': 'ShoulderRightSurfaceTrajectoryAngles',
          'extension': ['VerticalForthAngle', 'VerticalBackAngle']
        },
        'type': ScanMeasureType.Angle
      },
      'LeftShoulderSurface': {
        'value': {
          'id': 'ShoulderLeftSurfaceTrajectoryAngles',
          'extension': ['VerticalForthAngle', 'VerticalBackAngle']
        },
        'type': ScanMeasureType.Angle
      },
      'HipsMiddleAnatomic': {
        'value': {
          'id': 'HipsMiddleTrajectoryAngles',
          'extension': ['VerticalForthAngle', 'VerticalBackAngle']
        },
        'type': ScanMeasureType.Angle
      },
      'RightKneeAnatomic': {
        'value': {
          'id': 'KneeRightJointTrajectoryAngles',
          'extension': ['VerticalForthAngle', 'VerticalBackAngle']
        },
        'type': ScanMeasureType.Angle
      }
    },
    'lines': {},
    'referencePoints': ['RightAnteriorAnkle']
  },
  'base': {
    'HeadTopAnatomic': '',
    'HipsMiddleAnatomic': '',
    'CenterofMass': '',
    'AnklesMiddleAnatomic': '',
    'ShouldersMiddleAnatomic': '',
    'RightHipAnatomic': '',
    'LeftHipAnatomic': '',
    'RightShoulderSurface': '',
    'LeftShoulderSurface': '',
    'LeftKneeAnatomic': '',
    'KneesMiddleAnatomic': '',
    'RightKneeAnatomic': '',
    'LeftAnkleAnatomic': '',
    'RightAnkleAnatomic': '',
    'RightShoulderAnatomic': '',
    'LeftShoulderAnatomic': '',
    'LeftHandTipSurface': '',
    'RightHandTipSurface': ''
  }
}

// getters
const getters = {
  getMeasurePlacement: (state) => (movement) => {
    return state[movement]
  }
}

export default {
  namespaced: true,
  state,
  getters
//   actions,
//   mutations
}
