
































import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    value: Boolean,
    title: String,
    titleClass: {},
    message: String,
    cancelText: String,
    cancelClass: {},
    confirmText: String,
    confirmClass: {},
    icon: String,
    iconClass: {},
    action: Function as PropType<() => void | Promise<void>>
  },

  data: () => ({
    processing: false
  }),

  methods: {
    close (): void {
      this.onInput(false)
    },
    onInput (v: boolean): void {
      this.$emit('input', v)
    },
    async confirm (): Promise<void> {
      this.processing = true
      try {
        if (this.action != null) {
          await this.action()
        }
      } finally {
        this.processing = false
      }

      this.close()
    }
  }
})
