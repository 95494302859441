<template>
  <v-container>
    <h1>{{$t('surveys.sf36.result-title')}}</h1>
    <v-row justify="center">
      <v-col cols="12" md="12" lg="8">
        <radar v-if="survey != null" :chart-data="datacollection" :options="options"
               class="ma-n4"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Radar from './radar-data.js'
import { mapGetters, mapActions } from 'vuex'

const group1 = [1, 2, 20, 22, 34, 36]
const group2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const group3 = [13, 14, 15, 16, 17, 18, 19]
const group4 = [21, 23, 26, 27, 30]
const group5 = [24, 25, 28, 29, 31]
const group6 = [32, 33, 35]

export default {
  components: {
    Radar
  },

  props: {
    survey: Object
  },

  data: () => ({
    datacollection: {}
  }),

  computed: {
    ...mapGetters(['isSpecialist']),
    options: function () {
      let options = {
        scale: {
          angleLines: {
            color: this.gridColor
          },
          gridLines: {
            color: this.gridColor
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100
          }
        },
        layout: {
          // Sometimes labels get clipped
          // Prevent this with canvas padding which is compensated
          // by a negative margin of the element
          padding: 16
        }
      }
      return options
    },
    gridColor: function () {
      return this.isSpecialist
        ? 'rgba(36, 36, 36, .5)'
        : 'rgba(255, 255, 255, .5)'
    },
    surveyScore: function () {
      let score = [0, 0, 0, 0, 0, 0, 0, 0, 0]
      const physicalFunctioning = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      const rolePhysical = [13, 14, 15, 16]
      const roleEmotional = [17, 18, 19]
      const vitality = [23, 27, 29, 31]
      const mentalHealth = [24, 25, 26, 28, 30]
      const socialFunctioning = [32, 20]
      const bodilyPain = [21, 22]
      const generalHealth = [1, 33, 34, 35, 36]
      const healthDevelopment = [2]

      physicalFunctioning.forEach(q => { score[0] = score[0] + this.questionValue(q) })
      score[0] = score[0] / physicalFunctioning.length

      rolePhysical.forEach(q => { score[1] = score[1] + this.questionValue(q) })
      score[1] = score[1] / rolePhysical.length

      roleEmotional.forEach(q => { score[2] = score[2] + this.questionValue(q) })
      score[2] = score[2] / roleEmotional.length

      vitality.forEach(q => { score[3] = score[3] + this.questionValue(q) })
      score[3] = score[3] / vitality.length

      mentalHealth.forEach(q => { score[4] = score[4] + this.questionValue(q) })
      score[4] = score[4] / mentalHealth.length

      socialFunctioning.forEach(q => { score[5] = score[5] + this.questionValue(q) })
      score[5] = score[5] / socialFunctioning.length

      bodilyPain.forEach(q => { score[6] = score[6] + this.questionValue(q) })
      score[6] = score[6] / bodilyPain.length

      generalHealth.forEach(q => { score[7] = score[7] + this.questionValue(q) })
      score[7] = score[7] / generalHealth.length

      healthDevelopment.forEach(q => { score[8] = score[8] + this.questionValue(q) })
      score[8] = score[8] / healthDevelopment.length

      return score
    }
  },

  watch: {
    survey: function () {
      this.fillData()
    }
  },

  methods: {
    ...mapActions('surveys', ['getOrLoadMetadata']),
    fillData () {
      this.datacollection = {
        labels: [
          this.$t('surveys.sf36.labels.physical').split('\n'),
          this.$t('surveys.sf36.labels.physical_limitations').split('\n'),
          this.$t('surveys.sf36.labels.emotional_limitations').split('\n'),
          this.$t('surveys.sf36.labels.energy').split('\n'),
          this.$t('surveys.sf36.labels.emotional').split('\n'),
          this.$t('surveys.sf36.labels.social').split('\n'),
          this.$t('surveys.sf36.labels.pain').split('\n'),
          this.$t('surveys.sf36.labels.health').split('\n'),
          this.$t('surveys.sf36.labels.health_change').split('\n')
        ],
        fill: true
      }
      if (this.survey != null) {
        this.datacollection.datasets = [
          {
            label: `SF-36 - ${this.$formatDateTime(this.survey.AnsweredAt)}`,
            backgroundColor: 'rgba(255, 0, 93, 0.5)',
            borderColor: 'rgba(255, 0, 93, 1)',
            data: this.surveyScore,
            borderWidth: 2
          }
        ]
      }
    },
    getRandomInt () {
      return Math.floor(Math.random() * (100 - 5 + 1)) + 5
    },
    getAnswerByCode (code) {
      if (this.survey) {
        const answer = this.survey.answers.find(a => a.QuestionCode === code)
        if (answer) return answer.Answer
      }
      return null
    },
    questionValue: function (q) {
      q = parseInt(q)
      const val = parseInt(this.getAnswerByCode(`SF-36:${q}`))
      let answer = group1.find(a => a === q)
      if (answer) {
        if (val === 1) return 100
        if (val === 2) return 75
        if (val === 3) return 50
        if (val === 4) return 25
        if (val === 5) return 0
      }
      answer = group2.find(a => a === q)
      if (answer) {
        if (val === 1) return 0
        if (val === 2) return 50
        if (val === 3) return 100
      }
      answer = group3.find(a => a === q)
      if (answer) {
        if (val === 1) return 0
        if (val === 2) return 100
      }
      answer = group4.find(a => a === q)
      if (answer) {
        if (val === 1) return 100
        if (val === 2) return 80
        if (val === 3) return 60
        if (val === 4) return 40
        if (val === 5) return 20
        if (val === 6) return 0
      }
      answer = group5.find(a => a === q)
      if (answer) {
        if (val === 1) return 0
        if (val === 2) return 20
        if (val === 3) return 40
        if (val === 4) return 60
        if (val === 5) return 80
        if (val === 6) return 100
      }
      answer = group6.find(a => a === q)
      if (answer) {
        if (val === 1) return 0
        if (val === 2) return 25
        if (val === 3) return 50
        if (val === 4) return 75
        if (val === 5) return 100
      }
      return 0
    }
  },

  mounted () {
    this.fillData()
  }
}
</script>

<style>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }
</style>
