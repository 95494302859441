

























































import Vue from 'vue'
import { DataTableHeader } from 'vuetify'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import Lo from 'lodash'
import DateBox from '@/components/elements/date-box.vue'
import { Questionnaire, SurveyAssignment } from '@/models/surveys'
import { IconInfo } from '@/models/ui'
import { SeekerBasicInfo } from '@/models/users'
import { ListItem } from '@/models/vuetify'
import { Location } from 'vue-router'

enum SurveyStatus {
  Open = 'open',
  Answered = 'answered'
}

interface StatusValue {
  value: SurveyStatus,
  sortDate: string,
  icon: IconInfo
}

function getStatusIcon (status: SurveyStatus): IconInfo {
  return status === SurveyStatus.Answered
    ? { name: 'mdi-check-circle-outline', color: 'MProGreen' }
    : { name: 'mdi-help-circle-outline', color: 'MProYellow' }
}

interface SurveyAssignmentToDisplay extends SurveyAssignment {
  seekerNameOrEmail: string,
  seekerNameLower: string,
  seekerEmailLower: string,
  status: StatusValue
}

export default Vue.extend({
  components: {
    'mpro-date-box': DateBox
  },

  data: () => ({
    loaded: false,
    tableSortBy: 'status',
    tableSortDesc: false,
    filterQuestionnaire: undefined as string | undefined,
    filterSeeker: undefined as string | undefined,
    filterRequestDate: null as Date | null,
    filterStatus: undefined as SurveyStatus | undefined
  }),

  computed: {
    ...mapState('specialist', ['surveyAssignments', 'seekers']),
    ...mapState('surveys', ['availableSurveys']),
    tableHeaders: function (): DataTableHeader[] {
      return [
        {
          text: this.$t('specialist.assigned-surveys.questionnaire').toString(),
          value: 'QuestionnaireCode',
          sortable: false
        },
        {
          text: this.$t('specialist.assigned-surveys.seeker').toString(),
          value: 'seekerNameOrEmail'
        },
        {
          text: this.$t('specialist.assigned-surveys.request-date').toString(),
          value: 'AssignedAt',
          sort: (a: string, b: string): number => moment.parseZone(a).diff(moment.parseZone(b))
        },
        {
          text: this.$t('specialist.assigned-surveys.status').toString(),
          value: 'status',
          align: 'center',
          sort: (a: StatusValue, b: StatusValue): number => {
            // Sort by status, then by date
            const statusCompare = (a.value === SurveyStatus.Open ? 0 : 1) -
              (b.value === SurveyStatus.Open ? 0 : 1)
            return statusCompare !== 0
              ? statusCompare
              : moment(a.sortDate).diff(b.sortDate)
          }
        }
      ]
    },
    tableItems: function (): SurveyAssignmentToDisplay[] {
      const input = this.surveyAssignments as SurveyAssignment[]
      return input.map(sa => {
        const seeker = (this.seekers as SeekerBasicInfo[])
          .find(s => s.Id === sa.SeekerId)
        const status = sa.ResultSurveyId == null ? SurveyStatus.Open : SurveyStatus.Answered
        return {
          ...sa,
          seekerNameLower: (seeker?.DisplayName ?? '').toLocaleLowerCase(),
          seekerEmailLower: (seeker?.Email ?? '').toLocaleLowerCase(),
          seekerNameOrEmail: seeker?.DisplayName ?? seeker?.Email ?? '',
          status: {
            value: status,
            sortDate: sa.AssignedAt,
            icon: getStatusIcon(status)
          }
        }
      })
    },
    filteredTableItems: function (): SurveyAssignmentToDisplay[] {
      let result = this.tableItems
      if (this.filterStatus != null) {
        result = result.filter(item => item.status.value === this.filterStatus)
      }
      if ((this.filterQuestionnaire ?? '') !== '') {
        result = result.filter(item => item.QuestionnaireCode === this.filterQuestionnaire)
      }
      if ((this.filterSeeker ?? '') !== '') {
        const search = this.filterSeeker?.toLocaleLowerCase() ?? ''
        result = result.filter(item => item.seekerNameLower.includes(search) ||
          item.seekerEmailLower.includes(search))
      }
      if (this.filterRequestDate != null) {
        const date = moment(this.filterRequestDate).utc(true)
        result = result.filter(item => {
          const v = moment.parseZone(item.AssignedAt).utc(true)
          return v.isSameOrAfter(date) && v.isBefore(date.add(1, 'day'))
        })
      }
      return result
    },
    filterQuestionnaireItems: function (): ListItem[] {
      return Lo(this.availableSurveys as Questionnaire[])
        .map(q => ({ value: q.Code, text: q.Title }))
        .orderBy(item => item.text)
        .value()
    },
    filterStatusItems: function (): ListItem[] {
      return [
        { value: SurveyStatus.Open, text: this.$t('specialist.assigned-surveys.status-open').toString() },
        { value: SurveyStatus.Answered, text: this.$t('specialist.assigned-surveys.status-answered').toString() }
      ]
    }
  },

  methods: {
    ...mapActions(['setHeaderTitle']),
    ...mapActions('specialist', ['ensureSurveyAssignmentsLoaded', 'ensureSpecialistSeekersLoaded']),
    ...mapActions('surveys', ['getOrLoadAvailableSurveys']),
    getSurveyTitle (questionnaireCode: string): string {
      return (this.availableSurveys as Questionnaire[])
        .find(q => q.Code === questionnaireCode)
        ?.Title ?? ''
    },
    getSurveyLocation (resultSurveyId: string): Location {
      return {
        name: 'ViewSurvey',
        params: { surveyId: resultSurveyId }
      }
    },
    getSeekerSurveysLocation (seekerId: string): Location {
      return {
        name: 'clientSurveys',
        params: { seekerId }
      }
    }
  },

  async created () {
    const promises = [
      this.ensureSurveyAssignmentsLoaded(),
      this.ensureSpecialistSeekersLoaded(),
      this.getOrLoadAvailableSurveys()
    ]
    await Promise.all(promises)
    this.loaded = true
  },
  mounted () {
    this.setHeaderTitle(this.$t('specialist.assigned-surveys.title'))
  }
})
