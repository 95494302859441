var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"7","order":"1"}},[_c('v-text-field',{attrs:{"label":_vm.$t('specialist.seekers.search_seekers'),"clearable":"","clear-icon":"mdi-eraser","placeholder":_vm.$t('specialist.seekers.search_seekers_hint'),"prepend-inner-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.seekerFilter),callback:function ($$v) {_vm.seekerFilter=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"seekerFilter"}})],1),_c('v-col',{staticClass:"pl-sm-4",attrs:{"cols":"12","sm":"5","order":"2"}},[_c('v-select',{staticClass:"error-placeholder",attrs:{"multiple":"","items":_vm.sortedLibraries,"item-value":"Id","menu-props":{maxHeight: '75vh'},"hide-details":"","placeholder":_vm.$t('specialist.seekers.no-filter-libraries'),"persistent-placeholder":"","error":_vm.selectedLibraries.length === 0},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"v-select__selection--comma"},[_vm._v(" "+_vm._s(_vm.$tc('specialist.seekers.filter_libraries', _vm.selectedLibraries.length, { library: item.Name }))+" ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":_vm.selectedLibraries.includes(item.Id)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.Name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.OwnerName))])],1)],1)]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{on:{"click":_vm.toggleSelectAllLibraries}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":_vm.allLibrariesSelected,"indeterminate":_vm.selectedLibraries.length > 0 && !_vm.allLibrariesSelected}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.allLibrariesSelected ? _vm.$t('forms.unselect_all') : _vm.$t('forms.select_all'))+" ")])],1)],1),_c('v-divider',{staticClass:"my-1"})]},proxy:true}]),model:{value:(_vm.selectedLibraries),callback:function ($$v) {_vm.selectedLibraries=$$v},expression:"selectedLibraries"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"7","order":"4","order-sm":"3"}},[_c('div',{staticClass:"d-flex align-end"},[_c('v-select',{staticClass:"flex-grow",attrs:{"label":_vm.$t('specialist.seekers.sort_by.label'),"items":_vm.sortBy,"item-text":"label","item-value":"value","hide-details":""},model:{value:(_vm.seekerSort),callback:function ($$v) {_vm.seekerSort=$$v},expression:"seekerSort"}}),_c('v-btn-toggle',{staticClass:"d-inline-block text-no-wrap ml-2",attrs:{"rounded":"","mandatory":""},model:{value:(_vm.sortOrder),callback:function ($$v) {_vm.sortOrder=$$v},expression:"sortOrder"}},[_c('v-tooltip',{staticClass:"d-inline-flex",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","fab":"","outlined":"","value":"-1"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-sort-ascending")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('forms.sort_asc')))])]),_c('v-tooltip',{staticClass:"d-inline-flex",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","fab":"","outlined":"","value":"1"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-sort-descending")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('forms.sort_desc')))])])],1)],1)]),_c('v-col',{staticClass:"pl-sm-4",attrs:{"cols":"12","sm":"5","order":"3","order-sm":"4"}},[_c('v-select',{attrs:{"placeholder":_vm.$t('specialist.seekers.status-placeholder'),"clearable":"","clear-icon":"mdi-eraser","items":_vm.statusItems},model:{value:(_vm.seekerStatus),callback:function ($$v) {_vm.seekerStatus=$$v},expression:"seekerStatus"}})],1)],1)],1),_c('v-list',_vm._l((_vm.seekersToDisplay),function(seeker){return _c('v-list-item',{key:seeker.Id,attrs:{"disabled":_vm.isStub(seeker),"to":{name: 'ViewSeeker', params: {seekerId: seeker.Id}}}},[(_vm.isStub(seeker))?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" ")]),_c('v-list-item-subtitle',[_vm._v(" ")])],1):_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center flex-nowrap"},[_c('span',[_vm._v(_vm._s(seeker.DisplayName || seeker.Email))]),(seeker.status != null)?_c('v-chip',{staticClass:"ml-4",attrs:{"color":_vm.getStatusColor(seeker.status),"small":""}},[_vm._v(" "+_vm._s(_vm.$t(("enums.SeekerToSpecialistStatus." + (seeker.status))))+" ")]):_vm._e()],1),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-list-item-subtitle',[(seeker.LastScanCapturedAt)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('specialist.seekers.last_scan_on', { date: _vm.$formatDateTime(seeker.LastScanCapturedAt) }))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('scans.nothing-available'))+" ")])])],1)],1)],1)}),1),(_vm.pageCount > 1)?_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.pageIndex),callback:function ($$v) {_vm.pageIndex=$$v},expression:"pageIndex"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }