import axios from 'axios'
import Cache from 'ttl-cache'
import moment from 'moment'
import Lo from 'lodash'
import { ScanType } from '../../helpers/enums'
import { handleAxiosError } from '@/helpers/errors'
import { UserLogin } from '@/models/users'

const cache = new Cache({
  ttl: process.env.VUE_APP_DEFAULT_API_RESPONSE_LIFETIME / 1000,
  interval: 0
})

const CacheKeys = {
  SCANS: 'scans',
  GOALS: 'goals',
  EXERCISE_PROGRAMS: 'exercisePrograms',
  SURVEYS: 'surveys',
  SURVEY_ASSIGNMENTS: 'surveyAssignments'
}

const RequestUrls = {
  seekerScans3D: subordinateId => {
    return subordinateId == null
      ? '/scans'
      : `/scans?sub=${encodeURIComponent(subordinateId)}`
  },
  seekerScans2D: seekerId => `/scans2d/of-seeker/${encodeURIComponent(seekerId)}`,
  goals: subordinateId => {
    return subordinateId == null
      ? '/goals'
      : `/goals?seeker-id=${encodeURIComponent(subordinateId)}`
  },
  exercisePrograms: subordinateId => {
    return subordinateId == null
      ? '/exercises/programs'
      : `/exercises/programs?seeker-id=${encodeURIComponent(subordinateId)}`
  },
  surveys: subordinateId => {
    return subordinateId == null
      ? '/surveys'
      : `/surveys?sub=${encodeURIComponent(subordinateId)}`
  },
  surveyAssignments: subordinateId => {
    return subordinateId == null
      ? '/surveys/assignments'
      : `/surveys/assignments?sub=${encodeURIComponent(subordinateId)}`
  },
  ORGANIZATIONS_FOR_SHARING: '/users/organizations-for-data-sharing',
  DATA_SHARINGS: '/users/data-sharings',
  dataSharing: organizationCode => `/users/data-sharings/${encodeURIComponent(organizationCode)}`,
  CONTACTS: '/users/seeker-contacts',
  EMAIL_ADDRESS: '/users/seeker-email-address',
  moveData: token => `/users/move-seeker-data?token=${encodeURIComponent(token)}`
}

const state = {
  scans: [],
  surveys: [],
  surveyAssignments: [],
  exercisePrograms: [],
  goals: [],
  specialists: [],
  activeSubordinateId: null
}

const getters = {
  getScanById: state => scanId =>
    state.scans.find(s => s.Id === scanId),
  getAllScans: state =>
    state.scans,
  activeSubordinate: (state, getters, rootState) =>
    rootState.user.seeker?.subordinates?.find(s => s.Id === state.activeSubordinateId),
  pushedSurveyAssignment: (state) =>
    Lo(state.surveyAssignments)
      .filter(sa => sa.AssignedBy === UserLogin.System)
      .sortBy(['DeadlineDate', 'AssignedAt'])
      .first()
}

const actions = {
  ensureScansLoaded: async ({ state, commit, rootGetters }) => {
    if (cache.get(CacheKeys.SCANS)) return

    let response2D = null
    try {
      response2D = await axios.get(RequestUrls.seekerScans2D(state.activeSubordinateId ?? rootGetters['user/getSeeker'].Id))
    } catch (e) {
      console.warn('ensureScansLoaded2D:axios', e)
      return []
    }
    if (Array.isArray(response2D.data)) {
      response2D.data.forEach(scan => {
        scan.ScanType = ScanType.MOBILE
      })
    }

    let response3D = null
    try {
      response3D = await axios.get(RequestUrls.seekerScans3D(state.activeSubordinateId))
    } catch (e) {
      console.warn('ensureScansLoaded3D:axios', e)
      return
    }
    if (Array.isArray(response3D.data)) {
      response3D.data.forEach(scan => {
        scan.ScanType = ScanType.DEPTH
      })
    }
    let scans = [...response3D.data, ...response2D.data]
    scans.sort((s1, s2) => moment(s2.CapturedAt).diff(moment(s1.CapturedAt)))
    if (Array.isArray(scans)) {
      commit('storeScans', scans)
    }
  },

  ensureGoalsLoaded: async ({ state, commit }, force) => {
    if (cache.get(CacheKeys.GOALS) && !force) return

    let response
    try {
      response = await axios.get(RequestUrls.goals(state.activeSubordinateId))
    } catch (e) {
      console.log('ensureGoalsLoaded:axios', e)
      return
    }
    if (Array.isArray(response.data)) {
      commit('storeGoals', response.data)
    }
  },

  ensureExerciseProgramsLoaded: async ({ state, commit }) => {
    if (cache.get(CacheKeys.EXERCISE_PROGRAMS)) return

    let response
    try {
      response = await axios.get(RequestUrls.exercisePrograms(state.activeSubordinateId))
    } catch (e) {
      console.log('ensureExerciseProgramsLoaded:axios', e)
      return
    }

    if (Array.isArray(response.data)) {
      commit('storeExercisePrograms', response.data)
    }
  },

  ensureSurveysLoaded: async ({ state, commit }, options) => {
    const force = options && options.force
    if (cache.get(CacheKeys.SURVEYS) && !force) return

    let response
    try {
      response = await axios.get(RequestUrls.surveys(state.activeSubordinateId))
    } catch (e) {
      console.log('ensureSurveysLoaded:axios', e)
      return
    }

    if (Array.isArray(response.data)) {
      commit('storeSurveys', response.data)
    }
  },

  ensureSurveyAssignmentsLoaded: async ({state, commit}, options) => {
    const force = options && options.force
    if (cache.get(CacheKeys.SURVEY_ASSIGNMENTS) && !force) return

    let response
    try {
      response = await axios.get(RequestUrls.surveyAssignments(state.activeSubordinateId))
    } catch (e) {
      console.log('ensureSurveyAssignmentsLoaded:axios', e)
      return
    }

    if (Array.isArray(response.data)) {
      commit('storeSurveyAssignments', response.data)
    }
  },

  ensureSeekerSpecialistsLoaded: async ({commit}, options) => {
    const force = options?.force === true
    if (cache.get('specialists') && !force) return

    let response
    try {
      response = await axios.get(RequestUrls.CONTACTS)
    } catch (e) {
      console.log('ensureSeekerSpecialistsLoaded:axios', e)
      return
    }
    if (Array.isArray(response.data)) {
      commit('storeSpecialists', response.data)
    }
  },

  loadOrganizationsForDataSharing: async () => {
    let response
    try {
      response = await axios.get(RequestUrls.ORGANIZATIONS_FOR_SHARING)
    } catch (e) {
      console.warn('getOrganizationsForDataSharing:axios', e)
      return []
    }

    return Array.isArray(response.data) ? response.data : []
  },

  loadDataSharings: async (_, payload) => {
    const { subordinateId } = payload
    let response
    try {
      response = await axios.get(RequestUrls.DATA_SHARINGS, {
        params: { sub: subordinateId }
      })
    } catch (e) {
      console.warn('loadDataSharings:axios', e)
      return []
    }

    return Array.isArray(response.data) ? response.data : []
  },

  grantDataSharing: async (_, payload) => {
    const { subordinateId, organizationCode, scope, fromWidget, notes } = payload
    try {
      const response = await axios.put(RequestUrls.dataSharing(organizationCode),
        { scope, fromWidget, notes }, {
          params: { sub: subordinateId }
        })
      return { ...response.data, wasRevoked: false }
    } catch (e) {
      console.warn('grantDataSharing:axios', e)
      handleAxiosError(e)
    }
  },

  revokeDataSharing: async (_, payload) => {
    const { subordinateId, organizationCode } = payload

    try {
      const response = await axios.delete(RequestUrls.dataSharing(organizationCode), {
        params: { sub: subordinateId }
      })
      return { ...response.data, wasRevoked: true }
    } catch (e) {
      console.warn('revokeDataSharing:axios', e)
      handleAxiosError(e)
    }
  },

  requestChangeEmailAddress: async (_, payload) => {
    const { newEmail } = payload

    try {
      await axios.put(RequestUrls.EMAIL_ADDRESS, {
        newEmailAddress: newEmail
      })
    } catch (e) {
      console.warn('requestChangeEmailAddress:axios', e)
      handleAxiosError(e)
    }
  },

  getOldEmailAddressFromMoveDataToken: async (_, token) => {
    let response
    try {
      response = await axios.get(RequestUrls.moveData(token))
    } catch (e) {
      console.warn('decryptMoveData:axios', e)
      return undefined
    }

    return response.data.oa
  },

  moveDataFromAnotherAccount: async (_, token) => {
    try {
      await axios.post(RequestUrls.moveData(token), '')
    } catch (e) {
      console.warn('moveDataFromAnotherAccount:axios', e)
      handleAxiosError(e)
    }
  }
}

const mutations = {
  storeScans: (state, scans) => {
    state.scans = scans
    cache.set(CacheKeys.SCANS, 1)
  },
  storeSurveys: (state, surveys) => {
    state.surveys = surveys
    cache.set(CacheKeys.SURVEYS, 1)
  },
  storeSurveyAssignments: (state, surveyAssignments) => {
    state.surveyAssignments = surveyAssignments
    cache.set(CacheKeys.SURVEY_ASSIGNMENTS, 1)
  },
  storeExercisePrograms: (state, exercisePrograms) => {
    state.exercisePrograms = exercisePrograms
    cache.set(CacheKeys.EXERCISE_PROGRAMS, 1)
  },
  storeGoals: (state, goals) => {
    state.goals = goals
    cache.set(CacheKeys.GOALS, 1)
  },
  storeSpecialists: (state, specialists) => {
    state.specialists = specialists
    cache.set('specialists', 1)
  },
  setActiveSubordinate: (state, subordinateId) => {
    if (subordinateId !== state.activeSubordinateId) {
      state.activeSubordinateId = subordinateId

      state.scans = []
      state.goals = []
      state.exercisePrograms = []
      state.surveys = []
      state.surveyAssignments = []

      cache.del(CacheKeys.SCANS)
      cache.del(CacheKeys.GOALS)
      cache.del(CacheKeys.EXERCISE_PROGRAMS)
      cache.del(CacheKeys.SURVEYS)
      cache.del(CacheKeys.SURVEY_ASSIGNMENTS)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
