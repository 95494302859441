<template>
  <div class="problem-areas">
    <img v-if="isMale" src="./../../assets/img/scans/male.svg">
    <img v-else src="./../../assets/img/scans/female.svg">
    <div class="problem-areas-overlay" :class="{clickable: clickableAreas}">
      <div class="neck" :class="{ 'marked': areas.includes('NeckAndHead') }" @click="onAreaClick('NeckAndHead')"></div>
      <div class="left_shoulder" :class="{ 'marked': areas.includes('LeftShoulder') }" @click="onAreaClick('LeftShoulder')"></div>
      <div class="right_shoulder" :class="{ 'marked': areas.includes('RightShoulder') }" @click="onAreaClick('RightShoulder')"></div>
      <div class="left_upper_arm" :class="{ 'marked': areas.includes('LeftUpperArm') }" @click="onAreaClick('LeftUpperArm')"></div>
      <div class="right_upper_arm" :class="{ 'marked': areas.includes('RightUpperArm') }" @click="onAreaClick('RightUpperArm')"></div>
      <div class="left_elbow" :class="{ 'marked': areas.includes('LeftElbow') }" @click="onAreaClick('LeftElbow')"></div>
      <div class="right_elbow" :class="{ 'marked': areas.includes('RightElbow') }" @click="onAreaClick('RightElbow')"></div>
      <div class="left_lower_arm" :class="{ 'marked': areas.includes('LeftLowerArm') }" @click="onAreaClick('LeftLowerArm')"></div>
      <div class="right_lower_arm" :class="{ 'marked': areas.includes('RightLowerArm') }" @click="onAreaClick('RightLowerArm')"></div>
      <div class="left_hand" :class="{ 'marked': areas.includes('LeftWristAndHand') }" @click="onAreaClick('LeftWristAndHand')"></div>
      <div class="right_hand" :class="{ 'marked': areas.includes('RightWristAndHand') }" @click="onAreaClick('RightWristAndHand')"></div>
      <div class="upper_back" :class="{ 'marked': areas.includes('ThoracicSpine') }" @click="onAreaClick('ThoracicSpine')"></div>
      <div class="lower_back" :class="{ 'marked': areas.includes('LowerBack') }" @click="onAreaClick('LowerBack')"></div>
      <div class="pelvis" :class="{ 'marked': areas.includes('GroinAndLoins') }" @click="onAreaClick('GroinAndLoins')"></div>
      <div class="left_hips" :class="{ 'marked': areas.includes('LeftHip') }" @click="onAreaClick('LeftHip')"></div>
      <div class="right_hips" :class="{ 'marked': areas.includes('RightHip') }" @click="onAreaClick('RightHip')"></div>
      <div class="left_upper_leg" :class="{ 'marked': areas.includes('LeftThigh') }" @click="onAreaClick('LeftThigh')"></div>
      <div class="right_upper_leg" :class="{ 'marked': areas.includes('RightThigh') }" @click="onAreaClick('RightThigh')"></div>
      <div class="left_knee" :class="{ 'marked': areas.includes('LeftKnee') }" @click="onAreaClick('LeftKnee')"></div>
      <div class="right_knee" :class="{ 'marked': areas.includes('RightKnee') }" @click="onAreaClick('RightKnee')"></div>
      <div class="left_lower_leg" :class="{ 'marked': areas.includes('LeftShin') }" @click="onAreaClick('LeftShin')"></div>
      <div class="right_lower_leg" :class="{ 'marked': areas.includes('RightShin') }" @click="onAreaClick('RightShin')"></div>
      <div class="left_foot" :class="{ 'marked': areas.includes('LeftAnkleAndFoot') }" @click="onAreaClick('LeftAnkleAndFoot')"></div>
      <div class="right_foot" :class="{ 'marked': areas.includes('RightAnkleAndFoot') }" @click="onAreaClick('RightAnkleAndFoot')"></div>
    </div>
  </div>
</template>

<script>
import {Gender} from '../../helpers/enums'

export default {
  props: {
    areas: {type: Array, default: () => []},
    gender: {type: String, default: () => Gender.UNKNOWN},
    clickableAreas: {type: Boolean, default: false}
  },
  computed: {
    isMale: function () {
      return this.gender === Gender.MALE
    },
    imgSrc: () => {
      return './../../assets/img/scans/' + this.gender + '.svg'
    }
  },
  methods: {
    onAreaClick (area) {
      if (this.clickableAreas) {
        this.$emit('click-area', area)
      }
    }
  }
}
</script>

<style scoped>
    .problem-areas{
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .problem-areas *{
        padding:0;
    }

    .problem-areas img{
        width: 100%;
        height: auto;
        max-height: 300px;
    }

    .problem-areas-overlay{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        max-width: 300px;
        margin: auto;
    }
    .problem-areas-overlay div{
        position: absolute;
        border-radius: 50%;
        background: rgb(222, 222, 222, .5);
    }

    .problem-areas-overlay div.marked{
        background: rgb(255, 0, 0, .5);
    }

    .problem-areas-overlay.clickable div:hover {
        border: solid rgb(222, 222, 222) 2px;
    }

    .neck{
        left: 47.2%;
        right: 47.2%;
        top: 11.4%;
        height: 10%;
    }

    .left_shoulder{
        left: 39%;
        top: 20%;
        height: 6%;
        width: 6%;
    }

    .right_shoulder{
        right: 39%;
        top: 20%;
        height: 6%;
        width: 6%;
    }

    .left_upper_arm{
        left: 37.7%;
        top: 27.2%;
        height: 10%;
        width: 4%;
        transform: rotate(5deg);
    }

    .right_upper_arm{
        right: 37.7%;
        top: 27.2%;
        height: 10%;
        width: 4%;
        transform: rotate(-5deg);
    }

    .left_elbow{
        left: 36.55%;
        top: 38%;
        height: 3.7%;
        width: 3.7%;
    }

    .right_elbow{
        right: 36.55%;
        top: 38%;
        height: 3.7%;
        width: 3.7%;
    }

    .left_lower_arm{
        left: 36.35%;
        top: 42.3%;
        height: 6.7%;
        width: 3%;
        transform: rotate(3.5deg);
    }

    .right_lower_arm{
        right: 36.35%;
        top: 42.3%;
        height: 6.7%;
        width: 3%;
        transform: rotate(-3.5deg);
    }

    .left_hand{
        left: 36.3%;
        top: 50%;
        height: 2.3%;
        width: 2.3%;
    }

    .right_hand{
        right: 36.3%;
        top: 50%;
        height: 2.3%;
        width: 2.3%;
    }

    .upper_back{
        left: 42.9%;
        right: 42.9%;
        top: 22.4%;
        height: 14%;
    }

    .lower_back{
        left: 46%;
        right: 46%;
        top: 37%;
        height: 9.5%;
    }

    .pelvis{
        left: 47.1%;
        right: 47.1%;
        top: 47.2%;
        height: 6%;
    }

    .left_hips{
        left: 41.1%;
        top: 50.8%;
        height: 5.8%;
        width: 5.8%;
    }

    .right_hips{
        right: 41.1%;
        top: 50.8%;
        height: 5.8%;
        width: 5.8%;
    }

    .left_upper_leg{
        left: 42.4%;
        top: 57%;
        height: 13%;
        width: 5.7%;
    }

    .right_upper_leg{
        right: 42.4%;
        top: 57%;
        height: 13%;
        width: 5.7%;
    }

    .left_knee{
        left: 42.6%;
        top: 71%;
        height: 6.6%;
        width: 5%;
    }

    .right_knee{
        right: 42.6%;
        top: 71%;
        height: 6.6%;
        width: 5%;
    }

    .left_lower_leg{
        left: 43.3%;
        top: 79%;
        height: 12%;
        width: 4%;
        transform: rotate(-7deg);
    }

    .right_lower_leg{
        right: 43.3%;
        top: 79%;
        height: 12%;
        width: 4%;
        transform: rotate(7deg);
    }

    .left_foot{
        left: 45%;
        top: 92%;
        height: 7%;
        width: 2.6%;
        transform: rotate(0.5deg);
    }

    .right_foot{
        right: 45%;
        top: 92%;
        height: 7%;
        width: 2.6%;
        transform: rotate(0.5deg);
    }
</style>
