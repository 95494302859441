

















































































































import Vue from 'vue'
import {
  getSeekerToSpecialistStatus,
  SeekerToSpecialist, SeekerToSpecialistStatus
} from '@/models/users'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { Library } from '@/models/data-sharing'
import { ListItem } from '@/models/vuetify'

const SEEKERS_PER_PAGE = 10
const STUB_SEEKER_PREFIX = '__stub'

interface SeekerToDisplay extends SeekerToSpecialist {
  status: SeekerToSpecialistStatus | null
}

export default Vue.extend({
  data: function () {
    return {
      pageIndex: 1,
      sortBy: [
        {'label': this.$i18n.t('specialist.seekers.sort_by.' + 'DisplayName'), 'value': 'DisplayName'},
        {'label': this.$i18n.t('specialist.seekers.sort_by.' + 'BirthDate'), 'value': 'BirthDate'},
        {'label': this.$i18n.t('specialist.seekers.sort_by.' + 'LastScanCapturedAt'), 'value': 'LastScanCapturedAt'}
      ],
      seekerSort: 'DisplayName',
      sortOrder: 1
    }
  },

  computed: {
    seekerFilter: {
      get (): string { return this.$store.state.specialist.seekerList.search },
      set (value: string) { this.updateSeekerList({ search: value }) }
    },
    selectedLibraries: {
      get (): string[] { return this.$store.state.specialist.seekerList.libraries },
      set (value: string[]) { this.updateSeekerList({ libraries: value }) }
    },
    seekerStatus: {
      get (): SeekerToSpecialistStatus | null {
        return this.$store.state.specialist.seekerList.status
      },
      set (value: SeekerToSpecialistStatus | null) {
        this.updateSeekerList({ status: value })
      }
    },
    filterSeekers: function (): SeekerToDisplay[] {
      let result: SeekerToDisplay[] = this.seekers.map((s: SeekerToSpecialist) => ({
        ...s,
        status: getSeekerToSpecialistStatus(s)
      }))

      // Filter by status
      if ((this.seekerStatus ?? '') !== '') {
        result = result.filter(s => s.status === this.seekerStatus)
      }

      // Filter by libraries
      if (this.selectedLibraries.length === 0) {
        result = []
      } else if (!this.allLibrariesSelected) {
        result = result.filter(seeker =>
          seeker.LibraryIds.some(id => this.selectedLibraries.includes(id)))
      }

      // Filter by the search string
      if ((this.seekerFilter ?? '') !== '') {
        const seekerValue = this.seekerFilter.toLocaleLowerCase()
        result = result.filter(e => {
          return e.DisplayName?.toLocaleLowerCase()?.includes(seekerValue) === true ||
            e.Email?.toLocaleLowerCase()?.includes(seekerValue) === true
        })
      }

      return result
    },
    sortedSeekers (): SeekerToDisplay[] {
      if (this.seekerSort) {
        return this.filterSeekers.slice().sort((a, b) => {
          const aRecord = (a as unknown) as Record<string, string | undefined>
          const bRecord = (b as unknown) as Record<string, string | undefined>
          const sortA = (aRecord[this.seekerSort] ?? '').toLocaleLowerCase()
          const sortB = (bRecord[this.seekerSort] ?? '').toLocaleLowerCase()
          // Always place absent values to the bottom
          if (sortA === '' && sortB !== '') return 1
          if (sortA !== '' && sortB === '') return -1
          return sortA < sortB ? this.sortOrder : sortA > sortB ? -this.sortOrder : 0
        })
      } else {
        return this.filterSeekers
      }
    },
    sortedLibraries (): Library[] {
      if (this.libraries != null) {
        let libs: Library[] = this.libraries.slice()
        libs = libs.sort((a, b) => {
          return a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 1
        })
        return libs.sort((a, b) => {
          var nameA = a.OwnerName.toUpperCase() // ignore upper and lowercase
          var nameB = b.OwnerName.toUpperCase() // ignore upper and lowercase Name
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          // names must be equal
          return 0
        })
      } else {
        return []
      }
    },
    seekersToDisplay: function (): SeekerToDisplay[] {
      const startIndex = SEEKERS_PER_PAGE * (this.pageIndex - 1)
      let result = this.sortedSeekers
      result = result.slice(startIndex, startIndex + SEEKERS_PER_PAGE)
      if (this.pageIndex > 1) {
        while (result.length < SEEKERS_PER_PAGE) {
          result.push({
            Id: `${STUB_SEEKER_PREFIX}${result.length}`,
            FirstName: '',
            LastName: '',
            LibraryIds: [],
            LastJoinedAt: '',
            status: null
          })
        }
      }
      return result
    },
    pageCount: function (): number {
      let result = Math.floor((this.filterSeekers.length + SEEKERS_PER_PAGE - 1) / SEEKERS_PER_PAGE)
      return result
    },
    allLibrariesSelected (): boolean {
      return this.selectedLibraries.length === this.libraries.length
    },
    statusItems: function (): ListItem[] {
      return [
        SeekerToSpecialistStatus.WaitingForAdvice,
        SeekerToSpecialistStatus.ActiveContact,
        SeekerToSpecialistStatus.RecentlyJoined
      ].map(v => ({
        value: v,
        text: this.$t(`enums.SeekerToSpecialistStatus.${v}`).toString()
      }))
    },
    ...mapState('specialist', ['seekers', 'libraries']),
    ...mapGetters('user', ['getLastLogin'])
  },

  methods: {
    selectAllLibraries () {
      this.selectedLibraries = (this.libraries as Library[]).map(l => l.Id)
    },
    toggleSelectAllLibraries () {
      if (this.allLibrariesSelected) {
        this.selectedLibraries = []
      } else {
        this.selectAllLibraries()
      }
    },
    isStub (seeker: SeekerToSpecialist): boolean {
      return seeker.Id.startsWith(STUB_SEEKER_PREFIX)
    },
    getStatusColor (status: SeekerToSpecialistStatus): string {
      switch (status) {
        case SeekerToSpecialistStatus.WaitingForAdvice: return 'MProCoral'
        case SeekerToSpecialistStatus.ActiveContact: return 'MProPurple'
        case SeekerToSpecialistStatus.RecentlyJoined: return 'MProYellow'
      }
    },
    ...mapActions('specialist', ['ensureSpecialistSeekersLoaded', 'ensureSpecialistLibrariesLoaded']),
    ...mapMutations('specialist', ['updateSeekerList'])
  },

  watch: {
    filterSeekers () {
      this.pageIndex = 1
    },
    libraries () {
      this.selectAllLibraries()
    }
  },

  created () {
    this.ensureSpecialistSeekersLoaded()
    this.ensureSpecialistLibrariesLoaded()
  },
  mounted () {
    this.selectAllLibraries()
  }
})
