






















































































import Vue from 'vue'
import { Location } from 'vue-router'
import { mapActions, mapMutations, mapState } from 'vuex'
import Lo from 'lodash'
import IconSurveys from '@/components/icons/Surveys.vue'
import IconExercises from '@/components/icons/Exercises.vue'
import ExerciseProgramComp from '@/components/exercises/exercise_program.vue'
import { Scan } from '@/models/scans'
import { getScanIcon, IconInfo } from '@/models/ui'
import { ExerciseProgram } from '@/models/exercises'
import ProgramAnalyzer from '@/components/exercises/program-analyzer'
import { getSeekerToSpecialistStatus, SeekerToSpecialist, SeekerToSpecialistStatus } from '@/models/users'
import { SurveyAssignment } from '@/models/surveys'

const MAX_LIST_ITEMS = 15

export default Vue.extend({
  components: {
    'mpro-icon-surveys': IconSurveys,
    'mpro-icon-exercises': IconExercises,
    'mpro-exercise-program': ExerciseProgramComp
  },

  data: () => ({
    loading: true
  }),

  computed: {
    ...mapState('specialist', [
      'scans', 'libraries', 'seekers',
      'surveyAssignments', 'assignedPrograms'
    ]),
    waitingForAdviceCount: function (): number {
      return (this.seekers as SeekerToSpecialist[])
        .filter(s => getSeekerToSpecialistStatus(s) === SeekerToSpecialistStatus.WaitingForAdvice)
        .length
    },
    activeContactCount: function (): number {
      return (this.seekers as SeekerToSpecialist[])
        .filter(s => getSeekerToSpecialistStatus(s) === SeekerToSpecialistStatus.ActiveContact)
        .length
    },
    latestScans: function (): Scan[] {
      return Lo(this.scans)
        .orderBy(s => s.CapturedAt, 'desc')
        .take(MAX_LIST_ITEMS)
        .value()
    },
    activePrograms: function (): ExerciseProgram[] {
      let programs = this.assignedPrograms as ExerciseProgram[]
      programs = Lo(programs)
        .filter(ProgramAnalyzer.isActive)
        .orderBy(ProgramAnalyzer.getEndDate)
        .take(MAX_LIST_ITEMS)
        .value()
      programs.forEach(p => {
        p.progress = ProgramAnalyzer.getProgress(p)
      })
      return programs
    },
    surveyAssignmentCount: function (): number {
      return (this.surveyAssignments as SurveyAssignment[])
        .filter(sa => sa.ResultSurveyId == null)
        .length
    }
  },

  methods: {
    ...mapActions(['setHeaderTitle']),
    ...mapActions('specialist', [
      'ensureSpecialistScansLoaded', 'ensureSurveyAssignmentsLoaded',
      'ensureAssignedProgramsLoaded'
    ]),
    ...mapMutations('user', ['setMenuOpen']),
    ...mapMutations('specialist', ['updateSeekerList']),
    openWaitingSeekerList () {
      this.openSeekerList(SeekerToSpecialistStatus.WaitingForAdvice)
    },
    openContactSeekerList () {
      this.openSeekerList(SeekerToSpecialistStatus.ActiveContact)
    },
    openSeekerList (status: SeekerToSpecialistStatus) {
      this.updateSeekerList({
        search: '',
        libraries: (this.libraries as { Id: string }[]).map(l => l.Id),
        status
      })
      this.setMenuOpen(true)
    },
    getScanLocation (scanId: string): Location {
      return {
        name: 'Scan',
        params: { scanId }
      }
    },
    getScanIcon (scan: Scan): IconInfo {
      return getScanIcon(scan)
    },
    getProgramLocation (program: ExerciseProgram): Location {
      return {
        name: 'clientExercises',
        params: {
          seekerId: program.SeekerId
        },
        query: {
          programId: program.Id
        }
      }
    }
  },

  async created () {
    await Promise.all([
      this.ensureSpecialistScansLoaded(),
      this.ensureSurveyAssignmentsLoaded(),
      this.ensureAssignedProgramsLoaded()
    ])
    this.loading = false
  },
  mounted () {
    this.setHeaderTitle(undefined)
  }
})
