









































































































































































import Vue, { PropType } from 'vue'
import Lo from 'lodash'
import { TranslateResult } from 'vue-i18n'
import { mapActions, mapGetters } from 'vuex'
import ConfirmationDialog from '@/components/elements/confirmation-dialog.vue'
import { MessageKind } from '@/helpers/enums'
import { SharedDataKind, Organization, OrganizationBase, DataSharing, ChangeDataSharingResult } from '@/models/data-sharing'
import { ListItem } from '@/models/vuetify'
import { getDataSharingStatusIcon, IconInfo } from '@/models/ui'
import Utils from '@/helpers/utils'

type TabName = 'organization' | 'sharing' | 'note'

function resetScope (scope?: SharedDataKind[]) {
  return {
    scans3D: scope?.includes(SharedDataKind.Scans) === true,
    scans2D: scope?.includes(SharedDataKind.Scans2D) === true,
    surveys: scope?.includes(SharedDataKind.Surveys) === true,
    goalsExercises: scope?.includes(SharedDataKind.GoalsAndExercises) === true
  }
}

function resetData (dataSharing?: DataSharing) {
  return {
    activeTab: dataSharing == null ? 'organization' : 'sharing' as TabName,
    loadedOrganizations: new Array<Organization>(0),
    searchText: null as string | null,
    sameCountry: false,
    supportedLanguages: new Array<string>(0),
    selectedOrganization:
      dataSharing == null
        ? undefined
        : {
          Code: dataSharing.OrganizationCode,
          Name: dataSharing.OrganizationName,
          Offers3DService: dataSharing.OrganizationOffers3DService
        } as OrganizationBase,
    effectiveDataSharing: dataSharing,
    validated: false,
    scope: resetScope(dataSharing?.Scope),
    note: '',
    processing: false,
    revoking: false
  }
}

export default Vue.extend({
  components: {
    'mpro-confirmation': ConfirmationDialog
  },

  props: {
    value: { type: Boolean, default: false },
    subordinateId: String,
    dataSharing: Object as PropType<DataSharing>,
    organizationCodeFromWidget: String,
    existingDataSharings: { type: Array as PropType<DataSharing[]>, default: [] }
  },

  data: () => resetData(),

  computed: {
    ...mapGetters('user', ['getSeeker']),
    seekerCountryCode: function (): string {
      return this.getSeeker.CountryCode
    },
    seekerCountryName: function (): string {
      return this.$isoCountries.getName(this.seekerCountryCode, this.$i18n.locale)
    },
    organizations: function (): Organization[] {
      let result = this.loadedOrganizations
      if (this.sameCountry) {
        result = result.filter(o => o.CountryCode.toUpperCase() === this.seekerCountryCode.toUpperCase())
      }
      if (this.supportedLanguages.length > 0) {
        result = result.filter(o => o.SupportedLanguages.some(lang => this.supportedLanguages.includes(lang.toLowerCase())))
      }
      const search = (this.searchText || '').toLocaleLowerCase()
      if (search.length > 0) {
        result = result.filter(o => {
          return o.Name.toLocaleLowerCase().includes(search) ||
            (o.Description || '').toLocaleLowerCase().includes(search)
        })
      }
      return result
    },
    languageFilterItems: function (): ListItem[] {
      return Lo.chain(this.loadedOrganizations)
        .flatMap(o => o.SupportedLanguages)
        .map(lang => lang.toLowerCase()).uniq()
        .map(lang => ({
          value: lang,
          text: this.$isoLanguages.getName(lang, this.$i18n.locale) ?? lang.toUpperCase()
        }))
        .sortBy(item => item.text.toLocaleLowerCase())
        .value()
    },
    title: function (): TranslateResult {
      return this.activeTab === 'organization'
        ? this.$t('account.data-sharing.select-organization.title')
        : this.$t('account.data-sharing.share-data.title')
    },
    subTitle: function (): string | undefined {
      return this.activeTab === 'sharing' || this.activeTab === 'note'
        ? this.selectedOrganization?.Name
        : undefined
    },
    creatingNew: function (): boolean {
      return this.effectiveDataSharing == null
    },
    showAccept: function (): boolean {
      return this.creatingNew
        ? this.activeTab === 'note'
        : this.activeTab === 'sharing'
    },
    canShare: function (): boolean {
      return this.selectedOrganization != null && !this.scopeIsEmpty
    },
    hintFor3DScans: function (): TranslateResult {
      return this.selectedOrganization?.Offers3DService === true
        ? this.$t('account.data-sharing.share-data.hints.scans-3d')
        : this.$t('account.data-sharing.share-data.hints.scans-3d-no-service')
    },
    acceptButtonText: function (): TranslateResult {
      return this.doRevoke ? this.$t('account.data-sharing.share-data.revoke-button')
        : this.creatingNew ? this.$t('account.data-sharing.share-data.share-button')
          : this.$t('account.data-sharing.share-data.update-button')
    },
    scopeIsEmpty: function (): boolean {
      return this.scopeArray.length === 0
    },
    scopeIsFull: function (): boolean {
      return this.scopeArray.length === 4
    },
    scopeArray: function (): SharedDataKind[] {
      const result: SharedDataKind[] = []
      if (this.scope.scans3D) result.push(SharedDataKind.Scans)
      if (this.scope.scans2D) result.push(SharedDataKind.Scans2D)
      if (this.scope.surveys) result.push(SharedDataKind.Surveys)
      if (this.scope.goalsExercises) result.push(SharedDataKind.GoalsAndExercises)
      return result
    },
    scopeText: function (): string {
      return this.scopeArray.map(s => this.$t(`enums.SharedDataKind.${s}`)).join(', ')
    },
    doRevoke: function (): boolean {
      return !this.creatingNew && this.scopeIsEmpty
    },
    revokeConfirmationMessage: function (): TranslateResult {
      return this.$t('account.data-sharing.revoke-confirmation-message', {
        organization: this.selectedOrganization?.Name
      })
    }
  },

  asyncComputed: {
    loadedOrganizations: {
      async get (): Promise<Organization[]> {
        const result: Organization[] = await this.loadOrganizationsForDataSharing()
        return Lo.sortBy(result, o => o.Name.toLocaleLowerCase())
      },
      default: [],
      shouldUpdate: function () {
        return this.value && this.$asyncComputed.loadedOrganizations.state !== 'success' && this.activeTab === 'organization'
      }
    }
  },

  watch: {
    activeTab: function (): void {
      this.validated = false
    },
    value: function (newValue: boolean): void {
      // reset state on open/close
      this.reset()

      if (newValue && this.dataSharing == null && this.organizationCodeFromWidget != null) {
        this.autoSelectOrganization()
      }
    },
    effectiveDataSharing: function (): void {
      this.scope = resetScope(this.effectiveDataSharing?.Scope)
    }
  },

  methods: {
    ...mapActions('seeker', ['loadOrganizationsForDataSharing', 'grantDataSharing', 'revokeDataSharing']),
    ...mapActions(['showGlobalMessage', 'loadPublicOrganization']),
    close (): void {
      if (!this.processing) {
        this.onInput(false)
      }
    },
    reset () {
      const { loadedOrganizations: _, ...initialData } = resetData(this.dataSharing)
      Object.assign(this.$data, initialData)
    },
    onInput (v: boolean) {
      this.$emit('input', v)
    },
    selectOrganization (organization: OrganizationBase): void {
      this.selectedOrganization = organization
      this.effectiveDataSharing = this.getExistingDataSharing(this.selectedOrganization.Code)
      this.activeTab = 'sharing'
    },
    async autoSelectOrganization () {
      const org: OrganizationBase = await this.loadPublicOrganization(this.organizationCodeFromWidget)
      if (org != null) {
        this.selectOrganization(org)
        // Add a small delay to allow a 'sharing' tab to be fully rendered
        // Otherwise, the full scope checkbox may end in a wrong state
        await Utils.delay(100)
        this.toggleFullScope(true)
      } else {
        this.close()
      }
    },
    getExistingDataSharing (organizationCode: string): DataSharing | undefined {
      return this.existingDataSharings.find(ds => ds.OrganizationCode === organizationCode)
    },
    getOrganizationIcon (organizationCode: string): IconInfo {
      const ds = this.getExistingDataSharing(organizationCode)
      return (ds == null ? null : getDataSharingStatusIcon(ds.Status)) ?? {}
    },
    toggleFullScope (v: boolean): void {
      const selected = v === true
      for (const key in this.scope) {
        this.$set(this.scope, key, selected)
      }
    },
    continueToNote () {
      this.validated = true
      if (this.canShare) {
        this.activeTab = 'note'
      }
    },
    accept () {
      this.validated = true

      if (this.doRevoke) {
        this.revoking = true
      } else {
        this.shareData()
      }
    },
    async shareData (): Promise<void> {
      if (!this.canShare) return

      this.processing = true
      let result: ChangeDataSharingResult
      try {
        result = await this.grantDataSharing({
          organizationCode: this.selectedOrganization?.Code,
          scope: this.scopeArray,
          fromWidget: this.organizationCodeFromWidget != null,
          notes: this.creatingNew ? this.note : undefined,
          subordinateId: this.subordinateId
        })
      } catch (e) {
        const message = this.$errorMessage(e)
        this.showGlobalMessage({kind: MessageKind.ERROR, text: message})
        return
      } finally {
        this.processing = false
      }

      this.close()
      this.showGlobalMessage({
        kind: MessageKind.SUCCESS,
        text: this.creatingNew ? this.$t('account.data-sharing.granted') : this.$t('account.data-sharing.updated')
      })
      this.$emit('update', result)
    },
    async revokeSharing (): Promise<void> {
      if (this.selectedOrganization == null) return

      this.processing = true
      let result: ChangeDataSharingResult
      try {
        result = await this.revokeDataSharing({
          subordinateId: this.subordinateId,
          organizationCode: this.selectedOrganization.Code
        })
      } catch (e) {
        const message = this.$errorMessage(e)
        this.showGlobalMessage({kind: MessageKind.ERROR, text: message})
        return
      } finally {
        this.processing = false
      }

      this.close()
      this.showGlobalMessage({ kind: MessageKind.SUCCESS, text: this.$t('account.data-sharing.revoked') })
      this.$emit('update', result)
    }
  }
})
