import axios from 'axios'

const actions = {
  registerClickConnectionWidget: async (_, { organizationCode }) => {
    try {
      await axios.post('/activities/click-connection-widget', { organizationCode })
    } catch (e) {
      console.warn('registerClickConnectionWidget:axios', e)
    }
  }
}

export default {
  namespaced: true,
  actions
}
