import moment from 'moment'

function getDuration (program) {
  return moment.duration(program.DurationWeeks, 'weeks').asMilliseconds()
}

function getSinceStart (program) {
  const start = moment(program.StartDate)
  return moment().diff(start)
}

function isPending (program) {
  const sinceStart = getSinceStart(program)
  return sinceStart < 0
}

function isActive (program) {
  const sinceStart = getSinceStart(program)
  const duration = getDuration(program)
  return sinceStart > 0 && sinceStart < duration
}

function isFinished (program) {
  const sinceStart = getSinceStart(program)
  const duration = getDuration(program)
  return sinceStart >= duration
}

function getProgress (program) {
  const sinceStart = getSinceStart(program)
  const duration = getDuration(program)
  return sinceStart <= 0 ? 0
    : sinceStart >= duration ? 100
      : sinceStart / duration * 100
}

function daysSinceFinished (program) {
  const sinceStart = moment.duration(getSinceStart(program), 'milliseconds')
  const duration = getDuration(program)
  return Math.ceil(sinceStart.subtract(duration).asDays())
}

function getEndDate (program) {
  return moment(program.StartDate).add(program.DurationWeeks, 'w').startOf('day').format()
}

function isScheduledForToday (program) {
  const start = moment(program.StartDate)
  const today = moment().startOf('day')
  const diff = today.diff(start)
  const duration = getDuration(program)

  if (diff < 0 || diff >= duration) return false

  const diffDays = Math.floor(moment.duration(diff, 'milliseconds').asDays())
  switch (program.SessionRepeat.toLowerCase()) {
    case 'daily': return true
    case 'everyotherday': return diffDays % 2 === 0
    case 'weekly': return diffDays % 7 === 0
    case 'custom': return ((1 << today.day()) & program.WeekDaysMask) !== 0
    default: return false
  }
}

export default {
  isPending,
  isActive,
  isFinished,
  getProgress,
  daysSinceFinished,
  getEndDate,
  isScheduledForToday
}
