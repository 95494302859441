<template>
  <div class="d-inline-block">
    <div class="d-flex">
      <mpro-grant-right :organization-code="organizationCode"
                        @grant-right="$emit('update')"/>
    </div>

    <v-skeleton-loader type="table-head, table-body" :loading="loading">
      <mpro-rights-table :rights="rights" hide-object
                         @revoke-right="$emit('update')"/>
    </v-skeleton-loader>
  </div>
</template>

<script>
import RightsTable from '../rights/table'
import GrantRight from '../rights/grant-right'

export default {
  components: {
    'mpro-rights-table': RightsTable,
    'mpro-grant-right': GrantRight
  },

  props: {
    organizationCode: {type: String, default: null},
    rights: {type: Array, default: () => []}
  },

  computed: {
    loading: function () {
      return this.organizationCode == null
    }
  }
}
</script>
