import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
// TODO: Make a subset of the font to decrease file sizes (the stylesheet and the font itself)
import '@mdi/font/css/materialdesignicons.css'
import '@/scss/overrides.sass'
import User from '@/components/icons/iconfont/user.vue'
import Patients from '@/components/icons/iconfont/patients.vue'
import Menu from '@/components/icons/iconfont/menu.vue'
import Close from '@/components/icons/iconfont/close.vue'
import Chats from '@/components/icons/iconfont/chats.vue'

Vue.use(Vuetify)

const opts = {
  options: {
    customProperties: true
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        // From Branding
        MProPurple: '#310FDD',
        MProBlue: '#00DBFF',
        MProCoral: '#FF005D',
        MProYellow: '#FFD800',
        MProGreen: '#00E693',
        MProDark1: '#1B1B1B',
        MProDark2: '#242424',
        MProWhite1: '#FFFFFF',

        // From UI Design
        MProDark3: '#282828',
        MProDark4: '#2e2e2e',
        MProDark5: '#323232',
        MProWhite4: '#646464',
        MProWhite3: '#818181',
        MProWhite2: '#F8F8F8'
      },
      light: {
        // From Branding
        MProPurple: '#310FDD',
        MProBlue: '#00DBFF',
        MProCoral: '#FF005D',
        MProYellow: '#FFD800',
        MProGreen: '#00E693',
        MProDark1: '#1B1B1B',
        MProDark2: '#242424',
        MProWhite1: '#FFFFFF',

        // From UI Design
        MProDark3: '#282828',
        MProDark4: '#2e2e2e',
        MProDark5: '#323232',
        MProWhite4: '#646464',
        MProWhite3: '#818181',
        MProWhite2: '#F8F8F8'
      }
    }
  },
  icons: {
    iconfont: 'mdi',
    values: {
      user: { component: User },
      patients: { component: Patients },
      menu: { component: Menu },
      close: { component: Close },
      chats: { component: Chats }
    }
  }
}

export default new Vuetify(opts)
