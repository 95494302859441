<template>
  <div>
    <v-data-table :items="items" item-key="key"
                  :headers="headers"
                  disable-pagination hide-default-footer
                  :no-data-text="$t('admin.no_records')">
      <template #item.SpecialistName="{item}">
        <router-link class="d-flex flex-column"
                    :to="getLocationOfSpecialist(item.Specialist)">
          <span>{{item.SpecialistName}}</span>
          <span class="grey--text">{{item.Specialist}}</span>
        </router-link>
      </template>
      <template #item.OrganizationName="{item}">
        <router-link class="d-flex flex-column"
                    :to="{name: 'admin:Organization', params: {code: item.OrganizationCode}}">
          <span>{{item.OrganizationName}}</span>
          <span class="grey--text">{{item.OrganizationCode}}</span>
        </router-link>
      </template>
      <template #item.LibraryName="{item}">
        <router-link v-if="item.LibraryId"
                    :to="{name: 'admin:Library', params: {id: item.LibraryId}}">
          {{item.LibraryName}}
        </router-link>
        <span v-else>{{$t('admin.all_libraries')}}</span>
      </template>
      <template #item.GrantedByName="{item}">
        <router-link class="d-flex flex-column"
                    :to="getLocationOfSpecialist(item.GrantedBy)">
          <span v-if="item.GrantedByName">{{item.GrantedByName}}</span>
          <span class="grey--text">{{item.GrantedBy}}</span>
        </router-link>
      </template>
      <template #item.GrantedAt="{value}">
        {{$formatDateTime(value)}}
      </template>
      <template #item.key="{item}">
        <v-btn icon
               :loading="item.revoking" :disabled="item.revoking"
              @click="onRevokeRightClick(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {MessageKind} from '@/helpers/enums'

export default {
  props: {
    rights: {type: Array, default: () => []},
    hideSubject: {type: Boolean, default: false},
    hideObject: {type: Boolean, default: false}
  },

  computed: {
    ...mapGetters('admin', ['getSpecialistUuid']),
    items: function () {
      return this.rights.map(r => ({...r, key: this.getItemKey(r), revoking: false}))
    },
    headers: function () {
      const result = [
        { text: this.$t('admin.rights.right'), value: 'Right' },
        { text: this.$t('admin.rights.granted_by'), value: 'GrantedByName' },
        { text: this.$t('admin.rights.granted_at'), value: 'GrantedAt' },
        { text: this.$t('admin.rights.description'), value: 'Description' },
        { text: '', value: 'key', align: 'start', sortable: false, filterable: false }
      ]
      if (!this.hideObject) {
        result.unshift(
          { text: this.$t('admin.rights.organization'), value: 'OrganizationName' },
          { text: this.$t('admin.rights.library'), value: 'LibraryName' }
        )
      }
      if (!this.hideSubject) {
        result.unshift({ text: this.$t('admin.rights.specialist'), value: 'SpecialistName' })
      }
      return result
    }
  },

  methods: {
    getItemKey (item) {
      return `${item.Right}\n${item.OrganizationCode}\n${item.LibraryId}\n${item.Specialist}`
    },
    getLocationOfSpecialist (login) {
      return {
        name: 'admin:Specialist',
        params: { uuid: this.getSpecialistUuid(login) }
      }
    },
    async onRevokeRightClick (item) {
      item.revoking = true
      try {
        await this.revokeRight(item)
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e)
        })
        return
      } finally {
        item.revoking = false
      }

      this.showGlobalMessage({
        kind: MessageKind.SUCCESS,
        text: this.$t('admin.right_revoked')
      })
      this.$emit('revoke-right', item)
    },
    ...mapActions(['showGlobalMessage']),
    ...mapActions('admin', ['revokeRight'])
  }
}
</script>
