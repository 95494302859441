import axios from 'axios'
import TtlCache from 'ttl-cache'
import {handleAxiosError} from '../../helpers/errors'
import {Role} from '../../helpers/enums'

// initial state
const state = {
  msal: {
    lastLogin: null
  },
  roles: [],
  mfaMode: null,
  userSettings: {
    menuOpen: false,
    darkmode: false,
    viewAs: null,
    locale: null,
    chatsOpen: false,
    metric: true,
    settingsLoadedAt: null
  },
  userSettingsCache: new TtlCache({ ttl: 3600 }),
  seeker: {},
  specialist: {},
  userLoadedAt: null
}

// getters
const getters = {
  getAllRoles: state => {
    return state.roles
  },
  getLastLogin: state => {
    return state.msal.lastLogin
  },
  getUserEmail: state => {
    return [Role.SPECIALIST, Role.ADMINISTRATOR].includes(state.userSettings.viewAs)
      ? state.specialist.Login
      : state.userSettings.viewAs === Role.SEEKER
        ? state.seeker.Login
        : null
  },
  getDisplayName: state => {
    return [Role.SPECIALIST, Role.ADMINISTRATOR].includes(state.userSettings.viewAs)
      ? state.specialist.DisplayName
      : state.userSettings.viewAs === Role.SEEKER
        ? (state.seeker.FirstName || state.seeker.LastName ? `${state.seeker.FirstName} ${state.seeker.LastName}` : state.seeker.Username)
        : null
  },
  getFirstName: state => {
    return [Role.SPECIALIST, Role.ADMINISTRATOR].includes(state.userSettings.viewAs)
      ? state.specialist.DisplayName
      : state.userSettings.viewAs === Role.SEEKER
        ? state.seeker.FirstName
        : null
  },
  getSeeker: state => {
    return state.seeker
  },
  canViewNarratives: state => {
    return state.specialist?.canViewNarratives === true
  },
  canDownloadDesktopApps: state => {
    return state.specialist?.canDownloadDesktopApps === true
  },
  isQinematicAdmin: state => {
    return state.specialist?.isQinematicAdmin === true
  },
  isQinematicStaff: state => {
    return state.specialist?.isQinematicAdmin === true ||
      state.specialist?.isQinematicAuditor === true
  },
  isMetric: state => {
    return state.userSettings.metric
  },
  getSettings: state => setting => {
    if (setting && setting in state.userSettings) {
      return state.userSettings[setting]
    } else if (setting) {
      return null
    }
    return state.userSettings
  }
}

// actions
const actions = {
  updateLoginTime: async ({commit}, options) => {
    let response = null
    try {
      response = await axios.get('/users/new-login', {
        params: {
          newTime: options.loginTime
        }
      })
    } catch (e) {
      console.log('updateLoginTime:axios', e)
      return
    }
    commit('storeLastLogin', response.data.Value)
  },
  updateUserSetting: async ({commit}, options) => {
    commit('cacheUserSetting', options)
    try {
      await axios.post('/users/settings', {
        setting: options.setting,
        value: options.value
      })
    } catch (e) {
      console.log('updateUserSetting:axios', e)
      if (options.throwOnError === true) {
        handleAxiosError(e)
      }
    }
  },
  getUserSetting: async (_, setting) => {
    let response = null
    try {
      response = await axios.get('/users/settings', {
        params: {
          setting: setting
        }
      })
    } catch (e) {
      console.log('getUserSetting:axios', e)
      return
    }
    return response
  },
  getCachedUserSetting: async ({ state, dispatch, commit }, setting) => {
    let value = state.userSettingsCache.get(setting)
    if (value === undefined) {
      const response = await dispatch('getUserSetting', setting)
      if (response && response.data.length > 0) {
        value = response.data[0] === undefined ? null : response.data[0]
        commit('cacheUserSetting', { setting, value })
      }
    }
    return value
  },
  ensureUserDataLoaded: async ({ state, commit }, options) => {
    const force = options?.force
    if (state.userLoadedAt && (Date.now() - state.userLoadedAt < process.env.VUE_APP_DEFAULT_API_RESPONSE_LIFETIME) && !force) {
      return
    }
    let response = null
    try {
      response = await axios.get('/users/user')
    } catch (e) {
      console.warn('ensureUserDataLoaded:axios', e)
      return
    }
    commit('storeUserData', response.data)
  },
  ensureUserSettingsLoaded: async ({ state, commit }, options) => {
    const force = options && options.force
    if (state.userSettings.settingsLoadedAt && (Date.now() - state.userSettings.settingsLoadedAt < process.env.VUE_APP_DEFAULT_API_RESPONSE_LIFETIME) && !force) {
      return
    }
    let response = null
    try {
      response = await axios.get('/users/portal-settings')
    } catch (e) {
      console.warn('ensureUserSettingsLoaded:axios', e)
      return
    }
    commit('storeUserSettings', response.data)
  },
  startRegistration: async (context, payload) => {
    try {
      const res = await axios.post('/users/registration', {role: Role.SEEKER, ...payload})
      return res.data
    } catch (e) {
      handleAxiosError(e)
    }
  },
  registerUser: async (_, payload) => {
    const { credentials, profile, verification, connectWithOrganization } = payload
    try {
      const response = await axios.patch('/users/registration', {
        role: Role.SEEKER,
        credentials,
        profile,
        verification,
        connectWithOrganization
      })
      return response.data
    } catch (e) {
      handleAxiosError(e)
    }
  },
  setUserMfa: async (_, { mode }) => {
    try {
      await axios.put('/users/user-mfa', { mode })
    } catch (e) {
      handleAxiosError(e)
    }
  },
  updateSeekerProfile: async (context, {profile}) => {
    try {
      const response = await axios.patch('/users/seeker-profile', {profile})
      return response.data
    } catch (e) {
      handleAxiosError(e)
    }
  },
  async deleteSeekerAccount () {
    try {
      await axios.delete('/users/seeker-profile')
    } catch (e) {
      console.warn('deleteSeekerAccount:axios', e)
      handleAxiosError(e)
    }
  },
  async createSubordinateSeeker (context, {profile}) {
    try {
      const response = await axios.post('/users/sub-profiles', {profile})
      return response.data
    } catch (e) {
      handleAxiosError(e)
    }
  },
  async updateSubordinateSeeker (context, {subordinateId, profile}) {
    try {
      const response = await axios.patch(`users/sub-profiles/${encodeURIComponent(subordinateId)}`, {profile})
      return response.data
    } catch (e) {
      handleAxiosError(e)
    }
  },
  async setSeekerPinCode (context, {pinCode}) {
    try {
      await axios.put('/users/seeker-pin', {
        pinCode
      })
    } catch (e) {
      handleAxiosError(e)
    }
  },
  async createScanCode (context, {subordinateId}) {
    try {
      const response = await axios.post('/users/scan-codes', {
        subordinateId
      })
      return response.data
    } catch (e) {
      handleAxiosError(e)
    }
  },
  async updateSpecialistProfile (context, profile) {
    try {
      await axios.patch('/users/specialist-profile', profile)
    } catch (e) {
      handleAxiosError(e)
    }
  },
  async changeRole ({state, commit, dispatch}, role) {
    if (role !== state.userSettings.viewAs) {
      commit('setRole', role)
      if (role != null) {
        await dispatch('updateUserSetting', {value: role, setting: 'portal.activeRole'})
        await dispatch('onUserRoleChanged', null, {root: true})
      }
    }
  }
}

// mutations
const mutations = {
  setRole: (state, payload) => {
    state.userSettings.viewAs = payload
  },
  storeLastLogin: (state, payload) => {
    if (state.msal.lastLogin == null || payload > state.msal.lastLogin) {
      state.msal.lastLogin = payload
    }
  },
  storeUserData: (state, user) => {
    state.userLoadedAt = Date.now()

    state.roles = Array.isArray(user.roles) ? user.roles : []
    if (state.roles.length === 0) {
      state.userSettings.viewAs = null
    }

    state.seeker = user.seeker
    state.specialist = user.specialist

    state.mfaMode = user.mfaMode ?? false
  },
  storeUserSettings: (state, settings) => {
    state.userSettings.settingsLoadedAt = Date.now()
    if (!state.roles.includes(state.userSettings.viewAs)) {
      let role = settings.role != null && state.roles.includes(settings.role)
        ? settings.role
        : state.roles[0]
      state.userSettings.viewAs = role
    }

    if (settings.locale != null) {
      state.userSettings.locale = settings.locale
    }

    state.userSettings.metric = (settings.metric === 'true' || settings.metric == null)
  },
  cacheUserSetting: (state, { setting, value }) => {
    state.userSettingsCache.set(setting, value)
  },
  menuToggle: state => {
    state.userSettings.menuOpen = !state.userSettings.menuOpen
  },
  chatToggle: state => {
    state.userSettings.chatsOpen = !state.userSettings.chatsOpen
  },
  setMenuOpen: (state, menuOpen) => {
    state.userSettings.menuOpen = menuOpen
  },
  setChatsOpen: (state, chatOpen) => {
    state.userSettings.chatsOpen = chatOpen
  },
  darkmodeToggle: state => {
    state.userSettings.darkmode = !state.userSettings.darkmode
  },
  setLocale: (state, locale) => {
    state.userSettings.locale = locale
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
