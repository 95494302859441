




import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { Location } from 'vue-router'

export default Vue.extend({
  computed: {
    ...mapState('admin', ['organizations'])
  },

  methods: {
    ...mapActions('admin', ['ensureOrganizationsLoaded'])
  },

  async created () {
    // There is no dashboard for administrator => redirect to a default page
    // depending on whether it's an actual admin or just an auditor
    await this.ensureOrganizationsLoaded()
    const location: Location = this.organizations.length > 0
      ? { name: 'admin:SharingSeekers' }
      : { name: 'admin:Statistics' }
    this.$router.push(location)
  }
})
