<template>
  <v-card>
    <v-card-title class="py-0 pt-0 px-1 justify-center text-subtitle-2">{{ $t(`measures.${locationName}`) }}</v-card-title>
    <v-card-text class="ma-auto d-flex py-0 pt-0 px-1">
      <div v-for="(measure, name) in locationMeasures" :key="name + '2'" class="d-flex single-measure ma-auto" style="flex-basis: 50%">
        <v-tooltip bottom>
          <template #default>
            <span v-if="measureTip(name) != null">
              {{ measureTip(name) }}
            </span>
          </template>
          <template #activator="{on}">
            <span v-if="measure.arrow != null" v-on="on"
                  class="d-block ma-auto">
              <span v-if="measure.type === 'angle'">
                {{ Math.abs(angleMeasureValue(name)) }}°
                <v-icon :style="{
                    transform: `rotate(${angleMeasureArrowRotation(name)})`,
                    color: measureColor(name, angleMeasureValue(name), measure.threshold)
                  }">
                  mdi-arrow-up-bold
                </v-icon>
              </span>
              <span v-else-if="measure.type === 'angle-direction'">
                {{ Math.abs(angleMeasureValue(name)) }}°
                <v-icon :style="{ color: measureColor(name, angleMeasureValue(name)) }"
                        :small="displacementMeasureArrow(angleMeasureValue(name), measure.arrow).includes('circle')">
                  {{ displacementMeasureArrow(angleMeasureValue(name), measure.arrow) }}
                </v-icon>
              </span>
              <span v-else-if="measure.type === 'displacement'"
                    :small="displacementMeasureArrow(displacementMeasureValue(name), measure.arrow).includes('circle')">
                {{$formatLength(Math.abs(displacementMeasureValue(name)), 'cm', isMetric)}}
                <v-icon :style="`color: ${measureColor(name)};`">
                  {{ displacementMeasureArrow(displacementMeasureValue(name), measure.arrow) }}
                </v-icon>
              </span>
            </span>
          </template>
        </v-tooltip>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { ScanMeasureArrow } from '@/models/ui'
import { mapGetters } from 'vuex'

export default {
  props: {
    locationName: String,
    locationMeasures: Object,
    assessment: Object
  },

  computed: {
    ...mapGetters('user', ['isMetric'])
  },

  methods: {
    rawMeasureValue: function (name) {
      if (this.assessment == null) return undefined

      if (name.includes('.')) {
        const nameArray = name.split('.')
        return this.assessment[nameArray[0]][nameArray[1]]
      } else {
        return this.assessment[name]
      }
    },
    displacementMeasureValue: function (name) {
      return Math.round(this.rawMeasureValue(name) * 100)
    },
    angleMeasureValue: function (name) {
      // Same as in reports. See
      // https://bitbucket.org/qinematic/core/src/master/src/Reports/Small/OverImageRenderer.cs
      // Method DrawDotPathLabelText
      let value = this.rawMeasureValue(name)
      if (name.endsWith('ForthAngle')) {
        value = -value - Math.PI
      } else if (name.endsWith('BackAngle')) {
        value = -value
      }
      return Math.round(value * 180 / Math.PI)
    },
    displacementMeasureArrow: function (value, positiveDirection) {
      if (value > 0) {
        return `mdi-arrow-${positiveDirection}-bold`
      }
      if (value < 0) {
        switch (positiveDirection) {
          case ScanMeasureArrow.Up: return 'mdi-arrow-down-bold'
          case ScanMeasureArrow.Down: return 'mdi-arrow-up-bold'
          case ScanMeasureArrow.Left: return 'mdi-arrow-right-bold'
          case ScanMeasureArrow.Right: return 'mdi-arrow-left-bold'
        }
      }
      return 'mdi-circle'
    },
    angleMeasureArrowRotation: function (name) {
      const rawValue = this.rawMeasureValue(name)
      const degrees = Math.round(rawValue * 180 / Math.PI)
      return `${degrees}deg`
    },
    measureColor: function (name, value, threshold) {
      // Same as in reports. See
      // https://bitbucket.org/qinematic/core/src/master/src/Reports/Small/OverImageRenderer.cs
      // Method DrawDotPathLabelArrow
      if (threshold > 0) {
        return Math.abs(value) <= threshold ? '#00D588' : '#FF005D'
      } else if (threshold == null) {
        if (name.endsWith('ForthAngle')) {
          return '#5184CC'
        } else if (name.endsWith('BackAngle')) {
          return '#AF108E'
        }
      }
      return '#423E3F'
    },
    measureTip (name) {
      const key = `measures.${name}`
      return this.$te(key) ? this.$t(key) : undefined
    }
  }
}
</script>

<style scoped>
.single-measure:nth-child(odd) div {
  border-right: 1px solid #000
}

.single-measure .v-card__title {
  font-size: .9rem;
}
</style>
