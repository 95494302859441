<template>
  <div v-if="metadata" class="survey">
    <h2>{{metadata.Title}}</h2>
    <p v-if="metadata.Source"><small>
      {{$t('surveys.view.source_label')}}
      <a v-if="metadata.canNavigateToSource" :href="metadata.Source" target="_blank">{{metadata.Source}}</a>
      <span v-else>{{metadata.Source}}</span>
    </small></p>
    <p v-if="metadata.Instructions" class="instruction ws-pre-line">{{metadata.Instructions}}</p>
    <ol class="ml-1 ml-sm-2 pl-sm-4 pr-8 pr-sm-0 mr-4 mr-sm-0"
        :class="isSingleQuestion(metadata) ? 'no-bullets' : ''">
      <li v-for="item in metadata.questions" :key="item.Code"
          :class="getItemClass(item)">
        <p v-if="item.Text" class="ws-pre-line">{{item.Text}}</p>
        <p v-else class="ws-pre-line">
          <b v-if="item.Title">{{item.Title}}</b>
          <br v-if="item.Title && item.Instructions"/>
          <span v-if="item.Instructions">{{item.Instructions}}</span>
        </p>
        <template v-if="item.questions && item.questions.length > 0">
          <slot v-if="item.isQuestionGrid"
                name="grid" :code="item.Code"
                :questions="item.questions"
                :errors="item.questions.map(q => errors[q.Code])"/>
          <template v-else-if="item.questions.length === 1">
            <p class="ws-pre-line">{{item.questions[0].Text}}</p>
            <slot :question="item.questions[0]"/>
          </template>
          <ol v-else  class="ml-1 ml-sm-2 pl-0 pl-sm-4">
            <li v-for="q in item.questions" :key="q.Code"
                :class="getItemClass(q)">
              <p class="ws-pre-line">{{q.Text}}</p>
              <slot :question="q"/>
            </li>
          </ol>
        </template>
        <slot v-else :question="item"/>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    metadata: {type: Object, required: true},
    errors: {type: Object, default: () => ({})}
  },
  methods: {
    isSingleQuestion (item) {
      return !Array.isArray(item.questions) || item.questions.length === 1
    },
    getItemClass (item) {
      let code
      if (Array.isArray(item.questions)) {
        code = item.questions.length === 1 ? item.questions[0].Code : null
      } else {
        code = item.Code
      }
      return {
        question: code != null,
        error: code != null ? this.errors[code] != null : false
      }
    }
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0.25em;
}
p.instruction {
  margin-bottom: 1em;
}
ol {
  list-style-position: outside;
}
ol.no-bullets {
  list-style-type: none;
}
ol ol {
  list-style-type: lower-alpha;
}
li {
  margin-bottom: 0.75em;
}
.question.error {
  border-left: 2px red solid;
  padding-left: 5px;
  background: none !important;
}
</style>
