



























import Vue from 'vue'

export default Vue.extend({
  props: {
    value: { type: Boolean, default: false },
    revoked: Boolean,
    organizationName: String,
    backupPeriodDays: Number
  },

  methods: {
    onInput (newValue: boolean) {
      this.$emit('input', newValue)
    },
    close () {
      this.onInput(false)
    }
  }
})
