<template>
  <v-container>
    <v-row v-if="isSpecialist && sharesExercises === false" justify="center">
      <v-col cols="12" md="8">
        <v-alert dense text type="info">
          {{ $t('programs.not-shared-with-specialist') }}
        </v-alert>
      </v-col>
    </v-row>
    <template v-else>
      <v-row justify="center">
        <v-col cols="12" md="7">
          <h2 v-if="isSeeker">{{ seekerTitle }}</h2>
        </v-col>
        <v-col cols="12" md="2">
          <mpro-assign-program v-if="isSpecialist" :seeker-id="seekerId"/>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <div v-if="activePrograms.length > 0">
            <div v-for="program in activePrograms" :key="program.Id">
              <mpro-exercise-program :program="program"
                                      v-model="showProgram[program.Id]" />
            </div>
          </div>
          <div v-else>
            <v-alert dense text type="success">
              <span v-if="isSpecialist && seeker != null">
                {{ $tc('programs.num-active-programs-name', 0, { name: seeker.DisplayName || seeker.Username }) }}
              </span>
              <span v-else-if="activeSubordinate != null">
                {{ $tc('programs.num-active-programs-name', 0, { name: activeSubordinate.FirstName }) }}
              </span>
              <span v-else>
                {{ $tc('programs.num-active-programs-seeker', 0) }}
              </span>
            </v-alert>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="pendingPrograms.length > 0">
        <v-col cols="12" md="8">
          <div>
            <h2>{{$t('programs.pending-programs-title')}}</h2>
            <div v-for="program in pendingPrograms" :key="program.Id">
              <mpro-exercise-programs :program="program"
                                      v-model="showProgram[program.Id]" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="finishedPrograms.length > 0">
        <v-col cols="12" md="8">
          <div>
            <h2>{{$t('programs.finished-programs-title')}}</h2>
            <div v-for="program in finishedPrograms" :key="program.Id">
              <mpro-exercise-program :program="program"
                                     v-model="showProgram[program.Id]" />
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
    <mpro-header-handler tabName="exercises" :seeker="seeker"/>
  </v-container>
</template>

<script>
import { SharedDataKind } from '@/models/data-sharing'
import { mapState, mapGetters, mapActions } from 'vuex'
import assignExercise from '../exercises/assign-program'
import ExerciseProgram from '../exercises/exercise_program'
import HeaderHandler from '../specialist/header-handler'
import ProgramAnalyzer from './program-analyzer'

function fillProgress (programs) {
  programs.forEach(p => {
    p.progress = ProgramAnalyzer.getProgress(p)
  })
  return programs
}

export default {
  components: {
    'mpro-exercise-program': ExerciseProgram,
    'mpro-assign-program': assignExercise,
    'mpro-header-handler': HeaderHandler
  },

  data: () => ({
    showProgram: {}
  }),

  computed: {
    ...mapState({
      exercises: state => state.exercises.assignedExercises,
      myPrograms: state => state.seeker.exercisePrograms
    }),
    ...mapGetters('specialist', ['getSeekerById']),
    ...mapGetters('exercises', ['getAssignedPrograms']),
    ...mapGetters(['isSpecialist', 'isSeeker']),
    ...mapGetters('seeker', ['activeSubordinate']),
    seekerId: function () {
      return this.$route.params.seekerId
    },
    programId: function () {
      return this.$route.query.programId
    },
    seeker: function () {
      return this.getSeekerById(this.seekerId)
    },
    seekerTitle: function () {
      return this.activeSubordinate == null
        ? this.$t('programs.title-seeker')
        : this.$t('programs.title-subordinate', { name: this.activeSubordinate.FirstName })
    },
    programs: function () {
      return this.seekerId
        ? this.getAssignedPrograms(this.seekerId)
        : this.myPrograms
    },
    pendingPrograms: function () {
      return fillProgress(this.programs.filter(ProgramAnalyzer.isPending))
    },
    activePrograms: function () {
      return fillProgress(this.programs.filter(ProgramAnalyzer.isActive))
    },
    finishedPrograms: function () {
      return fillProgress(this.programs.filter(ProgramAnalyzer.isFinished))
    }
  },

  asyncComputed: {
    sharesExercises: async function () {
      if (this.seekerId == null || !this.isSpecialist) return false
      const sharedDataKinds = await this.getOrLoadSharedDataKinds(this.seekerId)
      return sharedDataKinds.includes(SharedDataKind.GoalsAndExercises)
    }
  },

  watch: {
    seekerId: function () {
      this.updateExercisePrograms()
    },
    sharesExercises: function () {
      this.updateExercisePrograms()
    },
    seekerTitle: function (newValue) {
      if (this.isSeeker) {
        this.setHeaderTitle(newValue)
      }
    }
  },

  methods: {
    ...mapActions(['showGlobalMessage', 'setHeaderTitle']),
    ...mapActions('seeker', ['ensureExerciseProgramsLoaded']),
    ...mapActions('exercises', ['ensureSeekerProgramsLoaded', 'ensureAllExercisesLoaded']),
    ...mapActions('specialist', ['ensureSpecialistSeekersLoaded', 'getOrLoadSharedDataKinds']),
    updateExercisePrograms () {
      if (this.isSpecialist) {
        if (this.seekerId != null && this.sharesExercises === true) {
          this.ensureSeekerProgramsLoaded({ seekerId: this.seekerId })
        }
      } else {
        this.ensureExerciseProgramsLoaded()
      }
    }
  },

  created () {
    this.ensureAllExercisesLoaded()
    if (this.isSpecialist) {
      this.ensureSpecialistSeekersLoaded()
    }
    this.updateExercisePrograms()
  },
  mounted () {
    if (this.isSeeker) {
      this.setHeaderTitle(this.seekerTitle)
    }
    if (this.programId != null) {
      this.showProgram = {}
      this.$set(this.showProgram, this.programId, true)
      this.$router.replace({ name: this.$route.name, params: this.$route.params })
    }
  }
}
</script>
