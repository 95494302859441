<template>
  <div>
    <div class="d-flex">
      <v-btn class="ma-6" small
             @click="deleting = true">
        <v-icon small>mdi-delete</v-icon>
        <small>{{ $t('admin.delete_specialist') }}</small>
      </v-btn>
    </div>

    <v-skeleton-loader type="card-heading, paragraph" :loading="loading"
                        class="ma-6 mt-0 min-card-width">
      <mpro-editable-card :save-callback="save"
                          @end-edit="onEndEdit">
        <template #title>{{$t('admin.details_card')}}</template>
        <template #default="{readonly}">
          <mpro-specialist-details ref="details" :specialist="specialist" :readonly="readonly"/>
        </template>
      </mpro-editable-card>
    </v-skeleton-loader>

    <mpro-delete-dialog v-model="deleting"
                        :title="$t('admin.delete_specialist')"
                        :message="deleteWarning"
                        @confirm="onDeleteConfirm"/>
  </div>
</template>

<script>
import EditableCard from '@/components/elements/editable-card'
import SpecialistDetails from './details'
import DeleteDialog from '@/components/elements/delete-dialog'
import {MessageKind} from '@/helpers/enums'
import { mapActions } from 'vuex'

export default {
  components: {
    'mpro-editable-card': EditableCard,
    'mpro-specialist-details': SpecialistDetails,
    'mpro-delete-dialog': DeleteDialog
  },

  props: {
    specialist: {type: Object, default: null}
  },

  data: () => ({
    deleting: false
  }),

  computed: {
    loading: function () {
      return this.specialist == null
    },
    deleteWarning: function () {
      return this.specialist == null
        ? null
        : this.$t('admin.delete_specialist_warning', {name: this.specialist.DisplayName})
    }
  },

  methods: {
    async save () {
      if (!this.$refs.details.validate()) return false

      try {
        await this.updateSpecialist(this.$refs.details.data)
        return true
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e)
        })
        return false
      }
    },
    onEndEdit (saved) {
      if (saved) {
        this.$emit('update')
      } else {
        this.$refs.details.reset()
      }
    },
    async onDeleteConfirm () {
      try {
        await this.deleteSpecialist(this.specialist.Login)
      } catch (e) {
        this.showGlobalMessage({
          kind: MessageKind.ERROR,
          text: this.$errorMessage(e)
        })
        return
      } finally {
        this.deleting = false
      }

      this.showGlobalMessage({
        kind: MessageKind.SUCCESS,
        text: this.$t('forms.save_changes_success')
      })

      if (this.specialist.OrganizationCode) {
        this.$router.push({
          name: 'admin:OrganizationTab',
          params: {
            code: this.specialist.OrganizationCode,
            activeTab: 'specialists'
          }})
      } else {
        // TODO: Change to admin:Specialists when it's implemented
        this.$router.push({name: 'Dashboard'})
      }
    },
    ...mapActions(['showGlobalMessage']),
    ...mapActions('admin', ['updateSpecialist', 'deleteSpecialist'])
  }
}
</script>
