<template>
  <v-container class="pa-6  mb-6">
    <v-row justify="center">
      <v-col cols="12" md="4">
        <v-avatar class="MProCoral ma-auto d-block" size="100" rounded large>
          <v-icon color="MProWhite" large>mdi-account</v-icon>
        </v-avatar>
        <v-divider class="mt-4 mb-3"/>
        <div v-if="seeker">
          <div>
            <p class="ma-0 pa-0 text-caption text--secondary">{{$t('person_profile.about')}}</p>
            <p class="ma-0 mb-3 pa-0">{{seeker.About ? seeker.About : '-'}}</p>
          </div>
          <p class="ma-0 pa-0 text-caption text--secondary">{{$t('person_profile.name')}}</p>
          <p class="ma-0 mb-3 pa-0">{{seeker.FirstName ? seeker.FirstName : '-'}} {{seeker.LastName ? seeker.LastName : '-'}}</p>
          <p class="ma-0 pa-0 text-caption text--secondary">{{$t('person_profile.age')}}</p>
          <p class="ma-0 mb-3 pa-0">{{seekerAge}} ({{seeker.BirthDate ? $moment(seeker.BirthDate).format('L') : '-'}})</p>
          <p class="ma-0 pa-0 text-caption text--secondary">{{$t('person_profile.gender')}}</p>
          <p class="ma-0 mb-3 pa-0">{{seeker.Gender ? seeker.Gender : '-'}}</p>
          <p class="ma-0 pa-0 text-caption text--secondary">{{$t('person_profile.country_of_residence')}}</p>
          <p class="ma-0 mb-3 pa-0">{{seeker.CountryCode ? $isoCountries.getName(seeker.CountryCode, $i18n.locale) : '-'}}</p>
          <p class="ma-0 pa-0 text-caption text--secondary">{{$t('person_profile.email')}}</p>
          <p class="ma-0 mb-3 pa-0">{{seeker.Email ? seeker.Email : '-'}}</p>
          <p class="ma-0 pa-0 text-caption text--secondary">{{$t('person_profile.phone')}}</p>
          <p class="ma-0 mb-3 pa-0">{{seeker.Phone ? seeker.Phone : '-'}}</p><br>
          <v-expansion-panels v-if="hasSubordinates || seeker.SuperiorId != null">
            <v-expansion-panel>
              <v-expansion-panel-header v-if="hasSubordinates">{{ $t('person_profile.subordinates') }}</v-expansion-panel-header>
              <v-expansion-panel-header v-else>{{ $t('person_profile.superior') }}</v-expansion-panel-header>
              <v-expansion-panel-content v-if="hasSubordinates">
                <v-list-item v-for="sub in seekerSubordinates.subordinates" :key="sub.Id"
                            style="min-height: 32px"
                            :to="{name: 'ViewSeeker', params: {seekerId: sub.Id}}">
                  <v-list-item-content class="py-2">
                    <v-list-item-title>{{ getSubordinateName(sub) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-content>
              <v-expansion-panel-content v-else>
                <v-list-item style="min-height: 32px"
                            :to="{name: 'ViewSeeker', params: {seekerId: seeker.SuperiorId}}">
                  <v-list-item-content class="py-2">
                    <v-list-item-title>{{ seeker.SuperiorName }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
      <v-col cols="12" md="4" :order="($vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'md') ? 2 : ''">
        <h2>{{$t('person_profile.timeline')}}</h2>
        <v-timeline v-if="itemsToDisplay && itemsToDisplay.length > 0" class="d-inline-block">
          <v-timeline-item v-for="(item, index) in itemsToDisplay" :key="item.Id">
            <template #opposite>
              <span>{{$formatDateTime((item.CapturedAt || item.AnsweredAt))}}</span>
            </template>
            <!-- Scan item -->
            <v-card v-if="item.CapturedAt != null" hover
                    :to="getItemLink(item)"
                    max-width="200" max-height="250"
                    class="pa-3"
                    :class="index % 2 === 1 ? 'ml-auto' : null">
              <mpro-problem-areas v-if="item.scanData"
                                  :areas="item.scanData.person.discomfort_areas"
                                  :gender="item.scanData.person.gender">
              </mpro-problem-areas>
              <v-card-title class="wb-normal" v-else-if="item.ScanType === '2D'">
                <p><v-icon>mdi-cellphone</v-icon>{{ $t('scans2d.mobile-scan') }}</p>
              </v-card-title>
              <v-skeleton-loader v-else type="image"/>
            </v-card>
            <!-- Survey item -->
            <v-card v-if="item.AnsweredAt != null" hover
                    :to="getItemLink(item)">
              <v-card-title class="wb-normal" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'font-size: 1rem;' : ''">
                <span v-if="item.metadata">{{item.metadata.Title}}</span>
                <span v-else>{{item.QuestionnaireCode}}</span>
              </v-card-title>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        <v-btn v-if="itemLimit < totalItems" @click="itemLimit = itemLimit + 5">Load more</v-btn>
      </v-col>
      <v-col cols="12" md="4" :order="($vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'md') ? 1 : ''">
        <div v-if="lastDiscomfort.length > 0">
          <p>{{ $t('person_profile.lastDiscomfort') }}</p>
          <mpro-problem-areas :areas="lastDiscomfort"
                              :gender="seeker.Gender">
          </mpro-problem-areas>
        </div>
        <p v-else>{{ $t('person_profile.noLastDiscomfort') }}</p>
        <p class="mb-0">{{ lastFall ? $t('person_profile.last_fall', {date: $formatDate(lastFall, 'YYYY-MM-DD')}) : $t('person_profile.no_last_fall') }}</p>
        <p class="mt-0">{{ lastFall ? $moment().diff($moment(lastFall), 'years') >= 1 ? $t('person_profile.fall_more_than_year') : $t('person_profile.fall_less_than_year') : ''}}</p>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{$tc('programs.title-specialist', programs.length, {name: seekerName, num: programs.length})}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p v-if="!sharesGoalsAndExercises">{{ $t('programs.not-shared-with-specialist') }}</p>
              <div v-else-if="programs.length">
                <div v-for="(program) in programs.slice(0,5)" :key="program.Id">
                  <p>{{$t('programs.header_title', {num: program.DurationWeeks} )}}, {{ $tc('programs.num-of-exercises', program.ExerciseIds.length, {num: program.ExerciseIds.length}) }}<span class="float-right text--secondary">{{$formatDate(program.StartDate)}}</span></p>
                </div>
              </div>
              <p v-else>{{ $t('programs.none-active') }}</p>
              <router-link v-if="sharesGoalsAndExercises"
                           :to="{name: 'clientExercises', params: {seekerId: seekerId}}">
                {{ $t('programs.view-all') }}
              </router-link>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $tc('goals.title-specialist', goals.length, {name: seekerName, num: goals.length}) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p v-if="!sharesGoalsAndExercises">{{ $t('goals.not-shared-with-specialist') }}</p>
              <div v-else-if="goals.length">
                <div v-for="(goal) in goals.slice(0,5)" :key="goal.Id">
                  <p>{{goal.Goal}}<span class="float-right text--secondary">{{$formatDate(goal.StartDate)}}</span></p>
                </div>
              </div>
              <p v-else>{{ $t('goals.none-active') }}</p>
              <router-link v-if="sharesGoalsAndExercises"
                           :to="{name: 'clientGoals', params: {seekerId: seekerId}}">
                {{ $t('goals.view-all') }}
              </router-link>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{$tc('scans.title-specialist',seekerScans.length, {name: seekerName, num: seekerScans.length})}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p v-if="!sharesScans3D && !sharesScans2D">{{ $t('scans.scans-not-shared-with-specialist') }}</p>
              <div v-else-if="seekerScans.length === 0">
                <p>{{ $t('scans.no-scans') }}</p>
                <p v-if="!sharesScans3D">{{ $t('scans.scans3d-not-shared-with-specialist') }}</p>
                <p v-if="!sharesScans2D">{{ $t('scans.scans2d-not-shared-with-specialist') }}</p>
              </div>
              <div v-else>
                <p v-for="(scan, index) in seekerScans.slice(0,5)" :key="scan.Id">
                  <v-icon small class="mr-2">{{ getScanIcon(scan).name }}</v-icon>
                  <router-link :to="{name: 'Scan', params: {scanId: scan.Id}}">
                    {{$t('scans.scan-title', {num: seekerScans.length - (index)})}}
                  </router-link>
                  <span class="float-right text--secondary">{{$formatDateTime(scan.CapturedAt)}}</span>
                </p>
                <router-link :to="{name: 'clientScans', params: {seekerId: seekerId}}">{{ $t('scans.view-all') }}</router-link>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{$tc('surveys.title-specialist', seekerSurveys && seekerSurveys.filter(s => s.AnsweredAt).length > 0, {name: seekerName, num: seekerSurveys && seekerSurveys.filter(s => s.AnsweredAt).length})}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p v-if="!sharesSurveys">{{ $t('surveys.not-shared-with-specialist') }}</p>
              <div v-else-if="seekerSurveys && seekerSurveys.filter(s => s.AnsweredAt).length">
                <p>{{ $t('surveys.latest-surveys-answered', {num: (seekerSurveys.filter(s => s.AnsweredAt).length > 5 ) ? 5 : seekerSurveys.filter(s => s.AnsweredAt).length}) }}</p>
                <div v-for="(survey) in seekerSurveys.filter(s => s.AnsweredAt).slice(0,5)" :key="`${survey.QuestionnaireCode}-${survey.AnsweredAt}`">
                  <router-link :to="getItemLink(survey)">
                    <p>{{survey.metadata ? survey.metadata.Title : survey.QuestionnaireCode}}<span class="float-right text--secondary">{{$formatDateTime(survey.AnsweredAt)}}</span></p>
                  </router-link>
                </div>
              </div>
              <p v-else>{{ $t('surveys.no-surveys-answered') }}</p>
              <router-link v-if="sharesSurveys"
                           :to="{name: 'clientSurveys', params: {seekerId: seekerId}}">
                {{ $t('surveys.view-all') }}
              </router-link>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <mpro-header-handler tabName="profile" :seeker="seeker"/>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import problemAreas from '../scans/problem-areas'
import HeaderHandler from '../specialist/header-handler'
import ProgramAnalyzer from '../exercises/program-analyzer'
import { ScanType } from '../../helpers/enums'
import { getScanIcon } from '@/models/ui'
import { SharedDataKind } from '@/models/data-sharing'

export default {
  components: {
    'mpro-problem-areas': problemAreas,
    'mpro-header-handler': HeaderHandler
  },

  data: () => ({
    itemLimit: 5,
    seekerSubordinates: [],
    refreshTimeline: 0,
    sharedDataKinds: []
  }),

  computed: {
    seekerId: function () {
      return this.$route.params.seekerId
    },
    seeker: function () {
      return this.getSeekerById(this.seekerId)
    },
    seekerAge: function () {
      return this.seeker?.BirthDate == null ? '-' : this.$formatAgeFromBirthDate(this.seeker.BirthDate)
    },
    seekerName: function () {
      return this.seeker?.FirstName ?? this.seeker?.Username
    },
    sharesScans3D: function () {
      return this.sharedDataKinds.includes(SharedDataKind.Scans)
    },
    sharesScans2D: function () {
      return this.sharedDataKinds.includes(SharedDataKind.Scans2D)
    },
    sharesSurveys: function () {
      return this.sharedDataKinds.includes(SharedDataKind.Surveys)
    },
    sharesGoalsAndExercises: function () {
      return this.sharedDataKinds.includes(SharedDataKind.GoalsAndExercises)
    },
    goals: function () {
      let openGoals = this.getGoalsBySeekerId(this.seekerId)
      return openGoals.filter(s => !s.EndDate)
    },
    seekerScans: function () {
      return this.getSeekerScansById(this.seekerId)
    },
    programs: function () {
      return this.getAssignedPrograms(this.seekerId).filter(ProgramAnalyzer.isActive)
    },
    subordinates: function () {
      if (!this.seekerSubordinates) return
      const result = [...this.seekerSubordinates]
      result.sort((s1, s2) => {
        const name1 = this.getSubordinateName(s1)
        const name2 = this.getSubordinateName(s2)
        return name1 < name2 ? -1 : name1 > name2 ? +1 : 0
      })
      return result
    },
    hasSubordinates: function () {
      return this.seekerSubordinates?.subordinates?.length > 0
    },
    items: function () {
      let scans = this.seekerScans?.filter(s => s.CapturedAt != null) ?? []
      let surveys = this.seekerSurveys != null && this.sharesSurveys ? this.seekerSurveys.filter(s => s.AnsweredAt != null) : []
      return scans.concat(surveys).map(item => ({ scanData: null, metadata: null, ...item }))
    },
    totalItems: function () {
      return this.items.length
    },
    itemsToDisplay: function () {
      let items = this.items.slice()
      items.sort((a, b) => this.$moment(b.CapturedAt || b.AnsweredAt).diff(this.$moment(a.CapturedAt || a.AnsweredAt)))
      // Refer refreshTimeline to force refresh when it's changed
      // This is always 0 as refreshTimeline is non-negative
      const offset = Math.min(0, this.refreshTimeline)
      items = items.slice(offset, this.itemLimit)
      return items
    },
    ...mapGetters('specialist', ['getSeekerById', 'getSeekerScansById']),
    ...mapGetters(['isSpecialist']),
    ...mapGetters('goals', ['getGoalsBySeekerId']),
    ...mapGetters('exercises', ['getAssignedPrograms']),
    ...mapGetters(['isSpecialist']),
    ...mapGetters('user', ['getSeeker'])
  },

  asyncComputed: {
    lastDiscomfort: {
      async get () {
        const lastScan = this.seekerScans?.length > 0
          ? this.seekerScans.find(s => s.CapturedAt != null && s.ScanType === ScanType.DEPTH)
          : null
        const lastSurvey = this.seekerSurveys?.length > 0
          ? this.seekerSurveys.find(s => s.QuestionnaireCode === 'DiscomfortAreas' && s.AnsweredAt != null)
          : null

        if (lastScan != null && (lastSurvey == null || this.$moment(lastSurvey.AnsweredAt).isBefore(this.$moment(lastScan.CapturedAt)))) {
          if (lastScan.scanData == null) {
            lastScan.scanData = await this.getOrLoadScanData({ scanId: lastScan.Id })
          }
          return lastScan.scanData?.person?.discomfort_areas ?? []
        } else if (lastSurvey != null && (lastScan == null || this.$moment(lastScan.CapturedAt).isBefore(this.$moment(lastSurvey.AnsweredAt)))) {
          const survey = await this.getOrLoadSurveyById(lastSurvey.Id)
          return survey?.answers?.length > 0
            ? survey.answers[0].Answer.split(',')
            : []
        }

        return []
      },
      default: []
    },

    lastFall: async function () {
      for (const item of this.seekerScans) {
        if (item.ScanType === ScanType.DEPTH) {
          if (item.scanData === undefined) {
            item.scanData = await this.getOrLoadScanData({ scanId: item.Id })
          }
          if (item.scanData != null && item.scanData.person.has_falls === true && item.CapturedAt != null) {
            return item.CapturedAt
          }
        }
      }
      return false
    },
    seekerSurveys: function () {
      return this.seekerId && this.sharesSurveys
        ? this.getOrLoadSurveysBySeekerId({ seekerId: this.seekerId })
        : Promise.resolve([])
    }
  },

  watch: {
    items: function () {
      // Load items metadata
      // Process each item on a separate Vue tick to allow
      // content to appear as soon as it's loaded
      const update = async (index) => {
        if (index >= this.items.length) return

        const item = this.items[index]
        if (item.CapturedAt != null && item.ScanType === ScanType.DEPTH && item.scanData == null) {
          item.scanData = await this.getOrLoadScanData({ scanId: item.Id })
        } else if (item.AnsweredAt != null && item.metadata == null) {
          item.metadata = await this.getOrLoadMetadata(item.QuestionnaireCode)
        }
        this.refreshTimeline++

        this.$nextTick(() => update(index + 1))
      }
      this.$nextTick(() => update(0))
    },
    seekerId () {
      this.onSeekerIdChanged()
    }
  },

  methods: {
    async onSeekerIdChanged () {
      this.sharedDataKinds = []
      this.updateSeekerData()
      if (this.seekerId != null) {
        this.sharedDataKinds = await this.getOrLoadSharedDataKinds(this.seekerId)
        this.updateSeekerData()
      }
    },
    getItemLink (item) {
      return item.CapturedAt != null
        ? {name: 'Scan', params: {scanId: item.Id}}
        : item.AnsweredAt
          ? {name: 'ViewSurvey', params: {surveyId: item.Id}}
          : null
    },
    getScanIcon (scan) {
      return getScanIcon(scan)
    },
    getSubordinateName (subordinate) {
      return `${subordinate.FirstName} ${subordinate.LastName}`
    },
    async updateSeekerData () {
      if (this.seekerId != null) {
        this.seekerSubordinates = await this.loadRelatedSeekers(this.seekerId)

        if (this.sharesGoalsAndExercises) {
          this.ensureSeekerGoalsLoaded({ seekerId: this.seekerId })
          this.ensureSeekerProgramsLoaded({ seekerId: this.seekerId })
        }
      }
    },
    ...mapActions(['showGlobalMessage']),
    ...mapActions('surveys', ['getOrLoadSurveysBySeekerId', 'getOrLoadMetadata', 'getOrLoadSurveyById']),
    ...mapActions('specialist', ['ensureSpecialistScansLoaded', 'ensureSpecialistSeekersLoaded', 'loadRelatedSeekers', 'getOrLoadSharedDataKinds']),
    ...mapActions('scans', ['getOrLoadScanData']),
    ...mapActions('goals', ['ensureSeekerGoalsLoaded']),
    ...mapActions('exercises', ['ensureSeekerProgramsLoaded'])
  },

  created () {
    this.ensureSpecialistSeekersLoaded()
    this.ensureSpecialistScansLoaded()
    this.onSeekerIdChanged()
  }
}
</script>
