<template>
  <div class="d-flex flex-column">
    <v-container class="ma-0 pb-0" style="max-width: 100%;" id="scan-select">
      <v-row justify="center">
        <v-col cols="3" class="py-1">
          <v-select
            :label="$t('scans.viewer.scans-select')"
            :placeholder="$t('scans.viewer.scans-select-first')" persistent-placeholder
            v-model='scanLeft'
            :items='scans'
            item-value='Id'
            item-text='Id'
            persistent-hint
            :hint="`${scanLeft ? scanLeft.LibraryName || scanLeft.CapturedBy : ''}`"
            class="max-text-width"
            return-object
          >
            <template slot='selection' slot-scope='{ item }'>
              {{ $t('scans.scan-title', {num: item.index}) }} - {{ $formatDate(item.CapturedAt) }}
            </template>
            <template slot='item' slot-scope='{ item }'>
              {{ $t('scans.scan-title', {num: item.index}) }} - {{ $formatDate(item.CapturedAt) }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="2" class="py-1">
          <v-select v-if="scanLeft"
                    v-model="showMovementLeft"
                    :items="movementItemsLeft"
                    :disabled="progressLeft != null"
                    :label="$t('scans.viewer.movement-select')"
                    :placeholder="$t('scans.viewer.movement-select-first')" persistent-placeholder
                    class="max-text-width">
          </v-select>
        </v-col>
        <v-col cols="2">
          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{on}">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-cog-outline</v-icon>
                </v-btn>
              </template>
              <div class="MProWhite" style="z-index:999;">
                <v-checkbox v-model="syncViews" class="mx-2"  :label="$t('scans.viewer.sync-vieport')"></v-checkbox>
                <v-checkbox v-model="mirror" v-if="showMirror" :disabled="!renderedR" class="mx-2"  :label="$t('scans.viewer.sync-right-vieport')"></v-checkbox>
                <v-divider/>
                <v-radio-group v-model="syncTime" v-if="showTimeSync" class="mx-2" :label="$t('scans.viewer.sync-time')">
                  <v-radio :label="$t('scans.viewer.sync-time-left')" value="left" class="mt-2"></v-radio>
                  <v-radio :label="$t('scans.viewer.sync-time-right')" value="right"></v-radio>
                  <v-radio :label="$t('scans.viewer.no-sync-time')" value="none"></v-radio>
                </v-radio-group>
              </div>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="3" class="py-1">
          <v-select
            :label="$t('scans.viewer.scans-select')"
            :placeholder="$t('scans.viewer.scans-select-first')" persistent-placeholder
            v-model='scanRight'
            :items='scans'
            item-value='Id'
            item-text='Id'
            persistent-hint
            :hint="`${scanRight ? scanRight.LibraryName || scanRight.CapturedBy : ''}`"
            return-object
          >
            <template slot='selection' slot-scope='{ item }'>
              {{ $t('scans.scan-title', {num: item.index}) }} - {{ $formatDate(item.CapturedAt) }}
            </template>
            <template slot='item' slot-scope='{ item }'>
              {{ $t('scans.scan-title', {num: item.index}) }} - {{ $formatDate(item.CapturedAt) }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="2" class="py-1">
          <v-select v-if="scanRight"
                    v-model="showMovementRight"
                    :items="movementItemsRight"
                    :disabled="progressRight != null"
                    :label="$t('scans.viewer.movement-select')"
                    :placeholder="$t('scans.viewer.movement-select-first')" persistent-placeholder
                    class="max-text-width">
          </v-select>
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col cols="5" class="pa-0">
          <v-progress-linear v-if="progressLeft != null"
                             :value="progressLeft" :indeterminate="progressLeft < 0" />
          <div v-else style="height:4px"></div>
        </v-col>
      <v-col cols="5" class="pa-0">
          <v-progress-linear v-if="progressRight != null"
                             :value="progressRight" :indeterminate="progressRight < 0" />
          <div v-else style="height:4px"></div>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="ma-0 py-0" style="max-width: 100%;" ref="viewerContainer">
      <v-row justify="center" class="ma-0 py-0">
        <v-col v-if="showMovementLeft && scanLeft"
               cols="6" class="ma-0 py-0  d-flex flex-column"
               :style="'height: ' + height + 'px'"
               :id="leftViewerElementId">
            <mpro-scan-viewer :scanId="scanLeft.Id"
                              :scanData="scanDataLeft"
                              :movement="showMovementLeft"
                              :reprojectionTable="reprojectionTableLeft"
                              :depthMap="movementDepthMapLeft"
                              :trackingPoints="movementTrackingPointsLeft"
                              :measures="movementMeasuresLeft"
                              :compare="true"
                              :syncView="syncViews"
                              :syncTime="syncTime"
                              :emitTime="emitTimeLeft"
                              class="fill-height"/>
        </v-col>
        <v-col v-else cols="6" class="ma-0 py-0  d-flex flex-column" :style="'height: ' + height + 'px'">
        </v-col>
        <v-col cols="6" class="ma-0 py-0  d-flex flex-column" :style="'height: ' + height + 'px'"
               v-if="showMovementRight && scanRight"
               :id="rightViewerElementId">
            <mpro-scan-viewer :scanId="scanRight.Id"
                              :scanData="scanDataRight"
                              :movement="showMovementRight"
                              :reprojectionTable="reprojectionTableRight"
                              :depthMap="movementDepthMapRight"
                              :trackingPoints="movementTrackingPointsRight"
                              :measures="movementMeasuresRight"
                              :compare="true"
                              :secondaryScan="true"
                              :syncView="syncViews"
                              :syncTime="syncTime"
                              :emitTime="emitTimeRight"
                              :mirror="mirror"
                              class="fill-height"/>
        </v-col>
        <v-col v-else cols="6" class="ma-0 py-0  d-flex flex-column" :style="'height: ' + height + 'px'">
        </v-col>
      </v-row>
    </v-container>
    <mpro-header-handler tabName="scans" :seeker="seeker"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import ScanViewer from './scan-viewer'
import HeaderHandler from '../specialist/header-handler'
import { ScanType, movementKindToAssessmentName, MovementKind } from '../../helpers/enums'
import ElementIds from './element-ids'
import { augmentAssessments } from '@/logic/assessments'

export default {
  components: {
    'mpro-scan-viewer': ScanViewer,
    'mpro-header-handler': HeaderHandler
  },
  data: () => {
    return {
      pageIndex: 1,
      dialog: false,
      scanLeft: undefined,
      scanRight: undefined,
      showMovementLeft: null,
      showMovementRight: null,
      reprojectionTableLeft: null,
      reprojectionTableRight: null,
      movementDepthMapLeft: null,
      movementDepthMapRight: null,
      movementTrackingPointsLeft: null,
      movementTrackingPointsRight: null,
      movementMeasuresLeft: null,
      movementMeasuresRight: null,
      camerLeft: null,
      cameraRight: null,
      scans: [],
      syncViews: true,
      syncTime: 'left',
      framesLeft: 0,
      framesRight: 0,
      firstFrame: [null, null],
      middleFrame: [null, null],
      lastFrame: [null, null],
      mirror: false,
      progressLeft: null,
      progressRight: null,
      windowHeight: window.innerHeight,
      resolution: '_Low',
      renderedR: false,
      presentMode: false
    }
  },
  computed: {
    ...mapState({
      scansSpecialist: state => state.specialist.scans
    }),
    ...mapGetters(['isSpecialist']),
    ...mapGetters('specialist', ['getSeekerById']),
    height: function () {
      let header = 0
      if (!this.presentMode) header = document.getElementsByTagName('header').length > 0 ? document.getElementsByTagName('header')[0].clientHeight : 96
      let footer = 0
      if (!this.presentMode) footer = document.getElementsByTagName('footer').length > 0 ? document.getElementsByTagName('footer')[0].clientHeight : 48
      let elemHeight = document.getElementById('scan-select') ? document.getElementById('scan-select').clientHeight : 108
      return this.windowHeight - header - footer - elemHeight
    },
    showTimeSync: function () {
      if (this.showMovementLeft && this.showMovementRight) {
        if (this.showMovementLeft === this.showMovementRight) return true
        const bothBalanceSingleOrSquatSingle =
          ([MovementKind.BALANCE_LEFT, MovementKind.BALANCE_RIGHT].includes(this.showMovementLeft) &&
          [MovementKind.BALANCE_LEFT, MovementKind.BALANCE_RIGHT].includes(this.showMovementRight)) ||
          ([MovementKind.SQUAT_LEFT, MovementKind.SQUAT_RIGHT].includes(this.showMovementLeft) &&
          [MovementKind.SQUAT_LEFT, MovementKind.SQUAT_RIGHT].includes(this.showMovementRight))
        if (bothBalanceSingleOrSquatSingle) return true
      }
      return false
    },
    showMirror: function () {
      if (this.showMovementLeft && this.showMovementRight) {
        const bothBalanceSingleOrSquatSingle =
          ([MovementKind.BALANCE_LEFT, MovementKind.BALANCE_RIGHT].includes(this.showMovementLeft) &&
          [MovementKind.BALANCE_LEFT, MovementKind.BALANCE_RIGHT].includes(this.showMovementRight)) ||
          ([MovementKind.SQUAT_LEFT, MovementKind.SQUAT_RIGHT].includes(this.showMovementLeft) &&
          [MovementKind.SQUAT_LEFT, MovementKind.SQUAT_RIGHT].includes(this.showMovementRight))
        if (bothBalanceSingleOrSquatSingle) return true
      }
      return false
    },
    seekerId: function () {
      return this.$route.params.seekerId
    },
    seeker: function () {
      return this.getSeekerById(this.seekerId)
    },
    movementItemsLeft: function () {
      if (this.scanDataLeft == null) return []

      return Object.keys(this.scanDataLeft.movements).map(key => ({
        value: key,
        text: this.movementTextString(this.scanDataLeft, key)
      }))
    },
    movementItemsRight: function () {
      if (this.scanDataRight == null) return []

      return Object.keys(this.scanDataRight.movements).map(key => ({
        value: key,
        text: this.movementTextString(this.scanDataRight, key)
      }))
    },
    emitTimeLeft: function () {
      return this.syncTime === 'left'
    },
    emitTimeRight: function () {
      return this.syncTime === 'right'
    },
    leftViewerElementId: function () {
      return ElementIds.getViewerId((this.scanLeft || {}).Id, true, false)
    },
    rightViewerElementId: function () {
      return ElementIds.getViewerId((this.scanRight || {}).Id, true, true)
    }
  },
  asyncComputed: {
    scanDataLeft: async function () {
      if (this.scanLeft != null && this.scanLeft.Id != null) {
        let scanData = await this.getOrLoadScanData({
          scanId: this.scanLeft.Id,
          resolution: this.resolution
        })
        if (scanData === 404) {
          this.resolution = ''
          this.loadReprojectionTable()
          return
        }
        return scanData
      }
      return null
    },
    scanDataRight: async function () {
      if (this.scanRight != null && this.scanRight.Id != null) {
        let scanData = await this.getOrLoadScanData({
          scanId: this.scanRight.Id,
          resolution: this.resolution
        })
        if (scanData === 404) {
          this.resolution = ''
          this.loadReprojectionTable()
          return
        }
        return scanData
      }
      return null
    }
  },
  methods: {
    ...mapActions('scans', ['getOrLoadScanData',
      'getOrLoadReprojectionTable', 'getOrLoadMovementDepthMap', 'getOrLoadMovementTrackingPoints', 'getOrLoadMovementMeasures']),
    ...mapActions('specialist', ['ensureSpecialistSeekersLoaded', 'ensureSpecialistScansLoaded']),
    ...mapMutations('specialist', ['markScanViewed']),
    onResize () {
      this.windowHeight = window.innerHeight
      // let elemHeight = document.getElementById('movement-select').clientHeight
      if (this.$refs.viewerContainer) this.$refs.viewerContainer.style.height = this.height
    },
    movementTextString: function (scanData, key) {
      let movementData = scanData.movements[key]
      let str = `${this.$t('scans.movements.' + key)}`
      if (movementData.repeat_count > 1 && movementData.mode !== 'Normal') {
        str += ` (${this.$t('general.count.' + movementData.repeat_count)}, ${this.$t('scans.modes.' + movementData.mode)})`
      } else if (movementData.repeat_count > 1) {
        str += ` (${this.$t('general.count.' + movementData.repeat_count)})`
      } else if (movementData.mode !== 'Normal') {
        str += ` (${movementData.mode})`
      }
      return str
    },
    async loadReprojectionTable () {
      if (this.scanLeft) {
        this.progressLeft = 0
        this.reprojectionTableLeft = await this.getOrLoadReprojectionTable({
          scanId: this.scanLeft.Id,
          progressCallback: p => { this.progressLeft = p },
          resolution: this.resolution
        })
        if (this.reprojectionTableLeft === 404) {
          this.resolution = ''
          this.loadReprojectionTable()
          return
        }
        this.progressLeft = null
      }
      if (this.scanRight) {
        this.progressRight = 0
        this.reprojectionTableRight = await this.getOrLoadReprojectionTable({
          scanId: this.scanRight.Id,
          progressCallback: p => { this.progressRight = p },
          resolution: this.resolution
        })
        if (this.reprojectionTableRight === 404) {
          this.resolution = ''
          this.loadReprojectionTable()
          return
        }
        this.progressRight = null
      }
    },
    loadMovementData () {
      if (this.scanLeft && this.showMovementLeft) {
        let p1l = 0
        let p2l = 0
        let p3l = 0
        const updateProgressLeft = () => {
          // Weighted average in accordance with common size ratio
          // Negative values mean "indeterminate"
          this.progressLeft = p1l < 0 || p2l < 0 || p3l < 0
            ? -1
            : (50 * p1l + p2l + 0.3 * p3l) / 51.3
        }
        this.progressLeft = 0
        const promise1l = this.getOrLoadMovementDepthMap({
          scanId: this.scanLeft.Id,
          movement: this.showMovementLeft,
          progressCallback: p => { p1l = p; updateProgressLeft() },
          resolution: this.resolution
        }).then(result => {
          if (result === 404) {
            this.resolution = ''
            this.loadMovementData()
            return
          }
          this.movementDepthMapLeft = result
        })
        const promise2l = this.getOrLoadMovementTrackingPoints({
          scanId: this.scanLeft.Id,
          movement: this.showMovementLeft,
          progressCallback: p => { p2l = p; updateProgressLeft() },
          resolution: this.resolution
        }).then(result => {
          if (result === 404) {
            this.resolution = ''
            this.loadMovementData()
            return
          }
          this.movementTrackingPointsLeft = result
        })
        const promise3l = this.getOrLoadMovementMeasures({
          scanId: this.scanLeft.Id,
          movement: this.showMovementLeft,
          progressCallback: p => { p3l = p; updateProgressLeft() },
          resolution: this.resolution
        }).then(result => {
          if (result === 404) {
            this.resolution = ''
            this.loadMovementData()
            return
          }
          augmentAssessments(result.Assessments)
          this.movementMeasuresLeft = result
          const assessmentNameLeft = movementKindToAssessmentName(this.showMovementLeft)
          const assessmentLeft = this.movementMeasuresLeft.Assessments[assessmentNameLeft]
          if (this.showMovementLeft !== MovementKind.SIDE_BEND) {
            this.firstFrame[0] = assessmentLeft.SelectROI.FromFrameIndexInclusive
            this.lastFrame[0] = assessmentLeft.SelectROI.ToFrameIndexExclusive
            if ([MovementKind.SQUAT, MovementKind.SQUAT_LEFT, MovementKind.SQUAT_RIGHT].includes(this.showMovementLeft)) {
              this.middleFrame[0] = assessmentLeft.SelectROI
            }
          } else {
            this.firstFrame[0] = assessmentLeft.SelectROILeft.FromFrameIndexInclusive
            this.lastFrame[0] = assessmentLeft.SelectROIRight.ToFrameIndexExclusive
            this.middleFrame[0] = Math.round((assessmentLeft.SelectROILeft.ToFrameIndexExclusive + assessmentLeft.SelectROIRight.FromFrameIndexInclusive) / 2)
          }
        })
        Promise.all([promise1l, promise2l, promise3l]).then(() => {
          this.progressLeft = null
          if (this.isSpecialist) {
            this.markScanViewed(this.scanLeft.Id)
          }
        })
      }

      if (this.scanRight && this.showMovementRight) {
        let p1r = 0
        let p2r = 0
        let p3r = 0
        const updateProgressRight = () => {
          // Weighted average in accordance with common size ratio
          // Negative values mean "indeterminate"
          this.progressRight = p1r < 0 || p2r < 0 || p3r < 0
            ? -1
            : (50 * p1r + p2r + 0.3 * p3r) / 51.3
        }
        const promise1r = this.getOrLoadMovementDepthMap({
          scanId: this.scanRight.Id,
          movement: this.showMovementRight,
          progressCallback: p => { p1r = p; updateProgressRight() },
          resolution: this.resolution
        }).then(result => {
          if (result === 404) {
            this.resolution = ''
            this.loadMovementData()
            return
          }
          this.movementDepthMapRight = result
        })
        const promise2r = this.getOrLoadMovementTrackingPoints({
          scanId: this.scanRight.Id,
          movement: this.showMovementRight,
          progressCallback: p => { p2r = p; updateProgressRight() },
          resolution: this.resolution
        }).then(result => {
          if (result === 404) {
            this.resolution = ''
            this.loadMovementData()
            return
          }
          this.movementTrackingPointsRight = result
        })
        const promise3r = this.getOrLoadMovementMeasures({
          scanId: this.scanRight.Id,
          movement: this.showMovementRight,
          progressCallback: p => { p3r = p; updateProgressRight() },
          resolution: this.resolution
        }).then(result => {
          if (result === 404) {
            this.resolution = ''
            this.loadMovementData()
            return
          }
          augmentAssessments(result.Assessments)
          this.movementMeasuresRight = result
          const assessmentNameRight = movementKindToAssessmentName(this.showMovementRight)
          const assessmentRight = this.movementMeasuresRight.Assessments[assessmentNameRight]
          if (this.showMovementRight !== MovementKind.SIDE_BEND) {
            this.firstFrame[1] = assessmentRight.SelectROI.FromFrameIndexInclusive
            this.lastFrame[1] = assessmentRight.SelectROI.ToFrameIndexExclusive
            if ([MovementKind.SQUAT, MovementKind.SQUAT_LEFT, MovementKind.SQUAT_RIGHT].includes(this.showMovementLeft)) {
              this.middleFrame[1] = assessmentRight.SelectROI
            }
          } else {
            this.firstFrame[1] = assessmentRight.SelectROILeft.FromFrameIndexInclusive
            this.lastFrame[1] = assessmentRight.SelectROIRight.ToFrameIndexExclusive
            this.middleFrame[1] = Math.round((assessmentRight.SelectROILeft.ToFrameIndexExclusive + assessmentRight.SelectROIRight.FromFrameIndexInclusive) / 2)
          }
        })
        Promise.all([promise1r, promise2r, promise3r]).then(() => {
          this.progressRight = null
          if (this.isSpecialist) {
            this.markScanViewed(this.scanRight.Id)
          }
        })
      }
    },
    async refreshItems () {
      await this.ensureSpecialistScansLoaded()
      await this.ensureSpecialistSeekersLoaded()
      const scans = this.scansSpecialist.filter(s => s.SeekerId === this.seekerId)
      scans.forEach(async (item, index) => {
        item.index = scans.length - index
      })
      this.scans = scans.filter(s => s.ScanType === ScanType.DEPTH)
      if (this.$route.query.scanIdL) this.scanLeft = this.scans.find(s => s.Id === this.$route.query.scanIdL)
      if (this.$route.query.scanIdR) this.scanRight = this.scans.find(s => s.Id === this.$route.query.scanIdR)
      if (this.$route.query.movementL) {
        await this.loadReprojectionTable()
        await this.loadMovementData()
        this.showMovementLeft = this.$route.query.movementL
      }
    },
    movementMapped: function (movement) {
      return movementKindToAssessmentName(movement)
    },
    calcNextFrame () {
      this.$root.$on('maxFrames', (frames, scanId) => {
        if (this.scanLeft && this.scanLeft.Id === scanId) this.framesLeft = frames - 1
        if (this.scanRight && this.scanRight.Id === scanId) this.framesRight = frames - 1
      })
      this.$root.$on('syncFrames', (frame) => {
        let returnFrame = frame
        if (this.syncTime === 'left') {
          if (frame <= this.firstFrame[0]) {
            returnFrame = Math.round(this.firstFrame[1] / this.firstFrame[0] * frame)
          } else if (this.middleFrame[0] && frame <= this.middleFrame[0]) {
            returnFrame = Math.round(this.middleFrame[1] / this.middleFrame[0] * frame)
          } else {
            returnFrame = Math.round(this.lastFrame[1] / this.lastFrame[0] * frame)
          }
          if (returnFrame > this.framesRight) returnFrame = this.framesRight
        }
        if (this.syncTime === 'right') {
          if (frame <= this.firstFrame[1]) {
            returnFrame = Math.round(this.firstFrame[0] / this.firstFrame[1] * frame)
          } else if (this.middleFrame[1] && frame <= this.middleFrame[1]) {
            returnFrame = Math.round(this.middleFrame[0] / this.middleFrame[1] * frame)
          } else {
            returnFrame = Math.round(this.lastFrame[0] / this.lastFrame[1] * frame)
          }
          if (returnFrame > this.framesLeft) returnFrame = this.framesLeft
        }

        this.$root.$emit('syncedFrame', returnFrame)
      })
    }
  },
  created () {
    if (this.isSpecialist) {
      this.ensureSpecialistSeekersLoaded()
      this.ensureSpecialistScansLoaded()
    }
  },
  mounted () {
    this.refreshItems()
    this.calcNextFrame()
    if (!this.showTimeSync) this.syncTime = 'none'
    window.addEventListener('resize', this.onResize)
    this.$nextTick(() => {
      this.onResize()
    })
    this.$root.$on('resizeElements', (presentMode) => {
      this.presentMode = presentMode
      this.$nextTick(() => {
        this.onResize()
      })
    })
    this.$root.$on('rendered', (done, scanId) => {
      if (this.scanRight && this.scanRight.Id === scanId) this.renderedR = done
    })
  },
  watch: {
    showMovementLeft () {
      this.firstFrame[0] = null
      this.middleFrame[0] = null
      this.lastFrame[0] = null
      this.mirror = false
      this.loadReprojectionTable()
      this.loadMovementData()
    },
    showMovementRight () {
      this.firstFrame[1] = null
      this.middleFrame[1] = null
      this.lastFrame[1] = null
      this.mirror = false
      this.loadReprojectionTable()
      this.loadMovementData()
    },
    scanLeft () {
      this.showMovementLeft = null
      this.reprojectionTableLeft = null
      this.movementDepthMapLeft = null
      this.movementTrackingPointsLeft = null
      this.movementMeasuresLeft = null
    },
    scanRight () {
      this.showMovementRight = null
      this.reprojectionTableRight = null
      this.movementDepthMapRight = null
      this.movementTrackingPointsRight = null
      this.movementMeasuresRight = null
    },
    showTimeSync: function () {
      if (!this.showTimeSync) this.syncTime = 'none'
      this.syncTime = 'left'
    },
    pageIndex: function () {
      this.refreshItems()
    }
  },
  beforeDestroy () {
    this.$root.$emit('resizeElements', false)
  }
}
</script>
