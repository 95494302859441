<template>
  <v-form>
    <p>{{$t('signup.person_details_instruction')}}</p>
    <v-text-field :label="$t('person_profile.first_name')" required
                  v-model.trim="user.firstName" :error-messages="$validationErrors($v.user.firstName)"
                  @input="$v.user.firstName.$touch()" @blur="$v.user.firstName.$touch()"
                  :readonly="processing"/>
    <v-text-field :label="$t('person_profile.last_name')" required
                  v-model.trim="user.lastName" :error-messages="$validationErrors($v.user.lastName)"
                  @input="$v.user.lastName.$touch()" @blur="$v.user.lastName.$touch()"
                  :readonly="processing"/>
    <v-select :label="$t('person_profile.gender')"
              :items="genderItems"
              required
              v-model="user.gender" :error-messages="$validationErrors($v.user.gender)"
              @input="$v.user.gender.$touch()" @blur="$v.user.gender.$touch()"
              :readonly="processing"/>
    <mpro-date-box :label="$t('person_profile.birth_date')" required :birthdayPicker="true" :max="new Date()"
                   v-model.trim="user.birthDate" :error-messages="$validationErrors($v.user.birthDate, birthDateValidationStrings)"
                   @input="$v.user.birthDate.$touch()" @blur="$v.user.birthDate.$touch()"
                   :readonly="processing"/>
    <mpro-country-select :label="$t('person_profile.country_of_residence')" required
                         v-model="user.country" :error-messages="$validationErrors($v.user.country)"
                         @input="$v.user.country.$touch()" @blur="$v.user.country.$touch()"
                         :readonly="processing"/>
  </v-form>
</template>

<script>
import {Gender} from '@/helpers/enums'
import {required, maxValue} from 'vuelidate/lib/validators'
import moment from 'moment'
import DateBox from '@/components/elements/date-box'
import CountrySelect from '@/components/elements/country-select'

export default {
  components: {
    'mpro-date-box': DateBox,
    'mpro-country-select': CountrySelect
  },

  props: {
    processing: {type: Boolean, default: false}
  },

  data: () => ({
    user: {
      firstName: '',
      lastName: '',
      gender: '',
      birthDate: null,
      country: ''
    }
  }),
  validations: {
    user: {
      firstName: {required},
      lastName: {required},
      gender: {required},
      birthDate: {required, ageOver18: maxValue(moment().endOf('date').subtract(18, 'years'))},
      country: {required}
    }
  },
  computed: {
    genderItems: function () {
      return Object.values(Gender).map(g => ({value: g, text: this.$t('enums.Gender.' + g)}))
    },
    birthDateValidationStrings: function () {
      return {
        ageOver18: this.$t('signup.validations.ageOver18')
      }
    }
  },
  methods: {
    getValidated () {
      this.$v.$touch()
      return this.$v.$invalid ? null : this.user
    }
  }
}
</script>
