<template>
  <v-toolbar-title to="dashboard">
    <router-link :to="{name: 'Dashboard'}">
      <icon-base :iconName="'Logo'" width="162.8" height="16" vbWidth="1628" vbHeight="160" class="d-none d-sm-inline"><component :is="'icon-Logo'"/></icon-base>
      <icon-base :iconName="'Logo'" width="16" height="16" vbWidth="160" vbHeight="160" class="d-inline d-sm-none"><component :is="'icon-LogoIcon'"/></icon-base>
    </router-link>
  </v-toolbar-title>
</template>

<script>
import IconBase from './../icons/IconBase.vue'
import IconLogo from './../icons/Logo.vue'
import IconLogoIcon from './../icons/LogoIcon.vue'

export default {
  components: {
    'icon-base': IconBase,
    'icon-Logo': IconLogo,
    'icon-LogoIcon': IconLogoIcon
  }
}
</script>
