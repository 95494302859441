import axios from 'axios'
import TtlCache from 'ttl-cache'
import Lo from 'lodash'
import { handleAxiosError } from '@/helpers/errors'

const videoUriCache = new TtlCache({
  ttl: process.env.VUE_APP_VIDEO_URI_LIFETIME / 1000,
  interval: 0
})

function dataKeyForNarrative (movementId) {
  return `narrative/${movementId}`
}

function getScanData (state, scanId, key) {
  const scanData = state.scanData[scanId]
  return scanData == null ? undefined : scanData[key]
}

function ensureScanDataLimit (state) {
  const limit = process.env.SCAN_BINARIES_LIMIT
  let mruList = Object.keys(state.scanData).map(scanId => ({ scanId, updatedAt: state.scanData[scanId].updatedAt }))
  if (mruList.length > limit) {
    mruList = Lo.sortBy(mruList, item => item.updatedAt)
    while (mruList.length > limit) {
      const oldest = mruList.shift()
      delete state.scanData[oldest.scanId]
    }
  }
}

function storeScanData (state, scanId, key, value) {
  let scanData = state.scanData[scanId]
  if (scanData == null) {
    scanData = {}
    state.scanData[scanId] = scanData
  }
  scanData.updatedAt = Date.now()
  scanData[key] = value

  ensureScanDataLimit(state)
}

const state = {
  scanData: {},
  viewerSettings: null
}

const getters = {
  getMovementNarrative: state => ({ scanId, movementId }) => {
    const key = dataKeyForNarrative(movementId)
    return getScanData(state, scanId, key)
  },
  viewerSettings: state => state.viewerSettings
}

const mutations = {
  storeViewerSettings (state, value) {
    state.viewerSettings = value
  },
  storeMovementNarrative (state, {scanId, movementId, value}) {
    const key = dataKeyForNarrative(movementId)
    storeScanData(state, scanId, key, value)
  }
}

const RequestUrls = {
  seekerScans: seekerId => `/scans2d/of-seeker/${encodeURIComponent(seekerId)}`,
  scanMovements: scanId => `/scans2d/${encodeURIComponent(scanId)}/movements`,
  scanRecordings: scanId => `/scans2d/${encodeURIComponent(scanId)}/recordings`,
  scanStatus: scanId => `/scans2d/${encodeURIComponent(scanId)}/status`,
  scanInfo: scanId => `/scans2d/${encodeURIComponent(scanId)}/info`,
  pdfReport: (scanId) => `/scans2d/${encodeURIComponent(scanId)}/report`,
  scanComments: (scanId) => `/scans2d/${encodeURIComponent(scanId)}/comments`,
  movementVideoUri: movementId => `/scans2d/movements/${encodeURIComponent(movementId)}/video-uri`,
  movementRawTracking: movementId => `/scans2d/movements/${encodeURIComponent(movementId)}/raw-tracking`,
  movementTrackingPoints: movementId => `/scans2d/movements/${encodeURIComponent(movementId)}/tracking-points`,
  movementGeneralInfo: movementId => `/scans2d/movements/${encodeURIComponent(movementId)}/general-info`,
  movementMeasures: movementId => `/scans2d/movements/${encodeURIComponent(movementId)}/measures`,
  movementNarrative: movementId => `/scans2d/movements/${encodeURIComponent(movementId)}/narrative`,
  recordingVideoUri: recordingId => `/scans2d/recordings/${encodeURIComponent(recordingId)}/video-uri`,
  recordingRawTracking: recordingId => `/scans2d/recordings/${encodeURIComponent(recordingId)}/raw-tracking`
}

const actions = {
  loadSeekerScans: async (context, seekerId) => {
    try {
      const response = await axios.get(RequestUrls.seekerScans(seekerId))
      return response.data
    } catch (e) {
      console.warn('loadSeekerScans:axios', e)
      return []
    }
  },

  loadScanMovements: async (context, scanId) => {
    try {
      const response = await axios.get(RequestUrls.scanMovements(scanId))
      return response.data
    } catch (e) {
      console.warn('loadScanMovements:axios', e)
      return []
    }
  },

  loadScanRecordings: async (context, scanId) => {
    try {
      const response = await axios.get(RequestUrls.scanRecordings(scanId))
      return response.data
    } catch (e) {
      console.warn('loadScanRecordings:axios', e)
      return []
    }
  },

  async loadScanStatus (context, scanId) {
    let response
    try {
      response = await axios.get(RequestUrls.scanStatus(scanId))
    } catch (e) {
      console.warn('loadScanStatus:axios', e)
      return undefined
    }
    return response.data
  },

  loadScanInfo: async (context, scanId) => {
    try {
      const response = await axios.get(RequestUrls.scanInfo(scanId))
      return response.data
    } catch (e) {
      console.warn('loadScanInfo:axios', e)
      return null
    }
  },

  getOrLoadMovementVideoUri: async (context, movementId) => {
    const url = RequestUrls.movementVideoUri(movementId)
    let result = videoUriCache.get(url)
    if (result != null) return result

    let response
    try {
      response = await axios.get(url)
    } catch (e) {
      console.warn('getOrLoadMovementVideoUri', e)
    }

    if (typeof response?.data === 'string') {
      result = response.data
      videoUriCache.set(url, result)
    }

    return result
  },

  loadMovementRawTracking: async (context, movementId) => {
    try {
      const response = await axios.get(RequestUrls.movementRawTracking(movementId))
      return response.data
    } catch (e) {
      console.warn('loadMovementRawTracking:axios', e)
      return ''
    }
  },

  loadMovementTrackingPoints: async (context, movementId) => {
    try {
      const response = await axios.get(RequestUrls.movementTrackingPoints(movementId))
      return response.data
    } catch (e) {
      console.warn('loadMovementTrackingPoints:axios', e)
      return ''
    }
  },

  loadMovementGeneralInfo: async (context, movementId) => {
    try {
      const response = await axios.get(RequestUrls.movementGeneralInfo(movementId))
      return response.data
    } catch (e) {
      console.warn('loadMovementGeneralInfo:axios', e)
      return null
    }
  },

  loadMovementMeasures: async (context, movementId) => {
    try {
      const response = await axios.get(RequestUrls.movementMeasures(movementId))
      return response.data
    } catch (e) {
      console.warn('loadMovementMeasures:axios', e)
      return null
    }
  },

  getOrLoadRecordingVideoUri: async (_, recordingId) => {
    const url = RequestUrls.recordingVideoUri(recordingId)
    let result = videoUriCache.get(url)
    if (result != null) return result

    let response
    try {
      response = await axios.get(url)
    } catch (e) {
      console.warn('getOrLoadRecordingVideoUri', e)
    }

    if (typeof response?.data === 'string') {
      result = response.data
      videoUriCache.set(url, result)
    }

    return result
  },

  loadRecordingRawTracking: async (context, recordingId) => {
    try {
      const response = await axios.get(RequestUrls.recordingRawTracking(recordingId))
      return response.data
    } catch (e) {
      console.warn('loadRecordingRawTracking', e)
      return []
    }
  },

  downloadPdfReport: async (context, {scanId, kind, isMetric}) => {
    try {
      const response = await axios.get(RequestUrls.pdfReport(scanId), {
        params: { kind, isMetric },
        responseType: 'blob'
      })
      return response.data
    } catch (e) {
      console.warn('downloadPdfReport', e)
      return undefined
    }
  },
  getScanComments: async (context, {scanId}) => {
    try {
      const response = await axios.get(RequestUrls.scanComments(scanId))
      return response.data
    } catch (e) {
      console.warn('getScanComments', e)
      return undefined
    }
  },
  postScanComment: async (_, {scanId, message}) => {
    try {
      await axios.post(RequestUrls.scanComments(scanId), {
        Comment: message
      })
    } catch (e) {
      handleAxiosError(e)
    }
  },

  getOrLoadMovementNarrative: async ({ commit, getters }, { scanId, movementId }) => {
    let result = getters.getMovementNarrative({ scanId, movementId })
    if (result != null) return result

    let response
    try {
      response = await axios.get(RequestUrls.movementNarrative(movementId))
    } catch (e) {
      console.log('getOrLoadMovementNarrative:axios', e)
      commit('storeMovementNarrative', {scanId, movementId, value: 'error'})
      return 'error'
    }
    result = null
    result = response.data
    commit('storeMovementNarrative', {scanId, movementId, value: result})
    return result
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
