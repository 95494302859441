<template>
  <v-simple-table>
    <thead>
      <tr>
        <th>{{$t('admin.specialists.login')}}</th>
        <th>{{$t('admin.specialists.name')}}</th>
        <th>{{$t('admin.specialists.title')}}</th>
        <th>{{$t('admin.specialists.department')}}</th>
        <th>{{$t('admin.specialists.phone')}}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="noRecords">
        <td colspan="5" class="text-center">{{$t('admin.no_records')}}</td>
      </tr>
      <tr v-for="s in specialists" :key="s.Login">
        <td>
          <router-link :to="getLocationOfSpecialist(s.Login)">
            {{s.Login}}
          </router-link>
        </td>
        <td>{{s.DisplayName}}</td>
        <td>{{s.Title}}</td>
        <td>{{s.Department}}</td>
        <td>{{s.Phone}}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    specialists: {type: Array, default: () => []}
  },

  computed: {
    ...mapGetters('admin', ['getSpecialistUuid']),
    noRecords: function () {
      return this.specialists == null || this.specialists.length === 0
    }
  },

  methods: {
    getLocationOfSpecialist (login) {
      return {
        name: 'admin:Specialist',
        params: { uuid: this.getSpecialistUuid(login) }
      }
    }
  }
}
</script>
